import { FormInputsContainer, Row } from 'components/edit-forms/styles';
import { Column } from 'components/register-forms-mobile/styles';
import { SearchSelectInput } from 'components/search-selected-input';
import InputText from 'components/styled-input';
import { TextInput } from 'components/text-input';
import { Mask } from 'model/enums/mask-types';
import { useEditForm } from 'provider/edit-form';
import { useRegisterForm } from 'provider/register-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { employmentBondOptions } from 'shared/util/select-utils';
import StringUtils from 'shared/util/string-utils';

export const ContainerInputsIncomeTopOne = props => {
  const { t } = useTranslation();
  const { initialClientData } = useEditForm();
  const { occupations, occupationsTotalPages, getOccupations } = useSelectLists();
  const [occupationName, setOccupationName] = useState('');
  const [employmentBondName, setEmploymentBondName] = useState('');
  const [page, setPage] = useState(0);

  useEffect(() => {
    getOccupations({ name: occupationName, page, size: 7 }, true);
  }, [page]);

  const renderMobile = () => {
    return (
      <Column>
        <TextInput name="employmentBond" label={t('register.inputs.employmentBond')} isRequired hasCustomInput>
          <SearchSelectInput
            disabled={props.onlyView}
            name="employmentBond"
            placeholder={t('register.placeholder.employmentBond')}
            options={employmentBondOptions}
            defaultOptions={employmentBondOptions}
            defaultValue={employmentBondOptions.find(res => res.value === initialClientData?.employmentBond)?.label}
            handleChange={value => setEmploymentBondName(value.name)}
          />
        </TextInput>
        <TextInput name="occupation" label={t('register.inputs.occupation')} isRequired hasCustomInput>
          <SearchSelectInput
            disabled={props.onlyView}
            name="occupation"
            placeholder={t('register.placeholder.occupation')}
            options={occupations.map(occ => ({ label: occ.name ?? '', value: occ.id.toString() }))}
            defaultValue={initialClientData?.physicalPerson?.occupation?.name}
            handleChange={values => {
              setOccupationName(values.name);
              setPage(0);
              getOccupations({ name: values.name, size: 7 });
            }}
            handleOnScroll={() => {
              if (page < occupationsTotalPages - 1) {
                setPage(page + 1);
              }
            }}
            defaultOptions={occupations.map(occ => ({ label: occ?.name ?? '', value: occ.id.toString() }))}
          />
        </TextInput>
        <InputText
          isRequired
          name="admissionDate"
          disabled={props.onlyView}
          label={t('register.inputs.admissionDate')}
          placeholder={t('register.placeholder.admissionDate')}
          maxLength={10}
          mask={Mask.BIRTHDAY}
          defaultValue={
            initialClientData?.physicalPerson?.admissionDate
              ? StringUtils.dateFormatMask(initialClientData?.physicalPerson?.admissionDate.toString())
              : ''
          }
        />
        <InputText
          disabled={props.onlyView}
          name="income"
          label={t('register.inputs.income')}
          placeholder={t('register.placeholder.income')}
          mask={Mask.CURRENCY}
          defaultValue={initialClientData?.physicalPerson?.income ? initialClientData?.physicalPerson?.income.toFixed(2) : ''}
          maxLength={14}
        />
      </Column>
    );
  };
  return (
    <FormInputsContainer $visible={props.visible}>
      {props.mobile ? (
        renderMobile()
      ) : (
        <>
          <Row>
            <TextInput name="employmentBond" label={t('register.inputs.employmentBond')} isRequired hasCustomInput>
              <SearchSelectInput
                disabled={props.onlyView}
                name="employmentBond"
                placeholder={t('register.placeholder.employmentBond')}
                options={employmentBondOptions}
                defaultOptions={employmentBondOptions}
                defaultValue={employmentBondOptions.find(res => res.value === initialClientData?.employmentBond)?.label}
                handleChange={value => setEmploymentBondName(value.name)}
              />
            </TextInput>
            <TextInput name="occupation" label={t('register.inputs.occupation')} isRequired hasCustomInput>
              <SearchSelectInput
                disabled={props.onlyView}
                name="occupation"
                placeholder={t('register.placeholder.occupation')}
                options={occupations.map(occ => ({ label: occ.name ?? '', value: occ.id.toString() }))}
                defaultValue={initialClientData?.physicalPerson?.occupation?.name}
                handleChange={values => {
                  setOccupationName(values.name);
                  setPage(0);
                  getOccupations({ name: values.name, size: 7 });
                }}
                handleOnScroll={() => {
                  if (page < occupationsTotalPages - 1) {
                    setPage(page + 1);
                  }
                }}
                defaultOptions={occupations.map(occ => ({ label: occ?.name ?? '', value: occ.id.toString() }))}
              />
            </TextInput>
            <InputText
              isRequired
              name="admissionDate"
              disabled={props.onlyView}
              label={t('register.inputs.admissionDate')}
              placeholder={t('register.placeholder.admissionDate')}
              maxLength={10}
              mask={Mask.BIRTHDAY}
              defaultValue={
                initialClientData?.physicalPerson?.admissionDate
                  ? StringUtils.dateFormatMask(initialClientData?.physicalPerson?.admissionDate.toString())
                  : ''
              }
            />
          </Row>
          <Row>
            <InputText
              disabled={props.onlyView}
              name="income"
              label={t('register.inputs.income')}
              placeholder={t('register.placeholder.income')}
              mask={Mask.CURRENCY}
              defaultValue={initialClientData?.physicalPerson?.income ? initialClientData?.physicalPerson?.income.toFixed(2) : ''}
              maxLength={14}
            />
          </Row>
        </>
      )}
    </FormInputsContainer>
  );
};
