import { Login } from 'components/general-components/header/styles';
import { BoxImage, ButtonBox, FoundDataText, FoundDataTitle, SliderButton } from 'components/landing-page/opening-screen/simulator/style';
import { ORGANIZATION_TYPE } from 'config/constants';
import { FoundDataFill } from 'images/found-data/found-data-fill';
import ButtonVariants from 'model/enums/buttonVariants';
import { OrganizationType } from 'model/enums/organization-type';
import { RoutePath } from 'model/enums/route-path';
import { FinancingSimpleSimulation } from 'model/financing';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { StyledScreenContainer } from 'themes/globalStyles';
import { StyledFoundData } from '../styled';
import CustomButton from 'components/custom-button';
import { SimulatorProcessingMoment, SimulatorState } from 'components/simulator-data/processing/hooks/use-simulator-state';

interface DesktopProps {
  sendSimulator?: FinancingSimpleSimulation;
  isAdmin?: boolean;
}

const Desktop: React.FC<DesktopProps> = ({ sendSimulator, isAdmin }) => {
  const history = useHistory();

  const { t } = useTranslation();
  const isPhysicalPerson = ORGANIZATION_TYPE === OrganizationType.PF;

  return (
    <StyledScreenContainer
      $customStyle={{
        height: 'calc(100vh - 68px)',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <StyledFoundData>
        <FoundDataTitle>{t('foundedData.foundedTitle', { label: isPhysicalPerson ? 'CPF' : 'CNPJ' })}</FoundDataTitle>
        <FoundDataText>{t('foundedData.foundedText')}</FoundDataText>
        <BoxImage>
          <FoundDataFill />
        </BoxImage>

        <ButtonBox>
          <CustomButton
            onClick={() => history.push(RoutePath.HOME)}
            height={48}
            isInvertColor
            width="160px"
            justifyContent="center"
            margin="16px 0px 0px 0px"
          >
            {t('global.button.backStep')}
          </CustomButton>
          <SliderButton
            $customStyle={{ variant: ButtonVariants.DEFAULT, width: '160px' }}
            onClick={() =>
              isAdmin
                ? history.push(`${RoutePath.SIMULATOR_PROCESSING_ADMIN}/${sendSimulator?.client?.id}`, {
                    sendSimulator: sendSimulator,
                  } as SimulatorState)
                : history.push(RoutePath.SIMULATOR_PROCESSING, {
                    sendSimulator: sendSimulator,
                  } as SimulatorState)
            }
          >
            {t('global.button.continue')}
          </SliderButton>
        </ButtonBox>
      </StyledFoundData>
    </StyledScreenContainer>
  );
};

export default Desktop;
