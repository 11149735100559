import { HttpRequestStatus } from 'model/enums/http-request-status';
import { Reducer } from 'redux';
import { UpdateEvolveGuaranteeState, UpdateEvolveGuaranteeTypes } from './types';
import { EvolveGuaranteeToSimple } from 'model/evolve-guarantee';

const initialState: UpdateEvolveGuaranteeState = {
  status: HttpRequestStatus.NOOP,
  guarantee: undefined,
  error: undefined,
};

const reducer: Reducer<UpdateEvolveGuaranteeState> = (state = initialState, action): UpdateEvolveGuaranteeState => {
  switch (action.type) {
    case UpdateEvolveGuaranteeTypes.UPDATE_EVOLVE_GUARANTEE_REQUEST: {
      return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
    }
    case UpdateEvolveGuaranteeTypes.UPDATE_EVOLVE_GUARANTEE_SUCCESS: {
      return {
        ...state,
        status: HttpRequestStatus.SUCCESS,
        guarantee: action.payload as EvolveGuaranteeToSimple | undefined,
        error: undefined,
      };
    }
    case UpdateEvolveGuaranteeTypes.UPDATE_EVOLVE_GUARANTEE_ERROR: {
      return { ...state, status: HttpRequestStatus.ERROR, error: action.payload as string | undefined };
    }
    case UpdateEvolveGuaranteeTypes.UPDATE_EVOLVE_GUARANTEE_RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export { reducer as updateEvolveGuaranteeReducer };
