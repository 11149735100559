import CustomButton from 'components/custom-button';
import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import Header from 'components/general-components/header';
import PreviousPage from 'components/previous-page';
import { FinancingStatus } from 'model/enums/financing-status';
import { HeaderVariants } from 'model/enums/header-variants';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Status } from '../dashboard/components/status';
import ContentCancelPaymentModal from './components/content-cancel-payment-modal';
import CustomLoadingStep from './components/custom-loading-step';
import CustomStepper from './components/custom-stepper';
import PendingPayment from './components/pending-payment';
import TransferCompletedSuccessfully from './components/transfer-completed-successfully';
import useNewBalanceScreen from './hooks/use-new-balance-screen';
import * as S from './styles';

const NewBalanceScreen = ({ t }: WithTranslation) => {
  const {
    color,
    state,
    isShowPaymentCancellationModal,
    currentStep,
    paymentInfo,
    goToPreviousScreen,
    handleCancelPayment,
    openCancelPaymentModal,
    closeCancelPaymentModal,
  } = useNewBalanceScreen();

  return (
    <>
      <Header variant={HeaderVariants.SECONDARY} />
      <S.Container>
        <S.InnerContainer>
          <S.TitleContainer>
            <PreviousPage title={t('admin.newBalance.title')} handleClick={() => goToPreviousScreen()} />
            <S.CCBNumber>{String(state.state?.paymentId).padStart(5, '0')}</S.CCBNumber>
            <Status text={t(`enum.financingStatus.${paymentInfo?.status}`)} color={paymentInfo?.status ?? ''} />
            {currentStep === 1 && paymentInfo?.status !== FinancingStatus.CANCELLED && (
              <CustomButton
                isInvertColor
                onClick={() => openCancelPaymentModal()}
                style={{ marginLeft: 'auto', color: `${color.rejected}`, borderColor: `${color.rejected}` }}
              >
                {t('global.button.cancelPayment')}
              </CustomButton>
            )}
          </S.TitleContainer>

          <CustomStepper currentStep={currentStep ?? 0} />

          {currentStep === 0 && (
            <CustomLoadingStep
              title={t('admin.newBalance.loading.generatingPixCode')}
              message={t('admin.newBalance.loading.yourPixCodeIsBeingGenerated')}
            />
          )}
          {currentStep === 1 && paymentInfo && (
            <PendingPayment
              cancel={paymentInfo?.status === FinancingStatus.CANCELLED}
              qrCodeUrl={paymentInfo?.iuguPixTransfer.qrCodeUrl}
              pixKey={paymentInfo?.iuguPixTransfer.qrCodeText}
              amountPay={paymentInfo?.amount}
              valueAdd={paymentInfo?.amountToPay}
              assignmentFee={Number(paymentInfo?.assignmentFee ?? 0).toString()}
            />
          )}
          {currentStep === 2 && (
            <CustomLoadingStep title={t('admin.newBalance.loading.transferLoading')} message={t('admin.newBalance.loading.paymentDone')} />
          )}
          {currentStep === 3 && (
            <CustomLoadingStep
              title={t('admin.newBalance.loading.confirmationLoading')}
              message={t('admin.newBalance.loading.yourTransferIsLoading')}
            />
          )}
          {currentStep === 4 && (
            <CustomLoadingStep
              title={t('admin.newBalance.loading.transferLoading')}
              message={t('admin.newBalance.loading.nellPayToGiro')}
            />
          )}
          {currentStep === 5 && (
            <CustomLoadingStep
              title={t('admin.newBalance.loading.confirmationLoading')}
              message={t('admin.newBalance.loading.confirmingTransfer')}
            />
          )}
          {currentStep === 6 && <TransferCompletedSuccessfully />}
        </S.InnerContainer>

        <CustomModalTwoButtons
          hideButtons
          isShowModal={isShowPaymentCancellationModal}
          onCloseModal={() => closeCancelPaymentModal()}
          children={
            <ContentCancelPaymentModal
              handleCancelPayment={() => handleCancelPayment()}
              handleCloseModal={() => closeCancelPaymentModal()}
            />
          }
        />
      </S.Container>
    </>
  );
};

export default withTranslation()(NewBalanceScreen);
