import { useTheme } from 'styled-components';

export const CalendarIcon = ({ isWhite }) => {
  const theme = useTheme();

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Miscellaneous/calendar">
        <g id="Vector (Stroke)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.33301 3.33329C2.96482 3.33329 2.66634 3.63177 2.66634 3.99996V13.3333C2.66634 13.7015 2.96482 14 3.33301 14H12.6663C13.0345 14 13.333 13.7015 13.333 13.3333V3.99996C13.333 3.63177 13.0345 3.33329 12.6663 3.33329H3.33301ZM1.33301 3.99996C1.33301 2.89539 2.22844 1.99996 3.33301 1.99996H12.6663C13.7709 1.99996 14.6663 2.89539 14.6663 3.99996V13.3333C14.6663 14.4379 13.7709 15.3333 12.6663 15.3333H3.33301C2.22844 15.3333 1.33301 14.4379 1.33301 13.3333V3.99996Z"
            fill={isWhite ? '#fff' : theme.color.primaryColor}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.33301 6.66663C1.33301 6.29844 1.63148 5.99996 1.99967 5.99996H13.9997C14.3679 5.99996 14.6663 6.29844 14.6663 6.66663C14.6663 7.03482 14.3679 7.33329 13.9997 7.33329H1.99967C1.63148 7.33329 1.33301 7.03482 1.33301 6.66663Z"
            fill={isWhite ? '#fff' : theme.color.primaryColor}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6663 0.666626C11.0345 0.666626 11.333 0.965103 11.333 1.33329V3.99996C11.333 4.36815 11.0345 4.66663 10.6663 4.66663C10.2982 4.66663 9.99967 4.36815 9.99967 3.99996V1.33329C9.99967 0.965103 10.2982 0.666626 10.6663 0.666626Z"
            fill={isWhite ? '#fff' : theme.color.primaryColor}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.33301 0.666626C5.7012 0.666626 5.99967 0.965103 5.99967 1.33329V3.99996C5.99967 4.36815 5.7012 4.66663 5.33301 4.66663C4.96482 4.66663 4.66634 4.36815 4.66634 3.99996V1.33329C4.66634 0.965103 4.96482 0.666626 5.33301 0.666626Z"
            fill={isWhite ? '#fff' : theme.color.primaryColor}
          />
        </g>
      </g>
    </svg>
  );
};
