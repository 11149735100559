import Stroke from 'images/stroke.svg';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import authenticationService from 'services/authentication-service';
import {
  Link,
  LinkButton,
  ThinBackground,
  ThinContainer,
  ThinHeader,
  ThinHeaderStroke,
  ThinSubTitle,
  ThinTextWithLink,
  ThinTitle,
} from 'styled-components/thin-styles';
interface StateType {
  login: string;
}
interface LocationType {
  state: StateType;
}
const RecoverySent: React.FC = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const location: LocationType = useLocation();
  const [sent, setSent] = useState<boolean>(false);
  const [seconds, setSeconds] = useState<number>(59);
  const [loading, setIsLoading] = useState<boolean>(false);
  const login = location.state.login;

  useEffect(() => {
    if (sent) {
      const myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else {
          setSent(false);
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  });

  const onHandleSubmit = () => {
    setIsLoading(true);

    const newData = { login };
    authenticationService.requestRecoveryPassword(newData).then(res => {
      setSent(true);
      setIsLoading(false);
    });
  };
  return (
    <ThinBackground>
      <ThinHeader onClick={() => history.goBack()}>
        <ThinHeaderStroke src={Stroke} />
        <span>{t('global.button.backStep')}</span>
      </ThinHeader>
      <ThinContainer>
        <ThinTitle>{t('login.recovery.sent.title')}</ThinTitle>
        <ThinSubTitle>{t('login.recovery.sent.subtitle')}</ThinSubTitle>
        {sent ? (
          <>
            <ThinTextWithLink>
              {t('login.recovery.sent.waitToResend')}
              <div style={{ marginLeft: '5px' }}>{`0:${seconds} segundos`}</div>
            </ThinTextWithLink>
          </>
        ) : (
          <ThinTextWithLink>
            {t('login.recovery.sent.dontReceived')}
            <div style={{ marginLeft: '5px' }}>
              <LinkButton onClick={onHandleSubmit}> {t('global.button.resend')}</LinkButton>
            </div>
          </ThinTextWithLink>
        )}
      </ThinContainer>
    </ThinBackground>
  );
};

export default RecoverySent;
