import { RedirectCard } from 'components/redirect-card';
import styled from 'styled-components';

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1 1;
  grid-template-rows: 1 1;
  grid-template-areas:
    'registers-card analysis-card'
    'contracts-card payments-cards';

  grid-row-gap: 22px;
  grid-column-gap: 22px;
  max-width: fit-content;
`;

export const CustomRegistersCard = styled(RedirectCard)`
  grid-area: registers-card;
`;
export const CustomAnalysisCard = styled(RedirectCard)`
  grid-area: analysis card;
`;
export const CustomContractsCard = styled(RedirectCard)`
  grid-area: contracts-card;
`;
export const CustomPaymentsCard = styled(RedirectCard)`
  grid-area: payments-cards;
`;
