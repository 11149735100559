import Header from 'components/general-components/header';
import { SliderButton } from 'components/landing-page/opening-screen/simulator/style';
import Translate from 'i18n/translate';
import { Authority } from 'model/enums/authority';
import ButtonVariants from 'model/enums/buttonVariants';
import { HeaderVariants } from 'model/enums/header-variants';
import { RoutePath } from 'model/enums/route-path';
import { FiCheckCircle } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import { useTheme } from 'styled-components';
import { PopupContent, PopupText, PopupTitle, SimulatorContainer } from '../style';

interface DesktopProps {
  registrationKey: string;
  whichRoute: boolean;
}

const Desktop: React.FC<DesktopProps> = ({ registrationKey, whichRoute }) => {
  const history = useHistory();
  const authorities = useSelector((state: IRootState) => state.authentication.account?.authorities);
  const { color } = useTheme();

  return (
    <>
      <Header variant={HeaderVariants.SECONDARY} />
      <SimulatorContainer>
        <PopupContent>
          <FiCheckCircle color={color.primaryColor} size={60} />
          <PopupTitle>
            <Translate
              contentKey={authorities?.includes(Authority.ROLE_ADMIN) ? 'admin.simulatorModal.title' : 'simulatorData.popUp.title'}
            />
          </PopupTitle>
          {!authorities?.includes(Authority.ROLE_CLIENT) && !authorities?.includes(Authority.ROLE_ADMIN) && (
            <PopupText>
              <Translate contentKey="simulatorData.popUp.text" />
            </PopupText>
          )}
          {!authorities?.includes(Authority.ROLE_CLIENT) ? (
            <SliderButton
              onClick={() =>
                history.push(authorities?.includes(Authority.ROLE_ADMIN) ? `admin/cadastros/` : `${RoutePath.REGISTER}${registrationKey}`)
              }
              $customStyle={{ variant: ButtonVariants.DEFAULT }}
            >
              <Translate contentKey="simulatorData.popUp.button" />
            </SliderButton>
          ) : (
            <SliderButton
              onClick={() => history.push(authorities?.includes(Authority.ROLE_ADMIN) ? `admin/cadastros/` : RoutePath.CREDITS)}
              $customStyle={{ variant: ButtonVariants.DEFAULT }}
            >
              <Translate contentKey="simulatorData.popUp.button" />
            </SliderButton>
          )}
        </PopupContent>
      </SimulatorContainer>
    </>
  );
};

export default Desktop;
