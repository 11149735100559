import CustomButton from 'components/custom-button';
import i18n from 'config/i18n';
import { CommentContainer } from 'features/simulator-result/registration-analysis/styles';
import { ReactComponent as DataAdjustmentsSvg } from 'images/data-adjustments.svg';
import { ReactComponent as FinancingAnalysisSvg } from 'images/financing-analysis.svg';
import { ReactComponent as FundingRejectedSvg } from 'images/funding-rejected.svg';
import { isEmpty } from 'lodash';
import FinancingStatusType from 'model/enums/financing-status-type';
import { Dispatch, SetStateAction } from 'react';
import financingService from 'services/financing-service';
import { PaintSvg, StyledButtonsContainer } from '../styles';

interface Props {
  procedureId: number;
  procedureStatus: FinancingStatusType;
  choosedValue: string;
  medicalProcedure: string;
  isLoadingSendSettingsButton: boolean;
  consultantComment?: string;
  setIsLoadingSendSettingsButton: Dispatch<SetStateAction<boolean>>;
  backProductsList: () => void;
}

const procedureAnalysis = ({
  procedureId,
  procedureStatus,
  choosedValue,
  medicalProcedure,
  consultantComment,
  isLoadingSendSettingsButton,
  setIsLoadingSendSettingsButton,
  backProductsList,
}: Props) => {
  const changeFinancingStatusToAdjusted = () => {
    setIsLoadingSendSettingsButton(true);

    financingService.financingStatusToAdjusted(procedureId).then(() => {
      backProductsList();
      setIsLoadingSendSettingsButton(false);
    });
  };

  if (procedureStatus === FinancingStatusType.ADJUST) {
    return {
      title: i18n.t('dashboard.dataAdjustment'),
      subtitle: i18n.t('dashboard.someTweaking'),
      children: (
        <PaintSvg className="dataAdjustmentsSvg">
          <DataAdjustmentsSvg />
        </PaintSvg>
      ),
      footer: (
        <>
          {!isEmpty(consultantComment) && (
            <CommentContainer style={{ marginTop: '10px', width: '100%' }}>{consultantComment}</CommentContainer>
          )}
          <StyledButtonsContainer $isReverseDirection={false}>
            <CustomButton
              style={{ marginTop: '25px', justifyContent: 'center' }}
              height={48}
              width="205px"
              handleRedirection={{
                location: '/procedimentos/editar-procedimento',
                state: {
                  id: procedureId,
                  medicalProcedure,
                  choosedValue,
                },
              }}
            >
              {i18n.t('global.button.editProcedure')}
            </CustomButton>
            <CustomButton
              style={{ marginTop: '25px', justifyContent: 'center' }}
              height={48}
              width="165px"
              onClick={() => changeFinancingStatusToAdjusted()}
              loading={isLoadingSendSettingsButton}
              isInvertColor
            >
              {i18n.t('global.button.submitSettings')}
            </CustomButton>
          </StyledButtonsContainer>
        </>
      ),
    };
  }

  if (procedureStatus === FinancingStatusType.REJECTED) {
    return {
      title: i18n.t('proceduresDashboard.procedureRejected'),
      subtitle: i18n.t('proceduresDashboard.weWereUnableApproveProcedure'),
      children: (
        <PaintSvg className="fundingRejectedSvg">
          <FundingRejectedSvg />
        </PaintSvg>
      ),
      footer: !isEmpty(consultantComment) ? <CommentContainer>{consultantComment}</CommentContainer> : <></>,
    };
  }

  if (procedureStatus === FinancingStatusType.APPROVED) {
    return {
      title: i18n.t('proceduresDashboard.completedProcedureAnalysis'),
      subtitle: i18n.t('proceduresDashboard.yourProductBeenApproved'),
      children: (
        <PaintSvg className="financingAnalysisSvg">
          <FinancingAnalysisSvg />
        </PaintSvg>
      ),
    };
  }

  return {
    title: i18n.t('proceduresDashboard.procedureAnalysis'),
    subtitle: i18n.t('proceduresDashboard.waitProcedureAnalyzed'),
    text: i18n.t('proceduresDashboard.soonYouWillResultAnalysisProcedure'),
    children: (
      <PaintSvg className="financingAnalysisSvg">
        <FinancingAnalysisSvg />
      </PaintSvg>
    ),
  };
};

export default procedureAnalysis;
