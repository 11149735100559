import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 672px;
  margin-top: 20px;
  flex-wrap: wrap;
`;

export const ContainerDataDocument = styled.div`
  width: 216px;
  height: 44px;
  background: #eff6ff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  a,
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #333333;
    cursor: pointer;
  }

  img {
    cursor: pointer;
  }
`;
