import styled, { css } from 'styled-components';

interface Props {
  $isChecked?: boolean;
}

export const Container = styled.button<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background-color: ${({ $isChecked, theme }) => ($isChecked ? theme.color.lightGray1 : 'transparent')};
  margin: 2px 0px;

  ${({ $isChecked }) =>
    $isChecked &&
    css`
      border-radius: 8px;
    `}

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.gray21};
    margin-left: 8px;
  }
`;
