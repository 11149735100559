import UserDropdown, { ListType } from 'components/general-components/header/dropdown';
import UseResponsiveContext from 'context/use-responsive-context';
import { DropdonwHeaderIcons } from 'images/header';
import { RoutePath } from 'model/enums/route-path';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AuthUtils from 'shared/util/auth-utils';
import { LoginButton } from '../styles';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

export interface Links {
  text: string;
  path: string;
}

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { isMidTablet } = useContext(UseResponsiveContext);
  const { t } = useTranslation();
  const history = useHistory();
  const links: Links[] = [
    { text: t('landingPage.multtiplo.who'), path: 'https://www.multtiplo.com.br/#Speaker' },
    { text: t('landingPage.multtiplo.help'), path: 'https://www.multtiplo.com.br/ouvidoria' },
  ];

  const LpDropdonw: ListType[] = [
    { icon: <DropdonwHeaderIcons.dollarIcon />, text: t('header.myProcedures'), link: RoutePath.MY_PROCEDURES },
    { icon: <DropdonwHeaderIcons.logOut />, text: t('header.logout'), link: RoutePath.LOGOUT },
  ];

  const renderButton = () => {
    if (!AuthUtils.isAuthenticated()) {
      return <LoginButton onClick={() => history.push(RoutePath.LOGIN)}>{t('global.button.enter')}</LoginButton>;
    } else {
      return <UserDropdown menuOpen list={LpDropdonw} />;
    }
  };

  return !isMidTablet ? <Desktop links={links} renderButton={renderButton} /> : <Mobile links={links} renderButton={renderButton} />;
};

export default Header;
