import SystemStepCategory from 'model/enums/system-step-category';
import { Column, Header, Row, Separator, Table } from './styles';
import { useExtractsScreen } from '../../hooks';
import { useCallback, useEffect, useState } from 'react';

export interface TableId {
  first: number;
  second?: number;
}

export interface TableValues {
  header: string;
  values: string[] | JSX.Element[] | (string | JSX.Element)[];
  ids?: TableId[];
  onClick?: (ids?: TableId) => void;
  fullLength?: boolean;
  maxWidth?: string;
}

export const SimpleTable = ({ balancesRequestTransactions }) => {
  const [tabledata, settabledata] = useState<TableValues[]>([]);

  useEffect(() => {
    settabledata(balancesRequestTransactions);
  }, [balancesRequestTransactions]);

  return (
    <Table $internal={true}>
      {tabledata.map(table => (
        <Column key={table.header} $maxWidth={table?.maxWidth}>
          <Header>{table.header}</Header>
          <Separator />
          {table.values.map((row, index) => (
            <>
              <Row
                onClick={() => {
                  if (table.onClick && table.ids) {
                    table.onClick(table.ids[index]);
                  }
                }}
                key={index}
                $blank={row === ''}
                $fullLength={table.fullLength}
              >
                <span>{row === '' ? '.' : row}</span>
              </Row>
              {index !== table.values.length - 1 && <Separator />}
            </>
          ))}
        </Column>
      ))}
    </Table>
  );
};
