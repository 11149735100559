import { Login } from 'components/general-components/header/styles';
import { BoxImage, ButtonBox, FoundDataText, FoundDataTitle, SliderButton } from 'components/landing-page/opening-screen/simulator/style';
import { FoundDataFill } from 'images/found-data/found-data-fill';
import ButtonVariants from 'model/enums/buttonVariants';
import { RoutePath } from 'model/enums/route-path';
import { FinancingSimpleSimulation } from 'model/financing';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { StyledScreenContainer } from 'themes/globalStyles';
import { StyledFoundData } from '../styled';
import { ORGANIZATION_TYPE } from 'config/constants';
import { OrganizationType } from 'model/enums/organization-type';
import CustomButton from 'components/custom-button';

interface DesktopProps {
  isAdmin?: boolean;
  clientId: number;
}

const Desktop: React.FC<DesktopProps> = ({ isAdmin, clientId }) => {
  const history = useHistory();
  const params = useParams<{ id: string }>();

  const { t } = useTranslation();
  const isPhysicalPerson = ORGANIZATION_TYPE === OrganizationType.PF;

  return (
    <StyledScreenContainer
      $customStyle={{
        height: 'calc(100vh - 68px)',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <StyledFoundData>
        <FoundDataTitle>{t('foundedData.foundedTitle', { label: isPhysicalPerson ? 'CPF' : 'CNPJ' })}</FoundDataTitle>
        <FoundDataText>{t('foundedData.foundedAdminText')}</FoundDataText>
        <BoxImage>
          <FoundDataFill />
        </BoxImage>

        <ButtonBox>
          <CustomButton
            onClick={() => history.push(RoutePath.HOME)}
            height={48}
            isInvertColor
            width="160px"
            justifyContent="center"
            margin="16px 0px 0px 0px"
          >
            {t('global.button.backStep')}
          </CustomButton>
          <SliderButton
            $customStyle={{ variant: ButtonVariants.DEFAULT, width: '160px' }}
            onClick={() => (clientId !== 0 ? history.push(`cadastros/${clientId}`) : history.push('cadastros'))}
          >
            {t('global.button.continueForProfile')}
          </SliderButton>
        </ButtonBox>
      </StyledFoundData>
    </StyledScreenContainer>
  );
};

export default Desktop;
