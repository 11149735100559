import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { Myaccounthistories } from 'model/enums/my-account';
import { useHistory } from 'react-router-dom';
import { StepDisplay } from '../StepDisplay';
import { SimpleTable } from '../SimpleTable';
import {
  Button,
  ButtonsContainer,
  ButtonsPage,
  Column,
  Container,
  Header,
  InputPage,
  Pagination,
  Row,
  Separator,
  SpanPagination,
  Table,
  StyledSeeMoreButton,
  TitleContainer,
  InfoContainer,
} from './styles';
import { PaginationBar } from '../PaginationBar';
import { PageableResponse } from 'model/pageable';
import _ from 'lodash';
import { useExtractsScreen } from '../../hooks';
import StringUtils from 'shared/util/string-utils';

export interface TableId {
  first: number;
  second?: number;
}
export interface TableValues {
  header: string;
  values: string[] | JSX.Element[] | (string | JSX.Element)[];
  ids?: TableId[];
  onClick?: (ids?: TableId) => void;
  fullLength?: boolean;
  maxWidth?: string;
  hide?: boolean;
}

interface TableListProps {
  page: string;
  setPage: string;
  totalPages: string;
  isLoadingTable: string;
  setIsLoadingTable: string;
  totalValue: number;
  balancesRequestTransactions: string;
  listResponse: string;
  selectedStep: Myaccounthistories;
  setSelectedStep: Dispatch<SetStateAction<Myaccounthistories>>;
}

export const TableListSeries = ({
  page,
  setPage,
  totalPages,
  isLoadingTable,
  setIsLoadingTable,
  balancesRequestTransactions,
  listResponse,
  selectedStep,
  setSelectedStep,
  totalValue,
}) => {
  const { t } = useTranslation();

  const setNewPage = _.debounce((newPage: number) => {
    if (newPage >= totalPages) {
      return setPage(page);
    }
    setPage(newPage);
  }, 500);

  return (
    <Container style={isLoadingTable ? { opacity: '0.5' } : {}}>
      <TitleContainer>
        {[Myaccounthistories.HISTORYOFTRANSACTIONS, Myaccounthistories.HISTORYOFBALANCEREQUEST].map((elem, index) => (
          <StepDisplay key={index} step={elem} selectedStep={selectedStep} setSelectedStep={setSelectedStep} stepName={elem} />
        ))}
      </TitleContainer>
      <InfoContainer>
        <div>{t('admin.myAccount.totalValue')}</div>
        <div>{`R$ ${StringUtils.maskAmount(totalValue)}`}</div>
      </InfoContainer>
      <SimpleTable balancesRequestTransactions={balancesRequestTransactions} />
      <PaginationBar
        page={page}
        setNewPage={setNewPage}
        setLoadingTable={setIsLoadingTable}
        loadingTable={isLoadingTable}
        noPagination={totalPages === 1}
        pageableResponse={listResponse}
      />
    </Container>
  );
};
