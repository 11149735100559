import { ButtonsContainer, IconContainer, StyledContainer } from './styles';
import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import { useState } from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import CustomButton from 'components/custom-button';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {
  documentName?: string;
  presignedUrl?: string;
  isShowModal: boolean;
  onCloseModal: () => void;
}

const ImagePreviewModalWithRotateButton = ({ presignedUrl, documentName, isShowModal, onCloseModal, t }: Props) => {
  const [rotation, setRotation] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(100);

  const rotateImage = () => {
    setZoom(100);
    setRotation(prevRotation => (prevRotation - 90) % 360);
  };

  const zoomIn = () => {
    setZoom(prevZoom => prevZoom + 10);
    setRotation(0);
  };

  const zoomOut = () => {
    setZoom(prevZoom => prevZoom - 10);
    setRotation(0);
  };

  return (
    <CustomModalTwoButtons
      isShowModal={isShowModal}
      onCloseModal={() => {
        onCloseModal();
        setRotation(0);
        setZoom(100);
      }}
      children={
        <>
          <StyledContainer $rotation={rotation} $zoom={zoom}>
            <ButtonsContainer>
              <CustomButton
                style={{ justifyContent: 'center', borderRadius: '5px' }}
                height={38}
                width="135px"
                icon={<ZoomInIcon />}
                onClick={() => zoomIn()}
                freeContent
                isInvertColor
              >
                {t('global.button.zoomIn')}
              </CustomButton>
              <CustomButton
                style={{ justifyContent: 'center', borderRadius: '5px' }}
                height={38}
                width="135px"
                icon={<ZoomOutIcon />}
                onClick={() => zoomOut()}
                freeContent
                isInvertColor
              >
                {t('global.button.zoomOut')}
              </CustomButton>
              <IconContainer onClick={() => rotateImage()}>
                <CachedIcon fontSize="large" />
              </IconContainer>
            </ButtonsContainer>
            <section>
              <img src={presignedUrl} alt={documentName} />
            </section>
          </StyledContainer>
        </>
      }
    />
  );
};

export default withTranslation()(ImagePreviewModalWithRotateButton);
