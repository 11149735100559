import { PageContainer } from '../dashboard/styles';
import { useHistory } from 'react-router-dom';
import { ButtonContainer, Container, Title } from './styles';
import Header from 'components/general-components/header';
import { HeaderVariants } from 'model/enums/header-variants';
import { FiChevronLeft } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useExtractsScreen } from './hooks';
import { TableListSeries } from './components/tables-list';
import { TitleHeader } from './components/header';
import { StyledButton } from 'components/custom-button/styles';

const ExtractsScreen: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { color } = useTheme();

  const {
    page,
    setPage,
    totalPages,
    setSearchName,
    isLoadingTable,
    setIsLoadingTable,
    balancesRequestTransactions,
    listResponse,
    filterStatusValues,
    cleanAppliedFilters,
    selectedStep,
    setSelectedStep,
    subsidiaryName,
    totalValue,
    idsCession,
    setIdsCession,
    deleteCheckeds,
    downloadPDF,
  } = useExtractsScreen();

  return (
    <PageContainer>
      <Header variant={HeaderVariants.SECONDARY} />
      <Container>
        <Title>
          <div className="firstTitle">
            <button
              onClick={() => {
                history.goBack();
              }}
            >
              <FiChevronLeft color={color.primaryColor} />
            </button>
            <span className="title">{`${t('admin.myAccount.extract')} ${subsidiaryName ? subsidiaryName : ''}`}</span>
          </div>
          <ButtonContainer>
            {idsCession.length > 0 && (
              <StyledButton onClick={e => deleteCheckeds()} $isInvertColor>
                {t('admin.myAccount.cleanCession')}
              </StyledButton>
            )}
            <StyledButton onClick={downloadPDF} disabled={idsCession.length < 1}>
              {t('admin.myAccount.generatePDF')}
            </StyledButton>
          </ButtonContainer>
        </Title>
        <TitleHeader
          selectedStep={selectedStep}
          setSearch={setSearchName}
          filterStatusValues={filterStatusValues}
          cleanFilters={cleanAppliedFilters}
        />
        <TableListSeries
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          isLoadingTable={isLoadingTable}
          setIsLoadingTable={setIsLoadingTable}
          balancesRequestTransactions={balancesRequestTransactions}
          listResponse={listResponse}
          selectedStep={selectedStep}
          setSelectedStep={setSelectedStep}
          totalValue={totalValue}
        />
      </Container>
    </PageContainer>
  );
};

export default ExtractsScreen;
