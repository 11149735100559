import { color } from 'shared/util/styled-component-utils';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  gap: 24px;
`;

export const GrayInfo = styled.div`
  display: flex;
  align-items: center;
  width: 276px;
  height: 65px;
  padding: 10px 12px 10px 12px;
  gap: 8px;
  border-radius: 8px;
  background-color: #f3f3f3;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  color: #1b1a1b;
`;

export const TitleContainer = styled.div`
  width: 100%;
  color: #656666;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  height: 40px;
  padding: 10px 8px 10px 8px;
  gap: 10px;
  opacity: 0px;
  margin-top: 20px;
  margin-bottom: 8px;
`;

export const ContentContainer = styled.div`
  position: relative;
  align-self: flex-start;
  margin-top: 24px;
`;

export const ScoreBarContainer = styled.div`
  width: 100%;
  height: 230px;
  margin-top: -38px;
`;

export const ColoredBar = styled.div`
  width: 100%;
  height: 40px;
  gap: 0px;
  border-radius: 8px 0px 0px 0px;
  display: flex;
  color: #656666;
`;

export const YellowColoredBar = styled.div`
  width: 8%;
  height: 40px;
  gap: 0px;
  /* border-radius: 8px; */
  background-color: #fddc69;
  margin: auto;
`;
export const GreenColoredBar = styled.div`
  width: 23%;
  height: 40px;
  gap: 0px;
  border-radius: 0px 8px 8px 0px;
  background-color: #42be65;
  margin: auto;
`;
export const RedColoredBar = styled.div`
  width: 23%;
  height: 40px;
  gap: 0px;
  border-radius: 8px 0px 0px 8px;
  background-color: #fa4d56;
  margin: auto;
`;

export const LightGreenColoredBar = styled.div`
  width: 23%;
  height: 40px;
  gap: 0px;
  /* border-radius: 8px; */
  background-color: #6fdc8c;
  margin: auto;
`;
export const LightRedColoredBar = styled.div`
  width: 23%;
  height: 40px;
  gap: 0px;
  /* border-radius: 8px; */
  background-color: #ff8389;
  margin: auto;
`;

export const PointerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, 25px);
`;

export const DescritionColorBar = styled.div`
  width: 23%;
  gap: 0px;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 14px;
`;

export const CenterDescritionColorBar = styled.div`
  width: 8%;
  gap: 0px;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 14px;
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InfoButton = styled.div`
  display: flex;
  font-family: Satoshi;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #4b6fdd;
  gap: 8px;
  cursor: pointer;
  padding-top: 24px;
  padding-bottom: 24px;
  width: 230px;
`;

export const InfoModalContainer = styled.div`
  display: flex;
  min-width: 600px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 12px;
  gap: 30px;
`;

export const DescritionModal = styled.div`
  gap: 0px;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding-left: 8px;
`;

export const RedVertivalBar = styled.div`
  min-height: 105px;
  background-color: #fa4d56;
  width: 40px;
  height: 100%;
  border-radius: 8px;
`;

export const YellowVertivalBar = styled.div`
  min-height: 52px;
  background-color: #fddc69;
  width: 40px;
  height: 100%;
  border-radius: 8px;
`;

export const GreenVertivalBar = styled.div`
  min-height: 105px;
  background-color: #42be65;
  width: 40px;
  height: 100%;
  border-radius: 8px;
`;

export const ColorContainer = styled.div`
  display: flex;
  justify-content: stretch;
  width: 100%;
`;

export const DescriptionContainer = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
