import MobileHeader from 'components/mobile-header';
import CustomButton from 'components/custom-button';
import {
  LoadingContainer,
  PageTitleSection,
  StyledBoxCameraContainer,
  StyledButton,
  StyledContainer,
  StyledInnerContainer,
} from '../styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import ContractContext from 'context/contract';
import contractService from 'services/contract-service';
import { RoutePath } from 'model/enums/route-path';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { ArrowContainer } from '../go-to-mobile/styles';
import { ReactComponent as ArrowBack } from 'images/arrow_back.svg';
import { documentUnicoCodes, IdentityDocumentTypes } from 'model/enums/identity-document-type';
import FaceMatch from 'model/enums/face-match';
import organizationsSystemStepsService from 'services/organization-system-steps-service';
import SystemStepCategory from 'model/enums/system-step-category';
import {
  UnicoConfig,
  SDKEnvironmentTypes,
  UnicoCheckBuilder,
  UnicoThemeBuilder,
  DocumentCameraTypes,
  CallbackCamera,
  ErrorPictureResponse,
  SuccessPictureResponse,
  SupportPictureResponse,
  DocumentCameraType,
} from 'unico-webframe';
import { useTheme } from 'styled-components';
import { UnicoDocumentCameraTypes } from 'model/enums/unico-documents-types';
import LoadingAnimation from 'components/loading-big-animation';
import {
  UNICO_CONFIG_HOST_INFO,
  UNICO_CONFIG_HOST_KEY,
  UNICO_CONFIG_HOST_NAME,
  UNICO_CONFIG_PROJECT_ID,
  UNICO_CONFIG_PROJECT_NUMBER,
} from 'config/constants';
import { IUnicoDocument, IUnicoLiveness } from 'model/liveness';
import { isIOS } from 'react-device-detect';
import { isDeviceIOS } from 'shared/util/utils';
import ScrollToTop from 'components/scroll-top';
import { IOSPhoto } from './styles';

const TakeDocument = ({ t }: WithTranslation) => {
  const history = useHistory();
  const theme = useTheme();

  const {
    signatureKey,
    personSignatureId,
    identityDocument,
    setIdentityDocument,
    contractResponse,
    setContractResponse,
    setErrorDescriptionModal,
  } = useContext(ContractContext);

  const [isShowButtons, setIsShowButtons] = useState<boolean>(false);

  const [picScreen, setPicScreen] = useState<string>('');
  const [encryptedString, setEncryptedString] = useState<string>('');

  const { isMobile, isTablet } = useResponsiveMobile();
  const [signatoryDocument, setSignatoryDocument] = useState<string>();

  const [orgStep, setOrgStep] = useState<SystemStepCategory[]>([]);

  const [preparedCamera, setPreparedCamera] = useState<any>({} as any);
  const [showBoxCamera, setShowBoxCamera] = useState(false);

  function resetComponentStates() {
    setPreparedCamera({} as any);
    setShowBoxCamera(false);
  }

  const urlPathModels = `${window.location.protocol}//${window.location.host}/models`;

  const unicoTheme = new UnicoThemeBuilder()
    .setColorSilhouetteSuccess('#69c62f')
    .setColorSilhouetteError('#D50000')
    .setColorSilhouetteNeutral('#fcfcfc')
    .setBackgroundColor('#e9e9e9')
    .setColorText('#ff2c2c')
    .setBackgroundColorComponents(theme.color.primaryColor)
    .setColorTextComponents('#dff1f5')
    .setBackgroundColorButtons(theme.color.primaryColor)
    .setColorTextButtons('#dff1f5')
    .setBackgroundColorBoxMessage('#fff')
    .setColorTextBoxMessage('#D50000')
    .setHtmlPopupLoading(`<div>Carregando...</div>`)
    .setColorProgressBar(theme.color.primaryColor)
    .build();

  const unicoCamera = new UnicoCheckBuilder()
    .setTheme(unicoTheme)
    .setModelsPath(urlPathModels)
    .setResourceDirectory('/resources')
    .setEnvironment(SDKEnvironmentTypes.PROD)
    .build();

  const config = new UnicoConfig()
    .setProjectNumber(UNICO_CONFIG_PROJECT_NUMBER)
    .setProjectId(UNICO_CONFIG_PROJECT_ID)
    .setMobileSdkAppId('')
    .setHostname(UNICO_CONFIG_HOST_NAME)
    .setHostInfo(UNICO_CONFIG_HOST_INFO)
    .setHostKey(UNICO_CONFIG_HOST_KEY);

  const sdkCallbacks: CallbackCamera = {
    on: {
      success: function (obj: SuccessPictureResponse) {
        resetComponentStates();

        setPicScreen(obj.base64);
        setEncryptedString(obj.encrypted);
        setIsShowButtons(true);
      },
      error: function (error: ErrorPictureResponse) {
        resetComponentStates();
        preperaDocumentWithIdentity();
      },
      support: function (error: SupportPictureResponse) {
        console.log('support', error);
        resetComponentStates();
      },
    },
  };

  const handleGetContract = async () => {
    const response = await contractService
      .getContract(`${signatureKey}`)
      .then(response => {
        if (response) {
          setContractResponse(response);
          setSignatoryDocument(response.contractSignatory?.signatory?.documentNumber);
          return response;
        }
      })
      .catch(() => {
        history.push(RoutePath.ERROR_ANALYSIS);
        return null;
      });

    return response;
  };

  const handleSendDocumentUnico = async () => {
    history.push(RoutePath.DOCUMENT_ANALYSIS);
    if (identityDocument && contractResponse?.uuid) {
      setIsShowButtons(false);
      const docData: IUnicoDocument = {
        type: identityDocument.replace(/\D/g, ''),
        image64: picScreen,
        encrypted: encryptedString,
      };
      contractService
        .sendDocumentUnico(contractResponse.uuid, docData)
        .then(response => {
          if (response.success && identityDocument === documentUnicoCodes.RG_FRENTE_NOVO) {
            setIdentityDocument(documentUnicoCodes.RG_VERSO_NOVO);
            history.push(RoutePath.TAKE_PHOTO_DOCUMENT);
          } else if (response.success && identityDocument === documentUnicoCodes.RG_FRENTE) {
            setIdentityDocument(documentUnicoCodes.RG_VERSO);
            history.push(RoutePath.TAKE_PHOTO_DOCUMENT);
          } else if (response.success) {
            setTimeout(() => {
              history.push(RoutePath.SIGNATURE_ANALYSIS);
            }, 2500);
            history.push(RoutePath.STEP_COMPLETED);
          } else if (response.success === false) {
            setErrorDescriptionModal(response.error.description);
            history.push(RoutePath.REGISTER_DOCUMENT);
          }
        })
        .catch(() => history.push(RoutePath.ERROR_ANALYSIS));
    }
  };

  const handleGetSystemSteps = () => {
    organizationsSystemStepsService
      .getSteps()
      .then(response => setOrgStep(response.map(systemSteps => systemSteps.systemStep.step)))
      .catch(() => history.push(RoutePath.ERROR_ANALYSIS));
  };

  const retakeDocumentPic = () => {
    setIsShowButtons(false);
    preperaDocumentWithIdentity();
  };

  useEffect(() => {
    if (!signatureKey) {
      return history.push(RoutePath.HOME);
    }
    if (signatureKey !== null) {
      if (!isMobile && !isTablet) {
        return history.push(`${RoutePath.QR_CODE}/${signatureKey}`);
      }
      handleGetContract();
      handleGetSystemSteps();
    }
  }, [signatureKey, isMobile, isTablet]);

  const prepareDocumentCamera = async (cameraType: DocumentCameraType) => {
    const { open } = await unicoCamera.prepareDocumentCamera(config, cameraType);

    setPreparedCamera({
      openCamera: open,
      isCameraReady: true,
      cameraName: ``,
      isUnicoCamera: true,
    });
  };

  useEffect(() => {
    preperaDocumentWithIdentity();
  }, []);

  const preperaDocumentWithIdentity = async () => {
    switch (identityDocument) {
      case documentUnicoCodes.RG_FRENTE:
        prepareDocumentCamera(DocumentCameraTypes.RG_FRENTE);
        break;
      case documentUnicoCodes.RG_VERSO:
        prepareDocumentCamera(DocumentCameraTypes.RG_VERSO);
        break;
      case documentUnicoCodes.RG_FRENTE_NOVO:
        prepareDocumentCamera(DocumentCameraTypes.RG_FRENTE_NOVO);
        break;
      case documentUnicoCodes.RG_VERSO_NOVO:
        prepareDocumentCamera(DocumentCameraTypes.RG_VERSO_NOVO);
        break;
      case documentUnicoCodes.CNH:
        prepareDocumentCamera(DocumentCameraTypes[UnicoDocumentCameraTypes.CNH]);
        break;
      case documentUnicoCodes.CTPS:
        prepareDocumentCamera(DocumentCameraTypes[UnicoDocumentCameraTypes.OTHERS](IdentityDocumentTypes.CTPS));
        break;
      case documentUnicoCodes.PASSAPORTE:
        prepareDocumentCamera(DocumentCameraTypes[UnicoDocumentCameraTypes.OTHERS](IdentityDocumentTypes.PASSAPORT));
        break;
      case undefined:
        history.push(`/assinar/${signatureKey}`);
    }
  };

  useEffect(() => {
    if (preparedCamera.isCameraReady) {
      if (preparedCamera.isUnicoCamera) {
        setShowBoxCamera(true);
      }
      preparedCamera.openCamera(sdkCallbacks);
    }
  }, [preparedCamera]);

  const renderIOSImage = useMemo(() => {
    const img = new Image();
    img.src = picScreen;
    let width = img.width;
    let height = img.height;

    const isPortrait = height > width;

    return <IOSPhoto $picScreen={picScreen} $isPortrait={isPortrait} />;
  }, [picScreen]);

  return (
    <StyledContainer style={{ height: '100vh' }}>
      <MobileHeader></MobileHeader>
      <ScrollToTop />
      <StyledInnerContainer>
        <PageTitleSection>
          <ArrowContainer>
            <ArrowBack onClick={() => history.push(RoutePath.REGISTER_DOCUMENT)} />
            <span>{t('letsSign.stepOfTotal', { step: '3' })}</span>
          </ArrowContainer>
          {!isShowButtons && (
            <>
              <p>{t('contract.takeDocument.satertingCamera')}</p>
              <span>{t('contract.takeDocument.waitStarting')}</span>
            </>
          )}
        </PageTitleSection>

        <StyledBoxCameraContainer style={{ display: showBoxCamera ? 'block' : 'none' }}>
          <div id="box-camera"></div>
        </StyledBoxCameraContainer>

        {isShowButtons && (
          <>
            {isIOS || isDeviceIOS() ? (
              renderIOSImage
            ) : (
              <img src={picScreen} style={{ maxWidth: '-webkit-fill-available', objectFit: 'contain' }} alt="foto do documento" />
            )}
            <StyledButton>
              <CustomButton isInvertColor className="button-mobile" onClick={() => retakeDocumentPic()}>
                {t('contract.takeDocument.takePicAgain')}
              </CustomButton>
              <CustomButton className="button-mobile" onClick={() => handleSendDocumentUnico()}>
                {t('global.button.continue')}
              </CustomButton>
            </StyledButton>
          </>
        )}
      </StyledInnerContainer>

      {!isShowButtons && (
        <LoadingContainer>
          <LoadingAnimation />
        </LoadingContainer>
      )}
      <div className="waiting-step-footer">{t('contract.footer')}</div>
    </StyledContainer>
  );
};

export default withTranslation()(TakeDocument);
