import { AxiosResponse } from 'axios';
import { api } from './api';
import { buildUrl } from 'shared/util/api-utils';
import FilterParameters from 'model/filter-parameters';
import { FilterOptions } from 'model/filter-options';

const analysisReportApi = () => {
  const generateReport = (filterParameters: FilterParameters): Promise<AxiosResponse<Blob>> => {
    const url = buildUrl('/analysis-report/', filterParameters);
    return api.get<Blob>(url);
  };

  const generatePartnerReport = (filterParameters: FilterParameters): Promise<AxiosResponse<Blob>> => {
    const url = buildUrl('/multtiplo-report', filterParameters);
    return api.get<Blob>(url, { responseType: 'blob' });
  };

  const generateBalanceReport = (filterParameters: FilterParameters): Promise<AxiosResponse<Blob>> => {
    const url = buildUrl('/multtiplo-report/balance', filterParameters);
    return api.get<Blob>(url, { responseType: 'blob' });
  };

  const getFilterOptions = (): Promise<AxiosResponse<FilterOptions>> => {
    return api.get<FilterOptions>(`/multtiplo-report/parameters`);
  };

  return {
    generateReport,
    generatePartnerReport,
    getFilterOptions,
    generateBalanceReport,
  };
};

export default analysisReportApi();
