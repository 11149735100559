import styled from 'styled-components';

interface IProps {
  $selected?: boolean;
}

export const StepCard = styled.div<IProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 5px;
  border-radius: 6px;
  flex: none;
  order: 0;
  flex-grow: 1;
  font-family: 'Satoshi';
  font-style: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #7f7f7f;
  cursor: pointer;
  user-select: none;
  width: 20%;

  ${props =>
    props.$selected && {
      color: props.theme.color.primaryColor,
      background: '#ffffff',
      fontWeight: '700',
      border: '1px solid #e5e7e7',
      boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.06)',
      borderRadius: '6px',
      transition: '500ms',
    }}
`;
