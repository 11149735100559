import React, { createContext, useState } from 'react';

const DEFAULT_VALUE = {
  registrationKey: '',
};

const PreRegisterContext = createContext<any>(DEFAULT_VALUE);

const PreRegisterContextProvider: React.FC<{ children: any }> = ({ children }) => {
  const [registrationKey, setRegistrationKey] = useState<string>(DEFAULT_VALUE.registrationKey);

  return <PreRegisterContext.Provider value={{ registrationKey, setRegistrationKey }}>{children}</PreRegisterContext.Provider>;
};

export { PreRegisterContextProvider };
export default PreRegisterContext;
