import { Client } from 'model/client';

export const unMaskedFieldsValues = (fields: Client) => {
  const newValues = { ...fields };

  if (newValues.physicalPerson) {
    newValues.physicalPerson.cpf = newValues.physicalPerson.cpf?.replace(/\W/g, '');
    newValues.physicalPerson.rg = newValues.physicalPerson.rg?.replace(/\W/g, '');
  }

  if (newValues.legalPerson) {
    newValues.legalPerson.cnpj = newValues.legalPerson.cnpj?.replace(/\W/g, '');

    if (newValues.legalPerson.partners) {
      newValues.legalPerson.partners.forEach(partner => {
        partner.cpf = partner.cpf?.replace(/\W/g, '');
        partner.rg = partner.rg?.replace(/\W/g, '');
        partner.income = Number(partner.income.toFixed(2));
        partner.address.zipcode = partner.address.zipcode?.replace(/\W/g, '');
      });
    }
  }

  if (newValues.address?.zipcode) {
    newValues.address.zipcode = newValues.address?.zipcode?.replace(/\W/g, '');
  }

  return newValues;
};

export const unMaskedCurrency = (value: string) => {
  return Number(value?.toString().replace(/\D/g, ''));
};
