import InputText from 'components/styled-input';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Container, ContainerInfoButton, ErrorSection, InputBox, StyledSection } from './styles';
import { Signatures } from 'model/signatures';
import StringUtils from 'shared/util/string-utils';
import { useState } from 'react';
import { ContentContainer, InfoButton } from '../../styles';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { useTheme } from 'styled-components';
import { AttachmentBox, CampContainer, CampName } from 'features/admin/clientData/components/RegisterDataDisplay/campDisplay/styles';
import { Attachment } from 'model/attachment';
import { ReactComponent as ClipIconSvg } from 'images/clip.svg';
import { RegistrationStatus } from 'model/enums/registration-status';
import { Status } from 'features/admin/dashboard/components/status';
import InputTextArea from 'components/styled-input-textarea';

interface IProps {
  signatureData: Signatures;
}

export const TabDocument = ({ signatureData }: IProps) => {
  const { t } = useTranslation();
  const [openFull, setopenFull] = useState(false);
  const { color } = useTheme();

  const methods = useForm({ mode: 'onSubmit' });

  return (
    <>
      <FormProvider {...methods}>
        <InputBox>
          <StyledSection>
            <ContentContainer>
              <CampName>{t('documents.identity.title')}</CampName>
              <Container>
                {signatureData.unicoProcess?.document?.imageFront && (
                  <div>
                    <CampContainer
                      onClick={() => {
                        window.open(signatureData.unicoProcess?.document?.imageFront?.presignedUrl, '_blank');
                      }}
                    >
                      <AttachmentBox>
                        <ClipIconSvg style={{ width: '16px', height: '16px' }} />
                        {(signatureData.unicoProcess?.document?.imageFront as Attachment)?.fileName}
                      </AttachmentBox>
                    </CampContainer>
                  </div>
                )}
                {signatureData.unicoProcess?.document?.imageBack && (
                  <div>
                    <CampContainer
                      onClick={() => {
                        window.open(signatureData.unicoProcess?.document?.imageBack?.presignedUrl, '_blank');
                      }}
                    >
                      <AttachmentBox>
                        <ClipIconSvg style={{ width: '16px', height: '16px' }} />
                        {(signatureData.unicoProcess?.document?.imageBack as Attachment)?.fileName}
                      </AttachmentBox>
                    </CampContainer>
                  </div>
                )}
              </Container>
              <Container>
                {signatureData?.unicoProcess?.processStatus?.codDocumentUnico != undefined && (
                  <div>
                    <CampName>{t('documents.identity.docSubmissionStatus')}</CampName>
                    <Status
                      big
                      text={
                        signatureData?.unicoProcess?.processStatus?.codDocumentUnico === 200
                          ? t(`enum.numberStatus.sendWithSuccess`)
                          : signatureData?.unicoProcess?.processStatus?.codDocumentUnico === 50016
                            ? t(`enum.unicoProcessStatus.problemType`)
                            : signatureData?.unicoProcess?.processStatus?.codDocumentUnico === 50019
                              ? t(`enum.unicoProcessStatus.photoLowQuality`)
                              : t(`enum.unicoProcessStatus.errorUnico`)
                      }
                      color={signatureData?.unicoProcess?.processStatus?.codDocumentUnico === 200 ? 'DONE' : 'PROBLEM'}
                    />
                  </div>
                )}
                {signatureData?.unicoProcess?.processStatus?.statusDocumentUnico != undefined && (
                  <div>
                    <CampName>{t('documents.identity.docSubmissionStatusUnico')}</CampName>
                    <Status
                      big
                      text={
                        signatureData?.unicoProcess?.processStatus?.statusDocumentUnico === 1
                          ? t(`enum.numberStatus.successType`)
                          : signatureData?.unicoProcess?.processStatus?.statusDocumentUnico === 0
                            ? t(`enum.numberStatus.failType`)
                            : t(`enum.numberStatus.off`)
                      }
                      color={
                        signatureData?.unicoProcess?.processStatus?.statusDocumentUnico === 1
                          ? RegistrationStatus.APPROVED
                          : signatureData?.unicoProcess?.processStatus?.statusDocumentUnico === 0
                            ? RegistrationStatus.REJECTED
                            : RegistrationStatus.IN_PROGRESS
                      }
                    />
                  </div>
                )}
              </Container>
            </ContentContainer>
          </StyledSection>
          {signatureData?.unicoProcess?.processStatus?.codDocumentUnico != undefined &&
            signatureData?.unicoProcess?.processStatus?.codDocumentUnico !== 200 && (
              <ErrorSection>
                <h1>{t('documents.codeAndDescription')}</h1>
                <div>
                  {`Código ${signatureData?.unicoProcess?.processStatus?.codDocumentUnico}. ${signatureData?.unicoProcess?.processStatus?.descDocumentUnico ?? ''}`}
                </div>
              </ErrorSection>
            )}

          {openFull && (
            <>
              <StyledSection>
                <div style={{ width: '100%', marginTop: '16px' }}>
                  <CampName>{t('documents.identity.docDetails')}</CampName>
                </div>
                <InputText
                  disabled
                  name="enhancedPersonName"
                  label={t('enums.faceOrOCR.name')}
                  defaultValue={signatureData.unicoProcess?.document?.data.name}
                />
                <InputText
                  disabled
                  name="birthDay"
                  label={t('enums.faceOrOCR.birthDay')}
                  defaultValue={
                    signatureData.unicoProcess?.document?.data.birthDate &&
                    StringUtils.dateFormatMask(signatureData.unicoProcess?.document?.data.birthDate.toLocaleString())
                  }
                />
                <InputText
                  disabled
                  name="placeOfBirth"
                  label={t('enums.faceOrOCR.placeOfBirth')}
                  defaultValue={signatureData.unicoProcess?.document?.data.placeOfBirth}
                />
                <InputText
                  disabled
                  name="rg"
                  label={t('enums.faceOrOCR.rg')}
                  defaultValue={signatureData.unicoProcess?.document?.data.rg}
                />
                <InputText
                  disabled
                  name="ExpeditionState"
                  label={t('enums.faceOrOCR.stateOfRegistration')}
                  defaultValue={signatureData.unicoProcess?.document?.data.expeditionState}
                />
                <InputText
                  disabled
                  name="ExpeditionCity"
                  label={t('enums.faceOrOCR.cityOfRegistration')}
                  defaultValue={signatureData.unicoProcess?.document?.data.expeditionCity}
                />
                <InputText
                  disabled
                  name="ExpeditionDate"
                  label={t('enums.faceOrOCR.dateOfRegistration')}
                  defaultValue={signatureData.unicoProcess?.document?.data.expeditionDate}
                />
                <InputText
                  disabled
                  name="ExpirationDate"
                  label={t('enums.faceOrOCR.dateOfValidation')}
                  defaultValue={signatureData.unicoProcess?.document?.data.expirationDate}
                />
                <InputText
                  disabled
                  name="MotherName"
                  label={t('enums.faceOrOCR.motherName')}
                  defaultValue={signatureData.unicoProcess?.document?.data.motherName}
                />
                <InputText
                  disabled
                  name="fatherName"
                  label={t('enums.faceOrOCR.fatherName')}
                  defaultValue={signatureData.unicoProcess?.document?.data.fatherName}
                />
                <InputText
                  disabled
                  name="category"
                  label={t('enums.faceOrOCR.category')}
                  defaultValue={signatureData.unicoProcess?.document?.data.category}
                />
                <InputText
                  disabled
                  name="firstLincenseDate"
                  label={t('enums.faceOrOCR.firstLincenseDate')}
                  defaultValue={signatureData.unicoProcess?.document?.data.firstLicenseDate}
                />
                <InputText
                  disabled
                  name="code"
                  label={t('enums.faceOrOCR.code')}
                  defaultValue={signatureData.unicoProcess?.document?.data.securityCode}
                />
                <InputText
                  disabled
                  name="renach"
                  label={t('enums.faceOrOCR.renach')}
                  defaultValue={signatureData.unicoProcess?.document?.data.renach}
                />
                <InputText
                  disabled
                  name="securityCode"
                  label={t('enums.faceOrOCR.securityCode')}
                  defaultValue={signatureData.unicoProcess?.document?.data.securityCode}
                />
                <InputText
                  disabled
                  name="mirrorNumber"
                  label={t('enums.faceOrOCR.mirrorNumber')}
                  defaultValue={signatureData.unicoProcess?.document?.data.mirrorNumber}
                />
                <InputText
                  disabled
                  name="CRM"
                  label={t('enums.faceOrOCR.CRM')}
                  defaultValue={signatureData.unicoProcess?.document?.data.crm}
                />
              </StyledSection>
              <div style={{ width: '100%' }}>
                <InputTextArea
                  disabled
                  defaultValue={signatureData.unicoProcess?.document?.data.observation}
                  width={'100%'}
                  label={t('enums.faceOrOCR.observation')}
                  name={'enhancedPersonAddress'}
                />
              </div>
            </>
          )}
          <ContainerInfoButton>
            <InfoButton onClick={() => setopenFull(!openFull)}>
              {!openFull ? (
                <>
                  <span>{t('admin.signatures.seeDetails')}</span>
                  <IoIosArrowDown color={color.primaryColor} />
                </>
              ) : (
                <>
                  <span>{t('admin.signatures.hideDetails')}</span>
                  <IoIosArrowUp color={color.primaryColor} />
                </>
              )}
            </InfoButton>
          </ContainerInfoButton>
        </InputBox>
      </FormProvider>
    </>
  );
};
