import { StyledInnerContainer, IconSuccessContainer } from './styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import MobileHeader from 'components/mobile-header';
import { ReactComponent as SucessIcon } from 'images/gif/sucess.svg';
import { PageTitleSection } from '../styles';
import LoadingAnimation from 'components/loading-big-animation';

const StepCompleted = ({ t }: WithTranslation) => {
  return (
    <>
      <MobileHeader />
      <StyledInnerContainer>
        <PageTitleSection>
          <>
            <p>{t('contract.waitingStep.stepCompleted.title')}</p>
            <span>{t('contract.waitingStep.stepCompleted.subtitle')}</span>
          </>
        </PageTitleSection>
        <IconSuccessContainer>
          <SucessIcon />
        </IconSuccessContainer>
        <div style={{ marginBottom: '28px' }}>
          <span>{t('contract.waitingStep.stepCompleted.processingNext')}</span>
          <div style={{ zoom: '0.4', marginTop: '28px' }}>
            <LoadingAnimation />
          </div>
        </div>
        <div className="waiting-step-footer">{t('contract.footer')}</div>
      </StyledInnerContainer>
    </>
  );
};

export default withTranslation()(StepCompleted);
