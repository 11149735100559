import styled, { css } from 'styled-components';

interface StyledInputWrapperProps {
  $isFocused: boolean;
  $isTouched: boolean;
  $isValid?: boolean;
  $hasError?: boolean;
  $hasValue?: boolean;
  $isWhite?: boolean;
  $disabled?: boolean;
}
const StyledInputWrapper = styled.div<StyledInputWrapperProps>`
  position: relative;
  display: flex;
  overflow: hidden;

  input {
    background: transparent;
    border: none;
    flex: 1 1 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: ${props => (props.$disabled ? '#7f7f7f' : '#333333')};
    margin-right: 30px;
  }

  input:focus {
    margin-right: 38px;
  }

  ${props => (props.$hasValue ? 'input ~ span.input__text--label,' : '')}
  input:focus ~ span.input__text--label {
    top: 12px;
    font-size: 0.75rem;
    color: #979797;
  }
`;

export default StyledInputWrapper;
