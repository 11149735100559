import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import {
  BaseModalContainer,
  ContentContainer,
  ContentContainerExtends,
  DarkModalContainer,
  InputContainer,
  Label,
  ModalTitle,
  StyledGrClose,
  Wrapper,
} from './styles';

interface AnalisysModalProps {
  status?: string;
  comment?: string;
  setShowModal: (action: boolean) => void;
}

export const AnalisysInfoModal = ({ status, comment, setShowModal }: AnalisysModalProps) => {
  const { t } = useTranslation();

  return (
    <DarkModalContainer>
      <BaseModalContainer>
        <Wrapper>
          <StyledGrClose onClick={() => setShowModal(false)} />
          <ModalTitle>{t('clientData.seeAnalysis')}</ModalTitle>
          <InputContainer>
            <Label>{t('admin.customerAnalysis.labels.action')}</Label>
            <ContentContainer>{status}</ContentContainer>
          </InputContainer>
          <InputContainer>
            <Label>{t('admin.customerAnalysis.labels.comment')}</Label>
            <ContentContainerExtends>{comment}</ContentContainerExtends>
          </InputContainer>
        </Wrapper>
      </BaseModalContainer>
    </DarkModalContainer>
  );
};
