import styled from 'styled-components';

export const StyledContainer = styled.div`
  height: 100vh;
  font-size: 16px;
  background-color: ${({ theme }) => theme.color.grayBackground};

  position: relative;
`;

export const StyledInnerContainer = styled.div`
  max-width: 962px;
  margin: 48px auto 100px auto;
  padding: 0 16px 100px;
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 32px;
  color: #000000;

  @media (max-width: 575.98px) {
    font-size: 20px;
  }
`;

export const StyledFooterMobile = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;

  width: 100%;
  background: #ffffff;
  box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.06);
  padding: 16px 0;
`;

export const StyledCardContainer = styled.div`
  width: 100%;
  min-height: 96px;

  padding: 16px;
  margin-left: auto;
  margin-bottom: 8px;
  border: ${({ theme }) => `1px solid ${theme.color.gray20}`};
  border-radius: 8px;
  font-size: 16px;
  color: #000000;
  text-align: left;
  background-color: #ffffff;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 991.98px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StyleCardInnerContainer = styled.div`
  width: 100%;
  margin-right: 10px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const StyledHeaderCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCardTitle = styled.span`
  font-weight: 700;
`;

export const StyledSubtitle = styled.span`
  font-weight: 600;
  line-height: 1.2;

  @media (max-width: 575.98px) {
    font-size: 14px;
  }
`;

export const StyledCardText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray19};
`;

export const StyledCardsContainerMobile = styled.div`
  max-height: 570px;
  margin-top: 15px;
  overflow-y: auto;
`;

export const StyleTextModalContainer = styled.div`
  max-width: 546px;

  text-align: center;
  font-weight: 400;
  color: ${({ theme }) => theme.color.gray19};

  @media (max-width: 575.98px) {
    font-size: 14px;
  }
`;
