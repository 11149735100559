import Slider from '@mui/material/Slider';
import styled from 'styled-components';

export const SliderBar = styled(Slider)(({ theme }) => ({
  height: 1,
  padding: '0px',

  '& .MuiSlider-thumb': {
    height: '24px',
    width: '24px',
    background: '#FFFFFF',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',
  },
  '& .MuiSlider-track': {
    opacity: 1,
    height: 1,
    color: theme.color.primaryColor,
  },
  '& .MuiSlider-rail': {
    color: theme.color.primaryColor,
    opacity: 1,
    height: 1,
  },
}));

export const SliderBox = styled.div`
  width: 100%;
  height: 48px;
  margin: 16px 0px 0px;
`;
