import styled from 'styled-components';

interface Props {
  $isSelected?: boolean;
}

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.color.grayBackground};
  height: 100vh;
  overflow: auto;
`;

export const TitleHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 48px;
  max-width: 70%;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 150%;

  > span {
    display: inline-block;
    font-weight: 700;
    margin-left: 16px;
  }

  > button {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  padding: 16px 24px 24px 24px;
  border: 1px solid ${({ theme }) => theme.color.borderGray};
  box-shadow: 0px 2px 12px ${({ theme }) => theme.color.shadow};
  border-radius: 8px;
  max-width: 70%;
  margin: 0 auto;
  margin-top: 32px;
`;

interface TabBoxProps {
  $isMobile?: boolean;
}
export const TabBox = styled.div<TabBoxProps>`
  background-color: ${({ theme }) => theme.color.grayBackground};
  padding: 4px;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: ${props => (props.$isMobile ? '16px 0px' : '32px 0px')};
`;

export const Tab = styled.button<Props>`
  background-color: ${({ theme, $isSelected }) => ($isSelected ? theme.color.white : 'transparent')};
  border: ${({ theme, $isSelected }) => ($isSelected ? `1px solid ${theme.color.gray20}` : 'none')};
  box-shadow: ${({ theme, $isSelected }) => ($isSelected ? `0px 2px 12px ${theme.color.shadow}` : 'none')};
  border-radius: 6px;
  padding: 8px;

  flex: 1;

  > span {
    font-style: normal;
    font-weight: ${({ $isSelected }) => ($isSelected ? '700' : '400')};
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme, $isSelected }) => ($isSelected ? theme.color.primaryColor : theme.color.gray18)};
  }
`;

export const TabContent = styled.div``;

export const Pagination = styled.div`
  max-width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
`;

export const InputPage = styled.div`
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: ${({ theme }) => theme.color.gray22};
    margin-right: 16px;
  }

  input + span {
    margin-left: 16px;
  }

  input {
    max-width: 40px;
    background-color: ${({ theme }) => theme.color.white};
    border: 1px solid ${({ theme }) => theme.color.gray23};
    border-radius: 6px;
    text-align: center;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: ${({ theme }) => theme.color.black0};

    :focus {
      outline: none;
    }

    ::placeholder {
      color: ${({ theme }) => theme.color.gray22};
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
`;

export const ButtonsPage = styled.div`
  button.left {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  button.right {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

export const Button = styled.button`
  border: 1px solid ${({ theme }) => theme.color.primaryColor};
  background-color: ${({ theme }) => theme.color.white};
  text-align: center;

  :hover {
    border-color: ${({ theme }) => theme.color.primaryColorDarker};
    background-color: ${({ theme }) => theme.color.grayBackground};
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > button {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    > span {
      margin-left: 8px;

      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      color: ${({ theme }) => theme.color.primaryColor};
    }
  }
`;

export const StyledLoadingContainer = styled.div`
  height: 24px;
  min-width: 135px;

  div {
    width: 100% !important;
  }

  svg {
    width: 24px;
    height: 24px;
    margin-top: -6px;
  }

  @media (max-width: 1080px) {
    min-width: 112px;
  }
`;

export const StyledButtonContainer = styled.div`
  margin-left: auto;
`;
