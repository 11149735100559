import styled, { css } from 'styled-components';

interface StyledProps {
  $rotation: number;
  $zoom: number;
}

export const StyledContainer = styled.div<StyledProps>`
  margin-top: 20px;
  height: 576px;

  img {
    height: 100%;
    object-fit: scale-down;
    transform-origin: ${({ $rotation }) => ($rotation !== 0 ? 'center' : 'top left')};
    transform: ${({ $zoom, $rotation }) => `scale(${$zoom / 100}) rotate(${$rotation}deg)`};
  }
  section {
    transform-origin: center center;
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
  }
`;

export const ButtonsContainer = styled.div`
  z-index: 10;
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  column-gap: 10px;
`;

export const IconContainer = styled.div``;
