import { Container, EllipsesContainer, EllipsesContainerChild } from './styles';
import { ReactComponent as EllipseIcon } from 'images/Ellipse.svg';

const LoadingAnimation = () => {
  return (
    <Container>
      <EllipsesContainer>
        <EllipsesContainerChild className="relative top-[38px] left-[35px]">
          <div className="dot-typing"></div>
          <EllipseIcon
            style={{
              position: 'relative',
              top: '-50px',
              left: '19px',
              minWidth: '98px',
              minHeight: '98px',
            }}
          />
        </EllipsesContainerChild>
      </EllipsesContainer>
    </Container>
  );
};

export default LoadingAnimation;
