import { CheckBoxFill } from 'images/checkbox-fill';
import { CheckBoxOut } from 'images/checkbox-outline';
import { useEffect, useState } from 'react';
import { Container } from './styles';

interface CustomCheckBoxProps {
  text: string;
  value: string;
  onHandleClick: (value: { value: string; check: boolean }) => void;
  checked?: boolean;
  reset?: boolean;
}

export const CustomCheckBox = ({ text, onHandleClick, value, checked, reset }: CustomCheckBoxProps) => {
  const [check, setCheck] = useState(checked ? checked : false);

  useEffect(() => {
    onHandleClick({ value, check });
  }, [check]);

  useEffect(() => {
    if (reset) {
      setCheck(false);
    }
  }, [reset]);

  return (
    <Container $isChecked={check} onClick={() => setCheck(!check)}>
      {check ? <CheckBoxFill /> : <CheckBoxOut />}
      <span>{text}</span>
    </Container>
  );
};
