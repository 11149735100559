import styled from 'styled-components';

export const EditContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const ButtonsContainer = styled.div`
  button {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
  }

  > button.edit {
    margin-right: 16px;
  }
`;

export const FormContainer = styled.form`
  margin-bottom: 40px;
  label > div {
    min-width: 336px;
  }
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  > label {
    min-width: 306px;
  }
`;

export const StyledAutocompleteContainer = styled.div`
  width: 306px;
`;

export const PaintSvg = styled.span`
  margin-right: 8px;
`;

export const StyledTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-top: 48px;
`;

export const Title = styled.div`
  width: auto;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-style: normal;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: ${({ theme }) => theme.color.gray21};
`;

export const BalancesContainer = styled.span`
  margin-right: 8px;
  width: 100%;
  max-height: 70px;
  display: flex;
  gap: 20px;
`;

export const BalanceLabel = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  min-width: 100px;
  height: 100%;
  color: var(--gray-dark-4, #656666);
  font-family: Satoshi;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
`;

export const ValueBalance = styled.span`
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #f3f3f3;
  color: var(--gray-dark-4, #656666);
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
`;
