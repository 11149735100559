import EnterCustomButton from 'components/enter-custom-button';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import InputText from 'components/styled-input';
import { ORGANIZATION_TYPE } from 'config/constants';
import Stroke from 'images/stroke.svg';
import { OrganizationType } from 'model/enums/organization-type';
import { Mask } from 'model/mask-types';
import { IRecoveryPassword } from 'model/recovery-password';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import authenticationService from 'services/authentication-service';
import StringUtils from 'shared/util/string-utils';
import {
  ThinBackground,
  ThinButtonContainer,
  ThinContainer,
  ThinHeader,
  ThinHeaderStroke,
  ThinInputContainer,
  ThinSubTitle,
  ThinTitle,
} from 'styled-components/thin-styles';
import * as yup from 'yup';

interface UserType {
  label: string;
  placeholder: string;
  mask: Mask;
}

const Recovery: React.FC = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const isAdmin = location.pathname.includes('admin');
  const [isLoading, setIsLoading] = useState(false);
  const schema = yup.object({
    login: yup.string().required(t('global.errorMessage.required')),
  });
  const isPhysicalPerson = ORGANIZATION_TYPE === OrganizationType.PF;

  const onHandleSubmit = (data: IRecoveryPassword) => {
    setIsLoading(true);
    const newData = { login: isAdmin ? data.login : StringUtils.removeNonNumbersFromMaskedValue(data.login) };

    authenticationService.requestRecoveryPassword(newData).then(res => {
      history.push('/login/redefinir-senha/enviado', {
        login: data.login,
      });
      setIsLoading(false);
    });
  };

  const getUserType = (): UserType => {
    if (isAdmin) {
      return {
        label: t('login.label.email'),
        placeholder: t('login.placeholder.email'),
        mask: Mask.DEFAULT,
      };
    } else if (!isPhysicalPerson) {
      return {
        label: t('login.label.cnpj'),
        placeholder: t('login.placeholder.cnpj'),
        mask: Mask.CNPJ,
      };
    }
    return {
      label: t('login.label.document'),
      placeholder: t('login.placeholder.cpf'),
      mask: Mask.CPF,
    };
  };

  const userType = getUserType();

  return (
    <ThinBackground>
      <ThinHeader onClick={() => history.goBack()}>
        <ThinHeaderStroke src={Stroke} />
        <span>{t('global.button.backStep')}</span>
      </ThinHeader>
      <ThinContainer>
        <ThinTitle>{t('login.recovery.title')}</ThinTitle>
        <ThinSubTitle>{t('login.recovery.subtitleAdmin', { label: userType.label })}</ThinSubTitle>
      </ThinContainer>
      <ReactHookFormProvider schema={schema} onSubmit={onHandleSubmit}>
        <ThinInputContainer>
          <InputText width={300} name="login" label={userType.label} placeholder={userType.placeholder} mask={userType.mask} />
        </ThinInputContainer>
        <ThinButtonContainer>
          <EnterCustomButton type="submit" width={300} text={t('global.button.sendEmail')} disabled={false} isLoading={isLoading} />
        </ThinButtonContainer>
      </ReactHookFormProvider>
    </ThinBackground>
  );
};

export default Recovery;
