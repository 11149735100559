export enum BalanceTransactionStatus {
  BLOCKED = 'BLOCKED',
  CANCELLED = 'CANCELLED',
  FINISHED = 'FINISHED',
}

export enum BalanceRequestStatus {
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  PROCESSING = 'PROCESSING',
  CANCELLED = 'CANCELLED',
  FINISHED = 'FINISHED',
  WAITING_TO_APPLY_AMOUNT = 'WAITING_TO_APPLY_AMOUNT',
}
