import { FormInputsContainer, Row } from 'components/edit-forms/styles';
import { Column } from 'components/register-forms-mobile/styles';
import { SearchSelectInput } from 'components/search-selected-input';
import InputText from 'components/styled-input';
import { TextInput } from 'components/text-input';
import { Mask } from 'model/enums/mask-types';
import { useEditForm } from 'provider/edit-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { genderType, nationalityType } from 'shared/util/select-utils';
import StringUtils from 'shared/util/string-utils';

export const ContainerInputsPersonalDataTopOne = props => {
  const { t } = useTranslation();
  const { initialClientData, isFirstForm, isLastForm, onClickBack, isLoading } = useEditForm();
  const { maritalStatus, getMaritalStatus } = useSelectLists();
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState('');

  useEffect(() => {
    getMaritalStatus();
  }, []);

  const renderMobile = () => {
    return (
      <Column>
        <InputText
          isRequired
          disabled={props.onlyView}
          name="name"
          label={t('register.inputs.name')}
          placeholder={t('register.placeholder.name')}
          defaultValue={initialClientData?.physicalPerson?.name}
        />
        <InputText
          isRequired
          disabled={props.onlyView}
          name="birth"
          label={t('register.inputs.birthDate')}
          placeholder={t('register.placeholder.birthDate')}
          maxLength={10}
          mask={Mask.BIRTHDAY}
          defaultValue={
            initialClientData?.physicalPerson?.birth ? StringUtils.dateFormatMask(initialClientData?.physicalPerson?.birth.toString()) : ''
          }
        />
        <InputText
          isRequired
          disabled={props.onlyView}
          name="phone"
          label={t('register.inputs.phone')}
          placeholder={t('register.placeholder.phone')}
          mask={Mask.PHONE}
          maxLength={15}
          defaultValue={initialClientData?.phone}
        />
        <InputText
          isRequired
          disabled
          name="cpf"
          label={t('register.inputs.cpf')}
          placeholder={t('register.placeholder.cpf')}
          mask={Mask.CPF}
          maxLength={14}
          defaultValue={initialClientData?.physicalPerson?.cpf}
        />
        <InputText
          isRequired
          disabled={props.onlyView}
          name="rg"
          label={t('register.inputs.rg')}
          placeholder={t('register.placeholder.rg')}
          defaultValue={initialClientData?.physicalPerson?.rg}
          maxLength={20}
        />
        <InputText
          isRequired
          disabled={props.onlyView}
          name="email"
          label={t('register.inputs.email')}
          placeholder={t('register.placeholder.email')}
          defaultValue={initialClientData?.email}
        />
        <InputText
          isRequired
          disabled={props.onlyView}
          name="motherName"
          label={t('register.inputs.motherName')}
          placeholder={t('register.placeholder.motherName')}
          defaultValue={initialClientData?.physicalPerson?.motherName}
        />
        <TextInput name="genderType" label={t('register.inputs.genderType')} isRequired hasCustomInput>
          <SearchSelectInput
            disabled={props.onlyView}
            name="genderType"
            placeholder={t('register.placeholder.genderType')}
            options={genderType}
            defaultOptions={genderType}
            defaultValue={genderType.find(res => res.value === initialClientData?.physicalPerson?.genderType)?.label}
          />
        </TextInput>
        <TextInput name="maritalStatus" label={t('register.inputs.maritalStatus')} isRequired hasCustomInput>
          <SearchSelectInput
            name="maritalStatus"
            placeholder={t('register.placeholder.maritalStatus')}
            options={maritalStatus.map(occ => ({ label: occ.displayValue ?? '', value: occ.id.toString() }))}
            defaultValue={maritalStatus.find(occ => occ.id === initialClientData?.physicalPerson?.maritalStatus?.id)?.displayValue}
            handleChange={values => {
              setSelectedMaritalStatus(values.name);
            }}
            defaultOptions={maritalStatus.map(occ => ({ label: occ?.displayValue ?? '', value: occ.id.toString() }))}
          />
        </TextInput>
        <TextInput name="nationalityType" label={t('register.inputs.nationalityType')} isRequired hasCustomInput>
          <SearchSelectInput
            name="nationalityType"
            disabled={props.onlyView}
            placeholder={t('register.placeholder.nationalityType')}
            options={nationalityType}
            defaultOptions={nationalityType}
            defaultValue={nationalityType.find(res => res.value === initialClientData?.physicalPerson?.nationalityType)?.label}
          />
        </TextInput>
      </Column>
    );
  };
  return (
    <FormInputsContainer $visible={props.visible}>
      {props.mobile ? (
        renderMobile()
      ) : (
        <>
          <Row>
            <InputText
              isRequired
              disabled={props.onlyView}
              name="name"
              label={t('register.inputs.name')}
              placeholder={t('register.placeholder.name')}
              defaultValue={initialClientData?.physicalPerson?.name}
            />
            <InputText
              isRequired
              disabled={props.onlyView}
              name="birth"
              label={t('register.inputs.birthDate')}
              placeholder={t('register.placeholder.birthDate')}
              maxLength={10}
              mask={Mask.BIRTHDAY}
              defaultValue={
                initialClientData?.physicalPerson?.birth
                  ? StringUtils.dateFormatMask(initialClientData?.physicalPerson?.birth.toString())
                  : ''
              }
            />
            <InputText
              isRequired
              disabled={props.onlyView}
              name="phone"
              label={t('register.inputs.phone')}
              placeholder={t('register.placeholder.phone')}
              mask={Mask.PHONE}
              maxLength={15}
              defaultValue={initialClientData?.phone}
            />
          </Row>
          <Row>
            <InputText
              isRequired
              disabled
              name="cpf"
              label={t('register.inputs.cpf')}
              placeholder={t('register.placeholder.cpf')}
              mask={Mask.CPF}
              maxLength={14}
              defaultValue={initialClientData?.physicalPerson?.cpf}
            />
            <InputText
              isRequired
              disabled={props.onlyView}
              name="rg"
              label={t('register.inputs.rg')}
              placeholder={t('register.placeholder.rg')}
              defaultValue={initialClientData?.physicalPerson?.rg}
              maxLength={20}
            />
            <InputText
              isRequired
              disabled={props.onlyView}
              name="email"
              label={t('register.inputs.email')}
              placeholder={t('register.placeholder.email')}
              defaultValue={initialClientData?.email}
            />
          </Row>
          <Row>
            <InputText
              isRequired
              disabled={props.onlyView}
              name="motherName"
              label={t('register.inputs.motherName')}
              placeholder={t('register.placeholder.motherName')}
              defaultValue={initialClientData?.physicalPerson?.motherName}
            />
            <TextInput name="genderType" label={t('register.inputs.genderType')} isRequired hasCustomInput>
              <SearchSelectInput
                disabled={props.onlyView}
                name="genderType"
                placeholder={t('register.placeholder.genderType')}
                options={genderType}
                defaultOptions={genderType}
                defaultValue={genderType.find(res => res.value === initialClientData?.physicalPerson?.genderType)?.label}
              />
            </TextInput>
            <TextInput name="maritalStatus" label={t('register.inputs.maritalStatus')} isRequired hasCustomInput>
              <SearchSelectInput
                name="maritalStatus"
                placeholder={t('register.placeholder.maritalStatus')}
                options={maritalStatus.map(occ => ({ label: occ.displayValue ?? '', value: occ.id.toString() }))}
                defaultValue={maritalStatus.find(occ => occ.id === initialClientData?.physicalPerson?.maritalStatus?.id)?.displayValue}
                handleChange={values => {
                  setSelectedMaritalStatus(values.name);
                }}
                defaultOptions={maritalStatus.map(occ => ({ label: occ?.displayValue ?? '', value: occ.id.toString() }))}
              />
            </TextInput>
          </Row>
          <Row>
            <TextInput name="nationalityType" label={t('register.inputs.nationalityType')} isRequired hasCustomInput>
              <SearchSelectInput
                name="nationalityType"
                disabled={props.onlyView}
                placeholder={t('register.placeholder.nationalityType')}
                options={nationalityType}
                defaultOptions={nationalityType}
                defaultValue={nationalityType.find(res => res.value === initialClientData?.physicalPerson?.nationalityType)?.label}
              />
            </TextInput>
          </Row>
        </>
      )}
    </FormInputsContainer>
  );
};
