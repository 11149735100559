import { useTranslation } from 'react-i18next';
import { Container, StyledSeeMoreButton, TitleContainer } from './styles';
import { useState } from 'react';
import { StepDisplay } from '../StepDisplay';
import { SimpleTable } from '../SimpleTable';
import { FiChevronRight } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { Myaccounthistories } from 'model/enums/my-account';
import { useHistory } from 'react-router-dom';

export interface TableId {
  first: number;
  second?: number;
}
export interface TableValues {
  header: string;
  values: string[] | JSX.Element[] | (string | JSX.Element)[];
  ids?: TableId[];
  onClick?: (ids?: TableId) => void;
  fullLength?: boolean;
  maxWidth?: string;
  hide?: boolean;
}

interface TableListProps {
  loadingTable?: boolean;
  balancesTransactions: TableValues[];
  balancesRequestTransactions: TableValues[];
  subsidiaryId?: string;
}

export const TableListSeries = ({ loadingTable, balancesTransactions, balancesRequestTransactions, subsidiaryId }: TableListProps) => {
  const { color } = useTheme();
  const { t } = useTranslation();
  const [selectedStep, setSelectedStep] = useState<Myaccounthistories>(Myaccounthistories.HISTORYOFTRANSACTIONS);
  const history = useHistory();

  return (
    <Container style={loadingTable ? { opacity: '0.5' } : {}}>
      <TitleContainer>
        {[Myaccounthistories.HISTORYOFTRANSACTIONS, Myaccounthistories.HISTORYOFBALANCEREQUEST].map((elem, index) => (
          <StepDisplay key={index} step={elem} selectedStep={selectedStep} setSelectedStep={setSelectedStep} stepName={elem} />
        ))}
        <StyledSeeMoreButton
          onClick={() => {
            if (selectedStep === Myaccounthistories.HISTORYOFTRANSACTIONS) {
              history.push(`/admin/extratos/transacoes/${subsidiaryId ?? ''}`);
            } else {
              history.push(`/admin/extratos/solicitacoes-de-saldo/${subsidiaryId ?? ''}`);
            }
          }}
        >
          {t('enums.vadu.seeMore')}
          <FiChevronRight color={color.primaryColor} />
        </StyledSeeMoreButton>
      </TitleContainer>
      <SimpleTable
        tableValues={selectedStep === Myaccounthistories.HISTORYOFTRANSACTIONS ? balancesTransactions : balancesRequestTransactions}
      />
    </Container>
  );
};
