import * as S from './styles';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import CustomFilterCalendar from '../custom-filter-calendar';
import CustomSelectionFilter from '../custom-selection-filter';
import { ReactComponent as BriefcaseIconSvg } from 'images/briefcase.svg';
import { ReactComponent as LoaderIconSvg } from 'images/loader.svg';
import { ReactComponent as DownloadReportIconSvg } from 'images/white-download-report.svg';
import FilterTag from '../filter-tag';
import CustomButton from 'components/custom-button';
import analysisReportService from 'services/analysis-report-service';
import ReportFilterType from 'model/filter-parameters';
import { convertDateTimeFromServer } from 'shared/util/date-utils';
import { OrganizationSubsidiaries } from 'model/landing-page';
import { FinancingStatus } from 'model/enums/financing-status';
import { ContractStatus } from 'model/enums/contract-status';
import SearchInput from '../search-input';
import { IuguTranferStatus } from 'model/enums/iugu-transfer-status';
import { FileType, GenerateReportButtons } from '../generate-report-buttons';

export interface StatusTypeFilter {
  name: string;
  value: string;
}

interface FilterType {
  initialCreationDate: Date | null;
  finalCreationDate: Date | null;
  initialPaidDate: Date | null;
  finalPaidDate: Date | null;
  subsidiary: OrganizationSubsidiaries[] | null;
  financingStatus: StatusTypeFilter[] | null;
  contractStatus: StatusTypeFilter[] | null;
  userNames: StatusTypeFilter[] | null;
}

const filterInitialValues: FilterType = {
  initialCreationDate: null,
  finalCreationDate: null,
  initialPaidDate: null,
  finalPaidDate: null,
  subsidiary: null,
  financingStatus: null,
  contractStatus: null,
  userNames: null,
};

const HeaderPartner = ({ t }: WithTranslation) => {
  const [allFilters, setAllFilters] = useState<FilterType>(filterInitialValues);
  const [isShowClearFilterButton, setIsShowClearFilterButton] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<'csv' | 'excel' | false>(false);
  const [analysiseSubsidiaries, setAnalysiseSubsidiaries] = useState<StatusTypeFilter[]>([]);
  const [userNames, setUserNames] = useState<StatusTypeFilter[]>([]);
  const [filterContractStatusValues, setFilterContractStatusValues] = useState<StatusTypeFilter[]>([
    {
      name: t(`enum.contractStatus.${ContractStatus.NEW}`),
      value: ContractStatus.NEW,
    },
    {
      name: t(`enum.contractStatus.${ContractStatus.IN_PROGRESS}`),
      value: ContractStatus.IN_PROGRESS,
    },
    {
      name: t(`enum.contractStatus.${ContractStatus.FINISHED}`),
      value: ContractStatus.FINISHED,
    },
    {
      name: t(`enum.contractStatus.${ContractStatus.CANCELLED}`),
      value: ContractStatus.CANCELLED,
    },
  ]);
  const [filterIUGUTranferStatusValues, setFilterIUGUTranferStatusValues] = useState<StatusTypeFilter[]>([
    { name: t(`enum.iuguStatus.${IuguTranferStatus.PENDING}`), value: IuguTranferStatus.PENDING },
    { name: t(`enum.iuguStatus.${IuguTranferStatus.TRANSFER_CREDITED}`), value: IuguTranferStatus.TRANSFER_CREDITED },
    { name: t(`enum.iuguStatus.${IuguTranferStatus.PROCESSING_QUEUE}`), value: IuguTranferStatus.PROCESSING_QUEUE },
    { name: t(`enum.iuguStatus.${IuguTranferStatus.DONE}`), value: IuguTranferStatus.DONE },
    { name: t(`enum.iuguStatus.${IuguTranferStatus.REJECTED}`), value: IuguTranferStatus.REJECTED },
    { name: t(`enum.iuguStatus.${IuguTranferStatus.ERROR}`), value: IuguTranferStatus.ERROR },
  ]);

  useEffect(() => {
    const doYouHaveFilterFilled = Object.values(allFilters)
      .map(value => (value != null ? true : false))
      .includes(true);

    if (doYouHaveFilterFilled) {
      setIsShowClearFilterButton(true);
    } else {
      setIsShowClearFilterButton(false);
    }
  }, [allFilters]);

  useEffect(() => {
    analysisReportService.getFilterOptions().then(response => {
      let newContractStatusValue = response.data.filters.contractStatus.map(item => {
        if (item == null) {
          return {
            name: t(`enum.contractStatus.notGenerated`),
            value: ContractStatus.UNDEFINED,
          };
        }
        return {
          name: t(`enum.contractStatus.${item}`),
          value: item,
        };
      });
      setFilterContractStatusValues(newContractStatusValue as StatusTypeFilter[]);
      let newFilterIUGUTranferStatusValues = response.data.filters.paymentServiceStatus.map(item => {
        if (item == null) {
          return {
            name: t(`enum.iuguStatus.notGenerated`),
            value: IuguTranferStatus.UNDEFINED,
          };
        }
        return {
          name: t(`enum.iuguStatus.${item?.toUpperCase()}`),
          value: item?.toUpperCase(),
        };
      });
      setFilterIUGUTranferStatusValues(newFilterIUGUTranferStatusValues as StatusTypeFilter[]);
      let newSubsidiaries = response.data.filters.subsidiary
        .filter(item => !!item)
        .map(item => {
          return {
            name: item,
            value: item,
          };
        });
      setAnalysiseSubsidiaries(newSubsidiaries as StatusTypeFilter[]);
      setUserNames(
        response.data.filters.userNames.map(item => {
          if (item == null) {
            return {
              name: t(`enum.iuguStatus.noUser`),
              value: IuguTranferStatus.UNDEFINED,
            };
          }
          return {
            name: item,
            value: item,
          };
        })
      );
    });
  }, []);

  const handleGenerateReport = (fileType: FileType) => {
    setIsLoading(fileType);
    const filters: ReportFilterType = {};

    if (allFilters?.initialCreationDate != null) {
      filters['financing.created_date.start'] = convertDateTimeFromServer(allFilters.initialCreationDate);
    }

    if (allFilters?.finalCreationDate != null) {
      filters['financing.created_date.end'] = convertDateTimeFromServer(allFilters.finalCreationDate);
    }

    if (allFilters?.initialPaidDate != null) {
      filters['financing.is_paid_date.start'] = convertDateTimeFromServer(allFilters.initialPaidDate);
    }

    if (allFilters?.finalPaidDate != null) {
      filters['financing.is_paid_date.end'] = convertDateTimeFromServer(allFilters.finalPaidDate);
    }

    if (allFilters?.subsidiary != null && allFilters.subsidiary.length > 0) {
      filters['organizationSubsidiary.name'] = allFilters.subsidiary.map(value => value.name);
    }

    if (allFilters?.financingStatus != null && allFilters.financingStatus.length > 0) {
      filters['iuguTransfer.status'] = allFilters.financingStatus.map(value => value.value);
    }

    if (allFilters?.contractStatus != null && allFilters.contractStatus.length > 0) {
      filters['contract.status'] = allFilters.contractStatus.map(value => value.value);
    }

    if (allFilters?.userNames != null && allFilters.userNames.length > 0) {
      filters['user.firstName'] = allFilters.userNames.map(value => value.value);
    }

    filters['fileType'] = fileType;

    analysisReportService
      .generatePartnerReport(filters)
      .then(response => {
        if (fileType === 'csv') {
          generateCsvFile(response);
        } else {
          generateExcelFile(response);
        }
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const generateReportName = (reportName?: string) => {
    return (reportName ?? 'report') + new Date().toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  const generateCsvFile = response => {
    const file = new Blob([response.data], { type: 'text/csv' });
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(file);
    a.download = generateReportName('relatorio-parceiros');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const generateExcelFile = response => {
    const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(file);
    a.download = generateReportName('relatorio-parceiros');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const cleanFilters = () => {
    setAllFilters(filterInitialValues);
    setIsShowClearFilterButton(false);
  };

  return (
    <>
      <S.HeaderContainer>
        <S.Title>{t('admin.dashboard.reportPartner')}</S.Title>
        <GenerateReportButtons handleGenerateReport={handleGenerateReport} isLoading={isLoading} />
      </S.HeaderContainer>

      <S.FilterContainer>
        <S.FilterContent>
          <CustomFilterCalendar
            placeholder={t('reportScreen.filter.initialCreationDate')}
            onChangeValue={date => setAllFilters({ ...allFilters, initialCreationDate: date })}
            isActive={allFilters?.initialCreationDate != null}
            date={allFilters.initialCreationDate}
            onCleanFilter={() => setAllFilters({ ...allFilters, initialCreationDate: null })}
          />
          <CustomFilterCalendar
            placeholder={t('reportScreen.filter.finalCreationDate')}
            onChangeValue={date => setAllFilters({ ...allFilters, finalCreationDate: date })}
            isActive={allFilters?.finalCreationDate != null}
            date={allFilters.finalCreationDate}
            onCleanFilter={() => setAllFilters({ ...allFilters, finalCreationDate: null })}
          />
          <CustomFilterCalendar
            placeholder={t('reportScreen.filter.initialPaymentDate')}
            onChangeValue={date => setAllFilters({ ...allFilters, initialPaidDate: date })}
            isActive={allFilters?.initialPaidDate != null}
            date={allFilters.initialPaidDate}
            onCleanFilter={() => setAllFilters({ ...allFilters, initialPaidDate: null })}
          />
          <CustomFilterCalendar
            placeholder={t('reportScreen.filter.finalPaymentDate')}
            onChangeValue={date => setAllFilters({ ...allFilters, finalPaidDate: date })}
            isActive={allFilters?.finalPaidDate != null}
            date={allFilters.finalPaidDate}
            onCleanFilter={() => setAllFilters({ ...allFilters, finalPaidDate: null })}
          />

          {analysiseSubsidiaries.length > 0 && (
            <CustomSelectionFilter
              icon={<BriefcaseIconSvg />}
              placeholder={t('admin.dashboard.branch')}
              filterData={analysiseSubsidiaries}
              keyDisplay="name"
              listSelectedItems={(subsidiaries: OrganizationSubsidiaries[] | null) =>
                setAllFilters({ ...allFilters, subsidiary: subsidiaries })
              }
              onCleanFilter={() => setAllFilters({ ...allFilters, subsidiary: null })}
              isActive={allFilters?.subsidiary != null && allFilters.subsidiary.length > 0}
            />
          )}
          {userNames.length > 0 && (
            <CustomSelectionFilter
              // todo falta API setSearch={}
              icon={<BriefcaseIconSvg />}
              placeholder={t('admin.dashboard.user')}
              filterData={userNames}
              keyDisplay="name"
              listSelectedItems={(users: StatusTypeFilter[] | null) => {
                setAllFilters({ ...allFilters, userNames: users });
              }}
              onCleanFilter={() => setAllFilters({ ...allFilters, userNames: null })}
              isActive={allFilters?.userNames != null && allFilters.userNames.length > 0}
            />
          )}
          <CustomSelectionFilter
            icon={<LoaderIconSvg />}
            placeholder={t('reportScreen.filter.IUGUTranferStatus')}
            filterData={filterIUGUTranferStatusValues}
            keyDisplay="name"
            listSelectedItems={(status: StatusTypeFilter[] | null) => setAllFilters({ ...allFilters, financingStatus: status })}
            onCleanFilter={() => setAllFilters({ ...allFilters, financingStatus: null })}
            isActive={allFilters?.financingStatus != null && allFilters.financingStatus.length > 0}
          />
          <CustomSelectionFilter
            icon={<LoaderIconSvg />}
            placeholder={t('reportScreen.filter.contractStatus')}
            filterData={filterContractStatusValues}
            keyDisplay="name"
            listSelectedItems={(status: StatusTypeFilter[] | null) => setAllFilters({ ...allFilters, contractStatus: status })}
            onCleanFilter={() => setAllFilters({ ...allFilters, contractStatus: null })}
            isActive={allFilters?.contractStatus != null && allFilters.contractStatus.length > 0}
          />
        </S.FilterContent>
      </S.FilterContainer>

      <S.FilterTagContainer>
        <S.FilterTagInnerContainer>
          {Object.keys(allFilters).map((value, index) => {
            if (allFilters[value] != null) {
              if (Array.isArray(allFilters[value])) {
                const isArrayOfObjects = allFilters[value].every(item => Object.prototype.hasOwnProperty.call(item, 'name'));

                if (isArrayOfObjects && allFilters[value].length > 0) {
                  const namesArray = allFilters[value].map(item => item.name);

                  return (
                    <FilterTag
                      key={`tag-${index}`}
                      text={t(`reportScreen.filter.tag.${value}`, {
                        value: namesArray.join(', '),
                      })}
                      onClick={() => setAllFilters({ ...allFilters, [value]: null })}
                    />
                  );
                } else {
                  return <></>;
                }
              }

              return (
                <FilterTag
                  key={`tag-${index}`}
                  text={t(`reportScreen.filter.tag.${value}`, {
                    value:
                      typeof allFilters[value] === 'object' && allFilters[value].toLocaleDateString
                        ? allFilters[value].toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })
                        : allFilters[value],
                  })}
                  onClick={() => setAllFilters({ ...allFilters, [value]: null })}
                />
              );
            }
          })}
        </S.FilterTagInnerContainer>
        {isShowClearFilterButton && <S.Button onClick={() => cleanFilters()}>{t('reportScreen.filter.clearFilters')}</S.Button>}
      </S.FilterTagContainer>
    </>
  );
};

export default withTranslation()(HeaderPartner);
