import { SVGProps } from 'react';
import theme from '../themes/default';

export const Copy = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Edit/copy" clip-path="url(#clip0_1893_1315)">
      <g id="Vector (Stroke)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.16732 8.33301C8.70708 8.33301 8.33398 8.7061 8.33398 9.16634V16.6663C8.33398 17.1266 8.70708 17.4997 9.16732 17.4997H16.6673C17.1276 17.4997 17.5006 17.1266 17.5006 16.6663V9.16634C17.5006 8.7061 17.1276 8.33301 16.6673 8.33301H9.16732ZM6.66732 9.16634C6.66732 7.78563 7.78661 6.66634 9.16732 6.66634H16.6673C18.048 6.66634 19.1673 7.78563 19.1673 9.16634V16.6663C19.1673 18.0471 18.048 19.1663 16.6673 19.1663H9.16732C7.78661 19.1663 6.66732 18.0471 6.66732 16.6663V9.16634Z"
          fill={theme.color.primaryColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.33398 2.49967C3.11297 2.49967 2.90101 2.58747 2.74473 2.74375C2.58845 2.90003 2.50065 3.11199 2.50065 3.33301V10.833C2.50065 11.054 2.58845 11.266 2.74473 11.4223C2.90101 11.5785 3.11297 11.6663 3.33398 11.6663H4.16732C4.62755 11.6663 5.00065 12.0394 5.00065 12.4997C5.00065 12.9599 4.62755 13.333 4.16732 13.333H3.33398C2.67094 13.333 2.03506 13.0696 1.56622 12.6008C1.09738 12.1319 0.833984 11.496 0.833984 10.833V3.33301C0.833984 2.66997 1.09738 2.03408 1.56622 1.56524C2.03506 1.0964 2.67094 0.833008 3.33398 0.833008H10.834C11.497 0.833008 12.1329 1.0964 12.6017 1.56524C13.0706 2.03408 13.334 2.66997 13.334 3.33301V4.16634C13.334 4.62658 12.9609 4.99967 12.5007 4.99967C12.0404 4.99967 11.6673 4.62658 11.6673 4.16634V3.33301C11.6673 3.11199 11.5795 2.90003 11.4232 2.74375C11.267 2.58747 11.055 2.49967 10.834 2.49967H3.33398Z"
          fill={theme.color.primaryColor}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1893_1315">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
