import { ORGANIZATION_NAME } from 'config/constants';
import Header from './components/header';
import { OrganizationName } from 'model/enums/organization-name';
import HeaderPartner from './components/header-partner';
import HeaderBalances from './components/header-balances';

const Report = () => {
  if (ORGANIZATION_NAME === OrganizationName.MULTTIPLO) {
    return (
      <>
        <HeaderPartner />
        <HeaderBalances />
      </>
    );
  }

  return (
    <>
      <Header />
    </>
  );
};

export default Report;
