import flexibleStructure from '../../images/multtiplo/flexibleStructure.png';
import formBackground from '../../images/multtiplo/formBackground.png';
import formBackgroundMobile from '../../images/multtiplo/formBackgroundMobile.png';
import formBackgroundTablet from '../../images/multtiplo/formBackgroundTablet.png';

import { ReactComponent as facebook } from '../../images/multtiplo/facebook.svg';
import { ReactComponent as instagram } from '../../images/multtiplo/instagram.svg';
import { ReactComponent as less } from '../../images/multtiplo/less.svg';
import { ReactComponent as plus } from '../../images/multtiplo/plus.svg';

import { ReactComponent as arrowBack } from '../../images/multtiplo/arrowBack.svg';
import { ReactComponent as banking } from '../../images/multtiplo/banking.svg';
import { ReactComponent as digitalAccount } from '../../images/multtiplo/digitalAccount.svg';
import { ReactComponent as issuanceOf } from '../../images/multtiplo/issuanceOf.svg';
import { ReactComponent as menu } from '../../images/multtiplo/menu.svg';
import { ReactComponent as payrollLoans } from '../../images/multtiplo/payrollLoans.svg';
import { ReactComponent as personalCredit } from '../../images/multtiplo/personalCredit.svg';
import { ReactComponent as propertyFinancing } from '../../images/multtiplo/propertyFinancing.svg';

export const MulttiploImgs = {
  formBackground,
  facebook,
  instagram,
  flexibleStructure,
  less,
  plus,
  banking,
  digitalAccount,
  payrollLoans,
  personalCredit,
  issuanceOf,
  propertyFinancing,
  arrowBack,
  menu,
  formBackgroundTablet,
  formBackgroundMobile,
};
