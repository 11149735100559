import { useTheme } from 'styled-components';
import { CircleContainer, PercentageContainer, StyledCircle, Svg } from './styles';

interface RadiusProgressBarProps {
  progress: number;
  text: string;
  score?: boolean;
  progressTwo?: number;
}
export const RadiusProgressBar = ({ progress, text, score, progressTwo }: RadiusProgressBarProps) => {
  const { color } = useTheme();
  const width = 250;

  const PI = 3.14;
  const R = width / 2 - (width / 10) * 2;
  const circumference = 2 * PI * R;
  const offset = circumference - (progress / 100) * circumference;
  const offsetTwo = offset - ((progressTwo ?? 0) / 100) * circumference;

  return (
    <CircleContainer>
      <PercentageContainer>
        {score ? <span className="percentage">{progress * 10}</span> : <span className="percentage">{progress}%</span>}
        <span className="text">{text}</span>
      </PercentageContainer>
      <Svg>
        <circle
          strokeWidth={30}
          fill="transparent"
          r={R}
          cx={width / 2}
          cy={width / 2}
          stroke={color.gray20}
          strokeDasharray={`${circumference} ${circumference}`}
        />

        {progressTwo && (
          <StyledCircle
            strokeWidth={30}
            fill={'transparent'}
            r={R}
            cx={width / 2}
            cy={width / 2}
            stroke={color.rejected}
            strokeLinecap={'butt'}
            strokeDasharray={`${circumference} ${circumference}`}
            strokeDashoffset={offsetTwo}
          />
        )}
        <StyledCircle
          strokeWidth={30}
          fill={'transparent'}
          r={R}
          cx={width / 2}
          cy={width / 2}
          stroke={color.primaryColor}
          strokeLinecap={'butt'}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
        />
      </Svg>
    </CircleContainer>
  );
};
