import styled from 'styled-components';

export const ContainerDesktop = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  @media (max-width: 1080px) {
    max-width: 100%;
    div {
      max-width: 100%;
    }
  }
`;
export const TitleDesktop = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
  color: #000000;
  margin-left: 20px;

  @media (max-width: 1080px) {
    font-size: 28px;
    padding: 8px;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 10px;
      width: 15px;
      cursor: pointer;
    }
  }
`;

export const EditButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  width: 170px;
  height: 48px;
  border: 1.5px solid #4b6fdd;
  border-radius: 24px;
  background-color: #fff;

  img {
    width: 20px;
    height: 20px;
  }
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #4b6fdd;
  }
`;
