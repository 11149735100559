import styled, { keyframes } from 'styled-components';

interface StyledProps {
  $animationDelay: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin-top: 88px;
  text-align: center;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.black0};
`;

export const Message = styled.div`
  max-width: 470px;
  margin: 0 auto;

  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.gray18};
`;

export const LoadingContainer = styled.div`
  margin-top: 15px;
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
    background-color: #525252;
  }
  25%, 75% {
    transform: translateY(-5px);
    background-color: #515151;
  }
  50% {
    transform: translateY(-10px);
    background-color: #777;
  }
`;

export const Dot = styled.div<StyledProps>`
  width: 24px;
  height: 24px;
  margin: 0 7px;
  border-radius: 50%;
  animation: ${bounce} 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
  display: inline-block;
  animation-delay: ${({ $animationDelay }) => $animationDelay};
`;
