import styled from 'styled-components';

export const Alignor = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SelectedContainer = styled.div``;
export const StyledOption = styled.div`
  font-style: normal;
  font-weight: 400;
  width: 90%;
  font-size: 16px;
  line-height: 190%;
  cursor: pointer;
  color: #4c4d4d;
  border-bottom: 1px solid #e5e7e7;
`;

export const StyledOptionsContainer = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 8px 16px 16px;
  gap: 8px;

  position: absolute;
  width: 390px;
  margin-top: 94px;
  margin-left: 7px;

  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  z-index: 20;
`;
interface IProps {
  $up?: boolean;
}

export const StyledLabel = styled.label<IProps>`
  position: absolute;
  margin-top: ${props => (props.$up ? '34px' : '54px')};
  margin-left: ${props => (props.$up ? '8px' : '10px')};
  transition: 200ms;
`;

export const SelectContainer = styled.div`
  width: 390px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  border: none;
  padding: 10px 10px 10px 0px;
  border-bottom: 1px solid #999999;
  margin-bottom: 35px;
  margin-left: 8px;
  margin-top: 44px;
  cursor: pointer;

  svg {
    fill: #656666;
    font-size: 20px;
  }
`;
