import Approved from 'components/simulator-data/aproved';
import ConfirmSimulation from 'components/simulator-data/confirm-simulation';
import SimulatorNotApproved from 'components/simulator-data/not-approved';
import ProcessingSimulator from 'components/simulator-data/processing';
import ContractScreen from 'features/admin/contractScreen';
import AdminAuthenticate from 'features/admin/login/authenticate';
import { PaymentScreen } from 'features/admin/paymentScreen';
import AppDownloadScreen from 'features/app-download-screen';
import AuthorizationScreen from 'features/client/authorization';
import { SelfieRoute } from 'features/client/selfie/routes';
import FoundedData from 'features/found-data';
import InactiveAccount from 'features/inactive-account';
import Authenticate from 'features/login/authenticate';
import Login from 'features/login/login';
import Logout from 'features/logout/logout';
import AddProcedure from 'features/my-procedures/add-procedure';
import MyProcedures from 'features/my-procedures/list-procedures';
import ProcedureDashboard from 'features/my-procedures/procedure-dashboard';
import OrganizationWithoutLimit from 'features/organization-without-limit';
import Profile from 'features/profile';
import EditProfile from 'features/profile/edit';
import Recovery from 'features/recovery';
import CreatePassword from 'features/recovery/create-password';
import RecoverySent from 'features/recovery/sent';
import Register from 'features/register';
import RegistrationAdjustments from 'features/register/adjustments';
import ConfirmRegistration from 'features/register/confirm';
import RegisterSent from 'features/register/sent';
import AddNewFinancing from 'features/simulator-result/add-new-financing';
import Dashboard from 'features/simulator-result/dashboard';
import MyContracts from 'features/simulator-result/my-contracts';
import MyCredits from 'features/simulator-result/my-credits';
import MyPayments from 'features/simulator-result/my-payments';
import NotActivateYourRegistration from 'features/simulator-result/registration-analysis/NotActivateYourRegistration';
import RegistryAdjustments from 'features/simulator-result/registration-analysis/RegistryAdjustments';
import WaitRegistrationValidation from 'features/simulator-result/registration-analysis/WaitRegistrationValidation';
import { RoutePath } from 'model/enums/route-path';
import { RegisterFormProvider } from 'provider/register-form';
import { SelectListsProvider } from 'provider/select-list';
import { Route, Switch } from 'react-router-dom';
import PageNotFound from 'shared/error/page-not-found';
import { AdminRoutes } from './admin';
import PrivateRoutes, { clientAccessLevel } from './private-routes';
import ScreenTermsOfUse from 'features/screen-terms-of-use';
import GoToMobile from 'features/contract/go-to-mobile';
import ViewContractToSign from 'features/contract/view-contract';
import LetsSign from 'features/contract/lets-sign';
import ConfirmDetails from 'features/contract/confirm-details';
import RegisterDocument from 'features/contract/register-document';
import DocumentInstructions from 'features/contract/document-instructions';
import ProofOfLifeInstructions from 'features/contract/proof-of-life-instructions';
import TakeDocument from 'features/contract/take-document';
import ProofOfLife from 'features/contract/proof-of-life';
import SelfieInstructions from 'features/contract/selfie-instructions';
import DocumentAnalysis from 'features/contract/waiting-step/DocumentAnalysis';
import OperationSucceed from 'features/contract/waiting-step/OperationSucceed';
import WaitNewLiberation from 'features/contract/waiting-step/WaitNewLiberation';
import SelfieAnalysis from 'features/contract/waiting-step/SelfieAnalysis';
import OperationCancelled from 'features/contract/waiting-step/OperationCancelled';
import ErrorAnalysis from 'features/contract/waiting-step/ErrorAnalysis';
import StepCompleted from 'features/contract/waiting-step/StepCompleted';
import ProofLifeAnalysis from 'features/contract/waiting-step/ProofLifeAnalysis';
import ExpiredLink from 'features/contract/waiting-step/ExpiredLink';
import ErrorPage from 'features/error-page';
import LandingPage from 'features/landing-page/custom-organization';
import AccessDenied from 'shared/error/access-denied';
import ProcessingData from 'features/contract/waiting-step/ProcessingData';

const AppRoutes = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.url} component={LandingPage} />
      <Route exact path={'/sem-acesso'} component={AccessDenied} />
      <Route exact path={RoutePath.ERROR} component={ErrorPage} />
      <Route exact path={'/D41D8CD98F00B204E9800998ECF8427E'} component={LandingPage} />
      <Route exact path={RoutePath.FOUNDED_DATA} component={FoundedData} />

      <Route path={'/qr-code/:signatureKey'} component={GoToMobile} />
      <Route path={'/assinar/:signatureKey'} component={ViewContractToSign} />
      <Route path={RoutePath.LETS_SIGN} component={LetsSign} />
      <Route path={RoutePath.CONFIRM_DATA} component={ConfirmDetails} />
      <Route path={RoutePath.REGISTER_DOCUMENT} component={RegisterDocument} />
      <Route path={RoutePath.DOCUMENT_SUBMISSION_INSTRUCTIONS} component={DocumentInstructions} />
      <Route path={RoutePath.TAKE_PHOTO_DOCUMENT} component={TakeDocument} />
      <Route path={RoutePath.PROOF_LIFE_ANALYSIS} component={ProofLifeAnalysis} />
      <Route path={RoutePath.DOCUMENT_ANALYSIS} component={DocumentAnalysis} />
      <Route path={RoutePath.SIGNATURE_SELFIE_ANALYSIS} component={SelfieAnalysis} />
      <Route path={RoutePath.SIGNATURE_ANALYSIS} component={ProcessingData} />

      <Route path={RoutePath.SIGNATURE_RELEASE} component={WaitNewLiberation} />

      <Route path={RoutePath.SIGNATURE_SUCCESS} component={OperationSucceed} />
      <Route path={RoutePath.SIGNATURE_CANCELED} component={OperationCancelled} />
      <Route path={RoutePath.ERROR_ANALYSIS} component={ErrorAnalysis} />
      <Route path={RoutePath.STEP_COMPLETED} component={StepCompleted} />
      <Route path={RoutePath.PROOF_LIFE_INSTRUCTIONS} component={ProofOfLifeInstructions} />
      <Route path={RoutePath.PROOF_LIFE} component={ProofOfLife} />
      <Route path={RoutePath.SELFIE_INSTRUCTIONS} component={SelfieInstructions} />
      <Route path={RoutePath.SUBSCRIPTION_EXPIRED} component={ExpiredLink} />

      <Route path={RoutePath.SIMULATOR_PROCESSING} component={ProcessingSimulator} />
      <Route path={RoutePath.SIMULATOR_FINISHING} component={ProcessingSimulator} />
      <Route exact path={RoutePath.CONFIRM_SIMULATION} component={ConfirmSimulation} />
      <Route exact path={RoutePath.SIMULATOR_APPROVED} component={Approved} />
      <Route exact path={RoutePath.SIMULATOR_REPROVED} component={SimulatorNotApproved} />
      <Route exact path={RoutePath.INACTIVE_ACCOUNT} component={InactiveAccount} />
      <Route exact path={RoutePath.ORG_WITHOUT_LIMIT} component={OrganizationWithoutLimit} />
      <Route exact path={'/login'} component={Login} />
      <Route exact path={'/login/authenticate'} component={Authenticate} />
      <Route exact path={'/login/redefinir-senha'} component={Recovery} />
      <Route exact path={'/login/redefinir-senha/enviado'} component={RecoverySent} />
      <Route exact path={'/redefinir-senha/:id'} component={CreatePassword} />
      <Route exact path={'/logout'} component={Logout} />

      <Route path="/analise-cadastro/status/reprovado" component={NotActivateYourRegistration} />
      <Route path="/analise-cadastro/status/ajustes" component={RegistryAdjustments} />

      <Route exact path={'/Termos_de_uso'} component={ScreenTermsOfUse} />

      <PrivateRoutes accessLevel={clientAccessLevel} path="/analise-cadastro/status/validacao" component={WaitRegistrationValidation} />

      <PrivateRoutes accessLevel={clientAccessLevel} path="/creditos" component={MyCredits} />
      <PrivateRoutes accessLevel={clientAccessLevel} path="/novo-financiamento" component={AddNewFinancing} />
      <PrivateRoutes exact accessLevel={clientAccessLevel} path="/meus-contratos" component={MyContracts} />
      <PrivateRoutes accessLevel={clientAccessLevel} path="/meus-contratos/:contractId" component={ContractScreen} />
      <Route exact path="/meus-pagamentos" component={MyPayments} />
      <Route exact path="/meus-pagamentos/:financingId" component={PaymentScreen} />

      <PrivateRoutes exact accessLevel={clientAccessLevel} path="/procedimentos" component={MyProcedures} />
      <PrivateRoutes accessLevel={clientAccessLevel} path="/procedimentos/novo-procedimento" component={AddProcedure} />
      <PrivateRoutes accessLevel={clientAccessLevel} path="/procedimentos/editar-procedimento" component={AddProcedure} />
      <PrivateRoutes accessLevel={clientAccessLevel} path="/procedimentos/dashboard" component={ProcedureDashboard} />

      <PrivateRoutes accessLevel={clientAccessLevel} path="/dashboard" component={Dashboard} />
      <PrivateRoutes accessLevel={clientAccessLevel} path="/termos-de-uso" component={AuthorizationScreen} />
      <Route exact path={'/cadastro/enviado'} component={RegisterSent} />
      <Route exact path={'/cadastro/ativar'} component={ConfirmRegistration} />
      <Route exact path={'/cadastro/ajustes'} component={RegistrationAdjustments} />

      <Route path={'/selfie'} component={SelfieRoute} />

      <Route path={'/store-code=:id'} component={AppDownloadScreen} />

      <Route path={'/admin/login'} component={AdminAuthenticate} />
      <Route path={'/admin/redefinir-senha'} component={Recovery} />
      <Route path={'/admin'} component={AdminRoutes} />
      <PrivateRoutes exact accessLevel={clientAccessLevel} path={'/perfil/editar'} component={EditProfile} />
      <PrivateRoutes exact accessLevel={clientAccessLevel} path={'/perfil'} component={Profile} />

      <SelectListsProvider>
        <RegisterFormProvider>
          <Route exact path={RoutePath.REGISTER_NO_KEY} component={Register} />
        </RegisterFormProvider>
      </SelectListsProvider>

      <Route path={'*'} component={PageNotFound} />
    </Switch>
  );
};

export default AppRoutes;
