import { ORGANIZATION_NAME } from 'config/constants';
import { useDefaultSendFinancingDataHandler } from './default';
import { OrganizationName } from 'model/enums/organization-name';
import { useAzureSendFinancingDataHandler } from './azure';
import { useMonjuaSendFinancingDataHandler } from './monjua';
import { FinancingSimpleSimulation } from 'model/financing';
import { SimulatorLimit } from 'context/simulator';
import { useEvolveSendFinancingDataHandler } from './evolve';
import { useCrefizSendFinancingDataHandler } from './crefiz';

export interface ProcessingSimulatorData {
  simulatorData?: FinancingSimpleSimulation;
  simulatorLimits?: SimulatorLimit;
  parameters: {
    isNewCredit: boolean;
    isAdmin: boolean;
  };
  redirect?: {
    approved?: boolean;
    confirmSimulation?: boolean;
    withoutLimit?: boolean;
  };
}

export interface ProcessingSimulatorHandler {
  execute: (data: ProcessingSimulatorData) => Promise<ProcessingSimulatorData>;
}

export const useSendFinancingData = () => {
  switch (ORGANIZATION_NAME) {
    case OrganizationName.AZURE:
      return useAzureSendFinancingDataHandler();
    case OrganizationName.MONJUA:
      return useMonjuaSendFinancingDataHandler();
    case OrganizationName.CREFIZ:
      return useCrefizSendFinancingDataHandler();
    default:
      return useDefaultSendFinancingDataHandler();
  }
};
