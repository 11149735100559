import styled from 'styled-components';

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  height: 56px;
  padding: 16px 0px;
  justify-content: space-between;
`;

export const SpanPagination = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray22};
  cursor: pointer;
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
`;

export const InputPage = styled.div`
  input + span {
    margin-left: 16px;
  }
  display: flex;
  align-items: center;

  .clicked {
    background: var(--primary-light, #eff6ff);
    color: var(--gray-dark-2, #333);
    text-align: center;
    font-family: Satoshi;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
  input {
    max-width: 40px;
    background-color: ${({ theme }) => theme.color.white};
    border: 1px solid ${({ theme }) => theme.color.gray23};
    border-radius: 6px;
    text-align: center;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: ${({ theme }) => theme.color.black0};

    :focus {
      outline: none;
    }

    ::placeholder {
      color: ${({ theme }) => theme.color.gray22};
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
`;

export const ButtonsPage = styled.div`
  display: flex;
  align-items: center;
  button.left {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  button.right {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

export const Button = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.color.white};
  text-align: center;
  display: flex;
  align-items: center;

  :hover {
    border-color: ${({ theme }) => theme.color.primaryColorDarker};
    background-color: ${({ theme }) => theme.color.grayBackground};
  }
`;
