import financingApi from 'api/financing-api';
import { AxiosError, AxiosResponse } from 'axios';
import i18n from 'config/i18n';
import { AdminAnalyze } from 'model/admin-analyze';
import { Attachment, AttachmentTypeRefund } from 'model/attachment';
import { AuthBiometric, Biometrics } from 'model/auth-biometric';
import { BankAccount } from 'model/bank-account';
import { CreditEngineRequest, CreditEngineType } from 'model/credit-engine';
import { ProcedureDashboard } from 'model/dashboard';
import FinancingPaymentType from 'model/enums/financingPaymentType';
import HttpStatus from 'model/enums/http-status';
import TypesBanks from 'model/enums/types-banks';
import {
  BankAccountInFinancing,
  Contract,
  ContractType,
  Financing,
  FinancingConfirmed,
  FinancingSketch,
  ISendGuarantorsFinancing,
  MakePaymentResponse,
  Payment,
  PaymentDetailsResponse,
  PaymentType,
  Procedure,
  StatusAuthenticationAndBiometrics,
  SubscriptionOption,
  TwoFactorAuthenticationStatus,
} from 'model/financing';
import { Installment } from 'model/installments';
import { PageableResponse } from 'model/pageable';
import { ProcedureRequest } from 'model/procedure';
import ProcedurePaymentStatusType from 'model/procedure-payment-status';
import { RequestProcedurePayment, ResponseProcedurePayment } from 'model/procedurePayment';
import toastUtils from 'shared/util/toast-utils';

const financingService = () => {
  const createFinancing = async (financingData: Financing): Promise<Financing> => {
    try {
      const result: AxiosResponse<Financing> = await financingApi.createFinancing(financingData);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      toastUtils.emitErrorToast(i18n.t('global.errorMessage.default'));

      console.error(response);
      return Promise.reject(response);
    }
  };

  const createFinancingByAdmin = async (financingData: Financing): Promise<Financing> => {
    try {
      const result: AxiosResponse<Financing> = await financingApi.createFinancingByAdmin(financingData);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;
      toastUtils.emitErrorToast(i18n.t('global.errorMessage.default'));

      console.error(response);
      return Promise.reject(response);
    }
  };

  const twoFactorAuthRequest = async (financingId: number): Promise<TwoFactorAuthenticationStatus> => {
    try {
      const result: AxiosResponse<TwoFactorAuthenticationStatus> = await financingApi.twoFactorAuthRequest(financingId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const biometricsRequest = async (financingId: number): Promise<Biometrics> => {
    try {
      const result: AxiosResponse<Biometrics> = await financingApi.biometricsRequest(financingId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.default'));
      console.error(response);
      return Promise.reject(response);
    }
  };

  const manuallyApproveBiometrics = async (financingId: number): Promise<void> => {
    try {
      const result: AxiosResponse<void> = await financingApi.manuallyApproveBiometrics(financingId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve();
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const createProcedure = async ({ choosedValue, medicalProcedure }: Procedure): Promise<Procedure> => {
    try {
      const result: AxiosResponse<Procedure> = await financingApi.createProcedure({ choosedValue, medicalProcedure });
      if (result.status === HttpStatus.CREATED && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getMyFinancing = async (): Promise<AxiosResponse<PageableResponse<Financing>>> => {
    try {
      const result: AxiosResponse<PageableResponse<Financing>> = await financingApi.getMyFinancing();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getFinancingById = async (id: number): Promise<Financing> => {
    try {
      const result: AxiosResponse<Financing> = await financingApi.getFinancingById(id);

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getClientFinancingsById = async (clientId: number): Promise<AxiosResponse<PageableResponse<Financing>>> => {
    try {
      const result: AxiosResponse<PageableResponse<Financing>> = await financingApi.getClientFinancingsById(clientId);

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getFinancingBankAccount = async (financingId: number): Promise<AxiosResponse<BankAccountInFinancing>> => {
    try {
      const result: AxiosResponse<BankAccountInFinancing> = await financingApi.getFinancingBankAccount(financingId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getFinancingContract = async (financingId: number): Promise<AxiosResponse<ContractType>> => {
    try {
      const result: AxiosResponse<ContractType> = await financingApi.getFinancingContract(financingId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getFinancingPayments = async (financingId: number): Promise<AxiosResponse<PaymentType>> => {
    try {
      const result: AxiosResponse<PaymentType> = await financingApi.getFinancingPayments(financingId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getIfFundingAlreadyConfirmed = async (financingId: number): Promise<AxiosResponse<FinancingConfirmed>> => {
    try {
      const result: AxiosResponse<FinancingConfirmed> = await financingApi.getIfFundingAlreadyConfirmed(financingId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getProcedurePaymentStatus = async (financingId: number): Promise<AxiosResponse<ProcedurePaymentStatusType>> => {
    try {
      const result: AxiosResponse<ProcedurePaymentStatusType> = await financingApi.getProcedurePaymentStatus(financingId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const addGuarantorsInFinancing = async (
    financingId: number,
    guarantors: ISendGuarantorsFinancing
  ): Promise<AxiosResponse<ISendGuarantorsFinancing>> => {
    try {
      const result: AxiosResponse<ISendGuarantorsFinancing> = await financingApi.addGuarantorsInFinancing(financingId, guarantors);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const addBankAccountInFinancing = async (financingId: number, bankAccountId: number): Promise<AxiosResponse<BankAccount>> => {
    try {
      const result: AxiosResponse<BankAccount> = await financingApi.addBankAccountInFinancing(financingId, bankAccountId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const confirmFinancing = async (financingId: number): Promise<AxiosResponse<Financing>> => {
    try {
      const result: AxiosResponse<Financing> = await financingApi.confirmFinancing(financingId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.default'));
      console.error(response);
      return Promise.reject(response);
    }
  };

  const getAllContractsFinancing = async (financingId: number): Promise<PageableResponse<Contract>> => {
    try {
      const result: AxiosResponse<PageableResponse<Contract>> = await financingApi.getAllContractsFinancing(financingId);
      if (result.status === HttpStatus.OK && result.data.content != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const updateAdminFinancing = async (financingId: number, financing: Financing): Promise<Financing> => {
    try {
      const res: AxiosResponse<Financing> = await financingApi.updateAdminFinancing(financingId, financing);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.default'));
      console.error(response);
      return Promise.reject(response);
    }
  };

  const adminAnalyzeFinancing = async (financingId: number, anlysisInfos: AdminAnalyze): Promise<Financing> => {
    try {
      const res: AxiosResponse<Financing> = await financingApi.adminAnalyzeFinancing(financingId, anlysisInfos);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const updateProcedure = async ({ choosedValue, medicalProcedure, id }: Procedure): Promise<Procedure> => {
    try {
      const res: AxiosResponse<Procedure> = await financingApi.updateProcedure({ choosedValue, medicalProcedure, id });
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const financingStatusToAdjusted = async (financingId: number): Promise<Financing> => {
    try {
      const res: AxiosResponse<Financing> = await financingApi.financingStatusToAdjusted(financingId);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getPaymentProgress = async (financingId: number): Promise<PaymentType> => {
    try {
      const res: AxiosResponse<PaymentType> = await financingApi.getPaymentProgress(financingId);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const verifyAuthenticationAndBiometrics = async (financingId: number): Promise<StatusAuthenticationAndBiometrics> => {
    try {
      const res: AxiosResponse<StatusAuthenticationAndBiometrics> = await financingApi.verifyAuthenticationAndBiometrics(financingId);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getAuthenticateAndBiometry = async (financingId: number): Promise<AuthBiometric> => {
    try {
      const res: AxiosResponse<AuthBiometric> = await financingApi.getAuthenticateAndBiometry(financingId);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const postCreditEngine = async (financingId: CreditEngineRequest): Promise<CreditEngineType> => {
    try {
      const res: AxiosResponse<CreditEngineType> = await financingApi.postCreditEngine(financingId);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getFinancingInstallments = async (
    financingId: number,
    size?: number,
    page?: number,
    status?: 'PAID' | 'PENDING'
  ): Promise<PageableResponse<Installment>> => {
    try {
      const result: AxiosResponse<PageableResponse<Installment>> = await financingApi.getFinancingInstallments(
        financingId,
        size,
        page,
        status
      );
      if (result.status === HttpStatus.OK && result.data.content != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const createFinancingSketch = async (sketch: FinancingSketch): Promise<Financing> => {
    try {
      const result: AxiosResponse<Financing> = await financingApi.createFinancingSketch(sketch);
      if (result.status === HttpStatus.CREATED && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const createProcedureAdmin = async (procedure: ProcedureRequest): Promise<ProcedureDashboard> => {
    try {
      const result: AxiosResponse<ProcedureDashboard> = await financingApi.createProcedureAdmin(procedure);
      if (result.status === HttpStatus.CREATED && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const approvePaymentManually = async (procedureId: number): Promise<ProcedureDashboard> => {
    try {
      const result: AxiosResponse<ProcedureDashboard> = await financingApi.approvePaymentManually(procedureId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const approveProcedureRefund = async (procedureId: number, attachment?: AttachmentTypeRefund): Promise<ProcedureDashboard> => {
    try {
      const result: AxiosResponse<ProcedureDashboard> = await financingApi.approveProcedureRefund(procedureId, attachment);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const updateProcedureAdmin = async (procedureId: number, procedure: ProcedureRequest): Promise<ProcedureDashboard> => {
    try {
      const result: AxiosResponse<ProcedureDashboard> = await financingApi.updateProcedureAdmin(procedureId, procedure);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const adminAnalyzeProcedure = async (procedureId: number, analyzeInfos: AdminAnalyze): Promise<Procedure> => {
    try {
      const res: AxiosResponse<Procedure> = await financingApi.adminAnalyzeProcedure(procedureId, analyzeInfos);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getBanks = async (): Promise<TypesBanks[]> => {
    try {
      const res: AxiosResponse<TypesBanks[]> = await financingApi.getBanks();
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getProcedurePayment = async (procedureId: number): Promise<ResponseProcedurePayment> => {
    try {
      const res: AxiosResponse<ResponseProcedurePayment> = await financingApi.getProcedurePayment(procedureId);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getListPaymentType = async (): Promise<FinancingPaymentType[]> => {
    try {
      const res: AxiosResponse<FinancingPaymentType[]> = await financingApi.getListPaymentType();
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const payProcedurePayment = async (procedureId: number, procedurePay: RequestProcedurePayment): Promise<ResponseProcedurePayment> => {
    try {
      const res: AxiosResponse<ResponseProcedurePayment> = await financingApi.payProcedurePayment(procedureId, procedurePay);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const cancelFinancing = async (financeId: number): Promise<Financing> => {
    try {
      const res: AxiosResponse<Financing> = await financingApi.cancelFinancing(financeId);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const confirmFinancingByAdmin = async (financeId: number): Promise<Financing> => {
    try {
      const res: AxiosResponse<Financing> = await financingApi.confirmFinancingByAdmin(financeId);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const confirmPayment = async (instalmentId: number): Promise<Financing> => {
    try {
      const res: AxiosResponse<Financing> = await financingApi.confirmPayment(instalmentId);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      toastUtils.emitErrorToast(i18n.t('admin.paymentScreen.toast.error'));
      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const makePayment = async (paymentData: Payment): Promise<MakePaymentResponse> => {
    try {
      const res: AxiosResponse<MakePaymentResponse> = await financingApi.makePayment(paymentData);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };
  const getPaymentDetails = async (id: number): Promise<PaymentDetailsResponse> => {
    try {
      const res: AxiosResponse<PaymentDetailsResponse> = await financingApi.getPaymentDetails(id);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getMeat = async (financeId: number): Promise<Attachment> => {
    try {
      const res: AxiosResponse<Attachment> = await financingApi.getMeat(financeId);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getSignatureOptions = async (): Promise<SubscriptionOption[]> => {
    try {
      const res: AxiosResponse<SubscriptionOption[]> = await financingApi.getSignatureOptions();
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const listPayment = async (id: number): Promise<MakePaymentResponse> => {
    try {
      const res: AxiosResponse<MakePaymentResponse> = await financingApi.listPayment(id);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const refoundPayment = async (refoundData: Payment, financingId?: number): Promise<ProcedureDashboard> => {
    try {
      const res: AxiosResponse<ProcedureDashboard> = await financingApi.refoundPayment(refoundData, financingId);
      if (res.status === HttpStatus.OK && res.data != null) {
        return Promise.resolve(res.data);
      }

      return Promise.reject({ status: res.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    createFinancing,
    createFinancingByAdmin,
    twoFactorAuthRequest,
    biometricsRequest,
    createProcedure,
    getMyFinancing,
    getFinancingContract,
    addGuarantorsInFinancing,
    addBankAccountInFinancing,
    getFinancingBankAccount,
    getFinancingById,
    getProcedurePaymentStatus,
    confirmFinancing,
    getFinancingPayments,
    getAllContractsFinancing,
    updateAdminFinancing,
    getIfFundingAlreadyConfirmed,
    getClientFinancingsById,
    adminAnalyzeFinancing,
    financingStatusToAdjusted,
    updateProcedure,
    getPaymentProgress,
    verifyAuthenticationAndBiometrics,
    getAuthenticateAndBiometry,
    postCreditEngine,
    getFinancingInstallments,
    createProcedureAdmin,
    approvePaymentManually,
    approveProcedureRefund,
    updateProcedureAdmin,
    adminAnalyzeProcedure,
    getBanks,
    getProcedurePayment,
    getListPaymentType,
    payProcedurePayment,
    cancelFinancing,
    confirmFinancingByAdmin,
    confirmPayment,
    makePayment,
    getPaymentDetails,
    getMeat,
    getSignatureOptions,
    listPayment,
    refoundPayment,
    createFinancingSketch,
    manuallyApproveBiometrics,
  };
};

export default financingService();
