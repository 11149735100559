import SystemStepCategory from 'model/enums/system-step-category';

export const editFormFields = {
  [SystemStepCategory.PERSONAL_DATA]: ['name', 'birth', 'phone', 'email', 'cpf', 'rg'],
  [SystemStepCategory.PERSONAL_DATA_ZM]: [
    'name',
    'birth',
    'genderType',
    'nationalityType',
    'cpf',
    'identityDocumentType',
    'identityDocument',
    'identityDocumentIssuingBody',
    'identityDocumentUfIssuingBody',
    'identityDocumentIssueDate',
    'email',
    'phone',
    'motherName',
    'maritalStatus',
  ],
  [SystemStepCategory.PERSONAL_DATA_MULTTIPLO]: ['name', 'birth', 'phone', 'email', 'cpf', 'rg'],
  [SystemStepCategory.PERSONAL_DATA_TOP_ONE]: [
    'name',
    'motherName',
    'genderType',
    'maritalStatus',
    'nationalityType',
    'birth',
    'phone',
    'email',
    'cpf',
    'rg',
  ],
  [SystemStepCategory.PERSONAL_DATA_MONJUA]: [
    'name',
    'motherName',
    'genderType',
    'maritalStatus',
    'birth',
    'phone',
    'spousePhone',
    'email',
    'cpf',
    'rg',
    'rgDateOfIssuance',
    'spouseBirth',
    'rgIssuingBody',
    'spouseName',
    'rgUfIssuingBody',
  ],
  [SystemStepCategory.ADDRESS_ZM]: [
    'zipcode',
    'street',
    'number',
    'complement',
    'district',
    'state',
    'city',
    'residenceType',
    'yearsOfResidence',
  ],
  [SystemStepCategory.ADDRESS]: ['zipcode', 'street', 'number', 'complement', 'district', 'state', 'city'],
  [SystemStepCategory.ADDRESS_EVOLVE]: ['zipcode', 'street', 'number', 'complement', 'district', 'state', 'city'],
  [SystemStepCategory.ADDRESS_TOP_ONE]: [
    'zipcode',
    'street',
    'number',
    'complement',
    'district',
    'state',
    'city',
    'residenceType',
    'mailingDestinationType',
  ],
  [SystemStepCategory.ADDRESS_MONJUA]: [
    'zipcode',
    'street',
    'number',
    'complement',
    'homePhone',
    'reference',
    'district',
    'state',
    'city',
    'residenceType',
    'yearsOfResidence',
  ],
  [SystemStepCategory.INCOME]: ['occupation', 'income', 'companyTimeInYears'],
  [SystemStepCategory.INCOME_EVOLVE]: ['occupation', 'income'],
  [SystemStepCategory.INCOME_TOP_ONE]: ['employmentBond', 'occupation', 'admissionDate', 'income'],
  [SystemStepCategory.INCOME_MONJUA]: [
    'occupation',
    'income',
    'companyTimeInYears',
    'company',
    'dependents',
    'ownCar',
    'scholarity',
    'ownMotorBike',
  ],
  [SystemStepCategory.PHYSICAL_PERSON_DOCUMENTS]: [],
  [SystemStepCategory.PHYSICAL_PERSON_IDENTIFICATION_DOCUMENT]: [],
  [SystemStepCategory.PHYSICAL_PERSON_DOCUMENTS_HCRED]: [],
  [SystemStepCategory.SELFIE]: [],
};
