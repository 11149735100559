import registerApi from 'api/register-api';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { Client } from 'model/client';
import { useRegisterForm } from 'provider/register-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { mailingDestinationType } from 'shared/util/select-utils';
import StringUtils from 'shared/util/string-utils';
import * as yup from 'yup';
import { FormContainer } from '../styles';
import { ContainerInputsAddress } from './inputs-address';

export const FormAddressZM = () => {
  const { onClickNext, initialClientData } = useRegisterForm();
  const { t } = useTranslation();
  const { getResidenceType, residenceType } = useSelectLists();

  useEffect(() => {
    getResidenceType();
  }, []);

  const schema = yup.object({
    zipcode: yup.string().min(9, t('global.errorMessage.zipcode')).required(t('global.errorMessage.required')),
    street: yup.string().required(t('global.errorMessage.required')),
    number: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-only-number', t('global.errorMessage.anyWord'), value => {
        if (value != null) {
          return /^\d+$/.test(value);
        }
        return false;
      }),
    complement: yup.string(),
    district: yup.string().required(t('global.errorMessage.required')),
    state: yup.string().required(t('global.errorMessage.required')),
    city: yup.string().required(t('global.errorMessage.required')),
    residenceType: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReference'), value => {
        if (value) {
          return residenceType.map(item => item.displayValue && item.displayValue.toLowerCase()).includes(value.toLowerCase());
        } else {
          return false;
        }
      }),
    yearsOfResidence: yup.string().required(t('global.errorMessage.required')),
  });

  const getStateId = async (uf: string) => {
    const res = await registerApi.getStates(7, 0, '', uf);
  };

  return (
    <FormContainer>
      <ReactHookFormProvider
        schema={schema}
        onSubmit={res => {
          getStateId(res.state);
          if (onClickNext) {
            const newRes = {
              homePhone: res.homePhone,
              address: {
                ...initialClientData?.address,
                zipcode: res.zipcode,
                street: res.street,
                number: res.number,
                complement: res.complement,
                district: res.district,
                reference: res.reference,
                residenceType: {
                  id: residenceType.find(type => type.displayValue === StringUtils.reverseDisplayValue(res.residenceType))?.id,
                },
                yearsOfResidence: Number(res.yearsOfResidence),
              },
            };

            onClickNext(newRes as Client);
          }
        }}
      >
        <ContainerInputsAddress />
      </ReactHookFormProvider>
    </FormContainer>
  );
};
