import React from 'react';
import ReactLoading from 'react-loading';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { Button } from './styles';

interface IEnterCustomButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  text: string;
  disabled?: boolean;
  isLoading: boolean;
  width?: number;
  maxWidth?: number;
  type?: 'button' | 'submit' | 'reset';
  textWidth?: number;
  fullSize?: boolean;
}

const EnterCustomButton: React.FC<IEnterCustomButtonProps> = props => {
  const isMobile = useResponsiveMobile().isTablet;
  return (
    <Button
      $fullSize={props.fullSize}
      $textWidth={props.textWidth}
      type={props.type}
      $width={props.width}
      disabled={props.disabled}
      onClick={props.onClick}
      $isMobile={isMobile}
      $maxWidth={props.maxWidth}
    >
      {props.isLoading ? <ReactLoading type="spinningBubbles" width="24px" height="24px" /> : <span>{props.text}</span>}
    </Button>
  );
};

export default EnterCustomButton;
