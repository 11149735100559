import { HttpRequestStatus } from 'model/enums/http-request-status';
import { Reducer } from 'redux';
import { LandingPageTemplateActionTypes, LandingPageTemplateState } from './types';
import { LandingPageType } from 'model/landing-page';

export const initialState: LandingPageTemplateState = {
  LandingPageTemplateStatus: HttpRequestStatus.NOOP,
  landingPageTemplate: undefined,
};

export interface LandingPageTemplateSuccessPayload {
  template: string;
}

const reducer: Reducer<LandingPageTemplateState> = (state = initialState, action) => {
  switch (action.type) {
    case LandingPageTemplateActionTypes.LANDING_PAGE_TEMPLATE_REQUEST: {
      return { ...state, LandingPageTemplateStatus: HttpRequestStatus.ON_GOING };
    }
    case LandingPageTemplateActionTypes.LANDING_PAGE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        LandingPageTemplateStatus: HttpRequestStatus.SUCCESS,
        landingPageTemplate: JSON.parse((action.payload as LandingPageTemplateSuccessPayload).template) as LandingPageType | undefined,
      };
    }
    case LandingPageTemplateActionTypes.LANDING_PAGE_TEMPLATE_ERROR: {
      return { ...state, LandingPageTemplateStatus: HttpRequestStatus.ERROR, errors: action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as landingPageTemplateReducer };
