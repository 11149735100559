import * as S from './styles';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import CustomFilterCalendar from '../custom-filter-calendar';
import CustomSelectionFilter from '../custom-selection-filter';
import { ReactComponent as BriefcaseIconSvg } from 'images/briefcase.svg';
import { ReactComponent as DownloadReportIconSvg } from 'images/white-download-report.svg';
import FilterTag from '../filter-tag';
import CustomButton from 'components/custom-button';
import analysisReportService from 'services/analysis-report-service';
import ReportFilterType from 'model/filter-parameters';
import { convertDateTimeFromServer } from 'shared/util/date-utils';
import landingPageService from 'services/landing-page-service';
import { OrganizationSubsidiaries } from 'model/landing-page';
import { StatusTypeFilter } from '../header-partner';
import { FileType, GenerateReportButtons } from '../generate-report-buttons';

interface FilterType {
  initialCreationDate: Date | null;
  finalCreationDate: Date | null;
  initialPixDate: Date | null;
  finalPixDate: Date | null;
  subsidiary: OrganizationSubsidiaries[] | null;
}

const filterInitialValues: FilterType = {
  initialCreationDate: null,
  finalCreationDate: null,
  initialPixDate: null,
  finalPixDate: null,
  subsidiary: null,
};

const HeaderBalances = ({ t }: WithTranslation) => {
  const [allFilters, setAllFilters] = useState<FilterType>(filterInitialValues);
  const [isShowClearFilterButton, setIsShowClearFilterButton] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<'csv' | 'excel' | false>(false);
  const [analysiseSubsidiaries, setAnalysiseSubsidiaries] = useState<StatusTypeFilter[]>([]);

  useEffect(() => {
    const doYouHaveFilterFilled = Object.values(allFilters)
      .map(value => (value != null ? true : false))
      .includes(true);

    if (doYouHaveFilterFilled) {
      setIsShowClearFilterButton(true);
    } else {
      setIsShowClearFilterButton(false);
    }
  }, [allFilters]);

  useEffect(() => {
    analysisReportService.getFilterOptions().then(response => {
      let newSubsidiaries = response.data.filters.subsidiary
        .filter(item => !!item)
        .map(item => {
          return {
            name: item,
            value: item,
          };
        });
      setAnalysiseSubsidiaries(newSubsidiaries as StatusTypeFilter[]);
    });
  }, []);

  const handleGenerateReport = (fileType: FileType) => {
    setIsLoading(fileType);

    const filters: ReportFilterType = {};

    if (allFilters?.initialCreationDate != null) {
      filters['balanceRequestTransaction.created_date.start'] = convertDateTimeFromServer(allFilters.initialCreationDate);
    }

    if (allFilters?.finalCreationDate != null) {
      filters['balanceRequestTransaction.created_date.end'] = convertDateTimeFromServer(allFilters.finalCreationDate);
    }

    if (allFilters?.initialPixDate != null) {
      filters['balanceRequestTransaction.pix_transfer_confirmation_date.start'] = convertDateTimeFromServer(allFilters.initialPixDate);
    }

    if (allFilters?.finalPixDate != null) {
      filters['balanceRequestTransaction.pix_transfer_confirmation_date.end'] = convertDateTimeFromServer(allFilters.finalPixDate);
    }

    if (allFilters?.subsidiary != null && allFilters.subsidiary.length > 0) {
      filters['organizationSubsidiary.name'] = allFilters.subsidiary.map(value => value.name);
    }

    filters['fileType'] = fileType;

    analysisReportService.generateBalanceReport(filters).then(response => {
      if (fileType === 'csv') {
        generateCsvFile(response);
      } else {
        generateExcelFile(response);
      }
      setIsLoading(false);
    });
  };

  const generateReportName = (reportName?: string) => {
    return (reportName ?? 'report') + new Date().toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  const generateCsvFile = response => {
    const file = new Blob([response.data], { type: 'text/csv' });
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(file);
    a.download = generateReportName('relatorio-saldos');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const generateExcelFile = response => {
    const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(file);
    a.download = generateReportName('relatorio-saldos');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const cleanFilters = () => {
    setAllFilters(filterInitialValues);
    setIsShowClearFilterButton(false);
  };

  return (
    <>
      <S.HeaderContainer>
        <S.Title>{t('admin.dashboard.reportBalances')}</S.Title>
        <GenerateReportButtons handleGenerateReport={handleGenerateReport} />
      </S.HeaderContainer>

      <S.FilterContainer>
        <S.FilterContent>
          <CustomFilterCalendar
            placeholder={t('reportScreen.filter.initialCreationDate')}
            onChangeValue={date => setAllFilters({ ...allFilters, initialCreationDate: date })}
            isActive={allFilters?.initialCreationDate != null}
            date={allFilters.initialCreationDate}
            onCleanFilter={() => setAllFilters({ ...allFilters, initialCreationDate: null })}
          />
          <CustomFilterCalendar
            placeholder={t('reportScreen.filter.finalCreationDate')}
            onChangeValue={date => setAllFilters({ ...allFilters, finalCreationDate: date })}
            isActive={allFilters?.finalCreationDate != null}
            date={allFilters.finalCreationDate}
            onCleanFilter={() => setAllFilters({ ...allFilters, finalCreationDate: null })}
          />
          <CustomFilterCalendar
            placeholder={t('reportScreen.filter.initialPixTransferDate')}
            onChangeValue={date => setAllFilters({ ...allFilters, initialPixDate: date })}
            isActive={allFilters?.initialPixDate != null}
            date={allFilters.initialPixDate}
            onCleanFilter={() => setAllFilters({ ...allFilters, initialPixDate: null })}
          />
          <CustomFilterCalendar
            placeholder={t('reportScreen.filter.finalPixTransferDate')}
            onChangeValue={date => setAllFilters({ ...allFilters, finalPixDate: date })}
            isActive={allFilters?.finalPixDate != null}
            date={allFilters.finalPixDate}
            onCleanFilter={() => setAllFilters({ ...allFilters, finalPixDate: null })}
          />

          {analysiseSubsidiaries.length > 0 && (
            <CustomSelectionFilter
              icon={<BriefcaseIconSvg />}
              placeholder={t('admin.dashboard.branch')}
              filterData={analysiseSubsidiaries}
              keyDisplay="name"
              listSelectedItems={(subsidiaries: OrganizationSubsidiaries[] | null) =>
                setAllFilters({ ...allFilters, subsidiary: subsidiaries })
              }
              onCleanFilter={() => setAllFilters({ ...allFilters, subsidiary: null })}
              isActive={allFilters?.subsidiary != null && allFilters.subsidiary.length > 0}
            />
          )}
        </S.FilterContent>
      </S.FilterContainer>

      <S.FilterTagContainer>
        <S.FilterTagInnerContainer>
          {Object.keys(allFilters).map((value, index) => {
            if (allFilters[value] != null) {
              if (Array.isArray(allFilters[value])) {
                const isArrayOfObjects = allFilters[value].every(item => Object.prototype.hasOwnProperty.call(item, 'name'));

                if (isArrayOfObjects && allFilters[value].length > 0) {
                  const namesArray = allFilters[value].map(item => item.name);

                  return (
                    <FilterTag
                      key={`tag-${index}`}
                      text={t(`reportScreen.filter.tag.${value}`, {
                        value: namesArray.join(', '),
                      })}
                      onClick={() => setAllFilters({ ...allFilters, [value]: null })}
                    />
                  );
                } else {
                  return <></>;
                }
              }

              return (
                <FilterTag
                  key={`tag-${index}`}
                  text={t(`reportScreen.filter.tag.${value}`, {
                    value:
                      typeof allFilters[value] === 'object' && allFilters[value].toLocaleDateString
                        ? allFilters[value].toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })
                        : allFilters[value],
                  })}
                  onClick={() => setAllFilters({ ...allFilters, [value]: null })}
                />
              );
            }
          })}
        </S.FilterTagInnerContainer>
        {isShowClearFilterButton && <S.Button onClick={() => cleanFilters()}>{t('reportScreen.filter.clearFilters')}</S.Button>}
      </S.FilterTagContainer>
    </>
  );
};

export default withTranslation()(HeaderBalances);
