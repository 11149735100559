import SystemStepCategory from 'model/enums/system-step-category';
import { Column, Header, Row, Separator, Table } from './styles';

export interface TableId {
  first: number;
  second?: number;
}

export interface TableValues {
  header: string;
  values: string[] | JSX.Element[] | (string | JSX.Element)[];
  ids?: TableId[];
  onClick?: (ids?: TableId) => void;
  fullLength?: boolean;
  maxWidth?: string;
  hide?: boolean;
}

interface TableListProps {
  tableValues: TableValues[];
}

export const SimpleTable = ({ tableValues }: TableListProps) => {
  return (
    <>
      <Table $internal={true}>
        {tableValues
          .filter(table => !table.hide)
          .map(table => (
            <Column key={table.header} $maxWidth={table?.maxWidth}>
              <Header>{table.header}</Header>
              <Separator />
              {table.values.map((row, index) => (
                <>
                  <Row
                    onClick={() => {
                      if (table.onClick && table.ids) {
                        table.onClick(table.ids[index]);
                      }
                    }}
                    key={index}
                    $blank={row === ''}
                    $fullLength={table.fullLength}
                  >
                    <span>{row === '' ? '.' : row}</span>
                  </Row>
                  {index !== table.values.length - 1 && <Separator />}
                </>
              ))}
            </Column>
          ))}
      </Table>
    </>
  );
};
