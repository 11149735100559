import styled from 'styled-components';

export const FormContainer = styled.div``;

export const FormInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
  background: #ffffff;
  box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.06);
  position: fixed;
  bottom: 0;
`;
interface IButtonProps {
  $primary?: boolean;
  $width?: number;
}
export const Button = styled.button<IButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  width: ${props => (props.$width ? `${props.$width}px` : '160px')};
  height: 48px;
  background: ${props => (props.$primary ? ({ theme }) => theme.color.primaryColor : 'transparent')};
  border: 1px solid ${props => (props.$primary ? ({ theme }) => theme.color.primaryColor : '#CCCCCC')};
  border-radius: 24px;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${props => (props.$primary ? '#fff' : '#CCCCCC')};
  }
`;
export const ImageContainer = styled.div`
  margin-left: 30px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 16px 100px;
  gap: 15px;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  margin: 16px;
`;

export const InfoPassword = styled.label`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #7f7f7f;
  margin-bottom: 20px;
`;
