import 'bootstrap/dist/css/bootstrap.min.css';
import GlobalContext from 'context';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import Theme from 'themes/default';
import GlobalStyle from 'themes/globalStyles';
import App from './App';
import i18n from './config/i18n';
import configureStore from './reducer/configureStore';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './shared/error/error-boundary';
import * as Sentry from '@sentry/react';
import Toast from 'components/toast';

Sentry.init({
  dsn: 'https://8fa5daf9c23c3c1c89d2f64d4e4eedc2@o62558.ingest.sentry.io/4505874253611008',
  integrations: [new Sentry.BrowserTracing()],
  enabled: false,
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const initialState = window.INITIAL_REDUX_STATE;
const store = configureStore(initialState);

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

export type RootDispatcher = typeof store.dispatch;

root.render(
  <ThemeProvider theme={Theme}>
    <ErrorBoundary>
      <Provider store={store}>
        <GlobalContext>
          <GlobalStyle />
          <I18nextProvider i18n={i18n}>
            <Sentry.ErrorBoundary fallback={<p>An Error has Occurred</p>}>
              <App />
              {/* If this slows down the app in dev disable it and enable when required  */}
            </Sentry.ErrorBoundary>
          </I18nextProvider>
        </GlobalContext>
      </Provider>
    </ErrorBoundary>
    <Toast />
  </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
