import styled from 'styled-components';

interface StyledProps {
  $picScreen?: string;
  $isPortrait?: boolean;
}

export const IOSPhoto = styled.div<StyledProps>`
  height: calc(100dvh - 270px);
  background-position: center;
  width: 1px;
  margin: auto;

  &:before {
    content: '';
    position: absolute;
    width: ${({ $isPortrait }) => ($isPortrait ? '350px' : 'calc(100dvh - 270px)')};
    height: calc(100dvh - 270px);
    z-index: 10;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: ${({ $picScreen }) => `url(${$picScreen})`};
    transform: ${({ $isPortrait }) => ($isPortrait ? 'translate(-50%, 0%)' : 'translate(-50%, 0%) rotate(-90deg)')};
    display: block;
    position: relative;
    background-position: center;
  }
`;
