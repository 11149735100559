import styled from 'styled-components';

export const Container = styled.div``;

export const Box = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 24px;
  row-gap: 16px;

  > label {
    > div {
      min-width: max-content;
    }
  }

  @media (max-width: 1452px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    > div,
    label {
      flex: 0;
    }
  }
`;

export const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Button = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.primaryColor};
    margin-left: 8px;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.lightGray2};
  margin: 32px 0px;
`;

export const TitleBox = styled.div`
  > span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.black0};
  }
`;
