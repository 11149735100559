import { IconSuccessContainer, StyledInnerContainer } from './styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import MobileHeader from 'components/mobile-header';
import { useHistory } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import ContractContext from 'context/contract';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { ReactComponent as XRedCircle } from 'images/x-red-circle.svg';

const OperationCancelled = ({ t }: WithTranslation) => {
  const history = useHistory();

  const { signatureKey } = useContext(ContractContext);
  const { isMobile, isTablet } = useResponsiveMobile();

  useEffect(() => {
    if (!isMobile && !isTablet) {
      return history.push(`/qr-code/${signatureKey}`);
    }
  }, []);

  return (
    <>
      <MobileHeader></MobileHeader>
      <StyledInnerContainer>
        <h1>{t('contract.waitingStep.operationCancelled.title')}</h1>
        <span>{t('contract.waitingStep.operationCancelled.subtitle')}</span>
        <span style={{ marginTop: '8px' }}>{t('contract.waitingStep.waitNewLiberation.doubts')}</span>
        <IconSuccessContainer>
          <XRedCircle />
        </IconSuccessContainer>

        <div className="waiting-step-footer">{t('contract.footer')}</div>
      </StyledInnerContainer>
    </>
  );
};

export default withTranslation()(OperationCancelled);
