import { ORGANIZATION_NAME } from 'config/constants';
import AdminFiltersContext from 'context/admin-filters';
import { Authority } from 'model/enums/authority';
import { OrganizationName } from 'model/enums/organization-name';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { hasAuthorities } from 'shared/util/utils';
import { ButtonContainer, Container, FilterContainer, FilterContent, FilterItem, Title } from './styles';
import SearchInput from 'features/admin/dashboard/components/search-input';
import { SearchInputOptions } from 'features/admin/dashboard/components/search-input-options';
import { OrderFilterButton } from 'features/admin/dashboard/components/filter-button-order';
import { StatusFilterButton } from 'features/admin/dashboard/components/filter-button-status';
import { StyledTitleContainer } from 'features/admin/dashboard/components/title-header/styles';

interface TitleHeaderProps {
  title: string;
  setSearch?: (value: string) => void;
  setSearchOption?: (value: string) => void;
  searchOption?: string;
  setFilters?: () => void;
  cleanFilters?: () => void;
  filterStatusValues?: string[];
  customButton?: JSX.Element;
}

export const TitleHeader = ({
  title,
  setSearch,
  setSearchOption,
  searchOption,
  setFilters,
  filterStatusValues,
  customButton,
  cleanFilters,
}: TitleHeaderProps) => {
  const { t } = useTranslation();

  const { filters, updateStatusFilters, updateDateRange } = useContext(AdminFiltersContext);

  const account = useSelector((state: IRootState) => state.authentication.account);

  const handleFilterStatus = (status: string) => {
    let newFilters: string[] = [];

    if (status !== '') {
      newFilters = [...filters.statusFilters.filter(filter => filter !== '')];
      if (newFilters.includes(status)) {
        newFilters = newFilters.filter(filter => filter !== status);
        if (ORGANIZATION_NAME === OrganizationName.HCRED) {
          newFilters = [''];
        }
      } else {
        if (ORGANIZATION_NAME === OrganizationName.HCRED) {
          newFilters = [status];
        } else {
          newFilters.push(status);
        }
      }
    }

    if (newFilters.length === 0) {
      newFilters = [''];
    }
    updateStatusFilters(newFilters);
  };

  const renderSearchButton = () => {
    return <SearchInput title={t('admin.dashboard.searchSubsidiaries')} setSearch={setSearch} />;
  };

  const renderContent = () => {
    return (
      <>
        <StyledTitleContainer>
          <Title>{title}</Title>
          {customButton}
        </StyledTitleContainer>
        <FilterContainer>
          <FilterContent>
            <StatusFilterButton
              filterStatus={filterStatusValues ? filterStatusValues : []}
              onAppliedFilters={() => {
                if (setFilters) {
                  setFilters();
                }
              }}
              onCleanFilter={() => {
                if (cleanFilters) {
                  cleanFilters();
                }
              }}
            />
            <OrderFilterButton
              labelTitle={t('admin.dashboard.orderByFilters.name')}
              labelDefaultOption={t('admin.dashboard.orderByFilters.increasing')}
              labelOption={t('admin.dashboard.orderByFilters.decreasing')}
            />
          </FilterContent>
          <ButtonContainer>
            {renderSearchButton()}
            {searchOption && setSearchOption && (
              <SearchInputOptions searchOption={searchOption} setSearchOption={setSearchOption} setSearch={setSearch} />
            )}
          </ButtonContainer>
        </FilterContainer>
      </>
    );
  };
  return <Container $changeLayout={true}>{renderContent()}</Container>;
};
