import { yupResolver } from '@hookform/resolvers/yup';
import UseResponsiveContext from 'context/use-responsive-context';
import _ from 'lodash';
import { FormProperties } from 'model/enums/form-properties';
import { RoutePath } from 'model/enums/route-path';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import { useHistory } from 'react-router-dom';
import authenticationService from 'services/authentication-service';
import clientService from 'services/client-service';
import landingPageService from 'services/landing-page-service';
import StringUtils, { validateCpf } from 'shared/util/string-utils';
import * as yup from 'yup';
import {
  Error,
  FormButton,
  FormContainer,
  FormContent,
  FormTitle,
  FormsTerm,
  Input,
  InputBox,
  Label,
  ScreenTitle,
  StyledForm,
} from '../styles';

export interface FormData {
  name?: string;
  email?: string;
  phone?: string;
  cpf?: string;
}

interface FormProps {}

const Form: React.FC<FormProps> = () => {
  const { isTablet } = useContext(UseResponsiveContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<number>(0);

  const [formData, setFormData] = useState<FormData>();
  const schema = yup.object().shape({
    [FormProperties.NAME]: yup
      .string()
      .required(t('landingPage.openingScreen.simulatorForm.formValidations.required'))
      .min(3, t('landingPage.openingScreen.simulatorForm.formValidations.minThree')),
    [FormProperties.PHONE]: yup.string().required(t('landingPage.openingScreen.simulatorForm.formValidations.required')),
    [FormProperties.EMAIL]: yup
      .string()
      .required(t('landingPage.openingScreen.simulatorForm.formValidations.required'))
      .email(t('landingPage.openingScreen.simulatorForm.formValidations.invalidEmail')),
    [FormProperties.CPF]: yup
      .string()
      .required(t('landingPage.openingScreen.simulatorForm.formValidations.required'))
      .test(`test-${FormProperties.CPF}`, t('global.errorMessage.invalidCpf'), numbers => validateCpf(numbers!)),
  });

  const methods = useForm<any>({ resolver: yupResolver(schema), mode: 'onSubmit' });

  const handleChange = (e, name: string, mask?: (value) => string) => {
    if (mask) {
      setFormData({ ...formData, [name]: mask(e.target.value) });
      methods.setValue(name, mask(e.target.value));
    } else {
      setFormData({ ...formData, [name]: e.target.value });
      methods.setValue(name, e.target.value);
    }
  };
  const handleForm = async () => {
    setIsLoading(1);

    const isValid = await methods.trigger();

    if (!isValid) {
      setIsLoading(0);
      return;
    }

    const client = {
      phone: formData?.phone,
      email: formData?.email,
      physicalPerson: {
        name: formData?.name,
        cpf: formData?.cpf,
      },
    };

    const clientData = await clientService.getBasicInfo(StringUtils.removeNonNumbersFromMaskedValue(formData?.cpf ?? ''));
    const hasData = Object.keys(clientData).length > 0;
    const alreadyRegister = await authenticationService.checkIfClientAlreadyRegistered(
      StringUtils.removeNonNumbersFromMaskedValue(formData?.cpf ?? '')
    );

    if (hasData && !alreadyRegister.isRegistered) {
      return history.push(`${RoutePath.REGISTER}${clientData.registrationKey}`);
    } else if (hasData && alreadyRegister.isRegistered) {
      return history.push(`${RoutePath.LOGIN}`);
    } else {
      landingPageService
        .postPhysicalPerson(client)
        .then(res => {
          history.push(`${RoutePath.REGISTER}${res.registrationKey}`);
          setIsLoading(2);
        })
        .catch(() => {
          setIsLoading(0);
          setFormData({});
          methods.reset();
        })
        .finally(() => {
          setFormData({});
          methods.reset();
        });
    }
  };

  const sentProcess = () => {
    switch (isLoading) {
      case 1:
        return (
          <FormButton>
            <ReactLoading type="spinningBubbles" width="24px" height="24px" />
          </FormButton>
        );
      case 2:
        return <FormButton>{t('landingPage.multtiplo.form.button')}</FormButton>;
      default:
        return <FormButton>{t('admin.myTeam.modal.create.buttonText')}</FormButton>;
    }
  };

  const handleSubmit = _.debounce(handleForm, 500);

  return (
    <FormContent>
      <FormContainer>
        <ScreenTitle>{t('landingPage.multtiplo.form.title')}</ScreenTitle>
        <FormProvider {...methods}>
          <StyledForm
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <FormTitle>{t('landingPage.multtiplo.form.formTitle')}</FormTitle>
            <InputBox>
              <Label>{t('landingPage.openingScreen.simulatorForm.inputLabels.name')}</Label>
              <Input
                {...methods.register(FormProperties.NAME, { required: true })}
                onChange={e => handleChange(e, FormProperties.NAME)}
                $customStyle={{ hasError: !!methods.formState.errors?.[FormProperties.NAME]?.message }}
                name={FormProperties.NAME}
                value={formData?.[FormProperties.NAME] ?? ''}
                autoComplete={'off'}
              />
              {!!methods.formState.errors?.[FormProperties.NAME]?.message && (
                <Error>{methods.formState.errors?.[FormProperties.NAME]?.message.toString()}</Error>
              )}
            </InputBox>
            <InputBox>
              <Label>{t('landingPage.openingScreen.simulatorForm.inputLabels.email')}</Label>
              <Input
                {...methods.register(FormProperties.EMAIL, { required: true })}
                onChange={e => handleChange(e, FormProperties.EMAIL)}
                $customStyle={{ hasError: !!methods.formState.errors?.[FormProperties.EMAIL]?.message }}
                name={FormProperties.EMAIL}
                value={formData?.[FormProperties.EMAIL] ?? ''}
                autoComplete={'off'}
              />
              {!!methods.formState.errors?.[FormProperties.EMAIL]?.message && (
                <Error>{methods.formState.errors?.[FormProperties.EMAIL]?.message.toString()}</Error>
              )}
            </InputBox>
            <InputBox>
              <Label>{t('register.inputs.phone')}</Label>
              <Input
                {...methods.register(FormProperties.PHONE, { required: true })}
                onChange={e => handleChange(e, FormProperties.PHONE, StringUtils.phoneMask)}
                $customStyle={{ hasError: !!methods.formState.errors[FormProperties.PHONE]?.message }}
                name={FormProperties.PHONE}
                value={formData?.[FormProperties.PHONE] ?? ''}
                autoComplete={'off'}
                maxLength={15}
              />
              {!!methods.formState.errors[FormProperties.PHONE]?.message && (
                <Error>{methods.formState.errors[FormProperties.PHONE].message.toString()}</Error>
              )}
            </InputBox>
            <InputBox>
              <Label>{t('landingPage.openingScreen.simulatorForm.inputLabels.cpf')}</Label>
              <Input
                {...methods.register(FormProperties.CPF, { required: true })}
                onChange={e => handleChange(e, FormProperties.CPF, StringUtils.cpfMask)}
                $customStyle={{ hasError: !!methods.formState.errors[FormProperties.CPF]?.message }}
                name={FormProperties.CPF}
                value={formData?.[FormProperties.CPF] ?? ''}
                autoComplete={'off'}
                maxLength={15}
              />
              {!!methods.formState.errors[FormProperties.CPF]?.message && (
                <Error>{methods.formState.errors[FormProperties.CPF].message.toString()}</Error>
              )}
            </InputBox>
            {sentProcess()}
            <FormsTerm>
              {t('landingPage.openingScreen.simulatorSlider.mensTerm')}
              <a href="https://esparta.s3.amazonaws.com/Termos+de+Uso+-+CreditFlow.pdf" target={'_blank'}>
                {t('landingPage.openingScreen.simulatorSlider.termsOfUse')}
              </a>
              {t('landingPage.openingScreen.simulatorSlider.and')}
              <a href="https://esparta.s3.amazonaws.com/Pol%C3%ADtica+de+Privacidade+-+CreditFlow.pdf" target={'_blank'}>
                {t('landingPage.openingScreen.simulatorSlider.privacyPolicy')}
              </a>
            </FormsTerm>
          </StyledForm>
        </FormProvider>
      </FormContainer>
    </FormContent>
  );
};

export default Form;
