import { Tooltip } from '@mui/material';
import FinancingStatusType from 'model/enums/financing-status-type';
import { Financing, Payment, PaymentData } from 'model/financing';
import { PaymentFormProperties, PaymentSectionType } from 'model/procedurePayment';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { PopupActions } from 'reactjs-popup/dist/types';
import financingService from 'services/financing-service';
import ConfirmPayment from '../confirm-payment';
import PaymentSection from '../payment-section';
import { ButtonAddPayment, EmptyList, PaymentContainer, PaymentList } from './styled';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { Authority } from 'model/enums/authority';

interface ServicePaymentProps {
  paymentData?: PaymentData;
  setPaymentData: (PaymentData) => void;
  paymentListData: Payment[];
  setPaymentListData: (value) => void;
  getPaymentData: () => void;
  procedure?: Financing;
}

const ServicePayment: React.FC<ServicePaymentProps> = ({
  paymentData,
  setPaymentData,
  paymentListData,
  setPaymentListData,
  getPaymentData,
  procedure,
}) => {
  const authorities = useSelector((state: IRootState) => state.authentication.account?.authorities);
  const { t } = useTranslation();
  const { procedureId } = useParams<{ procedureId: string }>();

  const [isLoading, setIsLoading] = useState<number>(0);

  const onDelete = (idx: number) => {
    setPaymentListData(paymentList => paymentList.filter((obj, objIndex) => objIndex !== idx));
  };
  const disableButton = paymentListData[paymentListData?.length - 1]?.[PaymentFormProperties.STATUS] === FinancingStatusType.PENDING;
  const maxPaymentValue = Number(paymentData?.totalValue) - Number(paymentData?.amountPaid);

  const modalRef = useRef<PopupActions>(null!);
  const closeTooltip = () => {
    if (modalRef?.current) {
      modalRef.current.close();
    }
  };
  const confirmPayment = (): void => {
    setIsLoading(1);

    if (Number(procedureId)) {
      financingService
        .approvePaymentManually(Number(Number(procedureId)))
        .then(res => {
          setTimeout(() => {
            setIsLoading(2);
          }, 2500);
          setPaymentData(paymentData => ({ ...paymentData, paymentStatus: res.procedurePaymentStatus }));
        })
        .finally(() => {
          setIsLoading(0);
          closeTooltip();
        });
    }
  };
  return (
    <PaymentContainer>
      <ConfirmPayment
        paymentData={paymentData}
        setPaymentData={setPaymentData}
        onConfirm={confirmPayment}
        reference={modalRef}
        isLoading={isLoading}
        buttonPayment={t('admin.procedureScreen.payments.confirmPayment')}
      />
      <PaymentList>
        {paymentListData?.length > 0 ? (
          paymentListData?.map((el, idx) => (
            <PaymentSection
              procedure={procedure}
              key={idx}
              title={`Pagamento ${(idx + 1).toString().padStart(2, '0')}`}
              sectionType={PaymentSectionType.PAYMENT}
              position={idx}
              payment={el}
              setPaymentListData={setPaymentListData}
              getPaymentData={getPaymentData}
              onDelete={() => onDelete(idx)}
              procedureBankAccountType={paymentData?.procedureBankAccountType}
              maxPaymentValue={maxPaymentValue}
              buttonPayment={t('admin.procedureScreen.payments.realizePayment')}
              errorPaymentMessage={el.errors}
            />
          ))
        ) : (
          <EmptyList>{t('admin.procedureScreen.payments.emptyList')}</EmptyList>
        )}
      </PaymentList>
      {disableButton && (authorities?.includes(Authority.ROLE_ADMIN) || authorities?.includes(Authority.ROLE_INTERNAL_ADMIN)) ? (
        <Tooltip title={t('proceduresDashboard.tooltipAddnew') ?? ''} placement="top">
          <ButtonAddPayment type="button" isDisabled={disableButton}>
            <AiOutlineInfoCircle />
            {t('proceduresDashboard.addNewService')}
          </ButtonAddPayment>
        </Tooltip>
      ) : maxPaymentValue > 0 && (authorities?.includes(Authority.ROLE_ADMIN) || authorities?.includes(Authority.ROLE_INTERNAL_ADMIN)) ? (
        <ButtonAddPayment
          type="button"
          onClick={() =>
            setPaymentListData(payment => [
              ...payment,
              { [PaymentFormProperties.STATUS]: FinancingStatusType.PENDING, financingId: Number(procedureId) },
            ])
          }
        >
          {t('proceduresDashboard.addNewService')}
        </ButtonAddPayment>
      ) : (
        (authorities?.includes(Authority.ROLE_ADMIN) || authorities?.includes(Authority.ROLE_INTERNAL_ADMIN)) && (
          <Tooltip title={t('proceduresDashboard.paymentCompleted') ?? ''} placement="top">
            <ButtonAddPayment type="button" isDisabled={!(maxPaymentValue > 0)}>
              <AiOutlineInfoCircle />
              {t('proceduresDashboard.addNewService')}
            </ButtonAddPayment>
          </Tooltip>
        )
      )}
    </PaymentContainer>
  );
};

export default ServicePayment;
