import { Attachment } from './attachment';
import { BankData } from './bank-data';
import { BankSlip } from './bank-slip';
import { BankTransfer } from './bank-transfer';
import { PaymentType } from './enums/payment-type';
import { ProcedurePaymentStatus } from './enums/procedure-payment-status';
import { RefundStatus } from './enums/refund-status';

export interface ResponseProcedurePayment {
  id?: number;
  procedurePayment?: ProcedurePayment;
  procedureRefund?: ProcedureRefund;
}

export interface ProcedurePayment {
  bankSlip?: BankSlip;
  bankTransfer?: BankTransfer;
  procedurePaymentType?: PaymentType;
  procedurePaymentStatus?: ProcedurePaymentStatus;
}

export interface ProcedureRefund {
  id?: number;
  procedureRefundStatus?: RefundStatus;
  attachment?: Attachment;
}

export interface RequestProcedurePayment {
  bankData?: BankData;
  bankSlip?: BankSlip;
  procedurePaymentType?: PaymentType;
}

export enum PaymentFormProperties {
  TOTAL_VALUE = 'totalValue',
  AMOUNT_PAID = 'amountPaid',
  PAYMENT_STATUS = 'paymentStatus',
  PIX_TYPE = 'pixType',
  PIX_KEY = 'pixKey',
  STATUS = 'status',
  COD_BANK_SLIP = 'codBankSlip',
  BENEFICIARY_NAME = 'beneficiaryName',
  AGENCY = 'agency',
  BANK_ACCOUNT = 'bankAccount',
  BANK = 'bank',
  PROCEDURE_PAYMENT_TYPE = 'procedurePaymentType',
  VALUE = 'value',
  CREATED_AT = 'createdAt',
  ATTACHMENTS = 'attachments',
}

export enum PaymentMethod {
  REGISTERED_PIX = 'REGISTERED_PIX',
  PIX = 'PIX',
  BANK_SLIP = 'BANK_SLIP',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  MANUAL_PAYMENT = 'MANUAL_PAYMENT',
}

export enum PaymentSectionType {
  PAYMENT = 'PAYMENT',
  REIMBURSEMENT = 'REIMBURSEMENT',
}
