import { Logo } from 'images/custom-organization';
import { MulttiploImgs } from 'images/multtiplo';
import { RoutePath } from 'model/enums/route-path';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Links } from '..';
import {
  ArrowBack,
  ButtonsBox,
  HeaderContent,
  LinksMobile,
  LoginButtonMobile,
  LogoMobileBox,
  MenuSocialMedia,
  MobileInfoBox,
  MobileInfoText,
  MobileInfoTitle,
  StyledPopup,
} from '../../styles';

interface MobileProps {
  links: Links[];
  renderButton: () => JSX.Element;
}

const Mobile: React.FC<MobileProps> = ({ links, renderButton }) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <HeaderContent>
      <div onClick={() => history.push('/')}>
        <Logo isWhite={false} />
      </div>

      <StyledPopup nested trigger={<MulttiploImgs.menu />} modal closeOnDocumentClick>
        {close => (
          <>
            <ArrowBack onClick={() => close()}>
              <MulttiploImgs.arrowBack />
            </ArrowBack>
            <LogoMobileBox>
              <Logo isWhite={false} />
            </LogoMobileBox>
            <ButtonsBox>
              {links.map((link, idx) => (
                <LinksMobile key={idx}>{link.text}</LinksMobile>
              ))}
              <LoginButtonMobile onClick={() => history.push(RoutePath.LOGIN)}> {t('global.button.enter')}</LoginButtonMobile>
            </ButtonsBox>
            <MobileInfoBox>
              <MobileInfoTitle>{t('landingPage.multtiplo.talk')}</MobileInfoTitle>
              <MobileInfoText>{t('landingPage.multtiplo.contactEmail')}</MobileInfoText>
              <MobileInfoText>{t('landingPage.multtiplo.contactPhoneNumber')}</MobileInfoText>
              <MenuSocialMedia>
                <MulttiploImgs.facebook />
                <MulttiploImgs.instagram />
              </MenuSocialMedia>
            </MobileInfoBox>
          </>
        )}
      </StyledPopup>
    </HeaderContent>
  );
};

export default Mobile;
