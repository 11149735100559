import { Button, ButtonsContainer, FormInputsContainer, Row } from 'components/edit-forms/styles';
import EnterCustomButton from 'components/enter-custom-button';
import { Column } from 'components/register-forms-mobile/styles';
import { AddPartner, EditButton, PartnerItem, PlusIcon } from 'components/register-forms/partner-relations/styles';
import InputText from 'components/styled-input';
import { GeneralImages } from 'images/general-images';
import { Partner } from 'model/dashboard';
import { partnerPath } from 'model/edit-paths';
import { Mask } from 'model/enums/mask-types';
import { useEditForm } from 'provider/edit-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import plus from 'images/plus-add.svg';
import { format, parse } from 'date-fns';

interface IContainerInputsMyCompanyProps {
  mobile?: boolean;
  onlyView?: boolean;
  visible?: boolean;
}

export const ContainerInputsMyCompany: React.FC<IContainerInputsMyCompanyProps> = props => {
  const { t } = useTranslation();
  const {
    initialClientData,
    setInitialClientData,
    setInitialPartnerData,
    setEditSteps,
    setCurrentEditStep,
    setClientFiles,
    setCurrentPartner,
    addingPartner,
    setAddingPartner,
  } = useEditForm();
  const [partners, setPartners] = useState<Partner[]>([]);
  const [editingPartner, setEditingPartner] = useState(false);

  useEffect(() => {
    const newPartners = initialClientData?.legalPerson?.partners;
    setPartners(newPartners ?? []);
  }, [initialClientData]);

  const onEditPartner = (partner: Partner, index: number) => {
    setInitialPartnerData(partner);
    setEditSteps(partnerPath);
    setCurrentEditStep(partnerPath[0]);
    setCurrentPartner(index);
    setClientFiles();
  };

  const onRemovePartner = (partner: Partner) => {
    const filteredPartners = initialClientData?.legalPerson?.partners.filter(p => p.cpf !== partner.cpf);
    if (initialClientData && initialClientData.legalPerson) {
      setInitialClientData({
        ...initialClientData,
        legalPerson: {
          ...initialClientData.legalPerson,
          partners: filteredPartners ?? [],
        },
      });
    }
  };

  const goToPartnerForms = () => {
    setEditSteps(partnerPath);
    setCurrentEditStep(partnerPath[0]);
    setAddingPartner(true);
  };

  const renderPartners = () => {
    return partners.map((partner, index) => (
      <PartnerItem>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="title">{`${t('register.inputs.partner')} ${index + 1}`}</span>
          <span className="subtitle">{partner.name}</span>
        </div>
        <div>
          <EditButton onClick={() => onEditPartner(partner, index)}>
            <img className="button" src={GeneralImages.pencil} />
          </EditButton>
          <EditButton onClick={() => onRemovePartner(partner)}>
            <img className="button" src={GeneralImages.trash} />
          </EditButton>
        </div>
      </PartnerItem>
    ));
  };

  const renderMobile = () => {
    return (
      <Column>
        <InputText
          isRequired
          name="baseDate"
          disabled={props.onlyView}
          label={t('register.inputs.baseDate')}
          placeholder={t('register.placeholder.baseDate')}
          maxLength={7}
          mask={Mask.MONTH_YEAR}
          defaultValue={
            initialClientData?.legalPerson?.baseDate
              ? StringUtils.parseMonthYearString(StringUtils.parseMonthYearDate(initialClientData?.legalPerson?.baseDate))
              : ''
          }
        />
        <InputText
          isRequired
          name="netWorth"
          disabled={props.onlyView}
          label={t('register.inputs.netWorth')}
          placeholder={t('register.placeholder.netWorth')}
          mask={Mask.CURRENCY}
          defaultValue={initialClientData?.legalPerson?.netWorth ? initialClientData?.legalPerson?.netWorth.toFixed(2) : ''}
          maxLength={14}
        />
        <InputText
          isRequired
          name="revenue"
          disabled={props.onlyView}
          label={t('register.inputs.revenue')}
          placeholder={t('register.placeholder.revenue')}
          mask={Mask.CURRENCY}
          defaultValue={initialClientData?.legalPerson?.revenue ? initialClientData?.legalPerson?.revenue.toFixed(2) : ''}
          maxLength={14}
        />
      </Column>
    );
  };
  return (
    <FormInputsContainer $visible={props.visible}>
      {props.mobile ? (
        renderMobile()
      ) : (
        <>
          <Row>
            <InputText
              isRequired
              name="baseDate"
              disabled={props.onlyView}
              label={t('register.inputs.baseDate')}
              placeholder={t('register.placeholder.baseDate')}
              maxLength={7}
              mask={Mask.MONTH_YEAR}
              defaultValue={
                initialClientData?.legalPerson?.baseDate
                  ? format(parse(initialClientData?.legalPerson?.baseDate, 'yyyy-MM-dd', new Date()), 'MM/yyyy')
                  : ''
              }
            />
            <InputText
              name="netWorth"
              disabled={props.onlyView}
              label={t('register.inputs.netWorth')}
              placeholder={t('register.placeholder.netWorth')}
              mask={Mask.CURRENCY}
              defaultValue={initialClientData?.legalPerson?.netWorth ? initialClientData?.legalPerson?.netWorth.toFixed(2) : ''}
              maxLength={14}
            />
            <InputText
              name="revenue"
              disabled={props.onlyView}
              label={t('register.inputs.revenue')}
              placeholder={t('register.placeholder.revenue')}
              mask={Mask.CURRENCY}
              defaultValue={initialClientData?.legalPerson?.revenue ? initialClientData?.legalPerson?.revenue.toFixed(2) : ''}
              maxLength={14}
            />
          </Row>
        </>
      )}
      {renderPartners()}
      <AddPartner onClick={goToPartnerForms}>
        <PlusIcon src={plus} />
        <span className="subtitle">{t('register.inputs.addPartner')}</span>
      </AddPartner>
    </FormInputsContainer>
  );
};
