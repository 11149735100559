import LoadingScreen from 'components/loading-screen/loading-screen';
import { SimulatorState } from 'components/simulator-data/processing/hooks/use-simulator-state';
import PreRegisterContext from 'context/pre-register';
import { useSystemParameters } from 'hooks/use-system-parameters';
import { ClientLP } from 'model/client';
import { FormProperties } from 'model/enums/form-properties';
import { LocalStorageKeys } from 'model/enums/local-storage-keys';
import { RoutePath } from 'model/enums/route-path';
import { SimulatorType, StepsType } from 'model/enums/simulator-lp-properties';
import { FinancingSimpleSimulation } from 'model/financing';
import { MaritalStatus, SimulatorFormData, SimulatorParameters, State } from 'model/landing-page';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import financingService from 'services/financing-service';
import landingPageService from 'services/landing-page-service';
import { checkTime } from 'shared/util/check-time';
import { formatTimeSlots } from 'shared/util/format-time';
import AddressModal from './address-modal';
import FormPrimary from './forms/form-primary';
import FormQuaternary from './forms/form-quaternary';
import FormQuinary from './forms/form-quinary';
import FormSecondary from './forms/form-secondary';
import FormTertiary from './forms/form-tertiary';
import OutOfTime from './out-of-time';
import OutOfZone from './out-of-zone';
import { SimulatorError } from './simulator-exception';
import { simulatorHandler } from './simulator-handler';
import SimulatorSlider from './simulator-slider';

interface SimulatorProps {
  isAdmin?: boolean;
}

const Simulator: React.FC<SimulatorProps> = props => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [simulatorData, setSimulatorData] = useState<SimulatorParameters>();
  let steps = {
    [SimulatorType.DEFAULT]: [StepsType.SLIDER, StepsType.FORM, StepsType.SECONDARY_FORM],
    [SimulatorType.MONJUA]: [StepsType.SLIDER, StepsType.FORM, StepsType.SECONDARY_FORM],
    [SimulatorType.TOP_ONE]: [StepsType.SLIDER, StepsType.FORM, StepsType.SECONDARY_FORM],
  };

  const [maritalStatusList, setMaritalStatusList] = useState<MaritalStatus[]>([]);

  const [hasLimit, setHasLimit] = useState<string>();

  const { t } = useTranslation();

  const formInitialValue: SimulatorFormData = {
    phone: '',
    email: '',
    address: {
      street: '',
      zipcode: '',
      district: '',
      city: {
        name: '',
      },
    },
  };

  const { systemParameters } = useSystemParameters();
  const formType = systemParameters?.formType?.value ?? SimulatorType.DEFAULT;
  const [formData, setFormData] = useState<SimulatorFormData>(formInitialValue);
  const [simulatorSaveData, setSimulatorSaveData] = useState<FinancingSimpleSimulation>();

  const [isLoading, setIsLoading] = useState<number>(0);
  const [limitLoading, setLimitLoading] = useState<boolean>(false);
  const [hasMissingFields, setHasMissingFields] = useState<boolean>(false);
  const { setRegistrationKey } = useContext(PreRegisterContext);

  const [showHours, setShowHours] = useState<string>('');

  const isPhysicalPerson = systemParameters?.fieldType?.value === FormProperties.CPF.toUpperCase();

  const [addressError, setAddressError] = useState<string>('');
  const [validStates, setValidStates] = useState<State[]>([]);
  const history = useHistory();

  const getOrientationRules = async id => {
    if (id) {
      const organizationRules = await landingPageService.getOrganizationRules(id);
      const validateTime = await organizationRules.openingHours.some(openingHour => {
        return checkTime(openingHour.initialTimeSlot, openingHour.finalTimeSlot);
      });

      if (!validateTime) {
        const showTime = formatTimeSlots(organizationRules.openingHours);
        setShowHours(showTime);
        setHasLimit(SimulatorType.OUT_OF_TIME);
      }

      setValidStates(organizationRules?.states);

      setLimitLoading(false);
    }
  };

  const getSimulatorData = async () => {
    setLimitLoading(true);

    const simulatorData = await landingPageService.getSimulatorParameters();
    if (simulatorData) {
      setSimulatorData(simulatorData);
    }
  };

  const getDropdownLists = async () => {
    const getMaritalStatus = await landingPageService.getMaritalStatus();
    const filterMaritalStatusByOrg = getMaritalStatus.content.filter(res => res.organization.toUpperCase() === formType);
    setMaritalStatusList(filterMaritalStatusByOrg);
  };

  useEffect(() => {
    getSimulatorData();
  }, [formType]);

  useEffect(() => {
    if (!formType) return;
    switch (formType) {
      case SimulatorType.TOP_ONE: {
        getOrientationRules(simulatorData?.organization.id);
        return;
      }
      case SimulatorType.MONJUA: {
        getDropdownLists();
        return setLimitLoading(false);
      }
      default: {
        return setLimitLoading(false);
      }
    }
  }, [simulatorData, formType]);

  const pfRedirectAfterSend = (sendSimulator: FinancingSimpleSimulation, redirectRoute: string, physicalPersonId?: number) => {
    if (!props.isAdmin) {
      history.push(redirectRoute, {
        sendSimulator: sendSimulator,
      } as SimulatorState);
    } else {
      history.push(`${redirectRoute}/${physicalPersonId}`, {
        sendSimulator: sendSimulator,
      } as SimulatorState);
    }
  };

  const sendPf = async (client: ClientLP, redirectRoute, nextRoute) => {
    const postPhysicalPerson = props.isAdmin
      ? await landingPageService.postPhysicalPersonAdmin(client)
      : await landingPageService.postPhysicalPerson(client);

    const financing = await financingService.createFinancingSketch({
      installmentsTotal: simulatorSaveData?.installmentsTotal,
      choosedValue: simulatorSaveData?.choosedValue ?? simulatorData?.minValue,
      client: {
        id: postPhysicalPerson.id,
      },
    });

    const sendSimulator = { ...simulatorSaveData, id: financing.id, client: { id: postPhysicalPerson?.id } };

    localStorage.setItem(LocalStorageKeys.REGISTRATION_KEY, JSON.stringify(postPhysicalPerson.registrationKey));
    setRegistrationKey(postPhysicalPerson.registrationKey);

    switch (formType) {
      case SimulatorType.TOP_ONE: {
        delete client.physicalPerson?.partner;

        try {
          pfRedirectAfterSend(sendSimulator, redirectRoute, postPhysicalPerson?.id);
        } catch (err: any) {
          if (err.data.fieldErrors) {
            setAddressError(t('landingPage.openingScreen.simulatorForm.formValidations.invalidZipCode'));
          }
        }
        break;
      }
      case SimulatorType.MONJUA: {
        const organizationSubsidiaries = landingPageService.getOrganizationSubsidiaries();

        const clientSubsidiary = (await organizationSubsidiaries).find(
          subsidiary => subsidiary.name.toUpperCase() === client.physicalPerson?.partner?.toUpperCase()
        );

        try {
          if (clientSubsidiary && postPhysicalPerson?.id) {
            await landingPageService.postOrganizationSubsidiaries(clientSubsidiary?.id, postPhysicalPerson.id);
          }
          pfRedirectAfterSend(sendSimulator, redirectRoute, postPhysicalPerson?.id);
        } catch (err: any) {
          if (err.data.fieldErrors) {
            setAddressError(t('landingPage.openingScreen.simulatorForm.formValidations.invalidZipCode'));
          }
        }
        break;
      }
      default: {
        delete client.physicalPerson?.partner;

        try {
          pfRedirectAfterSend(sendSimulator, redirectRoute, postPhysicalPerson?.id);
        } catch (err: any) {
          if (err.data.fieldErrors) {
            setAddressError(t('landingPage.openingScreen.simulatorForm.formValidations.invalidZipCode'));
          }
        }
        break;
      }
    }
  };

  const sendPj = async (client: ClientLP, redirectRoute, nextRoute) => {
    const postLegalPerson = props.isAdmin
      ? await landingPageService.postLegalPersonAdmin(client)
      : await landingPageService.postLegalPerson(client);

    const financing = await financingService.createFinancingSketch({
      installmentsTotal: simulatorSaveData?.installmentsTotal,
      choosedValue: simulatorSaveData?.choosedValue,
      client: {
        id: postLegalPerson.id,
      },
    });

    try {
      const sendSimulator = { ...simulatorSaveData, id: financing.id, client: { id: postLegalPerson?.id } };
      localStorage.setItem(LocalStorageKeys.REGISTRATION_KEY, JSON.stringify(postLegalPerson.registrationKey));
      setRegistrationKey(postLegalPerson.registrationKey);

      if (!props.isAdmin) {
        history.push(redirectRoute, {
          sendSimulator: sendSimulator,
        } as SimulatorState);
      } else {
        history.push(`${redirectRoute}/${postLegalPerson.id}`, {
          sendSimulator: sendSimulator,
        } as SimulatorState);
      }
    } catch (err: any) {
      if (err.data.fieldErrors) {
        console.error(err);
      }
    }
  };

  const sendApi = async data => {
    if (formType) {
      const type = SimulatorType[formType];

      try {
        const client = await simulatorHandler.process(data, type, isPhysicalPerson, setAddressError, validStates, setHasMissingFields);
        const SimulatorProcessingPath = props.isAdmin ? `${RoutePath.SIMULATOR_PROCESSING_ADMIN}` : RoutePath.SIMULATOR_PROCESSING;
        const ConfirmSimulationPath = props.isAdmin ? RoutePath.CONFIRM_SIMULATION_ADMIN : RoutePath.CONFIRM_SIMULATION;
        if (isPhysicalPerson) {
          return sendPf(client, SimulatorProcessingPath, ConfirmSimulationPath);
        } else {
          return sendPj(client, SimulatorProcessingPath, ConfirmSimulationPath);
        }
      } catch (error: unknown) {
        if (error instanceof SimulatorError) {
          setHasLimit(error.message);
        }
      }
    }
  };

  const verifyType = (systemType?: string) => {
    switch (systemType) {
      case SimulatorType.DEFAULT: {
        switch (steps[systemType][currentStep]) {
          case StepsType.FORM: {
            return (
              <>
                <FormPrimary
                  steps={steps[systemType]}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  setIsLoading={setIsLoading}
                  formData={formData}
                  setFormData={setFormData}
                  initialForm={formInitialValue}
                  isPhysicalPerson={isPhysicalPerson}
                  isLoading={isLoading}
                  sendApi={sendApi}
                  isAdmin={props.isAdmin}
                />
              </>
            );
          }
          case StepsType.SECONDARY_FORM: {
            return (
              <>
                <FormQuaternary
                  steps={steps[systemType]}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  setIsLoading={setIsLoading}
                  formData={formData}
                  setFormData={setFormData}
                  isPhysicalPerson={isPhysicalPerson}
                  initialForm={formInitialValue}
                  isLoading={isLoading}
                  sendApi={sendApi}
                  addressError={addressError}
                  isAdmin={props.isAdmin}
                />
              </>
            );
          }
          default: {
            return (
              <SimulatorSlider
                steps={steps[systemType]}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                simulatorSaveData={simulatorSaveData}
                setSimulatorSaveData={setSimulatorSaveData}
                simulatorData={simulatorData}
                isAdmin={props.isAdmin}
              />
            );
          }
        }
      }
      case SimulatorType.EVOLVE: {
        switch (steps[systemType][currentStep]) {
          case StepsType.FORM: {
            return (
              <>
                <FormPrimary
                  steps={steps[systemType]}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  setIsLoading={setIsLoading}
                  formData={formData}
                  setFormData={setFormData}
                  initialForm={formInitialValue}
                  isPhysicalPerson={isPhysicalPerson}
                  isLoading={isLoading}
                  sendApi={sendApi}
                  isAdmin={props.isAdmin}
                />
              </>
            );
          }
          case StepsType.SECONDARY_FORM: {
            return (
              <>
                <FormQuinary
                  steps={steps[systemType]}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  setIsLoading={setIsLoading}
                  formData={formData}
                  setFormData={setFormData}
                  isPhysicalPerson={isPhysicalPerson}
                  initialForm={formInitialValue}
                  isLoading={isLoading}
                  sendApi={sendApi}
                  addressError={addressError}
                  isAdmin={props.isAdmin}
                />
              </>
            );
          }
          default: {
            return (
              <SimulatorSlider
                steps={steps[systemType]}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                simulatorSaveData={simulatorSaveData}
                setSimulatorSaveData={setSimulatorSaveData}
                simulatorData={simulatorData}
                isAdmin={props.isAdmin}
              />
            );
          }
        }
      }
      case SimulatorType.TOP_ONE:
        switch (steps[systemType][currentStep]) {
          case StepsType.FORM:
            return (
              <>
                <FormPrimary
                  steps={steps[systemType]}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  setIsLoading={setIsLoading}
                  formData={formData}
                  setFormData={setFormData}
                  initialForm={formInitialValue}
                  isPhysicalPerson={isPhysicalPerson}
                  isLoading={isLoading}
                  sendApi={sendApi}
                  isAdmin={props.isAdmin}
                />
              </>
            );
          case StepsType.SECONDARY_FORM:
            return (
              <>
                <FormSecondary
                  steps={steps[systemType]}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  setIsLoading={setIsLoading}
                  formData={formData}
                  setFormData={setFormData}
                  initialForm={formInitialValue}
                  isLoading={isLoading}
                  sendApi={sendApi}
                  addressError={addressError}
                  isAdmin={props.isAdmin}
                />
              </>
            );

          default:
            return (
              <SimulatorSlider
                steps={steps[systemType]}
                currentStep={currentStep}
                simulatorSaveData={simulatorSaveData}
                setSimulatorSaveData={setSimulatorSaveData}
                setCurrentStep={setCurrentStep}
                simulatorData={simulatorData}
                isAdmin={props.isAdmin}
              />
            );
        }

      case SimulatorType.MONJUA:
        switch (steps[systemType][currentStep]) {
          case StepsType.FORM:
            return (
              <>
                <FormPrimary
                  steps={steps[systemType]}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  setIsLoading={setIsLoading}
                  formData={formData}
                  setFormData={setFormData}
                  initialForm={formInitialValue}
                  isPhysicalPerson={isPhysicalPerson}
                  isLoading={isLoading}
                  sendApi={sendApi}
                  isAdmin={props.isAdmin}
                />
              </>
            );
          case StepsType.SECONDARY_FORM:
            return (
              <>
                <FormTertiary
                  steps={steps[systemType]}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  setIsLoading={setIsLoading}
                  formData={formData}
                  setFormData={setFormData}
                  isLoading={isLoading}
                  sendApi={sendApi}
                  addressError={addressError}
                  isAdmin={props.isAdmin}
                  maritalStatusList={maritalStatusList}
                />
              </>
            );

          default:
            return (
              <SimulatorSlider
                steps={steps[systemType]}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                simulatorSaveData={simulatorSaveData}
                setSimulatorSaveData={setSimulatorSaveData}
                simulatorData={simulatorData}
                isAdmin={props.isAdmin}
              />
            );
        }
      default:
        switch (steps[SimulatorType.DEFAULT][currentStep]) {
          case StepsType.FORM:
            return (
              <>
                <FormPrimary
                  steps={steps[SimulatorType.DEFAULT]}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  setIsLoading={setIsLoading}
                  formData={formData}
                  setFormData={setFormData}
                  initialForm={formInitialValue}
                  isPhysicalPerson={isPhysicalPerson}
                  isLoading={isLoading}
                  sendApi={sendApi}
                  isAdmin={props.isAdmin}
                />
              </>
            );
          case StepsType.SECONDARY_FORM:
            return (
              <>
                <FormQuaternary
                  steps={steps[SimulatorType.DEFAULT]}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  setIsLoading={setIsLoading}
                  formData={formData}
                  setFormData={setFormData}
                  initialForm={formInitialValue}
                  isLoading={isLoading}
                  sendApi={sendApi}
                  addressError={addressError}
                  isAdmin={props.isAdmin}
                  isPhysicalPerson={isPhysicalPerson}
                />
              </>
            );
          default:
            return (
              <SimulatorSlider
                steps={steps[SimulatorType.DEFAULT]}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                simulatorSaveData={simulatorSaveData}
                setSimulatorSaveData={setSimulatorSaveData}
                simulatorData={simulatorData}
                isAdmin={props.isAdmin}
              />
            );
        }
    }
  };
  const renderSimulatorScreen = () => {
    switch (hasLimit) {
      case SimulatorType.OUT_OF_TIME:
        return <OutOfTime showHours={showHours} />;
      case SimulatorType.OUT_OF_ZONE:
        return <OutOfZone />;
      default:
        return <>{simulatorData && verifyType(formType)}</>;
    }
  };
  return (
    <LoadingScreen width="480px" height="480px" isLoading={limitLoading}>
      <AddressModal
        open={hasMissingFields}
        setIsLoading={setIsLoading}
        sendApi={sendApi}
        formData={formData}
        setFormData={setFormData}
        setHasMissingFields={setHasMissingFields}
      />
      {renderSimulatorScreen()}
    </LoadingScreen>
  );
};
export default Simulator;
