import { MulttiploImgs } from 'images/multtiplo';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaqBox, FaqContent, FaqTitle, HandleBox, Item, ItemText, ItemTitle } from '../styles';

export interface FaqItem {
  title: string;
  text: string;
}

interface FaqProps {}

const Faq: React.FC<FaqProps> = () => {
  const { t } = useTranslation();
  const [currentItem, setCurrentItem] = useState<number>();
  const faqItems: FaqItem[] = [
    {
      title: t('landingPage.multtiplo.faq.title.1'),
      text: t('landingPage.multtiplo.faq.text.1'),
    },
    {
      title: t('landingPage.multtiplo.faq.title.2'),
      text: t('landingPage.multtiplo.faq.text.2'),
    },
    {
      title: t('landingPage.multtiplo.faq.title.3'),
      text: t('landingPage.multtiplo.faq.text.3'),
    },
    {
      title: t('landingPage.multtiplo.faq.title.4'),
      text: t('landingPage.multtiplo.faq.text.4'),
    },
  ];

  const handleSetCurrentItem = (value: number) => {
    if (currentItem === value) {
      setCurrentItem(undefined);
      return;
    }
    setCurrentItem(value);
  };

  return (
    <FaqContent>
      <FaqTitle>{t('landingPage.multtiplo.faq.screenTitle')}</FaqTitle>
      <FaqBox>
        {faqItems.map((item, idx) => (
          <Item key={idx} onClick={() => handleSetCurrentItem(idx)} $customStyle={{ isActive: currentItem === idx }}>
            <HandleBox>
              <ItemTitle>{item.title}</ItemTitle>
              {currentItem === idx ? <MulttiploImgs.less /> : <MulttiploImgs.plus />}
            </HandleBox>

            {currentItem === idx && <ItemText>{item.text}</ItemText>}
          </Item>
        ))}
      </FaqBox>
    </FaqContent>
  );
};

export default Faq;
