export enum UnicoDocumentCameraTypes {
  CNH = 'CNH',
  CNH_FRENTE = 'CNH_FRENTE',
  CNH_VERSO = 'CNH_VERSO',
  CPF = 'CPF',
  OTHERS = 'OTHERS',
  RG_FRENTE = 'RG_FRENTE',
  RG_FRENTE_NOVO = 'RG_FRENTE_NOVO',
  RG_VERSO = 'RG_VERSO',
  RG_VERSO_NOVO = 'RG_VERSO_NOVO',
}
