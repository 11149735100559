import { Column, Header, Row, Separator, Table } from './styles';

export interface TableValues {
  header: string;
  values: string[] | JSX.Element[] | (string | JSX.Element)[];
  fullLength?: boolean;
  maxWidth?: string;
}

interface TableListProps {
  tableValues: TableValues[];
}

export const SimpleTable = ({ tableValues }: TableListProps) => {
  return (
    <>
      <Table $internal={true}>
        {tableValues.map(table => (
          <Column key={table.header} $maxWidth={table?.maxWidth}>
            <Header>{table.header}</Header>
            <Separator />
            {table.values.map((row, index) => (
              <>
                <Row key={index} $blank={row === ''} $fullLength={table.fullLength}>
                  <span>{row === '' ? '.' : row}</span>
                </Row>
                {index !== table.values.length - 1 && <Separator />}
              </>
            ))}
          </Column>
        ))}
      </Table>
    </>
  );
};
