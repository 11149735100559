import { BoxImage, ButtonBox, FoundDataText, FoundDataTitle, SliderButton } from 'components/landing-page/opening-screen/simulator/style';
import { InactiveAccountSVG } from 'images/inative-account';
import ButtonVariants from 'model/enums/buttonVariants';
import { RoutePath } from 'model/enums/route-path';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { StyledScreenContainer } from 'themes/globalStyles';
import { StyledFoundData } from '../styled';

interface MobileProps {}

const Mobile: React.FC<MobileProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <StyledScreenContainer
      $customStyle={{
        height: 'calc(100vh - 68px)',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <StyledFoundData>
        <FoundDataTitle>{t('register.inactiveAccount.title')}</FoundDataTitle>
        <BoxImage>
          <InactiveAccountSVG />
        </BoxImage>
        <FoundDataText>{t('register.inactiveAccount.subtitle')}</FoundDataText>
        <ButtonBox>
          <SliderButton $customStyle={{ variant: ButtonVariants.DEFAULT, width: '272px' }} onClick={() => history.push(RoutePath.HOME)}>
            {t('global.backStep')}
          </SliderButton>
        </ButtonBox>
      </StyledFoundData>
    </StyledScreenContainer>
  );
};

export default Mobile;
