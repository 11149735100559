import { StyledButton } from 'components/custom-button/styles';
import { Attachment } from 'model/attachment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { FiAlertCircle } from 'react-icons/fi';
import StringUtils from 'shared/util/string-utils';
import {
  Alignor,
  AttachmentBox,
  CampContainer,
  CampContent,
  CampName,
  CampsContainer,
  NoDataScreen,
  SelfieContainer,
  SelfieDisplay,
  Separator,
  StyledExpandIcon,
} from './styles';
import ImagePreviewModalWithRotateButton from 'components/image-preview-modal-with-rotate-button';
import { UploadedFileType } from 'model/uploaded-file';
import { GenericFieldProps } from '..';

interface CampsProps {
  camps: GenericFieldProps[];
  handleEditSelfie?: () => void;
}

const CustomSelfieStyles = {
  height: '86px',
  margin: 'auto',
  width: 'fit-content',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
};

interface ModalData {
  documentName?: string;
  presignedUrl?: string;
}

export const CampDisplay = ({ camps, handleEditSelfie }: CampsProps) => {
  const { t } = useTranslation();
  const [expandModal, setExpandModal] = useState<boolean>(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [dataModal, setDataModal] = useState<ModalData>();

  if (camps.length === 0) {
    return (
      <NoDataScreen>
        <FiAlertCircle />
        <p>{t('clientData.noSentData')}</p>
      </NoDataScreen>
    );
  }

  return (
    <>
      <CampsContainer>
        {camps?.map((elem, index) =>
          elem.hide ? null : elem.isElement ? (
            elem.content
          ) : elem.name === t('enums.campNames.creditScore') ? (
            <CampContainer key={index}>
              <>
                <CampName>{elem?.name}</CampName>
                <CampContent>{elem.content!['score']}</CampContent>
              </>
            </CampContainer>
          ) : typeof elem?.content !== 'object' || !elem?.content ? (
            <CampContainer isFullLineField={elem.isFullLineField} $maxWidth={elem.maxWindth} key={index}>
              <>
                <CampName>{elem?.name}</CampName>
                <Alignor>
                  <CampContent isFullLineField={elem.isFullLineField}>
                    {elem?.content === 'true' || elem?.content === true
                      ? t('global.button.yes')
                      : elem?.content === 'false' || elem?.content === false
                        ? t('global.button.no')
                        : elem.content
                          ? elem.content
                          : '...'}
                  </CampContent>
                  {typeof elem?.content === 'boolean' && elem?.content ? (
                    <AiOutlineCheck style={{ color: '#0DB052' }} />
                  ) : typeof elem?.content === 'boolean' && !elem?.content ? (
                    <AiOutlineClose style={{ color: '#FC4F4F' }} />
                  ) : (
                    <></>
                  )}
                </Alignor>
                <Separator $red={typeof elem?.content === 'boolean' && !elem?.content} />
              </>
            </CampContainer>
          ) : !elem.selfie ? (
            <CampContainer
              key={index}
              onClick={() => {
                if (typeof elem.content === 'object') {
                  if (elem.content?.contentType === UploadedFileType.PDF) {
                    window.open(elem.content?.presignedUrl, '_blank');
                  } else {
                    setIsShowModal(true);
                    setDataModal({ documentName: elem.name, presignedUrl: elem.content?.presignedUrl });
                  }
                }
              }}
            >
              <>
                <CampName>{elem?.name}</CampName>
                <AttachmentBox>{(elem?.content as Attachment)?.fileName}</AttachmentBox>
                <Separator $red={typeof elem?.content === 'boolean' && !elem?.content} />
              </>
            </CampContainer>
          ) : (
            <SelfieContainer key={index}>
              <CampContainer
                $custom={CustomSelfieStyles}
                onClick={() => {
                  if (typeof elem.content === 'object') {
                    setIsShowModal(true);
                    setDataModal({ documentName: elem.name, presignedUrl: elem.content?.presignedUrl });
                  }
                }}
              >
                <>
                  <StyledExpandIcon />
                  <CampName style={{ width: 'fit-content' }}>{elem?.name}</CampName>
                  <SelfieDisplay $expanded={expandModal} src={StringUtils.getFileOrUrl(elem?.content as Attachment)} />
                </>
              </CampContainer>
              {!expandModal && (
                <StyledButton $justifyContent="center" $maxWidth="200px" $width="90%" onClick={handleEditSelfie}>
                  {t('selfie.new')}
                </StyledButton>
              )}
            </SelfieContainer>
          )
        )}
      </CampsContainer>

      <ImagePreviewModalWithRotateButton
        isShowModal={isShowModal}
        onCloseModal={() => setIsShowModal(false)}
        documentName={dataModal?.documentName}
        presignedUrl={dataModal?.presignedUrl}
      />
    </>
  );
};
