import { ReactComponent as XSvg } from 'images/x.svg';
import { ReactNode } from 'react';
import { StyledContainerPopup, StyledPopUp, StyledXIconContainer } from './styles';

export interface CustomComponentModal {
  children: ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const CustomComponentModal = ({ children, open, setOpen }: CustomComponentModal) => {
  return (
    <StyledPopUp position="center center" on="click" modal open={open} closeOnDocumentClick onClose={() => setOpen(!open)}>
      {close => (
        <StyledContainerPopup>
          <StyledXIconContainer>
            <XSvg onClick={() => close()} style={{ cursor: 'pointer' }} />
          </StyledXIconContainer>
          {children}
        </StyledContainerPopup>
      )}
    </StyledPopUp>
  );
};

export default CustomComponentModal;
