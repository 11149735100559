import styled from 'styled-components';

interface GlobalProps {
  $isMobile?: boolean;
}
export const Container = styled.div``;
export const Title = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #1b1a1b;
  margin-bottom: 10px;
`;
export const Subtitle = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #7f7f7f;
  margin-top: 24px;
`;
export const ResumeContainer = styled.div<GlobalProps>`
  width: ${props => (props.$isMobile ? '320px' : '634px')};
  height: 443px;
  margin: 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
`;
export const ContentContainer = styled.div<GlobalProps>`
  width: ${props => (props.$isMobile ? '320px' : '634px')};
  margin: ${props => (props.$isMobile ? '30px auto' : '20px auto')};
  padding-bottom: ${props => props.$isMobile && '30px'};
`;
export const DetailsContainer = styled.div<GlobalProps>`
  width: 100%;
  display: flex;
  flex-direction: ${props => (props.$isMobile ? 'column' : 'row')};
  gap: ${props => props.$isMobile && '5px'};
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  margin-top: 20px;
  border-top: 1px solid #e5e7e7;
`;
export const Text = styled.span`
  width: 100%;
  height: 320px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #7f7f7f;
  overflow: auto;
  margin-top: 10px;
`;
export const Link = styled.a`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #0524dd;
  text-decoration: none;
  min-width: 187px;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })``;
