import styled from 'styled-components';

interface IStyledProps {
  $error?: boolean;
  $disabled?: boolean;
}

export const StyledContainer = styled.div<IStyledProps>`
  height: 64px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  cursor: ${({ $disabled }) => !$disabled && 'pointer'};
  pointer-events: ${({ $disabled }) => $disabled && 'none'};
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  border: ${({ theme, $error }) => ($error ? `2px dashed ${theme.color.rejected}` : `1.5px dashed ${theme.color.primaryColor}`)};
  border-radius: 8px;
  color: ${({ theme, $error }) => ($error ? theme.color.rejected : theme.color.primaryColor)};
  font-size: 16px;
  font-weight: 700;

  svg path {
    fill: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const StyledInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 14px;
`;

export const StyledErrorMessage = styled.span`
  position: absolute;
  bottom: -26px;
  left: -1px;
  color: ${({ theme }) => theme.color.rejected};
  font-weight: 400;
  font-size: 12px;
`;
