import { SVGProps } from 'react';

interface CardBottomProp extends SVGProps<SVGSVGElement> {
  color: string;
}

export const CardBottomSvg = (props: CardBottomProp) => (
  <svg width={252} height={180} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.1}
      d="M-120-85.565c79.496-59.074 276.096-174.779 324.866-88.61 52.967 93.584-255.33 145.69-225.722 238.65C8.75 157.434 178.587 32.034 204.866 94.102c19.829 46.833-77.08 62.592-128.751 73.661-35.714 7.65-78.491 26.653-66.543 68.386 30.597 106.868 199.574-24.573 200.964-25.268"
      stroke={props.color}
      strokeWidth={80}
    />
  </svg>
);
