import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ValidationType } from 'model/enums/simulator-vefication';
import { GetValidationType } from 'model/organization-types';
import React, { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { simulatorParametersRequest } from 'reducer/system-parameters/actions';
import { useRedirect } from 'hooks/use-redirect/use-redirect';

const DEFAULT_VALUE: GetValidationType[] = [];

const OrganizationParametersContext = createContext<any>(DEFAULT_VALUE);

const OrganizationParametersProvider: React.FC<{ children: any }> = ({ children }) => {
  const systemParameters = useSelector((state: IRootState) => state.systemParameters.systemParameters);
  const status = useSelector((state: IRootState) => state.systemParameters.systemParameterStatus);

  const dispatch = useDispatch();

  useEffect(() => {
    if (systemParameters == null && status === HttpRequestStatus.NOOP) {
      dispatch(simulatorParametersRequest(`${ValidationType.LANDING_PAGE_TYPE},${ValidationType.LOGIN_TYPE}`));
    }
  }, [systemParameters, status]);

  return <OrganizationParametersContext.Provider value={{ systemParameters, status }}>{children}</OrganizationParametersContext.Provider>;
};

export { OrganizationParametersProvider };
export default OrganizationParametersContext;
