import Autocomplete from '@mui/material/Autocomplete';
import { ControlLabel, InputText } from 'components/custom-text-field/styles';
import { ReactComponent as IconDropdown } from 'images/icon-dropdown.svg';
import { Pagination } from 'model/pageable';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Container, StyledListBox } from './styles';

interface Props extends WithTranslation {
  data: any[];
  keysValuesToDisplayed?: string[];
  label: string;
  pagination?: Pagination;
  errorMessage?: string;
  defaultValue?: any;
  placeholder?: string;
  setPagination?: Dispatch<SetStateAction<Pagination>>;
  inputValue?: (value: string) => void;
  optionSelected: (value: any) => void;
}

const CustomAutocomplete = ({
  data,
  keysValuesToDisplayed,
  label,
  pagination,
  errorMessage,
  defaultValue,
  placeholder,
  setPagination,
  inputValue,
  optionSelected,
  t,
}: Props) => {
  const [selectedDropdown, setSelectedDropdown] = useState<any>(defaultValue);
  const [valueEnteredInput, setValueEnteredInput] = useState<string>('');

  useEffect(() => {
    optionSelected(selectedDropdown);
  }, [selectedDropdown]);

  useEffect(() => {
    if (inputValue != null && pagination != null) {
      inputValue(valueEnteredInput);
      pagination.page = 0;
    }
  }, [valueEnteredInput]);

  return (
    <Container>
      <Autocomplete
        ListboxProps={{
          style: {
            maxHeight: 140,
          },
          onScroll: (event: React.SyntheticEvent) => {
            const listboxNode = event.currentTarget;

            if (pagination != null && setPagination != null) {
              if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                if (pagination.page + 1 < pagination.totalPages) {
                  setPagination({ ...pagination, page: pagination.page + 1 });
                }
              }
            }
          },
        }}
        options={data}
        getOptionLabel={(option: any) => {
          if (keysValuesToDisplayed != null) {
            let formattedText = '';
            keysValuesToDisplayed.map(key => {
              formattedText += `${option[key]} `;
            });
            return formattedText;
          } else {
            return option;
          }
        }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        autoComplete
        includeInputInList
        renderInput={params => (
          <ControlLabel
            label={label}
            labelPlacement="top"
            control={<InputText {...params} variant="outlined" helperText={errorMessage} placeholder={placeholder} />}
          />
        )}
        onChange={(event: any, value: any) => {
          setSelectedDropdown(value);
        }}
        onInputChange={(event: any, value: string) => setValueEnteredInput(value)}
        popupIcon={<IconDropdown />}
        PaperComponent={({ children }) => <StyledListBox>{children}</StyledListBox>}
        noOptionsText={t('global.fields.noOptions')}
        defaultValue={defaultValue}
      />
    </Container>
  );
};

export default withTranslation()(CustomAutocomplete);
