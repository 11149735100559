import { useTranslation } from 'react-i18next';
import { TotalValues } from '../..';
import { SimpleTable } from '../SimpleTable';
import { Footer } from '../SimpleTable/styles';
import { StepCard } from '../StepDisplay/styles';
import { Container, TitleContainer } from './styles';

export interface TableId {
  first: number;
  second?: number;
}
export interface TableValues {
  header: string;
  values: string[] | JSX.Element[] | (string | JSX.Element)[];
  ids?: TableId[];
  onClick?: (ids?: TableId) => void;
  fullLength?: boolean;
  maxWidth?: string;
}

interface TableListProps {
  loadingTable?: boolean;
  tableValues: TableValues[];
  totalValues?: TotalValues;
}

export const TableListSeries = ({ loadingTable, tableValues, totalValues }: TableListProps) => {
  const { t } = useTranslation();

  return (
    <Container style={loadingTable ? { opacity: '0.5' } : {}}>
      <TitleContainer>
        <StepCard onClick={() => {}} $selected={true}>
          {t(`admin.partners.table.title`)}
        </StepCard>
      </TitleContainer>
      <SimpleTable tableValues={tableValues} />
      <Footer>
        <span>{t(`admin.partners.table.total`)}</span>
        <span>{totalValues?.balanceAmountSum}</span>
        <span>{totalValues?.blockedAmountSum}</span>
        <span>{totalValues?.toReleaseAmountSum}</span>
      </Footer>
    </Container>
  );
};
