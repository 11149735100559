import styled, { css } from 'styled-components';

interface Props {
  $active?: boolean;
}

export const Container = styled.div`
  max-width: 70%;
  margin: 0 auto;
  margin-top: 32px;

  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 8px;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.gray21};
    margin-left: 4px;
  }
`;

export const BoxIcon = styled.div<Props>`
  width: 18px;
  height: 18px;
  border: 1px solid ${({ theme }) => theme.color.gray20};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ $active }) =>
    $active &&
    css`
      background-color: ${({ theme }) => theme.color.gray20};
    `}
`;

export const SeparatorStep = styled.div<Props>`
  flex: 1;
  height: 1px;
  background-color: ${({ theme, $active }) => ($active ? theme.color.primaryColor : theme.color.gray20)};
  margin: 0px 4px;
`;

export const StyledCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.primaryColor};
`;
