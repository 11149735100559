import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => theme.color.primaryColor};
  padding: 40px 40px;
  position: fixed;
`;
export const LogoContainer = styled.div`
  width: 100%;
  height: 28px;
  border-radius: 5px;
  margin-bottom: 50px;
  img {
    max-height: 58px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Footer = styled.div`
  width: 320px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
`;
export const FooterContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
export const FooterLabel = styled.div`
  margin-left: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  text-align: right;
  color: #ffffff;
`;
