import { ListType } from 'components/general-components/header/dropdown';
import AdminFiltersContext from 'context/admin-filters';
import StepType from 'model/enums/step-type';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { IRootState } from 'reducer';
import organizationsSystemStepsService from '../../../services/organization-system-steps-service';
import { DropdonwHeaderIcons } from '../../../images/header';
import { RegistrationStatus } from 'model/enums/registration-status';
import AuthUtils from 'shared/util/auth-utils';
import { RoutePath } from 'model/enums/route-path';
import { MdOutlineMedicalServices } from 'react-icons/md';
import { ModalType } from 'components/general-components/header/layout/desktop';
import { Tab } from 'features/admin/dashboard';
import { useAdmin } from 'provider/admin-general';
import { EditSignaturaSvg } from 'images/edit-signature';

export const useCustomSideMenu = () => {
  const history = useHistory();
  const [menuOpen, setMenuOpen] = useState<boolean>(true);
  const { tabsMenu } = useAdmin();
  const { resetFilters } = useContext(AdminFiltersContext);
  const location = useLocation();
  const { t } = useTranslation();
  const account = useSelector((state: IRootState) => state.authentication.account);
  const organizationId = useSelector((state: IRootState) => state.systemParameters.systemParameters?.fieldType?.organization.id);
  const [allStepsOrganization, setAllStepsOrganization] = useState<StepType[]>();

  useEffect(() => {
    if (organizationId != null) {
      getAllStepsOrganization();
    }
  }, [organizationId]);

  const matchUrlSelectedTab = (selectedTab: Tab) => {
    const match = tabsMenu.filter(tab => tab?.url === history.location.pathname)[0];
    return selectedTab?.label === match?.label;
  };

  const getAllStepsOrganization = () => {
    organizationsSystemStepsService.getSteps().then(response => {
      setAllStepsOrganization(response.map(step => step.stepType));
    });
  };

  const returnStatus = () => {
    switch (account?.client?.registrationStatus) {
      case RegistrationStatus.APPROVED:
        return true;
      case RegistrationStatus.UPDATED:
        return true;
      default:
        return false;
    }
  };

  const LpDropdonw: ListType[] = [
    { icon: <DropdonwHeaderIcons.dollarIcon />, text: t('header.myCredits'), link: RoutePath.CREDITS },
    { icon: <DropdonwHeaderIcons.logOut />, text: t('header.logout'), link: RoutePath.LOGOUT },
  ];

  const LoggedDropdonw: ListType[] = [{ icon: <DropdonwHeaderIcons.logOut />, text: t('header.logout'), link: RoutePath.LOGOUT }];

  const ApprovedLoggedDropdonw: ListType[] = [
    { icon: <DropdonwHeaderIcons.user />, text: t('header.myData'), link: RoutePath.PROFILE },
    allStepsOrganization?.includes(StepType.CCB)
      ? { icon: <DropdonwHeaderIcons.dollarIcon />, text: t('header.myCredits'), link: RoutePath.CREDITS }
      : {},
    allStepsOrganization?.includes(StepType.PROCEDURE)
      ? { icon: <DropdonwHeaderIcons.dollarIcon />, text: t('header.myProcedures'), link: RoutePath.PROCEDURES }
      : {},
    allStepsOrganization?.includes(StepType.CREDIT_ANTICIPATION)
      ? { icon: <DropdonwHeaderIcons.dollarIcon />, text: t('header.myAnticipations'), link: RoutePath.ANTICIPATIONS }
      : {},
    { icon: <DropdonwHeaderIcons.dollarIcon />, text: t('header.myPayments'), link: RoutePath.PAYMENTS },
    { icon: <DropdonwHeaderIcons.bank />, text: t('header.myContracts'), link: RoutePath.CONTRACTS },
    { icon: <DropdonwHeaderIcons.logOut />, text: t('header.logout'), link: RoutePath.LOGOUT },
  ];

  const AdminDropdown: ListType[] = [
    { icon: <DropdonwHeaderIcons.user />, text: t('header.myData'), link: RoutePath.ADMIN_HOME, modalType: ModalType.MY_DATA },
    { icon: <DropdonwHeaderIcons.logOut />, text: t('header.logout'), link: RoutePath.LOGOUT },
  ];

  const returnDropdonw = () => {
    if (AuthUtils.isAdmin()) {
      const stepsDropdown: ListType[] = [];

      if (allStepsOrganization?.includes(StepType.PROCEDURE)) {
        stepsDropdown.push({
          icon: <MdOutlineMedicalServices />,
          text: t('header.procedure'),
          link: RoutePath.PROCEDURE_ADMIN,
        });
      }

      if (allStepsOrganization?.includes(StepType.CCB)) {
        stepsDropdown.push({
          icon: <DropdonwHeaderIcons.dollarIcon />,
          text: t('header.creditAnalysis'),
          link: RoutePath.MY_FINANCING_ADMIN,
        });
      }

      if (allStepsOrganization?.includes(StepType.SIGNATURE)) {
        stepsDropdown.push({
          icon: <EditSignaturaSvg fill="rgb(127, 127, 127)" />,
          text: t('admin.dashboard.signatures'),
          link: RoutePath.SIGNATURE,
        });
      }

      return [...stepsDropdown, ...AdminDropdown];
    } else if (AuthUtils.isUser()) {
      if (account?.client?.registrationStatus !== RegistrationStatus.APPROVED) {
        return LoggedDropdonw;
      }
      if (!returnStatus()) {
        return LpDropdonw;
      }
      if (location.pathname === RoutePath.HOME) {
        return LpDropdonw;
      }
      return ApprovedLoggedDropdonw;
    }
  };

  return { resetFilters, menuOpen, setMenuOpen, matchUrlSelectedTab, returnDropdonw };
};
