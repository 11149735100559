import { FinancingType } from 'model/enums/financing-type';
import { Financing } from 'model/financing';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FiDollarSign } from 'react-icons/fi';
import { MdOutlineMedicalServices } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import StringUtils from 'shared/util/string-utils';
import { useTheme } from 'styled-components';
import BlueRightArrow from '../../../../../images/blueRightArrow.png';
import {
  AnalisisId,
  AnalisisStatus,
  CircleIcon,
  DataContainer,
  IdBox,
  MainContainer,
  Separate,
  SliceOne,
  SliceTwo,
  StyledArrow,
  VerticalLine,
  Wrapper,
} from './styles';

export const AnalysisCard = ({ analisis, getStatus }: { analisis: Financing; getStatus: any }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { color } = useTheme();

  const onHandleClick = async () => {
    if (analisis) {
      history.push(
        analisis.financingType === FinancingType.PERSONAL_CREDIT
          ? `/admin/creditos/analise/${analisis.id}`
          : analisis.financingType === FinancingType.PROCEDURE
            ? `/admin/procedimentos/analise/${analisis.id}`
            : '',
        { prevPath: history.location.pathname }
      );
    }
  };

  return (
    <MainContainer onClick={onHandleClick}>
      <div>
        <SliceOne>
          <Wrapper>
            {analisis.financingType === FinancingType.PERSONAL_CREDIT ? (
              <CircleIcon>
                <FiDollarSign color={color.primaryColor} size={20} />
              </CircleIcon>
            ) : analisis.financingType === FinancingType.PROCEDURE ? (
              <CircleIcon>
                <MdOutlineMedicalServices color={color.primaryColor} size={20} />
              </CircleIcon>
            ) : (
              <></>
            )}
            <AnalisisId>
              <IdBox>
                <span className="title">
                  {analisis.financingType === FinancingType.PERSONAL_CREDIT
                    ? t('admin.customerAnalysis.pageTitle')
                    : analisis.financingType === FinancingType.PROCEDURE
                      ? t('admin.customerAnalysis.procedureTitle')
                      : ''}{' '}
                </span>
                <span className="code">{`#${analisis?.identification}`}</span>
              </IdBox>
              {analisis?.ccb && (
                <>
                  <Separate />
                  <IdBox>
                    <span className="title">{t('admin.customerAnalysis.ccb')}</span>
                    <span className="code">{`#${analisis?.ccb}`}</span>
                  </IdBox>
                </>
              )}
            </AnalisisId>
          </Wrapper>
          <AnalisisStatus $styling={getStatus(analisis?.financingStatus, true)}>{getStatus(analisis?.financingStatus)}</AnalisisStatus>
        </SliceOne>
        <SliceTwo>
          <DataContainer>
            <span>{t('clientData.analysisCard.one')}</span>
            <span>{moment(analisis.createdDate).format('DD/MM/YYYY')}</span>
          </DataContainer>
          <VerticalLine />
          <DataContainer>
            <span>{t('clientData.analysisCard.two')}</span>
            <span>{StringUtils.moneyMaskPtbr(analisis.choosedValue)}</span>
          </DataContainer>
          <VerticalLine />
          <DataContainer>
            <span>{t('clientData.analysisCard.three')}</span>
            <span>
              {analisis.installmentsTotal && t('clientData.analysisCard.installments', { value: analisis.installmentsTotal })}
              {analisis.choosedValue && StringUtils.moneyMaskPtbr(analisis.installmentValue)}
            </span>
          </DataContainer>
        </SliceTwo>
      </div>
      <StyledArrow onClick={onHandleClick} src={BlueRightArrow} />
    </MainContainer>
  );
};
