import MobileHeader from 'components/mobile-header';
import CustomButton from 'components/custom-button';
import {
  LoadingContainer,
  PageTitleSection,
  StyledBoxCameraContainer,
  StyledButtonsContainer,
  StyledContainer,
  StyledInnerContainer,
  StyledPhotoPreview,
} from '../styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import ContractContext from 'context/contract';
import contractService from 'services/contract-service';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { IUnicoLiveness } from 'model/liveness';
import { RoutePath } from 'model/enums/route-path';
import { ReactComponent as ArrowBack } from 'images/arrow_back.svg';
import { ArrowContainer } from '../go-to-mobile/styles';
import {
  UnicoConfig,
  SDKEnvironmentTypes,
  UnicoCheckBuilder,
  SelfieCameraTypes,
  UnicoThemeBuilder,
  CallbackCamera,
  ErrorPictureResponse,
  SupportPictureResponse,
  SelfieCameraType,
} from 'unico-webframe';
import { useTheme } from 'styled-components';
import LoadingAnimation from 'components/loading-big-animation';
import {
  UNICO_CONFIG_HOST_INFO,
  UNICO_CONFIG_HOST_KEY,
  UNICO_CONFIG_HOST_NAME,
  UNICO_CONFIG_PROJECT_ID,
  UNICO_CONFIG_PROJECT_NUMBER,
} from 'config/constants';
import ScrollToTop from 'components/scroll-top';

const goToNextStepIn = 2300;

type OpenCameraState = {
  openCamera: (callback: CallbackCamera) => void;
  cameraName: string;
  isCameraReady: boolean;
  isUnicoCamera: boolean;
};

const ProofOfLife = ({ t }: WithTranslation) => {
  const theme = useTheme();
  const history = useHistory();
  const { signatureKey, contractResponse, setContractResponse, setErrorDescriptionModal } = useContext(ContractContext);
  const { isMobile, isTablet } = useResponsiveMobile();
  const [isShowCamera, setIsShowCamera] = useState<boolean>(true);
  const [photo, setPhoto] = useState<string>('');
  const [encryptedString, setEncryptedString] = useState<string>('');

  const [isLoading, setIsLoading] = useState(false);

  const [preparedCamera, setPreparedCamera] = useState<any>({} as any);

  const urlPathModels = `${window.location.protocol}//${window.location.host}/models`;

  const unicoTheme = new UnicoThemeBuilder()
    .setColorSilhouetteSuccess('#69c62f')
    .setColorSilhouetteError('#D50000')
    .setColorSilhouetteNeutral('#fcfcfc')
    .setBackgroundColor('#e9e9e9')
    .setColorText('#ff2c2c')
    .setBackgroundColorComponents(theme.color.primaryColor)
    .setColorTextComponents('#dff1f5')
    .setBackgroundColorButtons(theme.color.primaryColor)
    .setColorTextButtons('#dff1f5')
    .setBackgroundColorBoxMessage('#fff')
    .setColorTextBoxMessage('#D50000')
    .setHtmlPopupLoading(`<div>Carregando...</div>`)
    .setColorProgressBar(theme.color.primaryColor)
    .build();

  const unicoCamera = new UnicoCheckBuilder()
    .setResourceDirectory('/resources')
    .setModelsPath(urlPathModels)
    .setEnvironment(SDKEnvironmentTypes.PROD)
    .setTheme(unicoTheme)
    .setCustomFooterLogoDirectory('/unico_custom_footer_logo')
    .build();

  const config = new UnicoConfig()
    .setProjectNumber(UNICO_CONFIG_PROJECT_NUMBER)
    .setProjectId(UNICO_CONFIG_PROJECT_ID)
    .setMobileSdkAppId('')
    .setHostname(UNICO_CONFIG_HOST_NAME)
    .setHostInfo(UNICO_CONFIG_HOST_INFO)
    .setHostKey(UNICO_CONFIG_HOST_KEY);

  const sdkCallbacks: CallbackCamera = {
    on: {
      success: obj => {
        setPhoto(obj.base64);
        setEncryptedString(obj.encrypted);
        setIsShowCamera(false);
      },
      error: function (error: ErrorPictureResponse) {
        console.log('error', error);
        history.push(RoutePath.PROOF_LIFE_INSTRUCTIONS);
      },
      support: function (error: SupportPictureResponse) {
        console.log(error);
      },
    },
  };

  const handleGetContract = async () => {
    const response = await contractService
      .getContract(`${signatureKey}`)
      .then(response => {
        setContractResponse(response);
        return response;
      })
      .catch(() => {
        history.push(RoutePath.ERROR_ANALYSIS);
        return null;
      });

    return response;
  };

  const handleConfirmPhoto = (): void => {
    setIsLoading(true);

    const livenessData: IUnicoLiveness = {
      image64: photo,
      encrypted: encryptedString,
    };
    history.push(RoutePath.PROOF_LIFE_ANALYSIS);
    if (contractResponse?.uuid) {
      contractService
        .sendBiometryUnico(contractResponse?.uuid, livenessData)
        .then(response => {
          if (response.success === true) {
            history.push(RoutePath.STEP_COMPLETED);

            setTimeout(() => {
              history.push(RoutePath.REGISTER_DOCUMENT);
            }, goToNextStepIn);
          } else {
            setErrorDescriptionModal(response.error.description);
          }
        })
        .catch(() => history.push(RoutePath.ERROR_ANALYSIS));
    }
  };

  useEffect(() => {
    if (signatureKey !== null) {
      if (!isMobile && !isTablet) {
        return history.push(`${RoutePath.QR_CODE}/${signatureKey}`);
      }

      handleGetContract();
    }
  }, [signatureKey, isMobile, isTablet]);

  const prepareSelfieCamera = async (cameraType: SelfieCameraType, cameraName: string, isUnicoCamera: boolean) => {
    const { open } = await unicoCamera.prepareSelfieCamera(config, cameraType);

    setPreparedCamera({
      openCamera: open,
      isCameraReady: true,
      cameraName,
      isUnicoCamera,
    });
  };

  useEffect(() => {
    prepareSelfieCamera(SelfieCameraTypes.SMART, 'Facetec Liveness', true);
  }, []);

  const retakePic = () => {
    setPreparedCamera({} as OpenCameraState);

    setPhoto('');
    setIsShowCamera(true);
    prepareSelfieCamera(SelfieCameraTypes.SMART, 'Facetec Liveness', true);
  };

  useEffect(() => {
    if (preparedCamera.isCameraReady) {
      preparedCamera.openCamera(sdkCallbacks);
    }
  }, [preparedCamera]);

  return (
    <StyledContainer style={{ height: '100vh' }}>
      <MobileHeader></MobileHeader>
      <ScrollToTop />
      <StyledInnerContainer>
        <PageTitleSection>
          <ArrowContainer>
            <ArrowBack onClick={() => history.push(`/lets-sign`)} />
            <span>{t('letsSign.stepOfTotal', { step: '2' })}</span>
          </ArrowContainer>
          {isShowCamera ? (
            <>
              <p>{t('contract.takeDocument.satertingCamera')}</p>
              <span>{t('contract.takeDocument.waitStarting')}</span>
            </>
          ) : (
            <>
              <p>{t('contract.proofOfLife.title')}</p>
              <span>{t('contract.proofOfLife.subtitle2')}</span>
              <br />
              <span>{t('contract.proofOfLife.clearAndUndistorted')}</span>
              <br />
              <span>{t('contract.proofOfLife.noGlassesOrAccessories')}</span>
              <br />
              <span>{t('contract.proofOfLife.neutralExpression')}</span>
            </>
          )}
        </PageTitleSection>
        {isShowCamera && (
          <StyledBoxCameraContainer style={{ display: 'block' }}>
            <div id="box-camera"></div>
          </StyledBoxCameraContainer>
        )}
        {!isShowCamera && (
          <>
            <StyledPhotoPreview src={`data:image/jpeg;base64,${photo}`} />
            <StyledButtonsContainer>
              <CustomButton
                isInvertColor
                className="mobile-button--gofoward-inverted-color"
                onClick={() => {
                  retakePic();
                }}
              >
                {t('global.button.newPhoto')}
              </CustomButton>
              <CustomButton
                className="mobile-button--gofoward"
                disabled={isLoading}
                onClick={() => {
                  handleConfirmPhoto();
                }}
              >
                {t('global.button.confirm')}
              </CustomButton>
            </StyledButtonsContainer>
          </>
        )}
      </StyledInnerContainer>

      {isShowCamera && (
        <LoadingContainer>
          <LoadingAnimation />
        </LoadingContainer>
      )}

      <div className="waiting-step-footer">{t('contract.footer')}</div>
    </StyledContainer>
  );
};

export default withTranslation()(ProofOfLife);
