import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerInput = styled.div`
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
`;

export const Input = styled.input`
  display: none;
`;

export const PaintSvg = styled.span`
  svg path {
    border: 1px solid red !important;
    fill: ${({ theme }) => theme.color.primaryColor};
  }

  svg circle {
    fill: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.black1b};
  line-height: 1px;

  @media (max-width: 575.98px) {
    font-size: 12px;
  }
`;
