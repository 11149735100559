import MobileHeader from 'components/mobile-header';
import RG from '../../../images/RG.png';
import {
  ContractList,
  ImageContainer,
  PageTitleSection,
  StyledCardShowContractContainer,
  StyledButtonsContainer,
  StyledPhotoPreview,
} from '../styles';
import { StyledContainer, StyledInnerContainer, LivenessContainer } from '../styles';
import CustomButton from 'components/custom-button';
import CardShowContract from 'components/card-show-contract';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import ContractContext from 'context/contract';

import CustomComponentModal from 'components/custom-conmponent-modal';
import { ContractPdf } from 'features/admin/contractScreen/components/contractPdf';
import { isEmpty } from 'lodash';
import { ReactComponent as CameraIconSvg } from 'images/camera.svg';
import { LivenessComponent } from '../proof-of-life/Liveness';
import FaceMatch from 'model/enums/face-match';
import { AttachmentType } from 'model/enums/attachment-types';
import organizationsSystemStepsService from 'services/organization-system-steps-service';
import contractService from 'services/contract-service';
import SystemStepCategory from 'model/enums/system-step-category';
import { RoutePath } from 'model/enums/route-path';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { ArrowContainer } from '../go-to-mobile/styles';
import { ReactComponent as ArrowBack } from 'images/arrow_back.svg';
import { isIOS } from 'react-device-detect';
import { isDeviceIOS } from 'shared/util/utils';

const SelfieInstructions = ({ t }: WithTranslation) => {
  const history = useHistory();

  const { documentPresignUrl, signatureKey, contractResponse, setContractResponse } = useContext(ContractContext);
  const { isMobile, isTablet } = useResponsiveMobile();

  const [showComponentModal, setShowComponentModal] = useState<boolean>(false);

  const [photo, setPhoto] = useState<string>('');
  const [isShowCamera, setIsShowCamera] = useState<boolean>(false);

  const [orgStep, setOrgStep] = useState<SystemStepCategory[]>([]);

  const handleGetContract = async () => {
    const response = await contractService
      .getContract(`${signatureKey}`)
      .then(response => {
        setContractResponse(response);
        return response;
      })
      .catch(() => {
        history.push(RoutePath.ERROR_ANALYSIS);
        return null;
      });

    return response;
  };

  const handleGetSystemSteps = () => {
    organizationsSystemStepsService
      .getSteps()
      .then(response => setOrgStep(response.map(systemSteps => systemSteps.systemStep.step)))
      .catch(() => history.push(RoutePath.ERROR_ANALYSIS));
  };

  const handleCreateFaceMatch = (data: FaceMatch) => {
    contractService
      .createFaceMatch(data)
      .then(() =>
        handleGetContract().then(response => {
          if (response?.isFaceMatchDone) {
            const goToNextStepIn = 2300;

            history.push(RoutePath.STEP_COMPLETED);

            setTimeout(() => {
              history.push(RoutePath.SIGNATURE_SUCCESS);
            }, goToNextStepIn);
          } else {
            history.push(RoutePath.SIGNATURE_RELEASE);
          }
        })
      )
      .catch(() => history.push(RoutePath.ERROR_ANALYSIS));
  };

  const getDevicePixelRatio = (): number => {
    let mediaQuery;
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      mediaQuery =
        '(-webkit-min-device-pixel-ratio: 2),\
      (min-resolution: 2dppx)';
      if (window.matchMedia(mediaQuery).matches) {
        return 2;
      }
    }

    if (window.devicePixelRatio !== undefined && !isFirefox) {
      return window.devicePixelRatio;
    } else if (window.matchMedia) {
      if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        mediaQuery =
          '(-webkit-min-device-pixel-ratio: 2),\
        (min-resolution: 2dppx)';
        if (window.matchMedia(mediaQuery).matches) {
          return 2;
        }
      } else {
        mediaQuery =
          '(-webkit-min-device-pixel-ratio: 1.5),\
      (min--moz-device-pixel-ratio: 1.5),\
      (-o-min-device-pixel-ratio: 3/2),\
      (min-resolution: 1.5dppx)';
        if (window.matchMedia(mediaQuery).matches) {
          return 1.5;
        }
        mediaQuery =
          '(-webkit-min-device-pixel-ratio: 2),\
      (min--moz-device-pixel-ratio: 2),\
      (-o-min-device-pixel-ratio: 2/1),\
      (min-resolution: 2dppx)';
        if (window.matchMedia(mediaQuery).matches) {
          return 2;
        }
        mediaQuery =
          '(-webkit-min-device-pixel-ratio: 0.75),\
      (min--moz-device-pixel-ratio: 0.75),\
      (-o-min-device-pixel-ratio: 3/4),\
      (min-resolution: 0.75dppx)';
        if (window.matchMedia(mediaQuery).matches) {
          return 1.5;
        }
      }
    }
    return 1;
  };

  const handleTakingPhoto = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'user',
        },
      });
      const videoTrack = stream.getVideoTracks()[0];
      const settings = videoTrack.getSettings();
      const videoElement = document.createElement('video');
      videoElement.srcObject = stream;
      videoElement.setAttribute('muted', '');
      videoElement.playsInline = true;
      await videoElement.play();

      let canvasBackground = document.createElement('canvas');
      let width = (settings.width ?? 0) * getDevicePixelRatio();
      let height = (settings.height ?? 0) * getDevicePixelRatio();

      if (isIOS || isDeviceIOS()) {
        width = Math.min(settings.width ?? 0, settings.height ?? 0);
        height = Math.max(settings.width ?? 0, settings.height ?? 0);
      }

      canvasBackground.width = width;
      canvasBackground.height = height;

      canvasBackground.style.display = 'none';

      const context = canvasBackground.getContext('2d');

      if (context && (isIOS || isDeviceIOS())) {
        context.drawImage(videoElement, 0, 0, width, height);
        context.fillStyle = 'rgb(71,84,68)';
        context.fillRect(20, 50, 1, 1);
        context.fillStyle = 'rgb(211,190,124)';
        context.fillRect(422, 522, 1, 1);
        const pictureData = context?.getImageData(0, 0, width, height);
        context.putImageData(pictureData, 0, 0);
      } else if (context) {
        context.drawImage(videoElement, 0, 0, width, height);
        context.fillStyle = 'rgb(71,84,68)';
        context.fillRect(20, 50, 1, 1);
        context.fillStyle = 'rgb(211,190,124)';
        context.fillRect(422, 522, 1, 1);
        const pictureData = context?.getImageData(0, 0, width, height);
        context.putImageData(pictureData, 0, 0);
      }

      const photoURL = canvasBackground.toDataURL('image/png');

      setPhoto(photoURL);
      setIsShowCamera(false);

      stream.getTracks().forEach(track => track.stop());
    } catch (error) {
      console.error('Erro ao tirar foto:', error);
    }
  };

  const handleConfirmPhoto = (): void => {
    const fileData = photo.split(',');
    const positionBase64Image = 1;

    const faceMatch: FaceMatch = {
      faceMatch: {
        attachments: [
          {
            file: fileData[positionBase64Image],
            contentType: 'image/png',
            fileName: '',
            type: AttachmentType.FACE_MATCH,
          },
        ],
      },
    };

    if (
      !contractResponse?.isFaceMatchDone &&
      contractResponse?.faceMatchedProcessingTries === 0 &&
      !orgStep.includes(SystemStepCategory.LIVENESS)
    ) {
      history.push(RoutePath.SIGNATURE_SELFIE_ANALYSIS);
      handleCreateFaceMatch(faceMatch);
      return;
    }

    if (
      !contractResponse?.isFaceMatchDone &&
      contractResponse?.faceMatchedProcessingTries != null &&
      contractResponse.faceMatchedProcessingTries > 0 &&
      contractResponse?.canRetry
    ) {
      faceMatch.id = contractResponse?.id;
      history.push(RoutePath.SIGNATURE_ANALYSIS);
      handleCreateFaceMatch(faceMatch);
      return;
    }
  };

  useEffect(() => {
    if (signatureKey != null) {
      if (!isMobile && !isTablet) {
        return history.push(`${RoutePath.QR_CODE}/${signatureKey}`);
      }

      handleGetContract().then(() => handleGetSystemSteps());
    } else {
      history.push(RoutePath.ERROR_ANALYSIS);
    }
  }, [signatureKey, isMobile, isTablet]);

  useEffect(() => {
    if (orgStep.length > 0) {
      if (contractResponse?.isFaceMatchDone) {
        history.push(RoutePath.SIGNATURE_SUCCESS);
        return;
      }

      if (
        !contractResponse?.isFaceMatchDone &&
        contractResponse?.faceMatchedProcessingTries === 0 &&
        orgStep.includes(SystemStepCategory.LIVENESS)
      ) {
        history.push(RoutePath.SIGNATURE_ANALYSIS);
        handleCreateFaceMatch({ id: contractResponse?.id });
        return;
      }

      if (
        !contractResponse?.isFaceMatchDone &&
        contractResponse?.faceMatchedProcessingTries != null &&
        contractResponse.faceMatchedProcessingTries > 0 &&
        !contractResponse?.canRetry
      ) {
        history.push(RoutePath.SIGNATURE_RELEASE);
      }
    }
  }, [orgStep]);

  return (
    <StyledContainer>
      <MobileHeader />
      <PageTitleSection>
        <ArrowContainer>
          <ArrowBack onClick={() => history.push(`/lets-sign`)} />
          <span>{t('letsSign.stepOfTotal', { step: '4' })}</span>
        </ArrowContainer>
        {!isShowCamera && isEmpty(photo) ? (
          <>
            <p>{t('contract.selfieInstructions.title')}</p>
            <span>{t('contract.selfieInstructions.subtitle')}</span>
          </>
        ) : (
          <>
            <p>{t('selfie.title')}</p>
          </>
        )}
      </PageTitleSection>
      <StyledInnerContainer>
        {isShowCamera && (
          <LivenessContainer>
            <LivenessComponent
              sucessFunction={() => {
                /* Empty */
              }}
              setBase64={handleTakingPhoto}
            />
          </LivenessContainer>
        )}

        {isEmpty(photo) && !isShowCamera ? (
          <>
            <ImageContainer>
              <img src={RG} alt="document-image" />
            </ImageContainer>
            <ContractList>
              <li>{t('contract.selfieInstructions.contractList.firstItem')}</li>
              <li>{t('contract.selfieInstructions.contractList.secondItem')}</li>
              <li>{t('contract.selfieInstructions.contractList.thirdItem')}</li>
              <li>{t('contract.selfieInstructions.contractList.fourthItem')}</li>
              <li>{t('contract.selfieInstructions.contractList.fifthItem')}</li>
              <li>{t('contract.selfieInstructions.contractList.lastItem')}</li>
            </ContractList>

            <CustomButton className="mobile-button--gofoward" onClick={() => setIsShowCamera(true)}>
              <CameraIconSvg style={{ marginRight: '8px' }} />
              {t('global.button.newSelfie')}
            </CustomButton>
          </>
        ) : (
          <>
            <StyledPhotoPreview src={photo} />
            <StyledButtonsContainer>
              <CustomButton
                isInvertColor
                className="mobile-button--gofoward-inverted-color"
                onClick={() => {
                  setPhoto('');
                  setIsShowCamera(true);
                }}
              >
                {t('global.button.newSelfie')}
              </CustomButton>
              <CustomButton className="mobile-button--gofoward" onClick={() => handleConfirmPhoto()}>
                {t('global.button.confirm')}
              </CustomButton>
            </StyledButtonsContainer>
          </>
        )}

        <StyledCardShowContractContainer>
          <CardShowContract
            onClick={() => {
              setShowComponentModal(true);
            }}
          />
        </StyledCardShowContractContainer>
        <CustomComponentModal open={showComponentModal} setOpen={setShowComponentModal}>
          <ContractPdf url={documentPresignUrl ?? ''} />
        </CustomComponentModal>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default withTranslation()(SelfieInstructions);
