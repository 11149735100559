import styled from 'styled-components';

export const Background = styled.div`
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => `linear-gradient(to bottom, ${theme.color.primaryColor} 50%, white 0)`};
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Container = styled.div`
  padding: 40px 16px;
`;
export const LoginContainer = styled.div``;
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0 10px 0;
`;
export const LogoBox = styled.div`
  width: 150px;
  height: 28px;
  border-radius: 5px;
`;
export const Title = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
`;
export const SubTitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
`;

export const Resend = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.primaryColor};
  cursor: pointer;
  margin-top: 15px;
`;
