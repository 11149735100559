import styled from 'styled-components';

interface Props {
  $noHeader?: boolean;
  $blank?: boolean;
  $internal?: boolean;
  $fullLength?: boolean;
}

interface PropsColumn {
  $maxWidth?: string;
}

export const Container = styled.div`
  display: flex;
  padding: 4px 24px;
  margin-top: 24px;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--gray-light-4, #eee);
  background: var(--gray-bw-white, #fff);
`;

export const Table = styled.div<Props>`
  background-color: ${({ theme, $internal }) => ($internal ? 'transparent' : theme.color.white)};
  border: 1px solid ${({ theme, $internal }) => ($internal ? 'transparent' : theme.color.borderGray)};
  box-shadow: 0px 2px 12px ${({ theme, $internal }) => ($internal ? 'transparent' : theme.color.shadow)};
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  display: flex;
  height: 56px;
  padding: 16px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const Column = styled.div<PropsColumn>`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  max-width: ${({ $maxWidth }) => $maxWidth && $maxWidth};
`;

export const Header = styled.span<Props>`
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
  height: 42px;

  display: flex;
  padding: 12px 0px;
  align-items: flex-start;
  color: ${({ theme, $noHeader }) => ($noHeader ? 'transparent' : theme.color.black0)};
  color: var(--gray-dark-5, #7f7f7f);

  font-family: Satoshi;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.gray20};
`;

export const Row = styled.div<Props>`
  cursor: pointer;

  & > span {
    font-style: normal;
    font-size: 16px;
    height: 41px;
    line-height: 24px;
    font-weight: 400;
    color: ${({ theme, $blank }) => ($blank ? 'transparent' : theme.color.gray19)};

    display: flex;
    padding: 10px 0px;
    align-items: center;
    align-self: stretch;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${({ $fullLength }) => !$fullLength && 200}px;

    @media only screen and (max-width: 1145px) {
      max-width: ${({ $fullLength }) => !$fullLength && 100}px;
    }

    @media only screen and (max-width: 1550px) {
      max-width: ${({ $fullLength }) => !$fullLength && 150}px;
    }
  }
`;

export const SpanPagination = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray22};
  cursor: pointer;
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
`;

export const InputPage = styled.div`
  input + span {
    margin-left: 16px;
  }
  display: flex;
  align-items: center;

  .clicked {
    background: var(--primary-light, #eff6ff);
    color: var(--gray-dark-2, #333);
    text-align: center;
    font-family: Satoshi;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
  input {
    max-width: 40px;
    background-color: ${({ theme }) => theme.color.white};
    border: 1px solid ${({ theme }) => theme.color.gray23};
    border-radius: 6px;
    text-align: center;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: ${({ theme }) => theme.color.black0};

    :focus {
      outline: none;
    }

    ::placeholder {
      color: ${({ theme }) => theme.color.gray22};
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
`;

export const ButtonsPage = styled.div`
  display: flex;
  align-items: center;
  button.left {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  button.right {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

export const Button = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.color.white};
  text-align: center;
  display: flex;
  align-items: center;

  :hover {
    border-color: ${({ theme }) => theme.color.primaryColorDarker};
    background-color: ${({ theme }) => theme.color.grayBackground};
  }
`;
