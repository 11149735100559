import ButtonVariants from 'model/enums/buttonVariants';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

interface StyledPopupProps {
  children: (close: any) => JSX.Element;
}

export const StyledPopup = styled(Popup)<StyledPopupProps>`
  &-overlay {
    background: rgba(0, 0, 0, 0.1);
  }
  &-content {
    width: 620px;
    height: 340px;
    background: #ffffff;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;

    position: relative;
  }
`;

export const ClosedPopUp = styled.button`
  background: none;
  border: none;

  position: absolute;
  right: 24px;
  top: 24px;
`;

export const IconBox = styled.div`
  color: #f6b330;
  font-size: 54px;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: #333333;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #4c4d4d;
`;

export const ButtonBox = styled.div`
  margin: 24px 0px 0px;

  display: flex;
  column-gap: 16px;
`;

interface ConfirmButtonProps {
  customStyle: {
    variant: string;
  };
}

export const ConfirmButton = styled.button<ConfirmButtonProps>`
  width: 160px;
  height: 48px;

  font-weight: 700;
  font-size: 16px;
  color: #ffffff;

  background: ${({ theme }) => theme.color.primaryColor};
  border: ${({ theme }) => `1px solid ${theme.color.primaryColor}`};

  border-radius: 24px;

  ${({ theme, customStyle }) => {
    switch (customStyle.variant) {
      case ButtonVariants.DEFAULT:
        return `
          background: ${theme.color.lpColors.color1};
        color: ${theme.color.lpColors.whatIsScreenTextColor};`;
      case ButtonVariants.CHANGED:
        return `background: ${theme.color.lpColors.color2};
        color: ${theme.color.lpColors.whatIsScreenTextColor};`;
      case ButtonVariants.CANCEL:
        return `background: ${theme.color.rejected};
            color: ${theme.color.lpColors.whatIsScreenTextColor};`;
      case ButtonVariants.DISABLED:
        return `background: rgba(0, 0, 0, 0.5);
                  color: ${theme.color.lpColors.whatIsScreenTextColor};`;
      default:
        return ``;
    }
  }}

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background: #ffffff;
    color: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const CancelButton = styled.button`
  width: 160px;
  height: 48px;

  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  border-radius: 24px;
  background: #ffffff;
  color: ${({ theme }) => theme.color.primaryColor};
  border: ${({ theme }) => `1px solid ${theme.color.primaryColor}`};

  :hover {
    color: #ffffff;
    background: ${({ theme }) => theme.color.primaryColor};
  }
`;
