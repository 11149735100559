import { FormInputsContainer, Row } from 'components/edit-forms/styles';
import { Column } from 'components/register-forms-mobile/styles';
import { SearchSelectInput } from 'components/search-selected-input';
import InputText from 'components/styled-input';
import { TextInput } from 'components/text-input';
import { Mask } from 'model/enums/mask-types';
import { useEditForm } from 'provider/edit-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ContainerInputsAddressDataMulttiplo = props => {
  const { t } = useTranslation();
  const { initialClientData, getCepData } = useEditForm();
  const { states, cities, stateID, handleSetStateID, getStates, getCities, statesTotalPages, citiesTotalPages } = useSelectLists();
  const [statePage, setStatePage] = useState(0);
  const [cityPage, setCityPage] = useState(0);
  const [stateName, setStateName] = useState('');
  const [stateAc, setStateAc] = useState('');
  const [cityName, setCityName] = useState('');
  const [resetCities, setResetCities] = useState(false);

  useEffect(() => {
    getStates({ name: stateName, page: statePage, size: 7, ac: stateAc }, true);
  }, [statePage]);

  useEffect(() => {
    if (stateID) {
      getCities(stateID, { name: cityName, page: cityPage, size: 7 }, true);
    }
  }, [cityPage, stateID]);

  const renderMobile = () => {
    return (
      <Column>
        <InputText
          isRequired
          disabled={props.onlyView}
          name="zipcode"
          label={t('register.inputs.cep')}
          placeholder={t('register.placeholder.cep')}
          mask={Mask.CEP}
          maxLength={9}
          defaultValue={initialClientData?.address?.zipcode ?? ''}
          onChange={e => {
            if (e.target.value.length === 9) {
              getCepData(e.target.value);
            }
          }}
        />
        <InputText
          isRequired
          disabled={props.onlyView}
          name="street"
          label={t('register.inputs.street')}
          placeholder={t('register.placeholder.street')}
          defaultValue={initialClientData?.address?.street ?? ''}
        />
        <InputText
          isRequired
          disabled={props.onlyView}
          name="number"
          label={t('register.inputs.number')}
          placeholder={t('register.placeholder.number')}
          defaultValue={initialClientData?.address?.number ?? ''}
          maxLength={5}
        />
        <InputText
          name="complement"
          disabled={props.onlyView}
          label={t('register.inputs.complement')}
          placeholder={t('register.placeholder.complement')}
          defaultValue={initialClientData?.address?.complement ?? ''}
        />
        <InputText
          name="reference"
          disabled={props.onlyView}
          label={t('register.inputs.reference')}
          placeholder={t('register.placeholder.reference')}
          defaultValue={initialClientData?.address?.reference ?? ''}
        />
        <InputText
          isRequired
          disabled={props.onlyView}
          name="district"
          label={t('register.inputs.district')}
          placeholder={t('register.placeholder.district')}
          defaultValue={initialClientData?.address?.district ?? ''}
        />
        <TextInput name="state" label={t('register.inputs.state')} isRequired hasCustomInput>
          <SearchSelectInput
            disabled={props.onlyView}
            name="state"
            placeholder={t('register.placeholder.state')}
            options={states.map(state => ({ label: state.name ?? '', value: state.id.toString() }))}
            defaultOptions={states.map(state => ({ label: state.name ?? '', value: state.id.toString() }))}
            defaultValue={initialClientData?.address?.city?.state?.name}
            handleChange={values => {
              const state = states.find(st => st.name === values.name);
              if (state != null) {
                handleSetStateID(state.id);
              }
              setStateName(values.name);
              setStatePage(0);
              getStates({ name: values.name, size: 7 });
              setResetCities(true);
            }}
            handleOnScroll={() => {
              if (statePage < statesTotalPages - 1) {
                setStatePage(statePage + 1);
              }
            }}
          />
        </TextInput>
        <TextInput name="city" label={t('register.inputs.city')} isRequired hasCustomInput>
          <SearchSelectInput
            disabled={props.onlyView}
            name="city"
            placeholder={t('register.placeholder.city')}
            defaultValue={initialClientData?.address?.city?.name}
            options={cities.map(city => ({ label: city?.name ?? '', value: city.id.toString() }))}
            defaultOptions={cities.map(city => ({ label: city?.name ?? '', value: city.id.toString() }))}
            handleChange={values => {
              setResetCities(false);
              setCityName(values.name);
              setCityPage(0);
              if (stateID) {
                getCities(stateID, { name: values.name, size: 7 });
              }
            }}
            handleOnScroll={() => {
              if (cityPage < citiesTotalPages - 1) {
                setCityPage(cityPage + 1);
              }
            }}
          />
        </TextInput>
      </Column>
    );
  };

  return (
    <FormInputsContainer $visible={props.visible}>
      {props.mobile ? (
        renderMobile()
      ) : (
        <>
          <Row>
            <InputText
              isRequired
              disabled={props.onlyView}
              name="zipcode"
              label={t('register.inputs.cep')}
              placeholder={t('register.placeholder.cep')}
              mask={Mask.CEP}
              maxLength={9}
              defaultValue={initialClientData?.address?.zipcode ?? ''}
              onChange={e => {
                if (e.target.value.length === 9) {
                  getCepData(e.target.value);
                }
              }}
            />
            <InputText
              isRequired
              disabled={props.onlyView}
              name="street"
              label={t('register.inputs.street')}
              placeholder={t('register.placeholder.street')}
              defaultValue={initialClientData?.address?.street ?? ''}
            />
            <InputText
              isRequired
              disabled={props.onlyView}
              name="number"
              label={t('register.inputs.number')}
              placeholder={t('register.placeholder.number')}
              defaultValue={initialClientData?.address?.number ?? ''}
              maxLength={5}
            />
            <InputText
              name="complement"
              disabled={props.onlyView}
              label={t('register.inputs.complement')}
              placeholder={t('register.placeholder.complement')}
              defaultValue={initialClientData?.address?.complement ?? ''}
            />
            <InputText
              name="reference"
              disabled={props.onlyView}
              label={t('register.inputs.reference')}
              placeholder={t('register.placeholder.reference')}
              defaultValue={initialClientData?.address?.reference ?? ''}
            />
            <InputText
              isRequired
              disabled={props.onlyView}
              name="district"
              label={t('register.inputs.district')}
              placeholder={t('register.placeholder.district')}
              defaultValue={initialClientData?.address?.district ?? ''}
            />
            <TextInput name="state" label={t('register.inputs.state')} isRequired hasCustomInput>
              <SearchSelectInput
                disabled={props.onlyView}
                name="state"
                placeholder={t('register.placeholder.state')}
                options={states.map(state => ({ label: state.name ?? '', value: state.id.toString() }))}
                defaultOptions={states.map(state => ({ label: state.name ?? '', value: state.id.toString() }))}
                defaultValue={initialClientData?.address?.city?.state?.name}
                handleChange={values => {
                  const state = states.find(st => st.name === values.name);
                  if (state != null) {
                    handleSetStateID(state.id);
                  }
                  setStateName(values.name);
                  setStatePage(0);
                  getStates({ name: values.name, size: 7 });
                  setResetCities(true);
                }}
                handleOnScroll={() => {
                  if (statePage < statesTotalPages - 1) {
                    setStatePage(statePage + 1);
                  }
                }}
              />
            </TextInput>
            <TextInput name="city" label={t('register.inputs.city')} isRequired hasCustomInput>
              <SearchSelectInput
                disabled={props.onlyView}
                name="city"
                placeholder={t('register.placeholder.city')}
                defaultValue={initialClientData?.address?.city?.name}
                options={cities.map(city => ({ label: city?.name ?? '', value: city.id.toString() }))}
                defaultOptions={cities.map(city => ({ label: city?.name ?? '', value: city.id.toString() }))}
                handleChange={values => {
                  setResetCities(false);
                  setCityName(values.name);
                  setCityPage(0);
                  if (stateID) {
                    getCities(stateID, { name: values.name, size: 7 });
                  }
                }}
                handleOnScroll={() => {
                  if (cityPage < citiesTotalPages - 1) {
                    setCityPage(cityPage + 1);
                  }
                }}
              />
            </TextInput>
            <div style={{ width: '306px' }} />
          </Row>
        </>
      )}
    </FormInputsContainer>
  );
};
