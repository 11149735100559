import registerApi from 'api/register-api';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { Address } from 'model/address';
import { Client } from 'model/client';
import { useRegisterForm } from 'provider/register-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { FormContainer } from '../styles';
import { ContainerInputsAddress } from './inputs-address';

export const FormAddress = () => {
  const { onClickNext, initialClientData } = useRegisterForm();
  const { t } = useTranslation();

  const schema = yup.object({
    zipcode: yup.string().min(9, t('global.errorMessage.zipcode')).required(t('global.errorMessage.required')),
    street: yup.string().required(t('global.errorMessage.required')),
    number: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-only-number', t('global.errorMessage.anyWord'), value => {
        if (value != null) {
          return /^\d+$/.test(value);
        }
        return false;
      }),
    complement: yup.string(),
    district: yup.string().required(t('global.errorMessage.required')),
    state: yup.string().required(t('global.errorMessage.required')),
    city: yup.string().required(t('global.errorMessage.required')),
    yearsOfResidence: yup.string().required(t('global.errorMessage.required')),
  });

  const getStateId = async (uf: string) => {
    const res = await registerApi.getStates(7, 0, '', uf);
  };

  return (
    <FormContainer>
      <ReactHookFormProvider
        schema={schema}
        onSubmit={res => {
          getStateId(res.state);
          if (onClickNext) {
            const address: Address = {
              ...initialClientData?.address,
              zipcode: res.zipcode,
              street: res.street,
              number: res.number,
              complement: res.complement,
              district: res.district,
              reference: res.reference,
              yearsOfResidence: Number(res.yearsOfResidence),
            };
            onClickNext({ address } as Client);
          }
        }}
      >
        <ContainerInputsAddress />
      </ReactHookFormProvider>
    </FormContainer>
  );
};
