import styled from 'styled-components';

export const StyledScreenContainer = styled.div`
  width: 100%;
  height: 100vh;
`;

export const StyledMobileScreenContainer = styled.div`
  width: 100%;
  height: 100vh;
`;

export const Container = styled.div`
  display: flex;
  padding: 0px 48px;
`;
