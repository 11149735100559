import _ from 'lodash';
import { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { EmptyList } from '../empty-list';
import { Button, ButtonsPage, Column, Container, Header, InputPage, Pagination, Row, Separator, Table } from './styles';

export interface TableId {
  first: number;
  second?: number;
}
export interface TableValues {
  header: string;
  values: string[] | JSX.Element[] | (string | JSX.Element)[];
  ids?: TableId[];
  onClick?: (ids?: TableId) => void;
  fullLength?: boolean;
  maxWidth?: string;
  styleRow?: CSSProperties;
}

interface TableListProps {
  tableValues: TableValues[];
  page?: number;
  totalPages?: number;
  setNewPage?: (page: number) => void;
  editMode?: boolean;
  noPagination?: boolean;
  internal?: boolean;
}

export const TableList = ({ tableValues, totalPages, page, setNewPage, noPagination, editMode, internal }: TableListProps) => {
  const { color } = useTheme();
  const [currentPage, setCurrentPage] = useState('1');
  const [isEmpty, setIsEmpty] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const values = tableValues.map(table => table.values).flat();
    if (values.length <= 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, [tableValues]);

  useEffect(() => {
    if (page != null) {
      setCurrentPage((page + 1).toString());
    }
  }, [page]);

  const onHandleChangePage = (value: string) => {
    const newPage = Number(value);

    if (Number.isNaN(newPage)) {
      return;
    }

    if (newPage === 0) {
      return setCurrentPage('');
    }

    if (totalPages && newPage > totalPages) {
      if (page != null) {
        return setCurrentPage((page + 1).toString());
      }
    }

    setCurrentPage(newPage.toString());
    handleSetNewPage(newPage);
  };

  const handleSetNewPage = _.debounce((newPage: number) => {
    if (setNewPage) {
      if (totalPages && newPage >= totalPages) {
        setNewPage(totalPages - 1);
      }

      setNewPage(newPage - 1);
    }
  }, 500);

  const onHandleClickChangePage = (direction: 'left' | 'right') => {
    const newPage = direction === 'left' ? Number(currentPage) - 1 : direction === 'right' ? Number(currentPage) + 1 : Number(currentPage);

    if (setNewPage && newPage >= 1 && totalPages && newPage <= totalPages) {
      setCurrentPage(newPage.toString());
      setNewPage(newPage - 1);
    }
  };

  if (isEmpty && !noPagination) {
    return <EmptyList />;
  }

  return (
    <Container>
      <Table $internal={internal}>
        {tableValues.map(table => (
          <Column key={table.header} $maxWidth={table?.maxWidth}>
            <Header>{table.header}</Header>
            <Separator />
            {table.values.map((row, index) => (
              <div key={index}>
                <Row
                  onClick={() => {
                    if (table.onClick && table.ids) {
                      table.onClick(table.ids[index]);
                    }
                  }}
                  $blank={row === ''}
                  $fullLength={table.fullLength}
                  style={table?.styleRow}
                >
                  <span>{row === '' ? '.' : row}</span>
                </Row>
                {index !== table.values.length - 1 && <Separator key={`separator-${index}`} />}
              </div>
            ))}
          </Column>
        ))}
      </Table>
      {!noPagination && (
        <Pagination>
          <InputPage>
            <span>{t('admin.dashboard.table.page')}</span>
            <input
              type="number"
              placeholder={currentPage.toString() ?? '1'}
              value={currentPage}
              onChange={e => onHandleChangePage(e.target.value)}
              onBlur={() => {
                if (currentPage === '') {
                  setCurrentPage('1');
                  handleSetNewPage(1);
                }
              }}
            />
            <span>{t('admin.dashboard.table.totalPage', { total: totalPages })}</span>
          </InputPage>
          <ButtonsPage>
            <Button className="left" onClick={() => onHandleClickChangePage('left')}>
              <FiChevronLeft color={color.primaryColor} />
            </Button>
            <Button className="right" onClick={() => onHandleClickChangePage('right')}>
              <FiChevronRight color={color.primaryColor} />
            </Button>
          </ButtonsPage>
        </Pagination>
      )}
    </Container>
  );
};
