import authenticationApi from 'api/authentication-api';
import { AxiosResponse } from 'axios';
import { AUTHORITIES } from 'config/constants';
import { jwtDecode as jwt_decode } from 'jwt-decode';
import { Authentication } from 'model/authentication';
import { User } from 'model/user';
import { UserParameters } from 'model/user-parameters';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import AuthUtils from 'shared/util/auth-utils';
import {
  getAccountParametersError,
  getAccountParametersRequest,
  getAccountParametersSuccess,
  getSessionError,
  getSessionRequest,
  getSessionSuccess,
  loginError,
  loginRequest,
  loginSuccess,
  loginValidate,
} from './actions';
import { AuthenticationActionTypes } from './types';

function* handleLogin(action: ReturnType<typeof loginRequest>) {
  try {
    const result: AxiosResponse<Authentication> = yield call(authenticationApi.login, action.payload);

    if (result.status !== 200 && result.data.token != null) {
      const errorMessage: string = result.status === 401 ? 'badCredentials' : 'An unknown error occured.';
      yield put(loginError(errorMessage));
      return;
    }

    const jwt: string = result.data.token!;
    const decodeJwt: any = jwt_decode(jwt);

    if (decodeJwt.auth.includes(AUTHORITIES.ADMIN) || decodeJwt.auth.includes(AUTHORITIES.USER)) {
      AuthUtils.setToken(jwt);
      yield put(getSessionRequest());
      yield put(getAccountParametersRequest());
      return;
    }
  } catch (err: any) {
    if (err && err.stack) {
      if (err.response.data.code === 'error.user.not.active') {
        yield put(loginValidate(err.response.data.code));
      } else {
        yield put(loginError(err.stack));
      }
    } else {
      yield put(loginError('An unknown error occured.'));
    }
  }
}
function* handleAdminLogin(action: ReturnType<typeof loginRequest>) {
  try {
    const result: AxiosResponse<Authentication> = yield call(authenticationApi.adminLogin, action.payload);

    if (result.status !== 200 && result.data.token != null) {
      const errorMessage: string = result.status === 401 ? 'badCredentials' : 'An unknown error occured.';
      yield put(loginError(errorMessage));
      return;
    }

    const jwt: string = result.data.token!;
    // @ts-ignore
    const decode = jwt_decode(jwt!).auth;

    if (
      decode.includes(AUTHORITIES.ADMIN) ||
      decode.includes(AUTHORITIES.INTERNAL_ADMIN) ||
      decode.includes(AUTHORITIES.PARTNER) ||
      decode.includes(AUTHORITIES.INTERNAL) ||
      decode.includes(AUTHORITIES.USER)
    ) {
      AuthUtils.setToken(jwt);
      yield put(getSessionRequest());
      yield put(loginSuccess());
      return;
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(loginError(err.stack));
    } else {
      yield put(loginError('An unknown error occured.'));
    }
  }
}

function* handleGetSession() {
  try {
    const result: AxiosResponse<User> = yield call(authenticationApi.getSession);

    if (result.status === 200 && result.data != null) {
      yield put(getSessionSuccess(result.data));
    } else {
      yield put(getSessionError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getSessionError(err.stack));
    } else {
      yield put(getSessionError('An unknown error occured.'));
    }
  }
}

function* handleGetAccountParameters() {
  try {
    const result: AxiosResponse<UserParameters> = yield call(authenticationApi.getAccountParameters);

    if (result.status === 200 && result.data != null) {
      yield put(getAccountParametersSuccess(result.data));
    } else {
      yield put(getAccountParametersError(result.statusText));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getAccountParametersError(err.stack));
    } else {
      yield put(getAccountParametersError('An unknown error occured.'));
    }
  }
}

function* watchGetAccountParametersRequest() {
  yield takeEvery(AuthenticationActionTypes.GET_ACCOUNT_PARAMETERS_REQUEST, handleGetAccountParameters);
}

function* watchLoginRequest() {
  yield takeEvery(AuthenticationActionTypes.LOGIN_REQUEST, handleLogin);
}
function* watchAdminLoginRequest() {
  yield takeEvery(AuthenticationActionTypes.LOGIN_ADMIN_REQUEST, handleAdminLogin);
}

function* watchGetSessionRequest() {
  yield takeEvery(AuthenticationActionTypes.GET_SESSION_REQUEST, handleGetSession);
}

function* authenticationSaga() {
  yield all([fork(watchLoginRequest), fork(watchAdminLoginRequest), fork(watchGetSessionRequest), fork(watchGetAccountParametersRequest)]);
}

export default authenticationSaga;
