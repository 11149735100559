import BlueCustomButtonNoBackground from 'components/blue-custom-button-no-background';
import CustomButton from 'components/custom-button';
import Status from 'components/status/Status';
import WhiteCustomTable from 'components/white-custom-table/WhiteCustomTable';
import { ReactComponent as ChevronRight } from 'images/blue-chevron-right.svg';
import { ReactComponent as PlusSvg } from 'images/white-plus.svg';
import { Financing } from 'model/financing';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { formatDateToLocalFormat } from 'shared/util/date-utils';
import StringUtils from 'shared/util/string-utils';
import { StyledInnerContainer, StyledTitle, StyledTitleContainer } from '../styles';
import FinancingStatusType from 'model/enums/financing-status-type';

interface Props extends WithTranslation {
  myProcedures?: Financing[];
}

const Desktop = ({ myProcedures, t }: Props) => {
  const history = useHistory();

  const headerData = [
    t('myProcedures.requestDate'),
    t('myProcedures.procedureNumber'),
    t('myProcedures.value'),
    t('myProcedures.status'),
    '',
  ];

  const myProceduresFormatsToShowTable = myProcedures?.map(procedure => {
    const formattedValue =
      procedure?.choosedValue != null && procedure?.approvedValue != null
        ? `R$ ${StringUtils.maskAmount(
            procedure.choosedValue > procedure?.approvedValue ? procedure?.approvedValue : procedure.choosedValue
          )}`
        : `R$ ${StringUtils.maskAmount(procedure?.choosedValue)}`;

    return {
      requestDate: formatDateToLocalFormat(procedure.createdDate),
      identification: procedure.identification ?? '',
      value: formattedValue,
      status:
        procedure?.financingStatus != null ? (
          <Status
            status={StringUtils.configureCreditTableStatusView(
              procedure.financingStatus,
              procedure.contracts?.[0]?.status,
              procedure.payment?.status ?? FinancingStatusType.UNDEFINED
            )}
          />
        ) : (
          <></>
        ),
      access: (
        <BlueCustomButtonNoBackground
          onClick={() =>
            history.push('/procedimentos/dashboard', {
              procedureId: procedure.id,
              procedureStatus: procedure.financingStatus,
              choosedValue: formattedValue,
              medicalProcedure: procedure.medicalProcedure,
              consultantComment: procedure.consultantComment,
            })
          }
        >
          {t('global.button.access')}
          <ChevronRight />
        </BlueCustomButtonNoBackground>
      ),
    };
  });

  return (
    <StyledInnerContainer>
      <StyledTitleContainer>
        <StyledTitle>{t('myProcedures.title')}</StyledTitle>
        <CustomButton height={48} width="258px" onClick={() => history.push('/procedimentos/novo-procedimento')}>
          <PlusSvg />
          {t('global.button.addProcedure')}
        </CustomButton>
      </StyledTitleContainer>

      {myProcedures != null && (
        <WhiteCustomTable
          headerData={headerData}
          tableData={myProceduresFormatsToShowTable ?? []}
          textNoData={t('myProcedures.noProductsYet')}
        />
      )}
    </StyledInnerContainer>
  );
};

export default withTranslation()(Desktop);
