import { yupResolver } from '@hookform/resolvers/yup';
import { CustomTextFieldMaskTypes } from 'components/custom-text-field';
import i18n from 'config/i18n';
import { BankAccount } from 'model/bank-account';
import { PixType } from 'model/enums/payment-type';
import { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import bankService from 'services/bank-service';
import StringUtils, { validateCNPJ, validateCpf } from 'shared/util/string-utils';
import * as yup from 'yup';

export interface PixKeyType {
  value: PixType;
  text: string;
}

export interface PixForm {
  pixType?: string;
  pixKey?: string;
}

const pixKeyTypes: PixKeyType[] = [
  { value: PixType.CPF, text: i18n.t('enums.pixType.DOCUMENT') },
  { value: PixType.CNPJ, text: i18n.t('enums.pixType.CNPJ') },
  { value: PixType.EMAIL, text: i18n.t('enums.pixType.EMAIL') },
  { value: PixType.PHONE, text: i18n.t('enums.pixType.PHONE') },
  { value: PixType.RANDOM_KEY, text: i18n.t('enums.pixType.RANDOM_KEY') },
];

enum InputSize {
  CNPJ = 14,
  CPF = 11,
}

const usePixRegistrationForm = (
  setIsLoadingBankAccount: Dispatch<SetStateAction<boolean>>,
  setSelectedBankAccount: Dispatch<SetStateAction<BankAccount | undefined>>,
  setIsShowModalCreateBankAccount: Dispatch<SetStateAction<boolean>>,
  getRegisteredBanks?: () => void,
  getBankAccount?: () => void,
  selectedBankAccount?: BankAccount,
  isRegistrationByAdmin?: boolean,
  clientId?: number,
  financingId?: number
) => {
  const [pixKeyTypeSelected, setPixKeyTypeSelected] = useState<PixKeyType>();

  const schema = yup.object({
    pixType: yup.string().test('test-pix-type', i18n.t('global.errorMessage.required'), () => pixKeyTypeSelected != null),
    pixKey: yup.string().test('test-pix-key', i18n.t('global.errorMessage.invalidPixKey'), (value, context) => {
      if (!pixKeyTypeSelected) {
        return true;
      }

      switch (pixKeyTypeSelected.value) {
        case PixType.CPF:
          return validateCpf(value ?? '');
        case PixType.CNPJ:
          return validateCNPJ(value ?? '');
        case PixType.EMAIL:
          return yup.string().email().isValidSync(value);
        case PixType.PHONE:
          return StringUtils.validatePhonePixKey(value ?? '');
        case PixType.RANDOM_KEY:
          return StringUtils.validateRandomPixKey(value ?? '');
        default:
          return true;
      }
    }),
  });

  const methods = useForm<PixForm>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const handleCreatePixKey = (formData: PixForm) => {
    setIsLoadingBankAccount(true);

    const formattedBankAccount: BankAccount = {
      pixType:
        pixKeyTypeSelected?.value === PixType.CNPJ || pixKeyTypeSelected?.value === PixType.CPF
          ? PixType.DOCUMENT
          : pixKeyTypeSelected?.value,
      pixKey: pixKeyTypeSelected?.value === PixType.PHONE ? StringUtils.formatPhonePixKey(formData?.pixKey || '') : formData.pixKey,
    };

    isRegistrationByAdmin && clientId != null
      ? bankService.createBankAccountByAdmin(clientId, formattedBankAccount).then(response => {
          setSelectedBankAccount(response);

          if (response.id != null && financingId != null) {
            bankService.linkBankAccountWithFinancingByAdmin(financingId, response.id, clientId).then(() => {
              if (getBankAccount != null) {
                getBankAccount();
                setIsShowModalCreateBankAccount(false);
                setIsLoadingBankAccount(false);
              }
            });
          }
        })
      : bankService.createBankAccount(formattedBankAccount).then(response => {
          setSelectedBankAccount(response);
          setIsShowModalCreateBankAccount(false);
          setIsLoadingBankAccount(false);

          if (getRegisteredBanks != null) {
            getRegisteredBanks();
          }
        });
  };

  const handlePixKeyUpdate = (formData: PixForm) => {
    const formattedBankAccount: BankAccount = {
      pixType:
        pixKeyTypeSelected?.value === PixType.CNPJ || pixKeyTypeSelected?.value === PixType.CPF
          ? PixType.DOCUMENT
          : pixKeyTypeSelected?.value,
      pixKey: pixKeyTypeSelected?.value === PixType.PHONE ? StringUtils.formatPhonePixKey(formData?.pixKey ?? '') : formData.pixKey,
      id: selectedBankAccount?.id,
    };

    bankService.updateBankDetails(formattedBankAccount).then(response => {
      setSelectedBankAccount(response);
      setIsShowModalCreateBankAccount(false);

      if (getRegisteredBanks != null) {
        getRegisteredBanks();
      }
    });
  };

  const maximumInputSize = () => {
    if (pixKeyTypeSelected?.value == PixType.CNPJ) {
      return InputSize.CNPJ;
    }

    if (pixKeyTypeSelected?.value == PixType.CPF) {
      return InputSize.CPF;
    }
  };

  const typeMaskInput = () => {
    if (pixKeyTypeSelected?.value == PixType.CNPJ) {
      return CustomTextFieldMaskTypes.CNPJ;
    }

    if (pixKeyTypeSelected?.value == PixType.CPF) {
      return CustomTextFieldMaskTypes.CPF;
    }

    if (pixKeyTypeSelected?.value == PixType.RANDOM_KEY) {
      return CustomTextFieldMaskTypes.NO_WHITE_SPACE;
    }

    if (pixKeyTypeSelected?.value == PixType.EMAIL) {
      return CustomTextFieldMaskTypes.ONLY_LOWERCASE;
    }
  };

  const handleDefaultValue = () => {
    if (selectedBankAccount?.pixType != null && selectedBankAccount?.pixKey != null) {
      if (validateCNPJ(selectedBankAccount.pixKey)) {
        return pixKeyTypes.find(keyType => keyType.value === PixType.CNPJ);
      }

      if (validateCpf(selectedBankAccount.pixKey)) {
        return pixKeyTypes.find(keyType => keyType.value === PixType.CPF);
      }

      return pixKeyTypes.find(keyType => keyType.value === selectedBankAccount.pixType);
    }
  };

  const selectPixKeyType = (type: PixKeyType) => {
    setPixKeyTypeSelected(type);
  };

  return {
    pixKeyTypeSelected,
    pixKeyTypes,
    methods,
    handlePixKeyUpdate,
    handleCreatePixKey,
    selectPixKeyType,
    handleDefaultValue,
    maximumInputSize,
    typeMaskInput,
  };
};

export default usePixRegistrationForm;
