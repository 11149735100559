import CustomButton from 'components/custom-button';
import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import Header from 'components/general-components/header';
import { ORGANIZATION_NAME } from 'config/constants';
import { HeaderVariants } from 'model/enums/header-variants';
import { OrganizationName } from 'model/enums/organization-name';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  Button,
  ButtonsPage,
  Container,
  InputPage,
  PageContainer,
  Pagination,
  Tab,
  TabBox,
  TabContent,
  Title,
  TitleHeader,
} from '../styles';

interface DesktopProps {
  onHandleClickLeft: () => void;
  onHandleClickRight: () => void;
  onHandleChangePage: (value) => void;
  getTabContent: () => ReactNode;
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  tabSelected: number;
  setTabSelected: (value: number) => void;
  currentPage: string;
  setCurrentPage: (value: string) => void;
  totalPages: number | null;
  getInstallments: (page?: number) => void;
  getMeat: () => Promise<void>;
}

export const Desktop = ({
  onHandleClickLeft,
  onHandleClickRight,
  onHandleChangePage,
  getTabContent,
  openModal,
  setOpenModal,
  tabSelected,
  setTabSelected,
  currentPage,
  setCurrentPage,
  totalPages,
  getInstallments,
  getMeat,
}: DesktopProps) => {
  const { color } = useTheme();
  const history = useHistory();
  const location = useLocation<{ prevPath: string }>();
  const { t } = useTranslation();

  const [isLoadingMeat, setIsLoadingMeat] = useState<boolean>(false);

  return (
    <>
      {openModal && (
        <CustomModalTwoButtons
          secondButtonText={t('global.button.ok')}
          handleClickSecondButton={() => setOpenModal(false)}
          isShowModal={openModal}
          onCloseModal={() => setOpenModal(false)}
          freeContent
          large
          message={t('admin.paymentScreen.modal')}
        />
      )}
      <PageContainer>
        <Header variant={HeaderVariants.SECONDARY} />;
        <TitleHeader>
          <Title>
            <button
              onClick={() => {
                if (location.state?.prevPath) {
                  history.push(location.state.prevPath);
                } else {
                  history.goBack();
                }
              }}
            >
              <FiChevronLeft color={color.primaryColor} />
            </button>
            <span>{t('admin.paymentScreen.title')}</span>
          </Title>
          {ORGANIZATION_NAME === OrganizationName.CREFIZ && (
            <CustomButton
              onClick={() =>
                getMeat()
                  .then(() => setIsLoadingMeat(true))
                  .finally(() => setIsLoadingMeat(false))
              }
              loading={isLoadingMeat}
            >
              {t('global.button.downloadMeat')}
            </CustomButton>
          )}
        </TitleHeader>
        <Container>
          <TabBox>
            <Tab
              $isSelected={tabSelected === 1}
              onClick={() => {
                setCurrentPage('1');
                setTabSelected(1);
              }}
            >
              <span>{t('admin.paymentScreen.tabs.opened')}</span>
            </Tab>
            <Tab
              $isSelected={tabSelected === 2}
              onClick={() => {
                setCurrentPage('1');
                setTabSelected(2);
              }}
            >
              <span>{t('admin.paymentScreen.tabs.paid')}</span>
            </Tab>
            <Tab
              $isSelected={tabSelected === 3}
              onClick={() => {
                setCurrentPage('1');
                setTabSelected(3);
              }}
            >
              <span>{t('admin.paymentScreen.tabs.panorama')}</span>
            </Tab>
          </TabBox>
          <TabContent>{getTabContent()}</TabContent>
        </Container>
        {tabSelected !== 3 && (
          <Pagination>
            <InputPage>
              <span>{t('admin.dashboard.table.page')}</span>
              <input
                type="number"
                placeholder={currentPage.toString() ?? '1'}
                value={currentPage}
                onChange={e => onHandleChangePage(e.target.value)}
                onBlur={() => {
                  if (currentPage === '') {
                    setCurrentPage('1');
                    getInstallments();
                  }
                }}
              />
              <span>{t('admin.dashboard.table.totalPage', { total: totalPages })}</span>
            </InputPage>
            <ButtonsPage>
              <Button className="left" onClick={onHandleClickLeft}>
                <FiChevronLeft color={color.primaryColor} />
              </Button>
              <Button className="right" onClick={onHandleClickRight}>
                <FiChevronRight color={color.primaryColor} />
              </Button>
            </ButtonsPage>
          </Pagination>
        )}
      </PageContainer>
    </>
  );
};
