import { yupResolver } from '@hookform/resolvers/yup';
import { isDate, parse } from 'date-fns';
import _, { isEmpty } from 'lodash';
import { FormProperties } from 'model/enums/form-properties';
import { SimulatorFormData } from 'model/landing-page';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import SimulatorButton from '../form-button';
import { PaginationBox, SimulatorContainer, SimulatorText, SimulatorTitle, StyledForm, Terms } from '../../style';
import InputText from 'components/styled-input';
import { Mask } from 'model/mask-types';
import { TextInput } from 'components/text-input';
import { SearchSelectInput } from 'components/search-selected-input';
import { employmentBondOptions } from 'shared/util/select-utils';
import StringUtils from 'shared/util/string-utils';
import { ReactSelectSearch } from 'components/react-select-search';

interface FormSecondaryProps {
  steps: string[];
  currentStep: number;
  setCurrentStep: (step: number) => void;
  setIsLoading: (value: number) => void;
  formData: SimulatorFormData;
  setFormData: (data: SimulatorFormData) => void;
  initialForm: SimulatorFormData;
  isLoading: number;
  sendApi;
  addressError?: string;
  isAdmin?: boolean;
}

const FormSecondary: React.FC<FormSecondaryProps> = ({
  steps,
  currentStep,
  setCurrentStep,
  setIsLoading,
  formData,
  setFormData,
  initialForm,
  isLoading,
  sendApi,
  addressError,
  isAdmin = false,
}) => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    [FormProperties.BIRTH_DATE]: yup
      .date()
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .typeError(t('landingPage.openingScreen.simulatorForm.formValidations.invalidDate'))
      .required(t('landingPage.openingScreen.simulatorForm.formValidations.required')),
    [FormProperties.ZIP_CODE]: yup
      .string()
      .min(9, t('landingPage.openingScreen.simulatorForm.formValidations.invalidZipCode'))
      .required(t('landingPage.openingScreen.simulatorForm.formValidations.required')),
    [FormProperties.INCOME]: yup.string().required(t('landingPage.openingScreen.simulatorForm.formValidations.required')),
    [FormProperties.EMPLOYMENT_BOND]: yup
      .string()
      .transform((val, originalValue) => originalValue)
      .test('test-employmentBond', t('landingPage.openingScreen.simulatorForm.formValidations.required'), value => !isEmpty(value)),
  });
  const methods = useForm({ resolver: yupResolver(schema) });
  const isLast = currentStep === steps.length - 1;

  const handleForm = async () => {
    const isValid = await methods.trigger();
    if (!isValid) {
      return;
    }
    if (isLast) {
      sendApi(formData);
      setIsLoading(1);

      setTimeout(() => {
        setIsLoading(0);
      }, 2500);
    } else {
      setCurrentStep(currentStep + 1);
      setIsLoading(1);
    }
  };

  const formId = 'form-secondary';

  const handleSubmit = _.debounce(handleForm, 500);

  const onHandleChangeStep = async (step: number) => {
    const valid = await methods.trigger();
    if (valid || step < currentStep) {
      setCurrentStep(step);
    }
  };

  const handleChange = (value, key) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleChangeOption = (name, value) => {
    if (value) {
      setFormData({ ...formData, [name]: value });
    }
  };

  return (
    <>
      <SimulatorContainer>
        <SimulatorTitle>{t('landingPage.openingScreen.writeYourData')}</SimulatorTitle>
        <FormProvider {...methods}>
          <StyledForm
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
            name="LP - Form Completo"
            id={formId}
          >
            <InputText
              name={FormProperties.BIRTH_DATE}
              isRequired
              label={t('landingPage.openingScreen.simulatorForm.inputLabels.birthDate')}
              onChange={e => handleChange(StringUtils.birthdayMask(e.target.value), FormProperties.BIRTH_DATE)}
              mask={Mask.BIRTHDAY}
            />
            <InputText
              name={FormProperties.ZIP_CODE}
              isRequired
              label={t('landingPage.openingScreen.simulatorForm.inputLabels.zipCode')}
              onChange={e => handleChange(e.target.value, FormProperties.ZIP_CODE)}
              mask={Mask.CEP}
              errorMessage={addressError !== '' ? addressError : undefined}
              maxLength={9}
            />
            <InputText
              name={FormProperties.INCOME}
              isRequired
              label={t('landingPage.openingScreen.simulatorForm.inputLabels.income')}
              onChange={e => handleChange(e.target.value, FormProperties.INCOME)}
              mask={Mask.CURRENCY}
            />
            <TextInput
              name={FormProperties.EMPLOYMENT_BOND}
              label={t('landingPage.openingScreen.simulatorForm.inputLabels.employmentBond')}
              isRequired
              hasCustomInput
            >
              <ReactSelectSearch
                name={FormProperties.EMPLOYMENT_BOND}
                placeholder={''}
                options={employmentBondOptions}
                onChange={value => {
                  handleChangeOption(FormProperties.EMPLOYMENT_BOND, value.value);
                }}
              />
            </TextInput>
          </StyledForm>
        </FormProvider>

        {!isAdmin && (
          <Terms>
            {t('landingPage.openingScreen.simulatorSlider.mensTerm')}
            <a href="https://esparta.s3.amazonaws.com/Termos+de+Uso+-+CreditFlow.pdf" target={'_blank'}>
              {t('landingPage.openingScreen.simulatorSlider.termsOfUse')}
            </a>
            {t('landingPage.openingScreen.simulatorSlider.and')}
            <a href="https://esparta.s3.amazonaws.com/Pol%C3%ADtica+de+Privacidade+-+CreditFlow.pdf" target={'_blank'}>
              {t('landingPage.openingScreen.simulatorSlider.privacyPolicy')}
            </a>
          </Terms>
        )}
        <SimulatorButton isLast={isLast} currentStep={currentStep} setCurrentStep={setCurrentStep} isLoading={isLoading} formId={formId} />
      </SimulatorContainer>
    </>
  );
};

export default FormSecondary;
