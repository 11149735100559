import bankApi from 'api/bank-api';
import { AxiosError, AxiosResponse } from 'axios';
import i18n from 'config/i18n';
import { Bank, BankAccount } from 'model/bank-account';
import HttpStatus from 'model/enums/http-status';
import { PageableResponse } from 'model/pageable';
import toastUtils from 'shared/util/toast-utils';

const bankService = () => {
  const createBankAccount = async (account: BankAccount): Promise<BankAccount> => {
    try {
      const result: AxiosResponse<BankAccount[]> = await bankApi.createBankAccount(account);
      if (result.status === HttpStatus.CREATED && result.data != null) {
        return Promise.resolve(result.data[0]);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.failedRegisterBankAccount'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const createBankAccountByAdmin = async (clientId: number, account: BankAccount): Promise<BankAccount> => {
    try {
      const result: AxiosResponse<BankAccount[]> = await bankApi.createBankAccountByAdmin(clientId, account);
      if (result.status === HttpStatus.CREATED && result.data != null) {
        return Promise.resolve(result.data[0]);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.failedRegisterBankAccount'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getBankAccount = async (): Promise<AxiosResponse<BankAccount[]>> => {
    try {
      const result: AxiosResponse<BankAccount[]> = await bankApi.getBankAccount();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getAllBankNames = async (page: number, name: string, byParam?: string): Promise<AxiosResponse<PageableResponse<Bank>>> => {
    try {
      const result: AxiosResponse<PageableResponse<Bank>> = await bankApi.getAllBankNames(page, name, byParam);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const updateBankDetails = async (account: BankAccount): Promise<BankAccount> => {
    try {
      const result: AxiosResponse<BankAccount> = await bankApi.updateBankDetails(account);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const linkBankAccountWithFinancingByAdmin = async (
    financingId: number,
    bankAccountId: number,
    clientId: number
  ): Promise<BankAccount> => {
    try {
      const result: AxiosResponse<BankAccount> = await bankApi.linkBankAccountWithFinancingByAdmin(financingId, bankAccountId, clientId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      toastUtils.emitErrorToast(i18n.t('global.errorMessage.failedRegisterBankAccount'));
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const deleteBankAccount = async (id: number): Promise<BankAccount> => {
    try {
      const result: AxiosResponse<BankAccount> = await bankApi.deleteBankAccount(id);

      if (result.status === HttpStatus.NO_CONTENT) {
        return Promise.resolve(result.data ?? []);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    createBankAccount,
    createBankAccountByAdmin,
    getBankAccount,
    getAllBankNames,
    updateBankDetails,
    linkBankAccountWithFinancingByAdmin,
    deleteBankAccount,
  };
};

export default bankService();
