import { useTranslation } from 'react-i18next';
import { Button, ButtonsContainer, ButtonsPage, PaginationContainer, InputPage, SpanPagination } from './styles';
import { ReactComponent as ArrowLeft } from 'images/chevron-left.svg';
import { ReactComponent as ArrowRight } from 'images/chevron-right.svg';
import { ReactComponent as DoubleArrowLeft } from 'images/chevrons-left.svg';
import { ReactComponent as DoubleArrowRight } from 'images/chevrons-right.svg';
import { PageableResponse } from 'model/pageable';
import { Dispatch, SetStateAction, useState } from 'react';
import _ from 'lodash';

interface PaginationProps {
  page?: number;
  setNewPage?: (page: number) => void;
  noPagination: boolean;
  pageableResponse?: PageableResponse<any>;
  loadingTable?: boolean;
  setLoadingTable: Dispatch<SetStateAction<boolean>>;
}

export const PaginationBar = ({ page, setNewPage, noPagination, pageableResponse, loadingTable, setLoadingTable }: PaginationProps) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState('1');

  const onHandleClickChangePage = (direction: 'left' | 'right' | 'doubleLeft' | 'doubleRight') => {
    const newPage =
      direction === 'left'
        ? Number(currentPage) - 1
        : direction === 'doubleLeft'
          ? 1
          : direction === 'right'
            ? Number(currentPage) + 1
            : direction === 'doubleRight'
              ? Number(pageableResponse?.totalPages)
              : Number(currentPage);

    if (setNewPage && newPage >= 1 && pageableResponse?.totalPages && newPage <= pageableResponse?.totalPages) {
      setCurrentPage(newPage.toString());
      setNewPage(newPage - 1);
    }
  };

  const onHandleChangePage = (value: string) => {
    setLoadingTable(true);
    const newPage = Number(value);

    if (Number.isNaN(newPage)) {
      return;
    }

    if (newPage === 0) {
      return setCurrentPage('');
    }

    if (pageableResponse?.totalPages && newPage > pageableResponse?.totalPages) {
      if (page != null) {
        return setCurrentPage((page + 1).toString());
      }
    }

    setCurrentPage(newPage.toString());
    handleSetNewPage(newPage);
  };

  const handleSetNewPage = _.debounce((newPage: number) => {
    if (setNewPage) {
      if (pageableResponse?.totalPages && newPage >= pageableResponse?.totalPages) {
        setNewPage(pageableResponse?.totalPages - 1);
      }

      setNewPage(newPage - 1);
    }
  }, 500);

  return (
    <PaginationContainer>
      <div>
        {t('admin.dashboard.totalResults', {
          results: pageableResponse?.numberOfElements?.toString(),
          totalResults: pageableResponse?.totalElements?.toString(),
        })}
      </div>
      {!noPagination && (
        <ButtonsContainer>
          <ButtonsPage>
            <Button className="left" onClick={() => onHandleClickChangePage('doubleLeft')}>
              <DoubleArrowLeft />
            </Button>
            <Button className="left" onClick={() => onHandleClickChangePage('left')}>
              <ArrowLeft />
            </Button>
          </ButtonsPage>

          {pageableResponse?.totalPages && pageableResponse?.totalPages > 5 ? (
            currentPage === '1' || currentPage === '2' ? (
              <InputPage>
                {[...Array(3)].map((_, idx) => (
                  <SpanPagination
                    className={currentPage === (idx + 1).toString() ? 'clicked' : ''}
                    onClick={e => onHandleChangePage((idx + 1).toString())}
                  >
                    {(idx + 1).toString()}
                  </SpanPagination>
                ))}
                <SpanPagination>{'...'}</SpanPagination>
                <SpanPagination
                  className={currentPage === (pageableResponse?.totalPages - 1).toString() ? 'clicked' : ''}
                  onClick={e => pageableResponse?.totalPages && onHandleChangePage((pageableResponse?.totalPages - 1).toString())}
                >
                  {pageableResponse?.totalPages && pageableResponse?.totalPages - 1}
                </SpanPagination>
                <SpanPagination
                  className={currentPage === pageableResponse?.totalPages.toString() ? 'clicked' : ''}
                  onClick={e => pageableResponse?.totalPages && onHandleChangePage(pageableResponse?.totalPages?.toString())}
                >
                  {pageableResponse?.totalPages}
                </SpanPagination>
              </InputPage>
            ) : currentPage === pageableResponse?.totalPages.toString() || currentPage === (pageableResponse?.totalPages - 1).toString() ? (
              <InputPage>
                {[...Array(2)].map((_, idx) => (
                  <SpanPagination
                    className={currentPage === (idx + 1).toString() ? 'clicked' : ''}
                    onClick={e => onHandleChangePage((idx + 1).toString())}
                  >
                    {(idx + 1).toString()}
                  </SpanPagination>
                ))}
                <SpanPagination>{'...'}</SpanPagination>
                <SpanPagination
                  className={currentPage === (pageableResponse?.totalPages - 2).toString() ? 'clicked' : ''}
                  onClick={e => pageableResponse?.totalPages && onHandleChangePage((pageableResponse?.totalPages - 2).toString())}
                >
                  {pageableResponse?.totalPages && pageableResponse?.totalPages - 2}
                </SpanPagination>
                <SpanPagination
                  className={currentPage === (pageableResponse?.totalPages - 1).toString() ? 'clicked' : ''}
                  onClick={e => pageableResponse?.totalPages && onHandleChangePage((pageableResponse?.totalPages - 1).toString())}
                >
                  {pageableResponse?.totalPages && pageableResponse?.totalPages - 1}
                </SpanPagination>
                <SpanPagination
                  className={currentPage === pageableResponse?.totalPages.toString() ? 'clicked' : ''}
                  onClick={e => pageableResponse?.totalPages && onHandleChangePage(pageableResponse?.totalPages?.toString())}
                >
                  {pageableResponse?.totalPages}
                </SpanPagination>
              </InputPage>
            ) : (
              <InputPage>
                <SpanPagination className={currentPage === '1' ? 'clicked' : ''} onClick={e => onHandleChangePage('1')}>
                  {'1'}
                </SpanPagination>
                {currentPage !== '3' && <SpanPagination>{'...'}</SpanPagination>}

                <SpanPagination onClick={e => pageableResponse?.totalPages && onHandleChangePage((Number(currentPage) - 1).toString())}>
                  {Number(currentPage) - 1}
                </SpanPagination>
                <SpanPagination className="clicked">{currentPage}</SpanPagination>
                <SpanPagination onClick={e => pageableResponse?.totalPages && onHandleChangePage((Number(currentPage) + 1).toString())}>
                  {Number(currentPage) + 1}
                </SpanPagination>

                {currentPage !== (pageableResponse?.totalPages - 2).toString() && <SpanPagination>{'...'}</SpanPagination>}
                <SpanPagination
                  className={currentPage === pageableResponse?.totalPages.toString() ? 'clicked' : ''}
                  onClick={e => pageableResponse?.totalPages && onHandleChangePage(pageableResponse?.totalPages?.toString())}
                >
                  {pageableResponse?.totalPages}
                </SpanPagination>
              </InputPage>
            )
          ) : (
            <InputPage>
              {[...Array(pageableResponse?.totalPages)].map((_, idx) => (
                <SpanPagination
                  className={currentPage === (idx + 1).toString() ? 'clicked' : ''}
                  onClick={e => onHandleChangePage((idx + 1).toString())}
                >
                  {(idx + 1).toString()}
                </SpanPagination>
              ))}
            </InputPage>
          )}

          <ButtonsPage>
            <Button className="right" onClick={() => onHandleClickChangePage('right')}>
              <ArrowRight />
            </Button>
            <Button className="right" onClick={() => onHandleClickChangePage('doubleRight')}>
              <DoubleArrowRight />
            </Button>
          </ButtonsPage>
        </ButtonsContainer>
      )}
    </PaginationContainer>
  );
};
