import { IconSuccessContainer, StyledInnerContainer, StyledPaintSvg } from './styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import MobileHeader from 'components/mobile-header';
import { ReactComponent as BrokenLinkIcon } from 'images/broken-link.svg';

const ExpiredLink = ({ t }: WithTranslation) => {
  return (
    <>
      <MobileHeader />
      <StyledInnerContainer>
        <h1>{t('contract.waitingStep.expiredLink.title')}</h1>
        <span>{t('contract.waitingStep.errorAnalysis.weSorry')}</span>
        <span style={{ marginTop: '8px' }}>{t('contract.waitingStep.errorAnalysis.notTheCase')}</span>
        <span style={{ marginTop: '8px' }}>{t('contract.waitingStep.waitNewLiberation.doubts')}</span>
        <IconSuccessContainer>
          <BrokenLinkIcon />
        </IconSuccessContainer>

        <div className="waiting-step-footer">{t('contract.footer')}</div>
      </StyledInnerContainer>
    </>
  );
};

export default withTranslation()(ExpiredLink);
