import { Alert, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import partnersService from 'services/partners';
import StringUtils from 'shared/util/string-utils';
import { TableValues } from '../my-account/components/table-list-series';
import { StyledTitleContainer, Title } from '../my-account/styles';
import { TableListSeries } from './components/table-list-series';

export interface TotalValues {
  balanceAmountSum: string;
  blockedAmountSum: string;
  toReleaseAmountSum: string;
}

const PartnersScreen = () => {
  const { t } = useTranslation();
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [balance, setBalance] = useState<TableValues[]>([]);
  const [totalValues, setTotalValues] = useState<TotalValues>();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const getData = () => {
    setIsLoadingTable(true);

    partnersService
      .getBalances()
      .then(res => {
        setIsLoadingTable(false);
        const parameters = res.balanceParameters;
        setBalance([
          {
            header: t('admin.partners.table.partnerName'),
            values: parameters.map(line => String(line.organizationSubsidiary.name ?? '').padStart(5, '0')),
          },
          {
            header: t('admin.partners.table.actualBalance'),
            values: parameters.map(line => StringUtils.moneyMaskPtbr(line.balanceAmount)),
          },
          {
            header: t('admin.partners.table.blockedBalance'),
            values: parameters.map(line => StringUtils.moneyMaskPtbr(line.balanceTransactionAmount)),
          },
          {
            header: t('admin.partners.table.balanceToRelease'),
            values: parameters.map(line => StringUtils.moneyMaskPtbr(line.balanceRequestTransactionAmount)),
          },
        ]);

        setTotalValues({
          balanceAmountSum: StringUtils.moneyMaskPtbr(res.balanceAmountSum),
          blockedAmountSum: StringUtils.moneyMaskPtbr(res.blockedAmountSum),
          toReleaseAmountSum: StringUtils.moneyMaskPtbr(res.toReleaseAmountSum),
        });
      })
      .catch(err => setErrorMessage(err.statusText));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Snackbar open={!!errorMessage} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <StyledTitleContainer>
        <Title>{t('admin.dashboard.partnersBalance')}</Title>
      </StyledTitleContainer>
      <TableListSeries loadingTable={isLoadingTable} tableValues={balance} totalValues={totalValues} />
    </>
  );
};

export default PartnersScreen;
