import * as S from './styles';
import useCustomStepIcon from './hooks/use-custom-step-icon';

interface Props {
  label: string;
  stepValue: number;
  currentStep: number;
  stepCompleted: boolean;
}

const CustomStepIcon = ({ label, stepValue, currentStep, stepCompleted }: Props) => {
  const { checkIfYouAreInCurrentStep, handleStepIcon } = useCustomStepIcon();

  return (
    <S.Container $currentStep={checkIfYouAreInCurrentStep(stepValue, currentStep)} $stepCompleted={stepCompleted}>
      {handleStepIcon(stepCompleted, stepValue, currentStep)}
      {label}
    </S.Container>
  );
};

export default CustomStepIcon;
