import styled from 'styled-components';

export const StyledContainer = styled.div`
  height: 100%;
  padding-bottom: 55px;
  min-height: 100vh;
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.color.gray18};
  text-align: left;
  background-color: ${({ theme }) => theme.color.grayBackground};
  overflow-y: auto;
`;

export const StyledInnerContainer = styled.div`
  max-width: 480px;
  margin: 48px auto 0 auto;
  padding: 0 16px;
`;
