import { BigContainer, Container } from './styles';

interface StatusProps {
  text: string;
  color: string;
  big?: boolean;
}

export const Status = ({ text, color, big }: StatusProps) => {
  if (big) {
    return <BigContainer $color={color}>{text}</BigContainer>;
  } else {
    return <Container $color={color}>{text}</Container>;
  }
};
