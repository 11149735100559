import Popup from 'reactjs-popup';
import styled, { css } from 'styled-components';

interface PropsMenuOpen {
  $menuOpen?: boolean;
}

export const StyledTextMenuOpen = styled.div<PropsMenuOpen>`
  font-weight: 500;
  font-size: 16px;
  margin-left: 8px;
  color: ${({ theme }) => theme.color.white};

  ${({ $menuOpen }) =>
    !$menuOpen &&
    css`
      display: none;
      text-overflow: clip;
    `};
`;

export const DropdownContainer = styled.div`
  width: 200px;
  background: ${({ theme }) => theme.color.white};

  margin: 8px 0px 0px 0px;
  padding: 8px 0px;

  border: ${({ theme }) => `1px solid ${theme.color.lightGray1}`};
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
`;

export const DropdownItem = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;

  :hover {
    background: ${({ theme }) => theme.color.lightGray1};
    border-radius: 8px;
  }
`;

export const DropdownText = styled.div`
  margin: 0px 0px 0px 4px;
  color: ${({ theme }) => theme.color.gray19};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

interface SeparatorProps {
  $isLast?: boolean;
}

export const Separator = styled.div<SeparatorProps>`
  width: calc(100% - 32px);
  background: ${({ theme }) => theme.color.lightGray2};
  display: ${({ $isLast }) => ($isLast ? 'none' : 'block')};
  height: 1px;
  padding: 0px 16px;
  margin: 0px auto;
`;

interface StyledPopupProps {
  children: (close: any) => JSX.Element;
}

export const StyledPopup = styled(Popup)<StyledPopupProps>``;
