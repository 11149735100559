import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GrFormClose } from 'react-icons/gr';
import { ImAttachment } from 'react-icons/im';
import { TbUpload } from 'react-icons/tb';
import StringUtils from 'shared/util/string-utils';
import { AdminInputType } from '../input';
import {
  Container,
  DeleteUpload,
  StyledError,
  StyledInputBox,
  StyledLabel,
  TextBox,
  UpladContent,
  UploadButton,
  UploadDescription,
  UploadIcon,
  UploadTitle,
  UploadedBox,
  UploadedFileLink,
  UploadedFileName,
  UploadedImage,
} from './style';

interface AdminAttachProps {
  formData: any;
  setFormData: (data) => void;
  name: string;
  label: string;
  required?: boolean;
  position?: number;
  type?: string;
}

const AdminAttach: React.FC<AdminAttachProps> = ({ formData, setFormData, name, label, position, required, type }) => {
  const { t } = useTranslation();
  const renderAttach = () => {
    switch (type) {
      case AdminInputType.VIEW: {
        return (
          <StyledInputBox $calcWidth="88px">
            <StyledLabel>{label}</StyledLabel>
            <UploadedBox>
              <UploadedImage>
                <ImAttachment />
              </UploadedImage>
              <UploadedFileName>
                <UploadedFileLink href={formData.attachments?.presignedUrl} download={formData.attachments?.fileName} target="_blank">
                  {StringUtils.characterLimit(formData.attachments?.fileName, 26)}
                </UploadedFileLink>
              </UploadedFileName>
            </UploadedBox>
          </StyledInputBox>
        );
      }

      default: {
        const { register, setValue, control } = useFormContext();

        const {
          fieldState: { error },
        } = useController({ name, control });

        const [downloadUrl, setDownloadUrl] = useState<string>('');

        const onDrop = acceptedFiles => {
          const uploadedFile = acceptedFiles[0];
          const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf'];

          if (acceptedTypes.includes(uploadedFile.type)) {
            const reader = new FileReader();
            reader.onload = (ev: any) => {
              const file = {
                fileName: uploadedFile.name,
                contentType: uploadedFile.type,
                file: ev.target.result.split(',')[1],
                type: uploadedFile.type ?? '',
              };

              setValue(name, file);
              setDownloadUrl(ev.target.result);
              setFormData?.(prevListaObjetos => {
                const updatedObjectList = [...prevListaObjetos];
                updatedObjectList[position ?? 0] = {
                  ...updatedObjectList[position ?? 0],
                  [name]: file,
                };
                return updatedObjectList;
              });
            };
            reader.readAsDataURL(uploadedFile);
          }
        };

        const onDelete = () => {
          setDownloadUrl('');
          setFormData?.(prevListaObjetos => {
            const updatedObjectList = [...prevListaObjetos];
            updatedObjectList[position ?? 0] = {
              ...updatedObjectList[position ?? 0],
              [name]: '',
            };
            return updatedObjectList;
          });
          setValue(name, '');
        };

        const { getRootProps, getInputProps } = useDropzone({
          onDrop,
          accept: {
            'image/*': ['.png', '.jpeg', '.jpg'],
            'application/pdf': ['.pdf'],
          },
          multiple: false,
        });
        return !formData.attachments ? (
          <Container {...getRootProps()}>
            <input {...register(name, { required: required })} {...getInputProps()} />
            <UpladContent>
              <UploadIcon>
                <TbUpload />
              </UploadIcon>
              <TextBox>
                <UploadTitle>{t('proceduresDashboard.adminAttachSelectFile')}</UploadTitle>
                <UploadDescription>{t('proceduresDashboard.attach.uploadTypes')}</UploadDescription>
              </TextBox>
            </UpladContent>
            <UploadButton>{t('proceduresDashboard.attach.searchFile')}</UploadButton>
            {error && error[name] && <StyledError>{error[name]?.message}</StyledError>}
          </Container>
        ) : (
          <StyledInputBox $calcWidth="88px">
            <StyledLabel>{label}</StyledLabel>
            <UploadedBox>
              <UploadedImage>
                <ImAttachment />
              </UploadedImage>
              <UploadedFileName>
                <UploadedFileLink href={downloadUrl} download={formData.attachments?.fileName}>
                  {StringUtils.characterLimit(formData.attachments?.fileName, 26)}
                </UploadedFileLink>
              </UploadedFileName>
              <DeleteUpload onClick={onDelete} type="button">
                <GrFormClose />
              </DeleteUpload>
            </UploadedBox>
            {error && error[name] && <StyledError>{error[name]?.message}</StyledError>}
          </StyledInputBox>
        );
      }
    }
  };
  return renderAttach();
};
export default AdminAttach;
