import { ScreenDimensions } from 'model/enums/screen-dimensions-types';
import styled from 'styled-components';

interface Props {
  customStyle?: any;
  isActive?: boolean;
}

export const SimulatorContainer = styled.div`
  max-width: 600px;
  height: 488px;
  background: #ffffff;
  border: 1px solid #fafafa;
  border-radius: 10px;
  margin: 40px 0px 0px;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 671px) {
    height: auto;
    padding: 16px;
    margin: 0px 16px;
  }
`;

// global icon
export const StyledIcon = styled.div<Props>`
  width: 18px;
  height: 18px;
  background-image: url(${({ customStyle }) => customStyle.backgroundImage});
  background-repeat: no-repeat;
  background-position: center;
`;

// rate
export const StyledRateBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0px 12px;
`;
export const StyledRate = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.color.primaryColor};
  margin: 0px 0px 0px 8px;
`;

// sction title
export const SectionTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #7f7f7f;
  margin: 12px 0px 0px;
`;

// valor desejado
export const DesiredValueContainer = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  margin: 8px 0px 12px;
  border-bottom: 1px solid #e5e7e7;

  @media (max-width: 671px) {
    width: 240px;
    height: auto;
  }
`;
export const DesiredValue = styled.input`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.primaryColor};
  text-align: center;
  border: 0px;

  @media (max-width: 671px) {
    width: 192px;
    height: auto;
  }
`;

export const DesiredValueButtons = styled.button<Props>`
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  padding: 0px;

  ${({ customStyle }) => customStyle?.backgroundImage && `background-image: url(${({ customStyle }) => customStyle?.backgroundImage});`}
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  svg {
    path {
      fill: ${({ isActive, theme }) => (isActive ? theme.color.primaryColor : theme.color.gray18)};
    }
  }
`;

// parcelas
export const Installments = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px 0px 0px;
`;

export const SliderTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

export const SliderTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray21};
`;

export const SliderValue = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: ${({ theme }) => theme.color.primaryColor};
`;

export const PaymentInformationContainer = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: space-between;
  margin: 36px 0px 0px;

  @media (max-width: 671px) {
    flex-direction: column;
    height: auto;
    align-items: center;
  }
`;

export const PaymentInformationBox = styled.div`
  width: 248px;
  height: 72px;
  background: #fafafa;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 671px) {
    margin: 12px 0px 0px 0px;
  }
`;

export const PaymentInformationHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0px 0px;
`;

export const PaymentInformationTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.color.gray21};
  margin: 0px 0px 0px 4px;
`;

interface PaymentInformationStyledProps {
  limitMonthError?: boolean;
}

export const PaymentInformationText = styled.div<PaymentInformationStyledProps>`
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  line-height: 150%;
  padding: 4px 0px;
  color: ${({ theme, limitMonthError }) => (limitMonthError ? theme.color.rejected : theme.color.primaryColor)};
`;

export const InstallmentValueError = styled.div`
  color: ${({ theme }) => theme.color.rejected};
  width: 188px;
  align-self: flex-start;
  text-align: center;
  font-size: 14px;
  height: 42px;
`;

// primeiro vencimento

export const SimulatorInfo = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #b3b2b3;
  text-align: left;
  margin: 12px 0px 0px 0px;
`;

export interface SliderInputProps {
  value?: string;
}

export const SliderValueInput = styled.input<SliderInputProps>`
  color: #333;
  font-weight: 500;
  font-size: 18px;
  color: ${({ theme }) => theme.color.primaryColor};
  border: none;
  outline: none;
  width: ${({ value }) => (value ? `${value.length * 11}px` : '155px')};
  border-bottom: 1px solid;

  @media (max-width: ${ScreenDimensions.MID_TABLET}) {
    font-size: 18px;
  }
`;
