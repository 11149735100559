import Header from 'components/general-components/header';
import { SliderButton } from 'components/landing-page/opening-screen/simulator/style';
import { SimulatorContainer } from 'components/simulator-data/aproved/style';
import { StyledTitle } from 'components/simulator-data/confirm-simulation/style';

import { NotAprovedSvg } from 'images/not-approved-simulator';
import ButtonVariants from 'model/enums/buttonVariants';
import { HeaderVariants } from 'model/enums/header-variants';
import { useTranslation } from 'react-i18next';
import { StyledImage } from '../styled';

interface MobileProps {
  title: () => string;
  handleRedirect: () => void;
}

const Mobile: React.FC<MobileProps> = ({ title, handleRedirect }) => {
  const { t } = useTranslation();
  return (
    <>
      <Header variant={HeaderVariants.SECONDARY} />
      <SimulatorContainer>
        <StyledTitle>{title()}</StyledTitle>
        <StyledImage>
          <NotAprovedSvg />
        </StyledImage>
        <SliderButton onClick={handleRedirect} $customStyle={{ variant: ButtonVariants.DEFAULT, width: '180px' }}>
          {t('global.button.backStep')}
        </SliderButton>
      </SimulatorContainer>
    </>
  );
};

export default Mobile;
