import { MutableRefObject, useRef, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import { FilterItem } from '../title-header-new/styles';
import * as S from './styles';
import { PopupActions } from 'reactjs-popup/dist/types';
import { OrderLogoIcon } from 'images/order-logo';
import { RadioFillIcon } from 'images/radio-fill';
import { ReactComponent as RadioEmptyIcon } from 'images/radio-empty.svg';
import { useAdmin } from 'provider/admin-general';
import { Ordertype } from 'model/enums/order-type';
import { isEmpty } from 'lodash';

interface FilterButtonProps {
  labelTitle?: string;
  labelDefaultOption?: string;
  labelOption?: string;
}

export const OrderFilterButton = ({ labelTitle, labelDefaultOption, labelOption }: FilterButtonProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { sort, sortAscending, sortDescending } = useAdmin();

  const ref: MutableRefObject<PopupActions | null> = useRef(null);

  const closeModal = () => setIsOpen(false);

  return (
    <Popup
      ref={ref}
      open={isOpen}
      closeOnDocumentClick
      onClose={closeModal}
      trigger={
        <FilterItem onClick={() => setIsOpen(!isOpen)}>
          <OrderLogoIcon isWhite={false} />
          <span>{t('admin.dashboard.orderBy')}</span>
        </FilterItem>
      }
      position="bottom center"
      on="click"
    >
      <S.DropDownFilter>
        <S.SectionTitle className="status">{labelTitle ? labelTitle : t('admin.dashboard.creationDate')}</S.SectionTitle>
        <S.CustomRadioOption
          onClick={() => {
            sortDescending();
          }}
        >
          {sort === Ordertype.DESC ? <RadioFillIcon isWhite={false} /> : <RadioEmptyIcon />}
          <span>{labelDefaultOption ? labelDefaultOption : t('admin.dashboard.moreRecent')}</span>
        </S.CustomRadioOption>
        <S.CustomRadioOption
          onClick={() => {
            sortAscending();
          }}
        >
          {sort === Ordertype.ASC ? <RadioFillIcon isWhite={false} /> : <RadioEmptyIcon />}
          <span>{labelOption ? labelOption : t('admin.dashboard.moreOld')}</span>
        </S.CustomRadioOption>
      </S.DropDownFilter>
    </Popup>
  );
};
