import InputErrorMessage from 'components/InputErrorMessage';
import { ErrorMessageBox } from 'components/styled-input/styles';
import AlertIcon from 'images/alert-triangle.svg';
import { useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyledSelect } from './styles';

export interface ReactSelectOption {
  value: string;
  label: string;
}

export interface ReactSelectSearchProps {
  placeholder: string;
  options: ReactSelectOption[];
  name: string;
  errorMessage?: string;
  onMenuScrollToBottom?: () => void;
  onChange?: (value: ReactSelectOption) => void;
  onInputChange?: (value: string) => void;
  onBlur?: () => void;
  onMenuClose?: () => void;
  onFocus?: () => void;
  defaultValue?: ReactSelectOption | undefined;
}

export const ReactSelectSearch = ({
  placeholder,
  onMenuScrollToBottom,
  options,
  onChange,
  onInputChange,
  onBlur,
  onFocus,
  onMenuClose,
  name,
  errorMessage,
  defaultValue,
}: ReactSelectSearchProps) => {
  const [selectedOption, setSelectedOption] = useState<ReactSelectOption | undefined>(undefined);
  const [isFocused, setIsFocused] = useState(false);
  const { t } = useTranslation();

  const { register, setValue, control } = useFormContext();

  const {
    fieldState: { error },
  } = useController({ name, control });

  const onFocusHandler = () => {
    setIsFocused(true);
    if (onFocus) {
      onFocus();
    }
  };

  const onBlurHandler = () => {
    setIsFocused(false);
    if (onBlur) {
      onBlur();
    }
  };

  const inputValidation = {
    hasError: !!error || errorMessage != null,
  };

  useEffect(() => {
    if (defaultValue) {
      setSelectedOption(defaultValue);
      setValue(name, defaultValue.value);
    }
  }, []);

  return (
    <>
      <StyledSelect
        id={`select-search-${name}`}
        {...register(name ?? '')}
        $hasError={inputValidation.hasError}
        defaultValue={defaultValue ?? selectedOption}
        classNamePrefix="Select"
        openMenuOnFocus={true}
        placeholder={placeholder}
        styles={{ container: provided => ({ ...provided, width: '100%' }) }}
        maxMenuHeight={150}
        onMenuScrollToBottom={onMenuScrollToBottom}
        onMenuClose={onMenuClose}
        noOptionsMessage={() => t('global.no-results-found')}
        options={options}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
        onInputChange={(value, meta) => {
          if (onInputChange) {
            onInputChange(value);
          }
        }}
        onChange={(value, meta) => {
          const selectedOption = value as ReactSelectOption;
          setSelectedOption(selectedOption);
          setValue(name, selectedOption.value);
          if (onChange) {
            onChange(selectedOption);
          }
        }}
      />
      {inputValidation.hasError && (
        <ErrorMessageBox>
          <img src={AlertIcon} style={{ marginRight: '5px' }} />
          <InputErrorMessage isFocused={isFocused} errorMessage={error?.message ?? errorMessage} />
        </ErrorMessageBox>
      )}
    </>
  );
};
