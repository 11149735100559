import InputText from 'components/styled-input';
import { Mask } from 'model/enums/mask-types';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { InputContainer } from './styles';

interface TextInputProps {
  name: string;
  label: string;
  placeholder?: string;
  isRequired?: boolean;
  defaultValue?: string;
  maxLength?: number;
  minLength?: number;
  mask?: Mask;
  inputType?: string;
  hasCustomInput?: boolean;
  children?: JSX.Element | JSX.Element[] | any;
}

export const TextInput: React.FC<TextInputProps> = ({
  name,
  label,
  placeholder,
  isRequired,
  defaultValue,
  maxLength,
  minLength,
  mask,
  inputType,
  hasCustomInput,
  children,
}) => {
  const isMobile = useResponsiveMobile().isMobile;

  return (
    <InputContainer $isMobile={!!isMobile}>
      <div>
        <span>{label}</span>
        {isRequired && <span>*</span>}
      </div>

      {hasCustomInput && children != null ? (
        children
      ) : (
        <InputText
          name={name}
          label=""
          placeholder={placeholder}
          defaultValue={defaultValue ? defaultValue : ''}
          maxLength={maxLength ?? maxLength}
          minLength={minLength ?? minLength}
          mask={mask ?? mask}
          inputType={inputType ?? inputType}
        />
      )}
    </InputContainer>
  );
};
