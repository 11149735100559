import axios, { AxiosResponse } from 'axios';
import { IInternetProtocol } from 'model/network-data';
import IPersonSignatureLocationData, { ILocationData, LocationResponse } from 'model/person-singature-location';
import { api } from './api';

const networkDataApi = () => {
  const getPublicApiInternetProtocol = (): Promise<AxiosResponse<string>> => {
    return axios.get<string>('http://meuip.com/api/meuip.php');
  };

  const getInternetProtocol = (): Promise<AxiosResponse<IInternetProtocol>> => {
    return api.get<IInternetProtocol>('/person-signature/ip');
  };

  const postLocalizationAndProtocol = (data: IPersonSignatureLocationData): Promise<AxiosResponse<IPersonSignatureLocationData>> => {
    const id = data.id;
    const locationData = data.locationData;
    return api.post(`/person-signature/${id}/save-person-signature-location`, locationData);
  };

  const getPublicApiLocalizationByIP = (ipAddress: string): Promise<AxiosResponse<LocationResponse>> => {
    return axios.get<LocationResponse>(`http://ip-api.com/json/${ipAddress}`);
  };

  return {
    getPublicApiInternetProtocol,
    postLocalizationAndProtocol,
    getInternetProtocol,
    getPublicApiLocalizationByIP,
  };
};

export default networkDataApi();
