import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 32px;
`;

export const HeadersContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
`;

export const Title = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.black0};
`;

export const Separator = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.color.gray20};
  margin: 16px 0px;
  grid-area: separator;
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 32px 1fr;
  grid-template-areas:
    'separator separator separator'
    'filename signatories statusBox';
`;

export const SignatoriesBox = styled.div`
  grid-area: signatories;
  display: flex;
  flex-direction: column;
`;

export const Sign = styled.span`
  display: inline-block;
  margin-left: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray19};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const FileNameBox = styled.div`
  grid-area: filename;
  display: flex;
  align-items: center;

  > span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.gray19};
    display: inline-block;
    margin-left: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 350px;
  }
`;

export const StatusBox = styled.div`
  grid-area: statusBox;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Unsigned = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.color.gray20};
  border-radius: 50%;
`;

export const AccessButton = styled.button`
  border: none;
  background-color: transparent;
  margin-left: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;

  > span {
    margin-right: 14px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const SignatoryContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 45px 0px;

  & > span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.gray24};

    display: inline-block;
    margin-top: 18px;
  }
`;
