import styled from 'styled-components';

interface Props {
  $gridName?: string;
}

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.color.grayBackground};
  height: 100vh;
  overflow: auto;
`;

export const TitleHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 48px;
  max-width: 70%;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 150%;

  > span.title {
    display: inline-block;
    font-weight: 700;
    margin-left: 16px;
  }

  > button {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const Content = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border-bottom: 1px solid ${({ theme }) => theme.color.borderGray};
  border-radius: 8px;
  max-width: 70%;
  margin: 0 auto;
  margin-top: 32px;

  padding: 16px 24px 24px 24px;
`;

export const TextContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'bio date score'
    'status error error';
  grid-column-gap: 25px;
  grid-row-gap: 16px;
  align-items: flex-end;
`;

export const TextField = styled.div<Props>`
  grid-area: ${({ $gridName }) => $gridName};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray24};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & > div {
    margin-bottom: 6px;
  }
`;

export const Label = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray24};
`;

export const Text = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray21};

  display: inline-block;
  margin-bottom: 8px;
`;

export const QRCodeContainer = styled.div`
  width: 100%;
  height: 200px;

  margin-top: 48px;
`;
