import { StyledButton } from 'components/custom-button/styles';
import Camera from 'images/camera.svg';
import Selfie from 'images/selfie.svg';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelfieContext } from '../../provider';
import {
  ButtonContainer,
  Container,
  Content,
  InstructionsContainer,
  List,
  ListContainer,
  ListItem,
  SelfieText,
  Subtitle,
  Title,
} from './styles';

const SelfieIntructions = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const key = useParams<{ key: string }>().key;
  const id = useParams<{ id: string }>().id;
  const location = useLocation();
  const isAdmin = location.pathname.includes('admin');

  const goToSelfie = () => {
    if (key) {
      if (isAdmin) {
        history.push(`/selfie/admin/${id}/foto/${key}`);
      } else {
        history.push(`/selfie/foto/${key}`);
      }
    }
  };

  return (
    <Container>
      <Content>
        <Title>{t('selfie.instructions.title')}</Title>
        <SelfieText>{t('selfie.instructions.selfieText')}</SelfieText>
        <Subtitle>{t('selfie.instructions.subtitle')}</Subtitle>
        <InstructionsContainer>
          <img src={Selfie} />
          <ListContainer>
            <List>
              <ListItem>{t('selfie.instructions.item_1')}</ListItem>
              <ListItem>{t('selfie.instructions.item_2')}</ListItem>
              <ListItem>{t('selfie.instructions.item_3')}</ListItem>
              <ListItem>{t('selfie.instructions.item_4')}</ListItem>
              <ListItem>{t('selfie.instructions.item_5')}</ListItem>
              <ListItem>{t('selfie.instructions.item_6')}</ListItem>
            </List>
          </ListContainer>
        </InstructionsContainer>
        <ButtonContainer>
          <StyledButton onClick={() => goToSelfie()} $justifyContent="center" $width="80%">
            <img src={Camera} />
            <span>{t('global.button.takeSelfie')}</span>
          </StyledButton>
        </ButtonContainer>
      </Content>
    </Container>
  );
};

export default SelfieIntructions;
