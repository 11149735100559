import styled from 'styled-components';

export const InputContainer = styled.button`
  height: 40px !important;
  width: 280px;

  padding: 0 12px;
  margin: 0 !important;

  display: flex;
  column-gap: 6px;
  align-items: center;

  border: 1px solid var(--gray-light-3, #e5e7e7);
  background: var(--gray-bw-white, #fff);
  border-radius: 8px;

  > input {
    border: none;
    background-color: transparent;

    max-width: 100%;

    color: ${({ theme }) => theme.color.gray21};
    font-family: Satoshi;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */

    :focus {
      outline: none;
    }

    ::placeholder {
      color: ${({ theme }) => theme.color.primaryColor};
      color: var(--gray-dark-5, #7f7f7f);
    }
  }
`;
