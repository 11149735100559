import { useEffect, useState } from 'react';

interface CHeckBoxProps {
  defaultState?: boolean;
  cleaning?: boolean;
  onChange: () => void;
}

const CheckBoxComponent = ({ defaultState, cleaning, onChange }: CHeckBoxProps) => {
  const [checked, setchecked] = useState(defaultState);

  useEffect(() => {
    if (cleaning) {
      setchecked(false);
    }
  }, [cleaning]);

  return (
    <input
      type={'checkbox'}
      checked={checked}
      onChange={e => {
        onChange();
        setchecked(!checked);
      }}
    />
  );
};

export default CheckBoxComponent;
