import { FormAddress } from 'components/register-forms-mobile/address';
import { FormAddressTopOne } from 'components/register-forms-mobile/address-top-one';
import { FormDocuments } from 'components/register-forms-mobile/documents';
import { FormIncome } from 'components/register-forms-mobile/income';
import { FormIncomeTopOne } from 'components/register-forms-mobile/income-top-one';
import { FormPassword } from 'components/register-forms-mobile/password';
import { FormPersonalData } from 'components/register-forms-mobile/personal-data';
import { FormPersonalDataTopOne } from 'components/register-forms-mobile/personal-data-top-one';
import { FormGeneralData } from 'components/register-forms-mobile/general-data';
import { FormStepType } from 'model/enums/form-step-type';
import { useRegisterForm } from 'provider/register-form';
import { FormPatrimonialSituation } from 'components/register-forms-mobile/patrimonial-situation';
import { FormPartnerRelations } from 'components/register-forms-mobile/partner-relations';
import { FormLegalPersonDocuments } from 'components/register-forms-mobile/legal-person-documents';
import { useEffect } from 'react';
import { FormPersonalDataZM } from 'components/register-forms/personal-data-zm';
import { FormPersonalDataMulttiplo } from 'components/register-forms/personal-data-multtiplo';
import { FormPersonalDataMonjua } from 'components/register-forms/personal-data-monjua';
import { FormAddressMonjua } from 'components/register-forms/address-monjua';
import { FormAddressZM } from 'components/register-forms/address-zm';
import { FormIncomeMonjua } from 'components/register-forms/income-monjua';
import { FormReferencesMonjua } from 'components/edit-forms/references-monjua';
import { FormAddressMulttiplo } from 'components/register-forms/address-multtiplo';
import { FormDocuments as FormDocumentsOnlyID } from 'components/register-forms/documents';

interface IDynamicFormProps {
  type: string;
}

const DynamicMobileForm: React.FC<IDynamicFormProps> = props => {
  const { getClientData } = useRegisterForm();

  useEffect(() => {
    getClientData();
  }, []);

  const renderForms = () => {
    switch (props.type) {
      case FormStepType.PERSONAL_DATA:
        return <FormPersonalData />;
      case FormStepType.PERSONAL_DATA_TOP_ONE:
        return <FormPersonalDataTopOne />;
      case FormStepType.PERSONAL_DATA_ZM:
        return <FormPersonalDataZM />;
      case FormStepType.ADDRESS:
        return <FormAddress />;
      case FormStepType.ADDRESS_TOP_ONE:
        return <FormAddressTopOne />;
      case FormStepType.INCOME:
        return <FormIncome />;
      case FormStepType.INCOME_TOP_ONE:
        return <FormIncomeTopOne />;
      case FormStepType.PHYSICAL_PERSON_DOCUMENTS:
        return <FormDocuments />;
      case FormStepType.GENERAL_DATA:
        return <FormGeneralData />;
      case FormStepType.PATRIMONIAL_SITUATION:
        return <FormPatrimonialSituation />;
      case FormStepType.PARTNER_RELATIONS:
        return <FormPartnerRelations />;
      case FormStepType.LEGAL_PERSON_DOCUMENTS:
        return <FormLegalPersonDocuments />;
      case FormStepType.PASSWORD:
        return <FormPassword />;
      case FormStepType.PERSONAL_DATA_MULTTIPLO:
        return <FormPersonalDataMulttiplo />;
      case FormStepType.PERSONAL_DATA_MONJUA:
        return <FormPersonalDataMonjua />;
      case FormStepType.ADDRESS_MONJUA:
        return <FormAddressMonjua />;
      case FormStepType.ADDRESS_ZM:
        return <FormAddressZM />;
      case FormStepType.INCOME_MONJUA:
        return <FormIncomeMonjua />;
      case FormStepType.REFERENCES_MONJUA:
        return <FormReferencesMonjua />;
      case FormStepType.ADDRESS_MULTTIPLO:
        return <FormAddressMulttiplo />;
      case FormStepType.PHYSICAL_PERSON_IDENTIFICATION_DOCUMENT:
        return <FormDocumentsOnlyID isOnlyIdentificationDocument />;
      default:
        return <></>;
    }
  };

  return renderForms();
};

export default DynamicMobileForm;
