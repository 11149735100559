import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import * as S from './styles';
import StringUtils from 'shared/util/string-utils';

interface Props {
  name: string;
  label: string;
  helperText?: string;
  defaultValue?: string;
  maxLength?: number;
  disabled?: boolean;
  mask?: CustomTextFieldMaskTypes;
  placeholder?: string;
}

export enum CustomTextFieldMaskTypes {
  DATE = 'DATE',
  CPF = 'CPF',
  CNPJ = 'CNPJ',
  PHONE = 'PHONE',
  NO_WHITE_SPACE = 'NO_WHITE_SPACE',
  ONLY_LOWERCASE = 'ONLY_LOWERCASE',
}

const formatDateString = (value: string) => {
  const numericValue = value.replace(/\D/g, '');
  const formattedValue = numericValue.replace(/^(\d{2})(\d{2})(\d{4})$/, '$1/$2/$3').replace(/^(\d{2})(\d{2})/, '$1/$2');
  return formattedValue;
};

const CustomTextField = ({ name, label, helperText, defaultValue, maxLength, disabled, mask, placeholder }: Props) => {
  const { register } = useFormContext();

  const [valueEnteredInput, setValueEnteredInput] = useState<string>('');

  useEffect(() => {
    if (defaultValue != null) {
      handleInputChange(defaultValue);
    }
  }, [defaultValue]);

  const handleInputChange = (value: string) => {
    let formattedValue = value;

    switch (mask) {
      case CustomTextFieldMaskTypes.DATE:
        formattedValue = formatDateString(value);
        break;
      case CustomTextFieldMaskTypes.CPF:
        formattedValue = StringUtils.cpfMask(value);
        break;
      case CustomTextFieldMaskTypes.CNPJ:
        formattedValue = StringUtils.cnpjMask(value);
        break;
      case CustomTextFieldMaskTypes.PHONE:
        formattedValue = StringUtils.formatPhonePixKey(value);
        break;
      case CustomTextFieldMaskTypes.NO_WHITE_SPACE:
        formattedValue = StringUtils.removeAllWhitespace(value);
        break;
      case CustomTextFieldMaskTypes.ONLY_LOWERCASE:
        formattedValue = StringUtils.removeAccentToLowerCase(value);
        break;
    }

    setValueEnteredInput(formattedValue);
  };

  return (
    <S.ControlLabel
      label={label}
      labelPlacement="top"
      control={
        <S.InputText
          id={`#custom-text-${name}`}
          {...register(name)}
          disabled={disabled}
          variant="outlined"
          helperText={helperText}
          onChange={({ target }) => handleInputChange(target.value)}
          value={valueEnteredInput}
          inputProps={{ maxLength: maxLength ?? 255 }}
          autoComplete="off"
          placeholder={placeholder}
        />
      }
    />
  );
};

export default CustomTextField;
