import CustomButton from 'components/custom-button';
import * as S from './styles';
import { ReactComponent as DownloadReportIconSvg } from 'images/white-download-report.svg';
import { useTranslation } from 'react-i18next';

export type FileType = 'csv' | 'excel';

interface GenerateReportButtonsProps {
  handleGenerateReport: (type: FileType) => void;
  isLoading?: 'csv' | 'excel' | false;
}

export const GenerateReportButtons = ({ handleGenerateReport, isLoading }: GenerateReportButtonsProps) => {
  const { t } = useTranslation();

  return (
    <S.ReportContainer>
      <S.ReportTitle>{t('reportScreen.generateReport')}</S.ReportTitle>
      <CustomButton width="150px" onClick={() => handleGenerateReport('csv')} loading={isLoading === 'csv'}>
        <DownloadReportIconSvg />
        {t('global.fileType.csv')}
      </CustomButton>
      <CustomButton margin="0px 5px" width="150px" onClick={() => handleGenerateReport('excel')} loading={isLoading === 'excel'}>
        <DownloadReportIconSvg />
        {t('global.fileType.excel')}
      </CustomButton>
    </S.ReportContainer>
  );
};
