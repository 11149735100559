export enum IdentityDocumentTypes {
  RG = 'RG',
  RG_NOVO = 'NOVO RG',
  CNH = 'CNH',
  PASSAPORT = 'PASSAPORTE',
  CTPS = 'CTPS',
  OTHER = 'OTHER',
}

export const documentUnicoCodes = {
  FOTO_CLIENTE: '1',
  RG: '2',
  RG_FRENTE_NOVO: '501N',
  RG_VERSO_NOVO: '502N',
  RG_FRENTE: '501',
  RG_VERSO: '502',
  CPF: '3',
  CNH: '4',
  CNH_FRENTE: '401',
  CNH_VERSO: '402',
  CNH_DIGITAL: '403',
  CRM_FRENTE: '503',
  CRM_VERSO: '504',
  IDENTIDADE_MILITAR_FRENTE: '505',
  IDENTIDADE_MILITAR_VERSO: '506',
  COMPROVANTE_RENDA: '5',
  COMPROVANTE_ENDERECO: '6',
  IMPOSTO_RENDA: '7',
  CERTIDAO_CASAMENTO: '8',
  CERTIDAO_OBITO: '9',
  PASSAPORTE: '12',
  CARTAO_CNPJ: '13',
  CONTRATO_SOCIAL: '14',
  CARTEIRA_TRABALHO: '20',
  PAC: '21',
  CTPS: '22',
  IDENTIDADE_CLASSE: '24',
  CERTIDAO_NASCIMENTO: '25',
  TAD: '107',
  ASSINATURA_DIGITAL: '112',
  TERMO_CONSENTIMENTO: '114',
  VOUCHER: '115',
  PROPOSTA: '300',
  PROPOSTA_CONTRATO: '301',
  PROPOSTA_SEGURO: '302',
  PROPOSTA_GARANTIA: '303',
};
