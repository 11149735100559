import styled from 'styled-components';

export const ContainerDesktop = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  @media (max-width: 1080px) {
    max-width: 100%;
    div {
      max-width: 100%;
    }
  }
`;
export const TitleDesktop = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 20px;

  @media (max-width: 1080px) {
    font-size: 28px;
    padding: 8px;
  }
`;
