import { HttpRequestStatus } from 'model/enums/http-request-status';
import { validationEnum } from 'model/enums/organization';
import { ValidationType } from 'model/enums/simulator-vefication';
import { Reducer } from 'redux';
import { SystemParametersActionTypes, SystemParametersState } from './types';

export const initialState: SystemParametersState = {
  systemParameterStatus: HttpRequestStatus.NOOP,
  systemParameters: undefined,
};

const reducer: Reducer<SystemParametersState> = (state = initialState, action) => {
  switch (action.type) {
    case SystemParametersActionTypes.SYSTEM_PARAMETERS_REQUEST: {
      return { ...state, systemParameterStatus: HttpRequestStatus.ON_GOING };
    }
    case SystemParametersActionTypes.SYSTEM_PARAMETERS_SUCCESS: {
      const saveValidations = {};
      if (Array.isArray(action.payload)) {
        action.payload.map(validation => {
          if (validation.parameterType === ValidationType.LANDING_PAGE_TYPE) {
            saveValidations[validationEnum.formType] = validation;
          } else if (validation.parameterType === ValidationType.LOGIN_TYPE) {
            saveValidations[validationEnum.fieldType] = validation;
          }
        });
      }

      return { ...state, systemParameterStatus: HttpRequestStatus.SUCCESS, systemParameters: saveValidations };
    }
    case SystemParametersActionTypes.SYSTEM_PARAMETERS_ERROR: {
      return { ...state, systemParameterStatus: HttpRequestStatus.ERROR, error: action.payload as string | undefined };
    }
    case SystemParametersActionTypes.RESET_STATUS: {
      return { ...state, systemParameterStatus: HttpRequestStatus.NOOP };
    }
    default: {
      return state;
    }
  }
};

export { reducer as systemParametersReducer };
