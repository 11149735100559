import { Grid } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as S from './styles';
import CustomTextField from 'components/custom-text-field';
import StringUtils from 'shared/util/string-utils';
import { PhysicalPerson } from 'model/physical-person';
import TypesBankDetails from 'model/enums/types-bank-details';
import { FormProvider, useForm } from 'react-hook-form';
import SimpleRadioInput from './components/simple-radio-input';
import BankAccountForm from '../bank-account-form';
import { BankAccount } from 'model/bank-account';
import PixRegistrationForm from './components/pix-registration-form';
import useBankDetailsRegistrationFormPix from './components/hooks/use-bank-details-registration-form-pix';

interface Props extends WithTranslation {
  props: {
    clientData?: PhysicalPerson;
    clientId?: number;
    financingId?: number;
    selectedBankAccount?: BankAccount;
    isRegistrationByAdmin?: boolean;
    setSelectedBankAccount: Dispatch<SetStateAction<BankAccount | undefined>>;
    setIsShowModalCreateBankAccount: Dispatch<SetStateAction<boolean>>;
    setIsLoadingBankAccount: Dispatch<SetStateAction<boolean>>;
    getRegisteredBanks?: () => void;
    getBankAccount?: () => void;
  };
}

const BankDetailsRegistrationFormPix = ({ props, t }: Props) => {
  const { typeBankDetails, enableBankAccountEditingMode, enablePixKeyEditingMode, selectTypeDataBankDetails } =
    useBankDetailsRegistrationFormPix(props.selectedBankAccount);

  return (
    <>
      <FormProvider {...useForm()}>
        <Grid container rowSpacing={1.5} columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <CustomTextField name="name" label={t('dashboard.fullName')} defaultValue={props.clientData?.name} disabled />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField name="cpf" label={t('dashboard.cpf')} defaultValue={StringUtils.cpfMask(props.clientData?.cpf)} disabled />
          </Grid>
          <Grid item xs={12}>
            <S.SubTitle>{t('dashboard.paymentIsMadeInNameAndCpf')}</S.SubTitle>
            {props.selectedBankAccount?.id == null && (
              <>
                <S.Title>{t('dashboard.selectBankDetailsAdd')}</S.Title>

                <S.ContainerInputRadio>
                  <SimpleRadioInput
                    text={t('dashboard.agencyAndAccount')}
                    handleOnClick={() => selectTypeDataBankDetails(TypesBankDetails.AGENCY_AND_ACCOUNT)}
                    checked={typeBankDetails === TypesBankDetails.AGENCY_AND_ACCOUNT}
                  />
                  <SimpleRadioInput
                    text={t('dashboard.pixKey')}
                    handleOnClick={() => selectTypeDataBankDetails(TypesBankDetails.PIX_KEY)}
                    checked={typeBankDetails === TypesBankDetails.PIX_KEY}
                  />
                </S.ContainerInputRadio>
              </>
            )}
          </Grid>
        </Grid>
      </FormProvider>

      {(typeBankDetails === TypesBankDetails.AGENCY_AND_ACCOUNT || enableBankAccountEditingMode) && (
        <BankAccountForm
          hideShowCustomerData
          clientId={props.clientId}
          financingId={props.financingId}
          isRegistrationByAdmin={props.isRegistrationByAdmin}
          selectedBankAccount={props.selectedBankAccount}
          setSelectedBankAccount={props.setSelectedBankAccount}
          getRegisteredBanks={props.getRegisteredBanks}
          setIsShowModalCreateBankAccount={props.setIsShowModalCreateBankAccount}
          setIsLoadingBankAccount={props.setIsLoadingBankAccount}
          getBankAccount={props.getBankAccount}
        />
      )}

      {(typeBankDetails === TypesBankDetails.PIX_KEY || enablePixKeyEditingMode) && (
        <PixRegistrationForm
          clientId={props.clientId}
          financingId={props.financingId}
          isRegistrationByAdmin={props.isRegistrationByAdmin}
          selectedBankAccount={props.selectedBankAccount}
          setSelectedBankAccount={props.setSelectedBankAccount}
          setIsLoadingBankAccount={props.setIsLoadingBankAccount}
          setIsShowModalCreateBankAccount={props.setIsShowModalCreateBankAccount}
          getRegisteredBanks={props.getRegisteredBanks}
          getBankAccount={props.getBankAccount}
        />
      )}
    </>
  );
};

export default withTranslation()(BankDetailsRegistrationFormPix);
