import UseResponsiveContext from 'context/use-responsive-context';
import { MulttiploImgs } from 'images/multtiplo';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

export interface CardInfo {
  title: string;
  icon: JSX.Element;
}

interface NearProps {}

const Near: React.FC<NearProps> = () => {
  const { isTablet } = useContext(UseResponsiveContext);
  const { t } = useTranslation();
  const cardInfo: CardInfo[] = [
    { title: t('landingPage.multtiplo.near.card.1'), icon: <MulttiploImgs.digitalAccount /> },
    { title: t('landingPage.multtiplo.near.card.2'), icon: <MulttiploImgs.personalCredit /> },
    { title: t('landingPage.multtiplo.near.card.3'), icon: <MulttiploImgs.payrollLoans /> },
    { title: t('landingPage.multtiplo.near.card.4'), icon: <MulttiploImgs.issuanceOf /> },
    { title: t('landingPage.multtiplo.near.card.5'), icon: <MulttiploImgs.propertyFinancing /> },
    { title: t('landingPage.multtiplo.near.card.6'), icon: <MulttiploImgs.banking /> },
  ];

  return !isTablet ? <Desktop cardInfo={cardInfo} /> : <Mobile cardInfo={cardInfo} />;
};

export default Near;
