import { InputBaseComponentProps } from '@mui/material';
import InputMaskTypesOnlyNumbers from 'model/enums/input-mask-types-only-numbers';
import { ElementType, forwardRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputAttributes, NumericFormat, NumericFormatProps, PatternFormat, PatternFormatProps } from 'react-number-format';
import { ControlLabel, InputText } from 'components/custom-text-field/styles';

interface Props {
  name: string;
  label: string;
  helperText?: string;
  mask?: InputMaskTypesOnlyNumbers;
  defaultValue?: string;
  maxLength?: number;
  placeholder?: string;
}

const OnlyNumbers = forwardRef<HTMLElement, NumericFormatProps<InputAttributes>>((props, inputRef) => {
  const { ...other } = props;
  return <NumericFormat {...other} getInputRef={inputRef} />;
});

const MonetaryValue = forwardRef<HTMLElement, NumericFormatProps<InputAttributes>>((props, inputRef) => {
  const { ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      fixedDecimalScale
      decimalScale={2}
      prefix="R$"
      decimalSeparator=","
      thousandSeparator="."
    />
  );
});

const Phone = forwardRef<HTMLElement, NumericFormatProps<InputAttributes>>((props, inputRef) => {
  const { ...other } = props;
  return <PatternFormat {...other} getInputRef={inputRef} format="+55 (##) #####-####" mask="_" allowEmptyFormatting />;
});

const CustomInputNumbersOnly = ({ name, label, helperText, mask, defaultValue, maxLength, placeholder }: Props) => {
  const { register } = useFormContext();

  const [valueEnteredInput, setValueEnteredInput] = useState<string>(defaultValue ?? '');

  const inputWithMask = (typeMask?: InputMaskTypesOnlyNumbers) => {
    if (typeMask === InputMaskTypesOnlyNumbers.MONETARY_VALUE) {
      return MonetaryValue;
    }

    if (typeMask === InputMaskTypesOnlyNumbers.PHONE) {
      return Phone;
    }

    return OnlyNumbers;
  };

  return (
    <ControlLabel
      label={label}
      labelPlacement="top"
      control={
        <InputText
          {...register(name)}
          variant="outlined"
          onBlur={({ target }) => setValueEnteredInput(target.value)}
          value={valueEnteredInput}
          helperText={helperText}
          error={!!helperText}
          inputProps={{ maxLength: maxLength ?? 255 }}
          InputProps={{ inputComponent: inputWithMask(mask) as ElementType<InputBaseComponentProps> }}
          placeholder={placeholder}
        />
      }
    />
  );
};

export default CustomInputNumbersOnly;
