import { ScreenDimensions } from 'model/enums/screen-dimensions-types';
import styled from 'styled-components';

interface Props {
  noHeader?: boolean;
  blank?: boolean;
}

export const Container = styled.div`
  height: 336px;
  /* background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.borderGray};
  box-shadow: 0px 2px 12px ${({ theme }) => theme.color.shadow}; */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: space-around; */

  @media (max-width: ${ScreenDimensions.TABLET}) {
    height: auto;
  }
`;

export const Table = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.borderGray};
  box-shadow: 0px 2px 12px ${({ theme }) => theme.color.shadow};
  border-radius: 8px;
  padding: 16px 24px;
  margin: 24px 0px 0px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* justify-content: space-between; */
`;

export const Pagination = styled.div`
  width: 100%;
  margin: 32px 0px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* justify-self: flex-end; */

  @media (max-width: ${ScreenDimensions.TABLET}) {
    position: fixed;
    justify-content: center;
    height: 48px;
    background: ${({ theme }) => theme.color.white};
    bottom: 0;
    right: 0;
    border-top: 1px solid #e5e7e7;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* flex: 1; */
`;

export const Header = styled.div<Props>`
  min-height: 24px;

  font-size: 16px;
  font-weight: 700;
  color: ${({ theme, noHeader }) => (noHeader ? 'transparent' : theme.color.black0)};
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.gray20};
  margin: 16px 0px;
`;

export const Row = styled.div<Props>`
  min-height: 24px;

  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${({ theme, blank }) => (blank ? 'transparent' : theme.color.gray19)};
`;

export const InputPage = styled.div`
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: ${({ theme }) => theme.color.gray22};
    margin-right: 16px;
  }

  input + span {
    margin-left: 16px;
  }

  input {
    max-width: 40px;
    background-color: ${({ theme }) => theme.color.white};
    border: 1px solid ${({ theme }) => theme.color.gray23};
    border-radius: 6px;
    text-align: center;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: ${({ theme }) => theme.color.black0};

    :focus {
      outline: none;
    }

    ::placeholder {
      color: ${({ theme }) => theme.color.gray22};
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const ButtonsPage = styled.div`
  button.left {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  button.right {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

export const Button = styled.button`
  border: 1px solid ${({ theme }) => theme.color.primaryColor};
  background-color: ${({ theme }) => theme.color.white};
  text-align: center;

  :hover {
    border-color: ${({ theme }) => theme.color.primaryColorDarker};
    background-color: ${({ theme }) => theme.color.grayBackground};
  }
`;
