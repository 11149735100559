import analysisReportApi from 'api/analysis-report-api';
import { AxiosError, AxiosResponse } from 'axios';
import i18n from 'config/i18n';
import HttpStatus from 'model/enums/http-status';
import { FilterOptions } from 'model/filter-options';
import FilterParameters from 'model/filter-parameters';
import toastUtils from 'shared/util/toast-utils';

const analysisReportService = () => {
  const generateReport = async (filterParameters: FilterParameters): Promise<AxiosResponse<Blob>> => {
    try {
      const result: AxiosResponse<Blob> = await analysisReportApi.generateReport(filterParameters);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const generatePartnerReport = async (filterParameters: FilterParameters): Promise<AxiosResponse<Blob>> => {
    try {
      const result: AxiosResponse<Blob> = await analysisReportApi.generatePartnerReport(filterParameters);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      toastUtils.emitErrorToast(i18n.t('errorPage.title'));
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const generateBalanceReport = async (filterParameters: FilterParameters): Promise<AxiosResponse<Blob>> => {
    try {
      const result: AxiosResponse<Blob> = await analysisReportApi.generateBalanceReport(filterParameters);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      toastUtils.emitErrorToast(i18n.t('errorPage.title'));
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const getFilterOptions = async (): Promise<AxiosResponse<FilterOptions>> => {
    try {
      const result: AxiosResponse<FilterOptions> = await analysisReportApi.getFilterOptions();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result);
      }
      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  return {
    generateReport,
    generatePartnerReport,
    getFilterOptions,
    generateBalanceReport,
  };
};

export default analysisReportService();
