import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import myAccountApi, { FilterParams } from 'api/my-account-api';
import {
  AddBalanceResponse,
  BalanceRequest,
  BalanceRequestTransactions,
  BalanceTransactions,
  IdsArray,
  IdsArrayResponse,
  PaymentInfo,
} from 'model/my-account';
import { PaginationTotal } from 'model/pageable';

export const myAccountService = () => {
  const getBalances = async (subisidiaryId?: string): Promise<any> => {
    try {
      const result: AxiosResponse<any> = await myAccountApi.getBalances(subisidiaryId);
      if (result.data != null && result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const addBalance = async (amount: BalanceRequest, subisidiaryId?: string): Promise<AddBalanceResponse> => {
    try {
      const result: AxiosResponse<AddBalanceResponse> = await myAccountApi.addBalance(amount, subisidiaryId);
      if ((result.data != null && result.status === HttpStatus.OK) || result.status === HttpStatus.CREATED) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getBalancesTransactions = async (subisidiaryId?: string, filter?: FilterParams): Promise<PaginationTotal<BalanceTransactions>> => {
    try {
      const result: AxiosResponse<PaginationTotal<BalanceTransactions>> = await myAccountApi.getBalancesTransactions(subisidiaryId, filter);
      if (result.data != null && result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getBalancesRequestTransactions = async (
    subisidiaryId?: string,
    filter?: FilterParams
  ): Promise<PaginationTotal<BalanceRequestTransactions>> => {
    try {
      const result: AxiosResponse<PaginationTotal<BalanceRequestTransactions>> = await myAccountApi.getBalancesRequestTransactions(
        subisidiaryId,
        filter
      );
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getpaymentInfo = async (payemntId?: string): Promise<PaymentInfo> => {
    try {
      const result: AxiosResponse<PaymentInfo> = await myAccountApi.getpaymentInfo(payemntId);
      if ((result.data != null && result.status === HttpStatus.OK) || result.status === HttpStatus.CREATED) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const cancelTransaction = async (payemntId?: string): Promise<PaymentInfo> => {
    try {
      const result: AxiosResponse<PaymentInfo> = await myAccountApi.cancelTransaction(payemntId);
      if ((result.data != null && result.status === HttpStatus.OK) || result.status === HttpStatus.CREATED) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const downloadPDF = async (ids?: IdsArray): Promise<IdsArrayResponse> => {
    try {
      const result: AxiosResponse<IdsArrayResponse> = await myAccountApi.downloadPDF(ids);
      if ((result.data != null && result.status === HttpStatus.OK) || result.status === HttpStatus.CREATED) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const applyBalance = async (id: number): Promise<any> => {
    try {
      const result: AxiosResponse<any> = await myAccountApi.applyBalance(id);
      if (result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  return {
    getBalances,
    addBalance,
    getBalancesTransactions,
    getBalancesRequestTransactions,
    getpaymentInfo,
    cancelTransaction,
    downloadPDF,
    applyBalance,
  };
};

export default myAccountService();
