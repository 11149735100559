import Document from 'images/document.svg';
import Proof from 'images/proof.svg';
import { useRegisterForm } from 'provider/register-form';
import { useTranslation } from 'react-i18next';
import { Container, IdentityContainer, InfoContainer, ProofsContainer, Title } from './styles';
import { Button, ButtonsContainer } from 'components/register-forms-mobile/styles';

interface IInstructionsScreenProps {
  actualStep: number;
  setActualStep: (step: number) => void;
}

export const InstructionsScreen: React.FC<IInstructionsScreenProps> = props => {
  const { t } = useTranslation();
  const { isFirstForm, onClickBack } = useRegisterForm();

  return (
    <Container>
      <Title style={{ margin: '0px 16px' }}>{t('documents.title')}</Title>
      <IdentityContainer>
        <Title>{t('documents.identity.title')}</Title>
        <InfoContainer>
          <img src={Document} />
          <ul>
            <li>{t('documents.identity.steps.one')}</li>
            <li>{t('documents.identity.steps.two')}</li>
            <li>{t('documents.identity.steps.three')}</li>
            <li>{t('documents.identity.steps.four')}</li>
            <li>{t('documents.identity.steps.five')}</li>
            <li>{t('documents.identity.steps.six')}</li>
          </ul>
        </InfoContainer>
      </IdentityContainer>
      <ProofsContainer>
        <Title>{t('documents.proofs.title')}</Title>
        <InfoContainer>
          <img src={Proof} />
          <ul>
            <li>{t('documents.proofs.steps.one')}</li>
            <li>{t('documents.proofs.steps.two')}</li>
            <li>{t('documents.proofs.steps.three')}</li>
            <li>{t('documents.proofs.steps.four')}</li>
          </ul>
        </InfoContainer>
      </ProofsContainer>
      <ButtonsContainer>
        {!isFirstForm() ? (
          <Button onClick={onClickBack}>
            <span>{t('global.button.backStep')}</span>
          </Button>
        ) : (
          <div />
        )}
        <Button $primary onClick={() => props.setActualStep(props.actualStep + 1)}>
          <span>{t('global.button.nextStep')}</span>
        </Button>
      </ButtonsContainer>
    </Container>
  );
};
