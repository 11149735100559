export enum Authority {
  ROLE_INTERNAL = 'ROLE_INTERNAL',
  ROLE_INTERNAL_ADMIN = 'ROLE_INTERNAL_ADMIN',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_USER = 'ROLE_USER',
  ROLE_CLIENT = 'ROLE_CLIENT',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_SELLER = 'ROLE_SELLER',
  ROLE_ANALYST = 'ROLE_ANALYST',
  ROLE_FINANCIAL = 'ROLE_FINANCIAL',
  ROLE_ATTENDANT = 'ROLE_ATTENDANT',
}
