import { CSSProperties } from 'react';
import { ReactComponent as ArrowIconSvg } from '../../images/white_arrow_forward.svg';
import { StyledButton } from './styles';

interface IProps {
  text: string;
  white?: boolean;
  style?: CSSProperties;
  onClick?: any;
}

const ButtonArrow = ({ text, white, style, onClick }: IProps) => {
  return (
    <StyledButton style={style} $isWhite={white} onClick={onClick}>
      {text}
      <ArrowIconSvg />
    </StyledButton>
  );
};

export default ButtonArrow;
