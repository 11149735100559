import { IconSuccessContainer, StyledInnerContainer, StyledPaintSvg } from './styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import MobileHeader from 'components/mobile-header';
import { useHistory } from 'react-router-dom';
import ContractContext from 'context/contract';
import { useContext, useEffect, useState } from 'react';
import contractService from 'services/contract-service';
import { RoutePath } from 'model/enums/route-path';
import { ReactComponent as SucessIcon } from 'images/gif/sucess.svg';
import LoadingAnimation from 'components/loading-big-animation';
import { LoadingContainer } from '../styles';

const OperationSucceed = ({ t }: WithTranslation) => {
  const history = useHistory();
  const [signedSuccess, setSignedSuccess] = useState(false);
  const { personSignatureId } = useContext(ContractContext);

  useEffect(() => {
    if (personSignatureId != null) {
      contractService
        .finalizeContractSigning(personSignatureId)
        .then(_ => setSignedSuccess(true))
        .catch(() => history.push(RoutePath.ERROR_ANALYSIS));
    } else {
      history.push(RoutePath.ERROR_ANALYSIS);
    }
  }, [personSignatureId]);

  return (
    <>
      <MobileHeader></MobileHeader>
      <StyledInnerContainer>
        {!signedSuccess ? (
          <>
            <h1>{t('contract.waitingStep.finishingSubscription.title')}</h1>
            <span>{t('contract.waitingStep.finishingSubscription.subtitle')}</span>
            <LoadingContainer style={{ marginBottom: '24px' }}>
              <LoadingAnimation />
            </LoadingContainer>
          </>
        ) : (
          <>
            <h1>{t('contract.waitingStep.operationSucceed.title')}</h1>
            <span>{t('contract.waitingStep.operationSucceed.subtitle')}</span>
            <IconSuccessContainer>
              <SucessIcon />
            </IconSuccessContainer>
          </>
        )}

        <div className="waiting-step-footer">{t('contract.footer')}</div>
      </StyledInnerContainer>
    </>
  );
};

export default withTranslation()(OperationSucceed);
