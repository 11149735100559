import { CreateFinancingResponse } from 'model/create-financing-response';
import React, { createContext, useContext, useState } from 'react';

const DEFAULT_VALUE: DefaultValue = {
  simulatorScreenData: {},
  simulatorInsuranceInfo: {},
  simulatorLimits: {
    newMaxValue: undefined,
    newMinInstallment: undefined,
    newMaxInstallment: undefined,
    newMonthLimit: undefined,
  },
  setSimulatorScreenData: () => {},
  setSimulatorInsuranceInfo: () => {},
  setSimulatorLimits: () => {},
};

export interface DefaultValue {
  simulatorScreenData: CreateFinancingResponse;
  simulatorInsuranceInfo: CreateFinancingResponse;
  simulatorLimits: SimulatorLimit;
  setSimulatorScreenData: (data: CreateFinancingResponse) => void;
  setSimulatorInsuranceInfo: (data: CreateFinancingResponse) => void;
  setSimulatorLimits: (data: SimulatorLimit) => void;
}

export interface SimulatorLimit {
  newMaxValue?: number;
  newMinInstallment?: number;
  newMaxInstallment?: number;
  newMonthLimit?: number;
}

const SimulatorContext = createContext<DefaultValue>(DEFAULT_VALUE);
const useSimulatorContext = () => useContext(SimulatorContext);

const SimulatorContextProvider: React.FC<{ children: any }> = ({ children }) => {
  const [simulatorScreenData, setSimulatorScreenData] = useState(DEFAULT_VALUE.simulatorScreenData);
  const [simulatorInsuranceInfo, setSimulatorInsuranceInfo] = useState(DEFAULT_VALUE.simulatorInsuranceInfo);
  const [simulatorLimits, setSimulatorLimits] = useState(DEFAULT_VALUE.simulatorLimits);

  return (
    <SimulatorContext.Provider
      value={{
        simulatorScreenData,
        setSimulatorScreenData,
        simulatorLimits,
        setSimulatorLimits,
        simulatorInsuranceInfo,
        setSimulatorInsuranceInfo,
      }}
    >
      {children}
    </SimulatorContext.Provider>
  );
};

export { SimulatorContextProvider, useSimulatorContext };
export default SimulatorContext;
