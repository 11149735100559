import styled from 'styled-components';

interface ActiveProps {
  $active?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  position: absolute;
  width: 165px;
  height: 241px;
  border-radius: 8px;
`;

export const StepContainer = styled.div<ActiveProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 15px;
  width: 100%;
  height: 50px;
  background: #eff6ff;
  background: ${props => (props.$active ? '#eff6ff' : 'transparent')};
  border-radius: 4px;
`;

export const CustomStep = styled.div<ActiveProps>`
  display: flex;
  min-width: 16px;
  min-height: 16px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background: ${props => (props.$active ? '#FFFFFF' : 'transparent')};
  border: 1px solid #fff;
  margin: 3px 0;

  .Complete-StepIcon-circle {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #e0e5f6;
  }
  .StepIcon-completedIcon {
    color: #4b6fdd;
    font-size: 11px;
  }
  .StepIcon-circle {
    width: 16px;
    height: 16px;
    background: #4b6fdd;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${props => (props.$active ? '#4b6fdd' : '#e0e5f6')};
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 150%;
      display: flex;
      align-items: center;
      text-align: center;
      color: ${props => (props.$active ? '#fff' : '#4b6fdd')};
    }
  }
`;

export const StepLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const StepLabel = styled.span<ActiveProps>`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  text-align: right;
  color: ${props => (props.$active ? '#4b6fdd' : '#7F7F7F')};
  text-align: start;
`;
