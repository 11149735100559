import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 0px 16px 0px;
  width: 100%;
  height: 100%;
  max-width: 672px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 16px 0px 16px 0px;
`;

export const PlusIcon = styled.img`
  width: 22px;
  height: 22px;
  fill: #4b6fdd;
`;

export const AddPartner = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 0px 16px 0px;
  gap: 10px;
  height: 80px;
  width: 100%;
  border: 1.5px dashed #4b6fdd;
  border-radius: 8px;
  background-color: #fff;
  .icon {
    width: 22px;
    height: 22px;
    line {
      stroke: #4b6fdd;
    }
  }
  .subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #4b6fdd;
  }
`;

export const PartnerItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px 16px 24px;
  margin: 0px 0px 10px 0px;
  margin-top: 25px;
  gap: 10px;
  height: 80px;
  width: 100%;
  border: 1.5px solid #e5e7e7;
  border-radius: 8px;
  background-color: #fff;
  .button {
    width: 25px;
    height: 25px;
  }
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #333;
  }
  .subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #7f7f7f;
  }
`;

export const EditButton = styled.button`
  background-color: transparent;
  margin: 0px 3px 0px 3px;
  align-items: center;
  justify-content: center;
  border: none;
`;
