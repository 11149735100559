import { FormInputsContainer, Row } from 'components/edit-forms/styles';
import { Column } from 'components/register-forms-mobile/styles';
import { SearchSelectInput } from 'components/search-selected-input';
import InputText from 'components/styled-input';
import { TextInput } from 'components/text-input';
import { Mask } from 'model/enums/mask-types';
import { useEditForm } from 'provider/edit-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { genderType, identityDocumentTypes, nationalityType } from 'shared/util/select-utils';
import StringUtils from 'shared/util/string-utils';

export const ContainerInputsPersonalDataZm = props => {
  const { t } = useTranslation();
  const { initialClientData } = useEditForm();
  const { maritalStatus, getMaritalStatus } = useSelectLists();
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState('');
  const maritalStatusDV = maritalStatus.find(occ => occ.id === initialClientData?.physicalPerson?.maritalStatus?.id)?.displayValue;
  const [stateName, setStateName] = useState('');
  const [statePage, setStatePage] = useState(0);
  const { states, getStates, statesTotalPages } = useSelectLists();

  useEffect(() => {
    getStates({ name: stateName, page: statePage, size: 7, ac: '' }, true);
  }, [statePage]);

  useEffect(() => {
    getMaritalStatus();
  }, []);

  const renderMobile = () => {
    return (
      <Column>
        <InputText
          isRequired
          disabled={props.onlyView}
          name="name"
          label={t('register.inputs.name')}
          placeholder={t('register.placeholder.name')}
          defaultValue={initialClientData?.physicalPerson?.name}
        />
        <InputText
          isRequired
          disabled={props.onlyView}
          name="birth"
          label={t('register.inputs.birthDate')}
          placeholder={t('register.placeholder.birthDate')}
          maxLength={10}
          mask={Mask.BIRTHDAY}
          defaultValue={
            initialClientData?.physicalPerson?.birth ? StringUtils.dateFormatMask(initialClientData?.physicalPerson?.birth.toString()) : ''
          }
        />
        <TextInput name="genderType" label={t('register.inputs.genderType')} isRequired hasCustomInput>
          <SearchSelectInput
            name="genderType"
            placeholder={t('register.placeholder.genderType')}
            options={genderType}
            defaultOptions={genderType}
            defaultValue={genderType.find(res => res.value === initialClientData?.physicalPerson?.genderType)?.label}
          />
        </TextInput>
        <TextInput name="nationalityType" label={t('register.inputs.nationalityType')} isRequired hasCustomInput>
          <SearchSelectInput
            name="nationalityType"
            disabled={props.onlyView}
            placeholder={t('register.placeholder.nationalityType')}
            options={nationalityType}
            defaultOptions={nationalityType}
            defaultValue={nationalityType.find(res => res.value === initialClientData?.physicalPerson?.nationalityType)?.label}
          />
        </TextInput>
        <InputText
          isRequired
          name="cpf"
          disabled
          label={t('register.inputs.cpf')}
          placeholder={t('register.placeholder.cpf')}
          mask={Mask.CPF}
          maxLength={14}
          defaultValue={initialClientData?.physicalPerson?.cpf}
        />
        <TextInput name="identityDocumentType" label={t('register.inputs.identityDocumentType')} isRequired hasCustomInput>
          <SearchSelectInput
            name="identityDocumentType"
            placeholder={t('register.placeholder.identityDocumentType')}
            options={identityDocumentTypes}
            defaultOptions={identityDocumentTypes}
            defaultValue={identityDocumentTypes.find(res => res.value === initialClientData?.physicalPerson?.identityDocumentType)?.label}
          />
        </TextInput>
        <InputText
          isRequired
          name="identityDocument"
          label={t('register.inputs.identityDocument')}
          placeholder={t('register.placeholder.identityDocument')}
          defaultValue={initialClientData?.physicalPerson?.identityDocument}
        />
        <InputText
          isRequired
          name="identityDocumentIssuingBody"
          label={t('register.inputs.rgIssuingBody')}
          placeholder={t('register.placeholder.rgIssuingBody')}
          defaultValue={initialClientData?.physicalPerson?.identityDocumentIssuingBody}
          maxLength={20}
        />
        <TextInput name="identityDocumentUfIssuingBody" label={t('register.inputs.rgUfIssuingBody')} isRequired hasCustomInput>
          <SearchSelectInput
            disabled={props.onlyView}
            name="identityDocumentUfIssuingBody"
            placeholder={t('register.placeholder.rgUfIssuingBody')}
            options={states.map(state => ({ label: `${state.acronym} - ${state.name}`, value: state.id.toString() }))}
            defaultOptions={states.map(state => ({ label: `${state.acronym} - ${state.name}`, value: state.id.toString() }))}
            defaultValue={initialClientData?.physicalPerson?.identityDocumentUfIssuingBody}
            handleChange={values => {
              setStateName(values.name);
              setStatePage(0);
              getStates({ name: values.name, size: 7 });
            }}
            handleOnScroll={() => {
              if (statePage < statesTotalPages - 1) {
                setStatePage(statePage + 1);
              }
            }}
          />
        </TextInput>
        <InputText
          isRequired
          name="identityDocumentIssueDate"
          label={t('register.inputs.rgDateOfIssuance')}
          placeholder={t('register.placeholder.rgDateOfIssuance')}
          maxLength={10}
          mask={Mask.BIRTHDAY}
          defaultValue={
            initialClientData?.physicalPerson?.identityDocumentIssueDate
              ? StringUtils.dateFormatMask(initialClientData?.physicalPerson?.identityDocumentIssueDate.toString())
              : ''
          }
        />
        <InputText
          isRequired
          disabled={props.onlyView}
          name="email"
          label={t('register.inputs.email')}
          placeholder={t('register.placeholder.email')}
          defaultValue={initialClientData?.email}
        />
        <InputText
          isRequired
          name="phone"
          disabled={props.onlyView}
          label={t('register.inputs.phone')}
          placeholder={t('register.placeholder.phone')}
          mask={Mask.PHONE}
          maxLength={15}
          defaultValue={initialClientData?.phone}
        />
        <InputText
          isRequired
          name="motherName"
          label={t('register.inputs.motherName')}
          placeholder={t('register.placeholder.motherName')}
          defaultValue={initialClientData?.physicalPerson?.motherName}
        />
        <InputText
          name="fatherName"
          label={t('register.inputs.fatherName')}
          placeholder={t('register.placeholder.fatherName')}
          defaultValue={initialClientData?.physicalPerson?.fatherName}
        />
        <TextInput name="maritalStatus" label={t('register.inputs.maritalStatus')} isRequired hasCustomInput>
          <SearchSelectInput
            name="maritalStatus"
            placeholder={t('register.placeholder.maritalStatus')}
            options={maritalStatus.map(el => ({
              label: el.displayValue[0].toUpperCase() + el.displayValue.toLowerCase().substring(1) ?? '',
              value: el.id.toString(),
            }))}
            defaultValue={maritalStatusDV && maritalStatusDV[0].toUpperCase() + maritalStatusDV.toLowerCase().substring(1)}
            handleChange={values => {
              setSelectedMaritalStatus(values.name);
            }}
            defaultOptions={maritalStatus.map(el => ({
              label: el.displayValue[0].toUpperCase() + el.displayValue.toLowerCase().substring(1) ?? '',
              value: el.id.toString(),
            }))}
          />
        </TextInput>
        <InputText
          name="spouseName"
          label={t('register.inputs.spouseName')}
          placeholder={t('register.placeholder.spouseName')}
          defaultValue={initialClientData?.physicalPerson?.spouse?.name}
        />
        <InputText
          name="spouseCpf"
          label={t('register.inputs.spouseCpf')}
          placeholder={t('register.placeholder.cpf')}
          mask={Mask.CPF}
          defaultValue={initialClientData?.physicalPerson?.spouse?.cpf}
          maxLength={14}
        />
      </Column>
    );
  };
  return (
    <FormInputsContainer $visible={props.visible}>
      {props.mobile ? (
        renderMobile()
      ) : (
        <>
          <Row>
            <InputText
              isRequired
              disabled={props.onlyView}
              name="name"
              label={t('register.inputs.name')}
              placeholder={t('register.placeholder.name')}
              defaultValue={initialClientData?.physicalPerson?.name}
            />
            <InputText
              isRequired
              disabled={props.onlyView}
              name="birth"
              label={t('register.inputs.birthDate')}
              placeholder={t('register.placeholder.birthDate')}
              maxLength={10}
              mask={Mask.BIRTHDAY}
              defaultValue={
                initialClientData?.physicalPerson?.birth
                  ? StringUtils.dateFormatMask(initialClientData?.physicalPerson?.birth.toString())
                  : ''
              }
            />
            <TextInput name="genderType" label={t('register.inputs.genderType')} isRequired hasCustomInput>
              <SearchSelectInput
                name="genderType"
                placeholder={t('register.placeholder.genderType')}
                options={genderType}
                defaultOptions={genderType}
                defaultValue={genderType.find(res => res.value === initialClientData?.physicalPerson?.genderType)?.label}
              />
            </TextInput>
          </Row>
          <Row>
            <TextInput name="nationalityType" label={t('register.inputs.nationalityType')} isRequired hasCustomInput>
              <SearchSelectInput
                name="nationalityType"
                disabled={props.onlyView}
                placeholder={t('register.placeholder.nationalityType')}
                options={nationalityType}
                defaultOptions={nationalityType}
                defaultValue={nationalityType.find(res => res.value === initialClientData?.physicalPerson?.nationalityType)?.label}
              />
            </TextInput>
            <InputText
              isRequired
              name="cpf"
              disabled
              label={t('register.inputs.cpf')}
              placeholder={t('register.placeholder.cpf')}
              mask={Mask.CPF}
              maxLength={14}
              defaultValue={initialClientData?.physicalPerson?.cpf}
            />
            <TextInput name="identityDocumentType" label={t('register.inputs.identityDocumentType')} isRequired hasCustomInput>
              <SearchSelectInput
                name="identityDocumentType"
                placeholder={t('register.placeholder.identityDocumentType')}
                options={identityDocumentTypes}
                defaultOptions={identityDocumentTypes}
                defaultValue={
                  identityDocumentTypes.find(res => res.value === initialClientData?.physicalPerson?.identityDocumentType)?.label
                }
              />
            </TextInput>
          </Row>
          <Row>
            <InputText
              isRequired
              name="identityDocument"
              label={t('register.inputs.identityDocument')}
              placeholder={t('register.placeholder.identityDocument')}
              defaultValue={initialClientData?.physicalPerson?.identityDocument}
            />
            <InputText
              isRequired
              name="identityDocumentIssuingBody"
              label={t('register.inputs.rgIssuingBody')}
              placeholder={t('register.placeholder.rgIssuingBody')}
              defaultValue={initialClientData?.physicalPerson?.identityDocumentIssuingBody}
              maxLength={20}
            />
            <TextInput name="identityDocumentUfIssuingBody" label={t('register.inputs.rgUfIssuingBody')} isRequired hasCustomInput>
              <SearchSelectInput
                disabled={props.onlyView}
                name="identityDocumentUfIssuingBody"
                placeholder={t('register.placeholder.rgUfIssuingBody')}
                options={states.map(state => ({ label: `${state.acronym} - ${state.name}`, value: state.id.toString() }))}
                defaultOptions={states.map(state => ({ label: `${state.acronym} - ${state.name}`, value: state.id.toString() }))}
                defaultValue={initialClientData?.physicalPerson?.identityDocumentUfIssuingBody}
                handleChange={values => {
                  setStateName(values.name);
                  setStatePage(0);
                  getStates({ name: values.name, size: 7 });
                }}
                handleOnScroll={() => {
                  if (statePage < statesTotalPages - 1) {
                    setStatePage(statePage + 1);
                  }
                }}
              />
            </TextInput>
          </Row>
          <Row>
            <InputText
              isRequired
              name="identityDocumentIssueDate"
              label={t('register.inputs.rgDateOfIssuance')}
              placeholder={t('register.placeholder.rgDateOfIssuance')}
              maxLength={10}
              mask={Mask.BIRTHDAY}
              defaultValue={
                initialClientData?.physicalPerson?.identityDocumentIssueDate
                  ? StringUtils.dateFormatMask(initialClientData?.physicalPerson?.identityDocumentIssueDate.toString())
                  : ''
              }
            />
            <InputText
              isRequired
              disabled={props.onlyView}
              name="email"
              label={t('register.inputs.email')}
              placeholder={t('register.placeholder.email')}
              defaultValue={initialClientData?.email}
            />
            <InputText
              isRequired
              name="phone"
              disabled={props.onlyView}
              label={t('register.inputs.phone')}
              placeholder={t('register.placeholder.phone')}
              mask={Mask.PHONE}
              maxLength={15}
              defaultValue={initialClientData?.phone}
            />
          </Row>
          <Row>
            <InputText
              isRequired
              name="motherName"
              label={t('register.inputs.motherName')}
              placeholder={t('register.placeholder.motherName')}
              defaultValue={initialClientData?.physicalPerson?.motherName}
            />
            <InputText
              name="fatherName"
              label={t('register.inputs.fatherName')}
              placeholder={t('register.placeholder.fatherName')}
              defaultValue={initialClientData?.physicalPerson?.fatherName}
            />
            <TextInput name="maritalStatus" label={t('register.inputs.maritalStatus')} isRequired hasCustomInput>
              <SearchSelectInput
                name="maritalStatus"
                placeholder={t('register.placeholder.maritalStatus')}
                options={maritalStatus.map(el => ({
                  label: el.displayValue[0].toUpperCase() + el.displayValue.toLowerCase().substring(1) ?? '',
                  value: el.id.toString(),
                }))}
                defaultValue={maritalStatusDV && maritalStatusDV[0].toUpperCase() + maritalStatusDV.toLowerCase().substring(1)}
                handleChange={values => {
                  setSelectedMaritalStatus(values.name);
                }}
                defaultOptions={maritalStatus.map(el => ({
                  label: el.displayValue[0].toUpperCase() + el.displayValue.toLowerCase().substring(1) ?? '',
                  value: el.id.toString(),
                }))}
              />
            </TextInput>
          </Row>
          <Row style={{ justifyContent: 'left', columnGap: '49px' }}>
            <InputText
              name="spouseName"
              label={t('register.inputs.spouseName')}
              placeholder={t('register.placeholder.spouseName')}
              defaultValue={initialClientData?.physicalPerson?.spouse?.name}
            />
            <InputText
              name="spouseCpf"
              label={t('register.inputs.spouseCpf')}
              placeholder={t('register.placeholder.cpf')}
              mask={Mask.CPF}
              defaultValue={initialClientData?.physicalPerson?.spouse?.cpf}
              maxLength={14}
            />
          </Row>
        </>
      )}
    </FormInputsContainer>
  );
};
