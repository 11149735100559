import { Container, QuantityContainer, RedirectButton, StatusContainer, SvgBackground, Title, TitleContainer, Total } from './styles';
import { FaChevronRight } from 'react-icons/fa';

export interface StatusQuantity {
  quantity: number;
  status: string;
}

interface RedirectCardProps {
  title: string;
  redirectUrl: string;
  totalQuantity: number;
  statusQuantity: StatusQuantity[];
  svgBackground?: JSX.Element;
}

export const RedirectCard = ({ title, redirectUrl, totalQuantity, statusQuantity, svgBackground }: RedirectCardProps) => {
  return (
    <Container>
      {svgBackground && <SvgBackground>{svgBackground}</SvgBackground>}
      <TitleContainer>
        <Title>{title}</Title>
        <RedirectButton to={redirectUrl}>
          <span>
            Ver todos <FaChevronRight />
          </span>
        </RedirectButton>
      </TitleContainer>
      <QuantityContainer>
        <Total>{totalQuantity}</Total>
        <StatusContainer>
          {statusQuantity.map(
            status =>
              status.quantity > 0 && (
                <div key={status.status}>
                  <span className="quantity">{status.quantity}</span>
                  <span className="statusLabel">{status.status}</span>
                </div>
              )
          )}
        </StatusContainer>
      </QuantityContainer>
    </Container>
  );
};
