import styled from 'styled-components';

interface Props {
  $calcWidth?: string;
  $noMargin?: boolean;
  $isDisabled?: boolean;
}

export const PaymentContainer = styled.div`
  margin: 24px 0px 52px;
  min-height: 402px;

  position: relative;
`;

export const ButtonPayment = styled.button<Props>`
  width: ${({ $calcWidth }) => `calc(242px + ${$calcWidth ?? '0px'})`};
  height: 48px;
  background: transparent;
  border: 1.5px solid #0524dd;
  border-radius: 24px;

  font-weight: 700;
  font-size: 16px;
  color: #0524dd;
  margin: ${({ $noMargin }) => ($noMargin ? '0px' : '20px 0px 0px')};

  :hover {
    color: #fff;
    background: #0524dd;
  }
`;

export const AddPayment = styled.div``;

export const FormConfirmPayment = styled.form`
  width: 100%;
  height: 92px;

  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid #e5e7e7;
`;

export const ButtonAddPayment = styled.button<Props>`
  width: 100%;
  height: 48px;
  background: #ffffff;

  border: ${({ $isDisabled }) => ($isDisabled ? '1.5px dashed grey' : '1.5px dashed #0524dd')};

  border-radius: 8px;

  font-weight: 700;
  font-size: 16px;
  color: #0524dd;
  color: ${({ $isDisabled }) => ($isDisabled ? 'grey' : '#0524dd')};

  position: absolute;
  bottom: -52px;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;

  :hover {
    color: #fff;
    background: ${({ $isDisabled }) => ($isDisabled ? 'grey' : '#0524dd')};
  }
`;
export const PaymentList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const EmptyList = styled.div`
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
`;
