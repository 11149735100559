import styled from 'styled-components';

export const StyledGuarantorsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg path {
    fill: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const StyledList = styled.ul`
  width: 90%;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 8px 0;
    border-bottom: ${({ theme }) => `1px solid ${theme.color.gray20}`};
  }

  li:nth-child(1) {
    padding-top: 0;
  }

  li:nth-last-child(1) {
    padding-bottom: 0;
    border: none;
  }
`;

export const StyledButtonAddGuarantorContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledChevronRightContainer = styled.div`
  display: flex;
  justify-content: space-between;

  svg path {
    fill: ${({ theme }) => theme.color.primaryColor};
  }
`;
