import styled from 'styled-components';

interface StyledProps {
  $stepCompleted: boolean;
}

export const StepsContainer = styled.div`
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.color.gray20}`};
  padding: 12px 24px;

  .MuiStep-root {
    padding: 0;
  }

  .MuiStepper-root {
    justify-content: space-between;
  }
`;

export const StepsTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.gray22};
  margin-bottom: 4px;
`;

export const StepConnector = styled.div<StyledProps>`
  height: 1px;
  min-width: 15px;
  background-color: ${({ theme, $stepCompleted }) => ($stepCompleted ? theme.color.primaryColor : theme.color.gray20)};
`;
