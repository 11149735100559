import styled from 'styled-components';

export const Container = styled.div``;

export const ContainerTextArea = styled.textarea`
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--gray-light-2, #ccc);
  width: 300px;
  align-self: center;
  margin-bottom: 70px;
  min-height: 92px;
`;

export const PopupTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 20px;
  color: #333333;
  margin: 24px 0px;
`;
export const PopupText = styled.span`
  margin-bottom: 10px;
  width: fit-content;
`;
