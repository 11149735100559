import styled from 'styled-components';

interface Props {
  $flexInit?: boolean;
}

export const Container = styled.div`
  margin-top: 32px;
`;

export const ContainerInfoButton = styled.div`
  min-width: 100%;
  display: flex;
  place-content: flex-start;
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const InputBox = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 16px;
  row-gap: 16px;

  justify-content: space-between;
  margin-bottom: 16px;
  flex-flow: wrap;

  @media (max-width: 1300px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

export const Separator = styled.div`
  background-color: ${({ theme }) => theme.color.gray20};
  height: 1px;
  flex: 1;
  margin: 32px 0px;
`;

export const EditButtonContainer = styled.div`
  height: 67px;
  display: flex;
  align-items: end;
  justify-content: left;
`;

export const EditButton = styled.button`
  background: transparent;
  border: none;

  display: flex;
  align-items: center;

  > span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.primaryColor};
    margin-left: 10px;
  }
`;

export const Labelinput = styled.div`
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #656666;
    margin-bottom: 5px;
  }

  span + span {
    margin-left: 0.125rem;
    color: #f0654a;
  }
`;

export const StyledSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  column-gap: 16px;
  row-gap: 16px;
  flex-wrap: wrap;
`;
