import { Modal } from '@mui/material';
import CustomButton from 'components/custom-button';
import { ReactComponent as XSvg } from 'images/x.svg';
import { IconContainer, StyledButtonsContainer, StyledContainer, StyledMessage, StyledTitle, StyledXIconContainer } from './styles';

interface IProps {
  title?: string;
  children?: JSX.Element;
  firstButtonText?: string;
  secondButtonText?: string;
  isShowModal: boolean;
  formId?: string;
  onCloseModal: () => void;
  handleClickFirstButton?: () => void;
  handleClickSecondButton?: () => void;
  icon?: JSX.Element;
  freeContent?: boolean;
  message?: string;
  large?: boolean;
  loading?: boolean;
  firstButtonIcon?: JSX.Element;
  secondButtonIcon?: JSX.Element;
  hideButtons?: boolean;
  disableSecondaryButton?: boolean;
}

const CustomModalTwoButtons = ({
  title,
  children,
  firstButtonText,
  secondButtonText,
  isShowModal,
  formId,
  onCloseModal,
  handleClickFirstButton,
  handleClickSecondButton,
  icon,
  freeContent,
  message,
  large,
  loading,
  firstButtonIcon,
  secondButtonIcon,
  hideButtons = false,
  disableSecondaryButton,
}: IProps) => {
  return (
    <Modal open={isShowModal} onClose={() => onCloseModal()}>
      <StyledContainer $large={large}>
        <StyledXIconContainer>
          <XSvg onClick={() => onCloseModal()} style={{ cursor: 'pointer', marginTop: '-10px' }} />
        </StyledXIconContainer>
        {icon && <IconContainer>{icon}</IconContainer>}
        {title != null && <StyledTitle>{title}</StyledTitle>}
        {message && <StyledMessage>{message}</StyledMessage>}
        {children && children}

        {!hideButtons && (
          <StyledButtonsContainer>
            {firstButtonText != null && (
              <CustomButton
                style={{ justifyContent: 'center' }}
                height={48}
                width="160px"
                isInvertColor
                onClick={() => {
                  if (handleClickFirstButton != null) {
                    handleClickFirstButton();
                  }
                }}
                freeContent={freeContent}
                icon={firstButtonIcon}
              >
                {firstButtonText}
              </CustomButton>
            )}
            {secondButtonText != null && (
              <CustomButton
                style={{ justifyContent: 'center' }}
                height={48}
                width="160px"
                onClick={() => {
                  if (handleClickSecondButton != null && !loading) {
                    handleClickSecondButton();
                  }
                }}
                freeContent={freeContent}
                form={formId}
                icon={secondButtonIcon}
                loading={loading}
                disabled={disableSecondaryButton}
              >
                {secondButtonText}
              </CustomButton>
            )}
          </StyledButtonsContainer>
        )}
      </StyledContainer>
    </Modal>
  );
};

export default CustomModalTwoButtons;
