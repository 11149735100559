export enum RoutePath {
  HOME = '/',
  ERROR = '/problem',
  ADMIN_HOME = '/admin/dashboard',
  LOGIN = '/login',
  INACTIVE_ACCOUNT = '/conta-inativa',
  ORG_WITHOUT_LIMIT = '/organizacao-sem-limite',
  SIMULATOR_PROCESSING = '/processando-simulador',
  SIMULATOR_FINISHING = '/finalizando-simulador',
  CONFIRM_SIMULATION = '/confirmar-simulacao',
  SIMULATOR_PROCESSING_ADMIN = '/admin/processando-simulador',
  SIMULATOR_FINISHING_ADMIN = '/admin/finalizando-simulador',
  CONFIRM_SIMULATION_ADMIN = '/admin/confirmar-simulacao',
  REGISTER = '/cadastro?registrationKey=',
  REGISTER_NO_KEY = '/cadastro',
  PROFILE = '/perfil',
  SIMULATOR_REPROVED = '/simulador-reprovado',
  SIMULATOR_REPROVED_ADMIN = '/admin/simulador-reprovado',
  SIMULATOR_APPROVED = '/simulador-aprovado',
  LOGOUT = '/logout',
  CREDITS = '/creditos',
  MY_FINANCING_ADMIN = '/admin/creditos',
  PROCEDURE_ADMIN = '/admin/procedimentos',
  ADMIN_REGISTER_NEW_CUSTOMER = '/admin/novo-cliente',
  NEW_BALANCE = '/admin/novo-saldo',
  MY_CONTRACTS = '/meus-contratos',
  MY_PAYMENTS = '/meus-pagamentos',
  FOUNDED_DATA = '/infos-encontradas',
  BANK_DATA = '/',
  PROPERTY = '/',
  VEHICLE = '/',
  GUARANTORS = '/',
  PAYMENTS = '/meus-pagamentos',
  CONTRACTS = '/meus-contratos',
  PROCEDURES = '/procedimentos',
  ANTICIPATIONS = '/antecipacoes',
  MY_PROCEDURES = '/procedimentos',
  SIGNATURE = '/assinaturas',
  SIGNATURE_ANALYSIS = '/assinatura/analise',
  DOCUMENT_ANALYSIS = '/assinatura/analise/documento',
  SIGNATURE_SELFIE_ANALYSIS = '/assinatura/analise/selfie',
  PROOF_LIFE_ANALYSIS = '/assinatura/analise/prova-de-vida',
  SIGNATURE_SUCCESS = '/assinatura/sucesso',
  SIGNATURE_RELEASE = '/assinatura/liberacao',
  SUBSCRIPTION_EXPIRED = '/assinatura/expirada',
  DOCUMENT_SUBMISSION_INSTRUCTIONS = '/instrucoes-envio-documento-identificacao',
  SIGNATURE_CANCELED = '/assinatura/cancelada',
  QR_CODE = '/qr-code',
  PROOF_LIFE_INSTRUCTIONS = '/prova-de-vida-instrucoes',
  PROOF_LIFE = '/prova-de-vida',
  ERROR_ANALYSIS = '/erro',
  STEP_COMPLETED = '/etapa-concluida',
  SELFIE_INSTRUCTIONS = '/selfie-instrucoes',
  CONFIRM_DATA = '/confirmar-dados',
  LETS_SIGN = '/lets-sign',
  REGISTER_DOCUMENT = '/cadastrar-documento',
  TAKE_PHOTO_DOCUMENT = '/tirar-foto-documento',
  HISTORY_OF_TRANSACTIONS = '/admin/extratos/transacoes',
  HISTORY_OF_BALANCE_REQUEST = '/admin/extratos/solicitacoes-de-saldo',
}
