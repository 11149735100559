import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Snackbar } from '@mui/material';
import CustomAutocomplete from 'components/custom-autocomplete';
import CustomButton from 'components/custom-button';
import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import { ReactSelectOption, ReactSelectSearch } from 'components/react-select-search';
import InputText from 'components/styled-input';
import { ReactComponent as PlusIconSvg } from 'images/white-plus.svg';
import _ from 'lodash';
import { Authority } from 'model/enums/authority';
import { InternalStatus } from 'model/enums/internal-status';
import { Internal } from 'model/internals';
import { PageableResponse } from 'model/pageable';
import { Subsidiary } from 'model/subsidiaries';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiEdit2, FiTrash, FiTrash2 } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { InternalsService } from 'services/internals-service';
import landingPageService from 'services/landing-page-service';
import subsidiariesService from 'services/subsidiaries-service';
import { hasAuthorities } from 'shared/util/utils';
import { useTheme } from 'styled-components';
import * as yup from 'yup';
import { Status } from '../../components/status';
import { TableValues } from '../../components/table-list';
import { NewTableList } from '../../components/table-list-new';
import { TitleHeader } from '../../components/title-header';
import { ButtonsContainer, EditContainer, FormContainer, InputContent, PaintSvg, StyledAutocompleteContainer } from './styles';

interface Schema {
  name: string;
  email: string;
  authority: string;
  status: string;
  subsidiaries: string;
}

export const MyTeamList = () => {
  const [myTeam, setMyTeam] = useState<Internal[]>([]);
  const [searchName, setSearchName] = useState('');
  const [searchSubsidiaryName, setSearchSubsidiaryName] = useState('');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [internalSelected, setInternalSelected] = useState<Internal | null>(null);
  const [organizationId, setOrganizationId] = useState<number | null>(null);
  const [subsidiaries, setSubsidiaries] = useState<Subsidiary[]>([]);
  const [selectedSubsidiary, setSelectedSubsidiary] = useState<Subsidiary | null>(null);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const account = useSelector((state: IRootState) => state.authentication.account);
  const { t } = useTranslation();
  const { color } = useTheme();
  const [orderBy, setOrderBy] = useState<boolean>(false);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  const [myTeamResponse, setMyTeamResponse] = useState<PageableResponse<Internal>>();

  useEffect(() => {
    getMyTeamList();
    getOrganizationId();
  }, []);

  useEffect(() => {
    getMyTeamList({ name: searchName, subsidiaryName: searchSubsidiaryName });
  }, [searchName, searchSubsidiaryName]);

  useEffect(() => {
    getMyTeamList({ name: searchName, page, subsidiaryName: searchSubsidiaryName });
  }, [page]);

  useEffect(() => {
    getSubsidiaries();
  }, [organizationId]);

  const schema = yup.object().shape({
    name: yup.string().required(t('global.errorMessage.required')),
    email: yup.string().email(t('global.errorMessage.email')).required(t('global.errorMessage.required')),
    authority: yup.string().required(t('global.errorMessage.required')),
    status: yup.string().required(t('global.errorMessage.required')),
  });

  const methods = useForm<any>({ resolver: yupResolver(schema) });

  const getMyTeamList = async (props?: {
    name?: string;
    size?: number;
    page?: number;
    registrationStatus?: string[];
    subsidiaryName?: string;
  }) => {
    setIsLoadingTable(true);
    const res = await InternalsService().getInternalsList(props?.name, props?.size, props?.page, props?.subsidiaryName);
    setIsLoadingTable(false);
    setMyTeam(res.content);
    setMyTeamResponse(res);
    setPage(res.pageable.pageNumber);
    setTotalPages(res.totalPages);
  };

  const getOrganizationId = async () => {
    const res = await landingPageService.getData();
    if (res.organization.id) {
      setOrganizationId(res.organization.id);
    }
  };

  const getSubsidiaries = async () => {
    if (organizationId != null) {
      const res = await subsidiariesService.getSubsidiariesByOrgId(organizationId);
      setSubsidiaries(res);
    }
  };

  const getRoleName = authorities => {
    if (authorities.some(auth => [Authority.ROLE_ADMIN, Authority.ROLE_INTERNAL_ADMIN].includes(auth.name))) {
      return Authority.ROLE_ADMIN;
    }
    if (authorities.map(auth => auth.name).includes(Authority.ROLE_MANAGER)) {
      return Authority.ROLE_MANAGER;
    }
    if (authorities.map(auth => auth.name).includes(Authority.ROLE_SELLER)) {
      return Authority.ROLE_SELLER;
    }
    if (authorities.map(auth => auth.name).includes(Authority.ROLE_ANALYST)) {
      return Authority.ROLE_ANALYST;
    }
    if (authorities.map(auth => auth.name).includes(Authority.ROLE_FINANCIAL)) {
      return Authority.ROLE_FINANCIAL;
    }
    if (authorities.map(auth => auth.name).includes(Authority.ROLE_ATTENDANT)) {
      return Authority.ROLE_ATTENDANT;
    }
    if (authorities.map(auth => auth.name).includes(Authority.ROLE_CLIENT)) {
      return Authority.ROLE_CLIENT;
    }
    return Authority.ROLE_USER;
  };

  const setTableValues = () => {
    const hasSubsidiaries = myTeam.filter(team => team?.organizationSubsidiary != null).length > 0;

    const tableValues: TableValues[] = [
      { header: t('admin.dashboard.tableHeaders.name'), values: myTeam.map(mt => mt.name ?? '') },
      {
        header: hasSubsidiaries ? t('admin.dashboard.branch') : t('admin.dashboard.tableHeaders.email'),
        values: hasSubsidiaries ? myTeam.map(mt => mt.organizationSubsidiary?.name ?? '') : myTeam.map(mt => mt.email ?? ''),
      },
      {
        header: t('admin.dashboard.tableHeaders.authority.name'),
        values: myTeam.map(mt =>
          mt.user?.authorities?.[0] ? t(`admin.dashboard.tableHeaders.authority.${getRoleName(mt.user?.authorities)}`) : ''
        ),
      },
      {
        header: t('admin.dashboard.tableHeaders.status'),
        values: myTeam.map(mt => <Status text={t(`enum.internalStatus.${mt.internalStatus}`)} color={mt.internalStatus ?? ''} />),
      },
      {
        header: '',
        values: myTeam.map(mt => (
          <EditContainer key={mt.id}>
            {hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN, Authority.ROLE_INTERNAL_ADMIN]) && (
              <ButtonsContainer>
                <button
                  className="edit"
                  onClick={() => {
                    setInternalSelected(mt);
                    setOpenEditModal(true);
                  }}
                >
                  <FiEdit2 color={color.gray24} size={18} />
                </button>
                <button className="trash">
                  <FiTrash
                    color={color.gray24}
                    size={18}
                    onClick={() => {
                      setInternalSelected(mt);
                      setOpenDeleteModal(true);
                    }}
                  />
                </button>
              </ButtonsContainer>
            )}
          </EditContainer>
        )),
        fullLength: true,
      },
    ];

    return tableValues;
  };

  const updateSearchName = (name: string) => {
    setSearchName(name);
  };

  const updateSubsidiaryName = _.debounce((name: string) => {
    setSearchSubsidiaryName(name);
  }, 500);

  const setNewPage = _.debounce((newPage: number) => {
    if (newPage >= totalPages) {
      return setPage(page);
    }
    setPage(newPage);
  }, 500);

  const closeModalAndResetInternal = () => {
    setOpenDeleteModal(false);
    setOpenEditModal(false);
    setOpenCreateModal(false);
    setInternalSelected(null);
    setSelectedSubsidiary(null);
    cleanValues();
    getMyTeamList({ name: searchName, subsidiaryName: searchSubsidiaryName });
    setIsButtonLoading(false);
  };

  const inactivateInternal = async () => {
    setIsButtonLoading(true);

    if (internalSelected?.id) {
      await InternalsService().inactiveInternal(internalSelected.id);
      closeModalAndResetInternal();
    }
  };

  const updateInternal = async (values: Schema) => {
    setIsButtonLoading(true);

    if (internalSelected?.id) {
      const sub = subsidiaries.length > 0 && selectedSubsidiary?.id;

      let internal: Internal = {
        id: internalSelected?.id,
        name: values.name,
        email: values.email,
        authority: values.authority,
        internalStatus: values.status as InternalStatus,
      };

      if (sub) {
        internal = { ...internal, organizationSubsidiaryId: sub };
      } else {
        internal = { ...internal, organizationSubsidiaryId: undefined };
      }

      await InternalsService()
        .updateInternal(internalSelected.id, internal)
        .catch(error => {
          setErrorMessage(t(`global.errorMessage.${error.data.code}`) ?? t('global.registerError') ?? '');
          setOpenAlert(true);
          setTimeout(() => {
            setOpenAlert(false);
          }, 2000);
        });
      closeModalAndResetInternal();
    }
  };

  const createNewInternal = async (values: Schema) => {
    setIsButtonLoading(true);

    const sub = subsidiaries.length > 0 && selectedSubsidiary?.id;

    let internal: Internal = {
      name: values.name,
      email: values.email,
      authority: values.authority,
      internalStatus: values.status as InternalStatus,
    };

    if (sub) {
      internal = { ...internal, organizationSubsidiaryId: sub };
    }
    await InternalsService()
      .createInternal(internal)
      .catch(error => {
        setErrorMessage(t(`global.errorMessage.${error.data.code}`) ?? t('global.registerError') ?? '');
        setOpenAlert(true);
        setTimeout(() => {
          setOpenAlert(false);
        }, 2000);
      });
    closeModalAndResetInternal();
  };

  const cleanValues = () => {
    methods.setValue('name', '');
    methods.setValue('email', '');
    methods.setValue('status', '');
    methods.setValue('authority', '');
    methods.setValue('subsidiaries', '');
  };

  return (
    <>
      <Snackbar open={openAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <TitleHeader
        title={t('admin.dashboard.myTeam')}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        customButton={
          hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN, Authority.ROLE_INTERNAL_ADMIN]) ? (
            <CustomButton onClick={() => setOpenCreateModal(true)} width="205px" isInvertColor>
              <PaintSvg className="plusSvg">
                <PlusIconSvg />
              </PaintSvg>
              {t('admin.dashboard.myTeamButtonText')}
            </CustomButton>
          ) : undefined
        }
        setSearch={updateSearchName}
        setSubsidiarySearch={updateSubsidiaryName}
      />

      <NewTableList
        setLoadingTable={setIsLoadingTable}
        loadingTable={isLoadingTable}
        tableValues={setTableValues()}
        page={page}
        setNewPage={setNewPage}
        noPagination={totalPages === 1}
        signaturesResponse={myTeamResponse}
      />

      <CustomModalTwoButtons
        isShowModal={openDeleteModal}
        title={t('admin.myTeam.modal.inactivate.title')}
        firstButtonText={t('global.button.cancel')}
        secondButtonText={t('admin.myTeam.modal.inactivate.buttonText')}
        onCloseModal={closeModalAndResetInternal}
        handleClickFirstButton={closeModalAndResetInternal}
        handleClickSecondButton={inactivateInternal}
        message={t('admin.myTeam.modal.inactivate.message')}
        icon={<FiTrash2 size={60} color={color.rejected} />}
        freeContent
        loading={isButtonLoading}
      />

      <CustomModalTwoButtons
        isShowModal={openEditModal}
        title={t('admin.myTeam.modal.edit.title')}
        firstButtonText={t('global.button.cancel')}
        secondButtonText={t('admin.myTeam.modal.edit.buttonText')}
        onCloseModal={closeModalAndResetInternal}
        handleClickFirstButton={closeModalAndResetInternal}
        handleClickSecondButton={methods.handleSubmit(updateInternal)}
        freeContent
        loading={isButtonLoading}
      >
        <FormProvider {...methods}>
          <FormContainer>
            <InputContent>
              <InputText
                name="name"
                label={t('admin.myTeam.inputs.name')}
                placeholder={t('admin.myTeam.inputs.name')}
                defaultValue={internalSelected?.name}
              />
              <InputText
                name="email"
                label={t('admin.myTeam.inputs.email')}
                placeholder={t('admin.myTeam.inputs.email')}
                defaultValue={internalSelected?.email}
              />
            </InputContent>
            <InputContent>
              <ReactSelectSearch
                name="authority"
                placeholder={t('admin.myTeam.inputs.authority')}
                options={[
                  { label: t(`admin.myTeam.enum.authority.${Authority.ROLE_INTERNAL}`), value: Authority.ROLE_INTERNAL },
                  { label: t(`admin.myTeam.enum.authority.${Authority.ROLE_INTERNAL_ADMIN}`), value: Authority.ROLE_INTERNAL_ADMIN },
                  { label: t(`admin.myTeam.enum.authority.${Authority.ROLE_MANAGER}`), value: Authority.ROLE_MANAGER },
                  { label: t(`admin.myTeam.enum.authority.${Authority.ROLE_SELLER}`), value: Authority.ROLE_SELLER },
                  { label: t(`admin.myTeam.enum.authority.${Authority.ROLE_ANALYST}`), value: Authority.ROLE_ANALYST },
                  { label: t(`admin.myTeam.enum.authority.${Authority.ROLE_FINANCIAL}`), value: Authority.ROLE_FINANCIAL },
                  { label: t(`admin.myTeam.enum.authority.${Authority.ROLE_ATTENDANT}`), value: Authority.ROLE_ATTENDANT },
                ]}
                defaultValue={
                  internalSelected?.user?.authorities
                    ? internalSelected?.user?.authorities?.length > 1
                      ? ({
                          label: t(
                            `admin.myTeam.enum.authority.${internalSelected?.user.authorities?.filter(item => item.name !== Authority.ROLE_INTERNAL)?.[0].name}`
                          ),
                          value: internalSelected?.user.authorities?.filter(item => item.name !== Authority.ROLE_INTERNAL)?.[0].name,
                        } as ReactSelectOption)
                      : ({
                          label: t(`admin.myTeam.enum.authority.${internalSelected?.user.authorities?.[0]?.name}`),
                          value: internalSelected?.user.authorities?.[0]?.name,
                        } as ReactSelectOption)
                    : undefined
                }
              />
              <ReactSelectSearch
                name="status"
                placeholder={t('admin.myTeam.inputs.status')}
                options={[
                  { label: t(`admin.myTeam.enum.status.${InternalStatus.ACTIVE}`), value: InternalStatus.ACTIVE },
                  { label: t(`admin.myTeam.enum.status.${InternalStatus.INACTIVE}`), value: InternalStatus.INACTIVE },
                ]}
                defaultValue={
                  internalSelected?.internalStatus
                    ? ({
                        label: t(`admin.myTeam.enum.status.${internalSelected?.internalStatus}`),
                        value: internalSelected?.internalStatus,
                      } as ReactSelectOption)
                    : undefined
                }
              />
            </InputContent>
            {subsidiaries.length > 0 && (
              <InputContent>
                <StyledAutocompleteContainer>
                  <CustomAutocomplete
                    data={subsidiaries}
                    keysValuesToDisplayed={['name']}
                    label={t('admin.myTeam.inputs.subsidiaries')}
                    optionSelected={option => {
                      setSelectedSubsidiary(option);
                    }}
                    defaultValue={internalSelected?.organizationSubsidiary}
                  />
                </StyledAutocompleteContainer>
              </InputContent>
            )}
          </FormContainer>
        </FormProvider>
      </CustomModalTwoButtons>

      <CustomModalTwoButtons
        isShowModal={openCreateModal}
        title={t('admin.myTeam.modal.create.title')}
        firstButtonText={t('global.button.cancel')}
        secondButtonText={t('admin.myTeam.modal.create.buttonText')}
        onCloseModal={closeModalAndResetInternal}
        handleClickFirstButton={closeModalAndResetInternal}
        handleClickSecondButton={methods.handleSubmit(createNewInternal)}
        freeContent
        loading={isButtonLoading}
      >
        <FormProvider {...methods}>
          <FormContainer>
            <InputContent>
              <InputText name="name" placeholder={t('admin.myTeam.inputs.name')} />
              <InputText name="email" placeholder={t('admin.myTeam.inputs.email')} />
            </InputContent>
            <InputContent>
              <ReactSelectSearch
                name="authority"
                placeholder={t('admin.myTeam.inputs.authority')}
                options={[
                  { label: t(`admin.myTeam.enum.authority.${Authority.ROLE_INTERNAL}`), value: Authority.ROLE_INTERNAL },
                  { label: t(`admin.myTeam.enum.authority.${Authority.ROLE_INTERNAL_ADMIN}`), value: Authority.ROLE_INTERNAL_ADMIN },
                  { label: t(`admin.myTeam.enum.authority.${Authority.ROLE_MANAGER}`), value: Authority.ROLE_MANAGER },
                  { label: t(`admin.myTeam.enum.authority.${Authority.ROLE_SELLER}`), value: Authority.ROLE_SELLER },
                  { label: t(`admin.myTeam.enum.authority.${Authority.ROLE_ANALYST}`), value: Authority.ROLE_ANALYST },
                  { label: t(`admin.myTeam.enum.authority.${Authority.ROLE_FINANCIAL}`), value: Authority.ROLE_FINANCIAL },
                  { label: t(`admin.myTeam.enum.authority.${Authority.ROLE_ATTENDANT}`), value: Authority.ROLE_ATTENDANT },
                ]}
              />
              <ReactSelectSearch
                name="status"
                placeholder={t('admin.myTeam.inputs.status')}
                options={[
                  { label: t(`admin.myTeam.enum.status.${InternalStatus.ACTIVE}`), value: InternalStatus.ACTIVE },
                  { label: t(`admin.myTeam.enum.status.${InternalStatus.INACTIVE}`), value: InternalStatus.INACTIVE },
                ]}
              />
            </InputContent>
            {subsidiaries.length > 0 && (
              <InputContent>
                <StyledAutocompleteContainer>
                  <CustomAutocomplete
                    data={subsidiaries}
                    keysValuesToDisplayed={['name']}
                    label={t('admin.myTeam.inputs.subsidiaries')}
                    optionSelected={option => {
                      setSelectedSubsidiary(option);
                    }}
                    defaultValue={subsidiaries.length == 1 ? subsidiaries[0] : undefined}
                  />
                </StyledAutocompleteContainer>
              </InputContent>
            )}
          </FormContainer>
        </FormProvider>
      </CustomModalTwoButtons>
    </>
  );
};
