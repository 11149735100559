import styled from 'styled-components';

interface ButtonStyledType {
  $isActive?: boolean;
}

interface CustomTag {
  $customStyle: ButtonStyledType;
}

export const SimulatorContainer = styled.div`
  width: 366px;
  min-height: 350px;
  display: flex;
  flex-direction: column;

  border-radius: 30px;
  box-shadow: 0px 4px 8px rgba(27, 25, 25, 0.08);
  color: ${({ theme }) => theme.color.white};

  padding: 28px 40px;

  z-index: 10;
  background: #fff;
  align-self: flex-start;

  @media (max-width: 1055px) {
    height: auto;
  }

  @media (max-width: 671px) {
    width: 296px;
    padding: 32px 16px;

    height: auto;
  }
`;

export const SliderBoxTag = styled.div`
  width: 100%;
  height: 40px;
  margin: 24px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 4px;
`;

export const SliderContainer = styled.div`
  height: 160px;
  display: flex;
  flex-direction: column;
  margin: 40px 0px 0px;

  @media (max-width: 671px) {
    height: auto;
  }
`;

export const SliderTag = styled.div<CustomTag>`
  width: 74px;
  height: 40px;
  background: ${({ theme, $customStyle }) => (!$customStyle.$isActive ? theme.color.white : theme.color.primaryColor)};

  border: ${({ theme }) => `1px solid ${theme.color.primaryColor}`};
  border-radius: 10px;

  font-weight: 700;
  font-size: 14px;
  line-height: 21px;

  color: ${({ theme, $customStyle }) => (!$customStyle.$isActive ? theme.color.primaryColor : theme.color.white)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const SliderValue = styled.input`
  height: 38px;

  color: ${({ theme }) => theme.color.primaryColor};
  text-align: center;
  font-weight: 500;
  font-size: 48px;
  border: none;
  outline: none;

  @media (max-width: 575.98px) {
    font-size: 25px;
  }
`;

export const StyledMainTitle = styled.span`
  font-weight: 700;
  font-size: 24px;
  color: #000000;

  @media (max-width: 575.98px) {
    font-size: 20px;
  }
`;

export const StyledText = styled.span`
  margin: 16px 0 20px 0;
  display: block;
`;

export const DesiredValueContainer = styled.div`
  align-items: center;
  width: 100%;
  min-height: 52px;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  @media (max-width: 671px) {
    width: 240px;
    height: auto;
  }
`;

interface Props {
  $customStyle?: any;
  $isActive?: boolean;
}

export const DesiredValueButtons = styled.button<Props>`
  width: 24px;
  height: 24px;
  border-radius: 38px;
  border: 1px solid var(--gray-light-4, #eee);
  background: var(--gray-bw-white, #fff);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  padding: 0px;
  ${({ $customStyle }) => $customStyle?.backgroundImage && `background-image: url(${({ $customStyle }) => $customStyle?.backgroundImage});`}
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    path {
      fill: ${({ $isActive, theme }) => ($isActive ? theme.color.primaryColor : theme.color.gray18)};
    }
  }
`;

export const DesiredTime = styled.input`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.primaryColor};
  text-align: center;
  border: 0px;
  @media (max-width: 671px) {
    width: 192px;
    height: auto;
  }
`;

export const StyledPreSimulatorButton = styled.button`
  border-radius: 24px;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
  border: none;
  width: 100%;
  margin-top: 16px;
  color: #fff;
  ${({ theme }) => {
    return `
          background: ${theme.color.lpColors.simulatorButton}; `;
  }}
`;

export const SimulatorTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.black1b};
  text-align: center;
  margin-bottom: 24px;
`;

export const StyledSubtitle = styled.div`
  color: var(--gray-dark-2, #333);
  text-align: center;
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;
