import Popup from 'reactjs-popup';
import styled from 'styled-components';

interface Props {
  $customStyle?: any;
}

export const SimulatorContainer = styled.div`
  width: 100%;
  height: calc(100vh - 68px);
  background: ${({ theme }) => theme.color.grayBackground}; //dynamic

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledTitle = styled.div`
  width: 480px;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;

  color: #000000;

  margin: 48px 0px 0px;
`;

export const StyledText = styled.div`
  width: 480px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #7f7f7f;

  margin: 16px 0px 0px;
`;

export const SimulatorBox = styled.div``;

export const StyledButton = styled.button`
  width: 480px;
  height: 48px;

  background: ${({ theme }) => theme.color.primaryColor}; //dynamic

  border: ${({ theme }) => `1px solid ${theme.color.primaryColor}`}; //dynamic
  border-radius: 24px;

  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;

  margin: 40px 0px 0px;
  padding: 0px;
`;

export const StyledPopup = styled(Popup)`
  &-overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  }
  &-content {
    width: 620px;
    height: 368px;
    background: #ffffff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const PopupHeader = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: 24px 24px 0px 0px;
`;
export const CloseButton = styled.div<Props>`
  width: 20px;
  height: 20px;
  background-image: url(${({ $customStyle }) => $customStyle.backgroundImage});
  background-repeat: no-repeat;
  background-position: center;
`;

export const PopupContent = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 671px) {
    min-width: 224px;
    margin: 0px auto;
    text-align: center;
  }
`;

export const StyledImage = styled.div<Props>`
  width: 60px;
  height: 60px;
  background-image: url(${({ $customStyle }) => $customStyle.backgroundImage});
  background-repeat: no-repeat;
  background-position: center;
`;
export const PopupTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;

  color: #333333;

  margin: 24px 0px 0px;
`;
export const PopupText = styled(StyledText)`
  width: fit-content;
`;

export const PopupButton = styled(StyledButton)`
  width: 240px;
`;
