import styled from 'styled-components';

interface Props {
  customStyle?: any;
  isDisableClick?: boolean;
}

export const SimulatorContainer = styled.div`
  width: 100%;

  background: #ffffff;

  border: 1px solid #fafafa;
  border-radius: 10px;

  margin-top: 30px;
  padding: 24px 40px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
`;

export const StyledIcon = styled.div<Props>`
  width: 12px;
  height: 12px;

  background-image: url(${({ customStyle }) => customStyle.backgroundImage});
  background-repeat: no-repeat;
  background-position: center;
`;

export const StyledRateBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0px 0px 12px;
`;

export const StyledRate = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  color: ${({ theme }) => theme.color.primaryColor};

  margin: 0px 0px 0px 8px;
`;

export const SectionTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #7f7f7f;
`;

export const DesiredValueContainer = styled.div<Props>`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;

  margin: 8px 0px 12px;

  border-bottom: 1px solid #e5e7e7;

  pointer-events: ${({ isDisableClick }) => (isDisableClick ? 'none' : 'all')};
`;

export const DesiredValue = styled.input`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: ${({ theme }) => theme.color.primaryColor};

  text-align: center;
  border: 0px;
`;

export const DesiredValueButtons = styled.div<Props>`
  width: 20px;
  height: 20px;
  background-image: url(${({ customStyle }) => customStyle.backgroundImage});
  background-repeat: no-repeat;
  background-position: center;

  cursor: pointer;
`;

export const Installments = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 12px 0px 0px;
`;

export const SliderValue = styled.div`
  height: 40px;
  font-weight: 500;
  font-size: 40px;
  color: #000000;
`;

export const PaymentInformationContainer = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  row-gap: 16px;
  margin: 36px 0px 0px;

  @media (max-width: 575.98px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 70px;
  }
`;

export const ResultInformationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  row-gap: 16px;
  margin: 12px 0px 0px;
`;

export const PaymentInformationBox = styled.div`
  width: 188px;
  min-height: 64px;
  background: #fafafa;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PaymentInformationHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0px 0px;
`;

export const PaymentInformationTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #7f7f7f;

  margin: 0px 0px 0px 4px;
`;

export const PaymentInformationText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  color: #000000;
`;

export const StyledTitle = styled.div`
  color: #000000;
  font-weight: 700;
  font-size: 24px;
`;

export const StyledContainerButtons = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 12px;
  margin-top: 12px;
`;

export const StyledModalContainer = styled.div`
  width: 306px;
  min-height: 227px;
  margin: 0 auto;

  @media (max-width: 671px) {
    width: unset;
  }
`;

export const StyledButtonContainer = styled.div`
  margin-top: 24px;

  @media (max-width: 575.98px) {
    text-align: -webkit-center;
  }
`;
