import { SliderThumb } from '@mui/material/Slider';
import { GeneralImages } from 'images/general-images';
import { Arrows } from './styled';
import { ReactNode } from 'react';

interface SliderIconProps {
  children: JSX.Element | ReactNode | any;
}

const SliderIcon: React.FC<SliderIconProps> = ({ children, ...other }) => {
  return (
    <SliderThumb {...other}>
      {children}
      <Arrows src={GeneralImages.sliderIcon} />
    </SliderThumb>
  );
};

export default SliderIcon;
