import EnterCustomButton from 'components/enter-custom-button';
import { SimulatorState } from 'components/simulator-data/processing/hooks/use-simulator-state';
import { ORGANIZATION_NAME } from 'config/constants';
import PreRegisterContext from 'context/pre-register';
import { useSystemParameters } from 'hooks/use-system-parameters';
import AlertIcon from 'images/alert-triangle.svg';
import { LocalStorageKeys } from 'model/enums/local-storage-keys';
import { OrganizationName } from 'model/enums/organization-name';
import { RegistrationStatus } from 'model/enums/registration-status';
import { RoutePath } from 'model/enums/route-path';
import { FinancingSimpleSimulation } from 'model/financing';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import authenticationService from 'services/authentication-service';
import ClientService from 'services/client-service';
import StringUtils from 'shared/util/string-utils';
import { Container, MessageError, TextInput, Title } from './styles';
interface ILoginModalProps {
  documentValue: string;
  setDocumentValue: (value: string) => void;
  isMobile?: boolean;
}

const LoginModal: React.FC<ILoginModalProps> = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [messageError, setMessageError] = useState<boolean>(false);
  const [notRegistered, setNotRegistered] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setRegistrationKey } = useContext(PreRegisterContext);
  const { isPhysicalPerson } = useSystemParameters();

  const getIsPJ = () => {
    return ORGANIZATION_NAME === OrganizationName.GIROPJ;
  };

  const handleInputChange = e => {
    const value = e.target.value;
    if (value.length === 0) {
      setMessageError(true);
    } else {
      setMessageError(false);
    }
    if (isPhysicalPerson) {
      props.setDocumentValue(StringUtils.cpfMask(value));
    } else {
      props.setDocumentValue(StringUtils.cnpjMask(value));
    }
  };

  const handleOnClickLogin = () => {
    setIsLoading(true);

    ClientService.getBasicInfo(StringUtils.removeNonNumbersFromMaskedValue(props.documentValue)).then(basicInfo => {
      if (basicInfo.isActive === false) {
        return history.push(RoutePath.INACTIVE_ACCOUNT);
      }

      const isEmpty = Object.keys(basicInfo).length === 0;

      switch (basicInfo.registrationStatus) {
        case RegistrationStatus.NEW:
          authenticationService
            .checkIfClientAlreadyRegistered(StringUtils.removeNonNumbersFromMaskedValue(props.documentValue))
            .then(res => {
              if (res.isRegistered) {
                return history.push({
                  pathname: '/login/authenticate',
                  state: { documentValue: props.documentValue },
                });
              } else {
                localStorage.setItem(LocalStorageKeys.REGISTRATION_KEY, JSON.stringify(basicInfo.registrationKey));
                setRegistrationKey(basicInfo.registrationKey);

                if (ORGANIZATION_NAME !== OrganizationName.MULTTIPLO) {
                  const lastSimulation = basicInfo?.finances?.[0];

                  const sendSimulator: FinancingSimpleSimulation = {
                    choosedValue: lastSimulation?.choosedValue,
                    client: { id: basicInfo.id },
                    id: lastSimulation?.id,
                    installmentsTotal: lastSimulation?.installmentsTotal,
                  };
                  return history.push(RoutePath.FOUNDED_DATA, {
                    sendSimulator: sendSimulator,
                  } as SimulatorState);
                } else {
                  return history.push(`${RoutePath.REGISTER}${basicInfo.registrationKey}`);
                }
              }
            });
          return null;
        case RegistrationStatus.PRE_REJECTED:
          return history.push(RoutePath.SIMULATOR_REPROVED);
        case RegistrationStatus.REJECTED:
          return history.push(RoutePath.SIMULATOR_REPROVED);

        default:
          if (isEmpty) {
            setNotRegistered(true);
            setIsLoading(false);
            return;
          } else {
            authenticationService
              .checkIfClientAlreadyRegistered(StringUtils.removeNonNumbersFromMaskedValue(props.documentValue))
              .then(res => {
                if (res.isRegistered) {
                  return history.push({
                    pathname: '/login/authenticate',
                    state: { documentValue: props.documentValue },
                  });
                }
              });
          }
          return;
      }
    });
  };

  return (
    <Container>
      <Title>{getIsPJ() ? t('login.form.titlePJ') : t('login.form.title')}</Title>
      <TextInput
        autoFocus
        placeholder={getIsPJ() ? t('login.placeholder.cnpj') : t('login.placeholder.cpf')}
        maxLength={getIsPJ() ? 18 : 14}
        value={props.documentValue}
        onChange={e => handleInputChange(e)}
        $error={messageError}
      />
      {messageError && (
        <MessageError>
          <img src={AlertIcon} style={{ marginRight: '5px' }} />
          {isPhysicalPerson ? t('login.error.cpfLength') : t('login.error.cnpjLength')}
        </MessageError>
      )}
      {notRegistered && <MessageError>{t('login.error.notRegistered')}</MessageError>}
      {notRegistered ? (
        <EnterCustomButton
          width={props.isMobile ? 250 : 400}
          isLoading={isLoading}
          disabled={props.documentValue.length < 14}
          onClick={() => history.push('/')}
          text={t('global.button.goToSimulator')}
        />
      ) : (
        <EnterCustomButton
          width={props.isMobile ? 250 : 400}
          isLoading={isLoading}
          disabled={props.documentValue.length < 14}
          onClick={handleOnClickLogin}
          text={t('global.button.enter')}
        />
      )}
    </Container>
  );
};

export default LoginModal;
