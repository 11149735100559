import styled from 'styled-components';

export const ReportContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 350px;
`;
export const ReportTitle = styled.div`
  display: flex;
  width: 180px;
  padding-right: 10px;

  font-style: normal;
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.gray21};
`;
