import { ScreenDimensions } from 'model/enums/screen-dimensions-types';
import styled from 'styled-components';

export const ScreenContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: ${ScreenDimensions.TABLET}) {
    flex-direction: column;
    padding: 0px 16px 48px;
  }
`;

export const ImageBox = styled.div`
  width: 54px;
  height: 54px;
  display: flex;
`;

export const AlignContent = styled.div`
  width: 962px;
  margin: 48px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ScreenTitle = styled.div`
  font-size: 32px;
  font-weight: 700;
`;

export const StyledTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 32px;
  color: #000000;

  @media (max-width: 575.98px) {
    font-size: 20px;
  }
`;

export const TableContent = styled.div`
  width: 100%;
`;

export const AccessButton = styled.button`
  width: 100%;
  height: 24px;
  color: ${({ theme }) => theme.color.primaryColor};
  background: transparent;
  border: none;

  font-size: 16px;
  font-weight: 700;

  padding: 0px;
  cursor: pointer;

  display: flex;
  align-items: center;

  svg {
    margin: 0px 0px 0px 12px;
  }

  svg path {
    fill: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const AccessImage = styled.img`
  margin: 0px 0px 0px 12px;
`;

export const EmptyTitleBox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 24px 0px 0px;
`;

export const StyledCardsContainerMobile = styled.div`
  max-height: 570px;
  margin-top: 15px;
  overflow-y: auto;
`;

export const StyledIcon = styled.div`
  min-height: 24px;
  min-width: 24px;
`;

export const InputContainer = styled.div`
  width: 100%;
  padding: 12px 25px;
  border: 1.5px solid ${({ theme }) => theme.color.primaryColor};
  border-radius: 24px;
  background-color: transparent;
  max-height: 48px;

  display: flex;
  align-items: center;

  > input {
    width: 100%;
    border: none;
    background-color: transparent;

    color: ${({ theme }) => theme.color.gray21};
    font-size: 16px;
    font-weight: 700;
    font-style: normal;

    @media (max-width: ${ScreenDimensions.TABLET}) {
    }
    :focus {
      outline: none;
    }

    ::placeholder {
      color: ${({ theme }) => theme.color.primaryColor};
    }
  }
`;
