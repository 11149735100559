import { Logo } from 'images/custom-organization';
import { useHistory } from 'react-router-dom';
import { Links } from '..';

import { HeaderContent, Link, LinksBox } from '../../styles';

interface DesktopProps {
  links: Links[];
  renderButton: () => JSX.Element;
}

const Desktop: React.FC<DesktopProps> = ({ links, renderButton }) => {
  const history = useHistory();
  return (
    <HeaderContent>
      <div onClick={() => history.push('/')}>
        <Logo isWhite={false} />
      </div>
      <LinksBox>
        {links.map((link, idx) => (
          <Link key={idx} href={link.path} target="_blank">
            {link.text}
          </Link>
        ))}
        {renderButton()}
      </LinksBox>
    </HeaderContent>
  );
};

export default Desktop;
