import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 312px;
  max-height: 312px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const ViewCommentContainer = styled.div`
  width: 100%;
  & .hr {
    width: 100%;
    height: 1px;
    background-color: #e5e7e7;
    margin: 12px 0px;
  }
  max-height: 250px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #999999 transparent;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 6px;
    display: none;
  }
`;
export const ClientComment = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 24px;
`;
export const ClientCommentSender = styled.div`
  display: flex;
  flex-direction: column;
  & .name {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #333;
  }
  & .dateTime {
    width: 150px;
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #7f7f7f;
  }
`;
export const ClientCommentText = styled.div``;
export const CommentContainerActions = styled.div`
  display: flex;
  align-items: flex-start;
  & img {
    width: 20px;
    height: 20px;
  }
  & .edit {
    cursor: pointer;
  }
  & .delete {
    cursor: pointer;
  }
`;
export const CommentAction = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:hover {
    filter: brightness(0.8);
  }
`;
export const CommentContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  background-color: #fff;
`;
export const WriteComment = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;
export const CommentTitle = styled.h1`
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
`;
