import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  padding: 16px;
  color: ${({ theme }) => theme.color.black1b};
`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: 700;
`;

export const Subtitle = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.gray18};
  padding: 16px 0;
`;

export const PersonImage = styled.img`
  max-width: 181px;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;
