import qs from 'qs';
import HttpStatus from 'model/enums/http-status';
import { Pageable } from 'model/pageable';
import { Predicate } from 'model/predicate';

export const checkApiStatus = (validation: boolean, status: number, expectedStatus?: HttpStatus): boolean => {
  const compareStatus = expectedStatus || HttpStatus.OK;
  return status === compareStatus && validation;
};

export const requestParamsFormatter = (predicate: Predicate, page: Pageable, predicateParams?: object) => {
  const pageParams = qs.stringify({ ...page });
  const filterParams: string = Object.keys(predicate)
    .filter(key => predicate[key] != null)
    .map(key =>
      Array.isArray(predicate[key])
        ? `${key}=${predicate[key].map(item => item[predicateParams?.[key] || 'name']).join(',')}`
        : `${key}=${predicate[key]}`
    )
    .join('&');
  return [pageParams, filterParams];
};

export const simpleParamsFormatter = (predicate: object, predicateParams?: object) => {
  const filterParams: string = Object.keys(predicate)
    .filter(key => predicate[key] != null)
    .map(key =>
      Array.isArray(predicate[key])
        ? `${key}=${predicate[key].map(item => item[predicateParams?.[key] || 'name']).join(',')}`
        : `${key}=${predicate[key]}`
    )
    .join('&');
  return `?${filterParams}`;
};

export const buildUrl = (baseUrl: string, params: Record<string, any>) => {
  const queryParams = new URLSearchParams();

  for (const key in params) {
    if (Array.isArray(params[key])) {
      params[key].map(item => {
        queryParams.append(key, `${item}`);
      });
    } else {
      queryParams.append(key, params[key]);
    }
  }

  return `${baseUrl}?${queryParams.toString()}`;
};
