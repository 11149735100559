import styled from 'styled-components';

export const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  text-align: left;

  @media (max-width: 575.98px) {
    font-size: 12px;
  }
`;

export const SubTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.gray21};
  text-align: left;
  margin-top: -5px;
  margin-bottom: 24px;
`;

export const ContainerInputRadio = styled.div`
  height: 30px;
  display: flex;
  flex-direction: row;
  column-gap: 32px;
  margin-top: 8px;
  margin-left: 6px;
  margin-bottom: 35px;
`;
