import styled from 'styled-components';

export const Container = styled.div`
  min-width: 138px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 100px 0px;
`;
