import { StyledInnerContainer } from './styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import ContractContext from 'context/contract';
import { useHistory } from 'react-router-dom';
import MobileHeader from 'components/mobile-header';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { RoutePath } from 'model/enums/route-path';
import LoadingAnimation from 'components/loading-big-animation';
import { LoadingContainer, StyledContainer } from '../styles';
import CustomComponentModal from 'components/custom-conmponent-modal';
import CustomButton from 'components/custom-button';
import ScrollToTop from 'components/scroll-top';
import AlertIcon from 'images/alert-triangle2.svg';

const ProofLifeAnalysis = ({ t }: WithTranslation) => {
  const history = useHistory();

  const { signatureKey, errorDescriptionModal, setErrorDescriptionModal } = useContext(ContractContext);
  const { isMobile, isTablet } = useResponsiveMobile();

  useEffect(() => {
    if (!isMobile && !isTablet) {
      return history.push(`${RoutePath.QR_CODE}/${signatureKey}`);
    }
  }, []);

  return (
    <StyledContainer style={{ height: '100vh' }}>
      <MobileHeader></MobileHeader>
      <ScrollToTop />
      <StyledInnerContainer>
        <h1>{t('contract.waitingStep.proofLife.title')}</h1>
        <span>{t('contract.waitingStep.proofLife.subtitle')}</span>

        <LoadingContainer style={{ marginBottom: '24px' }}>
          <LoadingAnimation />
        </LoadingContainer>
      </StyledInnerContainer>

      <CustomComponentModal open={!!errorDescriptionModal} setOpen={bool => !bool && setErrorDescriptionModal(undefined)}>
        <div style={{ fontSize: '14px' }}>
          <img
            src={AlertIcon}
            style={{
              margin: 'auto',
              width: '100%',
              height: '28px',
              marginBottom: '4px',
            }}
          />

          <b>{t('contract.proofOfLife.erroModal.title')}</b>
          <div
            style={{ margin: '22px 0', textAlign: 'left' }}
          >{`${t('contract.proofOfLife.erroModal.reason')} ${errorDescriptionModal}`}</div>
        </div>
        <CustomButton
          style={{ width: '100%', justifyContent: 'center', borderRadius: '8px', background: '#FC4F4F' }}
          onClick={() => {
            setErrorDescriptionModal(undefined);
            history.push(RoutePath.PROOF_LIFE_INSTRUCTIONS);
          }}
        >
          {t('global.button.new')}
        </CustomButton>
      </CustomComponentModal>

      <div className="waiting-step-footer">{t('contract.footer')}</div>
    </StyledContainer>
  );
};

export default withTranslation()(ProofLifeAnalysis);
