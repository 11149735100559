import styled from 'styled-components';

interface Props {
  $isOpen?: boolean;
}

export const PaymentContainer = styled.div<Props>`
  width: 100%;
  height: ${({ $isOpen }) => (!$isOpen ? '96px' : 'auto')};

  border-bottom: 1px solid #e5e7e7;

  padding: 0px 0px 24px 0px;
`;

export const PaymentHeader = styled.div`
  height: 100%;
  min-height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const PaymentTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #000000;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
`;

export const DeletePayment = styled.button`
  display: flex;

  border: none;
  background: none;
  padding: 0px;
  margin: 0px;

  :hover {
    color: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const PaymentValue = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #1b1a1b;
`;

export const PaymentClosedHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;
export const PyamentClosedButtonBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

export const SeeDetails = styled.button`
  background: transparent;
  border: none;

  font-weight: 700;
  font-size: 16px;
  color: #0524dd;
`;

export const Separate = styled.div`
  width: 1px;
  height: 24px;
  background: #e5e7e7;
`;

export const DynamicForm = styled.form`
  display: flex;
  flex-wrap: wrap;

  column-gap: 24px;
  row-gap: 16px;
`;
