import LandingPageTemplateContext from 'context/landing-page-template';
import UseResponsiveContext from 'context/use-responsive-context';
import { DropdonwHeaderIcons } from 'images/header';
import { RegistrationStatus } from 'model/enums/registration-status';
import { RoutePath } from 'model/enums/route-path';
import StepType from 'model/enums/step-type';
import { HeaderType } from 'model/landing-page';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineMedicalServices } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { IRootState } from 'reducer';
import organizationsSystemStepsService from 'services/organization-system-steps-service';
import AuthUtils from 'shared/util/auth-utils';
import { ListType } from './dropdown';
import Desktop, { ModalType } from './layout/desktop';
import Mobile from './layout/mobile';

interface HeaderProps {
  variant: string;
  hideMenu?: boolean;
}

export interface LinkListType {
  name?: string;
  sectionId?: string;
  link?: string;
  text?: string;
}

const Header: React.FC<HeaderProps> = ({ variant, hideMenu }: HeaderProps) => {
  const location = useLocation();
  const { isMidTablet } = useContext(UseResponsiveContext);

  const { landingPageTemplate } = useContext(LandingPageTemplateContext);
  const data: HeaderType | undefined = landingPageTemplate?.headerScreen;
  const { t } = useTranslation();
  const account = useSelector((state: IRootState) => state.authentication.account);
  const organizationId = useSelector((state: IRootState) => state.systemParameters.systemParameters?.fieldType?.organization.id);

  const [allStepsOrganization, setAllStepsOrganization] = useState<StepType[]>();

  useEffect(() => {
    if (organizationId != null && account) {
      organizationsSystemStepsService.getSteps().then(response => setAllStepsOrganization(response.map(step => step.stepType)));
    }
  }, []);

  const LpDropdonw: ListType[] = [
    { icon: <DropdonwHeaderIcons.dollarIcon />, text: t('header.myCredits'), link: RoutePath.CREDITS },
    { icon: <DropdonwHeaderIcons.logOut />, text: t('header.logout'), link: RoutePath.LOGOUT },
  ];

  const LoggedDropdonw: ListType[] = [{ icon: <DropdonwHeaderIcons.logOut />, text: t('header.logout'), link: RoutePath.LOGOUT }];

  const ApprovedLoggedDropdonw: ListType[] = [
    { icon: <DropdonwHeaderIcons.user />, text: t('header.myData'), link: RoutePath.PROFILE },
    allStepsOrganization?.includes(StepType.CCB)
      ? { icon: <DropdonwHeaderIcons.dollarIcon />, text: t('header.myCredits'), link: RoutePath.CREDITS }
      : {},
    allStepsOrganization?.includes(StepType.PROCEDURE)
      ? { icon: <DropdonwHeaderIcons.dollarIcon />, text: t('header.myProcedures'), link: RoutePath.PROCEDURES }
      : {},
    allStepsOrganization?.includes(StepType.CREDIT_ANTICIPATION)
      ? { icon: <DropdonwHeaderIcons.dollarIcon />, text: t('header.myAnticipations'), link: RoutePath.ANTICIPATIONS }
      : {},
    { icon: <DropdonwHeaderIcons.dollarIcon />, text: t('header.myPayments'), link: RoutePath.PAYMENTS },
    { icon: <DropdonwHeaderIcons.bank />, text: t('header.myContracts'), link: RoutePath.CONTRACTS },
    // { icon: <DropdonwHeaderIcons.users />, text: t('header.myGuarantors'), link: RoutePath.GUARANTORS },
    // { icon: <DropdonwHeaderIcons.carIcon />, text: t('header.myVehicle'), link: RoutePath.VEHICLE },
    // { icon: <DropdonwHeaderIcons.home />, text: t('header.myProperty'), link: RoutePath.PROPERTY },
    // { icon: <DropdonwHeaderIcons.home />, text: t('header.bankData'), link: RoutePath.BANK_DATA },
    { icon: <DropdonwHeaderIcons.logOut />, text: t('header.logout'), link: RoutePath.LOGOUT },
  ];

  const AdminDropdown: ListType[] = [
    { icon: <DropdonwHeaderIcons.user />, text: t('header.myData'), link: RoutePath.ADMIN_HOME, modalType: ModalType.MY_DATA },
    {
      icon: allStepsOrganization?.includes(StepType.CCB) ? (
        <DropdonwHeaderIcons.dollarIcon />
      ) : allStepsOrganization?.includes(StepType.PROCEDURE) ? (
        <MdOutlineMedicalServices />
      ) : (
        <></>
      ),
      text: allStepsOrganization?.includes(StepType.CCB)
        ? t('header.creditAnalysis')
        : allStepsOrganization?.includes(StepType.PROCEDURE)
          ? t('header.procedure')
          : '',
      link: allStepsOrganization?.includes(StepType.CCB)
        ? RoutePath.MY_FINANCING_ADMIN
        : allStepsOrganization?.includes(StepType.PROCEDURE)
          ? RoutePath.PROCEDURE_ADMIN
          : '',
    },
    { icon: <DropdonwHeaderIcons.logOut />, text: t('header.logout'), link: RoutePath.LOGOUT },
  ];

  const returnStatus = () => {
    switch (account?.client?.registrationStatus) {
      case RegistrationStatus.APPROVED:
        return true;
      case RegistrationStatus.UPDATED:
        return true;
      default:
        return false;
    }
  };

  const returnDropdonw = () => {
    if (AuthUtils.isAdmin()) {
      return AdminDropdown;
    } else if (AuthUtils.isUser()) {
      if (account?.client?.registrationStatus !== RegistrationStatus.APPROVED) {
        return LoggedDropdonw;
      }
      if (!returnStatus()) {
        return LpDropdonw;
      }
      if (location.pathname === RoutePath.HOME) {
        return LpDropdonw;
      }
      return ApprovedLoggedDropdonw;
    }
  };

  return !isMidTablet ? (
    <Desktop variant={variant} linkList={data?.sections} LoggedDropdonw={returnDropdonw()} />
  ) : (
    <Mobile hideMenu={hideMenu} variant={variant} linkList={data?.sections} LoggedDropdonw={returnDropdonw()} />
  );
};

export default Header;
