import styled from 'styled-components';

interface ContainerProps {
  $changeLayout?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${({ $changeLayout }) => ($changeLayout ? 'column' : 'row')};
  align-items: ${({ $changeLayout }) => ($changeLayout ? 'start' : 'center')};

  justify-content: space-between;

  margin-top: 48px;
  width: 100%;
`;

export const Title = styled.div<ContainerProps>`
  width: ${props => (props.$changeLayout ? '100%' : 'auto')};

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-style: normal;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  color: ${({ theme }) => theme.color.gray21};
`;

export const ButtonContainer = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  > button {
    height: 40px;
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
`;

export const FilterContent = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin: 16px 0px 0px 0px;
`;

interface FilterItemProps {
  $isActive?: boolean;
}

export const FilterItem = styled.button<FilterItemProps>`
  height: 40px;

  padding: 4px 12px;
  background: ${({ theme }) => theme.color.white};

  border: ${({ theme, $isActive }) => ($isActive ? `2px solid ${theme.color.primaryColor}` : `2px solid ${theme.color.gray20}`)};
  border-radius: 16px;
  color: ${({ theme }) => theme.color.gray18};

  border-radius: 8px;

  font-weight: 700;
  font-size: 14px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;

  :hover {
    border: 1px solid ${({ theme }) => theme.color.primaryColor};
  }
`;
