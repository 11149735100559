import InputText from 'components/styled-input';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Container, ContainerInfoButton, InputBox, StyledSection } from './styles';
import { Signatures } from 'model/signatures';
import StringUtils from 'shared/util/string-utils';
import { useEffect, useState } from 'react';
import { InfoButton, StyledSubtitle } from '../../styles';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { useTheme } from 'styled-components';
import { Organization } from 'model/organization';
import { FaceMatchAndOCRAdminResponse } from 'model/face-match-and-ocr';
import clientDataService from 'services/client-data-service';

interface IProps {
  signatureData: Signatures;
  organizationData?: Organization;
}

export const TabOCR = ({ signatureData, organizationData }: IProps) => {
  const { t } = useTranslation();
  const [openFull, setopenFull] = useState(false);
  const { color } = useTheme();

  const [faceMatchAndOCR, setFaceMatchAndOCR] = useState<FaceMatchAndOCRAdminResponse>();

  const methods = useForm({ mode: 'onSubmit' });

  useEffect(() => {
    clientDataService.getFaceMatchAndOCRAdmin(signatureData.client.id).then(setFaceMatchAndOCR);
  }, []);

  return (
    <Container>
      <FormProvider {...methods}>
        <InputBox>
          <StyledSubtitle>{t('enums.faceOrOCR.generalDataConsultation')}</StyledSubtitle>
          <StyledSection>
            <InputText
              disabled={true}
              name="analysisResult"
              label={t('enums.faceOrOCR.analysisResult')}
              defaultValue={faceMatchAndOCR?.documentOcr?.internalComment}
              width={628}
            />
          </StyledSection>
          <StyledSection>
            <InputText
              disabled={true}
              name="doneTriesQuantity"
              label={t('enums.faceOrOCR.doneTriesQuantity')}
              defaultValue={String(signatureData?.ocrProcessingTries ?? 0)}
            />
            <InputText
              disabled={true}
              name="availableTriesQuantity"
              label={t('enums.faceOrOCR.availableTriesQuantity')}
              defaultValue={String(organizationData?.numberOfDocFraudCalls ?? 0)}
            />
            <InputText
              disabled={true}
              name="issuedAt"
              label={t('enums.faceOrOCR.issuedAt')}
              defaultValue={StringUtils.dateFormatMask(faceMatchAndOCR?.documentOcr?.issuedAt?.toLocaleString() ?? '')}
            />
          </StyledSection>
          <StyledSubtitle>{t('enums.faceOrOCR.MatchingDocumentAndRevenueData')}</StyledSubtitle>
          <StyledSection>
            <InputText
              disabled={true}
              name="matchesName"
              label={t('enums.faceOrOCR.matchesName')}
              placeholder={t('register.placeholder.name')}
              defaultValue={signatureData.documentOcr?.matchesName === 'true' ? t('global.button.yes') : t('global.button.no')}
            />
            <InputText
              disabled={true}
              name="matchesMothersName"
              label={t('enums.faceOrOCR.matchesMothersName')}
              placeholder={t('register.placeholder.name')}
              defaultValue={signatureData.documentOcr?.matchesMothersName === 'true' ? t('global.button.yes') : t('global.button.no')}
            />
            <InputText
              disabled={true}
              name="matchesBirthdate"
              label={t('enums.faceOrOCR.matchesBirthdate')}
              placeholder={t('register.placeholder.name')}
              defaultValue={signatureData.documentOcr?.matchesBirthdate === 'true' ? t('global.button.yes') : t('global.button.no')}
            />
          </StyledSection>
          <StyledSubtitle>{t('enums.faceOrOCR.classificationResult')}</StyledSubtitle>
          <StyledSection>
            <InputText
              disabled={true}
              name="classificationType"
              label={t('enums.faceOrOCR.typeClassification')}
              defaultValue={
                signatureData.documentOcr?.classificationType &&
                t(`enums.faceOrOCR.${signatureData.documentOcr?.classificationType.toLowerCase()}`)
              }
            />
            <InputText
              disabled={true}
              name="classificationSubtype"
              label={t('enums.faceOrOCR.subTypeClassification')}
              defaultValue={
                signatureData.documentOcr?.classificationSubtype && t(`enums.faceOrOCR.${signatureData.documentOcr?.classificationSubtype}`)
              }
            />
            <InputText
              disabled={true}
              name="classificationCountry"
              label={t('enums.faceOrOCR.countryClassification')}
              defaultValue={signatureData.documentOcr?.classificationCountry}
            />
            <InputText
              disabled={true}
              name="classificationSide"
              label={t('enums.faceOrOCR.sideClassification')}
              defaultValue={
                signatureData.documentOcr?.classificationSide &&
                t(`enums.faceOrOCR.${signatureData.documentOcr?.classificationSide.toLowerCase()}`)
              }
            />
            <InputText
              disabled={true}
              name="classificationSameImage"
              label={t('enums.faceOrOCR.matchesNaclassificationSameImageme')}
              defaultValue={signatureData.documentOcr?.classificationSameImage ? t('global.button.yes') : t('global.button.no')}
            />
            <InputText
              disabled={true}
              name="confidence"
              label={t('enums.faceOrOCR.confidence')}
              defaultValue={
                signatureData.documentOcr?.details?.[0]?.confidence
                  ? `${signatureData.documentOcr?.details?.[0]?.confidence * 100} %`
                  : '0 %'
              }
            />
          </StyledSection>

          <ContainerInfoButton>
            <InfoButton onClick={() => setopenFull(!openFull)}>
              <span>{t('admin.signatures.seeDetails')}</span>
              {!openFull ? <AiOutlinePlus color={color.primaryColor} /> : <AiOutlineMinus color={color.primaryColor} />}
            </InfoButton>
          </ContainerInfoButton>
          {openFull && (
            <>
              <StyledSubtitle>{t('enums.faceOrOCR.RevenueData')}</StyledSubtitle>
              <StyledSection>
                <InputText
                  disabled={true}
                  name="taxDataId"
                  label={t('enums.faceOrOCR.documentNumber')}
                  defaultValue={signatureData.documentOcr?.taxDataId}
                />
                <InputText
                  disabled={true}
                  name="taxDataName"
                  label={t('enums.faceOrOCR.documentName')}
                  defaultValue={signatureData.documentOcr?.taxDataName}
                />
                <InputText
                  disabled={true}
                  name="taxDataMothersName"
                  label={t('enums.faceOrOCR.motherName')}
                  defaultValue={signatureData.documentOcr?.taxDataMothersName}
                />
                <InputText
                  disabled={true}
                  name="birthDay"
                  label={t('enums.faceOrOCR.birthDay')}
                  defaultValue={
                    signatureData.documentOcr?.taxDataBirthdate &&
                    StringUtils.dateFormatMask(signatureData.documentOcr?.taxDataBirthdate.toLocaleString())
                  }
                />
              </StyledSection>

              <StyledSubtitle>{t('enums.faceOrOCR.documentExtractionData')}</StyledSubtitle>
              <StyledSection>
                <InputText
                  disabled={true}
                  name="documentName"
                  label={t('enums.faceOrOCR.documentName')}
                  defaultValue={
                    signatureData.documentOcr?.enhancedSchemaName &&
                    t(`enums.faceOrOCR.${signatureData.documentOcr?.enhancedSchemaName.toLowerCase()}`)
                  }
                />
                <InputText
                  disabled={true}
                  name="documentNumber"
                  label={t('enums.faceOrOCR.documentNumber')}
                  defaultValue={signatureData.documentOcr?.enhancedPersonTaxId}
                />
                <InputText
                  disabled={true}
                  name="enhancedPersonName"
                  label={t('enums.faceOrOCR.clientName')}
                  defaultValue={signatureData.documentOcr?.enhancedPersonName}
                />
                <InputText
                  disabled={true}
                  name="enhancedPersonBirthdate"
                  label={t('enums.faceOrOCR.birthDay')}
                  defaultValue={
                    signatureData.documentOcr?.enhancedPersonBirthdate &&
                    StringUtils.dateFormatMask(signatureData.documentOcr?.enhancedPersonBirthdate.toLocaleString())
                  }
                />
                <InputText
                  disabled={true}
                  name="enhancedPersonMothersName"
                  label={t('enums.faceOrOCR.motherName')}
                  defaultValue={signatureData.documentOcr?.enhancedPersonMothersName}
                />
                <InputText
                  disabled={true}
                  name="enhancedPersonFathersName"
                  label={t('enums.faceOrOCR.fatherName')}
                  defaultValue={signatureData.documentOcr?.enhancedPersonFathersName}
                />
              </StyledSection>
            </>
          )}
        </InputBox>
      </FormProvider>
    </Container>
  );
};
