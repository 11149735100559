import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTheme } from 'styled-components';
import { StyledButton } from '../../styles';
import {
  Alignor,
  AssignedInteralsCard,
  AssignedInternalsContainer,
  BaseModalContainer,
  ButtonAlignor,
  DarkModalContainer,
  DenialAlignor,
  ErrorMessage,
  IconWrapper,
  InnerText,
  ModalDescription,
  ModalTitle,
  NewAnalistButton,
  NormalLabel,
  StyledGrClose,
  StyledTextField,
  Wrapper,
} from './styles';

import { yupResolver } from '@hookform/resolvers/yup';
import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import EnterCustomButton from 'components/enter-custom-button';
import { ReactSelectSearch } from 'components/react-select-search';
import InputText from 'components/styled-input';
import { Authority } from 'model/enums/authority';
import { AnalisysOptions } from 'model/enums/client-data';
import { InternalStatus } from 'model/enums/internal-status';
import { RegistrationStatus } from 'model/enums/registration-status';
import { Internal } from 'model/internals';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BiTrashAlt } from 'react-icons/bi';
import { FiAlertCircle } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import clientDataService from 'services/client-data-service';
import { InternalsService } from 'services/internals-service';
import * as yup from 'yup';
import { ModalConfig, ModalConfigProps } from '../..';
import { FormContainer, InputContent } from '../../../dashboard/screens/my-team/styles';
import { SimpleAnalisysSelect } from './simpleAnalisysSelect';

interface Schema {
  name: string;
  email: string;
  authority: string;
  status: string;
}

interface AnalisysStatusProps {
  name?: string;
  status?: RegistrationStatus;
}

interface AnalisysModalProps {
  modalConfiguration?: ModalConfigProps;
  setShowModal: (action: boolean) => void;
}

export const AnalisysModal = ({ modalConfiguration, setShowModal }: AnalisysModalProps) => {
  const [refresh, setRefresh] = useState(false);
  const [analisysStatus, setAnalisysStatus] = useState<AnalisysStatusProps>({});
  const [showDenialModal, setShowDenialModal] = useState(false);
  const [analisysObservation, setAnalisysObservation] = useState<string>('');
  const [editingObservation, setEditingObservation] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [registering, setRegistering] = useState(false);
  const [errorStatus, setErrorStatus] = useState<boolean>(false);
  const [internalList, setInternalList] = useState<Internal[]>([]);
  const [selectedAnalist, setSelectedAnalist] = useState<Internal>({});
  const [assignedInternals, setAssignedInternals] = useState<Internal[]>([]);
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const theme = useTheme();

  const schema = yup.object().shape({
    name: yup.string().required(t('global.errorMessage.required')),
    email: yup.string().email(t('global.errorMessage.email')).required(t('global.errorMessage.required')),
    authority: yup.string().required(t('global.errorMessage.required')),
    status: yup.string().required(t('global.errorMessage.required')),
  });

  const createNewInternal = async (values: Schema) => {
    const internal: Internal = {
      name: values.name,
      email: values.email,
      authority: values.authority,
      internalStatus: values.status as InternalStatus,
    };

    await InternalsService().createInternal(internal);
    closeModalAndResetInternal();
  };

  const methods = useForm<Schema>({ resolver: yupResolver(schema) });

  const closeModalAndResetInternal = () => {
    setRegistering(false);
    cleanValues();
  };

  const cleanValues = () => {
    methods.setValue('name', '');
    methods.setValue('email', '');
    methods.setValue('status', '');
    methods.setValue('authority', '');
  };

  useEffect(() => {
    InternalsService()
      .getInternalsList()
      .then(res => setInternalList(res.content));
  }, [registering]);

  const handleAddInternal = (internal: Internal) => {
    if (selectedAnalist.id) {
      InternalsService()
        .updateFinancyInternalById(Number(params.id), {
          id: Number(params.id),
          internals: [...assignedInternals, internal],
        })
        .then(res => {
          setRefresh(!refresh);
          setShowModal(false);
        });
    }
  };

  const handleRemoveInternal = (internal: Internal) => {
    InternalsService()
      .updateFinancyInternalById(Number(params.id), {
        id: Number(params.id),
        internals: assignedInternals.filter(elem => elem.id !== internal.id),
      })
      .then(res => setRefresh(!refresh));
  };

  useEffect(() => {
    // TODO: rever lógica
    // InternalsService()
    //   .getFinancyInternalsById(Number(params.id))
    //   .then(res => setAssignedInternals(res.internals));
  }, [refresh]);

  useEffect(() => {
    if (modalConfiguration?.type === ModalConfig.REPROVE) {
      setAnalisysStatus({
        name: t('analisisModal.REJECTED'),
        status: RegistrationStatus.REJECTED,
      });
    }
  }, []);

  const handleChange = (internal: Internal) => {
    setSelectedAnalist(internal);
  };

  const handleAnalisysCancel = () => {
    setAnalisysStatus({});
    setAnalisysObservation('');
    setShowDenialModal(false);
  };

  const handleAnalisysUpdate = () => {
    if (analisysStatus.name && analisysStatus.status) {
      setErrorStatus(false);
      if (analisysStatus.name === t(`analisisModal.${RegistrationStatus.REJECTED}`) && !showDenialModal) {
        setShowDenialModal(true);
      } else {
        if (analisysStatus.name === t(`analisisModal.${RegistrationStatus.ADJUST}`)) {
          if (analisysObservation) {
            setIsLoading(false);
            clientDataService
              .analyzeClientRegistration(
                {
                  id: Number(params.id),
                  internalComment: analisysObservation,
                  registrationStatus: analisysStatus.status,
                },
                Number(params.id)
              )
              .then(res => {
                setIsLoading(false);
                setShowModal(false);
              });
          }
        } else {
          setIsLoading(false);
          clientDataService
            .analyzeClientRegistration(
              { id: Number(params.id), internalComment: analisysObservation, registrationStatus: analisysStatus.status },
              Number(params.id)
            )
            .then(res => {
              setIsLoading(false);
              setShowModal(false);
            });
        }
      }
    } else {
      setErrorStatus(true);
    }
  };

  return (
    <>
      {registering ? (
        <CustomModalTwoButtons
          isShowModal={openCreateModal}
          title={t('admin.myTeam.modal.create.title')}
          firstButtonText={t('global.button.cancel')}
          secondButtonText={t('admin.myTeam.modal.create.buttonText')}
          onCloseModal={closeModalAndResetInternal}
          handleClickFirstButton={closeModalAndResetInternal}
          handleClickSecondButton={methods.handleSubmit(createNewInternal)}
          freeContent
        >
          <FormProvider {...methods}>
            <FormContainer>
              <InputContent>
                <InputText name="name" label={t('admin.myTeam.inputs.name')} placeholder={t('admin.myTeam.inputs.name')} />
                <InputText name="email" label={t('admin.myTeam.inputs.email')} placeholder={t('admin.myTeam.inputs.email')} />
              </InputContent>
              <InputContent>
                <ReactSelectSearch
                  name="authority"
                  placeholder={t('admin.myTeam.inputs.authority')}
                  options={[
                    { label: t(`admin.myTeam.enum.authority.${Authority.ROLE_INTERNAL}`), value: Authority.ROLE_INTERNAL },
                    { label: t(`admin.myTeam.enum.authority.${Authority.ROLE_INTERNAL_ADMIN}`), value: Authority.ROLE_INTERNAL_ADMIN },
                  ]}
                />
                <ReactSelectSearch
                  name="status"
                  placeholder={t('admin.myTeam.inputs.status')}
                  options={[
                    { label: t(`admin.myTeam.enum.status.${InternalStatus.ACTIVE}`), value: InternalStatus.ACTIVE },
                    { label: t(`admin.myTeam.enum.status.${InternalStatus.INACTIVE}`), value: InternalStatus.INACTIVE },
                  ]}
                />
              </InputContent>
            </FormContainer>
          </FormProvider>
        </CustomModalTwoButtons>
      ) : (
        <DarkModalContainer>
          <BaseModalContainer>
            <Wrapper>
              <StyledGrClose onClick={() => setShowModal(false)} />
              {!showDenialModal && <ModalTitle>{modalConfiguration?.title}</ModalTitle>}
              {modalConfiguration?.description && <ModalDescription>{modalConfiguration?.description}</ModalDescription>}
              {modalConfiguration?.type === ModalConfig.ANALIST_SELECTOR && (
                <Alignor>
                  <SimpleAnalisysSelect
                    value={selectedAnalist}
                    onChangeFunc={handleChange}
                    placeholder={t('analisisModal.selectAnalist')}
                    options={internalList}
                  />
                  <NewAnalistButton onClick={() => setRegistering(true)}>{t('clientData.modals.one')}</NewAnalistButton>

                  <AssignedInternalsContainer>
                    {assignedInternals.map((elem, index) => (
                      <AssignedInteralsCard key={index}>
                        {elem.name}
                        <BiTrashAlt onClick={() => handleRemoveInternal(elem)} />
                      </AssignedInteralsCard>
                    ))}
                  </AssignedInternalsContainer>
                  <ButtonAlignor>
                    <StyledButton onClick={() => setShowModal(false)} value={'empty'}>
                      {t('global.button.cancel')}
                    </StyledButton>
                    <StyledButton onClick={() => handleAddInternal(selectedAnalist)}>{t('global.button.confirm')}</StyledButton>
                  </ButtonAlignor>
                </Alignor>
              )}

              {modalConfiguration?.type === ModalConfig.ACTION_SELECTOR && (
                <>
                  {!showDenialModal ? (
                    <Alignor>
                      <SimpleAnalisysSelect
                        value={analisysStatus}
                        onChangeFunc={setAnalisysStatus}
                        placeholder={t('clientData.modals.two')}
                        options={[
                          { name: AnalisysOptions.SEND_ADJUSTS },
                          { name: AnalisysOptions.APPROVE },
                          { name: AnalisysOptions.NEGATE },
                        ]}
                      />
                      {errorStatus && <ErrorMessage>{t('global.errorMessage.required')}</ErrorMessage>}
                      <NormalLabel
                        $isRequired={analisysStatus.name === AnalisysOptions.SEND_ADJUSTS && analisysObservation === ''}
                        $empty={analisysObservation || editingObservation ? true : false}
                      >
                        {t('clientData.modals.three')}
                      </NormalLabel>
                      <StyledTextField
                        $isRequired={analisysStatus.name === AnalisysOptions.SEND_ADJUSTS && analisysObservation === ''}
                        onBlur={() => setEditingObservation(false)}
                        onFocus={() => setEditingObservation(true)}
                        value={analisysObservation}
                        onChange={e => setAnalisysObservation(e.target.value)}
                      />
                      {analisysStatus.name === AnalisysOptions.SEND_ADJUSTS && analisysObservation === '' && (
                        <ErrorMessage>{t('global.errorMessage.required')}</ErrorMessage>
                      )}
                      <ButtonAlignor>
                        <StyledButton onClick={() => setShowModal(false)} value={'empty'}>
                          {t('global.button.cancel')}
                        </StyledButton>
                        <EnterCustomButton onClick={handleAnalisysUpdate} isLoading={isLoading} text={t('global.button.confirm')} />
                      </ButtonAlignor>
                    </Alignor>
                  ) : (
                    <DenialAlignor>
                      <IconWrapper>
                        <FiAlertCircle />
                      </IconWrapper>
                      <ModalTitle style={{ width: '390px' }}>{t('clientData.modals.four')}</ModalTitle>
                      <ModalDescription style={{ width: '354px' }}>{t('clientData.modals.five')}</ModalDescription>
                      <ButtonAlignor>
                        <StyledButton onClick={handleAnalisysCancel} value={'empty'}>
                          {t('global.button.cancel')}
                        </StyledButton>
                        <StyledButton onClick={handleAnalisysUpdate}>{AnalisysOptions.NEGATE}</StyledButton>
                      </ButtonAlignor>
                    </DenialAlignor>
                  )}
                </>
              )}

              {modalConfiguration?.type === ModalConfig.REPROVE && (
                <>
                  {!showDenialModal ? (
                    <Alignor>
                      <NormalLabel style={{ marginTop: '50px' }} $empty={analisysObservation || editingObservation ? true : false}>
                        {t('clientData.modals.three')}
                      </NormalLabel>
                      <StyledTextField
                        onBlur={() => setEditingObservation(false)}
                        onFocus={() => setEditingObservation(true)}
                        value={analisysObservation}
                        onChange={e => setAnalisysObservation(e.target.value)}
                      />
                      <ButtonAlignor>
                        <StyledButton onClick={() => setShowModal(false)} value={'empty'}>
                          {t('global.button.cancel')}
                        </StyledButton>
                        <EnterCustomButton onClick={handleAnalisysUpdate} isLoading={isLoading} text={t('global.button.confirm')} />
                      </ButtonAlignor>
                    </Alignor>
                  ) : (
                    <DenialAlignor>
                      <IconWrapper>
                        <FiAlertCircle />
                      </IconWrapper>
                      <ModalTitle style={{ width: '390px' }}>{t('clientData.modals.four')}</ModalTitle>
                      <ModalDescription style={{ width: '354px' }}>{t('clientData.modals.five')}</ModalDescription>
                      <ButtonAlignor>
                        <StyledButton onClick={handleAnalisysCancel} value={'empty'}>
                          {t('global.button.cancel')}
                        </StyledButton>
                        <StyledButton onClick={handleAnalisysUpdate}>{AnalisysOptions.NEGATE}</StyledButton>
                      </ButtonAlignor>
                    </DenialAlignor>
                  )}
                </>
              )}

              {modalConfiguration?.type === ModalConfig.SHOW_SCORE && (
                <>
                  <div style={{ width: '160px', height: '160px', marginBottom: '40px' }}>
                    <CircularProgressbarWithChildren
                      strokeWidth={16}
                      styles={{
                        path: {
                          stroke: theme.color.primaryColor,
                          strokeLinecap: 'butt',
                          transition: 'stroke-dashoffset 0.5s ease 0s',
                          transform: 'rotate(100turn)',
                          transformOrigin: 'center center',
                        },
                        trail: {
                          stroke: '#d6d6d6',
                          strokeLinecap: 'butt',
                          transform: 'rotate(0.25turn)',
                          transformOrigin: 'center center',
                        },
                        text: {
                          fill: 'black',
                          fontWeight: '700',
                          fontSize: '22px',
                        },
                        background: {
                          fill: '#3e98c7',
                        },
                      }}
                      value={700}
                      minValue={1}
                      maxValue={1000}
                    >
                      <InnerText>
                        <span>700</span> <span>de 1000</span>
                      </InnerText>
                    </CircularProgressbarWithChildren>
                  </div>
                  <StyledButton onClick={() => setShowModal(false)}>{t('global.button.confirm')}</StyledButton>
                </>
              )}
            </Wrapper>
          </BaseModalContainer>
        </DarkModalContainer>
      )}
    </>
  );
};
