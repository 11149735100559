import { Logo } from 'images/custom-organization';
import { GeneralImages } from 'images/general-images';
import { HeaderVariants } from 'model/enums/header-variants';
import { RoutePath } from 'model/enums/route-path';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AuthUtils from 'shared/util/auth-utils';
import { goTo } from 'shared/util/go-to';
import { LinkListType } from '..';
import UserDropdown, { ListType } from '../dropdown';
import { AlignBox, HeaderContainer, HeaderContent, Link, LoggedUserBox, Login, LogoBox, Nav, ProfileImage, StyledText } from '../styles';

interface DesktopProps {
  variant: string;
  linkList?: LinkListType[];
  LoggedDropdonw?: ListType[];
}

export enum ModalType {
  MY_DATA = 'MY_DATA',
}

const Desktop: React.FC<DesktopProps> = ({ variant, linkList, LoggedDropdonw }) => {
  const history = useHistory();

  const { t } = useTranslation();

  const renderContent = () => {
    switch (variant) {
      case HeaderVariants.PRIMARY:
        return (
          <HeaderContainer $variant={variant}>
            <HeaderContent $variant={variant}>
              <AlignBox>
                <LogoBox onClick={() => history.push(RoutePath.HOME)}>
                  <Logo isWhite={false} />
                </LogoBox>
                <Nav>
                  {linkList?.map((link, idx) => (
                    <Link key={idx} onClick={() => goTo(link?.sectionId)}>
                      {link.name}
                    </Link>
                  ))}
                </Nav>
              </AlignBox>
              {AuthUtils.isAuthenticated() ? (
                <UserDropdown menuOpen list={LoggedDropdonw} />
              ) : (
                <Login onClick={() => history.push(RoutePath.LOGIN)}>{t('global.button.enter')}</Login>
              )}
            </HeaderContent>
          </HeaderContainer>
        );

      case HeaderVariants.SECONDARY:
        return (
          <HeaderContainer $variant={variant}>
            <HeaderContent $variant={variant}>
              <LogoBox onClick={() => (AuthUtils.isAdmin() ? history.push(RoutePath.ADMIN_HOME) : history.push(RoutePath.HOME))}>
                <Logo isWhite={true} />
              </LogoBox>
              {AuthUtils.isAuthenticated() ? (
                <UserDropdown menuOpen list={LoggedDropdonw} />
              ) : (
                <LoggedUserBox $isAuth={AuthUtils.isAuthenticated()} onClick={() => history.push(RoutePath.LOGIN)}>
                  <ProfileImage src={GeneralImages.iconDefault} />
                  <StyledText>{t('global.button.login')}</StyledText>
                </LoggedUserBox>
              )}
            </HeaderContent>
          </HeaderContainer>
        );

      case HeaderVariants.TERTIARY:
        return (
          <HeaderContainer $variant={variant}>
            <HeaderContent $variant={variant}>
              <LogoBox onClick={() => (AuthUtils.isAdmin() ? history.push(RoutePath.ADMIN_HOME) : history.push(RoutePath.HOME))}>
                <Logo isWhite={true} />
              </LogoBox>
            </HeaderContent>
          </HeaderContainer>
        );

      default:
        return <></>;
    }
  };

  return renderContent();
};

export default Desktop;
