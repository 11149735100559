import { StyledInnerContainer } from './styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import MobileHeader from 'components/mobile-header';
import LoadingAnimation from 'components/loading-big-animation';
import { LoadingContainer } from '../styles';
import contractService from 'services/contract-service';
import { useContext, useEffect, useState } from 'react';
import ContractContext from 'context/contract';
import { RoutePath } from 'model/enums/route-path';
import { useHistory } from 'react-router-dom';

const ProcessingData = ({ t }: WithTranslation) => {
  const history = useHistory();
  const { signatureKey, setContractResponse } = useContext(ContractContext);
  const [savedUuid, setSavedUuid] = useState<string>();

  const handleGetContract = async () => {
    await contractService
      .getContract(`${signatureKey}`)
      .then(response => {
        setSavedUuid(response.uuid);
        setContractResponse(response);
      })
      .catch(() => {
        history.push(RoutePath.ERROR_ANALYSIS);
      });
  };

  useEffect(() => {
    handleGetContract();
  }, []);

  useEffect(() => {
    if (savedUuid) {
      verifyBiometryUnico(savedUuid);
    }
  }, [savedUuid]);

  const verifyBiometryUnico = uuid => {
    contractService.verifyBiometryUnico(uuid).then(response => {
      console.log('verifyBiometryUnico', response);
      if (response?.unicoGeneralProcess?.statusProcess) {
        if (
          response.unicoGeneralProcess.statusProcess === 'WAITING_FINISH' ||
          response.unicoGeneralProcess.statusProcess === 'IN_PROGRESS'
        ) {
          setTimeout(() => {
            verifyBiometryUnico(savedUuid);
          }, 3000);
          setTimeout(() => {
            history.push(RoutePath.SIGNATURE_RELEASE);
          }, 60000);
        } else if (response?.unicoGeneralProcess.statusProcess === 'CANCELED') {
          history.push(RoutePath.SIGNATURE_CANCELED);
        } else if (
          response?.unicoGeneralProcess.statusProcess === 'FRAUD_NEGATIVE' &&
          response?.personSignature?.isLivenessDone &&
          response?.personSignature?.isDocumentOcrDone &&
          response?.personSignature?.isFaceMatchDone
        ) {
          history.push(RoutePath.SIGNATURE_SUCCESS);
        } else {
          history.push(RoutePath.SIGNATURE_RELEASE);
        }
      }
    });
  };

  return (
    <>
      <MobileHeader></MobileHeader>
      <StyledInnerContainer>
        <h1>{t('contract.waitingStep.ProcessingData.title')}</h1>
        <span>{t('contract.waitingStep.ProcessingData.subtitle')}</span>
        <span>{t('contract.waitingStep.ProcessingData.subtitle2')}</span>
        <span>{t('contract.waitingStep.ProcessingData.subtitle3')}</span>
        <LoadingContainer style={{ marginBottom: '24px' }}>
          <LoadingAnimation />
        </LoadingContainer>

        <div className="waiting-step-footer">{t('contract.footer')}</div>
      </StyledInnerContainer>
    </>
  );
};

export default withTranslation()(ProcessingData);
