import { Tooltip } from '@mui/material';
import FinancingStatusType from 'model/enums/financing-status-type';
import { Payment, ReimbursementData } from 'model/financing';
import { PaymentFormProperties, PaymentSectionType } from 'model/procedurePayment';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { PopupActions } from 'reactjs-popup/dist/types';
import financingService from 'services/financing-service';
import ConfirmPayment from '../confirm-payment';
import PaymentSection from '../payment-section';
import { ButtonAddPayment, EmptyList, PaymentContainer, PaymentList } from './style';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { Authority } from 'model/enums/authority';

interface ReimbursementPaymentProps {
  reimbursementData?: ReimbursementData;
  setReimbursementData: (ReimbursementData) => void;
  paymentListData: Payment[];
  setPaymentListData: (value) => void;
  getPaymentData: () => void;
}

const ReimbursementPayment: React.FC<ReimbursementPaymentProps> = ({
  reimbursementData,
  setReimbursementData,
  paymentListData,
  setPaymentListData,
  getPaymentData,
}) => {
  const authorities = useSelector((state: IRootState) => state.authentication.account?.authorities);
  const { t } = useTranslation();
  const { procedureId } = useParams<{ procedureId: string }>();
  const [isLoading, setIsLoading] = useState<number>(0);

  const onDelete = (idx: number) => {
    setPaymentListData(paymentList => paymentList.filter((obj, objIndex) => objIndex !== idx));
  };

  const modalRef = useRef<PopupActions>(null!);
  const closeTooltip = () => {
    if (modalRef?.current) {
      modalRef.current.close();
    }
  };

  const disableButton = paymentListData[paymentListData?.length - 1]?.[PaymentFormProperties.STATUS] === FinancingStatusType.PENDING;

  const hasProcedureId = Number(procedureId);

  const confirmPayment = (): void => {
    setIsLoading(1);

    if (hasProcedureId) {
      financingService
        .approveProcedureRefund(hasProcedureId)
        .then(res => {
          setTimeout(() => {
            setIsLoading(2);
          }, 2500);
          setReimbursementData(reimbursementData => ({ ...reimbursementData, paymentStatus: res.procedurePaymentStatus }));
        })
        .finally(() => {
          setIsLoading(0);
          closeTooltip();
        });
    }
  };

  return (
    <PaymentContainer>
      <ConfirmPayment
        paymentData={reimbursementData}
        setPaymentData={setReimbursementData}
        onConfirm={confirmPayment}
        reference={modalRef}
        isLoading={isLoading}
        buttonPayment={t('admin.procedureScreen.payments.confirmDischarge')}
      />

      <PaymentList>
        {paymentListData?.length > 0 ? (
          paymentListData?.map((el, idx) => (
            <PaymentSection
              key={idx}
              title={`Pagamento para o Prestador ${(idx + 1).toString().padStart(2, '0')}`}
              sectionType={PaymentSectionType.REIMBURSEMENT}
              position={idx}
              payment={el}
              setPaymentListData={setPaymentListData}
              getPaymentData={getPaymentData}
              onDelete={() => onDelete(idx)}
              procedureBankAccountType={reimbursementData?.procedureBankAccountType}
              buttonPayment={t('admin.procedureScreen.payments.confirmReceive')}
              errorPaymentMessage={el.errors}
            />
          ))
        ) : (
          <EmptyList>{t('proceduresDashboard.emptyReimbursement')}</EmptyList>
        )}
      </PaymentList>
      {disableButton && (authorities?.includes(Authority.ROLE_ADMIN) || authorities?.includes(Authority.ROLE_INTERNAL_ADMIN)) ? (
        <Tooltip title={t('proceduresDashboard.reimbursementIsNotIncluded') ?? ''} placement="top">
          <ButtonAddPayment type="button" $isDisabled={disableButton}>
            <AiOutlineInfoCircle />
            {t('proceduresDashboard.addNewReimbursement')}
          </ButtonAddPayment>
        </Tooltip>
      ) : (
        (authorities?.includes(Authority.ROLE_ADMIN) || authorities?.includes(Authority.ROLE_INTERNAL_ADMIN)) && (
          <ButtonAddPayment
            type="button"
            onClick={() =>
              setPaymentListData(payment => [
                ...payment,
                { [PaymentFormProperties.STATUS]: FinancingStatusType.PENDING, financingId: Number(procedureId) },
              ])
            }
          >
            {t('proceduresDashboard.addNewReimbursement')}
          </ButtonAddPayment>
        )
      )}
    </PaymentContainer>
  );
};

export default ReimbursementPayment;
