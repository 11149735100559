import styled from 'styled-components';

export const StyledListBox = styled.div`
  width: 100%;
  height: 138px;

  position: absolute;
  top: 5px;

  border: 1px solid #eeeeee;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  background: #ffffff;
  list-style: none;
  font-size: 16px;
  color: ${({ theme }) => `1px solid ${theme.color.gray19}`};
  overflow-y: hidden;
`;

export const Container = styled.div`
  .MuiAutocomplete-input {
    margin-right: 52px;
  }
  .MuiAutocomplete-clearIndicator {
    visibility: visible;
    background-color: #6665651f;
  }
`;
