import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px 24px;
  gap: 32px;
  width: 990px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-top: 15px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px;
  width: 914px;
  height: 48px;
  background: #fafafa;
  border-radius: 8px;

  @media (max-width: 366px) {
    flex-direction: column;
    margin-bottom: 77px;
  }
`;

interface HeaderItem {
  $active?: boolean;
}
export const HeaderItem = styled.div<HeaderItem>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 5px;
  width: 302px;
  height: 40px;
  cursor: pointer;

  background: ${props => props.$active && '#fff'};
  border: ${props => props.$active && '1px solid #e5e7e7'};
  box-shadow: ${props => props.$active && '0px 2px 12px rgba(0, 0, 0, 0.06)'};
  border-radius: 6px;

  font-style: normal;
  font-weight: ${props => (props.$active ? 700 : 400)};
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: ${props => (props.$active ? ({ theme }) => theme.color.primaryColor : '#7F7F7F')};
`;
export const FormContainer = styled.div`
  width: 100%;
`;

export const StyledError = styled.div`
  width: 100%;
  height: 16px;

  font-weight: 400;
  font-size: 12px;
  color: red;
  text-align: right;
`;
