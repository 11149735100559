import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  /* height: 100%; */
`;
export const ContentContainer = styled.div`
  width: 100%;
  padding: 0px 5px 0px 5px;
`;
export const Title = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  color: #000000;
`;
