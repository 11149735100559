import { AxiosResponse } from 'axios';
import { Contract } from 'model/financing';
import { api } from './api';
import IContractDetails from 'model/contract-details';
import { IConfirmData } from 'model/confirm-data-signature';
import IOcr, { IOcrResponse } from 'model/ocr';
import FaceMatch from 'model/enums/face-match';
import { ILiveness, ILivenessRepose, IUnicoDocument, IUnicoGetProcessDocumentResponse, IUnicoLiveness } from 'model/liveness';
import { IBiometryProcessResponse } from 'model/biometryStatus';

const contractApi = () => {
  const createFaceMatch = (data: FaceMatch): Promise<AxiosResponse<FaceMatch>> => {
    return api.post<FaceMatch>('/person-signature/create-face-match', data);
  };

  const getContractById = (contractId: number): Promise<AxiosResponse<Contract>> => {
    return api.get<Contract>(`/contracts/${contractId}`);
  };

  const getContract = (signatureKey: string): Promise<AxiosResponse<IContractDetails>> => {
    return api.get<IContractDetails>(`/person-signature/signature-key/${signatureKey}`);
  };

  const resendEmail = (contractId: number): Promise<AxiosResponse<Contract>> => {
    return api.post<Contract>(`/contracts/${contractId}/resend-email`);
  };

  const sendOcr = (data: IOcr): Promise<AxiosResponse<IOcrResponse>> => {
    return api.post<IOcrResponse>(`/person-signature/create-ocr`, data);
  };

  const confirmPersonSignatureData = (data: IConfirmData): Promise<AxiosResponse<IConfirmData>> => {
    return api.patch<IConfirmData>('/person-signature/confirm-data', data);
  };

  const finalizeContractSigning = (personSignatureId: number): Promise<AxiosResponse<IConfirmData>> => {
    return api.patch<IConfirmData>(`/person-signature/finish/${personSignatureId}`);
  };

  const sendLiveness = (contractId: number, data: ILiveness): Promise<AxiosResponse<ILivenessRepose>> => {
    return api.post<ILivenessRepose>(`/person-signature/${contractId}/create-liveness`, data);
  };

  const sendBiometryUnico = (contractUuid: string, data: IUnicoLiveness): Promise<AxiosResponse<ILivenessRepose>> => {
    return api.post<ILivenessRepose>(`/biometry/${contractUuid}`, data);
  };

  const verifyBiometryUnico = (contractUuid: string): Promise<AxiosResponse<IBiometryProcessResponse>> => {
    return api.post<IBiometryProcessResponse>(`/biometry/process-verification/${contractUuid}`);
  };

  const sendDocumentUnico = (contractUuid: string, data: IUnicoDocument): Promise<AxiosResponse<IUnicoGetProcessDocumentResponse>> => {
    return api.post<IUnicoGetProcessDocumentResponse>(`/document/${contractUuid}`, data);
  };

  return {
    createFaceMatch,
    getContractById,
    resendEmail,
    getContract,
    confirmPersonSignatureData,
    finalizeContractSigning,
    sendOcr,
    sendLiveness,
    sendBiometryUnico,
    verifyBiometryUnico,
    sendDocumentUnico,
  };
};

export default contractApi();
