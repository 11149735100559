import { Button, ButtonsContainer, FormInputsContainer, Row } from 'components/edit-forms/styles';
import { SearchSelectInput } from 'components/search-selected-input';
import InputText from 'components/styled-input';
import { TextInput } from 'components/text-input';
import { Mask } from 'model/enums/mask-types';
import { useEditForm } from 'provider/edit-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ContainerInputsIncomeEvolve = props => {
  const { t } = useTranslation();
  const { initialClientData, isFirstForm, isLastForm, onClickBack, isLoading } = useEditForm();
  const { occupations, occupationsTotalPages, getOccupations } = useSelectLists();
  const [occupationName, setOccupationName] = useState('');
  const [page, setPage] = useState(0);

  useEffect(() => {
    getOccupations({ name: occupationName, page, size: 7 }, true);
  }, [page]);

  return (
    <FormInputsContainer $visible={props.visible}>
      <Row>
        <TextInput name="occupation" label={t('register.inputs.occupation')} isRequired hasCustomInput>
          <SearchSelectInput
            name="occupation"
            disabled={props.onlyView}
            placeholder={t('register.placeholder.occupation')}
            options={occupations.map(occ => ({ label: occ.name ?? '', value: occ.id.toString() }))}
            defaultValue={initialClientData?.physicalPerson?.occupation?.name}
            handleChange={values => {
              setOccupationName(values.name);
              setPage(0);
              getOccupations({ name: values.name, size: 7 });
            }}
            handleOnScroll={() => {
              if (page < occupationsTotalPages - 1) {
                setPage(page + 1);
              }
            }}
            defaultOptions={occupations.map(occ => ({ label: occ?.name ?? '', value: occ.id.toString() }))}
          />
        </TextInput>
        <InputText
          name="income"
          disabled={props.onlyView}
          label={t('register.inputs.incomeEstimated')}
          placeholder={t('register.placeholder.income')}
          mask={Mask.CURRENCY}
          defaultValue={initialClientData?.physicalPerson?.income ? initialClientData?.physicalPerson?.income.toFixed(2) : ''}
          maxLength={14}
        />
      </Row>
    </FormInputsContainer>
  );
};
