import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  padding: 25px 90px;
`;
export const ContentContainer = styled.div``;
export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
export const Separator = styled.div`
  width: 32px;
  height: 0px;
  border: 1px solid #cccccc;
  transform: rotate(90deg);
`;
export const Title = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
  color: #1b1a1b;
`;
export const Description = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #4c4d4d;
`;
