import { yupResolver } from '@hookform/resolvers/yup';
import { RadiusProgressBar } from 'components/radius-progress-bar';
import InputText from 'components/styled-input';
import { Financing, PaymentType } from 'model/financing';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiChevronRight } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import financingService from 'services/financing-service';
import StringUtils from 'shared/util/string-utils';
import * as yup from 'yup';
import { Container, InputsBox, InstallmentsStats, OverviewContainer, SeeInstallments, Stats } from './styles';

interface Schema {
  totalValue: string;
  paidValue: string;
  remainingValue: string;
  installmentsTotal: string;
  nextDueDate: string;
  fees: string;
  cet: string;
}

interface TabPaymentProps {
  showStats?: boolean;
  isMobile?: boolean;
}

export const TabPayments = ({ showStats = true, isMobile = false }: TabPaymentProps) => {
  const { t } = useTranslation();
  const { financingId } = useParams<{ financingId: string }>();
  const [payments, setPayments] = useState<PaymentType | null>(null);
  const [financing, setFinancing] = useState<Financing | null>(null);
  const history = useHistory();

  const schema = yup.object().shape({});

  const methods = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    getPaymentsInfo();
  }, []);

  const getPaymentsInfo = async () => {
    if (financingId) {
      const res = await financingService.getPaymentProgress(Number(financingId));
      const finRes = await financingService.getFinancingById(Number(financingId));
      setPayments(res);
      setFinancing(finRes);
    }
  };

  const getProgressPercentage = (isExpired?: boolean) => {
    const total = payments?.payment?.totalValue ?? 0;
    const progress = isExpired ? (payments?.payment?.expiredValue ?? 0) : (payments?.payment?.paidValue ?? 0);

    if (!isExpired && total === 0) {
      return total;
    }

    return Math.floor((progress * 100) / total);
  };

  return (
    <Container>
      <OverviewContainer>
        <div className="blank" />
        <RadiusProgressBar
          progress={getProgressPercentage()}
          progressTwo={getProgressPercentage(true)}
          text={t('admin.customerAnalysis.tabPayments.progressText')}
        />
        {showStats ? (
          <InstallmentsStats>
            <SeeInstallments
              onClick={() => {
                if (financingId) {
                  history.push(`/admin/pagamentos/analise/${financingId}`);
                }
              }}
            >
              <span>{t('admin.customerAnalysis.tabPayments.seeInstallment')}</span> <FiChevronRight size={20} />
            </SeeInstallments>
            <Stats>
              <div className="paid" />
              <span>{t('admin.customerAnalysis.tabPayments.paid')}</span>
            </Stats>
            <Stats>
              <div className="late" />
              <span>{t('admin.customerAnalysis.tabPayments.late')}</span>
            </Stats>
            <Stats>
              <div className="remaining" />
              <span>{t('admin.customerAnalysis.tabPayments.remaining')}</span>
            </Stats>
          </InstallmentsStats>
        ) : (
          <div className="blank" />
        )}
      </OverviewContainer>
      <div>
        <FormProvider {...methods}>
          <InputsBox>
            <InputText
              name="totalValue"
              label={t('admin.customerAnalysis.tabPayments.totalValue')}
              disabled
              defaultValue={StringUtils.moneyMaskPtbr(payments?.payment?.totalValue ?? 0)}
              flexWidth
            />
            <InputText
              name="paidValue"
              label={t('admin.customerAnalysis.tabPayments.paidValue')}
              disabled
              defaultValue={StringUtils.moneyMaskPtbr(payments?.payment?.paidValue ?? 0)}
              flexWidth
            />
            <InputText
              name="remainingValue"
              label={t('admin.customerAnalysis.tabPayments.remainingValue')}
              disabled
              defaultValue={StringUtils.moneyMaskPtbr(payments?.payment?.remainingValue ?? 0)}
              flexWidth
            />
          </InputsBox>
          <InputsBox>
            <InputText
              name="installmentsTotal"
              label={t('admin.customerAnalysis.tabPayments.installmentsTotal')}
              disabled
              defaultValue={t('admin.customerAnalysis.tabPayments.valueTotalInstallments', { total: financing?.installmentsTotal ?? 0 })}
              flexWidth
            />
            <InputText
              name="nextDueDate"
              label={t('admin.customerAnalysis.tabPayments.nextDueDate')}
              disabled
              defaultValue={StringUtils.dateFormatMask(payments?.payment?.nextDueDate ?? '')}
              flexWidth
            />
            <InputText
              name="fees"
              label={t('admin.customerAnalysis.tabPayments.fees')}
              disabled
              defaultValue={t('admin.customerAnalysis.tabPayments.valueFees', { fees: financing?.financingTax ?? 0 })}
              flexWidth
            />
          </InputsBox>
          <InputsBox>
            <InputText
              name="cet"
              label={t('admin.customerAnalysis.tabPayments.cetMonth')}
              disabled
              defaultValue={t('admin.customerAnalysis.tabPayments.valueCet', { cet: financing?.monthlyTotalEffectiveCost ?? 0 })}
              flexWidth
            />
            <InputText
              name="cetYear"
              label={t('admin.customerAnalysis.tabPayments.cetAnnual')}
              disabled
              defaultValue={t('admin.customerAnalysis.tabPayments.valueCetYear', { cetYear: financing?.annualTotalEffectiveCost ?? 0 })}
              flexWidth
            />
            <InputText
              name="expiredValue"
              label={t('admin.customerAnalysis.tabPayments.expiredValue')}
              disabled
              defaultValue={StringUtils.moneyMaskPtbr(payments?.payment?.expiredValue ?? 0)}
              flexWidth
            />
          </InputsBox>
        </FormProvider>
      </div>
    </Container>
  );
};
