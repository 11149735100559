import { useTranslation } from 'react-i18next';
import { FlexibleStructureContent, FlexibleStructureImage, FlexibleStructureTitle } from '../styles';

interface FlexibleStructureProps {}

const FlexibleStructure: React.FC<FlexibleStructureProps> = () => {
  const { t } = useTranslation();
  return (
    <FlexibleStructureContent>
      <FlexibleStructureImage>
        <FlexibleStructureTitle>{t('landingPage.multtiplo.flexibleStructure.text')}</FlexibleStructureTitle>
      </FlexibleStructureImage>
    </FlexibleStructureContent>
  );
};

export default FlexibleStructure;
