import { StyledButton } from 'components/custom-button/styles';
import { data } from 'jquery';
import { AttachmentType } from 'model/enums/attachment-types';
import { Attachment } from 'model/attachment';
import { useState } from 'react';
import Camera, { CameraProps } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import { PhotoViewComponent } from './photo-view';
import { ButtonContainer, CameraContainer, Container, PhotoContainer, Title } from './styles';
import CustomLoadingButton from 'components/custom-loading-button';

interface MobilePhotoComponentProps extends CameraProps {
  type: AttachmentType;
  title?: string;
  buttonTitle?: string;
  onSubmit?: (attachment: Attachment) => void;
  isLoading?: boolean;
}

export const MobilePhotoComponent = ({ title, buttonTitle, isLoading, onSubmit, ...props }: MobilePhotoComponentProps) => {
  const { t } = useTranslation();
  const [dataUri, setDataUri] = useState<string | undefined>(undefined);

  const onTakePhoto = (dataUri: string) => {
    setDataUri(dataUri);
  };

  const clearPhoto = () => {
    setDataUri(undefined);
  };

  const onHandleSubmit = () => {
    const random = Math.floor(100000 + Math.random() * 900000);
    if (dataUri && onSubmit) {
      const attachment: Attachment = {
        type: props.type,
        file: StringUtils.getFileFromUri(dataUri),
        contentType: 'image/jpeg',
        fileName: `selfie-${random}.jpg`,
        name: `selfie-${random}`,
      };
      onSubmit(attachment);
    }
  };

  const isPhotoTaken = dataUri != null;

  return (
    <Container>
      <Title>{title ?? t('selfie.title')}</Title>
      <PhotoContainer>
        {!isPhotoTaken ? (
          <CameraContainer>
            <Camera isImageMirror imageType="jpg" onTakePhoto={onTakePhoto} {...props} />
          </CameraContainer>
        ) : (
          <>
            <PhotoViewComponent dataUri={dataUri!} />
            <ButtonContainer>
              <StyledButton $isInvertColor={true} onClick={clearPhoto}>
                {t('global.button.takePhotoAgain')}
              </StyledButton>
              <CustomLoadingButton $justifyContent="center" $width="40%" isLoading={isLoading} onClick={onHandleSubmit}>
                {buttonTitle ?? t('global.button.send')}
              </CustomLoadingButton>
            </ButtonContainer>
          </>
        )}
      </PhotoContainer>
    </Container>
  );
};
