import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Blank, InputContainer, Label, Title, Option, OptionContainer, StyledErrorMessage } from './styles';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useTheme } from 'styled-components';

interface SimpleSelectInputProps {
  name: string;
  defaultValue?: string;
  disabled?: boolean;
  errorMessage?: string;
  label?: string;
  placeholder?: string;
  options: string[];
  onSelectedOption?: (option: string) => void;
}

export const SimpleSelectInput = ({
  name,
  defaultValue,
  disabled,
  errorMessage,
  label,
  placeholder,
  options,
  onSelectedOption,
}: SimpleSelectInputProps) => {
  const [openDropDown, setOpenDropDown] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const { color } = useTheme();

  useEffect(() => {
    const values = getValues();

    if (values[name]) {
      setSelectedOption(values[name]);
    }
  }, []);

  useEffect(() => {
    if (defaultValue) {
      setSelectedOption(defaultValue);
      setValue(name, defaultValue);
    }
  }, [defaultValue]);

  const {
    register,
    getValues,
    formState: { errors, dirtyFields },
    setValue,
  } = useFormContext();

  const handleGetValue = () => {
    const values = getValues();
    return Boolean(values[name]);
  };

  const hasDefaultValue = () => {
    if ((defaultValue || handleGetValue()) && !dirtyFields[name]) {
      return true;
    }
    return dirtyFields[name];
  };

  const styledComponentsProps = {
    hasError: !disabled ? !!errors[name] || errorMessage != null : false,
    hasValue: !disabled ? handleGetValue() || hasDefaultValue() : false,
    isValid: !disabled ? !errors[name] && hasDefaultValue() : false,
  };

  const selectOption = (option: string) => {
    setValue(name, option);
    setSelectedOption(option);

    if (onSelectedOption) {
      onSelectedOption(option);
    }
  };

  return (
    <>
      <Label htmlFor={`#input-${name}`} {...styledComponentsProps}>
        {label && selectedOption != null ? <Title>{label}</Title> : <Blank>{'.'}</Blank>}
        <InputContainer
          {...styledComponentsProps}
          $error={styledComponentsProps.hasError}
          type="button"
          onClick={() => setOpenDropDown(!openDropDown)}
        >
          <input id={`#select-${name}`} {...register(name)} disabled />
          {placeholder && !selectedOption && <span className="placeholder">{placeholder}</span>}
          {selectedOption && <span className="selectedOption">{selectedOption}</span>}
          {openDropDown && !disabled ? <FiChevronUp color={color.gray22} /> : <FiChevronDown color={color.gray22} />}

          {openDropDown && !disabled && (
            <OptionContainer>
              {options.map((option, idx) => (
                <Option key={idx} onClick={() => selectOption(option)}>
                  {option}
                </Option>
              ))}
            </OptionContainer>
          )}
          {errorMessage != null && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
        </InputContainer>
      </Label>
    </>
  );
};
