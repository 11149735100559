import { useRegisterForm } from 'provider/register-form';
import { useTranslation } from 'react-i18next';
import { Bar, Container, ProgressBar, Step, Title } from './styles';

interface RegisterStepsMobileProps {
  step?: string;
}

const RegisterStepsMobile: React.FC<RegisterStepsMobileProps> = props => {
  const { steps, actualStep } = useRegisterForm();

  const { t } = useTranslation();
  return (
    <Container>
      <Title>{t('register.title.registerStep')}</Title>
      <Step>{props.step}</Step>
      <ProgressBar>
        {steps.map((step, i) => (
          <Bar key={i} $active={actualStep >= i} />
        ))}
      </ProgressBar>
    </Container>
  );
};

export default RegisterStepsMobile;
