import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Message = styled.span`
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray19};

  max-width: 348px;
  text-align: center;
`;

export const QRCodeContainer = styled.div`
  width: 200px;
  height: 200px;

  margin-top: 24px;
`;
