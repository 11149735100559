import DynamicMobileForm from 'components/dynamic-mobile-form';
import RegisterStepsMobile from 'components/register-steps-mobile';
import { toLower } from 'lodash';
import { useRegisterForm } from 'provider/register-form';
import { useTranslation } from 'react-i18next';
import { Container, ContentContainer, Title } from './styles';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { useEffect } from 'react';

const StyledMobileFormContainer: React.FC = props => {
  const { t } = useTranslation();
  const { steps, actualStep, resetStates } = useRegisterForm();

  const isMobile = useResponsiveMobile().isTablet;

  useEffect(() => {
    resetStates();
  }, [isMobile]);

  const findTitle = () => {
    return steps.find(step => step.stepOrder === actualStep + 1)?.systemStep?.step ?? '';
  };

  return (
    <Container>
      <ContentContainer>
        {findTitle() && <RegisterStepsMobile step={t(`stepper.title.${toLower(findTitle())}`)} />}
        <DynamicMobileForm type={findTitle()} />
      </ContentContainer>
    </Container>
  );
};

export default StyledMobileFormContainer;
