export enum PersonSignatureStatusTypes {
  AWAITING_SUBMISSION_FOR_SIGNATURE = 'AWAITING_SUBMISSION_FOR_SIGNATURE',
  SENT_FOR_SIGNATURE = 'SENT_FOR_SIGNATURE',
  DONE = 'DONE',
  CANCELED = 'CANCELED',
  AWAITING_SIGNED_CONTRACT = 'AWAITING_SIGNED_CONTRACT',
  ERROR_ATTEMPTS_EXCEEDED_FOR_OCR = 'ERROR_ATTEMPTS_EXCEEDED_FOR_OCR',
  ERROR_ATTEMPTS_EXCEEDED_FOR_FACE_MATCH = 'ERROR_ATTEMPTS_EXCEEDED_FOR_FACE_MATCH',
  ERROR_ATTEMPTS_EXCEEDED_FOR_LIVENESS = 'ERROR_ATTEMPTS_EXCEEDED_FOR_LIVENESS',
  BIOMETRIC_COMPANY_CHECK = 'BIOMETRIC_COMPANY_CHECK',
  BIOMETRIC_ADMIN_CHECK = 'BIOMETRIC_ADMIN_CHECK',
  DOCUMENT_ADMIN_CHECK = 'DOCUMENT_ADMIN_CHECK',
  UNICO_COMPANY_CHECK = 'UNICO_COMPANY_CHECK',
  ERROR_OCR = 'ERROR_OCR',
  ERROR_FACEMATCH = 'ERROR_FACEMATCH',
  ERROR_LIVENESS = 'ERROR_LIVENESS',
  WAITING_SIGNATURE_FINISH = 'WAITING_SIGNATURE_FINISH',
  FINISH_ADMIN_CHECK = 'FINISH_ADMIN_CHECK',
}
