import styled from 'styled-components';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
interface IFormInputsContainer {
  $visible?: boolean;
}
export const FormInputsContainer = styled.div<IFormInputsContainer>`
  display: ${props => (props.$visible ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;

  @media (max-width: 400px) {
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
  }
`;
interface IButtonProps {
  $primary?: boolean;
}
export const Button = styled.button<IButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  width: 160px;
  height: 48px;
  background: ${props => (props.$primary ? ({ theme }) => theme.color.primaryColor : 'transparent')};
  border: 1px solid ${props => (props.$primary ? ({ theme }) => theme.color.primaryColor : '#CCCCCC')};
  border-radius: 24px;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${props => (props.$primary ? '#fff' : '#CCCCCC')};
  }
`;
export const ImageContainer = styled.div`
  margin-left: 30px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 18px;
  padding: 5px 0px;
  @media (max-width: 888px) {
    justify-content: space-between !important;
  }
`;

export const RowCenter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  row-gap: 0px;
  padding: 18px;
`;
