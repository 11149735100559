import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonsContainer } from 'components/edit-forms/styles';
import EnterCustomButton from 'components/enter-custom-button';
import { parse } from 'date-fns';
import { EditButton } from 'features/profile/styles';
import Stroke from 'images/x.svg';
import { isDate, isEmpty, toLower } from 'lodash';
import { Client, EmploymentBond } from 'model/client';
import SystemStepCategory from 'model/enums/system-step-category';
import { useEditForm } from 'provider/edit-form';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { employmentBondOptions, genderType, nationalityType, ownCar, ownMotorbike, scholarity } from 'shared/util/select-utils';
import StringUtils, { validateCpf } from 'shared/util/string-utils';
import * as yup from 'yup';
import { editFormFields } from './edit-form-fields';
import { EditFormSteps } from './edit-form-steps';
import { Container, FormContainer, Header, HeaderItem, StyledError } from './styles';
import { useSelectLists } from 'provider/select-list';
import { ReactComponent as EllipseIconSvg } from 'images/red-ellipse.svg';
import { ORGANIZATION_NAME } from 'config/constants';
import { OrganizationName } from 'model/enums/organization-name';

interface IDynamicEditFormProps {
  type: string;
  onlyView?: boolean;
  mobile?: boolean;
}

interface Schema {
  name: string;
  motherName?: string;
  fatherName?: string;
  genderType?: string;
  maritalStatus?: string;
  nationalityType?: string;
  homePhone?: string;
  complement?: string;
  reference?: string;
  admissionDate?: Date;
  spousePhone?: string | null;
  rgDateOfIssuance?: string | Date;
  city?: string;
  state?: string;
  birth: Date;
  rg?: string;
  cpf?: string;
  cnpj?: string;
  partnerCode?: string;
  paymentCompany?: string;
  bankAccountNumber?: string;
  bankAccountDigit?: string;
  agencyNumber?: string;
  agencyDigit?: string;
  bankType?: string;
  pixType?: string;
  pixKey?: string;
  street?: string;
  number: string;
  zipcode?: string;
  referencePoint?: string;
  district?: string;
  assignmentFee?: string;
  retentionPercentage?: string;
  phone: string;
  email: string;
  residenceType?: string;
  yearsOfResidence?: number | string;
  mailingDestinationType?: string;
  companyTimeInYears?: string;
  ownMotorbike?: string;
  ownCar?: string;
  scholarity?: string;
  company?: string;
  dependents?: number | string;
  password?: string;
  confirmPassword?: string;
  rgIssuingBody?: string;
  spouseName?: string;
  spouseBirth?: Date;
  occupation?: string;
  identityDocumentType?: string;
  identityDocument?: string;
  identityDocumentIssuingBody?: string;
  identityDocumentUfIssuingBody?: string;
  identityDocumentIssueDate?: string;
  employmentBond?: string;
  income?: number | string;
  rgUfIssuingBody?: string;
}

interface SchemaDefault {
  name: string;
  motherName?: string;
  fatherName?: string;
  genderType?: string;
  maritalStatus: string;
  nationalityType?: string;
  homePhone?: string;
  complement?: string;
  reference?: string;
  admissionDate?: Date;
  spousePhone?: string | null;
  rgDateOfIssuance?: string | Date;
  city: string;
  state: string;
  birth: Date;
  rg: string;
  cpf: string;
  cnpj?: string;
  partnerCode?: string;
  paymentCompany?: string;
  bankAccountNumber?: string;
  bankAccountDigit?: string;
  agencyNumber?: string;
  agencyDigit?: string;
  bankType?: string;
  pixType?: string;
  pixKey?: string;
  street: string;
  number: string;
  zipcode: string;
  referencePoint?: string;
  district: string;
  assignmentFee?: string;
  retentionPercentage?: string;
  phone: string;
  email: string;
  residenceType?: string;
  yearsOfResidence?: number | string;
  mailingDestinationType?: string;
  companyTimeInYears?: string;
  ownMotorbike?: string;
  ownCar?: string;
  scholarity?: string;
  company?: string;
  dependents?: number | string;
  password?: string;
  confirmPassword?: string;
  rgIssuingBody?: string;
  spouseName?: string;
  spouseBirth?: Date;
  occupation: string;
  identityDocumentType?: string;
  identityDocument?: string;
  identityDocumentIssuingBody?: string;
  identityDocumentUfIssuingBody?: string;
  identityDocumentIssueDate?: string;
  employmentBond?: string;
  income: string;
  rgUfIssuingBody?: string;
}

const DynamicDesktopEditForm: React.FC<IDynamicEditFormProps> = props => {
  const {
    getClientData,
    steps,
    actualStep,
    setActualStep,
    initialClientData,
    validateMajority,
    onClickNext,
    isLoading,
    setIsLoading,
    isLoadingInitialData,
  } = useEditForm();
  const { t } = useTranslation();
  const history = useHistory();
  const { occupations, maritalStatus, residenceType } = useSelectLists();

  useEffect(() => {
    getClientData();
  }, []);

  const checkIfIsRequired = (employmentBondValue: string) => {
    const employmentBond = employmentBondOptions.find(option => option.label === employmentBondValue);
    if (employmentBond?.value) {
      switch (employmentBond.value) {
        case EmploymentBond.AUTONOMO:
          return true;
        case EmploymentBond.PROFISSIONAL_LIBERAL:
          return true;
        case EmploymentBond.APOSENTADO:
          return true;
        case EmploymentBond.RURAL:
          return true;
        case EmploymentBond.EMPRESARIO:
          return true;
        case EmploymentBond.OUTRAS:
          return true;
        default:
          return false;
      }
    }
    return false;
  };

  const generateMinDate = () => {
    const actualDate = new Date();
    actualDate.setFullYear(actualDate.getFullYear() - 18);
    return actualDate;
  };

  const schema = yup.object({
    name: yup.string().required(t('global.errorMessage.required')),
    birth: yup
      .date()
      .max(generateMinDate(), t('global.errorMessage.majority'))
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .required(t('global.errorMessage.required'))
      .typeError(t('global.errorMessage.date')),
    phone: yup.string().min(10, t('global.errorMessage.phone')).required(t('global.errorMessage.required')),
    homePhone: yup.string(),
    email: yup.string().email(t('global.errorMessage.email')).required(t('global.errorMessage.required')),
    cpf: yup
      .string()
      .min(11, t('global.errorMessage.invalidCpf'))
      .test('test-cpf', t('global.errorMessage.invalidCpf'), cpf => validateCpf(cpf!))
      .required(t('global.errorMessage.required')),
    rg: yup.string().min(5, t('global.errorMessage.invalidRG')).required(t('global.errorMessage.required')),
    zipcode: yup.string().min(9, t('global.errorMessage.zipcode')).required(t('global.errorMessage.required')),
    street: yup.string().required(t('global.errorMessage.required')),
    number: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-only-number', t('global.errorMessage.anyWord'), value => {
        if (value != null) {
          return /^\d+$/.test(value);
        }
        return false;
      }),
    complement: yup.string(),
    reference: yup.string(),
    district: yup.string().required(t('global.errorMessage.required')),
    state: yup.string().required(t('global.errorMessage.required')),
    city: yup.string().required(t('global.errorMessage.required')),
    occupation: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReferenceAutomatic'), value => {
        if (value) {
          return occupations.map(item => item.name && item.name.toLowerCase()).includes(value.toLowerCase());
        } else {
          return false;
        }
      }),
    admissionDate: yup
      .date()
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .typeError(t('global.errorMessage.date')),
    income: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('test-income', t('global.errorMessage.income'), income => {
        return Boolean(income?.match(/\d/g));
      }),
    spousePhone: yup.string().nullable(),
    spouseBirth: yup
      .date()
      .nullable()
      .transform((value, originalValue) => {
        if (!originalValue) return null;
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .typeError(t('global.errorMessage.date')),
    spouseName: yup.string(),
  });

  const schemaMonjua = yup.object({
    name: yup.string().required(t('global.errorMessage.required')),
    motherName: yup.string().required(t('global.errorMessage.required')),
    genderType: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReference'), value => {
        if (value) {
          return genderType.map(item => item.label.toLowerCase()).includes(value.toLowerCase());
        } else {
          return false;
        }
      }),
    maritalStatus: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReference'), value => {
        if (value) {
          return maritalStatus.map(item => item.displayValue.toLowerCase()).includes(value.toLowerCase());
        } else {
          return false;
        }
      }),
    birth: yup
      .date()
      .max(generateMinDate(), t('global.errorMessage.majority'))
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .required(t('global.errorMessage.required'))
      .typeError(t('global.errorMessage.date')),
    phone: yup.string().min(10, t('global.errorMessage.phone')).required(t('global.errorMessage.required')),
    homePhone: yup.string(),
    email: yup.string().email(t('global.errorMessage.email')).required(t('global.errorMessage.required')),
    cpf: yup
      .string()
      .min(11, t('global.errorMessage.invalidCpf'))
      .test('test-cpf', t('global.errorMessage.invalidCpf'), cpf => validateCpf(cpf!))
      .required(t('global.errorMessage.required')),
    zipcode: yup.string().min(9, t('global.errorMessage.zipcode')).required(t('global.errorMessage.required')),
    street: yup.string().required(t('global.errorMessage.required')),
    number: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-only-number', t('global.errorMessage.anyWord'), value => {
        if (value != null) {
          return /^\d+$/.test(value);
        }
        return false;
      }),
    complement: yup.string(),
    reference: yup.string(),
    district: yup.string().required(t('global.errorMessage.required')),
    state: yup.string().required(t('global.errorMessage.required')),
    city: yup.string().required(t('global.errorMessage.required')),
    residenceType: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReference'), value => {
        if (value) {
          return residenceType
            .map(item => item.displayValue.toLowerCase())
            .includes(StringUtils.reverseDisplayValue(value.toLowerCase()) ?? '');
        } else {
          return false;
        }
      }),
    yearsOfResidence: yup.string().required(t('global.errorMessage.required')),
    occupation: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReferenceAutomatic'), value => {
        if (value) {
          return occupations.map(item => item.name && item.name.toLowerCase()).includes(value.toLowerCase());
        } else {
          return false;
        }
      }),
    companyTimeInYears: yup.string().required(t('global.errorMessage.required')),
    ownMotorbike: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReference'), value => {
        if (value) {
          return ownMotorbike.map(item => item.label && item.label.toLowerCase()).includes(value.toLowerCase());
        } else {
          return false;
        }
      }),
    scholarity: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReference'), value => {
        if (value) {
          return scholarity.map(item => item.label && item.label.toLowerCase()).includes(value.toLowerCase());
        } else {
          return false;
        }
      }),
    ownCar: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReference'), value => {
        if (value) {
          return ownCar.map(item => item.label && item.label.toLowerCase()).includes(value.toLowerCase());
        } else {
          return false;
        }
      }),
    company: yup.string().required(t('global.errorMessage.required')),
    dependents: yup.string().required(t('global.errorMessage.required')),
    admissionDate: yup
      .date()
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .typeError(t('global.errorMessage.date')),
    income: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('test-income', t('global.errorMessage.income'), income => {
        return Boolean(income?.match(/\d/g));
      }),
    rgIssuingBody: yup.string().required(t('global.errorMessage.required')),
    rgUfIssuingBody: yup.string().required(t('global.errorMessage.required')),
    spousePhone: yup.string().nullable(),
    spouseBirth: yup
      .date()
      .nullable()
      .transform((value, originalValue) => {
        if (!originalValue) return null;
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .typeError(t('global.errorMessage.date')),
    rgDateOfIssuance: yup
      .date()
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .required(t('global.errorMessage.required'))
      .typeError(t('global.errorMessage.date')),
    spouseName: yup.string(),
  });

  const schemaZm = yup.object({
    name: yup.string().required(t('global.errorMessage.required')),
    motherName: yup.string().required(t('global.errorMessage.required')),
    genderType: yup.string().test('valid-gender-type', t('global.errorMessage.invalidReference'), value => {
      if (value != null) {
        return genderType.map(gender => gender.label).includes(value);
      }

      return false;
    }),
    maritalStatus: yup.string().test('valid-marital-status', t('global.errorMessage.invalidReference'), value => {
      if (value != null) {
        return maritalStatus.map(value => value.displayValue).includes(value);
      }

      return false;
    }),
    nationalityType: yup.string().required(t('global.errorMessage.required')),
    birth: yup
      .date()
      .max(generateMinDate(), t('global.errorMessage.majority'))
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .required(t('global.errorMessage.required'))
      .typeError(t('global.errorMessage.date')),
    phone: yup.string().min(10, t('global.errorMessage.phone')).required(t('global.errorMessage.required')),
    email: yup.string().email(t('global.errorMessage.email')).required(t('global.errorMessage.required')),
    cpf: yup
      .string()
      .min(11, t('global.errorMessage.invalidCpf'))
      .test('test-cpf', t('global.errorMessage.invalidCpf'), cpf => validateCpf(cpf!))
      .required(t('global.errorMessage.required')),
    identityDocumentType: yup.string().required(t('global.errorMessage.required')),
    identityDocument: yup.string().min(5, t('global.errorMessage.invalidRG')).required(t('global.errorMessage.required')),
    identityDocumentIssuingBody: yup.string().required(t('global.errorMessage.required')),
    identityDocumentUfIssuingBody: yup.string().required(t('global.errorMessage.required')),
    identityDocumentIssueDate: yup
      .date()
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .required(t('global.errorMessage.required'))
      .typeError(t('global.errorMessage.date')),
    zipcode: yup.string().min(9, t('global.errorMessage.zipcode')).required(t('global.errorMessage.required')),
    street: yup.string().required(t('global.errorMessage.required')),
    number: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-only-number', t('global.errorMessage.anyWord'), value => {
        if (value != null) {
          return /^\d+$/.test(value);
        }
        return false;
      }),
    complement: yup.string(),
    reference: yup.string(),
    district: yup.string().required(t('global.errorMessage.required')),
    city: yup.string().required(t('global.errorMessage.required')),
    residenceType: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReference'), value => {
        if (value) {
          return residenceType
            .map(item => item.displayValue.toLowerCase())
            .includes(StringUtils.reverseDisplayValue(value.toLowerCase()) ?? '');
        } else {
          return false;
        }
      }),
    yearsOfResidence: yup.string().required(t('global.errorMessage.required')),
    occupation: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReferenceAutomatic'), value => {
        if (value) {
          return occupations.map(item => item.name && item.name.toLowerCase()).includes(value.toLowerCase());
        } else {
          return false;
        }
      }),
    companyTimeInYears: yup.string().required(t('global.errorMessage.required')),
    income: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('test-income', t('global.errorMessage.income'), income => {
        return Boolean(income?.match(/\d/g));
      }),
    spousePhone: yup.string().nullable(),
    spouseBirth: yup
      .date()
      .nullable()
      .transform((value, originalValue) => {
        if (!originalValue) return null;
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .typeError(t('global.errorMessage.date')),
    spouseName: yup.string(),
    fatherName: yup.string(),
  });

  const schemaMulttiplo = yup.object({
    name: yup.string().required(t('global.errorMessage.required')),
    birth: yup
      .date()
      .max(generateMinDate(), t('global.errorMessage.majority'))
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .required(t('global.errorMessage.required'))
      .typeError(t('global.errorMessage.date')),
    phone: yup.string().min(10, t('global.errorMessage.phone')).required(t('global.errorMessage.required')),
    email: yup.string().email(t('global.errorMessage.email')).required(t('global.errorMessage.required')),
    cpf: yup
      .string()
      .min(11, t('global.errorMessage.invalidCpf'))
      .test('test-cpf', t('global.errorMessage.invalidCpf'), cpf => validateCpf(cpf!))
      .required(t('global.errorMessage.required')),
    rg: yup.string().min(5, t('global.errorMessage.invalidRG')).required(t('global.errorMessage.required')),
    zipcode: yup.string().min(9, t('global.errorMessage.zipcode')).required(t('global.errorMessage.required')),
    street: yup.string().required(t('global.errorMessage.required')),
    number: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-only-number', t('global.errorMessage.anyWord'), value => {
        if (value != null) {
          return /^\d+$/.test(value);
        }
        return false;
      }),
    complement: yup.string(),
    reference: yup.string(),
    district: yup.string().required(t('global.errorMessage.required')),
    state: yup.string().required(t('global.errorMessage.required')),
    city: yup.string().required(t('global.errorMessage.required')),
  });

  const schemaEvolve = yup.object({
    name: yup.string().required(t('global.errorMessage.required')),
    birth: yup
      .date()
      .max(generateMinDate(), t('global.errorMessage.majority'))
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .required(t('global.errorMessage.required'))
      .typeError(t('global.errorMessage.date')),
    phone: yup.string().min(10, t('global.errorMessage.phone')).required(t('global.errorMessage.required')),
    homePhone: yup.string(),
    email: yup.string().email(t('global.errorMessage.email')).required(t('global.errorMessage.required')),
    cpf: yup
      .string()
      .min(11, t('global.errorMessage.invalidCpf'))
      .test('test-cpf', t('global.errorMessage.invalidCpf'), cpf => validateCpf(cpf!))
      .required(t('global.errorMessage.required')),
    rg: yup.string().min(5, t('global.errorMessage.invalidRG')).required(t('global.errorMessage.required')),
    zipcode: yup.string().min(9, t('global.errorMessage.zipcode')).required(t('global.errorMessage.required')),
    street: yup.string().required(t('global.errorMessage.required')),
    number: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-only-number', t('global.errorMessage.anyWord'), value => {
        if (value != null) {
          return /^\d+$/.test(value);
        }
        return false;
      }),
    complement: yup.string(),
    reference: yup.string(),
    district: yup.string().required(t('global.errorMessage.required')),
    state: yup.string().required(t('global.errorMessage.required')),
    city: yup.string().required(t('global.errorMessage.required')),
    occupation: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-reference', t('global.errorMessage.invalidReferenceAutomatic'), value => {
        if (value) {
          return occupations.map(item => item.name && item.name.toLowerCase()).includes(value.toLowerCase());
        } else {
          return false;
        }
      }),
    admissionDate: yup
      .date()
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .typeError(t('global.errorMessage.date')),
    income: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('test-income', t('global.errorMessage.income'), income => {
        return Boolean(income?.match(/\d/g));
      }),
    spousePhone: yup.string().nullable(),
    spouseBirth: yup
      .date()
      .nullable()
      .transform((value, originalValue) => {
        if (!originalValue) return null;
        const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'dd/MM/yyyy', new Date());
        return parsedDate;
      })
      .typeError(t('global.errorMessage.date')),
    spouseName: yup.string(),
  });

  const generateValidationSchemaByCategory = (): Resolver<any> => {
    if (ORGANIZATION_NAME === OrganizationName.MONJUA) {
      return yupResolver(schemaMonjua);
    }

    if (ORGANIZATION_NAME === OrganizationName.ZM) {
      return yupResolver(schemaZm);
    }
    if (ORGANIZATION_NAME === OrganizationName.MULTTIPLO) {
      return yupResolver(schemaMulttiplo);
    }

    return yupResolver(schema);
  };

  const methods = useForm({
    resolver: props.onlyView ? undefined : generateValidationSchemaByCategory(),
  });

  useEffect(() => {
    if (initialClientData) {
      if (initialClientData.physicalPerson) {
        methods.setValue('name', initialClientData.physicalPerson.name || '');
        methods.setValue('motherName', initialClientData.physicalPerson.motherName || '');
        methods.setValue('fatherName', initialClientData.physicalPerson.fatherName || '');
        methods.setValue('genderType', genderType.find(res => res.value === initialClientData?.physicalPerson?.genderType)?.label);
        if (initialClientData.physicalPerson.maritalStatus) {
          methods.setValue('maritalStatus', initialClientData.physicalPerson.maritalStatus.displayValue || '');
        }
        methods.setValue(
          'nationalityType',
          nationalityType.find(res => res.value === initialClientData?.physicalPerson?.nationalityType)?.label || ''
        );
        methods.setValue('birth', new Date(StringUtils.dateFormatMask(initialClientData?.physicalPerson?.birth.toString())));
        methods.setValue('cpf', initialClientData.physicalPerson.cpf || '');
        methods.setValue('rg', initialClientData.physicalPerson.rg || initialClientData.physicalPerson.identityDocument || '');

        if (initialClientData.address) {
          methods.setValue('zipcode', initialClientData.address.zipcode || '');
          methods.setValue('street', initialClientData.address.street || '');
          methods.setValue('number', initialClientData.address.number || '');
          methods.setValue('district', initialClientData.address.district || '');
          methods.setValue('complement', initialClientData.address.complement || '');
          methods.setValue('city', initialClientData.address.city?.name || ''); // todo checar cidade
          methods.setValue('state', initialClientData.address.state?.name || ''); // todo checar estado
          methods.setValue(
            'residenceType',
            residenceType.find(item => item.id === initialClientData?.address?.residenceType?.id)?.displayValue || ''
          );
          methods.setValue('yearsOfResidence', initialClientData.address.yearsOfResidence || '');
          methods.setValue('mailingDestinationType', initialClientData.address.mailingDestinationType || '');
        }
        methods.setValue('companyTimeInYears', initialClientData.physicalPerson.companyTimeInYears?.toString() || '');
        methods.setValue('ownCar', initialClientData.physicalPerson.ownCar || '');
        methods.setValue('ownMotorbike', initialClientData.physicalPerson.ownMotorbike || '');
        methods.setValue('scholarity', initialClientData.physicalPerson.scholarity || '');
        methods.setValue('company', initialClientData.physicalPerson.company || '');
        methods.setValue('dependents', initialClientData.physicalPerson.dependents || '');
        if (initialClientData?.physicalPerson?.admissionDate) {
          methods.setValue(
            'admissionDate',
            new Date(StringUtils.dateFormatMask(initialClientData?.physicalPerson?.admissionDate.toString()))
          );
        }
        methods.setValue('income', initialClientData.physicalPerson.income.toString() || '');
        if (initialClientData?.user?.password) {
          methods.setValue('password', initialClientData?.user?.password || '');
          methods.setValue('confirmPassword', initialClientData?.user?.password || '');
        }
        methods.setValue('rgIssuingBody', initialClientData.physicalPerson.rgIssuingBody || '');

        if (initialClientData?.physicalPerson?.rgDateOfIssuance) {
          methods.setValue('rgDateOfIssuance', StringUtils.dateFormatMask(initialClientData?.physicalPerson?.rgDateOfIssuance.toString()));
        }

        if (initialClientData.physicalPerson?.spouse) {
          methods.setValue('spouseName', initialClientData.physicalPerson.spouse?.name || '');
          methods.setValue('spouseBirth', initialClientData.physicalPerson.spouse?.birth as Date);
          methods.setValue('spousePhone', initialClientData.physicalPerson.spouse?.phone || '');
        }

        if (initialClientData.physicalPerson.occupation) {
          methods.setValue('occupation', initialClientData.physicalPerson.occupation.name || '');
        }

        if (initialClientData.physicalPerson.identityDocumentType != null) {
          methods.setValue('identityDocumentType', initialClientData.physicalPerson.identityDocumentType || '');
          methods.setValue('identityDocument', initialClientData.physicalPerson.identityDocument || '');
          methods.setValue('identityDocumentIssuingBody', initialClientData.physicalPerson.identityDocumentIssuingBody || '');
          methods.setValue('identityDocumentUfIssuingBody', initialClientData.physicalPerson.identityDocumentUfIssuingBody || '');
          methods.setValue(
            'identityDocumentIssueDate',
            initialClientData?.physicalPerson?.identityDocumentIssueDate
              ? StringUtils.dateFormatMask(initialClientData?.physicalPerson?.identityDocumentIssueDate.toString())
              : ''
          );
        }
      }

      methods.setValue('phone', initialClientData.phone || '');
      methods.setValue('homePhone', initialClientData.homePhone || '');
      methods.setValue('email', initialClientData.email || '');
      methods.setValue('employmentBond', initialClientData.employmentBond || '');
    }
  }, []);

  const onSubmit = res => {
    if (validateMajority(res.birth)) {
      onClickNext(res as Client);
    }
  };

  const [errorsFound, setErrorsFound] = useState<string[]>([]);

  const isCurrentStepFieldsValid = async () => {
    if (props.onlyView) {
      return true;
    }

    const currentStepFieldsValid = await methods.trigger(editFormFields[steps[actualStep].systemStep.step]);

    const errors = await methods.formState.errors;
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      setErrorsFound(Object.keys(errors));
      console.error('Ocorreram erros durante a validação:', errors);
    } else {
      setErrorsFound([]);
    }

    return currentStepFieldsValid;
  };

  const onChangeStep = async (step: number) => {
    await isCurrentStepFieldsValid();
    setActualStep(step);
  };

  const generateLabel = useCallback(
    stepLabel => {
      let listOfFields = editFormFields[stepLabel];
      if (listOfFields) {
        let existeElementoComum = errorsFound.some(elemento => listOfFields.includes(elemento));

        if (existeElementoComum && errorsFound.length > 0) {
          return (
            <>
              {t(`stepper.title.${toLower(stepLabel)}`)}
              <EllipseIconSvg />
            </>
          );
        } else {
          return <>{t(`stepper.title.${toLower(stepLabel)}`)}</>;
        }
      }

      return <>{t(`stepper.title.${toLower(stepLabel)}`)}</>;
    },
    [errorsFound]
  );

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={async res => {
          res.preventDefault();
          setIsLoading(true);
          const fullFieldsValid = await methods.trigger();

          const errors = await methods.formState.errors;
          const errorsArray = Object.keys(errors);
          if (errorsArray.length > 0) {
            setErrorsFound(Object.keys(errors));
            console.error('Ocorreram erros durante a validação:', errors);
          } else {
            setErrorsFound([]);
          }

          if (fullFieldsValid) {
            onSubmit(methods.watch());
          } else {
            setIsLoading(false);

            steps.map((step, key) => {
              if (editFormFields[step?.systemStep?.step].some(elemento => errorsArray.includes(elemento))) {
                setActualStep(key);
              }
            });
          }
        }}
        style={{ width: '100%' }}
      >
        <Container>
          <Header>
            {steps.map((step, key) => {
              return (
                <HeaderItem
                  key={key}
                  $active={actualStep === key}
                  onClick={async () => {
                    await onChangeStep(key);
                  }}
                >
                  {generateLabel(step?.systemStep?.step)}
                </HeaderItem>
              );
            })}
          </Header>
          <FormContainer>
            {!isLoadingInitialData && (
              <EditFormSteps
                checkIfIsRequired={checkIfIsRequired}
                steps={steps}
                visible={props.type as SystemStepCategory}
                onlyView={props.onlyView ?? false}
              />
            )}
          </FormContainer>
        </Container>
        {errorsFound.length > 0 && <StyledError>{t('global.errorMessage.fillInputs')}</StyledError>}
        {!props.onlyView && (
          <ButtonsContainer>
            <EditButton type="button" onClick={() => history.goBack()}>
              <img src={Stroke} />
              <span>{t('global.button.cancelEdit')}</span>
            </EditButton>
            <EnterCustomButton type="submit" isLoading={isLoading} text={t('global.button.saveData')} />
          </ButtonsContainer>
        )}
      </form>
    </FormProvider>
  );
};

export default DynamicDesktopEditForm;
