import InputText from 'components/styled-input';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Container, InputBox, StyledSection } from './styles';
import { Signatures } from 'model/signatures';

interface IProps {
  signatureData: Signatures;
}

export const TabFaceMatch = ({ signatureData }: IProps) => {
  const { t } = useTranslation();

  const methods = useForm({ mode: 'onSubmit' });

  return (
    <Container>
      <FormProvider {...methods}>
        <InputBox>
          <StyledSection>
            <InputText
              disabled={true}
              name="classificationSameImage"
              label={t('enums.faceOrOCR.matchesImage')}
              placeholder={t('register.placeholder.name')}
              defaultValue={!signatureData?.faceMatch?.matched ? t('global.button.no') : t('global.button.yes')}
            />
            <InputText
              disabled={true}
              name="email"
              label={t('enums.faceOrOCR.confidence')}
              placeholder={'0%'}
              defaultValue={signatureData?.faceMatch?.confidence?.toFixed(2)}
            />
          </StyledSection>
        </InputBox>
      </FormProvider>
    </Container>
  );
};
