import { ORGANIZATION_NAME, ORGANIZATION_TYPE } from 'config/constants';
import AdminFiltersContext, { Filters } from 'context/admin-filters';
import _ from 'lodash';
import { ContractDashboard } from 'model/dashboard';
import { CreatedDates } from 'model/date';
import { ContractStatus } from 'model/enums/contract-status';
import { OrganizationName } from 'model/enums/organization-name';
import { OrganizationType } from 'model/enums/organization-type';
import { useAdmin } from 'provider/admin-general';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DashboardService } from 'services/dashboard-service';
import { getStorageFilter } from 'shared/util/filter-utils';
import { Status } from '../../components/status';
import { TableId, TableList, TableValues } from '../../components/table-list';
import { TitleHeader } from '../../components/title-header';

interface GetContractProps {
  name?: string;
  size?: number;
  page?: number;
  contractStatus?: string[];
  createdDates?: CreatedDates;
  filters?: Filters;

  subsidiaryName?: string;
}

export const filterValues = [ContractStatus.NEW, ContractStatus.IN_PROGRESS, ContractStatus.FINISHED, ContractStatus.CANCELLED];

export const ContractsList = () => {
  const [contracts, setContracts] = useState<ContractDashboard[]>([]);
  const [searchName, setSearchName] = useState('');
  const [searchSubsidiaryName, setSearchSubsidiaryName] = useState('');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const { t } = useTranslation();
  const { statusFilters, createdDates, handleSetNewStatus } = useAdmin();
  const history = useHistory();
  const storageFilters = getStorageFilter();
  const isPhysicalPerson = ORGANIZATION_TYPE === OrganizationType.PF;
  const [orderBy, setOrderBy] = useState<boolean>(false);

  const { filters } = useContext(AdminFiltersContext);

  const isHcred = ORGANIZATION_NAME === OrganizationName.HCRED;

  useEffect(() => {
    verifyStorageAndGetContract();
  }, [searchName, page, orderBy, searchSubsidiaryName, filters]);

  const verifyStorageAndGetContract = () => {
    let defaultFilters = statusFilters;

    if (storageFilters?.statusFilters && storageFilters?.statusFilters?.length > 0 && !statusFilters.length) {
      defaultFilters = storageFilters.statusFilters;
      handleSetNewStatus(defaultFilters);
    }

    if (isHcred) {
      getDashboardContracts({
        name: searchName,
        page,
        contractStatus: filters.statusFilters,
        createdDates: filters.dateRange,
        subsidiaryName: searchSubsidiaryName,
      });
    } else {
      getDashboardContracts({ name: searchName, page, contractStatus: defaultFilters, createdDates, subsidiaryName: searchSubsidiaryName });
    }
  };

  const getDashboardContracts = async (props?: GetContractProps) => {
    const res = await DashboardService().getContractsList(
      props?.name,
      props?.size,
      props?.page,
      props?.contractStatus,
      props?.createdDates,
      orderBy,
      props?.subsidiaryName
    );
    setContracts(res.content);
    setPage(res.pageable.pageNumber);
    setTotalPages(res.totalPages);
  };

  const setTableValues = () => {
    const tableValues: TableValues[] = [
      {
        header: isPhysicalPerson ? t('admin.dashboard.tableHeaders.name') : t('admin.dashboard.tableHeaders.corporateName'),
        values: isPhysicalPerson
          ? contracts.map(contract => contract.financing.client.physicalPerson?.name ?? '')
          : contracts.map(contract => contract.financing.client.legalPerson?.corporateName ?? ''),
        ids: contracts.map(contract => ({
          first: contract.id,
        })),
        onClick: (ids?: TableId) => {
          if (ids?.first) {
            history.push(`/admin/contratos/analise/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.contract'),
        values: contracts.map(contract => contract.attachment?.fileName ?? ''),
        ids: contracts.map(contract => ({
          first: contract.id,
        })),
        onClick: (ids?: TableId) => {
          if (ids?.first) {
            history.push(`/admin/contratos/analise/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.status'),
        values: contracts.map(contract => (
          <Status key={contract.id} text={t(`enum.contractStatus.${contract.status}`)} color={contract.status} />
        )),
        ids: contracts.map(contract => ({
          first: contract.id,
        })),
        onClick: (ids?: TableId) => {
          if (ids?.first) {
            history.push(`/admin/contratos/analise/${ids.first}`);
          }
        },
      },
    ];

    return tableValues;
  };

  const updateSearchName = _.debounce((name: string) => {
    setSearchName(name);
  }, 500);

  const updateSubsidiaryName = _.debounce((name: string) => {
    setSearchSubsidiaryName(name);
  }, 500);

  const setNewPage = _.debounce((newPage: number) => {
    if (newPage >= totalPages) {
      return setPage(page);
    }
    setPage(newPage);
  }, 500);

  const setNewFilter = () => {
    getDashboardContracts({ name: searchName, contractStatus: statusFilters, createdDates });
  };

  const cleanAppliedFilters = () => {
    getDashboardContracts({ name: searchName });
  };

  return (
    <>
      <TitleHeader
        title={t('admin.dashboard.contracts')}
        setSearch={updateSearchName}
        setSubsidiarySearch={updateSubsidiaryName}
        filterValues={filterValues}
        setFilters={setNewFilter}
        cleanFilters={cleanAppliedFilters}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
      <TableList tableValues={setTableValues()} page={page} totalPages={totalPages} setNewPage={setNewPage} />
    </>
  );
};
