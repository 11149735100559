import { AxiosResponse } from 'axios';
import { CreatedDates } from 'model/date';
import { PartnersBalance } from 'services/partners';
import { api } from './api';

export interface FilterParams {
  searchName?: string;
  size?: number;
  page?: number;
  paymentStatus?: string[];
  createdDates?: CreatedDates;
}

export const partnersApi = () => {
  const getBalances = (): Promise<AxiosResponse<PartnersBalance>> => {
    return api.get<PartnersBalance>(`/balances/subsidiaries`);
  };

  return {
    getBalances,
  };
};

export default partnersApi();
