import React from 'react';
import { useTheme } from 'styled-components';
import logo from './logo.svg';
import logoWhite from './logoWhite.svg';

export const Logo = ({ isWhite }) => {
  const theme = useTheme();

  return !isWhite ? <img src={logo} /> : <img src={logoWhite} />;
};
