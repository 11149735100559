import { useTranslation } from 'react-i18next';
import { CardInfo } from '..';
import { Card, CardBox, CardTitle, NearButton, NearContent, NearText, NearTitle, TextContainer } from '../../styles';

interface DesktopProps {
  cardInfo: CardInfo[];
}

const Desktop: React.FC<DesktopProps> = ({ cardInfo }) => {
  const { t } = useTranslation();

  return (
    <NearContent>
      <CardBox>
        {cardInfo.map((card, index) => (
          <Card key={`cardInfo-${index}`}>
            {card.icon}
            <CardTitle>{card.title}</CardTitle>
          </Card>
        ))}
      </CardBox>
      <TextContainer>
        <NearTitle>{t('landingPage.multtiplo.near.title')}</NearTitle>
        <NearText>{t('landingPage.multtiplo.near.text')}</NearText>
        <NearButton>{t('landingPage.multtiplo.talk')}</NearButton>
      </TextContainer>
    </NearContent>
  );
};

export default Desktop;
