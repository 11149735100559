import styled from 'styled-components';

export const SignatureContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Unsigned = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.color.gray20};
  border-radius: 50%;
`;

export const Sign = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  margin: 0px;
  margin-left: 8px;
`;

export const DropDownButton = styled.button`
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  position: relative;
`;

export const DropDownBox = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  padding: 16px;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: max-content;
  align-items: flex-start;
  justify-content: center;

  border: 1px solid ${({ theme }) => theme.color.borderGray};
  box-shadow: 0px 4px 4px ${({ theme }) => theme.color.shadow};
  border-radius: 8px;

  button {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.gray19};

    border: none;
    background-color: transparent;

    width: 100%;
    text-align: left;
  }

  button + button {
    border-top: 1px solid ${({ theme }) => theme.color.gray20};
  }
`;
