import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  max-width: 540px;
  width: 85%;
  height: 316px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(27, 25, 25, 0.08);
  border-radius: 30px;
`;
export const Title = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #4c4d4d;
`;
interface TextInputProps {
  $error?: boolean;
}
export const TextInput = styled.input<TextInputProps>`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  gap: 8px;
  width: 390px;
  height: 68px;
  border: ${props => (!props.$error ? '1px solid #cccccc' : '2px solid #FC4F4F')};
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 150%;
  text-align: center;
  color: #1b1a1b;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #999999;
  }
  :-ms-input-placeholder {
    color: #999999;
  }
  @media (max-width: 450px) {
    font-size: 22px;
  }
`;

export const MessageError = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #fc4f4f;
  display: flex;
  align-items: align-center;
`;
