import InputErrorMessage from 'components/InputErrorMessage';
import { ErrorMessageBox } from 'components/styled-input/styles';
import StyledInputWrapper from 'components/StyledInputWrapper';
import AlertIcon from 'images/alert-triangle.svg';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Container, Option, OptionContainer, SelectContent } from './styles';

interface Options {
  label: string;
  value: string;
}

export interface Values {
  name: string;
  value?: string;
}

interface SelectInputProps {
  name: string;
  placeholder: string;
  options?: Options[];
  defaultOptions?: Options[];
  defaultValue?: string;
  handleChange?: (values: Values) => void;
  handleOnScroll?: () => void;
  errorMessage?: string;
  showErrorIcon?: boolean;
  disabled?: boolean;
  resetValue?: boolean;
  onBlur?: () => void;
}

export interface SelectInputHandlerProps {
  setError: (error: string) => void;
}

export const SearchSelectInput = forwardRef(
  (
    {
      name,
      options,
      placeholder,
      defaultOptions,
      defaultValue,
      handleChange,
      handleOnScroll,
      errorMessage,
      showErrorIcon,
      onBlur,
      disabled = false,
      resetValue,
    }: SelectInputProps,
    ref
  ) => {
    const [optionSelected, setOptionSelected] = useState<Options | null>(null);
    const [openOptions, setOpenOptions] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [timeOutSearch, setTimeOutSearch] = useState<NodeJS.Timeout | null>(null);

    const {
      register,
      getValues,
      formState: { dirtyFields },
      clearErrors,
      setValue,
      setError,
      control,
    } = useFormContext();

    const {
      fieldState: { error },
    } = useController({ name, control });

    useImperativeHandle(ref, () => ({
      setError: (error: string) => {
        setError(name, { message: error });
      },
    }));

    useEffect(() => {
      if (defaultValue) setInputValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
      clearErrors(name);
    }, [inputValue]);

    useEffect(() => {
      setValue(name, inputValue);

      if (timeOutSearch != null) {
        clearTimeout(timeOutSearch);
      }

      setTimeOutSearch(
        setTimeout(() => {
          if (handleChange) {
            handleChange({ name: inputValue, value: optionSelected?.value });
          }
        }, 500)
      );
    }, [inputValue]);

    useEffect(() => {
      if (resetValue) {
        setValue(name, '');
        setInputValue('');
        setOptionSelected(null);
        setOpenOptions(false);
        setIsFocused(false);
      }
    }, [resetValue]);

    const handleGetValue = () => {
      const values = getValues();
      return Boolean(values[name]);
    };

    const hasDefaultValue = () => {
      if ((defaultValue || handleGetValue()) && !dirtyFields[name]) {
        return true;
      }
      return dirtyFields[name];
    };

    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      if (onBlur) {
        onBlur();
      }
      setIsFocused(false);
    };

    const handleRegister = register(name);

    const styledComponentsProps = {
      $isTouched: isFocused,
      $hasError: !disabled ? (!!error || errorMessage != null) && !isFocused : false,
      $hasValue: !disabled ? handleGetValue() || hasDefaultValue() : false,
      $isFocused: isFocused,
      $isValid: !disabled
        ? (!error && !isFocused && (handleGetValue() || hasDefaultValue())) || handleGetValue() || defaultValue != null
        : false,
    };
    return (
      <Container>
        <StyledInputWrapper $disabled={disabled} {...styledComponentsProps} style={{ padding: 0 }}>
          <SelectContent
            type="button"
            onClick={() => !disabled && setOpenOptions(!openOptions)}
            $isOptionSelected={optionSelected?.label != null}
            $error={styledComponentsProps.$hasError}
          >
            <input
              id={`#select-${name}`}
              {...register(name)}
              placeholder={placeholder}
              value={inputValue}
              onChange={e => {
                setInputValue(e.target.value);
                setOptionSelected(null);
                setOpenOptions(true);
                handleRegister.onChange(e);
              }}
              onBlur={handleBlur}
              onFocus={handleFocus}
              disabled={disabled}
              autoComplete={'off'}
            />

            {!disabled && (openOptions ? <FiChevronUp /> : <FiChevronDown />)}
          </SelectContent>
        </StyledInputWrapper>
        {(styledComponentsProps.$hasError || errorMessage) && (
          <ErrorMessageBox>
            <img src={AlertIcon} style={{ marginRight: '5px' }} />
            <InputErrorMessage isFocused={isFocused} errorMessage={error?.message ?? errorMessage} />
          </ErrorMessageBox>
        )}
        {openOptions && (
          <OptionContainer
            $disabled={!isFocused}
            onScroll={e => {
              const listBox = e.currentTarget;
              if ((listBox.scrollTop + listBox.clientHeight + 1).toFixed() >= listBox.scrollHeight.toFixed()) {
                if (handleOnScroll) {
                  handleOnScroll();
                }
              }
            }}
          >
            {inputValue !== '' ? (
              options?.map(option => (
                <Option
                  key={option.value}
                  type="button"
                  onClick={() => {
                    setInputValue(option.label);
                    setOptionSelected(option);
                    setOpenOptions(false);
                  }}
                >
                  <span>{option.label}</span>
                </Option>
              ))
            ) : defaultOptions ? (
              defaultOptions?.map(option => (
                <Option
                  key={option.label}
                  type="button"
                  onClick={() => {
                    setInputValue(option.label);
                    setOptionSelected(option);
                    setOpenOptions(false);
                  }}
                >
                  <span>{option.label}</span>
                </Option>
              ))
            ) : (
              <></>
            )}
          </OptionContainer>
        )}
      </Container>
    );
  }
);
