import { CheckBoxFill } from 'images/checkbox-fill';
import { CheckBoxOut } from 'images/checkbox-outline';
import { Container } from './styles';

interface Props {
  text: string;
  checked: boolean;
  handleClick: () => void;
}

export const CheckBox = ({ text, checked, handleClick }: Props) => {
  return (
    <Container type="button" $isChecked={checked} onClick={() => handleClick()}>
      {checked ? <CheckBoxFill /> : <CheckBoxOut />}
      <span>{text}</span>
    </Container>
  );
};
