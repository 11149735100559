import CustomButton from 'components/custom-button';
import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import { TextArea } from 'components/styled-input-textarea/styles';
import { Input } from 'components/styled-input/styles';
import Edit from 'images/edit-icon.svg';
import Trash from 'images/trash-icon.svg';
import { ClientComments } from 'model/client-comments';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiTrash2 } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import clientService, { ClientService } from 'services/client-service';
import StringUtils from 'shared/util/string-utils';
import * as S from './styles';
interface Props {
  clientId?: string;
}

const Comments: React.FC<Props> = props => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [comments, setComments] = useState<ClientComments[]>([]);
  const [selectedComment, setSelectedComment] = useState<ClientComments | null>(null);
  const [text, setText] = useState<string>('');
  const [editedText, setEditedText] = useState<string>('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const { t } = useTranslation();
  const account = useSelector((state: IRootState) => state.authentication.account);

  useEffect(() => {
    getClientComments();
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.lastElementChild?.scrollIntoView();
    }
  }, [comments]);

  const getClientComments = async () => {
    if (props.clientId) {
      const res = await clientService.getClientComments(props.clientId);
      if (res) {
        setComments(res);
      }
    }
  };

  const createNewComment = async (event: React.FormEvent) => {
    event.preventDefault();

    if (props.clientId && text) {
      const newComment: ClientComments = {
        text,
        client: {
          id: props.clientId,
        },
      };

      await ClientService()
        .sendClientComments(newComment)
        .then(res => {
          setText('');
          getClientComments();
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const deleteComment = async () => {
    if (selectedComment?.id) {
      await ClientService()
        .deleteClientComment(selectedComment.id)
        .then(res => {
          getClientComments();
        })
        .catch(error => {
          console.log(error);
        });
      setOpenDeleteModal(false);
    }
  };

  const updateComment = async () => {
    if (selectedComment?.id && editedText) {
      const newComment: ClientComments = {
        text: editedText,
        id: selectedComment?.id,
      };

      await ClientService()
        .updateClientComment(newComment)
        .then(res => {
          setEditedText('');
          getClientComments();
        })
        .catch(error => {
          console.log(error);
        });
      setOpenEditModal(false);
    }
  };

  const closeModal = () => {
    setOpenDeleteModal(false);
    setOpenEditModal(false);
  };

  return (
    <>
      <CustomModalTwoButtons
        isShowModal={openDeleteModal}
        title={t('admin.comments.modal.title.delete')}
        firstButtonText={t('global.button.cancel')}
        secondButtonText={t('admin.comments.modal.button.delete')}
        onCloseModal={closeModal}
        handleClickFirstButton={closeModal}
        handleClickSecondButton={deleteComment}
        message={t('admin.comments.modal.message.delete')}
        icon={<FiTrash2 size={60} color={'red'} />}
        freeContent
      />
      <CustomModalTwoButtons
        isShowModal={openEditModal}
        title={t('admin.comments.modal.title.edit')}
        firstButtonText={t('global.button.cancel')}
        secondButtonText={t('admin.comments.modal.button.edit')}
        onCloseModal={closeModal}
        handleClickFirstButton={closeModal}
        handleClickSecondButton={updateComment}
        freeContent
      >
        <div style={{ width: '400px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <TextArea
            name="text"
            title="Comentario"
            placeholder="Escreva seu comentário"
            maxLength={1500}
            value={editedText}
            onChange={e => {
              setEditedText(e.target.value);
            }}
            style={{
              padding: '10px 12px',
              borderRadius: '8px',
              border: '1px solid #CCC',
              height: '150px',
            }}
          />
          <S.CommentTitle>({editedText.length}/1500 caracteres restantes)</S.CommentTitle>
        </div>
      </CustomModalTwoButtons>
      <S.Container>
        <S.ViewCommentContainer ref={containerRef}>
          {comments.map((item, i) => (
            <React.Fragment key={item.id}>
              <S.ClientComment key={item.id}>
                <div style={{ display: 'flex' }}>
                  <S.ClientCommentSender>
                    <span className="name">{item.user?.firstName}</span>
                    <span className="dateTime">{StringUtils.transformDateFormat(item.createdDate ?? '')}</span>
                  </S.ClientCommentSender>
                  <S.ClientCommentText>{item.text}</S.ClientCommentText>
                </div>
                <S.CommentContainerActions>
                  {item.user?.id === account?.id && (
                    <>
                      <S.CommentAction
                        onClick={() => {
                          setSelectedComment(item);
                          setEditedText(item.text);
                          setOpenEditModal(true);
                        }}
                      >
                        <img src={Edit} alt="edit" />
                      </S.CommentAction>
                      <S.CommentAction
                        onClick={() => {
                          setSelectedComment(item);
                          setOpenDeleteModal(true);
                        }}
                      >
                        <img src={Trash} alt="excluir" />
                      </S.CommentAction>
                    </>
                  )}
                </S.CommentContainerActions>
              </S.ClientComment>
              {i < comments.length - 1 && <div className="hr" />}
            </React.Fragment>
          ))}
        </S.ViewCommentContainer>
        <S.CommentContainer>
          <S.CommentTitle>Comentário ({text.length}/1500 caracteres restantes)</S.CommentTitle>
          <form onSubmit={createNewComment}>
            <S.WriteComment>
              <Input
                name="text"
                title="Comentario"
                placeholder="Escreva seu comentário"
                maxLength={1500}
                value={text}
                onChange={e => {
                  setText(e.target.value);
                }}
                style={{
                  padding: '10px 12px',
                  borderRadius: '8px',
                  border: '1px solid #CCC',
                }}
              />
              <CustomButton disabled={text.length === 0}>Enviar</CustomButton>
            </S.WriteComment>
          </form>
        </S.CommentContainer>
      </S.Container>
    </>
  );
};

export default Comments;
