import styled from 'styled-components';

export const Container = styled.span`
  display: flex;
  align-items: center;
  column-gap: 4px;

  padding: 0 6px;
  background-color: ${({ theme }) => theme.color.lightGray1};
  border-radius: 4px;

  color: ${({ theme }) => theme.color.gray22};
  font-size: 14px;
  font-weight: 700;
`;
