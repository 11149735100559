import styled from 'styled-components';

interface IStyledProps {
  $status: string;
}

export const StyledContainer = styled.span<IStyledProps>`
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${({ theme, $status }) => `${theme.color[$status.toLowerCase()]}`};
`;
