import { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { CalendarIcon } from 'images/calendar/index';
import {
  ButtonsContainer,
  CalendarContainer,
  DoubleCalendar,
  FilterItem,
  OptionContainer,
  RadioOptionContainer,
  StyledHr,
  StyledHrVertical,
} from './styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ReactComponent as ChevronDownIconSvg } from 'images/chevron-down.svg';
import { ReactComponent as EllipseIconSvg } from 'images/red-ellipse.svg';
import { formatDateToLocalFormat } from 'shared/util/date-utils';
import { format } from 'date-fns';

interface Props extends WithTranslation {
  placeholder: string;
  onChangeValue: (date: [Date | null, Date | null] | undefined | null) => void;
  onCleanFilter: () => void;
  isActive?: boolean;
  date: [Date | null, Date | null] | undefined;
}

const PeriodFilterCalendar = ({ date, onCleanFilter, placeholder, onChangeValue, isActive, t }: Props) => {
  const [calendarValues, setCalendarValues] = useState<Date | [Date | null, Date | null] | undefined | null>();
  const [showCalendar, setShowCalendar] = useState(false);
  const filterItemRef = useRef<HTMLButtonElement>(null);
  const calendarContainerRef = useRef<HTMLDivElement>(null);

  const currentDate = new Date();
  const previousDay = new Date(currentDate);
  previousDay.setDate(currentDate.getDate() - 1);
  const tomorrowDate = new Date(currentDate);
  tomorrowDate.setDate(currentDate.getDate() + 1);
  const previousMonth = new Date(currentDate);
  previousMonth.setMonth(currentDate.getMonth() - 1);
  const lastSunday = new Date(currentDate);
  const dayOfWeek = currentDate.getDay();
  const daysUntilLastSunday = dayOfWeek === 0 ? 7 : dayOfWeek;
  lastSunday.setDate(currentDate.getDate() - daysUntilLastSunday);
  const sundayBeforeLastSunday = new Date(currentDate);
  sundayBeforeLastSunday.setDate(lastSunday.getDate() - 7);
  const lastSaturday = new Date(currentDate);
  lastSaturday.setDate(lastSunday.getDate() - 1);
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const firstDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
  const lastDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
  const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const firstDayOfLastYear = new Date(currentDate.getFullYear() - 1, 0, 1);
  const lastDayOfLastYear = new Date(currentDate.getFullYear() - 1, 11, 31);

  const options: { label: string; value: [Date | null, Date | null] | undefined }[] = [
    { label: 'Hoje', value: [currentDate, currentDate] },
    { label: 'Ontem', value: [previousDay, previousDay] },
    { label: 'Esta semana', value: [lastSunday, currentDate] },
    { label: 'Semana passada', value: [sundayBeforeLastSunday, lastSaturday] },
    { label: 'Este mês', value: [firstDayOfMonth, currentDate] },
    { label: 'Mês passado', value: [firstDayOfLastMonth, lastDayOfLastMonth] },
    { label: 'Este ano', value: [firstDayOfYear, currentDate] },
    { label: 'Ano passado', value: [firstDayOfLastYear, lastDayOfLastYear] },
  ];

  useEffect(() => {
    onChangeValue(options.find(item => item.label === 'Este mês')?.value);
    setCalendarValues(options.find(item => item.label === 'Este mês')?.value);
  }, []);

  const ApplyfilterDate = () => {
    if (calendarValues instanceof Date) {
      onChangeValue([calendarValues, calendarValues]);
    } else {
      onChangeValue(calendarValues);
    }
  };

  const handleClick = () => {
    setShowCalendar(!showCalendar);
  };

  const generateDateLabel = () => {
    const includedINOptions = options.find(
      option =>
        date?.map(indDate => indDate && format(indDate, 'yyyy-MM-dd')).toString() ==
        option.value?.map(indDate => indDate && format(indDate, 'yyyy-MM-dd')).toString()
    );

    if (includedINOptions) {
      return (
        <>
          {`${placeholder} (${includedINOptions.label})`}
          <EllipseIconSvg />
        </>
      );
    }

    if (date) {
      return (
        <>
          {`${placeholder} (${formatDateToLocalFormat(date[0] ?? '')} - ${formatDateToLocalFormat(date[1] ?? '')})`}
          <EllipseIconSvg />
        </>
      );
    } else {
      return placeholder;
    }
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        calendarContainerRef.current &&
        filterItemRef.current &&
        !calendarContainerRef.current.contains(event.target as Node) &&
        !filterItemRef.current.contains(event.target as Node)
      ) {
        setShowCalendar(false);
      }
    }
    if (showCalendar) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showCalendar]);

  return (
    <OptionContainer>
      <FilterItem onClick={handleClick} ref={filterItemRef}>
        <CalendarIcon isWhite={false} />
        {generateDateLabel()}
        <ChevronDownIconSvg style={{ marginBottom: '-2px' }} />
      </FilterItem>
      {showCalendar && (
        <div>
          <CalendarContainer
            $filterItemOffsetLeft={filterItemRef?.current?.offsetLeft ?? 0}
            $width={filterItemRef?.current?.offsetWidth ?? 0}
            ref={calendarContainerRef}
          >
            <DoubleCalendar>
              <RadioOptionContainer>
                {options.map(option => (
                  <div key={option.label}>
                    <input
                      type="radio"
                      id={option.label}
                      value={option.label}
                      checked={
                        date?.map(indDate => indDate && format(indDate, 'yyyy-MM-dd')).toString() ==
                        option.value?.map(indDate => indDate && format(indDate, 'yyyy-MM-dd')).toString()
                      }
                      onChange={() => {
                        onChangeValue(option.value);
                        setCalendarValues(option.value);
                        setShowCalendar(false);
                      }}
                    />
                    <label htmlFor={option.label}>{option.label}</label>
                  </div>
                ))}
              </RadioOptionContainer>
              <StyledHrVertical />
              <Calendar
                value={calendarValues}
                onChange={value => {
                  setCalendarValues(value);
                }}
                selectRange={calendarValues instanceof Date}
                maxDate={new Date()}
                showDoubleView
              />
            </DoubleCalendar>
            <StyledHr />
            <ButtonsContainer>
              <button
                className="clean"
                onClick={() => {
                  onCleanFilter();
                  onChangeValue(undefined);
                  setShowCalendar(false);
                  setCalendarValues(undefined);
                }}
              >
                {t('global.button.clean')}
              </button>
              <button
                className="apply"
                onClick={() => {
                  setShowCalendar(false);
                  ApplyfilterDate();
                }}
              >
                {t('global.button.apply')}
              </button>
            </ButtonsContainer>
          </CalendarContainer>
        </div>
      )}
    </OptionContainer>
  );
};

export default withTranslation()(PeriodFilterCalendar);
