import { useSendDataToSimulator } from 'components/simulator-data/hooks/use-send-data-to-simulator';
import SimulatorContext, { SimulatorLimit } from 'context/simulator';
import { Client } from 'model/client';
import { CreditEngineStatus } from 'model/enums/credit-engine';
import { LocalStorageKeys } from 'model/enums/local-storage-keys';
import { FinancingSimpleSimulation } from 'model/financing';
import { CreditEngineData, CreditEngineMonjuaResponse } from 'model/landing-page';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import clientDataService from 'services/client-data-service';
import clientService from 'services/client-service';
import landingPageService from 'services/landing-page-service';
import { ProcessingSimulatorData, ProcessingSimulatorHandler } from '..';

export const useMonjuaSendFinancingDataHandler = (): ProcessingSimulatorHandler => {
  const account = useSelector((state: IRootState) => state.authentication.account);
  const { setSimulatorLimits } = useContext(SimulatorContext);
  const { sendDataToSimulator } = useSendDataToSimulator();

  const execute = async (data: ProcessingSimulatorData): Promise<ProcessingSimulatorData> => {
    const { isNewCredit, isAdmin } = data.parameters;
    const { simulatorData } = data;
    const clientIdFromSimulatorData = simulatorData?.client?.id;

    let clientDataForCreditEngine: Client | undefined = await getClientInfo(isAdmin, isNewCredit, clientIdFromSimulatorData);

    if (clientDataForCreditEngine) {
      const sendCreditEngine: CreditEngineData = {
        cpf: clientDataForCreditEngine.physicalPerson?.cpf ?? '',
        name: clientDataForCreditEngine.physicalPerson?.name ?? '',
        birthDate: clientDataForCreditEngine.physicalPerson?.birth ?? '',
        gender: clientDataForCreditEngine.physicalPerson?.genderType ?? '',
        maritalStatus: { id: clientDataForCreditEngine.physicalPerson?.maritalStatus?.id ?? 0 },
        cep: clientDataForCreditEngine.address?.zipcode ?? '',
      };

      let creditEngineResult: CreditEngineMonjuaResponse;

      if (clientDataForCreditEngine.id) {
        creditEngineResult = await landingPageService.creditEngineMonjua(sendCreditEngine, clientDataForCreditEngine.id);
      } else {
        return Promise.reject('No client id');
      }

      const organizationData = await landingPageService.getSimulatorParameters();

      const choosedValue = simulatorData?.choosedValue ?? 0;
      const totalLimit = creditEngineResult?.limite.saldo ?? 0;

      const installmentMin = organizationData.minInstallment ?? 0;

      const foundMaxValue = totalLimit > organizationData.maxValue ? organizationData.maxValue : totalLimit;
      const verifiedChoosedValue = choosedValue > foundMaxValue ? foundMaxValue : choosedValue;
      const installmentsTotal = simulatorData?.installmentsTotal ?? 1;
      const verifiedInstallment = installmentsTotal > installmentMin ? simulatorData?.installmentsTotal : installmentMin;

      const creditStatus = creditEngineResult?.creditEngineStatus;

      switch (creditStatus) {
        case CreditEngineStatus.APPROVED: {
          let simulate: FinancingSimpleSimulation = {
            choosedValue: verifiedChoosedValue,
            installmentsTotal: verifiedInstallment,
            client: { id: simulatorData?.client?.id },
          };

          if (simulatorData?.id) simulate.id = simulatorData?.id;

          await sendDataToSimulator(simulate, isNewCredit, isAdmin);

          const simulatorLimits: SimulatorLimit = {
            newMaxValue: foundMaxValue,
            newMinInstallment: installmentMin,
            newMaxInstallment: creditEngineResult?.maxparcelamento,
          };

          setSimulatorLimits(simulatorLimits);

          data.redirect = {
            confirmSimulation: true,
          };

          break;
        }
        case CreditEngineStatus.REJECTED: {
          let simulateDenied: FinancingSimpleSimulation = {
            choosedValue: simulatorData?.choosedValue,
            installmentsTotal: verifiedInstallment,
            client: { id: simulatorData?.client?.id },
          };

          if (simulatorData?.id) simulateDenied.id = simulatorData?.id;
          await sendDataToSimulator(simulateDenied, isNewCredit, isAdmin);

          const simulatorLimitsDenied = {
            newMaxValue: organizationData.minValue,
            newMinInstallment: installmentMin,
          };

          setSimulatorLimits(simulatorLimitsDenied);
          data.redirect = {
            approved: false,
          };
          break;
        }
      }

      return Promise.resolve(data);
    } else {
      return Promise.reject('No client data found');
    }
  };

  const getClientInfo = async (isAdmin: boolean, isNewCredit: boolean, clientId?: number) => {
    if (isAdmin) {
      return await clientDataService.getClientSummary(clientId);
    } else {
      const storedRegistrationKey = localStorage.getItem(LocalStorageKeys.REGISTRATION_KEY);
      const registrationKey = !isNewCredit ? JSON.parse(storedRegistrationKey ?? '') : null;

      let clientData: Client | undefined = undefined;

      if (!isNewCredit) {
        clientData = await clientService.getClientRegistration(registrationKey);
      } else {
        clientData = account?.client;
      }

      return clientData;
    }
  };

  return {
    execute,
  };
};
