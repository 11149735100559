import { StyledInnerContainer } from './styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import MobileHeader from 'components/mobile-header';
import ReactLoading from 'react-loading';
import { useTheme } from 'styled-components';
import { WaitRegistrationValidationSvg } from 'images/wait-registration-validation';
import { LoadingIcon } from 'images/loadingIcon/loadingIcon';
import { LoadingContainer } from '../styles';
import LoadingAnimation from 'components/loading-big-animation';

const SelfieAnalysis = ({ t }: WithTranslation) => {
  const { color } = useTheme();

  return (
    <>
      <MobileHeader></MobileHeader>
      <StyledInnerContainer>
        <h1>{t('contract.waitingStep.selfieAnalysis.title')}</h1>
        <span>{t('contract.waitingStep.selfieAnalysis.subtitle')}</span>
        <LoadingContainer style={{ marginBottom: '24px' }}>
          <LoadingAnimation />
        </LoadingContainer>

        <div className="waiting-step-footer">{t('contract.footer')}</div>
      </StyledInnerContainer>
    </>
  );
};

export default withTranslation()(SelfieAnalysis);
