import * as S from './styles';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import CustomFilterCalendar from '../custom-filter-calendar';
import CustomSelectionFilter from '../custom-selection-filter';
import { ReactComponent as BriefcaseIconSvg } from 'images/briefcase.svg';
import { ReactComponent as LoaderIconSvg } from 'images/loader.svg';
import { ReactComponent as DollarIconSvg } from 'images/dollar-icon.svg';
import { ReactComponent as DownloadReportIconSvg } from 'images/white-download-report.svg';
import FilterTag from '../filter-tag';
import CustomButton from 'components/custom-button';
import analysisReportService from 'services/analysis-report-service';
import ReportFilterType from 'model/filter-parameters';
import { convertDateTimeFromServer } from 'shared/util/date-utils';
import landingPageService from 'services/landing-page-service';
import { OrganizationSubsidiaries } from 'model/landing-page';
import { FinancingStatus } from 'model/enums/financing-status';
import { ContractStatus } from 'model/enums/contract-status';
import FinancingPaymentType from 'model/enums/financingPaymentType';
import { ORGANIZATION_NAME } from 'config/constants';

interface StatusTypeFilter {
  name: string;
  value: string;
}

interface TypeBooleanFilterData {
  name: string;
  value: boolean;
}

interface FilterType {
  initialCreationDate: Date | null;
  finalCreationDate: Date | null;
  initialAnalysisDate: Date | null;
  finalAnalysisDate: Date | null;
  customerSubsidiaries: OrganizationSubsidiaries[] | null;
  subsidiariesAnalysis: OrganizationSubsidiaries[] | null;
  financingStatus: StatusTypeFilter[] | null;
  contractStatus: StatusTypeFilter[] | null;
  paymentStatus: TypeBooleanFilterData[] | null;
  paymentType: StatusTypeFilter[] | null;
}

const filterInitialValues: FilterType = {
  initialCreationDate: null,
  finalCreationDate: null,
  initialAnalysisDate: null,
  finalAnalysisDate: null,
  customerSubsidiaries: null,
  subsidiariesAnalysis: null,
  financingStatus: null,
  contractStatus: null,
  paymentStatus: null,
  paymentType: null,
};

const Header = ({ t }: WithTranslation) => {
  const [allFilters, setAllFilters] = useState<FilterType>(filterInitialValues);
  const [isShowClearFilterButton, setIsShowClearFilterButton] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchCustomerSubsidiaryName, setSearchCustomerSubsidiaryName] = useState<string>('');
  const [searchSubsidiaryAnalysise, setSearchSubsidiaryAnalysisee] = useState<string>('');
  const [customerSubsidiaries, setCustomerSubsidiaries] = useState<OrganizationSubsidiaries[]>([]);
  const [analysiseSubsidiaries, setAnalysiseSubsidiaries] = useState<OrganizationSubsidiaries[]>([]);

  const filterFinancingStatusValues: StatusTypeFilter[] = [
    {
      name: t(`enum.financingStatus.${FinancingStatus.NEW}`),
      value: FinancingStatus.NEW,
    },
    {
      name: t(`enum.financingStatus.${FinancingStatus.IN_PROGRESS}`),
      value: FinancingStatus.IN_PROGRESS,
    },
    {
      name: t(`enum.financingStatus.${FinancingStatus.VALIDATION}`),
      value: FinancingStatus.VALIDATION,
    },
    {
      name: t(`enum.financingStatus.${FinancingStatus.PENDING_PAYMENT}`),
      value: FinancingStatus.PENDING_PAYMENT,
    },
    {
      name: t(`enum.financingStatus.${FinancingStatus.PENDING_SIGN}`),
      value: FinancingStatus.PENDING_SIGN,
    },
    { name: t(`enum.financingStatus.${FinancingStatus.ADJUST}`), value: FinancingStatus.ADJUST },
    { name: t(`enum.financingStatus.${FinancingStatus.ADJUSTED}`), value: FinancingStatus.ADJUSTED },
    { name: t(`enum.financingStatus.${FinancingStatus.APPROVED}`), value: FinancingStatus.APPROVED },
    { name: t(`enum.financingStatus.${FinancingStatus.FINISHED}`), value: FinancingStatus.FINISHED },
    { name: t(`enum.financingStatus.${FinancingStatus.REJECTED}`), value: FinancingStatus.REJECTED },
    { name: t(`enum.financingStatus.${FinancingStatus.CANCELLED}`), value: FinancingStatus.CANCELLED },
  ];

  const filterContractStatusValues: StatusTypeFilter[] = [
    {
      name: t(`enum.contractStatus.${ContractStatus.NEW}`),
      value: ContractStatus.NEW,
    },
    {
      name: t(`enum.contractStatus.${ContractStatus.IN_PROGRESS}`),
      value: ContractStatus.IN_PROGRESS,
    },
    {
      name: t(`enum.contractStatus.${ContractStatus.FINISHED}`),
      value: ContractStatus.FINISHED,
    },
    {
      name: t(`enum.contractStatus.${FinancingStatus.CANCELLED}`),
      value: FinancingStatus.CANCELLED,
    },
  ];

  const filterStatusPaymentValues: TypeBooleanFilterData[] = [
    {
      name: t('enum.paymentStatus.PAID'),
      value: true,
    },
    {
      name: t('enum.paymentStatus.NOT_PAID'),
      value: false,
    },
  ];

  const filterPaymentTypeValues: StatusTypeFilter[] = [
    {
      name: t('report.customer'),
      value: FinancingPaymentType.CLIENT,
    },
    {
      name: ORGANIZATION_NAME != null ? ORGANIZATION_NAME?.charAt(0).toUpperCase() + ORGANIZATION_NAME?.slice(1) : '',
      value: FinancingPaymentType.ORGANIZATION,
    },
  ];

  useEffect(() => {
    const doYouHaveFilterFilled = Object.values(allFilters)
      .map(value => (value != null ? true : false))
      .includes(true);

    if (doYouHaveFilterFilled) {
      setIsShowClearFilterButton(true);
    } else {
      setIsShowClearFilterButton(false);
    }
  }, [allFilters]);

  useEffect(() => {
    getCustomerSubsidiaries();
  }, [searchCustomerSubsidiaryName]);

  useEffect(() => {
    getSubsidiariesAnalysis();
  }, [searchSubsidiaryAnalysise]);

  const handleGenerateReport = () => {
    setIsLoading(true);
    const filters: ReportFilterType = {};

    if (allFilters?.initialCreationDate != null) {
      filters['financing.created_date.start'] = convertDateTimeFromServer(allFilters.initialCreationDate);
    }

    if (allFilters?.finalCreationDate != null) {
      filters['financing.created_date.end'] = convertDateTimeFromServer(allFilters.finalCreationDate);
    }

    if (allFilters?.initialAnalysisDate != null) {
      filters['contractSign.finish_date.start'] = convertDateTimeFromServer(allFilters.initialAnalysisDate);
    }

    if (allFilters?.finalAnalysisDate != null) {
      filters['contractSign.finish_date.end'] = convertDateTimeFromServer(allFilters.finalAnalysisDate);
    }

    if (allFilters?.customerSubsidiaries != null && allFilters.customerSubsidiaries.length > 0) {
      filters['client.organizationSubsidiary.id'] = allFilters.customerSubsidiaries.map(value => value.id).toString();
    }

    if (allFilters?.subsidiariesAnalysis != null && allFilters.subsidiariesAnalysis.length > 0) {
      filters['organizationSubsidiary.name'] = allFilters.subsidiariesAnalysis.map(value => value.name).toString();
    }

    if (allFilters?.financingStatus != null && allFilters.financingStatus.length > 0) {
      filters['financing.financingStatus'] = allFilters.financingStatus.map(value => value.value).toString();
    }

    if (allFilters?.contractStatus != null && allFilters.contractStatus.length > 0) {
      filters['contract.status'] = allFilters.contractStatus.map(value => value.value).toString();
    }

    if (allFilters?.paymentStatus != null && allFilters?.paymentStatus.length > 0) {
      filters['financing.isPaid'] = allFilters.paymentStatus[0].value;
    }

    if (allFilters?.paymentType != null && allFilters?.paymentType.length > 0) {
      filters['financing.financingPaymentType'] = allFilters.paymentType.map(value => value.value).toString();
    }

    analysisReportService.generateReport(filters).then(response => {
      const file = new Blob([response.data], { type: 'text/csv' });
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(file);
      a.download = 'report.csv';
      document.body.appendChild(a);
      a.click();
      setIsLoading(false);
    });
  };

  const getCustomerSubsidiaries = () => {
    landingPageService
      .getOrganizationSubsidiaries({ name: searchCustomerSubsidiaryName })
      .then(response => setCustomerSubsidiaries(response.slice(0, 10)));
  };

  const getSubsidiariesAnalysis = () => {
    landingPageService
      .getOrganizationSubsidiaries({ name: searchSubsidiaryAnalysise })
      .then(response => setAnalysiseSubsidiaries(response.slice(0, 10)));
  };

  const cleanFilters = () => {
    setAllFilters(filterInitialValues);
    setIsShowClearFilterButton(false);
  };

  return (
    <>
      <S.HeaderContainer>
        <S.Title>{t('admin.dashboard.report')}</S.Title>
        <CustomButton width="200px" onClick={() => handleGenerateReport()} loading={isLoading}>
          <DownloadReportIconSvg />
          {t('reportScreen.generateReport')}
        </CustomButton>
      </S.HeaderContainer>

      <S.FilterContainer>
        <S.FilterContent>
          <CustomFilterCalendar
            placeholder={t('reportScreen.filter.initialCreationDate')}
            onChangeValue={date => setAllFilters({ ...allFilters, initialCreationDate: date })}
            isActive={allFilters?.initialCreationDate != null}
            date={allFilters.initialCreationDate}
            onCleanFilter={() => setAllFilters({ ...allFilters, initialCreationDate: null })}
          />
          <CustomFilterCalendar
            placeholder={t('reportScreen.filter.finalCreationDate')}
            onChangeValue={date => setAllFilters({ ...allFilters, finalCreationDate: date })}
            isActive={allFilters?.finalCreationDate != null}
            date={allFilters.finalCreationDate}
            onCleanFilter={() => setAllFilters({ ...allFilters, finalCreationDate: null })}
          />
          <CustomFilterCalendar
            placeholder={t('reportScreen.filter.initialAnalysisDate')}
            onChangeValue={date => setAllFilters({ ...allFilters, initialAnalysisDate: date })}
            isActive={allFilters?.initialAnalysisDate != null}
            date={allFilters.initialAnalysisDate}
            onCleanFilter={() => setAllFilters({ ...allFilters, initialAnalysisDate: null })}
          />
          <CustomFilterCalendar
            placeholder={t('reportScreen.filter.finalAnalysisDate')}
            onChangeValue={date => setAllFilters({ ...allFilters, finalAnalysisDate: date })}
            isActive={allFilters?.finalAnalysisDate != null}
            date={allFilters.finalAnalysisDate}
            onCleanFilter={() => setAllFilters({ ...allFilters, finalAnalysisDate: null })}
          />
          {customerSubsidiaries.length > 0 && (
            <CustomSelectionFilter
              icon={<BriefcaseIconSvg />}
              placeholder={t('reportScreen.filter.branchAnalysis')}
              setSearch={setSearchCustomerSubsidiaryName}
              filterData={customerSubsidiaries}
              keyDisplay="name"
              listSelectedItems={(subsidiaries: OrganizationSubsidiaries[] | null) =>
                setAllFilters({ ...allFilters, customerSubsidiaries: subsidiaries })
              }
              onCleanFilter={() => setAllFilters({ ...allFilters, customerSubsidiaries: null })}
              isActive={allFilters?.customerSubsidiaries != null && allFilters.customerSubsidiaries.length > 0}
            />
          )}
          {analysiseSubsidiaries.length > 0 && (
            <CustomSelectionFilter
              icon={<BriefcaseIconSvg />}
              placeholder={t('reportScreen.filter.customerBranch')}
              setSearch={setSearchSubsidiaryAnalysisee}
              filterData={analysiseSubsidiaries}
              keyDisplay="name"
              listSelectedItems={(subsidiaries: OrganizationSubsidiaries[] | null) =>
                setAllFilters({ ...allFilters, subsidiariesAnalysis: subsidiaries })
              }
              onCleanFilter={() => setAllFilters({ ...allFilters, subsidiariesAnalysis: null })}
              isActive={allFilters?.subsidiariesAnalysis != null && allFilters.subsidiariesAnalysis.length > 0}
            />
          )}
          <CustomSelectionFilter
            icon={<LoaderIconSvg />}
            placeholder={t('reportScreen.filter.financingStatus')}
            filterData={filterFinancingStatusValues}
            keyDisplay="name"
            listSelectedItems={(status: StatusTypeFilter[] | null) => setAllFilters({ ...allFilters, financingStatus: status })}
            onCleanFilter={() => setAllFilters({ ...allFilters, financingStatus: null })}
            isActive={allFilters?.financingStatus != null && allFilters.financingStatus.length > 0}
          />
          <CustomSelectionFilter
            icon={<LoaderIconSvg />}
            placeholder={t('reportScreen.filter.contractStatus')}
            filterData={filterContractStatusValues}
            keyDisplay="name"
            listSelectedItems={(status: StatusTypeFilter[] | null) => setAllFilters({ ...allFilters, contractStatus: status })}
            onCleanFilter={() => setAllFilters({ ...allFilters, contractStatus: null })}
            isActive={allFilters?.contractStatus != null && allFilters.contractStatus.length > 0}
          />
          <CustomSelectionFilter
            icon={<DollarIconSvg />}
            placeholder={t('reportScreen.filter.typePayment')}
            filterData={filterPaymentTypeValues}
            keyDisplay="name"
            listSelectedItems={(status: StatusTypeFilter[] | null) => setAllFilters({ ...allFilters, paymentType: status })}
            onCleanFilter={() => setAllFilters({ ...allFilters, paymentType: null })}
            isActive={allFilters?.paymentType != null && allFilters.paymentType.length > 0}
          />
          <CustomSelectionFilter
            onlySelectOneElement
            icon={<LoaderIconSvg />}
            placeholder={t('reportScreen.filter.paymentStatus')}
            filterData={filterStatusPaymentValues}
            keyDisplay="name"
            listSelectedItems={(status: TypeBooleanFilterData[] | null) => setAllFilters({ ...allFilters, paymentStatus: status })}
            onCleanFilter={() => setAllFilters({ ...allFilters, paymentStatus: null })}
            isActive={allFilters?.paymentStatus != null && allFilters.paymentStatus.length > 0}
          />
        </S.FilterContent>
      </S.FilterContainer>

      <S.FilterTagContainer>
        <S.FilterTagInnerContainer>
          {Object.keys(allFilters).map((value, index) => {
            if (allFilters[value] != null) {
              if (Array.isArray(allFilters[value])) {
                const isArrayOfObjects = allFilters[value].every(item => Object.prototype.hasOwnProperty.call(item, 'name'));

                if (isArrayOfObjects && allFilters[value].length > 0) {
                  const namesArray = allFilters[value].map(item => item.name);

                  return (
                    <FilterTag
                      key={`tag-${index}`}
                      text={t(`reportScreen.filter.tag.${value}`, {
                        value: namesArray.join(', '),
                      })}
                      onClick={() => setAllFilters({ ...allFilters, [value]: null })}
                    />
                  );
                } else {
                  return <></>;
                }
              }

              return (
                <FilterTag
                  key={`tag-${index}`}
                  text={t(`reportScreen.filter.tag.${value}`, {
                    value:
                      typeof allFilters[value] === 'object' && allFilters[value].toLocaleDateString
                        ? allFilters[value].toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })
                        : allFilters[value],
                  })}
                  onClick={() => setAllFilters({ ...allFilters, [value]: null })}
                />
              );
            }
          })}
        </S.FilterTagInnerContainer>
        {isShowClearFilterButton && <S.Button onClick={() => cleanFilters()}>{t('reportScreen.filter.clearFilters')}</S.Button>}
      </S.FilterTagContainer>
    </>
  );
};

export default withTranslation()(Header);
