import { StyledInnerContainer, CentralizedContainer, IconSuccessContainer } from './styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import MobileHeader from 'components/mobile-header';
import { ReactComponent as XRedCircle } from 'images/x-red-circle.svg';

const ErrorAnalysis = ({ t }: WithTranslation) => {
  return (
    <>
      <MobileHeader />
      <StyledInnerContainer>
        <h1>{t('contract.waitingStep.errorAnalysis.title')}</h1>
        <span>{t('contract.waitingStep.errorAnalysis.subtitle')}</span>

        <span style={{ marginTop: '8px' }}>{t('contract.waitingStep.waitNewLiberation.doubts')}</span>
        <IconSuccessContainer>
          <XRedCircle />
        </IconSuccessContainer>
        <div className="waiting-step-footer">{t('contract.footer')}</div>
      </StyledInnerContainer>
    </>
  );
};

export default withTranslation()(ErrorAnalysis);
