import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { FiCalendar } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { Button, CalendarContainer, Container } from './styles';

interface CalendarInputProps {
  placeholder: string;
  onChangeValue: (date: Date) => void;
  defaultValue?: string;
  reset?: boolean;
}

export const CalendarInput = ({ placeholder, onChangeValue, defaultValue, reset }: CalendarInputProps) => {
  const { color } = useTheme();
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState<Date | null>(null);

  useEffect(() => {
    if (reset) {
      setDate(null);
    }
  }, [reset]);

  return (
    <Container>
      <Button onClick={() => setShowCalendar(!showCalendar)}>
        <span>{date ? date.toLocaleDateString() : defaultValue ? defaultValue : placeholder}</span>
        <FiCalendar color={color.gray22} size={20} />
      </Button>
      {showCalendar && (
        <CalendarContainer>
          <Calendar
            onChange={value => {
              if (value instanceof Date) {
                if (onChangeValue) {
                  onChangeValue(value);
                }
                setDate(value);
                setShowCalendar(false);
              }
            }}
            value={date}
            maxDate={new Date()}
          />
        </CalendarContainer>
      )}
    </Container>
  );
};
