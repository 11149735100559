import {
  StyledButton,
  StyledContainer,
  StyledContractContainer,
  StyledFooter,
  StyledLoadingContainer,
  StyledPdfDownloadButton,
} from './styles';
import { StyledHeader, StyledHeaderContainer } from './styles';
import { ReactComponent as DownloadIconSvg } from 'images/download-icon.svg';
import { ReactComponent as PrintIconSvg } from 'images/print-icon.svg';
import { useTranslation, withTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useEffect, useState, useContext, useRef } from 'react';
import CustomButton from 'components/custom-button';
import contractService from 'services/contract-service';
import ContractContext from 'context/contract';
import { Logo } from 'images/custom-organization';
import { TypeAttachment } from 'model/credit-card';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { Document, Page } from 'react-pdf';
import LoadingDocument from 'components/loading-document';
import { RoutePath } from 'model/enums/route-path';
import { isEmpty } from 'lodash';
import { PersonSignatureStatusTypes } from 'model/enums/person-signature-status';
import IContractDetails from 'model/contract-details';
import CustomComponentModal from 'components/custom-conmponent-modal';

const ViewContractToSign = () => {
  const { t } = useTranslation();
  const urlParams = useParams();
  const history = useHistory();
  const signatureKey = urlParams['signatureKey'];
  const { isMobile, isTablet } = useResponsiveMobile();

  const {
    localization,
    signatoryIp,
    setContractResponse,
    setDocumentPresignUrl,
    setSignatureKey,
    getGeolocalization,
    getLocationByIp,
    getSignatoryIp,
    setSignatoryDocument,
    contractResponse,
  } = useContext(ContractContext);

  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);
  const [modalLocalization, setModalLocalization] = useState<boolean>(false);
  const [attachedDocument, setAttachedDocument] = useState<TypeAttachment>();
  const [documentBase64, setDocumentBase64] = useState<ArrayBuffer>();
  const [numPages, setNumPages] = useState<number>(0);

  const handleDownloadDocument = async (contentType: string, documentBase64: ArrayBuffer, fileName?: string) => {
    const blob = new Blob([documentBase64], { type: contentType });
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = fileName ?? '';
    link.click();
  };

  const handleConvertingDocumentToBase64 = async (attachment: TypeAttachment) => {
    if (attachment?.presignedUrl != null) {
      const response = await fetch(attachment.presignedUrl);
      const blob = await response.blob();
      const base64 = await blob.arrayBuffer();
      setDocumentBase64(base64);
    }
  };

  const handlePrintDocument = (url: string) => {
    const newWindow = window.open(url, '_blank');
    newWindow!.addEventListener('load', () => {
      return newWindow!.print();
    });
  };

  const lestSignDocumets = () => {
    return history.push('/lets-sign');
  };

  useEffect(() => {
    if (!isMobile && !isTablet) {
      return history.push(`/qr-code/${signatureKey}`);
    }

    if (!signatureKey) {
      return history.push('/');
    }

    if (signatureKey !== null) {
      getGeolocalization();
      getSignatoryIp();
      contractService
        .getContract(`${signatureKey}`)
        .then(response => {
          if (response) {
            handleInitialRedirect(response);

            setContractResponse(response);
            setSignatoryDocument(response.contractSignatory?.signatory?.documentNumber);
            setSignatureKey(response.contractSignatory?.signatureKey);

            setAttachedDocument(response.contractSignatory?.contractSign?.contract.attachment);
            setDocumentPresignUrl(response.contractSignatory?.contractSign?.contract.attachment.presignedUrl);

            if (response.contractSignatory?.contractSign?.contract.attachment != null) {
              handleConvertingDocumentToBase64(response.contractSignatory?.contractSign?.contract.attachment);
            }
          }
        })
        .catch(() => history.push(RoutePath.SUBSCRIPTION_EXPIRED))
        .finally(() => setIsLoadingPage(false));
    }
  }, []);

  useEffect(() => {
    let toastTimeout: NodeJS.Timeout | null = null;
    if (localization.latitude == null || localization.longitude == null) {
      toastTimeout = setTimeout(() => setModalLocalization(true), 10000);
    }
    return () => {
      if (toastTimeout != null) {
        clearTimeout(toastTimeout);
      }
    };
  }, [localization]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleInitialRedirect = (contract: IContractDetails) => {
    if (
      contract.personSignatureStatus === PersonSignatureStatusTypes.SENT_FOR_SIGNATURE &&
      contract?.unicoProcess?.statusProcess === 'FRAUD_NEGATIVE' &&
      contract.isLivenessDone &&
      contract.isDocumentOcrDone &&
      contract.isFaceMatchDone
    ) {
      history.push(RoutePath.SIGNATURE_ANALYSIS);
    }

    if (contract?.processFinished) {
      return history.push(RoutePath.SUBSCRIPTION_EXPIRED);
    }

    if (contract?.personSignatureStatus === PersonSignatureStatusTypes.CANCELED) {
      return history.push(RoutePath.SIGNATURE_CANCELED);
    }

    if (
      (contract.personSignatureStatus === PersonSignatureStatusTypes.SENT_FOR_SIGNATURE && contract.canRetry === false) ||
      (contract.personSignatureStatus === PersonSignatureStatusTypes.DOCUMENT_ADMIN_CHECK && contract.canRetry === false) ||
      (contract.personSignatureStatus === PersonSignatureStatusTypes.BIOMETRIC_ADMIN_CHECK && contract.canRetry === false) ||
      (contract.personSignatureStatus === PersonSignatureStatusTypes.BIOMETRIC_COMPANY_CHECK && contract.canRetry === false) ||
      (contract.personSignatureStatus === PersonSignatureStatusTypes.SENT_FOR_SIGNATURE &&
        (contract?.unicoProcess?.statusProcess === 'FRAUD_CONFIRMED' ||
          contract?.unicoProcess?.statusProcess === 'FRAUD_POSSIBLE' ||
          contract?.unicoProcess?.statusProcess === 'FRAUD_NEUTRAL' ||
          contract?.unicoProcess?.statusProcess === 'CANCELLED' ||
          contract?.unicoProcess?.statusProcess === 'PROBLEM' ||
          contract?.unicoProcess?.statusProcess === 'ERROR' ||
          contract?.unicoProcess?.statusProcess === 'FRAUD_NO_EVIDENCES')) ||
      (contract.canRetry &&
        contract.personSignatureStatus === PersonSignatureStatusTypes.SENT_FOR_SIGNATURE &&
        contract?.unicoProcess?.statusProcess === 'FRAUD_NEGATIVE' &&
        (contract.isLivenessDone === false || contract.isDocumentOcrDone === false || contract.isFaceMatchDone === false))
    ) {
      return history.push(RoutePath.SIGNATURE_RELEASE);
    }
  };

  return (
    <StyledContainer>
      {isLoadingPage ? (
        <StyledLoadingContainer style={{ height: '100vh', alignItems: 'initial' }}>
          <LoadingDocument />
        </StyledLoadingContainer>
      ) : (
        <>
          <StyledHeaderContainer>
            <StyledHeader>
              <Logo isWhite={false} />
              <div>
                <StyledPdfDownloadButton
                  onClick={() => {
                    if (attachedDocument?.contentType != null && documentBase64) {
                      handleDownloadDocument(attachedDocument?.contentType, documentBase64, attachedDocument.fileName);
                    }
                  }}
                >
                  <DownloadIconSvg />
                </StyledPdfDownloadButton>
                <StyledButton
                  disabled={false}
                  onClick={() => !!attachedDocument?.presignedUrl && handlePrintDocument(attachedDocument?.presignedUrl)}
                >
                  <PrintIconSvg />
                </StyledButton>
              </div>
            </StyledHeader>
          </StyledHeaderContainer>

          <StyledContractContainer>
            <Document
              file={contractResponse?.contractSignatory?.contractSign?.contract.attachment.presignedUrl}
              loading={<LoadingDocument />}
              onLoadSuccess={onDocumentLoadSuccess}
              noData=""
            >
              {[...Array(numPages)]
                .map((_, i) => i + 1)
                .map(page => (
                  <Page
                    key={`page-${page}`}
                    pageNumber={page}
                    scale={0.6}
                    loading=""
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                  />
                ))}
            </Document>
          </StyledContractContainer>
          {modalLocalization && (
            <CustomComponentModal open={modalLocalization} setOpen={setModalLocalization}>
              <b>{t('global.message.locationNotFound')}</b>
              <div style={{ margin: '24px 0' }}>{t('global.message.youMustAllowLocation')}</div>
              <CustomButton
                style={{ width: '100%', justifyContent: 'center', borderRadius: '8px' }}
                onClick={async () => {
                  getGeolocalization();
                  getLocationByIp();
                  setModalLocalization(false);
                }}
              >
                {t('global.button.tryAgain')}
              </CustomButton>
            </CustomComponentModal>
          )}

          <StyledFooter>
            <CustomButton
              className="mobile-button"
              loading={localization.latitude == null || localization.longitude == null || isEmpty(signatoryIp)}
              onClick={() => lestSignDocumets()}
            >
              {t('global.button.sign')}
            </CustomButton>
          </StyledFooter>
        </>
      )}
    </StyledContainer>
  );
};

export default withTranslation()(ViewContractToSign);
