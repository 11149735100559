import styled from 'styled-components';

export const StyledContainer = styled.div`
  height: 100%;
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.color.gray18};
  text-align: left;
  /* background-color: ${({ theme }) => theme.color.grayBackground};s */
`;

export const StyledInnerContainer = styled.div`
  max-width: 470px;
  margin: 20px auto 0 auto;
  padding: 0 16px;
`;

export const StyledText = styled.div`
  margin: 20px 0;
`;

export const SvgContainer = styled.div`
  margin-top: 32px;
  text-align: center;
`;

export const StyledSvgContainer = styled.div`
  margin-top: 32px;
  text-align: center;

  svg {
    width: 40%;
  }
`;

export const StyledMainTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: #000000;
`;

export const CommentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 5px;
  width: 470px;
  max-height: 110px;
  background: #fafafa;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  overflow: auto;
  max-width: 100%;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #656666;
  }
`;

export const AdjustButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ConfirmButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Dividider = styled.div`
  width: 1px;
  height: 48px;
  background: ${({ theme }) => theme.color.gray20};
  margin: 0px 20px;
  margin-top: 20px;
`;
