import { Authority } from 'model/enums/authority';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';

export const useAuthorities = () => {
  const authorities = useSelector((state: IRootState) => state.authentication.account?.authorities);

  const allowedFunctions = [
    Authority.ROLE_ADMIN,
    Authority.ROLE_INTERNAL_ADMIN,
    Authority.ROLE_MANAGER,
    Authority.ROLE_SELLER,
    Authority.ROLE_FINANCIAL,
  ];
  const isDashboardAdmin = allowedFunctions.some(role => authorities?.includes(role));

  const hasAuthorities = (roles: Authority[]) => {
    return roles.some(role => authorities?.includes(role));
  };

  return {
    isDashboardAdmin,
    hasAuthorities,
  };
};
