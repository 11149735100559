import { CalendarInput } from 'components/calendar-input';
import { CustomCheckBox } from 'components/custom-checkbox';
import { CreatedDates } from 'model/date';
import moment from 'moment';
import { useAdmin } from 'provider/admin-general';
import { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import { FiFilter } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import { FilterItem } from '../title-header/styles';
import { ButtonsContainer, CalendarInputsContainer, Container, DropDownFilter, FilterBox, SectionTitle, StyledPopUp } from './styles';
import { Ordertype } from 'model/enums/order-type';

interface FilterButtonProps {
  filterStatus: string[];
  onCleanFilter: () => void;
  onAppliedFilters: () => void;
  orderBy?: boolean;
  setOrderBy?: (value: boolean) => void;
}

export const FilterButton = ({ filterStatus, onCleanFilter, onAppliedFilters, orderBy, setOrderBy }: FilterButtonProps) => {
  const { color } = useTheme();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const {
    statusFilters,
    handleSetNewStatus,
    resetStatusFilters,
    handleSetCreatedDates,
    resetCreatedDates,
    setSaveCalendarDates,
    saveCalendarDates,
  } = useAdmin();
  const [dateSelect, setDateSelect] = useState<number | null>(null);
  const [calendarDates, setCalendarDates] = useState<CreatedDates | null>(null);

  const checkFilterIsActive = (filter: string) => {
    const filterActive = statusFilters.find(status => status === filter);

    if (filterActive) {
      return true;
    }

    return false;
  };

  const checkDateSelect = (value: number) => {
    if (dateSelect === value) {
      return true;
    }

    return false;
  };

  const subtractDate = (days: number) => {
    const today = new Date();
    const sub = new Date().setDate(today.getDate() - days);
    return new Date(sub).toISOString().split('T')[0];
  };

  useEffect(() => {
    if (calendarDates?.gte && calendarDates.lte) {
      handleSetCreatedDates(calendarDates);
      setSaveCalendarDates(calendarDates);
    }
  }, [calendarDates]);

  return (
    <StyledPopUp
      trigger={
        <Container>
          <button onClick={() => setIsOpen(!isOpen)}>
            <FiFilter color={color.white} size={20} />
            <span>{t('admin.dashboard.filter')}</span>
          </button>{' '}
        </Container>
      }
      position="bottom center"
      on="click"
      arrow={false}
    >
      {() => (
        <DropDownFilter>
          <FilterBox>
            <SectionTitle>{t('admin.dashboard.sectionFilters.date')}</SectionTitle>
            <CalendarInputsContainer>
              <CalendarInput
                defaultValue={saveCalendarDates?.gte ? moment(saveCalendarDates.gte).format('DD/MM/YYYY') : undefined}
                placeholder="Inicial"
                onChangeValue={date => {
                  setDateSelect(null);
                  setCalendarDates({ gte: date.toISOString().split('T')[0], lte: calendarDates?.lte });
                }}
                reset={dateSelect != null}
              />
              <CalendarInput
                defaultValue={saveCalendarDates?.lte ? moment(saveCalendarDates.lte).format('DD/MM/YYYY') : undefined}
                placeholder="Final"
                onChangeValue={date => {
                  setDateSelect(null);
                  setCalendarDates({ lte: date.toISOString().split('T')[0], gte: calendarDates?.gte });
                }}
                reset={dateSelect != null}
              />
            </CalendarInputsContainer>
            <CustomCheckBox
              text={t('admin.dashboard.dateFilters.lastUpdates')}
              value={'1'}
              checked={checkDateSelect(1)}
              reset={dateSelect !== 1}
              onHandleClick={value => {
                if (value.check) {
                  const today = new Date();
                  handleSetCreatedDates({
                    lte: today.toISOString().split('T')[0],
                    gte: subtractDate(3),
                  });
                  setDateSelect(1);
                }
              }}
            />
            <CustomCheckBox
              text={t('admin.dashboard.dateFilters.last15Days')}
              value={'2'}
              checked={checkDateSelect(2)}
              reset={dateSelect !== 2}
              onHandleClick={value => {
                if (value.check) {
                  const today = new Date();
                  handleSetCreatedDates({
                    lte: today.toISOString().split('T')[0],
                    gte: subtractDate(15),
                  });
                  setDateSelect(2);
                }
              }}
            />
            <CustomCheckBox
              text={t('admin.dashboard.dateFilters.last30Days')}
              value={'3'}
              checked={checkDateSelect(3)}
              reset={dateSelect !== 3}
              onHandleClick={value => {
                if (value.check) {
                  const today = new Date();
                  handleSetCreatedDates({
                    lte: today.toISOString().split('T')[0],
                    gte: subtractDate(30),
                  });
                  setDateSelect(3);
                }
              }}
            />
          </FilterBox>
          <FilterBox>
            <SectionTitle className="status">{t('admin.dashboard.orderByFilters.orderBy')}</SectionTitle>
            <FilterItem
              $isActive
              onClick={() => {
                if (setOrderBy) {
                  setOrderBy(!orderBy);
                }
              }}
            >
              {t(`admin.dashboard.orderByFilters.${orderBy ? Ordertype.DESC : Ordertype.ASC}`)}
            </FilterItem>
          </FilterBox>
          <FilterBox>
            <SectionTitle className="status">{t('admin.dashboard.sectionFilters.status')}</SectionTitle>
            {filterStatus.map((status, idx) => (
              <CustomCheckBox
                key={idx}
                text={t(`enum.filterAllStatus.${status}`)}
                value={status}
                checked={checkFilterIsActive(status)}
                reset={statusFilters.length === 0}
                onHandleClick={value => {
                  if (value.check) {
                    handleSetNewStatus([...statusFilters, value.value]);
                  } else {
                    handleSetNewStatus(statusFilters.filter(filter => filter !== value.value));
                  }
                }}
              />
            ))}
          </FilterBox>
          <ButtonsContainer>
            <button
              className="clean"
              onClick={() => {
                resetStatusFilters();
                resetCreatedDates();
                setDateSelect(null);
                setSaveCalendarDates(null);
                onCleanFilter();
                if (setOrderBy) {
                  setOrderBy(false);
                }
              }}
            >
              {t('global.button.clean')}
            </button>
            <button
              className="apply"
              onClick={() => {
                if (dateSelect != null) {
                  setCalendarDates(null);
                  setSaveCalendarDates(null);
                }
                onAppliedFilters();
              }}
            >
              {t('global.button.apply')}
            </button>
          </ButtonsContainer>
        </DropDownFilter>
      )}
    </StyledPopUp>
  );
};
