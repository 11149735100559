import { ButtonsContainer, FormInputsContainer, Row } from 'components/edit-forms/styles';
import EnterCustomButton from 'components/enter-custom-button';
import { SearchSelectInput } from 'components/search-selected-input';
import InputText from 'components/styled-input';
import { TextInput } from 'components/text-input';
import { Mask } from 'model/enums/mask-types';
import { useRegisterForm } from 'provider/register-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { referencesType } from 'shared/util/select-utils';

export const ContainerInputsReferences = props => {
  const { t } = useTranslation();
  const { initialClientData, isFirstForm, isLastForm, onClickBack, getCepData, isLoading, isCepLoading } = useRegisterForm();
  const {
    states,
    cities,
    stateID,
    handleSetStateID,
    getStates,
    getCities,
    statesTotalPages,
    citiesTotalPages,
    getResidenceType,
    residenceType,
  } = useSelectLists();

  const [statePage, setStatePage] = useState(0);
  const [cityPage, setCityPage] = useState(0);
  const [stateName, setStateName] = useState('');
  const [stateAc, setStateAc] = useState('');
  const [cityName, setCityName] = useState('');
  const [resetCities, setResetCities] = useState(false);
  const [selectedResidenceType, setSelectedResidenceType] = useState('');

  useEffect(() => {
    getResidenceType();
  }, []);

  useEffect(() => {
    getStates({ name: stateName, page: statePage, size: 7, ac: stateAc }, true);
  }, [statePage]);

  useEffect(() => {
    if (stateID) {
      getCities(stateID, { name: cityName, page: cityPage, size: 7 }, true);
    }
  }, [cityPage]);

  useEffect(() => {
    if (initialClientData?.address?.zipcode) {
      getCepData(initialClientData?.address?.zipcode);
    }
  }, []);

  return (
    <FormInputsContainer $visible={props.visible}>
      <Row>
        <InputText isRequired name="name" label={t('register.inputs.name')} placeholder={t('register.placeholder.name')} />
        <TextInput name="referenceType" label={t('register.inputs.referenceType')} isRequired hasCustomInput>
          <SearchSelectInput
            name="referenceType"
            placeholder={t('register.placeholder.referenceType')}
            options={referencesType}
            defaultOptions={referencesType}
          />
        </TextInput>
        <InputText
          isRequired
          name="phone"
          label={t('register.inputs.phone')}
          placeholder={t('register.placeholder.phone')}
          mask={Mask.PHONE}
          maxLength={15}
        />
      </Row>
      <Row>
        <InputText
          name="homePhone"
          label={t('register.inputs.homePhone')}
          placeholder={t('register.placeholder.homePhone')}
          mask={Mask.PHONE}
          maxLength={15}
        />
        <InputText
          name="workPhone"
          label={t('register.inputs.workPhone')}
          placeholder={t('register.placeholder.workPhone')}
          mask={Mask.PHONE}
          maxLength={15}
        />
      </Row>
      <ButtonsContainer>
        <EnterCustomButton type="submit" isLoading={isLoading} text={t('global.button.add')} />
      </ButtonsContainer>
    </FormInputsContainer>
  );
};
