import partnersApi from 'api/partnersApi';
import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';

export interface PartnersBalance {
  balanceAmountSum: number;
  blockedAmountSum: number;
  toReleaseAmountSum: number;
  balanceParameters: [
    {
      id: number;
      balanceAmount: number;
      balanceRequestTransactionAmount: number;
      balanceTransactionAmount: number;
      assignmentFee: number;
      organization: {
        id: number;
      };
      organizationSubsidiary: {
        id: number;
        name: string;
      };
    },
  ];
}

export const partnersService = () => {
  const getBalances = async (): Promise<PartnersBalance> => {
    try {
      const result: AxiosResponse<PartnersBalance> = await partnersApi.getBalances();
      if (result.data != null && result.status === HttpStatus.OK) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  return {
    getBalances,
  };
};

export default partnersService();
