import { ReactComponent as ArrowLeft } from 'images/chevron-left.svg';
import { ReactComponent as ArrowRight } from 'images/chevron-right.svg';
import { ReactComponent as DoubleArrowLeft } from 'images/chevrons-left.svg';
import { ReactComponent as DoubleArrowRight } from 'images/chevrons-right.svg';
import _ from 'lodash';
import { PageableResponse } from 'model/pageable';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { EmptyList } from '../empty-list';
import {
  Button,
  ButtonsContainer,
  ButtonsPage,
  Column,
  Container,
  Header,
  InputPage,
  Pagination,
  Row,
  Separator,
  SpanPagination,
  Table,
} from './styles';

export interface TableId {
  first: number;
  second?: number;
}
export interface TableValues {
  header: string;
  values: string[] | JSX.Element[] | (string | JSX.Element)[];
  ids?: TableId[];
  onClick?: (ids?: TableId) => void;
  fullLength?: boolean;
  maxWidth?: string;
}

interface TableListProps {
  tableValues: TableValues[];
  page?: number;
  signaturesResponse?: PageableResponse<any>;
  setNewPage?: (page: number) => void;
  noPagination?: boolean;
  loadingTable?: boolean;
  setLoadingTable?: Dispatch<SetStateAction<boolean>>;
}

export const NewTableList = ({
  tableValues,
  signaturesResponse,
  page,
  setNewPage,
  noPagination,
  loadingTable,
  setLoadingTable,
}: TableListProps) => {
  const { color } = useTheme();
  const [currentPage, setCurrentPage] = useState('1');
  const [isEmpty, setIsEmpty] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const values = tableValues.map(table => table.values).flat();
    if (values.length <= 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, [tableValues]);

  useEffect(() => {
    if (page != null) {
      setCurrentPage((page + 1).toString());
    }
  }, [page]);

  const onHandleChangePage = (value: string) => {
    if (setLoadingTable != null) {
      setLoadingTable(true);
    }

    const newPage = Number(value);

    if (Number.isNaN(newPage)) {
      return;
    }

    if (newPage === 0) {
      return setCurrentPage('');
    }

    if (signaturesResponse?.totalPages && newPage > signaturesResponse?.totalPages) {
      if (page != null) {
        return setCurrentPage((page + 1).toString());
      }
    }

    setCurrentPage(newPage.toString());
    handleSetNewPage(newPage);
  };

  const handleSetNewPage = _.debounce((newPage: number) => {
    if (setNewPage) {
      if (signaturesResponse?.totalPages && newPage >= signaturesResponse?.totalPages) {
        setNewPage(signaturesResponse?.totalPages - 1);
      }

      setNewPage(newPage - 1);
    }
  }, 500);

  const onHandleClickChangePage = (direction: 'left' | 'right' | 'doubleLeft' | 'doubleRight') => {
    const newPage =
      direction === 'left'
        ? Number(currentPage) - 1
        : direction === 'doubleLeft'
          ? 1
          : direction === 'right'
            ? Number(currentPage) + 1
            : direction === 'doubleRight'
              ? Number(signaturesResponse?.totalPages)
              : Number(currentPage);

    if (setNewPage && newPage >= 1 && signaturesResponse?.totalPages && newPage <= signaturesResponse?.totalPages) {
      setCurrentPage(newPage.toString());
      setNewPage(newPage - 1);
    }
  };

  if (isEmpty && !noPagination) {
    return <EmptyList />;
  }

  return (
    <Container style={loadingTable ? { opacity: '0.5' } : {}}>
      <Table $internal={true}>
        {tableValues.map(table => (
          <Column key={table.header} $maxWidth={table?.maxWidth}>
            <Header>{table.header}</Header>
            <Separator />
            {table.values.map((row, index) => (
              <div key={`row-${index}`}>
                <Row
                  onClick={() => {
                    if (table.onClick && table.ids) {
                      table.onClick(table.ids[index]);
                    }
                  }}
                  key={index}
                  $blank={row === ''}
                  $fullLength={table.fullLength}
                >
                  <span>{row === '' ? '.' : row}</span>
                </Row>
                {index !== table.values.length - 1 && <Separator />}
              </div>
            ))}
          </Column>
        ))}
      </Table>
      {signaturesResponse != null && (
        <Pagination>
          <div>
            {t('admin.dashboard.totalResults', {
              results: signaturesResponse?.numberOfElements?.toString(),
              totalResults: signaturesResponse?.totalElements?.toString(),
            })}
          </div>
          {!noPagination && (
            <ButtonsContainer>
              <ButtonsPage>
                <Button className="left" onClick={() => onHandleClickChangePage('doubleLeft')}>
                  <DoubleArrowLeft />
                </Button>
                <Button className="left" onClick={() => onHandleClickChangePage('left')}>
                  <ArrowLeft />
                </Button>
              </ButtonsPage>

              {signaturesResponse?.totalPages && signaturesResponse?.totalPages > 5 ? (
                currentPage === '1' || currentPage === '2' ? (
                  <InputPage>
                    {[...Array(3)].map((_, idx) => (
                      <SpanPagination
                        key={`spanPagination-${idx}`}
                        className={currentPage === (idx + 1).toString() ? 'clicked' : ''}
                        onClick={e => onHandleChangePage((idx + 1).toString())}
                      >
                        {(idx + 1).toString()}
                      </SpanPagination>
                    ))}
                    <SpanPagination>{'...'}</SpanPagination>
                    <SpanPagination
                      className={currentPage === (signaturesResponse?.totalPages - 1).toString() ? 'clicked' : ''}
                      onClick={e => signaturesResponse?.totalPages && onHandleChangePage((signaturesResponse?.totalPages - 1).toString())}
                    >
                      {signaturesResponse?.totalPages && signaturesResponse?.totalPages - 1}
                    </SpanPagination>
                    <SpanPagination
                      className={currentPage === signaturesResponse?.totalPages.toString() ? 'clicked' : ''}
                      onClick={e => signaturesResponse?.totalPages && onHandleChangePage(signaturesResponse?.totalPages?.toString())}
                    >
                      {signaturesResponse?.totalPages}
                    </SpanPagination>
                  </InputPage>
                ) : currentPage === signaturesResponse?.totalPages.toString() ||
                  currentPage === (signaturesResponse?.totalPages - 1).toString() ? (
                  <InputPage>
                    {[...Array(2)].map((_, idx) => (
                      <SpanPagination
                        key={`spanpagination2-${idx}`}
                        className={currentPage === (idx + 1).toString() ? 'clicked' : ''}
                        onClick={e => onHandleChangePage((idx + 1).toString())}
                      >
                        {(idx + 1).toString()}
                      </SpanPagination>
                    ))}
                    <SpanPagination>{'...'}</SpanPagination>
                    <SpanPagination
                      className={currentPage === (signaturesResponse?.totalPages - 2).toString() ? 'clicked' : ''}
                      onClick={e => signaturesResponse?.totalPages && onHandleChangePage((signaturesResponse?.totalPages - 2).toString())}
                    >
                      {signaturesResponse?.totalPages && signaturesResponse?.totalPages - 2}
                    </SpanPagination>
                    <SpanPagination
                      className={currentPage === (signaturesResponse?.totalPages - 1).toString() ? 'clicked' : ''}
                      onClick={e => signaturesResponse?.totalPages && onHandleChangePage((signaturesResponse?.totalPages - 1).toString())}
                    >
                      {signaturesResponse?.totalPages && signaturesResponse?.totalPages - 1}
                    </SpanPagination>
                    <SpanPagination
                      className={currentPage === signaturesResponse?.totalPages.toString() ? 'clicked' : ''}
                      onClick={e => signaturesResponse?.totalPages && onHandleChangePage(signaturesResponse?.totalPages?.toString())}
                    >
                      {signaturesResponse?.totalPages}
                    </SpanPagination>
                  </InputPage>
                ) : (
                  <InputPage>
                    <SpanPagination className={currentPage === '1' ? 'clicked' : ''} onClick={e => onHandleChangePage('1')}>
                      {'1'}
                    </SpanPagination>
                    {currentPage !== '3' && <SpanPagination>{'...'}</SpanPagination>}

                    <SpanPagination
                      onClick={e => signaturesResponse?.totalPages && onHandleChangePage((Number(currentPage) - 1).toString())}
                    >
                      {Number(currentPage) - 1}
                    </SpanPagination>
                    <SpanPagination className="clicked">{currentPage}</SpanPagination>
                    <SpanPagination
                      onClick={e => signaturesResponse?.totalPages && onHandleChangePage((Number(currentPage) + 1).toString())}
                    >
                      {Number(currentPage) + 1}
                    </SpanPagination>

                    {currentPage !== (signaturesResponse?.totalPages - 2).toString() && <SpanPagination>{'...'}</SpanPagination>}
                    <SpanPagination
                      className={currentPage === signaturesResponse?.totalPages.toString() ? 'clicked' : ''}
                      onClick={e => signaturesResponse?.totalPages && onHandleChangePage(signaturesResponse?.totalPages?.toString())}
                    >
                      {signaturesResponse?.totalPages}
                    </SpanPagination>
                  </InputPage>
                )
              ) : (
                <InputPage>
                  {[...Array(signaturesResponse?.totalPages)].map((_, idx) => (
                    <SpanPagination
                      key={`spanpagination3-${idx}`}
                      className={currentPage === (idx + 1).toString() ? 'clicked' : ''}
                      onClick={e => onHandleChangePage((idx + 1).toString())}
                    >
                      {(idx + 1).toString()}
                    </SpanPagination>
                  ))}
                </InputPage>
              )}

              <ButtonsPage>
                <Button className="right" onClick={() => onHandleClickChangePage('right')}>
                  <ArrowRight />
                </Button>
                <Button className="right" onClick={() => onHandleClickChangePage('doubleRight')}>
                  <DoubleArrowRight />
                </Button>
              </ButtonsPage>
            </ButtonsContainer>
          )}
        </Pagination>
      )}
    </Container>
  );
};
