import Faq from './faq/index';
import FlexibleStructure from './flexible-structure/index';
import Footer from './footer';
import Form from './form';
import Header from './header';
import Near from './near';
import { StyledPage } from './styles';

interface LandingPageContainerProps {}

const LandingPageContainer: React.FC<LandingPageContainerProps> = () => {
  return (
    <>
      <StyledPage>
        <Header />
        <Form />
        <Near />
      </StyledPage>
      <StyledPage $customStyle={{ background: '#EDF1FF' }}>
        <Faq />
      </StyledPage>
      <StyledPage $customStyle={{ background: 'linear-gradient(to bottom, #EDF1FF 0, #EDF1FF 50%, #0524DD 50%)' }}>
        <FlexibleStructure />
      </StyledPage>
      <StyledPage $customStyle={{ background: '#0524DD' }}>
        <Footer />
      </StyledPage>
    </>
  );
};

export default LandingPageContainer;
