import { AxiosResponse } from 'axios';
import { api } from './api';
import {
  AddBalanceResponse,
  BalanceRequest,
  BalanceRequestTransactions,
  BalanceTransactions,
  IdsArray,
  IdsArrayResponse,
  PaymentInfo,
} from 'model/my-account';
import { PaginationTotal } from 'model/pageable';
import { CreatedDates } from 'model/date';

export interface FilterParams {
  searchName?: string;
  size?: number;
  page?: number;
  paymentStatus?: string[];
  createdDates?: CreatedDates;
}

export const myAccountApi = () => {
  const getBalances = (subisidiaryId?: string): Promise<AxiosResponse<any>> => {
    if (subisidiaryId) {
      return api.get<any>(`/balances?subsidiaryId=${subisidiaryId}`);
    } else {
      return api.get<any>(`/balances`);
    }
  };

  const addBalance = (amount: BalanceRequest, subisidiaryId?: string): Promise<AxiosResponse<AddBalanceResponse>> => {
    if (subisidiaryId) {
      return api.post<AddBalanceResponse>(`/balance-request-transactions?subsidiaryId=${subisidiaryId}`, amount);
    } else {
      return api.post<AddBalanceResponse>(`/balance-request-transactions`, amount);
    }
  };

  const getBalancesTransactions = (
    subisidiaryId?: string,
    filter?: FilterParams
  ): Promise<AxiosResponse<PaginationTotal<BalanceTransactions>>> => {
    const filterParams = `&sort=createdDate,desc&size=${filter?.size ?? 8}&page=${filter?.page ?? 0}&financing.ccb=${
      filter?.searchName ?? ''
    }${
      filter?.paymentStatus && filter?.paymentStatus.length > 0
        ? filter?.paymentStatus.map(status => `&status=contains(${status})`).join('')
        : ''
    }${filter?.createdDates?.gte ? `&createdDate=gte(${filter?.createdDates.gte}T00:00:00)` : ''}${
      filter?.createdDates?.lte ? `&createdDate=and(lte(${filter?.createdDates.lte}T24:00:00))` : ''
    }`;

    return api.get<PaginationTotal<BalanceTransactions>>(`/balance-transactions?subsidiaryId=${subisidiaryId ?? ''}${filterParams}`);
  };

  const getBalancesRequestTransactions = (
    subisidiaryId?: string,
    filter?: FilterParams
  ): Promise<AxiosResponse<PaginationTotal<BalanceRequestTransactions>>> => {
    const filterParams = `&sort=createdDate,desc&size=${filter?.size ?? 8}&page=${filter?.page ?? 0}&depositNumber=${
      filter?.searchName ?? ''
    }${
      filter?.paymentStatus && filter?.paymentStatus.length > 0
        ? filter?.paymentStatus.map(status => `&status=contains(${status})`).join('')
        : ''
    }${filter?.createdDates?.gte ? `&createdDate=gte(${filter?.createdDates.gte}T00:00:00)` : ''}${
      filter?.createdDates?.lte ? `&createdDate=and(lte(${filter?.createdDates.lte}T24:00:00))` : ''
    }`;

    return api.get<PaginationTotal<BalanceRequestTransactions>>(
      `/balance-request-transactions?subsidiaryId=${subisidiaryId ?? ''}${filterParams}`
    );
  };

  const getpaymentInfo = (paymentId?: string): Promise<AxiosResponse<PaymentInfo>> => {
    return api.get<PaymentInfo>(`/balance-request-transactions/${paymentId}`);
  };

  const cancelTransaction = (paymentId?: string): Promise<AxiosResponse<PaymentInfo>> => {
    return api.patch<PaymentInfo>(`/balance-request-transactions/cancel/${paymentId}`);
  };

  const downloadPDF = (ids?: IdsArray): Promise<AxiosResponse<IdsArrayResponse>> => {
    return api.post<IdsArrayResponse>(`/credit-assignments`, ids);
  };

  const applyBalance = (id: number): Promise<AxiosResponse<any>> => {
    return api.post<any>(`/balance-request-transactions/apply/${id}`);
  };

  return {
    getBalances,
    addBalance,
    getBalancesTransactions,
    getBalancesRequestTransactions,
    getpaymentInfo,
    cancelTransaction,
    downloadPDF,
    applyBalance,
  };
};

export default myAccountApi();
