import styled from 'styled-components';

export const ModalContainer = styled.div`
  max-width: 336px;
  margin-top: 16px;

  font-size: 20px;
  font-weight: 700;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 16px;
  margin-top: 24px;
`;
