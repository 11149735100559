import styled from 'styled-components';

export const InputContent = styled.div`
  width: 306px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  > label + label {
    margin-top: 35px;
  }
`;

export const FormContainer = styled.form`
  label {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-width: 306px;
    min-height: 53px;
  }
  label + label {
    margin-top: 16px;
  }
`;
