import { SearchSelectInput } from 'components/search-selected-input';
import InputText from 'components/styled-input';
import { TextInput } from 'components/text-input';
import { IPix } from 'model/bank-account';
import { PixType } from 'model/enums/payment-type';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleIndex, StyledDeleteButton, StyledPixContainer } from './styles';
import { ReactComponent as TrashIcon } from 'images/trash-2.svg';
import StringUtils, { validateCNPJ, validateCpf, validatePhone } from 'shared/util/string-utils';
import { UseFormReturn } from 'react-hook-form';

interface PixComponentProps {
  defaultPix?: IPix;
  pixesArray: IPix[];
  setPixesArray: Dispatch<SetStateAction<IPix[]>>;
  index: number;
  disabled?: boolean;
}

const pixOptions = [
  { label: 'CPF', value: PixType.CPF },
  { label: 'CNPJ', value: PixType.CNPJ },
  { label: 'E-mail', value: PixType.EMAIL },
  { label: 'Número de telefone', value: PixType.PHONE },
  { label: 'Chave aleatória', value: PixType.RANDOM_KEY },
];

const ValidatePixFormats = {
  [PixType.CNPJ]: validateCNPJ,
  [PixType.CPF]: validateCpf,
  [PixType.EMAIL]: StringUtils.isEmailValid,
  [PixType.PHONE]: validatePhone,
  [PixType.DOCUMENT]: () => true,
  [PixType.RANDOM_KEY]: () => true,
};

const PixComponent: React.FC<PixComponentProps> = props => {
  const { t } = useTranslation();

  const [pixKey, setPixKey] = useState<string>();
  const [pixType, setPixType] = useState<string>();
  const [defaultValuePixType, setDefaultValuePixType] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    let newPixesArray = props.pixesArray;
    newPixesArray[props.index] = {
      pixKey: pixKey,
      pixType: pixType as PixType,
    };
    props.setPixesArray(newPixesArray);
  }, [pixKey, pixType]);

  useEffect(() => {
    if (props.defaultPix) {
      setPixKey(props.defaultPix.pixKey);
      setPixType(props.defaultPix.pixType);

      setDefaultValuePixType(pixOptions.find(item => item.value === props.defaultPix?.pixType)?.label ?? '');
    }
  }, []);

  useEffect(() => {
    if (pixKey && pixType) {
      !ValidatePixFormats[pixType](pixKey) ? setErrorMessage(t('global.errorMessage.pixTypeIncompatible')) : setErrorMessage(undefined);
    }
  }, [pixKey]);

  const deletepix = () => {
    let newArray = [...props.pixesArray];
    let indexNewArray = props.index;
    setDefaultValuePixType('');
    if (indexNewArray !== -1) {
      newArray.splice(indexNewArray, 1);
      props.setPixesArray(newArray);
    }
  };

  return (
    <StyledPixContainer>
      <StyleIndex>{props.index + 1}</StyleIndex>
      <TextInput name={`pixType-${props.index}`} label={t('register.inputs.pixType')} hasCustomInput>
        <SearchSelectInput
          name={`pixType-${props.defaultPix?.pixType}-${props.index}`}
          placeholder={t('register.placeholder.selectPixType')}
          options={pixOptions}
          defaultOptions={pixOptions}
          handleChange={values => {
            if (values.value) {
              setPixType(values.value);
            }
          }}
          defaultValue={pixOptions.find(item => item.value === props.defaultPix?.pixType)?.label ?? ''}
          disabled={props.disabled}
        />
      </TextInput>
      <InputText
        disabled={props.disabled}
        defaultValue={props.defaultPix?.pixKey}
        onChange={e => {
          setPixKey(e.target.value);
        }}
        name={`pixKey-${props.defaultPix?.pixKey}-${props.index}`}
        label={t('register.inputs.pixKey')}
        placeholder={t('register.placeholder.insertPix')}
        mask={pixType}
        errorMessage={errorMessage}
      />
      <StyledDeleteButton disabled={props.disabled} type="button" onClick={() => deletepix()}>
        <TrashIcon />
      </StyledDeleteButton>
    </StyledPixContainer>
  );
};

export default PixComponent;
