import { CalendarOption } from 'components/calendar-option';
import { ORGANIZATION_NAME } from 'config/constants';
import AdminFiltersContext from 'context/admin-filters';
import { Authority } from 'model/enums/authority';
import { OrganizationName } from 'model/enums/organization-name';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { hasAuthorities } from 'shared/util/utils';
import { FilterButton } from '../filter-button';
import SearchInput from '../search-input';
import { ButtonContainer, Container, FilterContainer, FilterContent, FilterItem, Title } from './styles';
import { NewCalendarOption } from 'components/calendar-option-new';
import { StatusFilterButton } from '../filter-button-status';
import { OrderFilterButton } from '../filter-button-order';
import { SearchInputOptions } from '../search-input-options';
import { CreatedDates } from 'model/date';
import { useAdmin } from 'provider/admin-general';

interface TitleHeaderProps {
  title: string;
  setSearch?: (value: string) => void;
  setSearchOption?: (value: string) => void;
  searchOption?: string;
  setSubsidiarySearch?: (value: string) => void;
  setFilters?: () => void;
  cleanFilters?: () => void;
  setOrderBy?: (value: boolean) => void;
  filterStatusValues?: string[];
  customButton?: JSX.Element;
  orderBy?: boolean;
}

export const NewTitleHeader = ({
  title,
  setSearch,
  setSearchOption,
  searchOption,
  setSubsidiarySearch,
  setFilters,
  filterStatusValues,
  customButton,
  cleanFilters,
  orderBy,
  setOrderBy,
}: TitleHeaderProps) => {
  const { t } = useTranslation();
  const [gte, setGte] = useState<Date | null>(null);
  const [lte, setLte] = useState<Date | null>(null);
  const [calendarDates, setCalendarDates] = useState<CreatedDates | null>(null);

  const { filters, updateStatusFilters, updateDateRange } = useContext(AdminFiltersContext);
  const { handleSetCreatedDates, resetCreatedDates, setSaveCalendarDates, saveCalendarDates } = useAdmin();

  const [dateSelect, setDateSelect] = useState<number | null>(null);
  const account = useSelector((state: IRootState) => state.authentication.account);

  const handleFilterStatus = (status: string) => {
    let newFilters: string[] = [];

    if (status !== '') {
      newFilters = [...filters.statusFilters.filter(filter => filter !== '')];
      if (newFilters.includes(status)) {
        newFilters = newFilters.filter(filter => filter !== status);
        if (ORGANIZATION_NAME === OrganizationName.HCRED) {
          newFilters = [''];
        }
      } else {
        if (ORGANIZATION_NAME === OrganizationName.HCRED) {
          newFilters = [status];
        } else {
          newFilters.push(status);
        }
      }
    }

    if (newFilters.length === 0) {
      newFilters = [''];
    }
    updateStatusFilters(newFilters);
  };

  const handleFilterDate = (date: globalThis.Date, type) => {
    setDateSelect(null);
    updateDateRange({ [type]: date.toISOString().split('T')[0] });
    setCalendarDates({ [type]: date.toISOString().split('T')[0] });
  };

  useEffect(() => {
    if (calendarDates?.gte || calendarDates?.lte) {
      handleSetCreatedDates(calendarDates);
      setSaveCalendarDates(calendarDates);
    }
  }, [calendarDates]);

  const activeGte = filters.dateRange.gte !== '';
  const activeLte = filters.dateRange.lte !== '';

  const areAllFiltersUnselected = () => {
    return filters.statusFilters.every(filter => filter === '');
  };

  const renderSearchButton = () => {
    if (ORGANIZATION_NAME === OrganizationName.HCRED || ORGANIZATION_NAME === OrganizationName.MONJUA) {
      if (hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN])) {
        return <SearchInput title={t('admin.dashboard.searchSubsidiaries')} setSearch={setSubsidiarySearch} />;
      }
    }
  };

  const renderContent = () => {
    switch (ORGANIZATION_NAME) {
      case OrganizationName.NOVOSAQUE:
        return (
          <>
            <Title $changeLayout={ORGANIZATION_NAME === OrganizationName.NOVOSAQUE}>{title}</Title>
            <FilterContainer>
              <FilterContent>
                <StatusFilterButton
                  filterStatus={filterStatusValues ? filterStatusValues : []}
                  onAppliedFilters={() => {
                    if (setFilters) {
                      setFilters();
                    }
                  }}
                  onCleanFilter={() => {
                    if (cleanFilters) {
                      cleanFilters();
                    }
                  }}
                />
                <NewCalendarOption
                  defaultValue={filters.dateRange?.gte ? moment(filters.dateRange?.gte).format('DD/MM/YYYY') : undefined}
                  placeholder={t(`enum.filterAllStatus.START_DATE`)}
                  onChangeValue={date => handleFilterDate(date, 'gte')}
                  reset={dateSelect != null}
                  isActive={activeGte}
                  date={gte}
                  setDate={setGte}
                  onCleanFilter={() => {
                    updateDateRange({ gte: '', lte: '' });
                    setGte(null);
                    setLte(null);
                  }}
                />
                <NewCalendarOption
                  defaultValue={filters.dateRange?.lte ? moment(filters.dateRange?.lte).format('DD/MM/YYYY') : undefined}
                  placeholder={t(`enum.filterAllStatus.END_DATE`)}
                  onChangeValue={date => handleFilterDate(date, 'lte')}
                  reset={dateSelect != null}
                  isActive={activeLte}
                  date={lte}
                  setDate={setLte}
                  onCleanFilter={() => {
                    updateDateRange({ gte: '', lte: '' });
                    setGte(null);
                    setLte(null);
                  }}
                />
                <OrderFilterButton />
              </FilterContent>
              <ButtonContainer>
                {renderSearchButton()}
                {searchOption && setSearchOption && (
                  <SearchInputOptions searchOption={searchOption} setSearchOption={setSearchOption} setSearch={setSearch} />
                )}
              </ButtonContainer>
            </FilterContainer>
          </>
        );
      case OrganizationName.HCRED:
        return (
          <>
            <Title $changeLayout={ORGANIZATION_NAME === OrganizationName.HCRED}>
              {title}
              <ButtonContainer>
                {renderSearchButton()}
                <SearchInput setSearch={setSearch} />
              </ButtonContainer>
            </Title>
            <FilterContent>
              <FilterItem
                $isActive={!activeLte && !activeGte}
                onClick={() => {
                  updateDateRange({ gte: '', lte: '' });
                  setGte(null);
                  setLte(null);
                }}
              >
                {t(`enum.filterAllStatus.ALLDATES`)}
              </FilterItem>
              <CalendarOption
                defaultValue={filters.dateRange?.gte ? moment(filters.dateRange?.gte).format('DD/MM/YYYY') : undefined}
                placeholder={t(`enum.filterAllStatus.START_DATE`)}
                onChangeValue={date => handleFilterDate(date, 'gte')}
                reset={dateSelect != null}
                isActive={activeGte}
                date={gte}
                setDate={setGte}
              />
              <CalendarOption
                defaultValue={filters.dateRange?.lte ? moment(filters.dateRange?.lte).format('DD/MM/YYYY') : undefined}
                placeholder={t(`enum.filterAllStatus.END_DATE`)}
                onChangeValue={date => handleFilterDate(date, 'lte')}
                reset={dateSelect != null}
                isActive={activeLte}
                date={lte}
                setDate={setLte}
              />
              <FilterItem
                $isActive={orderBy === false}
                onClick={() => {
                  setOrderBy?.(!orderBy);
                }}
              >
                {t(`admin.dashboard.orderByFilters.asc`)}
              </FilterItem>
              <FilterItem
                $isActive={orderBy === true}
                onClick={() => {
                  setOrderBy?.(!orderBy);
                }}
              >
                {t(`admin.dashboard.orderByFilters.desc`)}
              </FilterItem>
            </FilterContent>
            <FilterContent>
              <FilterItem $isActive={areAllFiltersUnselected()} onClick={() => updateStatusFilters([])}>
                {t(`enum.filterAllStatus.ALLSTATUS`)}
              </FilterItem>
              {filterStatusValues?.map((status, idx) => (
                <FilterItem
                  key={`${status}${idx}`}
                  $isActive={filters.statusFilters.includes(status)}
                  onClick={() => handleFilterStatus(status)}
                >
                  {t(`enum.filterAllStatus.${status}`)}
                </FilterItem>
              ))}
            </FilterContent>
          </>
        );

      default:
        return (
          <>
            <Title>{title}</Title>
            <ButtonContainer>
              {renderSearchButton()}
              <SearchInput setSearch={setSearch} />
              {filterStatusValues && (
                <FilterButton
                  filterStatus={filterStatusValues}
                  onAppliedFilters={() => {
                    if (setFilters) {
                      setFilters();
                    }
                  }}
                  onCleanFilter={() => {
                    if (cleanFilters) {
                      cleanFilters();
                    }
                  }}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                />
              )}
              {customButton && !filterStatusValues && customButton}
            </ButtonContainer>
          </>
        );
    }
  };
  return (
    <Container $changeLayout={ORGANIZATION_NAME === OrganizationName.HCRED || ORGANIZATION_NAME === OrganizationName.NOVOSAQUE}>
      {renderContent()}
    </Container>
  );
};
