import { Status } from 'features/admin/dashboard/components/status';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputStatus, StyledError, StyledInput, StyledInputBox, StyledLabel } from './styled';

interface StyledType {
  borderRadius?: boolean;
  borderRight?: boolean;
}

export enum AdminInputType {
  TEXT = 'text',
  STATUS = 'status',
  VIEW = 'view',
  DEFAULT = 'default',
  UPLOAD = 'upload',
}

interface AdminInputProps {
  styled?: StyledType;
  label: string;
  name: string;
  htmlFor: string;
  required?: boolean;
  formData?: any;
  setFormData?: (formData) => void;
  maxLength?: number;
  mask?: (value: string) => string;
  addressError?: string;
  type: string;
  status?: string;
  statusColor?: string;
  calcWidth?: string;
  isList?: boolean;
  position?: number;
  onBlur?: (e) => void;
}

const AdminInput: React.FC<AdminInputProps> = ({
  styled,
  label,
  name,
  required,
  htmlFor,
  addressError,
  formData,
  setFormData,
  maxLength,
  mask,
  type,
  status,
  statusColor,
  calcWidth,
  isList,
  onBlur,
  position,
}) => {
  const isActive = formData?.[name]?.length > 0;

  const { t } = useTranslation();
  const verifyInputType = () => {
    switch (type) {
      case AdminInputType.VIEW: {
        return <InputStatus>{mask ? mask(formData?.[name]) : formData?.[name]}</InputStatus>;
      }
      case AdminInputType.STATUS: {
        return (
          <InputStatus>
            <Status text={t(`enum.financingStatus.${status}`)} color={statusColor ?? ''} />
          </InputStatus>
        );
      }

      default: {
        const { register, reset, setValue, control } = useFormContext();

        const {
          fieldState: { error },
        } = useController({ name, control });

        const handleClick = () => {
          setFormData?.({ ...formData, [name]: '' });
          reset({ [name]: '' });
        };

        const handleChange = (e, key) => {
          if (mask) {
            setFormData?.({ ...formData, [key]: mask(e.target.value) });
            setValue(key, mask(e.target.value));
          } else {
            setFormData?.({ ...formData, [key]: e.target.value });
            setValue(key, e.target.value);
          }
        };

        const handleChangeList = (e, key) => {
          if (mask) {
            setValue(key, mask(e.target.value));
            setFormData?.(prevListaObjetos => {
              const updatedObjectList = [...prevListaObjetos];
              updatedObjectList[position ?? 0] = {
                ...updatedObjectList[position ?? 0],
                [key]: mask(e.target.value),
              };
              return updatedObjectList;
            });
          } else {
            setValue(key, e.target.value);
            setFormData?.(prevListaObjetos => {
              const updatedObjectList = [...prevListaObjetos];
              updatedObjectList[position ?? 0] = {
                ...updatedObjectList[position ?? 0],
                [key]: e.target.value,
              };
              return updatedObjectList;
            });
          }
        };

        return (
          <>
            <StyledInput
              id={`#admin-input-${name}`}
              {...register(name, { required: required })}
              onChange={e => (isList ? handleChangeList(e, name) : handleChange(e, name))}
              isActive={isActive}
              hasError={!!error?.message}
              name={name}
              value={formData?.[name] ?? ''}
              maxLength={maxLength}
              autoComplete={'off'}
              onBlur={onBlur}
            />
            {error && <StyledError>{error?.message}</StyledError>}
          </>
        );
      }
    }
  };

  return (
    <StyledInputBox borderRadius={styled?.borderRadius} calcWidth={calcWidth}>
      <StyledLabel htmlFor={htmlFor} isActive={isActive}>
        {label}
      </StyledLabel>
      {verifyInputType()}
    </StyledInputBox>
  );
};

export default AdminInput;
