import styled from 'styled-components';

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.color.grayBackground};
  height: 100vh;
  overflow: auto;
`;

export const Container = styled.div`
  max-width: 470px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 48px;

  > span {
    width: 100%;
    text-align: left;
  }
`;
