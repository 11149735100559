import CustomButton from 'components/custom-button';
import Slider from 'components/slider';
import { Authority } from 'model/enums/authority';
import { RoutePath } from 'model/enums/route-path';
import { SimulatorParameters } from 'model/landing-page';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import landingPageService from 'services/landing-page-service';
import { unMaskedCurrency } from 'shared/util/register-utils';
import StringUtils from 'shared/util/string-utils';
import {
  DesiredTime,
  DesiredValueButtons,
  DesiredValueContainer,
  SimulatorContainer,
  SimulatorTitle,
  SliderBoxTag,
  SliderContainer,
  SliderTag,
  SliderValue,
  StyledMainTitle,
  StyledPreSimulatorButton,
  StyledSubtitle,
  StyledText,
} from './styles';
import { SimulatorImages } from 'images/simulator';
import TypesPlusMinus from 'model/enums/types-plus-minus';
import { SimulatorProcessingMoment, SimulatorState } from 'components/simulator-data/processing/hooks/use-simulator-state';
import { FinancingSimpleSimulation } from 'model/financing';

interface SimulatorFinancingProps {
  clientId?: number;
}

const SimulatorFinancing = ({ clientId }: SimulatorFinancingProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [simulatorSaveData, setSimulatorSaveData] = useState<FinancingSimpleSimulation>({
    client: { id: clientId ? clientId : useSelector((state: IRootState) => state.authentication.account?.client?.id) },
  });

  const [simulatorData, setSimulatorData] = useState<SimulatorParameters>();
  const [value, setValue] = useState<number>();
  const authorities = useSelector((state: IRootState) => state.authentication.account?.authorities);

  useEffect(() => {
    landingPageService.getSimulatorParameters().then(response => {
      setSimulatorData(response);
      setValue(Number(response?.minValue?.toFixed(2)));
      setSimulatorSaveData({ ...simulatorSaveData, choosedValue: response.minValue });
    });
  }, []);

  useEffect(() => {
    if (simulatorSaveData?.choosedValue === undefined && simulatorData) {
      setSimulatorSaveData({
        ...simulatorSaveData,
        choosedValue: simulatorData.minValue,
      });
    }
    if (!simulatorSaveData?.installmentsTotal && simulatorData) {
      setSimulatorSaveData({
        ...simulatorSaveData,
        installmentsTotal: simulatorData?.simulatorInstallmentOptions?.[0]?.installmentValue,
      });
    }
  }, [simulatorData, simulatorSaveData]);

  const allowedFunctions = [
    Authority.ROLE_ADMIN,
    Authority.ROLE_INTERNAL_ADMIN,
    Authority.ROLE_MANAGER,
    Authority.ROLE_SELLER,
    Authority.ROLE_FINANCIAL,
  ];
  const isAdmin = allowedFunctions.some(role => authorities?.includes(role));

  const postSimulator = () => {
    if (simulatorSaveData != null && value != null) {
      const simulatorState: SimulatorState = {
        isNewCredit: true,
        sendSimulator: {
          installmentsTotal: simulatorSaveData.installmentsTotal,
          choosedValue: simulatorSaveData.choosedValue,
          client: simulatorSaveData.client,
        },
      };
      if (isAdmin) {
        return history.push(`/admin/processando-simulador/${clientId}`, simulatorState);
      } else if (!isAdmin) {
        history.push(RoutePath.SIMULATOR_PROCESSING, simulatorState);
      }
    }
  };

  const handleChange = (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setSimulatorSaveData({ ...simulatorSaveData, choosedValue: newValue });
    }
  };

  const setTag = tag => {
    if (simulatorSaveData?.installmentsTotal === tag) {
      setSimulatorSaveData({ ...simulatorSaveData, installmentsTotal: NaN });
    } else {
      setSimulatorSaveData({ ...simulatorSaveData, installmentsTotal: tag });
    }
  };

  const valueChange = e => {
    if (simulatorData) {
      setSimulatorSaveData({ ...simulatorSaveData, choosedValue: unMaskedCurrency(e.target.value) / 100 });
    }
  };

  const handleBlur = e => {
    setSimulatorSaveData({
      ...simulatorSaveData,
      choosedValue:
        unMaskedCurrency(StringUtils.verifyMinAndMaxValue(e.target.value, simulatorData?.minValue, simulatorData?.maxValue)) / 100,
    });
  };

  const [indexChosenMounth, setindexChosenMounth] = useState<number>(0);

  const buttonAction = action => {
    if (simulatorData) {
      if (action === TypesPlusMinus.PLUS) {
        if (canPlus) {
          setindexChosenMounth(indexChosenMounth + 1);
        }
      } else {
        if (canMinus) {
          setindexChosenMounth(indexChosenMounth - 1);
        }
      }
    }
  };

  useEffect(() => {
    if (simulatorInstallmentOptions) {
      setTag(simulatorInstallmentOptions[indexChosenMounth]?.installmentValue);
    }
  }, [indexChosenMounth]);

  const simulatorInstallmentOptions = simulatorData?.simulatorInstallmentOptions.sort((a, b) => a.installmentValue - b.installmentValue);

  const canPlus = indexChosenMounth !== (simulatorInstallmentOptions?.length || 0) - 1;
  const canMinus = indexChosenMounth !== 0;

  return (
    <>
      <StyledMainTitle>
        {authorities?.includes(Authority.ROLE_ADMIN)
          ? t('admin.simulator.runNewSimulation')
          : t('landingPage.openingScreen.simulatorSlider.runNewSimulation')}
      </StyledMainTitle>
      <StyledText>
        {authorities?.includes(Authority.ROLE_ADMIN)
          ? t('admin.simulator.simulatorSlider')
          : t('landingPage.openingScreen.simulatorSlider.text')}
      </StyledText>
      <SimulatorContainer>
        <SimulatorTitle>{t('landingPage.openingScreen.whatValue')}</SimulatorTitle>
        <SliderContainer>
          <SliderValue
            value={
              simulatorData?.fixedValue == null
                ? StringUtils.currencyMask(simulatorSaveData?.choosedValue?.toFixed(2))
                : simulatorData?.fixedValue
            }
            onChange={valueChange}
            onBlur={handleBlur}
          />
          <Slider
            min={simulatorData?.minValue}
            max={simulatorData?.maxValue}
            value={(simulatorData?.fixedValue == null ? simulatorSaveData?.choosedValue : simulatorData?.fixedValue) ?? 0}
            handleChange={handleChange}
            disabled={!(simulatorData?.fixedValue == null)}
          />
          <StyledSubtitle>{t('landingPage.openingScreen.timeToPay')}</StyledSubtitle>
          <DesiredValueContainer>
            <DesiredValueButtons $isActive={canMinus} onClick={() => buttonAction(TypesPlusMinus.MINUS)} disabled={!canMinus}>
              <SimulatorImages.minus2 />
            </DesiredValueButtons>
            <DesiredTime
              disabled
              value={
                simulatorInstallmentOptions && simulatorInstallmentOptions[indexChosenMounth]
                  ? simulatorInstallmentOptions[indexChosenMounth].installmentValue > 1
                    ? `${simulatorInstallmentOptions[indexChosenMounth].installmentValue} ${t(
                        'landingPage.openingScreen.simulatorSlider.meses'
                      )}`
                    : `${simulatorInstallmentOptions[indexChosenMounth].installmentValue} ${t(
                        'landingPage.openingScreen.simulatorSlider.mes'
                      )}`
                  : '--'
              }
            />
            <DesiredValueButtons $isActive={canPlus} onClick={() => buttonAction(TypesPlusMinus.PLUS)} disabled={!canPlus}>
              <SimulatorImages.plus2 />
            </DesiredValueButtons>
          </DesiredValueContainer>
        </SliderContainer>
        <StyledPreSimulatorButton
          disabled={simulatorSaveData?.installmentsTotal == null || isNaN(simulatorSaveData?.installmentsTotal) || value == null}
          onClick={() => postSimulator()}
        >
          {t('global.button.simulate')}
        </StyledPreSimulatorButton>
      </SimulatorContainer>
    </>
  );
};

export default SimulatorFinancing;
