import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import { WithTranslation, withTranslation } from 'react-i18next';
import useModalAnalyzingCredit from './hooks/use-modal-analyzing-credit';
import * as S from './styles';
import { FormProvider } from 'react-hook-form';
import { SimpleSelectInput } from 'components/simple-select-input';
import { AdminAnalyzeStatus } from 'model/enums/admin-analyze-status';
import { ORGANIZATION_NAME } from 'config/constants';
import { OrganizationName } from 'model/enums/organization-name';
import FinancingPaymentType from 'model/enums/financingPaymentType';
import InputText from 'components/styled-input';
import useFormatPaymentTypeToShow from 'hooks/use-format-payment-type-to-show';

interface Props extends WithTranslation {
  showModal: boolean;
  closeModal: () => void;
}

const ModalAnalyzingCredit = ({ showModal, closeModal, t }: Props) => {
  const {
    isLoading,
    listPaymentType,
    signatureOptions,
    organizationData,
    antiFraudOptions,
    defaultOptionAntiFraud,
    methods,
    sendAnalysis,
    clearForm,
  } = useModalAnalyzingCredit();
  const { formatPaymentTypeToShow } = useFormatPaymentTypeToShow();

  return (
    <CustomModalTwoButtons
      isShowModal={showModal}
      onCloseModal={() => {
        closeModal();
        clearForm();
      }}
      firstButtonText={t('global.button.cancel')}
      secondButtonText={t('global.button.confirm')}
      handleClickFirstButton={() => {
        closeModal();
        clearForm();
      }}
      handleClickSecondButton={methods.handleSubmit(sendAnalysis)}
      title={t('admin.customerAnalysis.buttonText')}
      large
      loading={isLoading}
    >
      <S.InputContent>
        <FormProvider {...methods}>
          <S.FormContainer>
            <SimpleSelectInput
              name="action"
              placeholder={t('admin.customerAnalysis.inputs.action')}
              options={[
                t(`admin.customerAnalysis.analysisOptions.${AdminAnalyzeStatus.ADJUST}`),
                t(`admin.customerAnalysis.analysisOptions.${AdminAnalyzeStatus.APPROVED}`),
                t(`admin.customerAnalysis.analysisOptions.${AdminAnalyzeStatus.REJECTED}`),
              ]}
              errorMessage={methods.formState.errors.action?.message?.toString()}
            />
            {ORGANIZATION_NAME === OrganizationName.MONJUA && (
              <SimpleSelectInput
                name="payment"
                placeholder={t('admin.customerAnalysis.inputs.payment')}
                options={listPaymentType
                  .filter(item => item !== FinancingPaymentType.DEALER)
                  .map(paymentType => formatPaymentTypeToShow(paymentType))}
                errorMessage={methods.formState.errors.payment?.message?.toString()}
              />
            )}
            {signatureOptions.length > 1 && (
              <SimpleSelectInput
                name="subscriptionOption"
                placeholder={t('admin.customerAnalysis.inputs.selectSubscriptionOption')}
                defaultValue={signatureOptions.filter(signatureOption => signatureOption.isDefault)?.[0]?.description}
                options={signatureOptions.map(signatureOption => signatureOption.description)}
              />
            )}
            {organizationData?.antiFraudIsNecessary && (
              <SimpleSelectInput
                name="antiFraud"
                placeholder={t('admin.customerAnalysis.inputs.antiFraud')}
                defaultValue={defaultOptionAntiFraud}
                options={antiFraudOptions}
              />
            )}
            <InputText name="comment" placeholder={t('admin.customerAnalysis.inputs.comment')} />
          </S.FormContainer>
        </FormProvider>
      </S.InputContent>
    </CustomModalTwoButtons>
  );
};

export default withTranslation()(ModalAnalyzingCredit);
