import styled from 'styled-components';

export const Container = styled.div``;

export const OverviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > div.blank {
    width: 130px;
  }
`;

export const Overview = styled.div``;

export const InstallmentsStats = styled.div`
  min-width: 130px;
`;

export const SeeInstallments = styled.button`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.color.primaryColor};

  margin-bottom: 50px;

  > span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
  }
`;

export const Stats = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  > div.paid {
    background: ${({ theme }) => theme.color.primaryColor};
  }

  > div.late {
    background: ${({ theme }) => theme.color.rejected};
  }

  > div.remaining {
    background: ${({ theme }) => theme.color.borderGray};
  }

  margin-bottom: 16px;

  > span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.gray21};
    margin-left: 8px;
  }
`;

export const InputsBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  gap: 15px;
  flex-wrap: wrap;
  > div,
  label {
    flex: 1;
  }
  @media (max-width: 1300px) {
    > div,
    label {
      flex: 0;
    }
  }
`;
