import styled, { css } from 'styled-components';

export const StyledPixContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  label {
    input {
      background-color: unset;
    }
  }
`;

export const StyledDeleteButton = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 21px;
  width: 60px;
  height: 60px;
  path {
    fill: ${props => props.disabled && 'gray'};
  }
`;

export const StyleIndex = styled.span`
  border-radius: 50px;
  border: 1px solid #e5e7e7;
  background: #fff;
  display: flex;
  width: 44px;
  height: 44px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #656666;
  font-family: Satoshi;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
`;
