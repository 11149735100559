import styled from 'styled-components';

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.color.grayBackground};
  height: 100vh;
  overflow: auto;
`;

export const StyledInnerContainer = styled.div`
  max-width: 480px;
  margin: 48px auto 0 auto;
  padding: 0 16px;
`;

export const StyledMainTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: #000000;

  @media (max-width: 575.98px) {
    font-size: 20px;
  }
`;

export const StyledText = styled.div`
  margin: 16px 0 20px 0;
`;

export const StyledCardContainer = styled.div`
  width: 100%;
  margin: 40px 0px 50px 0px;

  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(27, 25, 25, 0.08);
  background: #ffffff;

  padding: 40px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  h1 {
    color: var(--gray-dark-2, #333);
    font-family: Satoshi;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-top: 15px;
  }
  span {
    color: var(--gray-dark-3, #4c4d4d);
    text-align: center;
    font-family: Satoshi;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`;
