import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  max-width: 95vw;
  margin: 0 16px;
`;

export const InnerContainer = styled.div`
  max-width: 1230px;
  margin: 34px auto 38px auto;
`;

export const TitleContainer = styled.div`
  display: flex;
  column-gap: 16px;
  margin-bottom: 24px;
  align-items: center;
`;

export const CCBNumber = styled.div`
  font-size: 24px;
  font-weight: 400;
  border-left: 1px solid #e5e7e7;
  border-right: 1px solid #e5e7e7;
  padding: 0 16px;
`;
