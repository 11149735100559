import styled from 'styled-components';
export const StyledContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.primaryColorLight};
  border-radius: 8px;
  padding: 1rem;
  color: white;
`;

export const StyledTitle = styled.div`
  font-weight: 600;
  margin-bottom: 1px;
  color: white;
`;
