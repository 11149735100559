import styled from 'styled-components';

interface Props {
  $customStyle?: any;
  $isActive?: boolean;
}

export const LoanGracePeriodContainer = styled.div`
  margin-top: 30px;
  width: 100%;
`;

export const SectionTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #7f7f7f;
  margin: 12px 0px 0px;
  text-align: center;
`;

export const DesiredValueContainer = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  margin: 8px 0px 12px;
  border-bottom: 1px solid #e5e7e7;

  @media (max-width: 671px) {
    width: 100%;
    height: auto;
  }
`;
export const DesiredValue = styled.input`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.primaryColor};
  text-align: center;
  border: 0px;

  @media (max-width: 671px) {
    width: 192px;
    height: auto;
  }
`;

export const DesiredValueButtons = styled.button<Props>`
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  padding: 0px;

  ${({ $customStyle }) => $customStyle?.backgroundImage && `background-image: url(${({ $customStyle }) => $customStyle?.backgroundImage});`}
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  svg {
    path {
      fill: ${({ $isActive, theme }) => ($isActive ? theme.color.primaryColor : theme.color.gray18)};
    }
  }
`;

export const SliderTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

export const SliderTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray21};
`;

export const SliderValue = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: ${({ theme }) => theme.color.primaryColor};
`;
