import registerApi from 'api/register-api';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { City, State } from 'model/address';
import { Partner } from 'model/dashboard';
import { useRegisterForm } from 'provider/register-form';
import { useSelectLists } from 'provider/select-list';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Container } from '../styles';
import { ContainerInputsPartnerAddress } from './inputs-partner-address';

interface IParterDataProps {
  onClickBack: () => void;
  onClickForward: () => void;
  onlyView?: boolean;
}

export const EditFormPartnerAddress: React.FC<IParterDataProps> = props => {
  const { initialPartnerData, setInitialPartnerData } = useRegisterForm();
  const { states, cities, getCities } = useSelectLists();
  const { t } = useTranslation();

  const handleSubmit = (partner: Partner) => {
    setInitialPartnerData(partner);
    props.onClickForward();
  };

  const schema = yup.object({
    zipcode: yup.string().min(9, t('global.errorMessage.zipcode')).required(t('global.errorMessage.required')),
    street: yup.string().required(t('global.errorMessage.required')),
    number: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('valid-only-number', t('global.errorMessage.anyWord'), value => {
        if (value != null) {
          return /^\d+$/.test(value);
        }
        return false;
      }),
    complement: yup.string(),
    district: yup.string().required(t('global.errorMessage.required')),
    state: yup.string().required(t('global.errorMessage.required')),
    city: yup.string().required(t('global.errorMessage.required')),
  });

  const getStateAndCityId = async (uf: string, city: string) => {
    const stateRes = await registerApi.getStates(7, 0, '', uf);
    const stateId = states.find(state => state.name === uf)?.id;
    if (stateId) {
      getCities(stateId, { name: city, page: 0, size: 7 }, true);
    }
  };

  return (
    <Container>
      <ReactHookFormProvider
        schema={schema}
        onSubmit={res => {
          getStateAndCityId(res.state, res.city).then(() => {
            const city = {
              name: res.city,
              id: cities.find(city => city.name === res.city)?.id,
            } as City;

            const state = {
              name: res.state,
              id: states.find(state => state.name === res.state)?.id,
              acronym: states.find(state => state.name === res.state)?.acronym,
            } as State;

            const newPartner = {
              ...initialPartnerData,
              address: {
                zipcode: res.zipcode,
                street: res.street,
                number: res.number,
                complement: res.complement,
                district: res.district,
                reference: res.reference,
                city,
                state,
              },
            };
            handleSubmit(newPartner as Partner);
          });
        }}
      >
        <ContainerInputsPartnerAddress onlyView={props.onlyView} visible={false} onClickBack={props.onClickBack} />
      </ReactHookFormProvider>
    </Container>
  );
};
