import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

export const Toast = styled(ToastContainer)`
  .Toastify__toast {
    min-height: 46px;
    padding: 0 13px;
    margin: 10px;
    border-radius: 4px;
    font-family: 'Satoshi';
  }

  .Toastify__close-button {
    align-self: center;
    color: ${({ theme }) => theme.color.primaryColor};
    opacity: 1;
  }

  .Toastify__progress-bar {
    // Se none o toast não fecha automaticamente. Somente quando clicar no X.
    display: none;
  }
`;
