import { Attachment } from 'model/attachment';
import { Partner } from 'model/dashboard';
import { useRegisterForm } from 'provider/register-form';
import { useState } from 'react';
import { InstructionsScreen } from './screens/instructions';
import { ResumeScreen } from './screens/resume';

interface Props {
  onClickBack: () => void;
  onClickForward: () => void;
  attachmentIdentification?: Attachment;
  attachmentIncome?: Attachment;
  attachmentAddress?: Attachment;
  isGuarantorRegister?: boolean;
  isOnlyIdentificationDocument?: boolean;
}

export const FormPartnerDocuments = ({ onClickBack, onClickForward }: Props) => {
  const [actualStep, setActualStep] = useState(0);
  const { files, initialClientData, setInitialClientData, setInitialPartnerData } = useRegisterForm();

  const onFinishAddingDocuments = (partner: Partner) => {
    const partners = initialClientData?.legalPerson?.partners ?? [];
    const newPartners = [...partners, partner];
    if (initialClientData && initialClientData.legalPerson) {
      setInitialClientData({
        ...initialClientData,
        legalPerson: {
          ...initialClientData.legalPerson,
          partners: newPartners,
        },
      });
    }
    onClickForward();
  };

  const renderSteps = () => {
    switch (actualStep) {
      case 0:
        return <InstructionsScreen actualStep={actualStep} setActualStep={setActualStep} onClickBack={onClickBack} />;
      case 1:
        return (
          <ResumeScreen
            actualStep={actualStep}
            setActualStep={setActualStep}
            onClickForward={onFinishAddingDocuments}
            handleVerifyEmptyFields={handleVerifyEmptyFields}
          />
        );
      default:
        return <></>;
    }
  };

  const handleVerifyEmptyFields = (field: string) => {
    const hasFiles = files.find(file => file.type === field);
    if (hasFiles) {
      return false;
    }
    return true;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', margin: '31px 0px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0px', width: '100%' }}>{renderSteps()}</div>
    </div>
  );
};
