import { FormInputsContainer, Row } from 'components/edit-forms/styles';
import { Column } from 'components/register-forms-mobile/styles';
import { SearchSelectInput } from 'components/search-selected-input';
import InputText from 'components/styled-input';
import { TextInput } from 'components/text-input';
import { Mask } from 'model/enums/mask-types';
import { useEditForm } from 'provider/edit-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ownCar, ownMotorbike, scholarity } from 'shared/util/select-utils';

export const ContainerInputsIncomeMonjua = props => {
  const { t } = useTranslation();
  const { initialClientData } = useEditForm();
  const { occupations, occupationsTotalPages, getOccupations } = useSelectLists();
  const [occupationName, setOccupationName] = useState('');
  const [page, setPage] = useState(0);

  useEffect(() => {
    getOccupations({ name: occupationName, page, size: 7 }, true);
  }, [page]);

  const renderMobile = () => {
    return (
      <Column>
        <TextInput name="occupation" label={t('register.inputs.occupation')} isRequired hasCustomInput>
          <SearchSelectInput
            name="occupation"
            placeholder={t('register.placeholder.occupation')}
            options={occupations.map(occ => ({ label: occ.name ?? '', value: occ.id.toString() }))}
            defaultValue={initialClientData?.physicalPerson?.occupation?.name}
            handleChange={values => {
              setOccupationName(values.name);
              setPage(0);
              getOccupations({ name: values.name, size: 7 });
            }}
            handleOnScroll={() => {
              if (page < occupationsTotalPages - 1) {
                setPage(page + 1);
              }
            }}
            defaultOptions={occupations.map(occ => ({ label: occ?.name ?? '', value: occ.id.toString() }))}
          />
        </TextInput>
        <InputText
          isRequired
          name="company"
          label={t('register.inputs.company')}
          placeholder={t('register.placeholder.company')}
          defaultValue={initialClientData?.physicalPerson?.company ?? ''}
        />
        <InputText
          isRequired
          name="companyTimeInYears"
          label={t('register.inputs.companyTimeInYears')}
          mask={Mask.NUMBERS}
          placeholder={t('register.placeholder.companyTimeInYears')}
          defaultValue={initialClientData?.physicalPerson?.companyTimeInYears?.toString() ?? ''}
          maxLength={2}
        />
        <InputText
          name="income"
          label={t('register.inputs.income')}
          placeholder={t('register.placeholder.income')}
          mask={Mask.CURRENCY}
          defaultValue={initialClientData?.physicalPerson?.income ? initialClientData?.physicalPerson?.income.toFixed(2) : ''}
          maxLength={14}
        />
        <InputText
          isRequired
          name="dependents"
          label={t('register.inputs.dependents')}
          mask={Mask.NUMBERS}
          placeholder={t('register.placeholder.dependents')}
          defaultValue={initialClientData?.physicalPerson?.dependents?.toString() ?? ''}
          maxLength={2}
        />
        <TextInput name="scholarity" label={t('register.inputs.scholarity')} isRequired hasCustomInput>
          <SearchSelectInput
            name="scholarity"
            placeholder={t('register.placeholder.scholarity')}
            options={scholarity}
            defaultOptions={scholarity}
            defaultValue={scholarity.find(res => res.value === initialClientData?.physicalPerson?.scholarity)?.label}
          />
        </TextInput>
        <TextInput name="ownCar" label={t('register.inputs.ownCar')} isRequired hasCustomInput>
          <SearchSelectInput
            name="ownCar"
            placeholder={t('register.placeholder.ownCar')}
            options={ownCar}
            defaultOptions={ownCar}
            defaultValue={ownCar.find(res => res.value === initialClientData?.physicalPerson?.ownCar)?.label}
          />
        </TextInput>
        <TextInput name="ownMotorbike" label={t('register.inputs.ownMotorbike')} isRequired hasCustomInput>
          <SearchSelectInput
            name="ownMotorbike"
            placeholder={t('register.placeholder.ownMotorbike')}
            options={ownMotorbike}
            defaultOptions={ownMotorbike}
            defaultValue={ownMotorbike.find(res => res.value === initialClientData?.physicalPerson?.ownMotorbike)?.label}
          />
        </TextInput>
      </Column>
    );
  };
  return (
    <FormInputsContainer $visible={props.visible}>
      {props.mobile ? (
        renderMobile()
      ) : (
        <>
          <Row>
            <TextInput name="occupation" label={t('register.inputs.occupation')} isRequired hasCustomInput>
              <SearchSelectInput
                name="occupation"
                placeholder={t('register.placeholder.occupation')}
                options={occupations.map(occ => ({ label: occ.name ?? '', value: occ.id.toString() }))}
                defaultValue={initialClientData?.physicalPerson?.occupation?.name}
                handleChange={values => {
                  setOccupationName(values.name);
                  setPage(0);
                  getOccupations({ name: values.name, size: 7 });
                }}
                handleOnScroll={() => {
                  if (page < occupationsTotalPages - 1) {
                    setPage(page + 1);
                  }
                }}
                defaultOptions={occupations.map(occ => ({ label: occ?.name ?? '', value: occ.id.toString() }))}
              />
            </TextInput>
            <InputText
              isRequired
              name="company"
              label={t('register.inputs.company')}
              placeholder={t('register.placeholder.company')}
              defaultValue={initialClientData?.physicalPerson?.company ?? ''}
            />

            <InputText
              isRequired
              name="companyTimeInYears"
              label={t('register.inputs.companyTimeInYears')}
              mask={Mask.NUMBERS}
              placeholder={t('register.placeholder.companyTimeInYears')}
              defaultValue={initialClientData?.physicalPerson?.companyTimeInYears?.toString() ?? ''}
              maxLength={2}
            />
          </Row>
          <Row>
            <InputText
              name="income"
              label={t('register.inputs.income')}
              placeholder={t('register.placeholder.income')}
              mask={Mask.CURRENCY}
              defaultValue={initialClientData?.physicalPerson?.income ? initialClientData?.physicalPerson?.income.toFixed(2) : ''}
              maxLength={14}
            />
            <InputText
              isRequired
              name="dependents"
              label={t('register.inputs.dependents')}
              mask={Mask.NUMBERS}
              placeholder={t('register.placeholder.dependents')}
              defaultValue={initialClientData?.physicalPerson?.dependents?.toString() ?? ''}
              maxLength={2}
            />
            <TextInput name="scholarity" label={t('register.inputs.scholarity')} isRequired hasCustomInput>
              <SearchSelectInput
                name="scholarity"
                placeholder={t('register.placeholder.scholarity')}
                options={scholarity}
                defaultOptions={scholarity}
                defaultValue={scholarity.find(res => res.value === initialClientData?.physicalPerson?.scholarity)?.label}
              />
            </TextInput>
          </Row>
          <Row>
            <TextInput name="ownCar" label={t('register.inputs.ownCar')} isRequired hasCustomInput>
              <SearchSelectInput
                name="ownCar"
                placeholder={t('register.placeholder.ownCar')}
                options={ownCar}
                defaultOptions={ownCar}
                defaultValue={ownCar.find(res => res.value === initialClientData?.physicalPerson?.ownCar)?.label}
              />
            </TextInput>
            <TextInput name="ownMotorbike" label={t('register.inputs.ownMotorbike')} isRequired hasCustomInput>
              <SearchSelectInput
                name="ownMotorbike"
                placeholder={t('register.placeholder.ownMotorbike')}
                options={ownMotorbike}
                defaultOptions={ownMotorbike}
                defaultValue={ownMotorbike.find(res => res.value === initialClientData?.physicalPerson?.ownMotorbike)?.label}
              />
            </TextInput>
            <div style={{ width: '280px' }} />
          </Row>
        </>
      )}
    </FormInputsContainer>
  );
};
