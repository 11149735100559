import MobileHeader from 'components/mobile-header';
import InstructionCard from 'components/instruction-card';
import { ReactComponent as ConfirmDataIconSvg } from './../../../images/user.svg';
import { ReactComponent as DocIconSvg } from './../../../images/doc.svg';
import { ReactComponent as CameraVideoIconSvg } from './../../../images/video-camera.svg';
import { ReactComponent as SimpleCameraIconSvg } from './../../../images/camera.svg';
import CustomButton from 'components/custom-button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CardShowContract from 'components/card-show-contract';
import { PageTitleSection, StyledCardShowContractContainer, StyledCardsContainer, StyledContainer, StyledInnerContainer } from '../styles';
import { useContext, useEffect, useState } from 'react';
import CustomComponentModal from 'components/custom-conmponent-modal';
import { ContractPdf } from 'features/admin/contractScreen/components/contractPdf';
import ContractContext from 'context/contract';
import contractService from 'services/contract-service';
import { RoutePath } from 'model/enums/route-path';
import { PersonSignatureStatusTypes } from 'model/enums/person-signature-status';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import organizationSystemStepsService from 'services/organization-system-steps-service';
import OrganizationsSystemSteps from 'model/organization-system-steps';
import SystemStepCategory from 'model/enums/system-step-category';
import ScrollToTop from 'components/scroll-top';
import {
  UNICO_CONFIG_HOST_INFO,
  UNICO_CONFIG_HOST_KEY,
  UNICO_CONFIG_HOST_NAME,
  UNICO_CONFIG_PROJECT_ID,
  UNICO_CONFIG_PROJECT_NUMBER,
} from 'config/constants';

const LetsSign = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const { documentPresignUrl, signatureKey, setErrorDescriptionModal } = useContext(ContractContext);
  const { isMobile, isTablet } = useResponsiveMobile();

  const [loading, setLoading] = useState<boolean>(false);
  const [showComponentModal, setShowComponentModal] = useState<boolean>(false);
  const [steps, setSteps] = useState<OrganizationsSystemSteps[]>([]);
  const [isConfirmedData, setIsConfirmedData] = useState<boolean>(false);
  const [isDocumentOcrDone, setIsDocumentOcrDone] = useState<boolean>(false);
  const [isBiometricUnicoDone, setIsBiometricUnicoDone] = useState<boolean>(false);
  const [isFaceMatchDone, setIsFaceMatchDone] = useState<boolean>(false);
  const [ocrProcessingTries, setOcrProcessingTries] = useState<number>(0);
  const [livenessProcessingTries, setLivenessProcessingTries] = useState<number>(0);
  const [faceMatchedProcessingTries, setFaceMatchedProcessingTries] = useState<number>(0);
  const [canRetry, setCanRetry] = useState<boolean>(false);
  const [personSignatureStatus, setPersonSignatureStatus] = useState<PersonSignatureStatusTypes>();

  const handleNextStep = () => {
    if (personSignatureStatus === PersonSignatureStatusTypes.DONE) {
      return history.push(RoutePath.SIGNATURE_SUCCESS);
    }
    if (personSignatureStatus === PersonSignatureStatusTypes.CANCELED) {
      return history.push(RoutePath.SIGNATURE_CANCELED);
    }

    if (isConfirmedData && isBiometricUnicoDone) {
      return history.push(RoutePath.REGISTER_DOCUMENT);
    }

    if (isConfirmedData && !isBiometricUnicoDone) {
      if (livenessProcessingTries > 0 && !canRetry) {
        return history.push(RoutePath.SIGNATURE_RELEASE);
      }

      return history.push(RoutePath.PROOF_LIFE_INSTRUCTIONS);
    }

    return history.push(RoutePath.CONFIRM_DATA);
  };

  const renderSteps = () => {
    return steps
      .sort((a, b) => a.stepOrder - b.stepOrder)
      .map((oneStep, key) => {
        if (oneStep.systemStep.step === SystemStepCategory.SIGNATURE_DATA) {
          return (
            <div key={`Instruction-card-${key}`}>
              <InstructionCard
                icon={<ConfirmDataIconSvg />}
                title={'Confirme seus dados'}
                text={'Nome, CPF e data de nascimento.'}
                isDone={isConfirmedData}
              />
            </div>
          );
        }
        if (oneStep.systemStep.step === SystemStepCategory.BIOMETRIC_UNICO) {
          return (
            <div key={`Instruction-card-${key}`}>
              <InstructionCard
                icon={<SimpleCameraIconSvg />}
                title={'Prova de vida'}
                text={'Confirmação de identidade'}
                isDone={isBiometricUnicoDone}
              />
            </div>
          );
        }
        if (oneStep.systemStep.step === SystemStepCategory.DOCUMENT_UNICO) {
          return (
            <div key={`Instruction-card-${key}`}>
              <InstructionCard icon={<DocIconSvg />} title={'Documentação'} text={'Verificação de documento'} isDone={isDocumentOcrDone} />
            </div>
          );
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const responseSteps = await organizationSystemStepsService.getSteps();
        setSteps(responseSteps);

        const responseContract = await contractService.getContract(`${signatureKey}`);
        if (responseContract) {
          setIsConfirmedData(responseContract.isConfirmedData ?? false);
          setIsDocumentOcrDone(responseContract.isDocumentOcrDone ?? false);
          setIsFaceMatchDone(responseContract.isFaceMatchDone ?? false);
          setCanRetry(responseContract.canRetry ?? false);
          setOcrProcessingTries(responseContract.ocrProcessingTries ?? 0);
          setLivenessProcessingTries(responseContract.livenessProcessingTries ?? 0);
          setFaceMatchedProcessingTries(responseContract.faceMatchedProcessingTries ?? 0);
          setPersonSignatureStatus(responseContract.personSignatureStatus);
          if (
            responseContract?.unicoProcess?.processStatus?.codBiometricUnico === 200 &&
            responseContract?.unicoProcess?.processStatus?.codDocumentUnico === 200 &&
            responseContract?.unicoProcess?.processStatus?.codFinishUnico === 200
          ) {
            history.push(RoutePath.SIGNATURE_ANALYSIS);
          }
          if (responseContract?.unicoProcess?.createdDate) {
            const createdDate = new Date(responseContract.unicoProcess.createdDate);
            const fiftyMinutesAgo = new Date(Date.now() - 50 * 60 * 1000);

            if (fiftyMinutesAgo < createdDate) {
              if (responseContract?.unicoProcess?.processStatus?.codBiometricUnico === 200) {
                setIsBiometricUnicoDone(true);
              }
              if (responseContract?.unicoProcess?.processStatus?.codDocumentUnico === 200) {
                setIsDocumentOcrDone(true);
              }
            }
          }
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (!isMobile && !isTablet) {
      return history.push(`/qr-code/${signatureKey}`);
    }
    if (!signatureKey) {
      return history.push('/');
    }

    fetchData();
  }, []);

  return (
    <StyledContainer>
      <ScrollToTop />
      <MobileHeader></MobileHeader>
      <StyledInnerContainer>
        <PageTitleSection>
          <p>{t('letsSign.title')}</p>
          <span>{t('letsSign.subtitle')}</span>
        </PageTitleSection>
        <StyledCardsContainer>
          {renderSteps()}
          <CustomButton className="mobile-button--gofoward" disabled={loading} onClick={() => handleNextStep()}>
            {t('global.button.nextStep')}
          </CustomButton>
        </StyledCardsContainer>
        <StyledCardShowContractContainer>
          <CardShowContract
            onClick={() => {
              setShowComponentModal(true);
            }}
          />
        </StyledCardShowContractContainer>
        <CustomComponentModal open={showComponentModal} setOpen={setShowComponentModal}>
          <ContractPdf url={documentPresignUrl ?? ''} />
        </CustomComponentModal>
      </StyledInnerContainer>

      <div className="waiting-step-footer">{t('contract.footer')}</div>
    </StyledContainer>
  );
};

export default LetsSign;
