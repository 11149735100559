import EnterCustomButton from 'components/enter-custom-button';
import { Button, ButtonsContainer, Column, FormInputsContainer, InfoPassword } from 'components/register-forms-mobile/styles';
import { RequirementItem, RequirementsContainer, RequirementTitle } from 'components/register-forms/styles';
import InputText from 'components/styled-input';
import { useRegisterForm } from 'provider/register-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ContainerInputsPassword = () => {
  const { t } = useTranslation();
  const { initialClientData, isFirstForm, isLastForm, onClickBack, isLoading } = useRegisterForm();
  const [password, setPassword] = useState('');

  const upperCaseRegex = /^(?=.*[A-Z])/;
  const lowerCaseRegex = /^(?=.*[a-z])/;
  const atLeastOneNumberRegex = /^(?=.*[0-9])/;

  return (
    <FormInputsContainer>
      <RequirementsContainer style={{ margin: '0 16px' }}>
        <RequirementTitle>{t('login.recovery.createPassword.requirements.title')}</RequirementTitle>
        <RequirementItem $isValid={password.length >= 6 ? true : false}>
          {t('login.recovery.createPassword.requirements.stepOne')}
        </RequirementItem>
        <RequirementItem $isValid={lowerCaseRegex.test(password)}>
          {t('login.recovery.createPassword.requirements.stepTwo')}
        </RequirementItem>
        <RequirementItem $isValid={upperCaseRegex.test(password)}>
          {t('login.recovery.createPassword.requirements.stepThree')}
        </RequirementItem>
        <RequirementItem $isValid={atLeastOneNumberRegex.test(password)}>
          {t('login.recovery.createPassword.requirements.stepFour')}
        </RequirementItem>
      </RequirementsContainer>
      <Column>
        <InputText
          onChange={e => setPassword(e.target.value)}
          isRequired
          inputType="password"
          name="password"
          label={t('register.inputs.password')}
          placeholder={t('register.placeholder.password')}
          defaultValue={initialClientData?.user?.password}
        />
        <InputText
          isRequired
          inputType="password"
          name="confirmPassword"
          label={t('register.inputs.confirmPassword')}
          placeholder={t('register.placeholder.confirmPassword')}
          defaultValue={initialClientData?.user?.password}
        />
        <InfoPassword>{t('global.message.password')}</InfoPassword>
      </Column>
      <ButtonsContainer>
        {!isFirstForm() ? (
          <Button onClick={onClickBack}>
            <span>{t('global.button.backStep')}</span>
          </Button>
        ) : (
          <div />
        )}
        <EnterCustomButton
          fullSize
          type="submit"
          isLoading={isLoading}
          text={isLastForm() ? t('global.button.register') : t('global.button.nextStep')}
        />
      </ButtonsContainer>
    </FormInputsContainer>
  );
};
