import { ORGANIZATION_NAME, ORGANIZATION_TYPE } from 'config/constants';
import AdminFiltersContext from 'context/admin-filters';
import _ from 'lodash';
import { Client } from 'model/client';
import { CreatedDates } from 'model/date';
import { OrganizationName } from 'model/enums/organization-name';
import { OrganizationType } from 'model/enums/organization-type';
import { RegistrationStatus } from 'model/enums/registration-status';
import { useAdmin } from 'provider/admin-general';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DashboardService } from 'services/dashboard-service';
import { formatDateToLocalFormat } from 'shared/util/date-utils';
import { getStorageFilter } from 'shared/util/filter-utils';
import { Status } from '../../components/status';
import { TableId, TableList, TableValues } from '../../components/table-list';
import { TitleHeader } from '../../components/title-header';

const filterValues = [
  RegistrationStatus.NEW,
  RegistrationStatus.VALIDATION,
  RegistrationStatus.ADJUSTED,
  RegistrationStatus.ADJUST,
  RegistrationStatus.APPROVED,
  RegistrationStatus.REJECTED,
  RegistrationStatus.PRE_REJECTED,
  RegistrationStatus.UPDATED,
];

export const RegistersList = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [searchName, setSearchName] = useState('');
  const [searchSubsidiaryName, setSearchSubsidiaryName] = useState('');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const { statusFilters, createdDates, handleSetNewStatus } = useAdmin();
  const { t } = useTranslation();
  const history = useHistory();
  const storageFilters = getStorageFilter();
  const isPhysicalPerson = ORGANIZATION_TYPE === OrganizationType.PF;
  const [orderBy, setOrderBy] = useState<boolean>(false);

  const isHcred = ORGANIZATION_NAME === OrganizationName.HCRED;

  const { filters } = useContext(AdminFiltersContext);

  useEffect(() => {
    verifyStorageAndGetClients();
  }, [searchName, page, orderBy, searchSubsidiaryName, filters]);

  const verifyStorageAndGetClients = () => {
    let defaultFilters = statusFilters;

    if (storageFilters?.statusFilters && storageFilters?.statusFilters?.length > 0 && !statusFilters.length) {
      defaultFilters = storageFilters.statusFilters;
      handleSetNewStatus(defaultFilters);
    }
    if (isHcred) {
      getDashboardClients({
        name: searchName,
        page,
        registrationStatus: filters?.statusFilters,
        createdDates: filters?.dateRange,
        subsidiaryName: searchSubsidiaryName,
      });
    } else {
      getDashboardClients({
        name: searchName,
        page,
        registrationStatus: defaultFilters,
        createdDates,
        subsidiaryName: searchSubsidiaryName,
      });
    }
  };

  const getDashboardClients = async (props?: {
    name?: string;
    size?: number;
    page?: number;
    registrationStatus?: string[];
    createdDates?: CreatedDates;
    subsidiaryName?: string;
  }) => {
    const res = await DashboardService().getClientsList(
      props?.name,
      props?.size,
      props?.page,
      props?.registrationStatus,
      props?.createdDates,
      orderBy,
      props?.subsidiaryName
    );
    setClients(res.content);
    setPage(res.pageable.pageNumber);
    setTotalPages(res.totalPages);
  };

  const setTableValues = () => {
    const tableValues: TableValues[] = [
      {
        header: isPhysicalPerson ? t('admin.dashboard.tableHeaders.name') : t('admin.dashboard.tableHeaders.corporateName'),
        values: isPhysicalPerson
          ? clients.map(client => client.physicalPerson?.name ?? '')
          : clients.map(client => client.legalPerson?.corporateName ?? ''),
        ids: clients.map(client => ({ first: client.id ?? -1 })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/cadastros/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.email'),
        values: clients.map(client => client.email ?? ''),
        ids: clients.map(client => ({ first: client.id ?? -1 })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/cadastros/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.internal'),
        values: clients.map(client => (client?.internals?.[0]?.name ? client.internals[0].name : '')),
        ids: clients.map(client => ({ first: client.id ?? -1 })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/cadastros/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.registrationDate'),
        values: clients.map(client => formatDateToLocalFormat(client.createdDate)),
        ids: clients.map(client => ({ first: client.id ?? -1 })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/cadastros/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.atualizationDate'),
        values: clients.map(client => formatDateToLocalFormat(client.lastModifiedDate)),
        ids: clients.map(client => ({ first: client.id ?? -1 })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/cadastros/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.status'),
        values: clients.map(client => (
          <Status
            key={client.id}
            text={t(`enum.registrationStatus.${client.registrationStatus}`)}
            color={client.registrationStatus ?? ''}
          />
        )),
        ids: clients.map(client => ({ first: client.id ?? -1 })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/cadastros/${ids.first}`);
          }
        },
      },
    ];

    return tableValues;
  };

  const updateSearchName = _.debounce((name: string) => {
    setSearchName(name);
  }, 500);

  const updateSubsidiaryName = _.debounce((name: string) => {
    setSearchSubsidiaryName(name);
  }, 500);

  const setNewPage = _.debounce((newPage: number) => {
    if (newPage >= totalPages) {
      return setPage(page);
    }
    setPage(newPage);
  }, 500);

  const setNewFilter = () => {
    getDashboardClients({ name: searchName, registrationStatus: statusFilters, createdDates });
  };

  const cleanAppliedFilters = () => {
    getDashboardClients({ name: searchName });
  };

  return (
    <>
      <TitleHeader
        title={t('admin.dashboard.register')}
        setSearch={updateSearchName}
        setSubsidiarySearch={updateSubsidiaryName}
        setFilters={setNewFilter}
        filterValues={filterValues}
        cleanFilters={cleanAppliedFilters}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
      <TableList tableValues={setTableValues()} page={page} totalPages={totalPages} setNewPage={setNewPage} />
    </>
  );
};
