import CustomLoadingButton from 'components/custom-loading-button';
import { ORGANIZATION_NAME } from 'config/constants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import AuthUtils from 'shared/util/auth-utils';
import StringUtils from 'shared/util/string-utils';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { useSelfieContext } from '../../provider';
import { ButtonContainer, Container, Content, ImageContainer, PersonImage, Subtitle, Title } from './styles';
import { GuarantorSvg } from 'images/illustration-guarantor-fill';

export const SelfieSuccess = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { updateUserParameters, updateAccount } = useSelfieContext();
  const id = useParams<{ id: string }>().id ?? undefined;
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useResponsiveMobile().isTablet;

  useEffect(() => {
    updateUserParameters();
  }, []);

  const handleClick = () => {
    setIsLoading(true);
    updateAccount();

    setTimeout(() => {
      if (AuthUtils.isAuthenticated() && AuthUtils.isAdmin()) {
        history.push(`/admin/cadastros/${id}`);
      } else {
        history.push('/login');
      }
    }, 1000);
  };

  const renderButtons = () => {
    if (isMobile && id) {
      return;
    }
    return (
      <ButtonContainer>
        <CustomLoadingButton isLoading={isLoading} $justifyContent="center" $maxWidth="300px" $width="90%" onClick={handleClick}>
          {AuthUtils.isAdmin() ? t('selfie.success.accessClient') : t('selfie.success.access')}
        </CustomLoadingButton>
      </ButtonContainer>
    );
  };
  return (
    <Container>
      <Content>
        <Title>{t('selfie.success.title')}</Title>
        {AuthUtils.isAdmin() ? (
          <Subtitle>{`${t('selfie.success.subtitleAdmin')}`}</Subtitle>
        ) : isMobile && id ? (
          <Subtitle> {`${t('selfie.success.subtitleAdminMobile')} ${StringUtils.replaceToCapitalize(ORGANIZATION_NAME)}`}</Subtitle>
        ) : (
          <Subtitle> {t('selfie.success.subtitle')}</Subtitle>
        )}
        <ImageContainer>
          <GuarantorSvg />
        </ImageContainer>
        {renderButtons()}
      </Content>
    </Container>
  );
};
