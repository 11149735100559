import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import DynamicDesktopForm from 'components/dynamic-desktop-form';
import { toLower } from 'lodash';
import { useRegisterForm } from 'provider/register-form';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { Container, ContainerTitle, ContentContainer, Description, Separator, Title } from './styles';

const StyledDesktopFormContainer: React.FC = props => {
  const { t } = useTranslation();
  const { steps, substeps, actualStep, resetStates, actualSubstep, isLoginModal, setIsLoginModal } = useRegisterForm();
  const history = useHistory();

  const isMobile = useResponsiveMobile().isTablet;

  useEffect(() => {
    resetStates();
  }, [isMobile]);

  const findType = () => {
    return steps.find(step => step.stepOrder === actualStep + 1)?.systemStep?.step ?? '';
  };

  const findTitle = () => {
    const step = steps.find(step => step.stepOrder === actualStep + 1)?.systemStep?.step ?? '';
    const substep = substeps.find(substep => substep.stepOrder && substep.name === actualSubstep)?.name ?? '';
    const title = substep ? substep : step;
    return title;
  };

  return (
    <>
      <CustomModalTwoButtons
        isShowModal={isLoginModal}
        title={t('register.modals.title.loginAlreadyRegistered')}
        firstButtonText={t('global.button.cancel')}
        secondButtonText={t('register.modals.button.login')}
        onCloseModal={() => setIsLoginModal(false)}
        handleClickFirstButton={() => setIsLoginModal(false)}
        handleClickSecondButton={() => history.replace('/login')}
        freeContent
      />
      <Container>
        <ContentContainer>
          {findTitle() && (
            <ContainerTitle>
              <Title>{t(`stepper.title.${toLower(findTitle())}`)}</Title>
              <Separator />
              <Description>{t(`stepper.subtitle.${toLower(findTitle())}`)}</Description>
            </ContainerTitle>
          )}
          <DynamicDesktopForm type={findType()} />
        </ContentContainer>
      </Container>
    </>
  );
};

export default StyledDesktopFormContainer;
