import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 5px 15px;

  display: fixed;
  inset: 0px;
  align-items: center;
  justify-content: center;

  ${({ theme }) =>
    theme &&
    css`
      .dot-typing {
        position: relative;
        left: -10050px;
        width: 34px;
        height: 34px;
        border-radius: 34px;
        background-color: ${theme.color.primaryColor};
        color: ${theme.color.primaryColor};
        z-index: 1;
        box-shadow:
          10000px 0 0 0 ${theme.color.primaryColor},
          10050px 0 0 0 ${theme.color.primaryColor},
          10100px 0 0 0 ${theme.color.primaryColor};
        animation: dotTyping 2.2s infinite ease-in-out;
      }

      @keyframes dotTyping {
        0% {
          box-shadow:
            10000px 0 0 0 ${theme.color.primaryColor},
            10050px 0 0 0 ${theme.color.primaryColor},
            10100px 0 0 0 ${theme.color.primaryColor};
        }
        16.66725% {
          box-shadow:
            10000px -40px 0 0 ${theme.color.primaryColor},
            10050px 0 0 0 ${theme.color.primaryColor},
            10100px 0 0 0 ${theme.color.primaryColor};
        }
        33.333% {
          box-shadow:
            10000px 0 0 0 ${theme.color.primaryColor},
            10050px -60px 0 0 ${theme.color.primaryColor},
            10100px 0 0 0 ${theme.color.primaryColor};
        }
        66.667% {
          box-shadow:
            10000px 0 0 0 ${theme.color.primaryColor},
            10050px 0 0 0 ${theme.color.primaryColor},
            10100px -80px 0 0 ${theme.color.primaryColor};
        }
        83.333% {
          box-shadow:
            10000px 0 0 0 ${theme.color.primaryColor},
            10050px 0 0 0 ${theme.color.primaryColor},
            10100px 0 0 ${theme.color.primaryColor};
        }
        100% {
          box-shadow:
            10000px 0 0 0 ${theme.color.primaryColor},
            10050px 0 0 0 ${theme.color.primaryColor},
            10100px 0 0 0 ${theme.color.primaryColor};
        }
      }

      circle {
        fill: ${theme.color.primaryColorLight};
      }
    `}
`;

export const EllipsesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EllipsesContainerChild = styled.div`
  position: relative;
  top: 38px;
  left: 35px;
`;
