import { truncate } from 'lodash';
import { useEditForm } from 'provider/edit-form';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import Delete from '../../images/close-black.svg';
import { Container, ContainerDataDocument } from './styles';

interface Props {
  editDocument?: boolean;
  attachmentType?: string;
  onlyView?: boolean;
}
export default function ContainerEditUploadedDocument({ editDocument, attachmentType, onlyView }: Props) {
  const { t } = useTranslation();
  const { setFiles, files } = useEditForm();

  const handleRemoveFile = (fileName: string) => {
    const filesFiltred = files.filter(file => file.fileName !== fileName);
    setFiles(filesFiltred);
  };

  return (
    <Container $onlyView={onlyView}>
      {files
        ?.filter(item => item.type === attachmentType)
        .map((file, key) => (
          <ContainerDataDocument key={key}>
            <a download={file.fileName} href={StringUtils.getFileOrUrl(file)} target="_blank">
              {file.fileName && truncate(file.fileName, { length: 25 })}
            </a>
            {editDocument && <img src={Delete} onClick={() => handleRemoveFile(file.fileName ?? '')} />}
          </ContainerDataDocument>
        ))}
    </Container>
  );
}
