import useResponsiveMobile from 'shared/util/use-responsive-hook';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';
import { isMobile } from 'react-device-detect';

interface IRegisterProps {}

const Register: React.FC<IRegisterProps> = props => {
  const isMobileDeviceOrScren = useResponsiveMobile().isTablet || isMobile;

  return !isMobileDeviceOrScren ? <Desktop /> : <Mobile />;
};

export default Register;
