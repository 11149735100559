import { MulttiploImgs } from 'images/multtiplo';
import { ScreenDimensions } from 'model/enums/screen-dimensions-types';
import Popup from 'reactjs-popup';
import styled, { css } from 'styled-components';

interface CustomStyleProps {
  background?: string;
  isActive?: boolean;
  hasError?: boolean;
  sended?: boolean;
}
interface StyledProps {
  $customStyle?: CustomStyleProps;
}

export const StyledPage = styled.div<StyledProps>`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Popins', sans-serif;
  ${({ $customStyle }) =>
    $customStyle &&
    css`
      background: ${$customStyle.background};
    `}
`;

// header
export const HeaderContent = styled.div`
  width: 100%;
  max-width: 1054px;
  height: 64px;
  padding: 0px 64px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${ScreenDimensions.MID_TABLET}) {
    padding: 0px 48px;
  }
`;

export const LinksBox = styled.div`
  width: 100%;
  height: 100%;
  max-width: 458px;
  display: flex;
  justify-content: flex-end;
  column-gap: 24px;
  align-items: center;
`;

export const Link = styled.a`
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;

  ${({ theme }) =>
    theme &&
    css`
      color: ${theme.color.primaryColor};
    `}
`;

export const LoginButton = styled.button`
  width: 80px;
  height: 48px;
  border: 2px solid #27e0ff;
  border-radius: 8px;
  background: #fff;

  color: #27e0ff;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;

  :hover {
    background: #27e0ff;
    color: #fff;
  }
`;

// header mobile

interface StyledPopupProps {
  children: (close: any) => JSX.Element;
}

export const StyledPopup = styled(Popup)<StyledPopupProps>`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  &-content {
    width: 80%;
    height: 100%;
    padding: 0px 32px;
    background: #fff;
    position: absolute !important;
    right: 0px !important;
  }
`;

export const ArrowBack = styled.div`
  position: absolute;
  top: 24px;
  right: 32px;
  z-index: 99;
`;

export const LogoMobileBox = styled.div`
  height: 112px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
`;

export const ButtonsBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;

  margin: 16px 0px;

  padding: 0px 0px 24px;
  border-bottom: 1px solid #c6c6c6;
`;

export const LinksMobile = styled.div`
  width: 100%;
  height: 48px;
  background: #edf1ff;
  border-radius: 8px;

  font-weight: 600;
  font-size: 14px;
  color: #2d49fe;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoginButtonMobile = styled.button`
  width: 100%;
  height: 48px;
  border: 2px solid #27e0ff;
  border-radius: 8px;
  background: #fff;

  color: #27e0ff;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;

  :hover {
    background: #27e0ff;
    color: #fff;
  }
`;

export const MobileInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 8px;
`;

export const MobileInfoTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #393939;
  margin: 0px 0px 8px;
`;
export const MobileInfoText = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #393939;
`;

export const MenuSocialMedia = styled.div`
  display: flex;
  column-gap: 8px;
  margin: 16px 0px 0px;
`;

// form

export const FormContent = styled.div`
  width: 100%;
  max-width: 1054px;
  padding: 16px 32px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FormContainer = styled.div`
  width: 100%;
  height: 660px;
  background-image: url(${MulttiploImgs.formBackground});
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  overflow: hidden;
  padding: 64px 64px 40px;

  display: flex;
  justify-content: space-between;

  @media (max-width: ${ScreenDimensions.TABLET}) {
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: ${ScreenDimensions.MID_TABLET}) {
    background-image: url(${MulttiploImgs.formBackgroundTablet});
    padding: 64px 24px;
  }

  @media (max-width: ${ScreenDimensions.MOBILE}) {
    background-image: url(${MulttiploImgs.formBackgroundMobile});
  }
`;

export const ScreenTitle = styled.div`
  max-width: 324px;
  width: 100%;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  color: #ffffff;

  align-self: flex-end;
  margin: 0px 0px 64px 0px;

  @media (max-width: ${ScreenDimensions.TABLET}) {
    max-width: 446px;
    align-self: center;
    margin: 0px 0px 32px 0px;
  }

  @media (max-width: ${ScreenDimensions.MOBILE}) {
    font-weight: 700;
    font-size: 20px;
  }
`;

export const StyledForm = styled.form`
  max-width: 446px;
  width: 100%;
  padding: 48px;
  background: #ffffff;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;

  @media (max-width: ${ScreenDimensions.MOBILE}) {
    padding: 24px 16px;
  }
`;

export const FormTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #262626;
  margin: 0px 0px 16px;
`;

export const InputBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  position: relative;
`;
export const Label = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #525252;
`;
export const Input = styled.input<StyledProps>`
  width: 100%;
  height: 48px;
  background: #edf1ff;
  border-radius: 8px;
  padding: 0px 16px 0px;
  border: none;
  font-weight: 400;
  font-size: 16px;
  color: #262626;

  ${({ $customStyle }) =>
    $customStyle?.hasError &&
    css`
      background: #fff1f1;
      color: #fa4d56;
    `}
`;
export const Error = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #fa4d56;
  position: absolute;
  bottom: -18px;
  right: 0px;
`;

export const FormButton = styled.button<StyledProps>`
  width: 100%;
  min-height: 48px;
  border: none;
  background: #27e0ff;
  border-radius: 8px;

  font-weight: 600;
  font-size: 14px;
  color: #ffffff;

  margin: 16px 0px 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background: #08bdba;
  }

  ${({ $customStyle }) =>
    $customStyle?.sended &&
    css`
      background: #08bdba;
    `}
`;

export const FormsTerm = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #6f6f6f;
  a {
    color: ${({ theme }) => theme.color.primaryColor};
    text-decoration: none;
  }

  a:hover {
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

// near

export const NearContent = styled.div`
  width: 100%;
  max-width: 1054px;
  padding: 72px 96px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${ScreenDimensions.TABLET}) {
    flex-direction: column;
    padding: 40px 40px 80px;
  }
`;

export const CardBox = styled.div`
  width: 100%;
  max-width: 384px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: ${ScreenDimensions.TABLET}) {
    max-width: 448px;

    margin: 40px 0px 0px;
  }
  @media (max-width: ${ScreenDimensions.MOBILE}) {
    margin: 16px 0px 0px;
  }
`;

export const Card = styled.div`
  width: 182px;
  height: 140px;
  border-radius: 8px;
  background: #ffffff;
  filter: drop-shadow(0px 6px 24px rgba(0, 0, 0, 0.12));

  padding: 24px 32px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;

  @media (max-width: ${ScreenDimensions.TABLET}) {
    width: 214px;
  }
  @media (max-width: ${ScreenDimensions.MID_TABLET}) {
    width: 110px;
  }
`;

export const CardTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #525252;
  text-align: center;
`;

export const TextContainer = styled.div`
  width: 100%;
  max-width: 414px;
  display: flex;
  flex-direction: column;
`;

export const NearTitle = styled.div`
  max-width: 320px;

  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  color: #262626;

  @media (max-width: ${ScreenDimensions.TABLET}) {
    max-width: 100%;
  }
  @media (max-width: ${ScreenDimensions.MOBILE}) {
    font-weight: 700;
    font-size: 20px;
  }
`;

export const NearText = styled.div`
  max-width: 320px;

  font-weight: 500;
  font-size: 14px;
  color: #525252;

  margin: 40px 0px 0px;

  @media (max-width: ${ScreenDimensions.TABLET}) {
    max-width: 100%;
  }
  @media (max-width: ${ScreenDimensions.MOBILE}) {
    font-weight: 400;
    font-size: 12px;
    margin: 16px 0px 0px;
  }
`;

export const NearButton = styled.button`
  width: 260px;
  height: 48px;
  border: none;
  background: #27e0ff;
  border-radius: 8px;

  font-weight: 600;
  font-size: 14px;
  color: #ffffff;

  margin: 48px 0px 0px;

  :hover {
    background: #08bdba;
  }

  @media (max-width: ${ScreenDimensions.TABLET}) {
    width: 100%;
  }

  @media (max-width: ${ScreenDimensions.MOBILE}) {
    margin: 40px 0px 0px;
  }
`;
// faq

export const FaqContent = styled.div`
  width: 100%;
  max-width: 1054px;
  padding: 80px 96px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${ScreenDimensions.TABLET}) {
    align-items: center;
    justify-content: center;
  }

  @media (max-width: ${ScreenDimensions.MID_TABLET}) {
    padding: 80px 40px;
  }
`;
export const FaqTitle = styled.div`
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  color: #262626;

  @media (max-width: ${ScreenDimensions.MOBILE}) {
    width: 100%;
    font-weight: 700;
    font-size: 20px;
  }
`;

export const FaqBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 24px;
  column-gap: 40px;
  margin: 40px 0px 0px;

  @media (max-width: ${ScreenDimensions.TABLET}) {
    align-items: center;
    justify-content: center;
  }
`;

export const Item = styled.div<StyledProps>`
  width: 404px;
  height: ${({ $customStyle }) => ($customStyle?.isActive ? 'auto' : '66px')};
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-bottom: 1px solid #c6c6c6;
  padding: 0px 24px 16px 0px;
  transition: all 1s ease;
  cursor: pointer;
`;

export const HandleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  svg {
    position: absolute;
    right: -24px;
  }
`;

export const ItemTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #525252;
`;
export const ItemText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;

  color: #525252;
`;

// flexible structure

export const FlexibleStructureContent = styled.div`
  width: 100%;
  max-width: 1054px;
  padding: 0px 32px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;

  @media (max-width: ${ScreenDimensions.MOBILE}) {
    padding: 0px 16px;
  }
`;

export const FlexibleStructureImage = styled.div`
  width: 100%;
  height: 316px;
  background-image: url(${MulttiploImgs.flexibleStructure});
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  overflow: hidden;

  padding: 0px 0px 0px 96px;
  display: flex;
  align-items: center;

  @media (max-width: ${ScreenDimensions.TABLET}) {
    padding: 0px 80px;
  }

  @media (max-width: ${ScreenDimensions.MID_TABLET}) {
    padding: 0px 40px;
  }

  @media (max-width: ${ScreenDimensions.MOBILE}) {
    padding: 0px 16px;
    height: 150px;
  }
`;
export const FlexibleStructureTitle = styled.div`
  max-width: 700px;
  font-weight: 700;
  font-size: 40px;
  color: #fff;

  @media (max-width: ${ScreenDimensions.MOBILE}) {
    font-weight: 700;
    font-size: 20px;
  }
`;

// footer

export const FooterContent = styled.div`
  width: 100%;
  max-width: 1054px;
  padding: 40px 64px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;

  @media (max-width: ${ScreenDimensions.MID_TABLET}) {
    flex-direction: column;
    padding: 40px;
  }
`;

export const FooterInfoBox = styled.div`
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const InfoTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin: 0px 0px 16px;

  @media (max-width: ${ScreenDimensions.MID_TABLET}) {
    margin: 16px 0px 8px;
  }
`;
export const InfoText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const SocialMedia = styled.div`
  width: 56px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 64px;
  top: 40px;

  svg {
    cursor: pointer;
  }

  @media (max-width: ${ScreenDimensions.MID_TABLET}) {
    position: static;
    margin: 16px 0px 0px;
  }
`;
