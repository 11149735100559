import BlueCustomButtonNoBackground from 'components/blue-custom-button-no-background';
import CardForDashboard from 'components/card-for-dashboard';
import CustomButton from 'components/custom-button';
import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import Header from 'components/general-components/header';
import LinkWithButtonAppearance from 'components/link-with-button-appearance';
import LoadingDashboard from 'components/loading-dashboard';
import LoadingJustProcessing from 'components/loading-just-processing';
import SimulatorDashboard from 'components/simulator-dashboard';
import StepView from 'components/step-view';
import { ReactComponent as AuthenticationSvg } from 'images/authentication.svg';
import { ReactComponent as BankDataSvg } from 'images/bank-data.svg';
import { ReactComponent as BiometricsSvg } from 'images/biometrics.svg';
import { ReactComponent as ChevronRightSvg } from 'images/blue-chevron-right.svg';
import { ReactComponent as ContractSignSvg } from 'images/contract-sign.svg';
import { ReactComponent as InfoSvg } from 'images/gray-info.svg';
import { ReactComponent as GuarantorSvg } from 'images/Illustration-guarantor.svg';
import { ReactComponent as NotApprovedSvg } from 'images/not-approved.svg';
import { ReactComponent as PaymentCompletedSvg } from 'images/payment-completed.svg';
import { ReactComponent as PaymentSvg } from 'images/payment.svg';
import { ReactComponent as ViewContratSvg } from 'images/view-contract.svg';
import { isEmpty } from 'lodash';
import { BankAccount } from 'model/bank-account';
import { BiometryStatus } from 'model/enums/biometric-status';
import { ContractStatus } from 'model/enums/contract-status';
import { ICardData, IFinancingStageComponent, IFinancingStatus } from 'model/enums/dashboard';
import FinancingStatusType from 'model/enums/financing-status-type';
import { HeaderVariants } from 'model/enums/header-variants';
import { InstallmentStatus } from 'model/enums/installment-status';
import StepMoment from 'model/enums/step-moment';
import StepType from 'model/enums/step-type';
import { TwoFactorStatus } from 'model/enums/two-factors-status';
import SystemStepCategory from 'model/enums/system-step-category';
import TypeVerifyIdentity from 'model/enums/type-verify-identity';
import { BankAccountInFinancing, ContractType, PaymentType, StatusAuthenticationAndBiometrics } from 'model/financing';
import { Guarantor } from 'model/guarantor';
import OrganizationsSystemSteps from 'model/organization-system-steps';
import TypeDashbordCreditStage from 'model/type-dashbord-credit-stage';
import QRCode from 'qrcode.react';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { IRootState } from 'reducer';
import bankService from 'services/bank-service';
import clientService from 'services/client-service';
import financingService from 'services/financing-service';
import guarantorService from 'services/guarantor-service';
import organizationsSystemStepsService from 'services/organization-system-steps-service';
import { ordererInstallments, stepsOrdererByStepMomentAndOrderStep } from 'shared/util/utils';
import BankAccountSelection from './bank-account-selection';
import financingAnalysisData from './financing-analysis';
import GuarantorSelection from './guarantor-selection';
import StepsController from './steps-controller';
import {
  PaintSvg,
  StyledButtonsContainer,
  StyledColumn,
  StyledContainer,
  StyledInnerContainer,
  StyledNameContainer,
  StyledStage,
  StyledText,
} from './styles';
import contractQueueService from 'services/contract-queue-service';
import BankDetailsRegistrationFormPix from './bank-details-registration-form-pix';
import { ORGANIZATION_NAME } from 'config/constants';
import { OrganizationName } from 'model/enums/organization-name';
import BankAccountForm from './bank-account-form';

const Dashboard = ({ t }: WithTranslation) => {
  const history = useHistory();
  const location = useLocation<IFinancingStatus>();
  const { financingId, organization, biometricsIsNecessary } = location.state;
  const loggedUserData = useSelector((state: IRootState) => state.authentication.account);

  const [isLoadingSystemSteps, setIsLoadingSystemSteps] = useState<boolean>(true);
  const [isInternalDataLoading, setIsInternalDataLoading] = useState<boolean>(true);
  const [isLoadingBankAccount, setIsLoadingBankAccount] = useState<boolean>(false);
  const [isLoadingBiometricsOrAuthenticationButton, setIsLoadingBiometricsOrAuthenticationButton] = useState<boolean>(false);
  const [isFinishedLoadingSimulatorData, setIsFinishedLoadingSimulatorData] = useState<boolean>(false);
  const [isFundingAlreadyConfirmed, setIsFundingAlreadyConfirmed] = useState<boolean>(false);
  const [isLockChangeAllStepsBeforeValidation, setIsLockChangeAllStepsBeforeValidation] = useState<boolean>(false);

  const [steps, setSteps] = useState<OrganizationsSystemSteps[]>([]);
  const [activeStepIndex, setActiveStepIndex] = useState<number>();
  const [lastActiveStepIndex, setLastActiveStepIndex] = useState<number>(0);
  const [lastActiveStep, setLastActiveStep] = useState<OrganizationsSystemSteps>();
  const [financingStageComponentData, setFinancingStageComponentData] = useState<IFinancingStageComponent>();
  const [isLoadingSendSettingsButton, setIsLoadingSendSettingsButton] = useState<boolean>(false);

  const [isShowModalAddGuarantor, setIsShowModalAddGuarantor] = useState<boolean>(false);
  const [isShowListDataModal, setIsShowListDataModal] = useState<boolean>(false);
  const [allGuarantorsInFinancing, setAllGuarantorsInFinancing] = useState<Guarantor[]>([]);
  const [listGuarantorsToAddFinancing, setListGuarantorsToAddFinancing] = useState<Guarantor[]>([]);
  const [lastGuarantorsSelected, setLastGuarantorsSelected] = useState<Guarantor>();

  const [isShowModalSelectBankAccount, setIsShowModalSelectBankAccount] = useState<boolean>(false);
  const [isShowModalCreateBankAccount, setIsShowModalCreateBankAccount] = useState<boolean>(false);
  const [allBankAccounts, setAllBankAccounts] = useState<BankAccount[]>([]);
  const [bankAccountInFinancing, setBankAccountInFinancing] = useState<BankAccountInFinancing>();
  const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccount>();

  const [financingContracts, setFinancingContracts] = useState<ContractType>();
  const [financingPayments, setFinancingPayments] = useState<PaymentType>();
  const [statusAuthenticationAndBiometrics, setStatusAuthenticationAndBiometrics] = useState<StatusAuthenticationAndBiometrics>();
  const [verifyIdentity, setVerifyIdentity] = useState<TypeVerifyIdentity>();

  const [cardData, setCardData] = useState<ICardData[]>([]);

  let keepCheckingBiometrics: NodeJS.Timeout | null = null;
  let keepCheckingAuthentication: NodeJS.Timeout | null = null;
  let keepCheckingContractSignature: NodeJS.Timeout | null = null;

  const [financingStatus, setFinancingStatus] = useState<FinancingStatusType>(FinancingStatusType.IN_ANALYSIS);
  const [consultantComment, setConsultantComment] = useState<string>();

  useEffect(() => {
    const fetchData = async () => {
      const financing = await financingService.getFinancingById(financingId);
      if (financing.financingStatus) {
        setFinancingStatus(financing.financingStatus);
      }
      setConsultantComment(financing.consultantComment);
    };

    fetchData();

    handleGetSteps();
  }, []);

  useEffect(() => {
    steps.map((step, index) => {
      if (index > 0) {
        const previousStepCardData = StepsController({
          step: steps[index - 1].systemStep.step,
          financingStatus,
          isFundingAlreadyConfirmed,
          allGuarantorsInFinancing,
          setIsShowModalAddGuarantor,
          bankAccountInFinancing,
          setIsShowModalSelectBankAccount,
          financingContracts,
          financingPayments,
          statusAuthenticationAndBiometrics,
          organization,
          verifyIdentity,
        });

        if (previousStepCardData != null) {
          const data = StepsController({
            step: step.systemStep.step,
            financingStatus,
            isFundingAlreadyConfirmed,
            allGuarantorsInFinancing,
            setIsShowModalAddGuarantor,
            bankAccountInFinancing,
            setIsShowModalSelectBankAccount,
            financingContracts,
            financingPayments,
            statusAuthenticationAndBiometrics,
            previousStep: previousStepCardData,
            organization,
            verifyIdentity,
          });

          if (data != null) {
            setCardData(old => [...old, data]);

            if (data.isActive) {
              setLastActiveStep(step);
              setLastActiveStepIndex(index);

              if (step.stepMoment === StepMoment.AFTER_VALIDATION) {
                setIsLockChangeAllStepsBeforeValidation(true);
              }
            }
          }
        }
      } else {
        const data = StepsController({
          step: step.systemStep.step,
          financingStatus,
          isFundingAlreadyConfirmed,
          allGuarantorsInFinancing,
          setIsShowModalAddGuarantor,
          bankAccountInFinancing,
          setIsShowModalSelectBankAccount,
          financingContracts,
          financingPayments,
          statusAuthenticationAndBiometrics,
          organization,
        });

        if (data != null) {
          setCardData([data]);

          if (data.isActive) {
            setLastActiveStep(step);
            setLastActiveStepIndex(index);
          }
        }
      }
    });
  }, [
    steps,
    allGuarantorsInFinancing,
    bankAccountInFinancing,
    isFinishedLoadingSimulatorData,
    isFundingAlreadyConfirmed,
    statusAuthenticationAndBiometrics,
    financingContracts,
    financingPayments,
    financingStatus,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      cardData.map(async card => {
        if (card.isActive && !card.isDataFilled && card.stepCategory != null) {
          await handleRequestsStep(card.stepCategory);
        }
      });
    };

    fetchData();
    setIsInternalDataLoading(false);
  }, [cardData]);

  useEffect(() => {
    if (lastActiveStep != null) {
      setFinancingStageComponentData(financingStageComponent(lastActiveStep.systemStep.step, lastActiveStep.mandatory));
    } else {
      setFinancingStageComponentData(financingStageComponent(SystemStepCategory.FINANCING_ANALYSIS));
    }
  }, [lastActiveStep, isInternalDataLoading, statusAuthenticationAndBiometrics, financingPayments, financingContracts]);

  useEffect(() => {
    if (
      statusAuthenticationAndBiometrics?.twoFactorAuth != null &&
      !statusAuthenticationAndBiometrics?.twoFactorAuth[0]?.verified &&
      statusAuthenticationAndBiometrics?.twoFactorAuth[0]?.status !== TwoFactorStatus.REPLIED &&
      keepCheckingAuthentication == null
    ) {
      keepCheckingAuthentication = setInterval(() => verifyAuthentication(), 30000);
    }

    return () => {
      if (keepCheckingAuthentication != null) {
        clearInterval(keepCheckingAuthentication);
      }
    };
  }, [statusAuthenticationAndBiometrics]);

  useEffect(() => {
    if (
      statusAuthenticationAndBiometrics?.biometricsStatus?.score == null &&
      statusAuthenticationAndBiometrics?.twoFactorAuth?.[0]?.verified &&
      keepCheckingBiometrics == null
    ) {
      keepCheckingBiometrics = setInterval(() => verifyBiometrics(), 30000);
    }

    return () => {
      if (keepCheckingBiometrics != null) {
        clearInterval(keepCheckingBiometrics);
      }
    };
  }, [statusAuthenticationAndBiometrics?.biometricsStatus?.status]);

  useEffect(() => {
    if (
      financingContracts?.contracts != null &&
      !financingContracts.contracts.map(contract => contract.status).includes(ContractStatus.FINISHED) &&
      keepCheckingContractSignature == null
    ) {
      keepCheckingContractSignature = setInterval(() => getFinancingContract(), 30000);
    }

    return () => {
      if (keepCheckingContractSignature != null) {
        clearInterval(keepCheckingContractSignature);
      }
    };
  }, [financingContracts?.id]);

  const handleGetSteps = () => {
    organizationsSystemStepsService
      .getSteps(StepType.CCB)
      .then(response => {
        if (!biometricsIsNecessary) {
          const antiFraudSteps = [
            SystemStepCategory.FRAUD_ANALYSIS_BIOMETRY,
            SystemStepCategory.FRAUD_ANALYSIS,
            SystemStepCategory.DOC_FRAUD_ANALYSIS,
            SystemStepCategory.FRAUD_ANALYSIS_2FA,
          ];

          const stepsWithoutAntiFraud = response.filter(steps => !antiFraudSteps.includes(steps.systemStep.step));

          setSteps([...stepsWithoutAntiFraud].sort(stepsOrdererByStepMomentAndOrderStep));
        } else {
          getIfClientNeedsToDoBiometricsOrAuthentication();

          setSteps([...response].sort(stepsOrdererByStepMomentAndOrderStep));
        }
      })
      .finally(() => setIsLoadingSystemSteps(false));
  };

  const getAllGuarantorsInFinancing = () => {
    guarantorService.getAllGuarantorsInFinancing(financingId).then(response => {
      setAllGuarantorsInFinancing(response.data.guarantors);
      setListGuarantorsToAddFinancing(response.data.guarantors);
    });
  };

  const getRegisteredBanks = () => {
    bankService.getBankAccount().then(response => setAllBankAccounts(response.data));
  };

  const getFinancingBankAccount = () => {
    financingService.getFinancingBankAccount(financingId).then(response => {
      setBankAccountInFinancing(response.data);
    });
  };

  const handleSendingGuarantorsForFinancing = () => {
    if (listGuarantorsToAddFinancing.length > 0) {
      financingService
        .addGuarantorsInFinancing(financingId, {
          id: financingId,
          guarantors: listGuarantorsToAddFinancing.map(guarantor => ({
            id: guarantor.id,
          })),
        })
        .then(() => {
          getAllGuarantorsInFinancing();
          resetModal();
        });
    }
  };

  const handleSendingBankAccountForFinancing = () => {
    if (selectedBankAccount?.id != null) {
      setIsLoadingBankAccount(true);

      financingService.addBankAccountInFinancing(financingId, selectedBankAccount.id).then(() => {
        resetModal();
        getFinancingBankAccount();
        setIsLoadingBankAccount(false);
      });
    }
  };

  const getFinancingContract = async () => {
    const contractsFromFinancing = await financingService.getFinancingContract(financingId);
    const contractQueueForFinancingExists = (await contractQueueService.getContractQueueExists(financingId)).contractQueueExists;
    const financingHasContracts = contractsFromFinancing.data.contracts.length > 0;

    if (financingHasContracts) {
      if (
        contractsFromFinancing.data.contracts.map(contract => contract.status).includes(ContractStatus.FINISHED) &&
        keepCheckingContractSignature != null
      ) {
        clearInterval(keepCheckingContractSignature);
      }

      setFinancingContracts(contractsFromFinancing.data);
    } else if (!financingHasContracts && !contractQueueForFinancingExists) {
      createContractQueue();
    }
  };

  const getFinancingPayments = async () => {
    await financingService.getFinancingPayments(financingId).then(response => {
      if (financingPayments === undefined) {
        response.data.installments = [...response.data.installments].sort(ordererInstallments);
        setFinancingPayments(response.data);
      }
    });
  };

  const createContractQueue = () => {
    contractQueueService.createContractQueue(financingId).then(() => getFinancingContract());
  };

  const verifyAuthentication = () => {
    financingService.verifyAuthenticationAndBiometrics(financingId).then(response => {
      if (keepCheckingAuthentication != null) {
        clearInterval(keepCheckingAuthentication);
      }

      setStatusAuthenticationAndBiometrics(response);
      setIsLoadingBiometricsOrAuthenticationButton(false);
    });
  };

  const verifyBiometrics = () => {
    financingService.verifyAuthenticationAndBiometrics(financingId).then(response => {
      if (response.biometricsStatus == null && (verifyIdentity === TypeVerifyIdentity.NECESSARY || verifyIdentity == null)) {
        return biometricsRequest();
      }

      if (response?.biometricsStatus?.score != null && keepCheckingBiometrics != null) {
        clearInterval(keepCheckingBiometrics);
      }

      setStatusAuthenticationAndBiometrics(response);
      setIsLoadingBiometricsOrAuthenticationButton(false);
    });
  };

  const biometricsRequest = () => {
    financingService.biometricsRequest(financingId).then(() => verifyBiometrics());
  };

  const getIfClientNeedsToDoBiometricsOrAuthentication = () => {
    if (loggedUserData?.client?.id != null) {
      clientService
        .getIfClientNeedsToDoBiometricsOrAuthentication(loggedUserData?.client?.id)
        .then(result => setVerifyIdentity(result.isToVerifyIdentity));
    }
  };

  const resetModal = () => {
    setIsShowListDataModal(false);

    setIsShowModalAddGuarantor(false);
    setListGuarantorsToAddFinancing([]);
    setLastGuarantorsSelected(undefined);

    setIsShowModalSelectBankAccount(false);
    setSelectedBankAccount(undefined);

    setIsShowModalCreateBankAccount(false);
  };

  const backCreditsList = () => {
    history.goBack();
  };

  const handleRequestsStep = async (step: SystemStepCategory) => {
    switch (step) {
      case SystemStepCategory.BANK_ACCOUNT_DATA:
        if (bankAccountInFinancing === undefined) {
          await getFinancingBankAccount();
        }

        if (allBankAccounts.length < 1) {
          await getRegisteredBanks();
        }
        break;
      case SystemStepCategory.GUARANTORS:
        await getAllGuarantorsInFinancing();
        break;
      case SystemStepCategory.CONTRACT:
        if (financingContracts?.contracts == null) {
          await getFinancingContract();
        }
        break;
      case SystemStepCategory.PAYMENT:
        if (financingPayments === undefined) {
          await getFinancingPayments();
        }
        break;
      case SystemStepCategory.FRAUD_ANALYSIS_BIOMETRY:
        if (statusAuthenticationAndBiometrics?.biometricsStatus == null) {
          await verifyBiometrics();
        }
        break;
      case SystemStepCategory.FRAUD_ANALYSIS_2FA:
        if (statusAuthenticationAndBiometrics?.twoFactorAuth == null || statusAuthenticationAndBiometrics?.twoFactorAuth.length === 0) {
          await verifyAuthentication();
        }
        break;
      default:
        break;
    }
  };

  const financingStageComponent = (step: SystemStepCategory, isMandatory?: boolean) => {
    const financingStage: TypeDashbordCreditStage = {
      SIMULATOR: {
        title: t('dashboard.confirmationInstallments'),
        children: isFundingAlreadyConfirmed ? (
          <span>{t('dashboard.completedCreditConfirmation')}</span>
        ) : (
          <>
            <StyledText>{t('dashboard.nowYouJustNeedConfirmRequestedCredit')}</StyledText>
            <SimulatorDashboard
              financingId={financingId}
              setIsFinishedLoadingSimulatorData={setIsFinishedLoadingSimulatorData}
              setIsFundingAlreadyConfirmed={setIsFundingAlreadyConfirmed}
            />
          </>
        ),
      },
      GUARANTORS: {
        title: t('dashboard.guarantorRegistration'),
        subtitle: allGuarantorsInFinancing?.length > 0 ? t('dashboard.registeredGuarantors') : t('dashboard.approvedRegistration'),
        text: allGuarantorsInFinancing?.length > 0 ? t('dashboard.nowThatGuarantorsAreRegistered') : t('dashboard.moreGuarantors'),
        children: <GuarantorSvg />,
        footer: (
          <StyledButtonsContainer $isReverseDirection={allGuarantorsInFinancing?.length > 0}>
            {(!isMandatory || allGuarantorsInFinancing?.length > 0) && (
              <CustomButton style={{ justifyContent: 'center' }} height={48} width="204px" isInvertColor>
                {allGuarantorsInFinancing?.length > 0 ? t('global.button.validateGuarantors') : t('global.button.sendWithoutGuarantors')}
              </CustomButton>
            )}
            <CustomButton style={{ justifyContent: 'center' }} height={48} width="194px" onClick={() => setIsShowModalAddGuarantor(true)}>
              {t('global.button.addGuarantors')}
            </CustomButton>
          </StyledButtonsContainer>
        ),
      },
      BANK_ACCOUNT_DATA: {
        title: t('dashboard.bankData'),
        subtitle: t('dashboard.creditConfirmed'),
        text: t('dashboard.willBeAccount'),
        children: (
          <PaintSvg className="bankDataSvg">
            <BankDataSvg />
          </PaintSvg>
        ),
        footer: (
          <CustomButton
            style={{ marginTop: '36px', justifyContent: 'center' }}
            height={48}
            width="255px"
            onClick={() => setIsShowModalSelectBankAccount(true)}
          >
            {t('global.button.addBankData')}
          </CustomButton>
        ),
      },
      CONTRACT: {
        title: t('dashboard.contractSigning'),
        subtitle:
          financingContracts?.contracts == null ||
          financingContracts.contracts.map(contract => contract.status).includes(ContractStatus.NEW)
            ? t('dashboard.waitSignContract')
            : t('dashboard.almostThere'),
        text:
          financingContracts?.contracts == null ||
          financingContracts.contracts.map(contract => contract.status).includes(ContractStatus.NEW)
            ? t('dashboard.shortlyReceiveEmailWithInstructionsToSign')
            : t('dashboard.youFewStepsRealizingDream'),
        children:
          financingContracts?.contracts == null ||
          financingContracts.contracts.map(contract => contract.status).includes(ContractStatus.NEW) ? (
            <PaintSvg className="viewContratSvg">
              <ViewContratSvg />
            </PaintSvg>
          ) : (
            <PaintSvg className="contractSignSvg">
              <ContractSignSvg />
            </PaintSvg>
          ),
        footer:
          financingContracts?.contracts != null &&
          !financingContracts.contracts.map(contract => contract.status).includes(ContractStatus.NEW) ? (
            <>
              {financingContracts.contracts[0].contractSign.contractSignatories[0].signatureLink != null &&
                (isMobile ? (
                  <LinkWithButtonAppearance
                    url={financingContracts.contracts[0].contractSign.contractSignatories[0].signatureLink}
                    width="197px"
                    style={{ margin: '24px auto 24px auto' }}
                  >
                    {t('global.button.signContract')}
                  </LinkWithButtonAppearance>
                ) : (
                  <QRCode renderAs="svg" value={financingContracts.contracts[0].contractSign.contractSignatories[0].signatureLink} />
                ))}

              <CustomButton
                style={{ justifyContent: 'center', margin: '0 auto 24px auto' }}
                height={48}
                width="197px"
                onClick={() => history.push(`/meus-contratos/${financingContracts.contracts[0].id}`)}
                loading={isLoadingBiometricsOrAuthenticationButton}
              >
                {t('global.button.viewContract')}
              </CustomButton>
            </>
          ) : (
            <></>
          ),
      },
      PAYMENT: {
        title: t('dashboard.payment'),
        subtitle:
          financingPayments?.installments == null || financingPayments?.payment?.status === FinancingStatusType.NEW
            ? t('dashboard.waitPaymentInstallments')
            : financingPayments?.payment?.status === FinancingStatusType.FINISHED
              ? t('dashboard.congratulationsPaymentCompleted')
              : financingPayments?.installments[0].status === InstallmentStatus.PENDING
                ? t('dashboard.firstInstallmentAvailable')
                : t('dashboard.installmentsAvailableForPayment'),
        text:
          financingPayments?.installments == null || financingPayments?.payment?.status === FinancingStatusType.NEW
            ? t('dashboard.willReceiveTicketSoon')
            : financingPayments?.payment?.status === FinancingStatusType.FINISHED
              ? t('dashboard.allInstallmentsSuccessfullyPaid')
              : financingPayments?.installments[0]?.status === InstallmentStatus.PENDING
                ? t('dashboard.yourFirstInstallmentPaymentAcvailable')
                : t('dashboard.installmentsAvailableForPayment'),
        children:
          financingPayments?.installments == null || financingPayments?.payment?.status === FinancingStatusType.NEW ? (
            <PaintSvg className="viewContratSvg">
              <ViewContratSvg />
            </PaintSvg>
          ) : financingPayments?.payment?.status === FinancingStatusType.FINISHED ? (
            <PaintSvg className="paymentCompletedSvg">
              <PaymentCompletedSvg />
            </PaintSvg>
          ) : (
            <PaintSvg className="paymentSvg">
              <PaymentSvg />
            </PaintSvg>
          ),
        footer:
          financingPayments?.installments[0].bankSlip != null ? (
            <CustomButton
              style={{ marginTop: '36px', justifyContent: 'center' }}
              height={48}
              width="181px"
              onClick={() => history.push(`/meus-pagamentos/${financingPayments?.id}`)}
            >
              {t('global.button.viewTicket')}
            </CustomButton>
          ) : (
            <></>
          ),
      },
      DOC_FRAUD_ANALYSIS: {
        title: t('DOC_FRAUD_ANALYSIS'),
        children: <></>,
      },
      FRAUD_ANALYSIS: {
        title: t('dashboard.fraudAnalysis'),
        children: <></>,
      },
      FRAUD_ANALYSIS_2FA: {
        title: t('dashboard.authentication'),
        subtitle:
          verifyIdentity === TypeVerifyIdentity.IS_NOT_NECESSARY
            ? t('dashboard.authenticationNotRequired')
            : statusAuthenticationAndBiometrics?.twoFactorAuth == null ||
                statusAuthenticationAndBiometrics?.twoFactorAuth.length === 0 ||
                (statusAuthenticationAndBiometrics?.twoFactorAuth?.[0].status === TwoFactorStatus.SENT &&
                  !statusAuthenticationAndBiometrics?.twoFactorAuth?.[0].verified)
              ? t('dashboard.smsWillSentWithInstructions')
              : statusAuthenticationAndBiometrics?.twoFactorAuth?.[0].verified
                ? t('dashboard.completedAuthentication')
                : t('dashboard.wereUnableAuthentication'),
        text:
          verifyIdentity === TypeVerifyIdentity.IS_NOT_NECESSARY
            ? ''
            : statusAuthenticationAndBiometrics?.twoFactorAuth == null ||
                statusAuthenticationAndBiometrics?.twoFactorAuth.length === 0 ||
                (statusAuthenticationAndBiometrics?.twoFactorAuth?.[0]?.status === TwoFactorStatus.SENT &&
                  !statusAuthenticationAndBiometrics?.twoFactorAuth?.[0].verified)
              ? t('dashboard.checkStepReceivedCellProceed')
              : t('dashboard.thisFunctionCurrentlyNotAvailable', { email: organization?.contactEmail }),
        children:
          statusAuthenticationAndBiometrics?.twoFactorAuth == null ||
          statusAuthenticationAndBiometrics?.twoFactorAuth.length === 0 ||
          statusAuthenticationAndBiometrics?.twoFactorAuth?.[0]?.verified ||
          (statusAuthenticationAndBiometrics?.twoFactorAuth?.[0].status === TwoFactorStatus.SENT &&
            !statusAuthenticationAndBiometrics?.twoFactorAuth?.[0].verified) ? (
            <PaintSvg className="authenticationSvg">
              <AuthenticationSvg />
            </PaintSvg>
          ) : (
            <PaintSvg className="authenticationNotApprovedSvg">
              <NotApprovedSvg />
            </PaintSvg>
          ),
        footer:
          verifyIdentity === TypeVerifyIdentity.IS_NOT_NECESSARY ? (
            <></>
          ) : statusAuthenticationAndBiometrics?.twoFactorAuth?.[0]?.verified ||
            statusAuthenticationAndBiometrics?.twoFactorAuth?.[0]?.status === TwoFactorStatus.REPLIED ? (
            <></>
          ) : (
            <StyledButtonsContainer $isReverseDirection={false}>
              <CustomButton
                style={{ justifyContent: 'center' }}
                height={48}
                width="214px"
                onClick={() => {
                  setIsLoadingBiometricsOrAuthenticationButton(true);
                  verifyAuthentication();
                }}
                loading={isLoadingBiometricsOrAuthenticationButton}
              >
                {t('global.button.endAuthentication')}
              </CustomButton>
            </StyledButtonsContainer>
          ),
      },
      FRAUD_ANALYSIS_BIOMETRY: {
        title: t('dashboard.biometrics'),
        subtitle:
          verifyIdentity === TypeVerifyIdentity.IS_NOT_NECESSARY
            ? t('dashboard.biometricsNotRequired')
            : statusAuthenticationAndBiometrics?.biometricsStatus?.score == null
              ? isMobile
                ? t('dashboard.registrationValidation')
                : t('selfie.qrCode.title')
              : statusAuthenticationAndBiometrics?.biometricsStatus?.status === BiometryStatus.DONE &&
                  statusAuthenticationAndBiometrics?.biometricsStatus?.score != null
                ? t('dashboard.completedBiometrics')
                : t('dashboard.unableApproveBiometrics'),
        text:
          verifyIdentity === TypeVerifyIdentity.IS_NOT_NECESSARY ? (
            ''
          ) : statusAuthenticationAndBiometrics?.biometricsStatus?.score == null ? (
            isMobile ? (
              <Trans i18nKey={'dashboard.clickButtonProceedRegistrationValidation'} />
            ) : (
              t('dashboard.scanQrCodeToProceedRegistrationValidation')
            )
          ) : (
            t('dashboard.thisFunctionCurrentlyNotAvailable', { email: organization?.contactEmail })
          ),
        children: (
          <PaintSvg className="biometricsSvg">
            <BiometricsSvg />
          </PaintSvg>
        ),
        footer:
          verifyIdentity === TypeVerifyIdentity.IS_NOT_NECESSARY ? (
            <></>
          ) : statusAuthenticationAndBiometrics?.biometricsStatus != null &&
            statusAuthenticationAndBiometrics?.biometricsStatus?.score == null &&
            !isEmpty(statusAuthenticationAndBiometrics?.biometricsStatus?.url) ? (
            <>
              {isMobile ? (
                <LinkWithButtonAppearance
                  url={statusAuthenticationAndBiometrics.biometricsStatus.url}
                  width="214px"
                  style={{ margin: '24px auto 24px auto' }}
                >
                  {t('dashboard.registrationValidation')}
                </LinkWithButtonAppearance>
              ) : (
                <QRCode renderAs="svg" value={statusAuthenticationAndBiometrics.biometricsStatus.url} />
              )}

              <CustomButton
                style={{ justifyContent: 'center', margin: '0 auto 24px auto' }}
                height={48}
                width="214px"
                onClick={() => {
                  setIsLoadingBiometricsOrAuthenticationButton(true);
                  verifyBiometrics();
                  window.location.reload();
                }}
                loading={isLoadingBiometricsOrAuthenticationButton}
              >
                {t('global.button.finalizeBiometrics')}
              </CustomButton>
            </>
          ) : statusAuthenticationAndBiometrics?.biometricsStatus == null ? (
            <LoadingJustProcessing text={t('simulatorFLow.processingScreen.processingData')} />
          ) : (
            <></>
          ),
      },
      FINANCING_ANALYSIS: financingAnalysisData({
        financingId,
        financingStatus,
        consultantComment,
        isLoadingSendSettingsButton,
        setIsLoadingSendSettingsButton,
        backCreditsList,
      }),
      LOADING: {
        title: '',
        children: <LoadingDashboard showSummaryLoading />,
      },
    };

    if (isInternalDataLoading) {
      return financingStage[SystemStepCategory.LOADING];
    }

    if (step != null) {
      return financingStage[step];
    }

    return financingAnalysisData({
      financingId,
      financingStatus,
      consultantComment,
      isLoadingSendSettingsButton,
      setIsLoadingSendSettingsButton,
      backCreditsList,
    });
  };

  return (
    <StyledContainer>
      <Header variant={HeaderVariants.SECONDARY} />
      {isLoadingSystemSteps ? (
        <LoadingDashboard />
      ) : (
        <StyledInnerContainer>
          <StyledColumn>
            <StyledNameContainer>
              <BlueCustomButtonNoBackground onClick={() => history.push('/creditos')}>
                <ChevronRightSvg style={{ transform: 'rotate(180deg)' }} />
                {t('global.button.backStep')}
              </BlueCustomButtonNoBackground>
            </StyledNameContainer>

            <StyledStage>
              {t('dashboard.creditStage')}
              <InfoSvg />
            </StyledStage>
            <StepView
              title={financingStageComponentData?.title ?? ''}
              subtitle={financingStageComponentData?.subtitle}
              text={financingStageComponentData?.text}
              lastStep={lastActiveStepIndex}
              activeStep={activeStepIndex}
              steps={steps.map(step => step.systemStep.step)}
              footer={financingStageComponentData?.footer}
            >
              {financingStageComponentData?.children ?? <></>}
            </StepView>
          </StyledColumn>
          <StyledColumn>
            {cardData.map((card, index) => (
              <CardForDashboard
                loading={isInternalDataLoading}
                icon={card?.icon}
                title={card?.title}
                content={card?.content}
                isActive={card?.isActive}
                key={`card-${index}`}
                onHandleClick={() => {
                  if (card.stepCategory != null && !isLockChangeAllStepsBeforeValidation) {
                    setFinancingStageComponentData(financingStageComponent(card.stepCategory, card.mandatory));
                    setActiveStepIndex(index);
                  }
                }}
                disableClick={isLockChangeAllStepsBeforeValidation && card.stepCategory !== lastActiveStep?.systemStep.step}
                arrowButton={card?.isShowArrow}
                flexCard
                redirectPathClickArrow={card.redirectPathClickArrow ?? ''}
              />
            ))}
          </StyledColumn>
        </StyledInnerContainer>
      )}

      <CustomModalTwoButtons
        isShowModal={isShowModalAddGuarantor}
        onCloseModal={() => resetModal()}
        title={t('dashboard.addGuarantors')}
        children={
          <GuarantorSelection
            isShowListDataModal={isShowListDataModal}
            lastGuarantorsSelected={lastGuarantorsSelected}
            listGuarantorsToAddFinancing={listGuarantorsToAddFinancing}
            setIsShowListDataModal={setIsShowListDataModal}
            setLastGuarantorsSelected={setLastGuarantorsSelected}
            setListGuarantorsToAddFinancing={setListGuarantorsToAddFinancing}
          />
        }
        firstButtonText={t('global.button.cancel')}
        secondButtonText={t('global.button.add')}
        handleClickFirstButton={() => resetModal()}
        handleClickSecondButton={() => handleSendingGuarantorsForFinancing()}
      />

      <CustomModalTwoButtons
        isShowModal={isShowModalSelectBankAccount}
        large
        onCloseModal={() => resetModal()}
        title={t('dashboard.bankData')}
        children={
          <BankAccountSelection
            isShowListDataModal={isShowListDataModal}
            selectedBankAccount={selectedBankAccount}
            allBankAccounts={allBankAccounts}
            setIsShowListDataModal={setIsShowListDataModal}
            setSelectedBankAccount={setSelectedBankAccount}
            setIsShowModalCreateBankAccount={setIsShowModalCreateBankAccount}
            getRegisteredBanks={getRegisteredBanks}
            getFinancingBankAccount={getFinancingBankAccount}
          />
        }
        firstButtonText={t('global.button.cancel')}
        secondButtonText={t('global.button.add')}
        handleClickFirstButton={() => resetModal()}
        handleClickSecondButton={() => handleSendingBankAccountForFinancing()}
        loading={isLoadingBankAccount}
        disableSecondaryButton={selectedBankAccount == null}
      />

      <CustomModalTwoButtons
        formId="bank-account-creation-form"
        large
        isShowModal={isShowModalCreateBankAccount}
        onCloseModal={() => setIsShowModalCreateBankAccount(false)}
        title={selectedBankAccount?.id != null ? t('dashboard.editBankDetails') : t('dashboard.addBankDetails')}
        children={
          ORGANIZATION_NAME !== OrganizationName.ZM ? (
            <BankDetailsRegistrationFormPix
              props={{
                clientData: loggedUserData?.client?.physicalPerson,
                selectedBankAccount,
                setSelectedBankAccount,
                getRegisteredBanks,
                setIsShowModalCreateBankAccount,
                setIsLoadingBankAccount,
              }}
            />
          ) : (
            <BankAccountForm
              selectedBankAccount={selectedBankAccount}
              setSelectedBankAccount={setSelectedBankAccount}
              setIsShowModalCreateBankAccount={setIsShowModalCreateBankAccount}
              setIsLoadingBankAccount={setIsLoadingBankAccount}
              clientData={loggedUserData?.client?.physicalPerson}
              getRegisteredBanks={getRegisteredBanks}
            />
          )
        }
        firstButtonText={t('global.button.cancel')}
        secondButtonText={selectedBankAccount?.id != null ? t('global.button.save') : t('global.button.add')}
        handleClickFirstButton={() => setIsShowModalCreateBankAccount(false)}
        loading={isLoadingBankAccount}
      />
    </StyledContainer>
  );
};

export default withTranslation()(Dashboard);
