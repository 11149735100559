import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.borderGray};
  border-radius: 8px;
  box-shadow: 0px 2px 12px ${({ theme }) => theme.color.shadow};

  margin-top: 32px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 45px;
  padding-bottom: 40px;

  span.title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.gray21};
    margin-top: 20px;
  }

  span.message {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.gray19};
    margin-top: 16px;
  }
`;
