import styled from 'styled-components';

interface Props {
  $error?: boolean;
}

export const InputContainer = styled.button<Props>`
  min-width: 306px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${props => (!props.$error ? props.theme.color.gray24 : props.theme.color.canceled)};
  position: relative;

  > input {
    display: none;
  }

  > span.placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: ${({ theme }) => theme.color.gray21};
    background-color: transparent;
  }
`;

export const Label = styled.label`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray18};
  text-align: start;
`;

export const Title = styled.span<Props>`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray18};
  display: inline-block;

  transition: all 0.3s;
`;

export const Blank = styled.span`
  color: transparent;
`;

export const OptionContainer = styled.div`
  position: absolute;
  top: 35px;
  left: 0;
  z-index: 100;
  height: 100px;
  overflow-y: auto;

  padding: 16px;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.borderGray};
  box-shadow: 0px 4px 4px ${({ theme }) => theme.color.shadow};

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  > button + button {
    border-top: 1px solid ${({ theme }) => theme.color.gray20};
  }
`;

export const Option = styled.button`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray19};

  width: 100%;
  border: none;
  background-color: transparent;
  text-align: left;
  padding: 8px;
`;

export const StyledErrorMessage = styled.span`
  position: absolute;
  bottom: -19px;
  color: ${({ theme }) => theme.color.canceled};
  font-size: 12px;
`;
