import { useTheme } from 'styled-components';

export const StatusLogoIcon = ({ isWhite }) => {
  const theme = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_8286_10393)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00033 11.3334C8.36852 11.3334 8.66699 11.6319 8.66699 12.0001V14.6667C8.66699 15.0349 8.36852 15.3334 8.00033 15.3334C7.63214 15.3334 7.33366 15.0349 7.33366 14.6667V12.0001C7.33366 11.6319 7.63214 11.3334 8.00033 11.3334Z"
          fill={isWhite ? '#fff' : theme.color.primaryColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3556 10.3553C10.6159 10.095 11.038 10.095 11.2984 10.3553L13.1851 12.242C13.4454 12.5024 13.4454 12.9245 13.1851 13.1848C12.9247 13.4452 12.5026 13.4452 12.2423 13.1848L10.3556 11.2982C10.0952 11.0378 10.0952 10.6157 10.3556 10.3553Z"
          fill={isWhite ? '#fff' : theme.color.primaryColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.64506 10.3553C5.90541 10.6157 5.90541 11.0378 5.64506 11.2982L3.7584 13.1848C3.49805 13.4452 3.07594 13.4452 2.81559 13.1848C2.55524 12.9245 2.55524 12.5024 2.81559 12.242L4.70225 10.3553C4.9626 10.095 5.38471 10.095 5.64506 10.3553Z"
          fill={isWhite ? '#fff' : theme.color.primaryColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3337 8.00008C11.3337 7.63189 11.6321 7.33341 12.0003 7.33341H14.667C15.0352 7.33341 15.3337 7.63189 15.3337 8.00008C15.3337 8.36827 15.0352 8.66675 14.667 8.66675H12.0003C11.6321 8.66675 11.3337 8.36827 11.3337 8.00008Z"
          fill={isWhite ? '#fff' : theme.color.primaryColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.666992 8.00008C0.666992 7.63189 0.965469 7.33341 1.33366 7.33341H4.00033C4.36852 7.33341 4.66699 7.63189 4.66699 8.00008C4.66699 8.36827 4.36852 8.66675 4.00033 8.66675H1.33366C0.965469 8.66675 0.666992 8.36827 0.666992 8.00008Z"
          fill={isWhite ? '#fff' : theme.color.primaryColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1851 2.81534C13.4454 3.07569 13.4454 3.4978 13.1851 3.75815L11.2984 5.64482C11.038 5.90517 10.6159 5.90517 10.3556 5.64482C10.0952 5.38447 10.0952 4.96236 10.3556 4.70201L12.2423 2.81534C12.5026 2.55499 12.9247 2.55499 13.1851 2.81534Z"
          fill={isWhite ? '#fff' : theme.color.primaryColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.81559 2.81534C3.07594 2.55499 3.49805 2.55499 3.7584 2.81534L5.64506 4.70201C5.90541 4.96236 5.90541 5.38447 5.64506 5.64482C5.38471 5.90517 4.9626 5.90517 4.70225 5.64482L2.81559 3.75815C2.55524 3.4978 2.55524 3.07569 2.81559 2.81534Z"
          fill={isWhite ? '#fff' : theme.color.primaryColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00033 0.666748C8.36852 0.666748 8.66699 0.965225 8.66699 1.33341V4.00008C8.66699 4.36827 8.36852 4.66675 8.00033 4.66675C7.63214 4.66675 7.33366 4.36827 7.33366 4.00008V1.33341C7.33366 0.965225 7.63214 0.666748 8.00033 0.666748Z"
          fill={isWhite ? '#fff' : theme.color.primaryColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_8286_10393">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
