import Header from 'components/general-components/header';
import { Attachment } from 'model/attachment';
import { CreditCard, FinancingCreditCards } from 'model/credit-card';
import { CreditCardStatus } from 'model/enums/credit-card-status';
import { HeaderVariants } from 'model/enums/header-variants';
import { Contract } from 'model/financing';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft, FiPlus } from 'react-icons/fi';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import clientService from 'services/client-service';
import StringUtils from 'shared/util/string-utils';
import { useTheme } from 'styled-components';
import { AttachmentBox, CampContainer, CampName } from '../clientData/components/RegisterDataDisplay/campDisplay/styles';
import { Status } from '../dashboard/components/status';
import {
  Button,
  CardTitle,
  Content,
  ContentHeader,
  Label,
  PageContainer,
  Separator,
  Text,
  TextContainer,
  TextField,
  Title,
  TitleHeader,
} from './styles';

export const CreditCardSummaryScreen = () => {
  const { color } = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<{ prevPath: string }>();
  const { id, creditCardId } = useParams<{ id: string; creditCardId: string }>();
  const [creditCard, setCreditCard] = useState<CreditCard>();
  const [contracts, setContracts] = useState<Contract[]>();

  useEffect(() => {
    getCreditCardInfos();
  }, []);

  const getCreditCardInfos = async () => {
    if (id && creditCardId) {
      const res = await clientService.getCreditCardFromClient(Number(id));
      setCreditCard(res.find(c => c.id === Number(creditCardId)));
    }
  };

  const handleCancelCreditCard = async () => {
    if (creditCardId) {
      const res = await clientService.cancelCreditCard(Number(creditCardId));
      if (res) {
        history.push(location.state.prevPath);
      }
    }
  };

  return (
    <PageContainer>
      <Header variant={HeaderVariants.SECONDARY} />
      <TitleHeader>
        <Title>
          <button
            onClick={() => {
              if (location.state?.prevPath) {
                history.push(location.state.prevPath);
              } else {
                history.goBack();
              }
            }}
          >
            <FiChevronLeft color={color.primaryColor} />
          </button>
          <span className="title">{t('admin.creditCardScreen.pageTitle')}</span>
        </Title>
      </TitleHeader>
      <Content>
        <ContentHeader>
          {creditCard?.status !== CreditCardStatus.APPROVED && (
            <Button>
              <FiPlus size={20} color={color.primaryColor} />
              <span>{t('admin.creditCardScreen.addDispute')}</span>
            </Button>
          )}
        </ContentHeader>
        <TextContainer>
          <TextField>
            <Label>{t('admin.creditCardScreen.cardNumber')}</Label>
            <Text>{t('admin.customerAnalysis.textStepCards.creditCard', { number: creditCard?.number })}</Text>
          </TextField>
          <TextField>
            <Label>{t('admin.creditCardScreen.validity')}</Label>
            <Text>
              {t('admin.creditCardScreen.expirationDate', { month: creditCard?.expirationMonth, year: creditCard?.expirationYear })}
            </Text>
          </TextField>
          <TextField>
            <Label>{t('admin.creditCardScreen.cvv')}</Label>
            <Text>{creditCard?.cvv ?? '***'}</Text>
          </TextField>
          <TextField>
            <Label>{t('admin.creditCardScreen.cardholderName')}</Label>
            <Text>{creditCard?.cardholderName}</Text>
          </TextField>
          <TextField>
            <Label>{t('admin.creditCardScreen.cpf')}</Label>
            <Text>{StringUtils.cpfMask(creditCard?.cardholderDocument)}</Text>
          </TextField>
          <TextField>
            <Label>{t('admin.creditCardScreen.phone')}</Label>
            <Text>{StringUtils.phoneMask(creditCard?.cardholderPhone)}</Text>
          </TextField>
          {creditCard?.status && (
            <TextField>
              <Label>{t('admin.creditCardScreen.status')}</Label>
              <Status text={t(`enum.filterAllStatus.${creditCard.status}`)} color={creditCard.status} />
            </TextField>
          )}
          {creditCard?.attachment && (
            <CampContainer
              onClick={() => {
                window.open(StringUtils.getFileOrUrl(creditCard?.attachment as Attachment), '_blank');
              }}
            >
              <>
                <CampName>{creditCard?.attachment?.name}</CampName>
                <AttachmentBox>{(creditCard?.attachment as Attachment)?.fileName}</AttachmentBox>
              </>
            </CampContainer>
          )}
          <Button $rejectButton={true} onClick={() => handleCancelCreditCard()}>
            {t('global.button.rejectCreditCard')}
          </Button>
        </TextContainer>
      </Content>
    </PageContainer>
  );
};
