import styled from 'styled-components';

interface Props {
  $isSelected?: boolean;
}

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.color.grayBackground};
  height: 100vh;
  overflow: auto;
`;

export const TitleHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 48px;
  max-width: 70%;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 150%;

  > span.title {
    display: inline-block;
    font-weight: 700;
    margin-left: 16px;
  }

  > span.code {
    margin-left: 8px;
  }

  > button {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  padding: 16px 24px 24px 24px;
  border: 1px solid ${({ theme }) => theme.color.borderGray};
  box-shadow: 0px 2px 12px ${({ theme }) => theme.color.shadow};
  border-radius: 8px;
  max-width: 70%;
  margin: 32px auto 32px auto;
`;

export const TabBox = styled.div`
  background-color: ${({ theme }) => theme.color.grayBackground};
  padding: 4px;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Tab = styled.button<Props>`
  background-color: ${({ theme, $isSelected }) => ($isSelected ? theme.color.white : 'transparent')};
  border: ${({ theme, $isSelected }) => ($isSelected ? `1px solid ${theme.color.gray20}` : 'none')};
  box-shadow: ${({ theme, $isSelected }) => ($isSelected ? `0px 2px 12px ${theme.color.shadow}` : 'none')};
  border-radius: 6px;
  padding: 8px;
  flex: 1;

  font-weight: ${({ $isSelected }) => ($isSelected ? '700' : '400')};
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme, $isSelected }) => ($isSelected ? theme.color.primaryColor : theme.color.gray18)};

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
`;

export const TabContent = styled.div``;

export const InputContent = styled.div`
  width: 306px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  > label + label {
    margin-top: 35px;
  }
`;

export const FormContainer = styled.div`
  label {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-width: 306px;
    min-height: 53px;
  }
  label + label {
    margin-top: 16px;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  width: 70%;
  margin: 0 auto;
  padding-left: 50px;

  column-gap: 10px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  & > button.cancel {
    background-color: ${({ theme }) => theme.color.rejected};
    border-color: ${({ theme }) => theme.color.rejected};
  }

  @media (max-width: 1495px) {
    width: 100%;
    display: flex;
    justify-content: right;
  }
`;
