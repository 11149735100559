import { InputContainer } from './styles';
import { BiSearch } from 'react-icons/bi';
import { useTheme } from 'styled-components';
import { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface SearchInputProps extends WithTranslation {
  setSearch?: (value: string) => void;
  title?: string;
}

const SearchInput = ({ setSearch, title, t }: SearchInputProps) => {
  const { color } = useTheme();

  const [timeOutSearch, setTimeOutSearch] = useState<NodeJS.Timeout | null>(null);
  const [searchText, setSearchText] = useState<string>('');

  const handleOnChange = (value: string) => {
    setSearchText(value);

    if (timeOutSearch != null) {
      clearTimeout(timeOutSearch);
    }

    setTimeOutSearch(
      setTimeout(() => {
        if (setSearch != null) {
          setSearch(value);
        }
      }, 500)
    );
  };

  return (
    <InputContainer>
      <BiSearch color={color.primaryColor} size={16} />
      <input
        type="text"
        placeholder={!title ? t('admin.dashboard.search') : title}
        value={searchText}
        onChange={e => handleOnChange(e.target.value)}
      />
    </InputContainer>
  );
};

export default withTranslation()(SearchInput);
