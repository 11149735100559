import MobileHeader from 'components/mobile-header';
import { useHistory } from 'react-router-dom';
import CardShowContract from 'components/card-show-contract';
import { PageTitleSection, StyledContainer, StyledContainerInputRadio, StyledModalTitle, StyledUl } from '../styles';
import { StyledInnerContainer, StyledCardShowContractContainer } from '../styles';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import CustomComponentModal from 'components/custom-conmponent-modal';
import { ContractPdf } from 'features/admin/contractScreen/components/contractPdf';
import ContractContext from 'context/contract';
import { RoutePath } from 'model/enums/route-path';
import CustomButton from 'components/custom-button';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { ArrowContainer } from '../go-to-mobile/styles';
import { ReactComponent as ArrowBack } from 'images/arrow_back.svg';
import CustomInputRadio from './components/custom-input-radio';
import { isEmpty } from 'lodash';
import { documentUnicoCodes, IdentityDocumentTypes } from 'model/enums/identity-document-type';
import { StyledPopUp, StyledContainerPopup } from 'components/custom-conmponent-modal/styles';
import ScrollToTop from 'components/scroll-top';
import AlertIcon from 'images/alert-triangle2.svg';

const RegisterDocument = ({ t }: WithTranslation) => {
  const history = useHistory();

  const [countSeconds, setCountSeconds] = useState<number>(5);
  const [closeWarning, setCloseWarning] = useState<boolean>(false);
  const [showComponentModal, setShowComponentModal] = useState<boolean>(false);
  const {
    documentPresignUrl,
    signatureKey,
    identityDocument,
    setIdentityDocument,
    showErroExtractModal,
    setShowErroExtractModal,
    errorDescriptionModal,
    setErrorDescriptionModal,
  } = useContext(ContractContext);
  const { isMobile, isTablet } = useResponsiveMobile();

  useEffect(() => {
    if (!isMobile && !isTablet) {
      return history.push(`${RoutePath.QR_CODE}/${signatureKey}`);
    }
  }, [signatureKey, isMobile]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (countSeconds > 0) {
        setCountSeconds(prevCount => prevCount - 1);
      } else {
        clearInterval(intervalId);
        setCloseWarning(true);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [countSeconds]);

  const handleConfirm = () => {
    history.push(RoutePath.DOCUMENT_SUBMISSION_INSTRUCTIONS);
  };

  return (
    <StyledContainer>
      <ScrollToTop />
      <MobileHeader />
      <StyledInnerContainer>
        <PageTitleSection>
          <ArrowContainer>
            <ArrowBack onClick={() => history.push(RoutePath.LETS_SIGN)} />
            <span>{t('letsSign.stepOfTotal', { step: '3' })}</span>
          </ArrowContainer>
          <p>{t('contract.registerDocument.selectDocument.title')}</p>
          <span>{t('contract.registerDocument.selectDocument.subtitle')}</span>
        </PageTitleSection>

        <StyledContainerInputRadio>
          <CustomInputRadio
            title={IdentityDocumentTypes.RG_NOVO}
            text={t('contract.registerDocument.selectDocument.nationalIdentityCardNew')}
            handleOnClick={() => setIdentityDocument(documentUnicoCodes.RG_FRENTE_NOVO)}
            checked={identityDocument === documentUnicoCodes.RG_FRENTE_NOVO}
          />
          <CustomInputRadio
            title={IdentityDocumentTypes.RG}
            text={t('contract.registerDocument.selectDocument.nationalIdentityCard')}
            handleOnClick={() => setIdentityDocument(documentUnicoCodes.RG_FRENTE)}
            checked={identityDocument === documentUnicoCodes.RG_FRENTE}
          />
          <CustomInputRadio
            title={IdentityDocumentTypes.CNH}
            text={t('contract.registerDocument.selectDocument.nationalDrivingLicense')}
            handleOnClick={() => setIdentityDocument(documentUnicoCodes.CNH)}
            checked={identityDocument === documentUnicoCodes.CNH}
          />
          <CustomInputRadio
            title={IdentityDocumentTypes.PASSAPORT}
            text={t('contract.registerDocument.selectDocument.passaport')}
            handleOnClick={() => setIdentityDocument(documentUnicoCodes.PASSAPORTE)}
            checked={identityDocument === documentUnicoCodes.PASSAPORTE}
          />
          <CustomInputRadio
            title={IdentityDocumentTypes.CTPS}
            text={t('contract.registerDocument.selectDocument.workCard')}
            handleOnClick={() => setIdentityDocument(documentUnicoCodes.CTPS)}
            checked={identityDocument === documentUnicoCodes.CTPS}
          />
        </StyledContainerInputRadio>
        <CustomButton className="mobile-button--gofoward" disabled={isEmpty(identityDocument)} onClick={() => handleConfirm()}>
          {t('letsSign.comfirm')}
        </CustomButton>

        <StyledCardShowContractContainer>
          <CardShowContract
            onClick={() => {
              setShowComponentModal(true);
            }}
          />
        </StyledCardShowContractContainer>
        <CustomComponentModal open={showComponentModal} setOpen={setShowComponentModal}>
          <ContractPdf url={documentPresignUrl ?? ''} />
        </CustomComponentModal>

        <StyledPopUp position="center center" open={showErroExtractModal} modal>
          {close => (
            <StyledContainerPopup>
              <StyledModalTitle>{t('letsSign.notPossibleExtract.title')}</StyledModalTitle>
              <StyledUl>
                <li>
                  <Trans i18nKey="letsSign.notPossibleExtract.list1" />
                </li>
                <li>
                  <Trans i18nKey="letsSign.notPossibleExtract.list2" />
                </li>
                <li>
                  <Trans i18nKey="letsSign.notPossibleExtract.list3" />
                </li>
                <li>
                  <Trans i18nKey="letsSign.notPossibleExtract.list4" />
                </li>
                <li>
                  <Trans i18nKey="letsSign.notPossibleExtract.list5" />
                </li>
                <li>
                  <Trans i18nKey="letsSign.notPossibleExtract.list6" />
                </li>
              </StyledUl>

              <CustomButton
                width="100%"
                type="button"
                style={{
                  borderRadius: '8px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '16px',
                  boxShadow: '0px -1px 9px 0px rgba(0, 0, 0, 0.21)',
                  marginTop: '24px',
                  backgroundColor: !closeWarning ? 'gray' : '',
                  border: !closeWarning ? 'gray' : '',
                }}
                disabled={!closeWarning}
                onClick={() => {
                  setShowErroExtractModal(false);
                }}
              >
                <div className="mobile-button--gofoward">
                  {!closeWarning ? `${t('letsSign.notPossibleExtract.newTry')} (${countSeconds})` : t('letsSign.notPossibleExtract.newTry')}
                </div>
              </CustomButton>
            </StyledContainerPopup>
          )}
        </StyledPopUp>

        <CustomComponentModal open={!!errorDescriptionModal} setOpen={bool => !bool && setErrorDescriptionModal(undefined)}>
          <div style={{ fontSize: '14px' }}>
            <img
              src={AlertIcon}
              style={{
                margin: 'auto',
                width: '100%',
                height: '28px',
                marginBottom: '4px',
              }}
            />

            <b>{t('contract.document.erroModal.title')}</b>
            <div
              style={{ margin: '22px 0', textAlign: 'left' }}
            >{`${t('contract.proofOfLife.erroModal.reason')} ${errorDescriptionModal}`}</div>
          </div>
          <CustomButton
            style={{ width: '100%', justifyContent: 'center', borderRadius: '8px', background: '#FC4F4F' }}
            onClick={() => {
              setErrorDescriptionModal(undefined);
              history.push(RoutePath.LETS_SIGN);
            }}
          >
            {t('global.button.new')}
          </CustomButton>
        </CustomComponentModal>
      </StyledInnerContainer>

      <div className="waiting-step-footer">{t('contract.footer')}</div>
    </StyledContainer>
  );
};

export default withTranslation()(RegisterDocument);
