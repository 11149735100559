import UseResponsiveContext from 'context/use-responsive-context';
import { MulttiploImgs } from 'images/multtiplo';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FooterContent, FooterInfoBox, InfoText, InfoTitle, SocialMedia } from '../styles';

interface Item {
  text: string;
  path: string;
}
export interface FooterInfos {
  title: string;
  items: Item[];
}

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const { isMobile } = useContext(UseResponsiveContext);
  const { t } = useTranslation();
  const footerInfos: FooterInfos[] = [
    {
      title: t('landingPage.multtiplo.footer.section.1.title'),
      items: [
        {
          text: t('landingPage.multtiplo.who'),
          path: '',
        },
        {
          text: t('landingPage.multtiplo.footer.section.1.text.2'),
          path: '',
        },
        {
          text: t('landingPage.multtiplo.footer.section.1.text.3'),
          path: '',
        },
        {
          text: t('landingPage.multtiplo.footer.section.1.text.4'),
          path: '',
        },
      ],
    },
    {
      title: t('admin.customerAnalysis.stepCards.ratings'),
      items: [
        {
          text: t('landingPage.openingScreen.simulatorSlider.privacyPolicy'),
          path: '',
        },
        {
          text: t('landingPage.multtiplo.footer.section.2.text.3'),
          path: '',
        },
        {
          text: t('landingPage.multtiplo.footer.section.2.text.4'),
          path: '',
        },
      ],
    },
    {
      title: t('register.inputs.address'),
      items: [
        {
          text: t('landingPage.multtiplo.footer.section.3.text.1'),
          path: '',
        },
        {
          text: t('landingPage.multtiplo.footer.section.3.text.2'),
          path: '',
        },
        {
          text: t('landingPage.multtiplo.footer.section.3.text.3'),
          path: '',
        },
      ],
    },
    {
      title: t('landingPage.multtiplo.talk'),
      items: [
        {
          text: t('landingPage.multtiplo.contactEmail'),
          path: '',
        },
        {
          text: t('landingPage.multtiplo.contactPhoneNumber'),
          path: '',
        },
      ],
    },
  ];

  return (
    <FooterContent>
      {footerInfos.map((info, idx) => (
        <FooterInfoBox key={idx}>
          <InfoTitle>{info.title}</InfoTitle>
          {info.items.map((item, idx2) => (
            <InfoText key={idx2}>{item.text}</InfoText>
          ))}
        </FooterInfoBox>
      ))}
      <SocialMedia>
        <MulttiploImgs.facebook />
        <MulttiploImgs.instagram />
      </SocialMedia>
    </FooterContent>
  );
};

export default Footer;
