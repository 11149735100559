import styled from 'styled-components';

export const StyledChevronRightContainer = styled.div`
  display: flex;
  justify-content: space-between;

  svg path {
    fill: ${({ theme }) => theme.color.primaryColor};
  }
`;
