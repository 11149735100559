import i18n from 'config/i18n';
import { BankAccount } from 'model/bank-account';
import { BankAccountType } from 'model/enums/bank-account-type';
import StringUtils, { validateCNPJ, validateCpf } from 'shared/util/string-utils';

const useBankDataFormatter = () => {
  const dataFormatter = (bankData: BankAccount) => {
    if (bankData.pixKey != null) {
      if (validateCNPJ(bankData.pixKey)) {
        return i18n.t('dashboard.pixKeyValue', { value: StringUtils.cnpjMask(bankData.pixKey) });
      }

      if (validateCpf(bankData.pixKey)) {
        return i18n.t('dashboard.pixKeyValue', { value: StringUtils.cpfMask(bankData.pixKey) });
      }

      return i18n.t('dashboard.pixKeyValue', { value: bankData.pixKey });
    }

    return `${bankData.type ? BankAccountType[bankData.type] : ''} ${bankData.bankAccountNumber}-${bankData.bankAccountDigit}`;
  };

  return { dataFormatter };
};
export default useBankDataFormatter;
