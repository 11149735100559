import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 68px;
  background: #4b6fdd;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  background: ${({ theme }) => theme.color.primaryColor};
  padding: 40px 40px;
  display: flex;
  align-items: center;
`;
export const LogoContainer = styled.div`
  margin-left: 239px;
  img {
    max-height: 58px;
  }
`;
