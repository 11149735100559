import { FiDollarSign } from 'react-icons/fi';
import styled from 'styled-components';

export const AnalisisId = styled.div`
  font-style: normal;
  font-size: 16px;
  color: #000000;
  margin: 0;
  margin-left: 4px;

  border: 1px solid ${({ theme }) => theme.color.lightGray2};
  border-radius: 8px;
  padding: 2px 8px;
`;

interface IProps {
  $styling: any;
}

export const AnalisisStatus = styled.h4<IProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  min-width: fit-content;
  padding: 6px;
  border-radius: 4px;
  background-color: ${props => props.$styling};
  align-self: flex-end;
  margin: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const SliceOne = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const SliceTwo = styled.div`
  display: flex;
`;

export const StyledArrow = styled.img`
  cursor: pointer;
  margin-left: -10px;
`;

export const StyledDolarSign = styled(FiDollarSign)`
  color: ${({ theme }) => theme.color.primaryColor};
  font-size: 30px;
  background-color: ${({ theme }) => theme.color.brightnessPrimary};
  border-radius: 100%;
  cursor: pointer;

  margin: 0;
`;

export const MainContainer = styled.button`
  min-width: 469px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 32px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
`;

export const DataContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 40px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #999999;
  width: 98px;

  & > span:nth-child(2) {
    font-weight: 500;
    color: #333333;
  }
`;

export const VerticalLine = styled.div`
  width: 1px;
  height: 30px;
  margin-left: 24px;
  margin-right: 24px;
  background-color: #e5e7e7;
`;

export const Separate = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.color.lightGray2};
`;

export const IdBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > span.title {
    font-weight: 700;
    margin-right: 4px;
  }

  width: 100%;
  text-align: left;
`;

export const CircleIcon = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.color.brightnessPrimary};
  border-radius: 50%;
`;
