import { Dispatch, SetStateAction } from 'react';
import CustomButton from 'components/custom-button';
import Status from 'components/status/Status';
import { ReactComponent as PlusSvg } from 'images/white-plus.svg';
import BiometricsStatusAndClientAuthentication from 'model/biometrics-status-and-client-authentication';
import { Financing } from 'model/financing';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FiChevronRight } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { formatDateToLocalFormat } from 'shared/util/date-utils';
import StringUtils from 'shared/util/string-utils';
import { useTheme } from 'styled-components';
import {
  StyleCardInnerContainer,
  StyledCardContainer,
  StyledCardText,
  StyledCardTitle,
  StyledCardsContainerMobile,
  StyledFooterMobile,
  StyledHeaderCardContainer,
  StyledInnerContainer,
  StyledSubtitle,
  StyledTitle,
} from '../styles';

interface Props extends WithTranslation {
  myFinancing?: Financing[];
  biometricsStatusAndClientAuthentication?: BiometricsStatusAndClientAuthentication;
  setIsShowCreditLockModal: Dispatch<SetStateAction<boolean>>;
}

const Mobile = ({ myFinancing, biometricsStatusAndClientAuthentication, setIsShowCreditLockModal, t }: Props) => {
  const history = useHistory();
  const { color } = useTheme();

  return (
    <>
      <StyledInnerContainer>
        <StyledTitle style={{ marginBottom: '16px' }}>{t('myFinancing.title')}</StyledTitle>
        <StyledSubtitle>{t('myFinancing.subtitle')}</StyledSubtitle>
        <StyledCardsContainerMobile>
          {myFinancing?.map((financing, index) => (
            <StyledCardContainer
              key={`financing-${index}`}
              onClick={() =>
                history.push('dashboard', {
                  financingId: financing.id,
                  financingStatus: financing.financingStatus,
                  consultantComment: financing.consultantComment,
                  organization: biometricsStatusAndClientAuthentication?.organization,
                  biometricsIsNecessary: financing.biometricsIsNecessary,
                })
              }
            >
              <StyleCardInnerContainer>
                <StyledHeaderCardContainer>
                  <StyledCardTitle>{financing.identification}</StyledCardTitle>
                  {financing?.financingStatus != null ? (
                    <Status
                      status={StringUtils.configureCreditTableStatusView(
                        financing.financingStatus,
                        financing.contracts?.[0]?.status,
                        financing.payment?.status
                      )}
                      style={{ fontSize: '12px' }}
                    />
                  ) : (
                    <></>
                  )}
                </StyledHeaderCardContainer>
                <StyledCardText>
                  {`${formatDateToLocalFormat(financing.createdDate)} | `}
                  {financing?.choosedValue != null && financing?.approvedValue != null
                    ? `R$${StringUtils.maskAmount(
                        financing.choosedValue > financing?.approvedValue ? financing?.approvedValue : financing.choosedValue
                      )}`
                    : `R$${StringUtils.maskAmount(financing?.choosedValue)}`}
                </StyledCardText>
              </StyleCardInnerContainer>
              <FiChevronRight color={color.primaryColor} size={24} />
            </StyledCardContainer>
          ))}
        </StyledCardsContainerMobile>
      </StyledInnerContainer>

      <StyledFooterMobile>
        <CustomButton
          disabled={biometricsStatusAndClientAuthentication == null}
          height={48}
          width="258px"
          onClick={() =>
            biometricsStatusAndClientAuthentication?.isVerified ? history.push('/novo-financiamento') : setIsShowCreditLockModal(true)
          }
        >
          <>
            <PlusSvg />
            {t('global.button.addNewCredit')}
          </>
        </CustomButton>
      </StyledFooterMobile>
    </>
  );
};

export default withTranslation()(Mobile);
