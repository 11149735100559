import ButtonVariants from 'model/enums/buttonVariants';
import ReactLoading from 'react-loading';
import { SliderButton } from '../../style';
import { useTranslation } from 'react-i18next';

interface SimulatorButtonProps {
  isLast: boolean;
  currentStep: number;
  setCurrentStep: (step: number) => void;
  isLoading?: number;
  formId?: string;
  disabled?: boolean;
}

const SimulatorButton: React.FC<SimulatorButtonProps> = ({ isLast, currentStep, setCurrentStep, isLoading, formId, disabled = false }) => {
  const { t } = useTranslation();

  const sentProcess = (formId?: string) => {
    let translationKey: string = 'global.button.simulate';
    let buttonVariant: ButtonVariants = ButtonVariants.DEFAULT;
    let showSpinner: boolean = false;
    let showText: boolean = false;
    let type: 'submit' | 'reset' | 'button' | undefined = 'submit';

    switch (isLoading) {
      case 0:
        translationKey = isLast ? 'global.button.simulate' : 'global.button.nextStep';
        buttonVariant = ButtonVariants.DEFAULT;
        showSpinner = false;
        showText = true;
        type = 'submit';
        break;
      case 1:
        translationKey = 'global.button.sending';
        buttonVariant = ButtonVariants.DEFAULT;
        showSpinner = true;
        showText = false;
        type = 'button';
        break;
      case 2:
        translationKey = 'global.button.sended';
        buttonVariant = ButtonVariants.CHANGED;
        showSpinner = false;
        showText = true;
        type = 'button';
        break;
      default:
        return <></>;
    }

    return (
      <SliderButton type={type} $customStyle={{ width: '100%', variant: buttonVariant }} form={formId} disabled={disabled}>
        {showSpinner && <ReactLoading type="spinningBubbles" width="24px" height="24px" />}
        {showText && <span>{t(translationKey)}</span>}
      </SliderButton>
    );
  };

  return formId ? (
    sentProcess(formId)
  ) : (
    <SliderButton
      $customStyle={{ width: '100%', variant: disabled ? ButtonVariants.DISABLED : ButtonVariants.DEFAULT }}
      onClick={() => setCurrentStep(currentStep + 1)}
      disabled={disabled}
    >
      <span>{t('global.button.nextStep')}</span>
    </SliderButton>
  );
};

export default SimulatorButton;
