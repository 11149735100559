import { Reducer } from 'redux';
import { GlobalModalActionTypes, GlobalModalState } from './types';

export const initialState: GlobalModalState = {
  paidDiseaseModalOpen: false,
  imageModal: {
    isOpen: false,
    imageSrc: '',
  },
  feedbackModal: false,
};

const reducer: Reducer<GlobalModalState> = (state = initialState, action) => {
  switch (action.type) {
    case GlobalModalActionTypes.OPEN_PAID_DISEASE_MODAL: {
      return { ...state, paidDiseaseModalOpen: true };
    }
    case GlobalModalActionTypes.CLOSE_PAID_DISEASE_MODAL: {
      return { ...state, paidDiseaseModalOpen: false };
    }
    case GlobalModalActionTypes.OPEN_PAID_IMAGE_MODAL: {
      return { ...state, imageModal: { isOpen: true, imageSrc: action.payload as string | undefined } };
    }
    case GlobalModalActionTypes.CLOSE_PAID_IMAGE_MODAL: {
      return { ...state, imageModal: { isOpen: false, imageSrc: '' } };
    }
    case GlobalModalActionTypes.OPEN_FEEDBACK_MODAL: {
      return { ...state, feedbackModal: true };
    }
    case GlobalModalActionTypes.CLOSE_FEEDBACK_MODAL: {
      return { ...state, feedbackModal: false };
    }
    default: {
      return state;
    }
  }
};

export { reducer as globalModalReducer };
