import { yupResolver } from '@hookform/resolvers/yup';
import CustomButton from 'components/custom-button';
import { Loading } from 'components/loading';
import InputText from 'components/styled-input';
import { ReactComponent as XSvg } from 'images/x.svg';
import { User } from 'model/user';
import { ReactElement, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiEdit2 } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import authenticationService from 'services/authentication-service';
import * as yup from 'yup';
import { DropdownItem, DropdownText } from '../../dropdown/style';
import { InputsContainer, StyledButtonsContainer, StyledContainer, StyledPopUp, StyledTitle, StyledXIconContainer } from './styled';

interface MyDataModalProps {
  text?: string;
  icon?: ReactElement;
  setCloseOnDocumentClick: (value: boolean) => void;
  closeMenu: () => void;
}
interface Schema {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const MyDataModal: React.FC<MyDataModalProps> = ({ text, icon, setCloseOnDocumentClick, closeMenu }) => {
  const [editMode, setEditMode] = useState(false);
  const account = useSelector((state: IRootState) => state.authentication.account);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  useEffect(() => {
    if (editMode) {
      methods.setValue('password', '');
      methods.setValue('confirmPassword', '');
    }
  }, [editMode]);

  const schema = yup.object().shape({
    name: yup.string().required(t('global.errorMessage.required')),
    email: yup.string().email(t('global.errorMessage.email')).required(t('global.errorMessage.required')),
    password: yup.string().required(t('global.errorMessage.required')),
    confirmPassword: yup
      .string()
      .required(t('global.errorMessage.confirmPassword'))
      .oneOf([yup.ref('password')], t('global.errorMessage.matchPasswords')),
  });
  const methods = useForm<Schema>({ resolver: yupResolver(schema) });

  const updateProfile = async (values: Schema) => {
    setLoading(true);
    if (account?.id) {
      const data: User = {
        firstName: values.name,
        login: values.email,
        password: values.password,
      };

      const res = await authenticationService.updateAdminProfile(data);

      if (res?.id) {
        setLoading(false);
        setEditMode(false);
      }
    }
  };

  const handleClickFirstButton = close => {
    if (editMode) {
      setEditMode(false);
      methods.setValue('password', account?.password ?? '****');
    } else {
      closeMenu();
      close();
    }
    setLoading(false);
  };

  return (
    <StyledPopUp
      trigger={
        <DropdownItem>
          {icon ?? ''}
          <DropdownText>{text}</DropdownText>
        </DropdownItem>
      }
      position="center center"
      on="click"
      modal
      closeOnDocumentClick
      onOpen={() => setCloseOnDocumentClick(false)}
      onClose={() => setCloseOnDocumentClick(true)}
    >
      {close => (
        <StyledContainer>
          <StyledXIconContainer>
            <XSvg onClick={() => close()} style={{ cursor: 'pointer' }} />
          </StyledXIconContainer>
          {<StyledTitle>{t('global.button.myData')}</StyledTitle>}
          <FormProvider {...methods}>
            <InputsContainer>
              <InputText
                name="name"
                label={t('admin.modalProfile.name')}
                defaultValue={`${account?.firstName ?? ''} ${account?.lastName ?? ''}`}
                disabled={!editMode}
              />
              <InputText name="email" label={t('admin.modalProfile.email')} defaultValue={account?.email} disabled={!editMode} />
              <InputText
                name="password"
                label={editMode ? t('admin.modalProfile.newPassword') : t('admin.modalProfile.password')}
                defaultValue={account?.password ?? '****'}
                inputType="password"
                disabled={!editMode}
                notIcon={editMode}
              />
              {editMode && (
                <InputText
                  name="confirmPassword"
                  label={t('admin.modalProfile.confirmPassword')}
                  defaultValue={account?.password ?? '****'}
                  inputType="password"
                  notIcon={editMode}
                />
              )}
            </InputsContainer>
          </FormProvider>
          <StyledButtonsContainer>
            <CustomButton
              style={{ justifyContent: 'center' }}
              height={48}
              width="160px"
              isInvertColor
              onClick={() => {
                handleClickFirstButton(close);
              }}
            >
              {t('global.button.cancel')}
            </CustomButton>

            <CustomButton
              style={{ justifyContent: 'center' }}
              height={48}
              width="160px"
              icon={editMode ? undefined : <FiEdit2 />}
              onClick={editMode ? methods.handleSubmit(updateProfile) : () => setEditMode(true)}
              freeContent
            >
              {loading ? <Loading invertColor /> : editMode ? t('global.button.save') : t('global.button.editData')}
            </CustomButton>
          </StyledButtonsContainer>
        </StyledContainer>
      )}
    </StyledPopUp>
  );
};
export default MyDataModal;
