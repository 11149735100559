export enum OrganizationName {
  AZURE = 'azure',
  CREFIZ = 'crefiz',
  NOVOSAQUE = 'novo-saque',
  HCRED = 'hcred',
  MONJUA = 'monjua',
  MULTTIPLO = 'multtiplo',
  GIROPJ = 'giropj',
  ZM = 'zm-emprestimos',
}
