import { Attachment } from 'model/attachment';
import { useRegisterForm } from 'provider/register-form';
import { useEffect, useState } from 'react';
import { IdentificationScreen } from './screens/identification';
import { IncomeScreen } from './screens/income';
import { InstructionsScreen } from './screens/instructions';
import { ResidenceScreen } from './screens/residence';
import { ResumeScreen } from './screens/resume';
interface Props {
  attachmentIdentification?: Attachment;
  attachmentIncome?: Attachment;
  attachmentAddress?: Attachment;
  isGuarantorRegister?: boolean;
  isOnlyIdentificationDocument?: boolean;
}

export const FormDocuments = ({
  attachmentIdentification,
  attachmentIncome,
  attachmentAddress,
  isGuarantorRegister,
  isOnlyIdentificationDocument = false,
}: Props) => {
  const [actualStep, setActualStep] = useState(0);
  const { files } = useRegisterForm();

  const renderSteps = () => {
    switch (actualStep) {
      case 0:
        return <InstructionsScreen actualStep={actualStep} setActualStep={setActualStep} />;
      case 1:
        return (
          <IdentificationScreen actualStep={actualStep} setActualStep={setActualStep} handleVerifyEmptyFields={handleVerifyEmptyFields} />
        );
      case 2:
        return <IncomeScreen actualStep={actualStep} setActualStep={setActualStep} handleVerifyEmptyFields={handleVerifyEmptyFields} />;
      case 3:
        return <ResidenceScreen actualStep={actualStep} setActualStep={setActualStep} handleVerifyEmptyFields={handleVerifyEmptyFields} />;
      case 4:
        return <ResumeScreen actualStep={actualStep} setActualStep={setActualStep} />;
      default:
        return <></>;
    }
  };

  const handleVerifyEmptyFields = (field: string) => {
    const hasFiles = files.find(file => file.type === field);
    if (hasFiles) {
      return false;
    }
    return true;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '0px', width: '100%' }}>{renderSteps()}</div>
    </div>
  );
};
