import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import CustomButton from 'components/custom-button';
import CustomInputNumbersOnly from 'components/custom-input-numbers-only';
import CustomTextField from 'components/custom-text-field';
import Header from 'components/general-components/header';
import { HeaderVariants } from 'model/enums/header-variants';
import InputMaskTypesOnlyNumbers from 'model/enums/input-mask-types-only-numbers';
import { Procedure } from 'model/financing';
import { ProcedureRequest } from 'model/procedure';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import financingService from 'services/financing-service';
import StringUtils from 'shared/util/string-utils';
import * as yup from 'yup';
import {
  ModalContainer,
  PageContainer,
  StyledButtonContainer,
  StyledCardContainer,
  StyledInnerContainer,
  StyledMainTitle,
  StyledText,
} from './styles';
import CustomModalTwoButtons from 'components/custom-modal-two-buttons';
import { ReactComponent as AttentionIcon } from 'images/attention-red.svg';
import { ReactSelectSearch } from 'components/react-select-search';
import { FormalizationType } from 'model/enums/formalization-type';

export const AddProcedureScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [showInsufficientWarning, setShowInsufficientWarning] = useState(false);

  const schema = yup.object({
    choosedValue: yup.string().required(t('global.errorMessage.required')),
    medicalProcedure: yup.string().required(t('global.errorMessage.required')),
    installmentsTotal: yup
      .string()
      .required(t('global.errorMessage.required'))
      .test('test-maxValue', t('global.errorMessage.max60'), value => validateMaxStringNumber(value!)),
  });

  function validateMaxStringNumber(value: string) {
    const numberValue = Number(value);
    if (numberValue > 60) {
      return false;
    }
    return true;
  }

  const methods = useForm({ resolver: yupResolver(schema), mode: 'onBlur' });

  const onHandleSubmit = async (values: Procedure) => {
    setLoading(true);

    values.choosedValue = StringUtils.removeMoneyFormat(values.choosedValue);
    const newProcedure: ProcedureRequest = {
      choosedValue: Number(values.choosedValue),
      installmentsTotal: Number(values.installmentsTotal),
      medicalProcedure: values.medicalProcedure,
      client: {
        id: Number(id),
      },
    };

    await financingService
      .createProcedureAdmin(newProcedure)
      .then(res => {
        if (res.id) {
          setTimeout(() => {
            history.push(`/admin/cadastros/${id}`);
            setLoading(false);
          }, 500);
        }
      })
      .catch(error => {
        setLoading(false);
        if (error.data.detail.includes('insufficient balance')) {
          setShowInsufficientWarning(true);
        }
      });
  };

  return (
    <PageContainer>
      <Header variant={HeaderVariants.SECONDARY} />
      <StyledInnerContainer>
        <StyledMainTitle>{t('myProcedures.addNewProcedure')}</StyledMainTitle>
        <StyledText>{t('myProcedures.fillProcedureDataToProceed')}</StyledText>
        <StyledCardContainer>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(formData => onHandleSubmit(formData))} id="procedure-form">
              <Grid container rowSpacing={1} columnSpacing={3}>
                <Grid item xs={12}>
                  <CustomTextField
                    name="medicalProcedure"
                    label={t('myProcedures.procedure')}
                    helperText={methods.formState.errors.medicalProcedure?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomInputNumbersOnly
                    name="choosedValue"
                    label={t('myProcedures.procedureValue')}
                    helperText={methods.formState.errors.choosedValue?.message}
                    mask={InputMaskTypesOnlyNumbers.MONETARY_VALUE}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomInputNumbersOnly
                    name="installmentsTotal"
                    label={t('myProcedures.numberOfInstallments')}
                    helperText={methods.formState.errors.installmentsTotal?.message}
                  />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </StyledCardContainer>
        <StyledButtonContainer>
          <CustomButton
            style={{ justifyContent: 'center' }}
            height={48}
            width="160px"
            onClick={() => history.push(`/admin/cadastros/${id}`)}
            isInvertColor
          >
            {t('global.button.cancel')}
          </CustomButton>
          <CustomButton style={{ justifyContent: 'center' }} height={48} width="160px" form="procedure-form" loading={loading}>
            {t('global.button.add')}
          </CustomButton>
        </StyledButtonContainer>
      </StyledInnerContainer>
      <CustomModalTwoButtons
        isShowModal={showInsufficientWarning}
        onCloseModal={() => {
          setShowInsufficientWarning(false);
          setLoading(false);
        }}
        large
        children={
          <ModalContainer>
            <AttentionIcon />
            <h1>{t('myProcedures.insufficientBalance')}</h1>
            <span>{t('myProcedures.youDontHaveBalance')}</span>
            <span>
              <Trans i18nKey={'myProcedures.accessMenu'} />
            </span>
          </ModalContainer>
        }
        secondButtonText={t('global.button.ok')}
        handleClickSecondButton={() => {
          setShowInsufficientWarning(false);
          setLoading(false);
        }}
      />
    </PageContainer>
  );
};
