import { FormInputsContainer, Row } from 'components/edit-forms/styles';
import { Column } from 'components/register-forms-mobile/styles';
import { SearchSelectInput } from 'components/search-selected-input';
import InputText from 'components/styled-input';
import { TextInput } from 'components/text-input';
import { Mask } from 'model/enums/mask-types';
import { useEditForm } from 'provider/edit-form';
import { useSelectLists } from 'provider/select-list';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';

export const ContainerInputsPartnerGeneralData = props => {
  const { t } = useTranslation();
  const { initialPartnerData } = useEditForm();
  const { getOccupations, occupations, occupationsTotalPages } = useSelectLists();
  const [occupationsPage, setOccupationsPage] = useState(0);
  const [occupationName, setOccupationName] = useState('');

  useEffect(() => {
    getOccupations({ name: occupationName, page: occupationsPage, size: 7 }, true);
  }, [occupationsPage]);
  const renderMobile = () => {
    return (
      <Column>
        <InputText
          isRequired
          disabled={props.onlyView}
          name="name"
          label={t('register.inputs.name')}
          placeholder={t('register.placeholder.name')}
          defaultValue={initialPartnerData?.name}
        />
        <InputText
          isRequired
          disabled={props.onlyView}
          name="birth"
          label={t('register.inputs.birthDate')}
          placeholder={t('register.placeholder.birthDate')}
          maxLength={10}
          mask={Mask.BIRTHDAY}
          defaultValue={initialPartnerData?.birth ? StringUtils.dateFormatMask(initialPartnerData?.birth.toString()) : ''}
        />
        <InputText
          isRequired
          name="phone"
          disabled={props.onlyView}
          label={t('register.inputs.phone')}
          placeholder={t('register.placeholder.phone')}
          mask={Mask.PHONE}
          maxLength={15}
          defaultValue={initialPartnerData?.phone}
        />
        <InputText
          isRequired
          name="cpf"
          disabled={props.onlyView}
          label={t('register.inputs.cpf')}
          placeholder={t('register.placeholder.cpf')}
          mask={Mask.CPF}
          maxLength={14}
          defaultValue={initialPartnerData?.cpf}
        />
        <InputText
          isRequired
          name="rg"
          disabled={props.onlyView}
          label={t('register.inputs.rg')}
          placeholder={t('register.placeholder.rg')}
          defaultValue={initialPartnerData?.rg}
          maxLength={20}
        />
        <InputText
          isRequired
          disabled={props.onlyView}
          name="email"
          label={t('register.inputs.email')}
          placeholder={t('register.placeholder.email')}
          defaultValue={initialPartnerData?.email}
        />
        <TextInput name="occupation" label={t('register.inputs.occupation')} isRequired hasCustomInput>
          <SearchSelectInput
            name="occupation"
            disabled={props.onlyView}
            placeholder={t('register.inputs.occupation')}
            defaultValue={initialPartnerData?.occupation?.name}
            options={occupations.map(occupation => ({ label: occupation?.name ?? '', value: occupation.id.toString() }))}
            defaultOptions={occupations.map(occupation => ({ label: occupation?.name ?? '', value: occupation.id.toString() }))}
            handleChange={values => {
              setOccupationName(values.name);
              setOccupationsPage(0);
              getOccupations({ name: values.name, size: 7 });
            }}
            handleOnScroll={() => {
              if (occupationsPage < occupationsTotalPages - 1) {
                setOccupationsPage(occupationsPage + 1);
              }
            }}
          />
        </TextInput>
        <InputText
          isRequired
          disabled={props.onlyView}
          name="income"
          label={t('register.inputs.partnerIncome')}
          placeholder={t('register.placeholder.partnerIncome')}
          mask={Mask.CURRENCY}
          defaultValue={initialPartnerData?.income ? initialPartnerData?.income.toFixed(2) : ''}
          maxLength={14}
        />
      </Column>
    );
  };
  return (
    <FormInputsContainer $visible={props.visible}>
      {props.mobile ? (
        renderMobile()
      ) : (
        <>
          <Row>
            <InputText
              isRequired
              disabled={props.onlyView}
              name="name"
              label={t('register.inputs.name')}
              placeholder={t('register.placeholder.name')}
              defaultValue={initialPartnerData?.name}
            />
            <InputText
              isRequired
              disabled={props.onlyView}
              name="birth"
              label={t('register.inputs.birthDate')}
              placeholder={t('register.placeholder.birthDate')}
              maxLength={10}
              mask={Mask.BIRTHDAY}
              defaultValue={initialPartnerData?.birth ? StringUtils.dateFormatMask(initialPartnerData?.birth.toString()) : ''}
            />
            <InputText
              isRequired
              name="phone"
              disabled={props.onlyView}
              label={t('register.inputs.phone')}
              placeholder={t('register.placeholder.phone')}
              mask={Mask.PHONE}
              maxLength={15}
              defaultValue={initialPartnerData?.phone}
            />
            <InputText
              isRequired
              name="cpf"
              disabled={props.onlyView}
              label={t('register.inputs.cpf')}
              placeholder={t('register.placeholder.cpf')}
              mask={Mask.CPF}
              maxLength={14}
              defaultValue={initialPartnerData?.cpf}
            />
            <InputText
              isRequired
              name="rg"
              disabled={props.onlyView}
              label={t('register.inputs.rg')}
              placeholder={t('register.placeholder.rg')}
              defaultValue={initialPartnerData?.rg}
              maxLength={10}
            />
            <InputText
              isRequired
              disabled={props.onlyView}
              name="email"
              label={t('register.inputs.email')}
              placeholder={t('register.placeholder.email')}
              defaultValue={initialPartnerData?.email}
            />
            <TextInput name="occupation" label={t('register.inputs.occupation')} isRequired hasCustomInput>
              <SearchSelectInput
                name="occupation"
                disabled={props.onlyView}
                placeholder={t('register.inputs.occupation')}
                defaultValue={initialPartnerData?.occupation?.name}
                options={occupations.map(occupation => ({ label: occupation?.name ?? '', value: occupation.id.toString() }))}
                defaultOptions={occupations.map(occupation => ({ label: occupation?.name ?? '', value: occupation.id.toString() }))}
                handleChange={values => {
                  setOccupationName(values.name);
                  setOccupationsPage(0);
                  getOccupations({ name: values.name, size: 7 });
                }}
                handleOnScroll={() => {
                  if (occupationsPage < occupationsTotalPages - 1) {
                    setOccupationsPage(occupationsPage + 1);
                  }
                }}
              />
            </TextInput>
            <InputText
              isRequired
              disabled={props.onlyView}
              name="income"
              label={t('register.inputs.partnerIncome')}
              placeholder={t('register.placeholder.partnerIncome')}
              mask={Mask.CURRENCY}
              defaultValue={initialPartnerData?.income ? initialPartnerData?.income.toFixed(2) : ''}
              maxLength={14}
            />
          </Row>
        </>
      )}
    </FormInputsContainer>
  );
};
