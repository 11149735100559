import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
  margin-top: 16px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.color.gray20}`};
`;

export const InputsContainer = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.color.lightGray2}`};
  padding-bottom: 24px;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => `1px solid ${theme.color.black1b}`};
`;

export const QrCodeContainer = styled.div`
  text-align: center;
`;

export const Text = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.gray18};
  margin: 24px 0;
  word-break: break-all;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin: 0 auto;

  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.primaryColor};
  background: none;
  border: none;

  &:active {
    opacity: 0.5;
  }

  svg path {
    fill: ${({ theme }) => theme.color.primaryColor};
  }
`;
