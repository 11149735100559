import contractApi from 'api/contract-api';
import CustomButton from 'components/custom-button';
import Header from 'components/general-components/header';
import { Authority } from 'model/enums/authority';
import { ContractStatus } from 'model/enums/contract-status';
import { HeaderVariants } from 'model/enums/header-variants';
import { Contract } from 'model/financing';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronLeft, FiDownload, FiFileText } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IRootState } from 'reducer';
import contractService from 'services/contract-service';
import { getNameMonthPtbr } from 'shared/util/date-utils';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { hasAuthorities } from 'shared/util/utils';
import { useTheme } from 'styled-components';
import { Status } from '../../dashboard/components/status';
import { ContractPdf } from '../components/contractPdf';
import { Signatures } from '../components/signatures';
import { SignQRCode } from '../components/signqrcode';
import {
  ButtonsContainer,
  Container,
  DocIconBox,
  FileHeader,
  FileTitle,
  PageContainer,
  Tab,
  TabBox,
  TabContent,
  Title,
  TitleHeader,
} from '../styles';
import { Mobile } from './mobile';

interface DesktopProps {
  contract: Contract | null;
  date: {
    day: string;
    month: string;
    year: string;
  };
  tabSelected: number;
  setTabSelected: (value: number) => void;
  downloadPdf: (value: string) => void;
  setIsLoading: (value: boolean) => void;
  isLoading: boolean;
  resendEmail: () => Promise<void>;
  signatureLink: string | null;
}

export const Desktop = ({
  contract,
  date,
  tabSelected,
  setTabSelected,
  downloadPdf,
  setIsLoading,
  isLoading,
  resendEmail,
  signatureLink,
}: DesktopProps) => {
  const { color } = useTheme();
  const history = useHistory();
  const location = useLocation<{ prevPath: string }>();
  const { t } = useTranslation();
  const account = useSelector((state: IRootState) => state.authentication.account);

  const getTabContent = () => {
    if (signatureLink != null) {
      switch (tabSelected) {
        case 1:
          return <SignQRCode status={contract?.status} url={signatureLink} />;
        case 2:
          return <Signatures signatories={contract?.contractSign?.contractSignatories} status={contract?.status} />;
        case 3:
          return <ContractPdf url={contract?.contractSign?.attachment?.presignedUrl ?? contract?.attachment?.presignedUrl ?? ''} />;
        default:
          return <></>;
      }
    } else {
      switch (tabSelected) {
        case 1:
          return <Signatures signatories={contract?.contractSign?.contractSignatories} status={contract?.status} />;
        case 2:
          return <ContractPdf url={contract?.contractSign?.attachment?.presignedUrl ?? contract?.attachment?.presignedUrl ?? ''} />;
        default:
          return <></>;
      }
    }
  };

  return (
    <PageContainer>
      <Header variant={HeaderVariants.SECONDARY} />
      <TitleHeader>
        <Title>
          <button
            onClick={() => {
              if (location.state?.prevPath) {
                history.push(location.state.prevPath);
              } else {
                history.goBack();
              }
            }}
          >
            <FiChevronLeft color={color.primaryColor} />
          </button>
          <span>{t('admin.contractScreen.title')}</span>
        </Title>
        {contract?.status !== ContractStatus.FINISHED &&
          hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN, Authority.ROLE_MANAGER, Authority.ROLE_SELLER]) && (
            <CustomButton
              loading={isLoading}
              onClick={() => {
                setIsLoading(true);
                resendEmail();
              }}
            >
              {t('admin.contractScreen.buttonText')}
            </CustomButton>
          )}
      </TitleHeader>
      <Container>
        <FileHeader>
          <div className="textsBox">
            <DocIconBox>
              <FiFileText size={20} color={color.primaryColor} />
            </DocIconBox>
            <FileTitle>
              <span className="fileName">{contract?.attachment.fileName}</span>
              <span className="date">
                {t('admin.contractScreen.date', {
                  day: date.day,
                  month: date.month,
                  year: date.year,
                })}
              </span>
            </FileTitle>
            <Status text={t(`enum.filterAllStatus.${contract?.status}`)} color={contract?.status ?? ''} />
          </div>
          <ButtonsContainer>
            <button onClick={() => downloadPdf(contract?.attachment?.presignedUrl ?? '')}>
              <FiDownload size={20} color={color.primaryColor} />
              <span>{t('admin.contractScreen.downloadButton')}</span>
            </button>
            {contract?.contractSign?.attachment?.presignedUrl && (
              <button onClick={() => downloadPdf(contract?.contractSign?.attachment?.presignedUrl ?? '')}>
                <FiDownload size={20} color={color.primaryColor} />
                <span>{t('admin.contractScreen.downloadAssignedButton')}</span>
              </button>
            )}
          </ButtonsContainer>
        </FileHeader>
        <TabBox>
          {signatureLink != null && (
            <Tab $isSelected={tabSelected === 1} onClick={() => setTabSelected(1)}>
              <span>{t('admin.contractScreen.tabs.sign')}</span>
            </Tab>
          )}
          <Tab
            $isSelected={signatureLink != null ? tabSelected === 2 : tabSelected === 1}
            onClick={() => (signatureLink != null ? setTabSelected(2) : setTabSelected(1))}
          >
            <span>{t('admin.contractScreen.tabs.signatories')}</span>
          </Tab>
          <Tab
            $isSelected={signatureLink != null ? tabSelected === 3 : tabSelected === 2}
            onClick={() => (signatureLink != null ? setTabSelected(3) : setTabSelected(2))}
          >
            <span>{t('admin.contractScreen.tabs.document')}</span>
          </Tab>
        </TabBox>
        <TabContent>{getTabContent()}</TabContent>
      </Container>
    </PageContainer>
  );
};
