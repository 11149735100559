import { Middleware, applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { thunk } from 'redux-thunk';
import rootReducer, { IRootState, rootSaga } from './index';

const initialize = (initialState?: IRootState) => {
  const sagaMiddleware = createSagaMiddleware();
  const enhancer = compose(applyMiddleware(thunk as unknown as Middleware<unknown, any, any>, sagaMiddleware));

  const store = createStore(rootReducer, initialState, enhancer);

  sagaMiddleware.run(rootSaga);

  return store;
};

export default initialize;
