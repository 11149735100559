import { ContainerInputsAddressEvolve } from 'components/edit-forms/address-evolve/inputs-address';
import { ContainerInputsAddressDataMonjua } from 'components/edit-forms/address-monjua/inputs-address';
import { ContainerInputsAddressDataMulttiplo } from 'components/edit-forms/address-multtiplo/inputs-address';
import { ContainerInputsAddressTopOne } from 'components/edit-forms/address-top-one/inputs-address';
import { ContainerInputsAddressZm } from 'components/edit-forms/address-zm/inputs-address';
import { ContainerInputsAddress } from 'components/edit-forms/address/inputs-address';
import { ResumeScreen } from 'components/edit-forms/documents/screens/resume';
import { EditSelfieScreen } from 'components/edit-forms/documents/screens/selfie-edit';
import { ContainerInputsIncomeEvolve } from 'components/edit-forms/income-evolve/inputs-income';
import { ContainerInputsIncomeMonjua } from 'components/edit-forms/income-monjua/inputs-income';
import { ContainerInputsIncomeTopOne } from 'components/edit-forms/income-top-one/inputs-income';
import { ContainerInputsIncome } from 'components/edit-forms/income/inputs-income';
import { ContainerInputsPersonalDataMonjua } from 'components/edit-forms/personal-data-monjua/inputs-personal-data';
import { ContainerInputsPersonalDataMulttiplo } from 'components/edit-forms/personal-data-multtiplo/inputs-personal-data';
import { ContainerInputsPersonalDataTopOne } from 'components/edit-forms/personal-data-top-one/inputs-personal-data';
import { ContainerInputsPersonalDataZm } from 'components/edit-forms/personal-data-zm/inputs-personal-data';
import { ContainerInputsPersonalData } from 'components/edit-forms/personal-data/inputs-personal-data';
import { FormReferencesMonjua } from 'components/edit-forms/references-monjua';
import SystemStepCategory from 'model/enums/system-step-category';
import TypeSubStepCategory from 'model/enums/type-substep-category';
import OrganizationsSystemSteps from 'model/organization-system-steps';
import { useMemo } from 'react';

export interface StepEditForm {
  step: OrganizationsSystemSteps;
  form: JSX.Element;
}

interface EditStepsProps {
  steps: OrganizationsSystemSteps[];
  onlyView: boolean;
  visible?: SystemStepCategory | TypeSubStepCategory;
  checkIfIsRequired?: (employmentBond: string) => boolean;
}

export const EditFormSteps = ({ steps, onlyView, visible, checkIfIsRequired }: EditStepsProps): JSX.Element => {
  const elements: JSX.Element[] = useMemo(() => {
    return steps.map((step: OrganizationsSystemSteps) => {
      switch (step.systemStep?.step) {
        case SystemStepCategory.PERSONAL_DATA:
          return <ContainerInputsPersonalData onlyView={onlyView} visible={visible === SystemStepCategory.PERSONAL_DATA} />;
        case SystemStepCategory.PERSONAL_DATA_ZM:
          return <ContainerInputsPersonalDataZm onlyView={onlyView} visible={visible === SystemStepCategory.PERSONAL_DATA_ZM} />;
        case SystemStepCategory.PERSONAL_DATA_TOP_ONE:
          return <ContainerInputsPersonalDataTopOne onlyView={onlyView} visible={visible === SystemStepCategory.PERSONAL_DATA_TOP_ONE} />;
        case SystemStepCategory.PERSONAL_DATA_MONJUA:
          return <ContainerInputsPersonalDataMonjua onlyView={onlyView} visible={visible === SystemStepCategory.PERSONAL_DATA_MONJUA} />;
        case SystemStepCategory.PERSONAL_DATA_MULTTIPLO:
          return (
            <ContainerInputsPersonalDataMulttiplo onlyView={onlyView} visible={visible === SystemStepCategory.PERSONAL_DATA_MULTTIPLO} />
          );
        case SystemStepCategory.ADDRESS_MONJUA:
          return <ContainerInputsAddressDataMonjua onlyView={onlyView} visible={visible === SystemStepCategory.ADDRESS_MONJUA} />;
        case SystemStepCategory.ADDRESS_ZM:
          return <ContainerInputsAddressZm onlyView={onlyView} visible={visible === SystemStepCategory.ADDRESS_ZM} />;
        case SystemStepCategory.ADDRESS_EVOLVE:
          return <ContainerInputsAddressEvolve onlyView={onlyView} visible={visible === SystemStepCategory.ADDRESS_EVOLVE} />;
        case SystemStepCategory.ADDRESS:
          return <ContainerInputsAddress onlyView={onlyView} visible={visible === SystemStepCategory.ADDRESS} />;
        case SystemStepCategory.ADDRESS_TOP_ONE:
          return <ContainerInputsAddressTopOne onlyView={onlyView} visible={visible === SystemStepCategory.ADDRESS_TOP_ONE} />;
        case SystemStepCategory.ADDRESS_MULTTIPLO:
          return <ContainerInputsAddressDataMulttiplo onlyView={onlyView} visible={visible === SystemStepCategory.ADDRESS_MULTTIPLO} />;

        case SystemStepCategory.SELFIE:
          return <EditSelfieScreen onlyView={onlyView} visible={visible === SystemStepCategory.SELFIE} />;

        case SystemStepCategory.PHYSICAL_PERSON_DOCUMENTS:
          return <ResumeScreen onlyView={onlyView} visible={visible === SystemStepCategory.PHYSICAL_PERSON_DOCUMENTS} />;
        case SystemStepCategory.PHYSICAL_PERSON_DOCUMENTS_HCRED:
          return (
            <ResumeScreen
              onlyView={onlyView}
              visible={visible === SystemStepCategory.PHYSICAL_PERSON_DOCUMENTS_HCRED}
              isIdentificationDocumentOnly
            />
          );
        case SystemStepCategory.PHYSICAL_PERSON_IDENTIFICATION_DOCUMENT:
          return (
            <ResumeScreen
              onlyView={onlyView}
              visible={visible === SystemStepCategory.PHYSICAL_PERSON_IDENTIFICATION_DOCUMENT}
              isIdentificationDocumentOnly
            />
          );
        case SystemStepCategory.INCOME:
          return <ContainerInputsIncome onlyView={onlyView} visible={visible === SystemStepCategory.INCOME} />;
        case SystemStepCategory.INCOME_EVOLVE:
          return <ContainerInputsIncomeEvolve onlyView={onlyView} visible={visible === SystemStepCategory.INCOME_EVOLVE} />;
        case SystemStepCategory.INCOME_TOP_ONE:
          return (
            <ContainerInputsIncomeTopOne
              checkIfIsRequired={checkIfIsRequired}
              onlyView={onlyView}
              visible={visible === SystemStepCategory.INCOME_TOP_ONE}
            />
          );
        case SystemStepCategory.INCOME_MONJUA:
          return (
            <ContainerInputsIncomeMonjua
              checkIfIsRequired={checkIfIsRequired}
              onlyView={onlyView}
              visible={visible === SystemStepCategory.INCOME_MONJUA}
            />
          );
        case SystemStepCategory.REFERENCES_MONJUA:
          return <FormReferencesMonjua onlyView={onlyView} visible={visible === SystemStepCategory.REFERENCES_MONJUA} />;
        default:
          return <></>;
      }
    });
  }, [steps, visible]);
  return <>{elements}</>;
};
