import { SVGProps } from 'react';
import theme from '../themes/default';

export const RoundCheckSolidSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21.5C7.0293 21.5 3 17.4707 3 12.5C3 7.5293 7.0293 3.5 12 3.5C16.9707 3.5 21 7.5293 21 12.5C21 17.4707 16.9707 21.5 12 21.5ZM12 19.7C15.9765 19.7 19.2 16.4765 19.2 12.5C19.2 8.52355 15.9765 5.3 12 5.3C8.02355 5.3 4.8 8.52355 4.8 12.5C4.8 16.4765 8.02355 19.7 12 19.7ZM7.9203 11.645C7.56888 11.9964 7.56888 12.5662 7.9203 12.9176L11.1027 16.1L16.8299 10.3728C17.1815 10.0212 17.1814 9.45109 16.8297 9.0996C16.4781 8.74827 15.9083 8.74839 15.5568 9.09988L11.1027 13.5548L9.1929 11.645C8.84148 11.2936 8.27172 11.2936 7.9203 11.645Z"
      fill={theme.color.primaryColor}
    />
  </svg>
);
