import styled from 'styled-components';

interface IStyledProps {
  $isActive: boolean;
  $flexCard?: boolean;
  $clickable?: boolean;
  $loading?: boolean;
}

export const StyledContainer = styled.div<IStyledProps>`
  width: ${({ $flexCard }) => ($flexCard ? '100%' : '388px')};
  min-height: 96px;

  padding: 16px;
  margin-left: auto;
  margin-bottom: 8px;
  border: ${({ theme }) => `1px solid ${theme.color.gray20}`};
  border-radius: 8px;
  font-size: 16px;
  color: ${({ theme, $isActive, $loading }) => ($isActive && !$loading ? '#000000' : theme.color.gray18)};
  text-align: left;
  pointer-events: ${({ $clickable, $loading }) => !$clickable && $loading && 'none'};
  background-color: ${({ $isActive, $loading }) => $isActive && !$loading && '#ffffff'};
  cursor: ${({ $clickable, $isActive, $loading }) => ($clickable && $isActive && !$loading ? 'pointer' : 'default')};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .MuiCircularProgress-root {
    color: ${({ theme }) => theme.color.primaryColor} !important;
  }

  @media (max-width: 991.98px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StyledInnerContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 8px;

  svg path {
    fill: ${({ theme }) => theme.color.primaryColor};
  }
`;

export const StyledTitle = styled.span`
  font-weight: 700;
`;

export const StyledContentContainer = styled.div<IStyledProps>`
  font-weight: 400;
  color: ${({ theme, $isActive }) => $isActive && theme.color.gray19};

  @media (max-width: 575.98px) {
    font-size: 14px;
  }
`;
