import { useState } from 'react';
import TypesBankDetails from 'model/enums/types-bank-details';
import { BankAccount } from 'model/bank-account';

const useBankDetailsRegistrationFormPix = (selectedBankAccount?: BankAccount) => {
  const enableBankAccountEditingMode = selectedBankAccount?.id != null && selectedBankAccount?.bank != null;
  const enablePixKeyEditingMode = selectedBankAccount?.id != null && selectedBankAccount?.pixKey != null;

  const [typeBankDetails, setTypeBankDetails] = useState<TypesBankDetails>();

  const selectTypeDataBankDetails = (type: TypesBankDetails) => {
    setTypeBankDetails(type);
  };

  return {
    typeBankDetails,
    enableBankAccountEditingMode,
    enablePixKeyEditingMode,
    selectTypeDataBankDetails,
  };
};

export default useBankDetailsRegistrationFormPix;
