import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { CalendarIcon } from './../../images/calendar/index';
import { ButtonsContainer, CalendarContainer, FilterItem, OptionContainer, StyledHr } from './style';
import { useTranslation } from 'react-i18next';

interface CalendarOptionProps {
  placeholder: string;
  onChangeValue: (date: Date) => void;
  defaultValue?: string;
  reset?: boolean;
  onCleanFilter: () => void;
  isActive?: boolean;
  date: Date | null;
  setDate: (date: Date | null) => void;
}

export const NewCalendarOption = ({
  date,
  setDate,
  onCleanFilter,
  placeholder,
  onChangeValue,
  defaultValue,
  reset,
  isActive,
}: CalendarOptionProps) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const filterItemRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (reset) {
      setDate(null);
    }
  }, [reset]);

  const handleClick = () => {
    setShowCalendar(!showCalendar);
  };

  return (
    <OptionContainer>
      <FilterItem onClick={handleClick} ref={filterItemRef} $isActive={isActive}>
        <CalendarIcon isWhite={false} />
        {date
          ? date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })
          : defaultValue
            ? defaultValue
            : placeholder}
      </FilterItem>
      {showCalendar && (
        <CalendarContainer
          $filterItemOffsetLeft={filterItemRef?.current?.offsetLeft ?? 0}
          $width={filterItemRef?.current?.offsetWidth ?? 0}
        >
          <Calendar
            onChange={value => {
              if (value instanceof Date) {
                if (onChangeValue) {
                  onChangeValue(value);
                }
                setDate(value);
              }
            }}
            value={date}
            maxDate={new Date()}
          />
          <StyledHr />
          <ButtonsContainer>
            <button
              className="clean"
              onClick={() => {
                setDate(null);
                onCleanFilter();
                setShowCalendar(false);
              }}
            >
              {t('global.button.clean')}
            </button>
            <button
              className="apply"
              onClick={() => {
                setShowCalendar(false);
              }}
            >
              {t('global.button.apply')}
            </button>
          </ButtonsContainer>
        </CalendarContainer>
      )}
    </OptionContainer>
  );
};
