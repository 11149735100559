import { Grid } from '@mui/material';
import CustomAutocomplete from 'components/custom-autocomplete';
import { Dispatch, SetStateAction } from 'react';
import { FormProvider } from 'react-hook-form';
import { WithTranslation, withTranslation } from 'react-i18next';
import CustomTextField from 'components/custom-text-field';
import { PixType } from 'model/enums/payment-type';
import * as S from './styles';
import CustomInputNumbersOnly from 'components/custom-input-numbers-only';
import { BankAccount } from 'model/bank-account';
import usePixRegistrationForm, { PixForm } from './hooks/use-pix-registration-form';
import InputMaskTypesOnlyNumbers from 'model/enums/input-mask-types-only-numbers';

interface Props extends WithTranslation {
  isRegistrationByAdmin?: boolean;
  clientId?: number;
  financingId?: number;
  selectedBankAccount?: BankAccount;
  setSelectedBankAccount: Dispatch<SetStateAction<BankAccount | undefined>>;
  setIsLoadingBankAccount: Dispatch<SetStateAction<boolean>>;
  setIsShowModalCreateBankAccount: Dispatch<SetStateAction<boolean>>;
  getBankAccount?: () => void;
  getRegisteredBanks?: () => void;
}

const PixRegistrationForm = ({
  isRegistrationByAdmin,
  clientId,
  financingId,
  selectedBankAccount,
  setSelectedBankAccount,
  setIsShowModalCreateBankAccount,
  setIsLoadingBankAccount,
  getBankAccount,
  getRegisteredBanks,
  t,
}: Props) => {
  const {
    methods,
    pixKeyTypes,
    pixKeyTypeSelected,
    handlePixKeyUpdate,
    handleCreatePixKey,
    selectPixKeyType,
    handleDefaultValue,
    maximumInputSize,
    typeMaskInput,
  } = usePixRegistrationForm(
    setIsLoadingBankAccount,
    setSelectedBankAccount,
    setIsShowModalCreateBankAccount,
    getRegisteredBanks,
    getBankAccount,
    selectedBankAccount,
    isRegistrationByAdmin,
    clientId,
    financingId
  );

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(formData => {
          selectedBankAccount?.id != null ? handlePixKeyUpdate(formData as PixForm) : handleCreatePixKey(formData as PixForm);
        })}
        id="bank-account-creation-form"
      >
        <Grid container rowSpacing={1.5} columnSpacing={3}>
          <Grid item xs={12} sm={6}>
            <CustomAutocomplete
              data={pixKeyTypes}
              keysValuesToDisplayed={['text']}
              label={t('dashboard.pixKeyType')}
              optionSelected={selectPixKeyType}
              defaultValue={handleDefaultValue()}
              placeholder={t('dashboard.selectTypePixKey')}
              errorMessage={methods.formState.errors.pixType?.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <S.InputsContainer>
              {pixKeyTypeSelected?.value === PixType.PHONE ? (
                <CustomInputNumbersOnly
                  name="pixKey"
                  mask={InputMaskTypesOnlyNumbers.PHONE}
                  label={t('dashboard.pixKey')}
                  placeholder={t('dashboard.insertYourPixKey')}
                  helperText={methods.formState.errors.pixKey?.message}
                  defaultValue={selectedBankAccount?.pixKey}
                />
              ) : (
                <CustomTextField
                  name="pixKey"
                  maxLength={maximumInputSize()}
                  mask={typeMaskInput()}
                  label={t('dashboard.pixKey')}
                  placeholder={t('dashboard.insertYourPixKey')}
                  helperText={methods.formState.errors.pixKey?.message}
                  defaultValue={selectedBankAccount?.pixKey}
                />
              )}
            </S.InputsContainer>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default withTranslation()(PixRegistrationForm);
