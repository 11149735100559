import { WithTranslation, withTranslation } from 'react-i18next';
import * as S from './styles';
import { ReactComponent as SuccessSvg } from 'images/green-success.svg';

const TransferCompletedSuccessfully = ({ t }: WithTranslation) => {
  return (
    <S.Container>
      <SuccessSvg style={{ margin: '0 auto' }} />
      <S.Title>{t('admin.newBalance.success.title')}</S.Title>
      <S.Message>{t('admin.newBalance.success.transferMade')}</S.Message>
    </S.Container>
  );
};

export default withTranslation()(TransferCompletedSuccessfully);
