import { SVGProps } from 'react';
import theme from '../themes/default';

export const DownloadSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.999959 10.1667C1.4602 10.1667 1.83329 10.5398 1.83329 11.0001V14.3334C1.83329 14.5544 1.92109 14.7664 2.07737 14.9227C2.23365 15.079 2.44561 15.1667 2.66663 15.1667H14.3333C14.5543 15.1667 14.7663 15.079 14.9225 14.9227C15.0788 14.7664 15.1666 14.5544 15.1666 14.3334V11.0001C15.1666 10.5398 15.5397 10.1667 16 10.1667C16.4602 10.1667 16.8333 10.5398 16.8333 11.0001V14.3334C16.8333 14.9965 16.5699 15.6323 16.1011 16.1012C15.6322 16.57 14.9963 16.8334 14.3333 16.8334H2.66663C2.00358 16.8334 1.3677 16.57 0.898859 16.1012C0.430018 15.6323 0.166626 14.9965 0.166626 14.3334V11.0001C0.166626 10.5398 0.539722 10.1667 0.999959 10.1667Z"
      fill={theme.color.primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.74404 6.24416C4.06947 5.91872 4.59711 5.91872 4.92255 6.24416L8.49996 9.82157L12.0774 6.24416C12.4028 5.91872 12.9304 5.91872 13.2559 6.24416C13.5813 6.5696 13.5813 7.09723 13.2559 7.42267L9.08922 11.5893C8.76378 11.9148 8.23614 11.9148 7.9107 11.5893L3.74404 7.42267C3.4186 7.09723 3.4186 6.5696 3.74404 6.24416Z"
      fill={theme.color.primaryColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.49996 0.166748C8.9602 0.166748 9.33329 0.539844 9.33329 1.00008V11.0001C9.33329 11.4603 8.9602 11.8334 8.49996 11.8334C8.03972 11.8334 7.66663 11.4603 7.66663 11.0001V1.00008C7.66663 0.539844 8.03972 0.166748 8.49996 0.166748Z"
      fill={theme.color.primaryColor}
    />
  </svg>
);
