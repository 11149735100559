import styled from 'styled-components';

export const DropDownFilter = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  padding: 16px 16px;
  border: 1px solid ${({ theme }) => theme.color.gray20};
  border-radius: 8px;

  width: max-content;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.span`
  font-style: normal;
  font-family: Satoshi;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.gray18};
  margin-bottom: 16px;
`;

export const CustomRadioOption = styled.div`
  width: 146px;
  color: var(--gray-dark-3, #4c4d4d);
  font-family: Satoshi;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  span {
    font-size: 14px;
  }
  svg {
    width: 24px;
  }
`;
