export enum UnicoProcessStatus {
  NEW = 'NEW',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  SYSTEM_ERROR = 'SYSTEM_ERROR',
  CANCELED = 'CANCELED',
  BIOMETRIC_SUCCESS = 'BIOMETRIC_SUCCESS',
  DOCUMENT_SUCCESS = 'DOCUMENT_SUCCESS',
  FINISH_SUCCESS = 'FINISH_SUCCESS',
  WAITING_FINISH = 'WAITING_FINISH',
  PROBLEM = 'PROBLEM',
  FINISHED = 'FINISHED',
  OFF = 'OFF',
  OCR_SUCCESS = 'OCR_SUCCESS',
  OCR_NOTOK = 'OCR_NOTOK',
  FACEMATCH_SUCCESS = 'FACEMATCH_SUCCESS',
  FACEMATCH_NOTOK = 'FACEMATCH_NOTOK',
  FRAUD_CONFIRMED = 'FRAUD_CONFIRMED',
  FRAUD_POSSIBLE = 'FRAUD_POSSIBLE',
  FRAUD_NEUTRAL = 'FRAUD_NEUTRAL',
  FRAUD_NO_EVIDENCES = 'FRAUD_NO_EVIDENCES',
  FRAUD_NEGATIVE = 'FRAUD_NEGATIVE',
  FRAUD_DETECTED = 'FRAUD_DETECTED',
  UNICO_PROBLEM = 'UNICO_PROBLEM',
}

// StatusProcess enum
export enum StatusProcess {
  WAITING_FINISH = 'WAITING_FINISH', // Em Processamento
  PROBLEM = 'PROBLEM', // Problema
  FINISHED = 'FINISHED', // Finalizado
  CANCELLED = 'CANCELLED', // Cancelado
  ERROR = 'ERROR', // Erro
}

// StatusBiometricInternal enum
export enum StatusBiometricInternal {
  OFF = 'OFF', // Desligado
  BIOMETRIC_SUCCESS = 'BIOMETRIC_SUCCESS', // Aprovado
  BIOMETRIC_NOTOK = 'BIOMETRIC_NOTOK', // Foto de Foto
}

// StatusOCRInternal enum
export enum StatusOCRInternal {
  OFF = 'OFF', // Desligado
  OCR_SUCCESS = 'OCR_SUCCESS', // Aprovado
  OCR_NOTOK = 'OCR_NOTOK', // Reprovado
}

// StatusFaceMatchInternal enum
export enum StatusFaceMatchInternal {
  OFF = 'OFF', // Desligado
  FACEMATCH_SUCCESS = 'FACEMATCH_SUCCESS', // Aprovado
  FACEMATCH_NOTOK = 'FACEMATCH_NOTOK', // Reprovado
}
