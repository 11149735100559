import { RegistrationStatus } from 'model/enums/registration-status';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown } from 'react-icons/io';
import { SelectContainer, StyledLabel, StyledOptionsContainer, StyledOption, Alignor, SelectedContainer } from './styles';

export const SimpleAnalisysSelect = ({ value, onChangeFunc, placeholder, options }) => {
  const [openSelector, setOpenSelector] = useState(false);
  const { t } = useTranslation();

  const handleSelection = elem => {
    if (!elem.email) {
      switch (elem.name) {
        case t('analisisModal.APPROVED'):
          onChangeFunc({ ...elem, status: RegistrationStatus.APPROVED });
          break;
        case t('analisisModal.ADJUST'):
          onChangeFunc({ ...elem, status: RegistrationStatus.ADJUST });
          break;
        case t('analisisModal.REJECTED'):
          onChangeFunc({ ...elem, status: RegistrationStatus.REJECTED });
          break;

        default:
          break;
      }
    } else {
      onChangeFunc(elem);
    }
    setOpenSelector(false);
  };

  return (
    <>
      <StyledLabel $up={openSelector || value.name !== undefined}>{placeholder}</StyledLabel>
      <SelectContainer onClick={() => setOpenSelector(!openSelector)}>
        <Alignor>
          <SelectedContainer>{value.name}</SelectedContainer>
          <IoIosArrowDown />
        </Alignor>
      </SelectContainer>
      {openSelector && (
        <StyledOptionsContainer>
          {options.map(elem => (
            <StyledOption onClick={() => handleSelection(elem)}>{elem.name}</StyledOption>
          ))}
        </StyledOptionsContainer>
      )}
    </>
  );
};
