import { ScreenDimensions } from 'model/enums/screen-dimensions-types';
import styled from 'styled-components';

export const StyledCardsContainerMobile = styled.div`
  /* max-height: 480px; */
  margin-top: 16px;
  overflow-y: auto;
`;

export const StyledCardContainer = styled.div`
  width: 100%;
  min-height: 96px;

  padding: 12px;
  margin-left: auto;
  margin-bottom: 8px;
  border: ${({ theme }) => `1px solid ${theme.color.gray20}`};
  border-radius: 8px;
  font-size: 16px;
  color: #000000;
  text-align: left;
  background-color: #ffffff;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 991.98px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StyleCardInnerContainer = styled.div`
  width: 100%;
  margin-right: 10px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const StyledHeaderCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCardTitle = styled.span`
  font-weight: 700;

  @media (max-width: ${ScreenDimensions.MID_TABLET}) {
    font-size: 14px;
  }
`;

export const StyledSubtitle = styled.span`
  font-weight: 600;
  line-height: 1.2;

  @media (max-width: 575.98px) {
    font-size: 14px;
  }
`;

export const StyledCardText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.color.gray19};
`;
