import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin-top: 88px;
  text-align: center;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.black0};
`;

export const Message = styled.div`
  max-width: 470px;
  margin: 0 auto;

  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.gray18};
`;
