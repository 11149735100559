import { StyledInnerContainer } from './styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import ContractContext from 'context/contract';
import { useHistory } from 'react-router-dom';
import MobileHeader from 'components/mobile-header';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { RoutePath } from 'model/enums/route-path';
import { LoadingContainer, StyledContainer } from '../styles';
import LoadingAnimation from 'components/loading-big-animation';

const DocumentAnalysis = ({ t }: WithTranslation) => {
  const history = useHistory();

  const { signatureKey } = useContext(ContractContext);
  const { isMobile, isTablet } = useResponsiveMobile();

  useEffect(() => {
    if (!isMobile && !isTablet) {
      return history.push(`${RoutePath.QR_CODE}/${signatureKey}`);
    }
  }, []);

  return (
    <StyledContainer style={{ height: '100vh' }}>
      <MobileHeader></MobileHeader>
      <StyledInnerContainer>
        <h1>{t('contract.waitingStep.documentAnalysis.title')}</h1>
        <span>{t('contract.waitingStep.documentAnalysis.subtitle')}</span>
        <LoadingContainer style={{ marginBottom: '24px' }}>
          <LoadingAnimation />
        </LoadingContainer>
      </StyledInnerContainer>
      <div className="waiting-step-footer">{t('contract.footer')}</div>
    </StyledContainer>
  );
};

export default withTranslation()(DocumentAnalysis);
