import * as S from './styles';
import { ReactComponent as InputRadioNotSelectedSvg } from 'images/gray-input-radio-not-selected.svg';
import { ReactComponent as InputRadioCheckedSvg } from 'images/input-radio-checked.svg';

interface Props {
  text: string;
  checked: boolean;
  handleOnClick: () => void;
}

const SimpleRadioInput = ({ text, checked, handleOnClick }: Props) => {
  return (
    <S.Container onClick={() => handleOnClick()}>
      <S.ContainerInput>
        <S.Input name="radio" type="radio" checked={checked} readOnly />
        {checked ? (
          <S.PaintSvg>
            <InputRadioCheckedSvg />
          </S.PaintSvg>
        ) : (
          <InputRadioNotSelectedSvg />
        )}
      </S.ContainerInput>

      <S.Text>{text}</S.Text>
    </S.Container>
  );
};

export default SimpleRadioInput;
