import DocumentInstructionFrame from 'images/document-instruction-frame.png';
import CngPng from 'images/cnh.png';
import MobileHeader from 'components/mobile-header';
import CustomButton from 'components/custom-button';
import { useHistory } from 'react-router-dom';
import CardShowContract from 'components/card-show-contract';
import {
  ContractList,
  ImageContainer,
  PageTitleSection,
  StyledCardShowContractContainer,
  StyledContainer,
  StyledInnerContainer,
} from '../styles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import ContractContext from 'context/contract';
import { ReactComponent as ArrowBack } from 'images/arrow_back.svg';
import { Trans } from 'react-i18next';
import CustomComponentModal from 'components/custom-conmponent-modal';
import { ContractPdf } from 'features/admin/contractScreen/components/contractPdf';
import { ReactComponent as CameraIconSvg } from 'images/camera.svg';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { ArrowContainer } from '../go-to-mobile/styles';
import { RoutePath } from 'model/enums/route-path';
import { IdentityDocumentTypes } from 'model/enums/identity-document-type';
import RG from '../../../images/RG.png';
import ScrollToTop from 'components/scroll-top';

const ProofOfLifeInstructions = ({ t }: WithTranslation) => {
  const history = useHistory();

  const [showComponentModal, setShowComponentModal] = useState<boolean>(false);
  const { isMobile, isTablet } = useResponsiveMobile();

  const { documentPresignUrl, signatureKey, identityDocument } = useContext(ContractContext);

  const takeDocument = () => {
    history.push(RoutePath.PROOF_LIFE);
  };

  useEffect(() => {
    if (!isMobile && !isTablet) {
      return history.push(`/qr-code/${signatureKey}`);
    }

    if (!signatureKey) {
      return history.push('/');
    }
  }, []);

  return (
    <StyledContainer>
      <MobileHeader></MobileHeader>
      <ScrollToTop />
      <StyledInnerContainer>
        <PageTitleSection>
          <ArrowContainer>
            <ArrowBack onClick={() => history.push(`/lets-sign`)} />
            <span>{t('letsSign.stepOfTotal', { step: '2' })}</span>
          </ArrowContainer>
          <p>{t('contract.proofOfLife.title')}</p>
          <ImageContainer style={{ marginTop: '24px' }}>
            <img
              src={RG}
              alt="document-image"
              style={{
                width: '72.41px',
                height: '115px',
              }}
            />
          </ImageContainer>
        </PageTitleSection>

        <ContractList>
          <li>{t('contract.proofOfLife.instructionList.maximizeScreenBrightness')}</li>
          <li>{t('contract.proofOfLife.instructionList.avoidBrightLighting')}</li>
          <li>{t('contract.proofOfLife.instructionList.avoidStrongShadows')}</li>
          <li>{t('contract.proofOfLife.instructionList.removeGlassesAndAccessories')}</li>
        </ContractList>
        <CustomButton className="mobile-button--gofoward" disabled={false} onClick={() => takeDocument()}>
          <CameraIconSvg style={{ marginRight: '8px' }} />
          {t('global.button.takeSelfie')}
        </CustomButton>
        <StyledCardShowContractContainer>
          <CardShowContract
            onClick={() => {
              setShowComponentModal(true);
            }}
          />
        </StyledCardShowContractContainer>
        <CustomComponentModal open={showComponentModal} setOpen={setShowComponentModal}>
          <ContractPdf url={documentPresignUrl ?? ''} />
        </CustomComponentModal>
      </StyledInnerContainer>

      <div className="waiting-step-footer">{t('contract.footer')}</div>
    </StyledContainer>
  );
};

export default withTranslation()(ProofOfLifeInstructions);
