import { AxiosResponse } from 'axios';
import { PaymentCompany, Subsidiary } from 'model/subsidiaries';
import { api } from './api';
import { OrganizationSubsidiaries } from 'model/landing-page';
import { PageableResponse } from 'model/pageable';
import { BranchStatusTypes } from 'model/enums/branch-status';
import { simpleParamsFormatter } from 'shared/util/api-utils';

export const subsidiariesApi = () => {
  const getSubsidiariesByOrgId = (orgId: number): Promise<AxiosResponse<Subsidiary[]>> => {
    return api.get<Subsidiary[]>(`/organization-subsidiaries/organization/${orgId}`);
  };

  const getOrganizationSubsidiaries = (
    searchName?: string,
    size?: number,
    page?: number,
    activeStatus?: string,
    sort?: string
  ): Promise<AxiosResponse<PageableResponse<OrganizationSubsidiaries>>> => {
    const filterParams = simpleParamsFormatter(
      {
        size: size ?? 10,
        page: page ?? 0,
        cnpjName: searchName,
        active: activeStatus == BranchStatusTypes.ACTIVE ? 'true' : 'false',
        sort: [{ order: 'name' }, { order: sort }],
      },
      {
        sort: 'order',
      }
    );

    return api.get<PageableResponse<OrganizationSubsidiaries>>(`/organization-subsidiaries${filterParams}`);
  };

  const createSubsidiaries = (newBranch: Subsidiary): Promise<AxiosResponse<Subsidiary>> => {
    return api.post<Subsidiary>(`/organization-subsidiaries`, newBranch);
  };

  const updateBranch = (id: number, newBranch: Subsidiary): Promise<AxiosResponse<Subsidiary>> => {
    return api.patch<Subsidiary>(`/organization-subsidiaries/${id}`, newBranch);
  };

  const getBranchInfos = (id: number): Promise<AxiosResponse<Subsidiary>> => {
    return api.get<Subsidiary>(`/organization-subsidiaries/${id}`);
  };

  const activateBranch = (id: number): Promise<AxiosResponse<Subsidiary>> => {
    return api.patch<Subsidiary>(`/organization-subsidiaries/${id}/activate`);
  };

  const inactivateBranch = (id: number): Promise<AxiosResponse<Subsidiary>> => {
    return api.patch<Subsidiary>(`/organization-subsidiaries/${id}/inactivate`);
  };

  const getPaymentCompanies = (): Promise<AxiosResponse<PaymentCompany[]>> => {
    return api.get<PaymentCompany[]>(`/payment-companies`);
  };

  return {
    getSubsidiariesByOrgId,
    getOrganizationSubsidiaries,
    createSubsidiaries,
    getBranchInfos,
    activateBranch,
    inactivateBranch,
    updateBranch,
    getPaymentCompanies,
  };
};

export default subsidiariesApi();
