import styled from 'styled-components';

export const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.color.grayBackground};
  height: 100vh;
`;

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 55px;
  padding-left: 55px;
  max-width: 1425px;
  margin: 0 auto;
`;

export const TitleHeader = styled.div`
  margin-top: 48px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  span.title {
    color: ${({ theme }) => theme.color.gray21};
    font-weight: 700;
    font-size: 32px;
    line-height: 150%;
  }
`;
